// 2021-04-09由FZY创建 用于通知API

/* 
	传参字典
		lave_lesson_notice: 剩余课时提醒
		lave_expriation_notice: 有效期不足
		today_before_class_notice: 前一日提醒
		today_class_notice: 当日上课提醒
		leave_limit: 请假时间限制 1- 当天 2- 前一天 3- 不限制
 */


/* 
	@describe 获取续费提醒配置
	@date 2021-04-09
	@by FZY	
 */
export async function getNoticeConfigRenew() {
	var {
		data: res
	} = await this.$http.get('/notice/config/renew');
	if (res.code == 0) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}


/* 
	@describe 获取上课提醒配置
	@date 2021-04-09
	@by FZY	
 */
export async function getNoticeConfigClass() {
	var {
		data: res
	} = await this.$http.get('/notice/config/class');
	if (res.code == 0) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}


/* 
	@describe 修改通知配置
	@date 2021-04-09
	@by FZY
	@data
		lave_lesson_notice: Number
		lave_expriation_notice: Number
		today_before_class_notice: String
		today_class_notice: Number
		leave_limit: Number
 */
export async function modifyNoticeConfig(data) {
	var {
		data: res
	} = await this.$http.post('/notice/config/store', data);
	if (res.code == 0) {
		this.$message.success('修改通知配置成功')
		return res.data
	} else this.$message.error(res.message || res.msg)
}
