/* 部门配置 */
import {
	formatSameData
} from '../util.js'

export const LEVELTYPE = [
	{
		label: '校区',
		value: 1
	},
	{
		label: '总部',
		value: 2
	}
]
export const LEVEL = [
	{
		label: '1级',
		value: 1
	},
	{
		label: '10级',
		value: 10
	},
	{
		label: '20级',
		value: 20
	},	
	{
		label: '30级',
		value: 30
	},
	{
		label: '40级',
		value: 40
	},
	{
		label: '50级',
		value: 50
	},
	{
		label: '60级',
		value: 60
	},
	{
		label: '70级',
		value: 70
	},
	{
		label: '80级',
		value: 80
	},
	{
		label: '90级',
		value: 90
	},
	{
		label: '100级',
		value: 100
	},
]

export class level {
	constructor() {
		var arg = Array.from(arguments);
		this.data = [];
	    this.schoole = [];
		this.headquarters = [];
		(arg.length > 0) && (this.setData(arg[0]), this.setDefault())
	}
	setData (data) {
		this.data = formatSameData(data, 'type');
	}
	setDefault () {
		this.setSchoole();
		this.setHeadquarters()
	}
	setSchoole () {
		this.schoole = [];
		if (this.data.length <= 0) return		
		var index = this.data.findIndex( item => {
			if (item.length == 0 || item[0].type != 1) return false
			return true
		})
		if (index == -1) return
		console.log(index, 'sch')
		this.data[index].forEach( item => {
			this.schoole.push(item.level)
		})
	}
	setHeadquarters () {
		this.headquarters = [];
		if (this.data.length <= 1) return
		var index = this.data.findIndex( item => {
			if (item.length == 0 || item[0].type != 2) return false
			return true
		})
		if (index == -1) return
		console.log(index, 'ch')
		this.data[index].forEach( item => {
			this.headquarters.push(item.level)
		})
	}
	getLately (key) {
		//key值是schoole就是this.schoole 反之亦然  key接受两个schoole和headquarters
		if (key != 'schoole' && key != 'headquarters') return '请确认传参是否正确。接受schoole和headquarters中的一个'
		var result = 10,
			max;
		if (this[key].length > 0) max = Math.max(...this[key])
		if (max && max != 1) result = max - 0 + 10
		return result
	}

}