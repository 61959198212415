<template>
	<!-- 数据分析筛选 -->
	<div class="flex">
		<el-date-picker v-model="date" class='mg-l-10' style="width: 30%;" type="daterange" range-separator="至" v-if='isHaveDatePicker'
		 :clearable="false" start-placeholder="开始日期" end-placeholder="结束日期" size="small" :format="dateFormat" :value-format="dateFormat"
		 @change="dateChange">
		</el-date-picker>
		<!-- 上月 -->
		<div class="tag mg-l-10 cursor-p" :class="{selectTag: selectTag == 7}" @click='getScreen(7)' v-if="lastMonth">{{lastMonth}}</div>
		<!-- 当月 -->
		<div class="tag mg-l-10 cursor-p" :class="{selectTag: selectTag == 3}" @click='getScreen(3)' v-if="thisMonth">{{thisMonth}}</div>
		<!-- 7天 -->
		<div class="tag mg-l-10 cursor-p" :class="{selectTag: selectTag == 0}" @click='getScreen(0)' v-if="sevenDay">{{sevenDay}}</div>
		<!-- 昨天 -->
		<div class="tag mg-l-10 cursor-p" :class="{selectTag: selectTag == 1}" @click='getScreen(1)' v-if="yesterday">{{yesterday}}</div>
		<!-- 今天 -->
		<div class="tag mg-l-10 cursor-p" :class="{selectTag: selectTag == 2}" @click='getScreen(2)' v-if="today">{{today}}</div>
		<el-select size='small' class="mg-l-10" style="width: 15%;" v-model="screen.school_id" @change="schoolChange">
			<el-option v-for="item in schoolOptions" :key="item.id" :label="item.name" :value="item.id">
			</el-option>
		</el-select>
	</div>
</template>

<script>
	let screenDate;
	import {
		formatDate
	} from '../../../assets/js/myMethods.js'
	import {
		extend
	} from '../../../assets/js/util.js'
	export default {
		name: 'DataAnalysisSearch',
		props: {
			isHaveDatePicker: {
				type: Boolean,
				default: true
			},
			dateFormat: {
				default: 'yyyy-MM-dd'
			},
			defaultData: {
				default: function() {
					return {
						selectTag: 1,
						date: [],
						school_id: ''
					}
				},
			},
			lastMonth: {
				default: ''
			},
			thisMonth: {
				default: ''
			},
			sevenDay: {
				default: ''
			},
			yesterday: {
				default: ''
			},
			lastMonth: {
				default: ''
			},
			today: {
				default: ''
			}
		},
		data() {
			return {
				schoolOptions: [],
				selectTag: 1,
				screen: {
					date_start: '',
					date_end: '',
					school_id: ''
				},
				date: []
			}
		},
		methods: {
			throwDefault() {
				return {
					selectTag: this.selectTag,
					date: [this.screen.date_start, this.screen.date_end],
					school_id: this.screen.school_id
				}
			},
			checkIsHave(selectTag) {
				const check = ['sevenDay', 'yesterday', 'today', 'thisMonth', '', '', '', 'lastMonth'];
				var bool = this[check[selectTag]];
				if (bool == '') return false
				return true
			},
			setDefault() {
				var defaultData = this.defaultData;
				if (this.isHaveDatePicker) {
					if (this.checkIsHave(defaultData.selectTag)) {
						this.selectTag = defaultData.selectTag;
					} else {
						this.selectTag = -1
					}
					if (this.selectTag == -1) {
						this.date = defaultData.date.length == 2 ? [...defaultData.date] : [screenDate[6].date_start, screenDate[6].date_end];
					} else {
						this.date = [screenDate[defaultData.selectTag].date_start, screenDate[defaultData.selectTag].date_end]
					}
					this.screen.date_start = this.date[0];
					this.screen.date_end = this.date[1]
				}
				this.screen.school_id = defaultData.school_id
			},
			schoolChange() {
				this.throwData()
			},
			dateChange(id) {
				this.selectTag = -1;
				this.screen.date_start = id[0];
				this.screen.date_end = id[1];
				this.throwData()
			},
			getScreen(key) {
				this.selectTag = key;
				extend(this.screen, screenDate[key]);
				this.date = [screenDate[key].date_start, screenDate[key].date_end]
				this.throwData()
			},
			/* 获取校区 */
			async getSchool() {
				var {
					data: res
				} = await this.$http.get('/statistics/school');
				if (res.code == 0) {
					var arr = [{
						id: '',
						name: '全部校区'
					}];
					arr.push(...res.data);
					this.schoolOptions = arr
				} else {
					this.$message.error('获取校区失败')
				}
			},
			throwData() {
				this.$emit('getScreen', this.screen)
			},
			/* 7天 昨天 今天 当月 近一年 */
			getConditionReport() {
				var DATE = new Date(),
					dateNow = formatDate.detail(DATE, 'yyyy-mm-dd'),
					dateNowDetail = formatDate.detail(DATE);
				var reportDate = [{ //7天					
						date_start: formatDate.computed(DATE, 6, 'reduce', 'day'),
						date_end: formatDate.computed(DATE, 0, 'add', 'day')
					},
					{ //昨天
						date_start: formatDate.computed(DATE, 1, 'reduce', 'day'),
						date_end: formatDate.computed(DATE, 1, 'reduce', 'day'),
					},
					{ //今天
						date_start: dateNow,
						date_end: dateNow //formatDate.computed(DATE, 1, 'add', 'day'),
					},
					{ //当月  带日期
						date_start: formatDate.detail(dateNowDetail.year + '-' + dateNowDetail.mouth + '-01', 'yyyy-mm-dd'),
						date_end: dateNow
					},
					{ //近一年  不带日期
						date_start: formatDate.computed(DATE, 1, 'reduce', 'year', 'yyyy-mm'),
						date_end: formatDate.computed(DATE, 0, 'add', 'mouth', 'yyyy-mm')
					},
					{ //近一年  带日期
						date_start: formatDate.computed(DATE, 1, 'reduce', 'year', 'yyyy-mm-dd'),
						date_end: formatDate.computed(DATE, 0, 'add', 'mouth', 'yyyy-mm-dd')
					},
					{ //半年  不带日期
						date_start: formatDate.computed(DATE, 6, 'reduce', 'mouth', 'yyyy-mm'),
						date_end: formatDate.computed(DATE, 0, 'add', 'mouth', 'yyyy-mm')
					},
					{ //上月
						date_start: formatDate.detail((dateNowDetail.mouth == '01' ? dateNowDetail.year - 1 : dateNowDetail.year) + '-' +
							(dateNowDetail.mouth == '01' ? 12 : dateNowDetail.mouth - 1) + '-01', 'yyyy-mm-dd'),
						date_end: formatDate.computed(formatDate.detail(dateNowDetail.year + '-' + dateNowDetail.mouth + '-01',
							'yyyy-mm-dd'), 1, 'reduce', 'day', 'yyyy-mm-dd')
					}
				];
				return reportDate
			}
		},
		watch: {
			'schoolOptions': function() {
				this.setDefault()
			}
		},
		created() {
			screenDate = this.getConditionReport();
			this.getSchool();
			this.setDefault()
		},
		mounted() {
			this.$emit('throwDefault', this.throwDefault)
		}
	}
</script>

<style scoped>
	.selectTag {
		border: 1px solid #fed90e !important;
		color: #fed90e !important;
	}

	.tag {
		border: 1px solid #c0c4cc;
		color: #424346;
		border-radius: 4px;
		height: 32px;
		line-height: 32px;
		padding: 0 15px;
		font-size: 14px;
	}

	@media screen and (min-width: 1500px) {
		.tag {
			padding: 0 15px;
			font-size: 14px;
		}
	}

	@media screen and (max-width: 1499px) {
		.tag {
			padding: 0 10px;
			font-size: 12px;
		}
	}
</style>
