<template>
  <div>
    <div v-if="isHaveQuickOperation">
      <h1>四步快速完成 <span style="color: #0099ff;">初始设置</span></h1>
      <el-card class='mg-t-10'>
        <div class="flex align-center space-around" style="padding: 0 40px;">
          <div class="flex flex-column align-center cursor-p" @click="navigation('/system/account/user')">
            <img src="../assets/image/novice1.svg" style='vertical-align: middle;' />
            <h4 style="color: #333333;" class="mg-t-10"><span style="color: #00afec;">教师</span>管理</h4>
            <p class="fs-12 mg-t-10" style="color: #959595;"><span style="margin-right: 5px;">添加教师</span><span style="margin-left: 5px;">设置权限</span></p>
          </div>
          <div class='line'></div>
          <div class="flex flex-column align-center cursor-p" @click="navigation('/organization/charges')">
            <img src="../assets/image/novice2.svg" style='vertical-align: middle;' />
            <h4 style="color: #333333;" class="mg-t-10"><span style="color: #00afec;">课程</span>设置</h4>
            <p class="fs-12 mg-t-10" style="color: #959595;"><span style="margin-right: 5px;">添加课程</span><span style="margin-left: 5px;">设定课程规划</span></p>
          </div>
          <div class='line'></div>
          <div class="flex flex-column align-center cursor-p" @click="navigation('/organization/classrooms')">
            <img src="../assets/image/novice3.svg" style='vertical-align: middle;' />
            <h4 style="color: #333333;" class="mg-t-10"><span style="color: #00afec;">场地</span>设置</h4>
            <p class="fs-12 mg-t-10" style="color: #959595;"><span style="margin-right: 5px;">设置上课场地</span><span style="margin-left: 5px;">设置人数</span></p>
          </div>
          <div class='line'></div>
          <div class="flex flex-column align-center cursor-p" @click="navigation('/education/educationFastTypesetting')">
            <img src="../assets/image/novice5.svg" style='vertical-align: middle;' />
            <h4 style="color: #333333;" class="mg-t-10"><span style="color: #00afec;">快速</span>排班</h4>
            <p class="fs-12 mg-t-10" style="color: #959595;"><span style="margin-right: 5px;">设立班级</span><span style="margin-left: 5px;">快速排班</span></p>
          </div>
        </div>
      </el-card>
    </div>
    <el-card :class='{"mg-t-10": isHaveQuickOperation }'>
      <div slot='header' class="fs-14">快捷操作台</div>
      <div class="flex align-center space-around" style="padding: 0 40px;">
        <div class="flex flex-column align-center">
          <div class="flex cursor-p" style="align-items: flex-end;" @click="navigation('/education/attendanceRecords?a_status=4')">
            <img src="../assets/image/novice6.svg" style='vertical-align: middle; width: 40px;' />
            <div class="fs-14" style="margin-left: 5px;">
              <div style="font-weight: bold;color:#fed90e ;">{{untreated}}</div>
              <p>待考勤</p>
            </div>
          </div>
        </div>
        <div class='line' style="opacity: 0;"></div>
        <div class="flex flex-column align-center">
          <div class="flex cursor-p" style="align-items: flex-end;" @click="navigation('/education/calendars?status=2')">
            <img src="../assets/image/novice7.svg" style='vertical-align: middle; width: 40px;' />
            <div class="fs-14" style="margin-left: 5px;">
              <div style="font-weight: bold;color:#fed90e ;">{{notUpload}}</div>
              <p>待点评</p>
            </div>
          </div>
        </div>
        <div class='line' style="opacity: 0;"></div>
        <div class="flex flex-column align-center">
          <div class="flex cursor-p" style="align-items: flex-end;" @click="navigation('/msg?tab=leave')">
            <img src="../assets/image/novice8.svg" style='vertical-align: middle; width: 40px;' />
            <div class="fs-14" style="margin-left: 5px;">
              <div style="font-weight: bold;color:#fed90e ;">{{type1}}</div>
              <p>家长请假</p>
            </div>
          </div>
        </div>
        <div class='line' style="opacity: 0;"></div>
        <div class="flex flex-column align-center">
          <div class="flex cursor-p" style="align-items: flex-end;" @click="navigation('/msg?tab=classTime')">
            <img src="../assets/image/novice9.svg" style='vertical-align: middle; width: 40px;' />
            <div class="fs-14" style="margin-left: 5px;">
              <div style="font-weight: bold;color:#fed90e ;">{{type10}}</div>
              <p>待续费</p>
            </div>
          </div>
        </div>
        <div class='line' style="opacity: 0;"></div>
        <div class="flex flex-column align-center">
          <div class="flex cursor-p" style="align-items: flex-end;" @click="navigation('/msg?tab=term')">
            <img src="../assets/image/novice10.svg" style='vertical-align: middle; width: 40px;' />
            <div class="fs-14" style="margin-left: 5px;">
              <div style="font-weight: bold;color:#fed90e ;">{{type9}}</div>
              <p>将到期</p>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-tabs type="border-card" v-model="activeName" class="mg-t-10">
      <el-tab-pane label="表格视图" name="表格视图">
        <student-time-table-c></student-time-table-c>
      </el-tab-pane>
      <el-tab-pane label="方块视图" name="方块视图" :lazy='true'>
        <fast-type-setting-c :flagAddClass="false"></fast-type-setting-c>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
import courseC from "./education/form/education-calendar";
import fastTypeSettingC from "./education/fastTypesetting.vue";
import studentTimeTableC from "./student/form/studentTimeTable.vue";
import classJs from "../assets/js/class.js";
import { getMsg_NoRead } from "../assets/js/API/msg.js";
export default {
  name: "Index",
  components: {
    courseC,
    fastTypeSettingC,
    studentTimeTableC,
  },
  data() {
    return {
      activeName: "表格视图",
      isHaveQuickOperation: true,
      type1: 0,
      type9: 0,
      type10: 0,
      untreated: 0,
      notUpload: 0,
      /* 选择时间 */
      chooseTime: "",
      /* 时间选择配置 */
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      /* 搜索 */
      key: "",
      /*  */
      date_start: "",
      date_end: "",
      /* 是否代开dialog */
      dialogVisible: false,
      /* 允许横向拖拽 */
      allowXDrag: false,
      /* 正在移动的标志 */
      bodyIsMove: true,
      /* Y轴高度 */
      heightY: 0,
      /* X轴的宽度 */
      widthX: 0,
      /* 选中的班级 */
      choose: {},
      /* 存班级上课时间坐标 （二维数组） */
      coordinate: [[], [], [], [], [], [], []],
      /* Y轴数据 */
      scrollYData: [],
      classTime: [],
      scrollXData: [],
      /* 颜色大全 */
      colors: [
        "#2196F3",
        "#00BCD4",
        "#e8900a",
        "#353535",
        "#d05939",
        "#5fb858",
        "#7f16e1",
        "#b941b5",
        "#3abc8f",
        "#5458f1",
        "#FF00FF",
        "#BC8F8F",
        "#808A87",
        "#A066D3",
        "#FF9912",
      ],
    };
  },
  methods: {
    getMsg_NoRead() {
      getMsg_NoRead
        .call(this)
        .then((res) => {
          this.type1 = res.type_1;
          this.type9 = res.type_9;
          this.type10 = res.type_10;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 获取考勤统计分析 */
    async getAttendanceAnalysis() {
      const { data: res } = await this.$http.get(
        "education/attendance/getAnalysis"
      );
      if (res.code == 200) {
        this.untreated = res.data.attendance.untreated;
        this.notUpload = res.data.upload.notUpload;
      }
    },
    navigation(url) {
      this.$router.push(url);
    },
    /* 超出字体省略 */
    elips(playerName, len) {
      var new_playerName = "";
      if (playerName.length > len) {
        new_playerName = playerName.substring(0, len);
        new_playerName += "..";
      } else {
        new_playerName = playerName;
      }
      return new_playerName;
    },
  },
  created() {
    setTimeout(() => {
      this.getAttendanceAnalysis();
      this.getMsg_NoRead();
    }, 1000);
    let date = classJs.formatDate.getWeekStartAndEndByChange(
      classJs.formatDate.getNowFormatDate()
    );
    this.date_start == "" && (this.date_start = date.date_start);
    this.date_end == "" && (this.date_end = date.date_end);
    this.chooseTime = this.date_start;
    var role = sessionStorage.getItem("role");
    role > 2 && (this.isHaveQuickOperation = false);
  },
};
</script>
<style lang="less" scoped>
.line {
  width: 10%;
  height: 4px;
  background-color: #b7b7b7;
  border-radius: 4px;
  position: relative;
  top: -25px;
}

.card-header {
  width: 10%;
  display: flex;
  justify-content: space-between;
}

/deep/ .el-card__body {
  flex: 1;
}

div {
  font-size: 14px;
}

.containers {
  background: linear-gradient(180deg, red, blue);
  color: transparent;
  -webkit-background-clip: text;
  line-height: 100%;
}

/* chrome 和Safari去除滚动条 */
*::-webkit-scrollbar {
  display: none;
}

* {
  /* IE去除滚动条IE10+ */
  -ms-overflow-style: none;
  /* 火狐 */
  scrollbar-width: none;
}

.time > div {
  line-height: 50px;
  min-height: 40px;
  max-height: 50px;
  border-bottom: 1px solid #eee;
}

.dateTime {
  border-bottom: 1px solid #eee;
}

.dialog-container > div {
  padding: 8px;
}
</style>
