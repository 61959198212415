<template>
  <!-- 课时表格 -->
  <div>
    <div class="header" v-if="operation">
      <el-button type="primary" @click="showDialog = true" size="small">新增课时</el-button>
    </div>
    <el-date-picker v-model="screen.date" class="mg-t-10" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="small"></el-date-picker>
    <el-table :data="list" ref="refTable" @current-change='current_change($event)' row-key="id" class="fsMedia" :highlight-current-row='isHightlight' :row-class-name="tableRowClassName">
      <el-table-column prop="" label="课时" width="240" show-overflow-tooltip>
        <template v-slot="scope">
          {{scope.row.date.split(' ')[0] }} {{scope.row.name}} {{scope.row.time_start}} - {{scope.row.time_end}}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="课时状态" width="90" show-overflow-tooltip>
        <template v-slot="scoped">
          <span v-if="scoped.row.status == 0">未处理</span>
          <span v-else-if="scoped.row.status == 1">已考勤</span>
          <span v-else-if="scoped.row.status == 2">已处理</span>
          <span v-else-if="scoped.row.status == 3">顺延</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="teacher_name" label="主课老师" width="100" show-overflow-tooltip>
        <template v-slot="scope">
          {{scope.row.teacher_name ? scope.row.teacher_name : '-'}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="student_count" label="学员总数" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="unprocessed_count" label="未处理" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="attendance_count" label="出勤" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="leave_count" label="请假" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="truancy_count" label="旷课" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="course_content" label="上课内容" min-width="80" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="!classId" prop="className" label="所属班级" min-width="80" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" fixed="right" width="320" v-if="operation">
        <template v-slot="scoped">
          <el-button size="small" type="primary" @click="showInfo(scoped.row)">查看详情</el-button>
          <el-button v-if="scoped.row.status != 1" size="small" type="primary" @click="show(scoped.row)">编辑</el-button>
          <el-button v-if="scoped.row.status != 1" size="small" type="primary" @click='postPhone(scoped.row)'>顺延</el-button>
          <el-button v-if="scoped.row.status != 1" size="small" type="primary" @click="delClassHour(scoped.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :current-page.sync="screen.page" @current-change="getClassHour" :total="pagination.total" class="mg-t-10">
    </el-pagination>

    <el-dialog :title="formData.id ? '编辑课时' : '添加课时'" :visible.sync="showDialog" width="40%" @close="close" center>
      <el-form :model="formData" ref="formDataRef" :rules="rules" label-position="right" label-width="100px">
        <el-form-item label="主课老师" prop="teacher_name">
          <el-input v-model="formData.teacher_name" readonly @focus="dialogVisible_taether = true"></el-input>
          <i class="el-icon-close cursor-p" style="position: absolute;right: 10px;top: 50%;transform: translate(0, -50%);" @click="clearTeacher()"></i>
        </el-form-item>
        <el-form-item label="开班日期" prop="date">
          <div class="block">
            <el-date-picker v-model="formData.date" size="small" style="width: 100%;" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="上课开始时间" prop="time_start">
          <el-time-select v-model="formData.time_start" size="small" style="width: 100%;" @change="formData.time_end = ''" :picker-options="{
				            start: '08:30',
				            step: '00:30',
				            end: '22:30'
				        }" placeholder="开始时间"></el-time-select>
        </el-form-item>
        <el-form-item label="上课结束时间" prop="time_end">
          <el-time-select v-model="formData.time_end" size="small" style="width: 100%;" :picker-options="{
				            start: '08:30',
				            step: '00:30',
				            end: '22:30',
				            minTime: formData.time_start
				        }" placeholder="结束时间"></el-time-select>
        </el-form-item>
        <el-form-item label="上课内容" prop="course_content">
          <el-input type='textarea' v-model="formData.course_content" placeholder="请输入上课内容" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="主课老师" :visible.sync="dialogVisible_taether" width="50%" v-if="dialogVisible_taether">
      <teacher-c :operation="false" @current_change='teacherCurrentChange' :selectRowIndex='getSelectRowID_teacher' :isHightlight="true"></teacher-c>
    </el-dialog>
  </div>
</template>

<script>
import {
  getClassHour_bringUp,
  getClassHour_callIn,
  addClassHour,
  modifyClassHour,
  postPhone,
  delClassHour,
} from "../../assets/js/API/class-hour.js";
import { extend } from "../../assets/js/util.js";
import teacherC from "../common/iteration-teacher-table.vue";
import vali from "../../assets/js/validate.js";
import {
  getClassRoom,
  modifyClassRoom,
  delClassRoom,
  addClassRoom,
  sortClassRoom,
} from "../../assets/js/API/classroom.js";

export default {
  name: "ClassRoomTable",
  props: {
    classId: {
      type: String,
      default: "",
    },
    isHightlight: {
      type: Boolean,
      default: false,
    },
    selectRowIndex: {
      default: -1,
    },
    operation: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    teacherC,
  },
  data() {
    return {
      isCanHttp: true,
      screen: {
        page: 1,
        date: [],
      },
      pagination: {
        total: 0,
      },
      rowIndex: -1,
      list: [],
      showDialog: false,
      dialogVisible_taether: false,
      formData: {
        id: "",
        date: "",
        time_start: "",
        time_end: "",
        course_content: "",
        ma_teacher: "",
        teacher_name: "",
      },

      isAdd: false,
      rules: {
        date: vali.validatorCommon.mandatory("请选择日期", "change"),
        time_start: vali.validatorCommon.mandatory("请选择开始时间", "change"),
        time_end: vali.validatorCommon.mandatory("请选择结束时间", "change"),
        teacher_name: vali.validatorCommon.mandatory(
          "请选择主课老师",
          "change"
        ),
      },
    };
  },
  watch: {
    selectRowIndex: function (val) {
      this.$refs.refTable.setCurrentRow();
      this.returnIndex();
    },
  },
  created() {
    this.getClassHour();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (this.rowIndex == rowIndex) return "hight-light-row";
      return "";
    },
    close() {
      console.log("close");
      this.formData.id = "";
      this.$refs.formDataRef.resetFields();
    },
    current_change($event) {
      this.rowIndex = -1;
      this.$emit("current_change", $event);
    },
    dateChange() {
      this.screen.page = 1;
      this.getClassHour();
    },
    // 获取教师列表
    teacherCurrentChange(row) {
      (this.formData.teacher_name = row.name),
        (this.formData.ma_teacher = row.id),
        (this.dialogVisible_taether = false);
    },
    clearTeacher() {
      this.formData.ma_teacher = "";
      this.formData.teacher_name = "";
    },
    showInfo(row) {
      this.$router.push({
        path: "/education/student-state",
        query: {
          cid: row.cid,
          date: row.date,
          time_start: row.time_start,
          time_end: row.time_end,
          name: row.name,
          course_content: row.course_content,
          teacher_name: row.teacher_name,
        },
      });
    },
    show(row) {
      this.showDialog = true;
      var cache = { ...row };
      this.$nextTick(() => {
        extend(this.formData, cache);
      });
    },
    async postPhone(row) {
      if (!this.isCanHttp) return this.$message.info("正在处理中...");
      var flag = await this.$confirm(
        "您确认要顺延吗?",
        "提示"
      ).catch((err) => { });
      if (flag != "confirm") return;
      var params = {
        cid: row.cid,
        name: row.name,
        date: row.date,
        id: row.id,
      };
      this.isCanHttp = false;
      postPhone
        .call(this, params)
        .then((res) => {
          this.isCanHttp = true;
          if (res) this.getClassHour();
        })
        .catch((err) => (this.isCanHttp = true));
    },
    async delClassHour(row) {
      if (!this.isCanHttp) return this.$message.info("正在处理中...");
      var flag = await this.$confirm(
        "您确认要删除吗?",
        "提示"
      ).catch((err) => { });
      if (flag != "confirm") return;
      var params = {
        date: row.created_at.split("")[0],
        time_start: row.time_start,
        time_end: row.time_end,
      };
      this.isCanHttp = false;
      delClassHour
        .call(this, row.id, params)
        .then((res) => {
          this.isCanHttp = true;
          if (res) this.getClassHour();
        })
        .catch((err) => (this.isCanHttp = true));
    },
    getClassHour() {
      if (this.classId) this.getClassHour_bringUp();
      else this.getClassHour_callIn();
    },
    getClassHour_callIn() {
      var params = {
        page: 1,
        date_start: "",
        date_end: "",
      };
      getClassHour_callIn
        .call(this, this.classId, params)
        .then((res) => {
          if (res) {
            this.list = res.data;
            this.page = res.current_page;
            this.total = res.total;
            this.returnIndex();
          }
        })
        .catch((err) => console.log(err));
    },
    getClassHour_bringUp() {
      var params = { ...this.screen };
      params.date &&
        params.date.length == 2 &&
        ((params.date_start = params.date[0]),
          (params.date_end = params.date[1]));
      delete params.date;
      getClassHour_bringUp
        .call(this, this.classId, params)
        .then((res) => {
          if (res) {
            this.list = res.data;
            this.formData.ma_teacher = res.ma_teacher.id;
            this.formData.teacher_name = res.ma_teacher.name;
            this.screen.page = res.current_page;
            this.pagination.total = res.total;
            this.returnIndex();
          }
        })
        .catch((err) => console.log(err));
    },
    async confirm() {
      if (!this.isCanHttp) return this.$message.info("正在处理中...");
      var bool = await this.$refs.formDataRef
        .validate()
        .catch((err) => console.log(err));
      if (!bool) return;
      if (this.formData.id) this.modifyClassHour();
      else this.addClassHour();
    },
    addClassHour() {
      var params = { ...this.formData };
      delete params.id;
      delete params.teacher_name;
      this.isCanHttp = false;
      addClassHour
        .call(this, this.classId, params)
        .then((res) => {
          this.isCanHttp = true;
          if (res) {
            this.showDialog = false;
            this.getClassHour();
          }
        })
        .catch((err) => (this.isCanHttp = true));
    },
    modifyClassHour() {
      var params = { ...this.formData };
      this.isCanHttp = false;
      modifyClassHour
        .call(this, params.id, params)
        .then((res) => {
          this.isCanHttp = true;
          if (res) {
            this.showDialog = false;
            this.getClassHour();
          }
        })
        .catch((err) => (this.isCanHttp = true));
    },
    /* 根据课时获取在已有课时的Index */
    returnIndex() {
      this.rowIndex = this.list.findIndex((item) => {
        return item.id == this.selectRowIndex;
      });
    },
  },
  computed: {
    getSelectRowID_teacher() {
      var id = "";
      this.formData.teacher_name &&
        this.formData.ma_teacher &&
        (id = this.formData.ma_teacher);
      return id;
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #888888;
}

/deep/ .el-table .hight-light-row {
  background-color: #fed90e;
}
</style>
