<template>
  <div style="">
    <div class="classInfoStyle">
      <div class="infoTitle">课时信息</div>
      <div class="infoBox flex">
        <div class="flex-1 flex-column">
          <span class="itemTitle">主课老师</span>
          <span class="itemValue">{{$route.query.teacher_name}}</span>
        </div>
        <div class="flex-1 flex-column">
          <span class="itemTitle">课程名称</span>
          <span class="itemValue">{{course ? course.name : ''}}</span>
        </div>
        <div class="flex-1 flex-column">
          <span class="itemTitle">班级名称</span>
          <span class="itemValue">{{className}}</span>
        </div>
        <div class="flex-1 flex-column">
          <span class="itemTitle">上课时间</span>
          <span class="itemValue">{{$route.query.date}} {{$route.query.time_start}}-{{$route.query.time_end}}</span>
        </div>
        <div class="flex-1 flex-column">
          <span class="itemTitle">上课内容</span>
          <span class="itemValue">{{$route.query.course_content}}</span>
        </div>
      </div>
    </div>
    <el-card class="mg-t-10">
      <div class="flex align-center justify-between">
        <div class="flex align-center">
          <div class="fontWeightB c-555 title" style="letter-spacing: 1px;">学员信息</div>
          <div class="mg-l-20 fs-12">总学员{{studentLists.length}}人,出勤{{getAttendance}}人</div>
        </div>
        <div class="fs-12">
          <span class="cursor-p" @click="callIn">申请调入</span>
          <span class="mg-l-20 cursor-p" @click="selectAllStudent">全选</span>
        </div>
      </div>
      <div class="mg-t-10 boxScroll">
        <div class="text-align-c" style="color: #999;" v-if="!isLoading">
          <i class="el-icon-loading loading-one"></i>
        </div>
        <div v-else>
          <div class="text-align-c" style="color: #999;margin-top: 65px;" v-if="studentLists.length == 0">暂无学员</div>
          <div v-else class="boxStudent flex-column flex align-center flex-center cursor-p" :class="{ 'selectStudent': selectStudentId == item.id, 'selectStudent2': selectStudentAttendanceIds.includes(item.aid) }" v-for="(item, index) in studentLists" :key='item.id' @click="getStudentWorks(item)">
            <div class="boxImage">
              <img v-if='item.avatar' :src="item.avatar" style="width: 100%;vertical-align: middle;" alt="头像" />
              <img v-else-if='item.sex == 1' src="../../../assets/image/man_student.svg" style="width: 100%;vertical-align: middle;" alt="头像" />
              <img v-else-if='item.sex == 2' src="../../../assets/image/woman_student.svg" style="width: 100%;vertical-align: middle;" alt="头像" />
            </div>
            <div class="mg-t-10 fs-12 w-per-100 textNoWarp text-align-c">{{item.name}}</div>
            <div style="color: #999;" class="fs-12" :style="{color: $param.state_attendance_student[item.a_status - 1].color}">{{$param.state_attendance_student[item.a_status - 1].label}}</div>
            <div class="boxSign" @click.stop="selectStudent(item.aid)"></div>
            <div class="boxSign2" @click.stop="selectStudent(item.aid)"></div>
            <div class="boxDel cursor-p" @click.stop="delStudent(item, index)">
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 考勤与作品 -START -->
      <div v-if="studentLists.length > 0" style="position: relative;">
        <div class="boxAttendance">
          <div class="fontWeightB title c-555 mg-b-10" style="letter-spacing: 1px;">学员出勤</div>
          <span v-for="(item, index) in $param.state_attendance_student" :key="index" class="fs-12 attendance cursor-p" :class="{ 'selectAttendance': selectAttendanceStatus == item.value }" @click="changeAttendanceStatus(item.value)">{{item.label}}</span>
          <span class="fs-12 attendance allAttendance cursor-p" @click="allAttendance">全部出勤</span>
        </div>
        <div class="pd-tb-20">
          <div class="fontWeightB title c-555 mg-b-10" style="letter-spacing: 1px;">作品上传</div>
          <upload-c :file-lists="works" @success="success" @remove="remove"></upload-c>
          <div class="boxComment mg-t-10">
            <div class="fontWeightB title c-555" style="letter-spacing: 1px;">教师点评</div>
            <el-input placeholder="教师点评" type="textarea" class="mg-t-10" :autosize='{ minRows: 13, maxRows: 13}' v-model="comment"></el-input>
            <div class="boxConfirm mg-t-10 cursor-p" @click="upStudentWorks">保存</div>
          </div>
        </div>
        <!-- /加载动画 -->
        <div v-if='!isLoading_work' class='text-align-c' style="color: #999;position: absolute;top: 0;left: 0; background-color: rgba(0, 0, 0, 0.02);width: 100%;height: 100%;">
          <i class="el-icon-loading loading-two"></i>
        </div>
      </div>
      <!-- 考勤与作品 -END -->
    </el-card>
    <!-- 请假事由 -START -->
    <el-dialog :visible.sync="leaveDialogShow" center title="请假事由" width="30%" @close="$refs.refLeaveForm.resetFields()">
      <el-form label-position="right" label-width="80px" :rules="rules" :model="leaveFormData" ref='refLeaveForm'>
        <el-form-item label="请假事由" prop="reason">
          <el-input placeholder="请假事由" v-model="leaveFormData.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="leaveDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="updateAttendance(2)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 请假事由 -END -->
    <!-- 调入学员 -START -->
    <el-dialog center width="50%" :visible.sync="callInStudentDialogShow" title="申请调入学员">
      <div class="flex">
        <el-input v-model="screen_callIn_student.name" size="small" placeholder="请输入学员姓名" @change="getStudentByCourse" clearable style="width: 30%;">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="getStudentByCourse"></i>
        </el-input>
      </div>
      <el-table :data="callInStudentLists">
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="sex" label="性别">
          <template v-slot='scope'>
            {{scope.row.sex == 1 ? '男' : (scope.row.sex == 2 ? '女' : '未知')}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="90">
          <template v-slot="scope">
            <span v-if="flagCallIn(scope.row)">已在班级内</span>
            <el-button v-else size="small" type="primary" @click='callInStudent(scope.row)'>调入</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mg-t-10" background layout="total, prev, pager, next" :current-page.sync="screen_callIn_student.page" :total="pagination.total" @current-change="getStudentByCourse">
      </el-pagination>
    </el-dialog>
    <!-- 调入学员 -END -->
  </div>
</template>
<script>
import { StringToUTF, UTFtoString } from "../../../assets/js/utf.js";

import uploadC from "../../common/iteration-upload.vue";

import {
  updateAttendance,
  getStudentClass,
  upLoadStudentWorks,
  getStudentWorks,
  allAttendance,
  getStudentByCourse,
  delStudent,
  callInStudent,
} from "../../../assets/js/API/student.js";
import { getClassInfo } from "../../../assets/js/API/class.js";

import { extend } from "../../../assets/js/util.js";

import vali from "../../../assets/js/validate.js";
export default {
  name: "StudentState",
  components: {
    uploadC,
  },
  data() {
    return {
      classInfo: {
        name: "",
        type: "",
      },
      pagination: {
        total: 0,
      },
      isLoading: false, //是否获取到班级学员 false还没获取到 true是获取到了
      isLoading_work: false, //是否获取到作品 false是没获取到 true是获取到
      formData: {
        key: "",
        token: "",
      },
      selectStudentId: "",
      selectStudentAttendanceIds: [],
      selectAttendanceStatus: "",
      selectAttendanceId: "",
      leaveFormData: {
        reason: "",
      },
      rules: {
        reason: vali.validatorCommon.mandatory("请输入请假事由"),
      },
      studentLists: [],
      leaveDialogShow: false,
      works: [],
      uploading: [],
      comment: "",
      callInStudentDialogShow: false,
      screen_callIn_student: {
        name: "",
        page: 1,
      },
      callInStudentLists: [],
      course: {
        id: "",
        name: "",
      },
      className: "",
      isCanClick: true, //控制考勤和上传作品是否调用接口 true能调 false不能调
    };
  },
  computed: {
    getAttendance() {
      var number_of_people = 0;
      this.studentLists.forEach((item) => {
        if (item.a_status == 1) number_of_people += 1;
      });
      return number_of_people;
    },
  },
  created() {
    this.getClassInfo();
    this.getStudentClass();
  },
  methods: {
    flagCallIn(row) {
      return this.studentLists.some((item) => item.id == row.id);
    },
    async callInStudent(row) {
      var confirm = await this.$confirm("您确定要调入学员到这个班级?", "提示");
      if (confirm != "confirm") return;
      var { cid, time_start, time_end, date } = this.$route.query;
      var params = {
        cid,
        sid: row.id,
        time_end,
        time_start,
        date,
        course_classification_id: this.course.id ? this.course.id : "",
      };
      this.classInfo.type == 4 && (params.type = this.classInfo.type);
      callInStudent.call(this, params).then((res) => {
        if (res) {
          this.callInStudentDialogShow = false;
          this.getStudentClass();
        }
      });
    },
    getClassInfo() {
      getClassInfo.call(this, this.$route.query.cid).then((res) => {
        if (res) {
          this.course =
            res.courseClassification instanceof Object
              ? res.courseClassification
              : {
                  id: "",
                  name: "",
                };
          extend(this.classInfo, res);
          this.className = res.name;
        }
      });
    },
    callIn() {
      this.callInStudentDialogShow = true;
      this.getStudentByCourse();
    },
    async delStudent(item, index) {
      var confirm = await this.$confirm("您确定要移除这个学员吗?", "提示");
      if (confirm == "confirm") {
        delStudent
          .call(this, {
            aid: item.aid,
            sid: item.id,
          })
          .then((res) => {
            if (res) {
              this.studentLists.splice(index, 1);
              if (item.id == this.selectStudentId) {
                this.selectStudentId = "";
                this.studentLists.length > 0 &&
                  this.getStudentWorks(
                    this.studentLists[
                      index > this.studentLists.length - 1 ? index - 1 : index
                    ]
                  );
              }
            }
          });
      }
    },
    getStudentByCourse() {
      var params = { ...this.screen_callIn_student };
      params.course_classification_id = this.course.id ? this.course.id : "";
      getStudentByCourse.call(this, params).then((res) => {
        this.callInStudentLists = res.data;
        this.pagination.total = res.total;
      });
    },
    allAttendance() {
      if (!this.isCanClick) return this.$message.info("正在处理中,请稍后再试");
      if (this.selectStudentAttendanceIds.length == 0)
        return this.$message.info("请选择要出勤的学员");
      this.$confirm("您确定要全部出勤吗", "提示")
        .then(() => {
          var params = {
            id: this.selectStudentAttendanceIds,
            status: 1,
          };
          this.isCanClick = false;
          allAttendance
            .call(this, params)
            .then((res) => {
              if (res) {
                this.selectStudentAttendanceIds.forEach((item) => {
                  var index = this.studentLists.findIndex((item1) => {
                    return item1.aid == item;
                  });
                  index != -1 && (this.studentLists[index].a_status = 1);
                });
              }
              this.isCanClick = true;
            })
            .catch((err) => (this.isCanClick = true));
        })
        .catch();
    },
    selectAllStudent() {
      var studentLists = this.studentLists;
      var arr = [];
      studentLists.forEach((item) => {
        if (!this.selectStudentAttendanceIds.includes(item.aid))
          arr.push(item.aid);
      });
      this.selectStudentAttendanceIds = [...arr];
    },
    selectStudent(sid) {
      if (this.selectStudentAttendanceIds.includes(sid)) {
        var index = this.selectStudentAttendanceIds.findIndex((item) => {
          return item == sid;
        });
        this.selectStudentAttendanceIds.splice(index, 1);
      } else this.selectStudentAttendanceIds.push(sid);
    },
    upStudentWorks() {
      if (this.comment == "") return this.$message.info("请输入对学员的点评");
      if (!this.isCanClick) return this.$message.info("正在处理中，请稍后再试");
      this.upLoadStudentWorks();
    },
    upLoadStudentWorks() {
      var { date, time_start, time_end } = this.$route.query;
      var params = {
        sid: this.selectStudentId,
        comment: StringToUTF(this.comment),
        date,
        time_start,
        time_end,
      };
      params.works = this.works.map((item) => {
        return {
          url: item.response
            ? "http://v.hipposteam.com/" + item.response.key
            : item.url || item.path,
          type: item.raw ? item.raw.type : item.type,
        };
      });
      this.isCanClick = false;
      upLoadStudentWorks
        .call(this, params)
        .then((res) => (this.isCanClick = true))
        .catch((err) => (this.isCanClick = true));
    },
    success({ lists }) {
      this.works = lists;
    },
    remove({ lists }) {
      this.works = lists;
    },
    changeAttendanceStatus(status) {
      if (!this.isCanClick) return this.$message.info("正在处理中,请稍后再试");
      if (status == this.selectAttendanceStatus) return;
      if (status == 2) this.leaveDialogShow = true;
      else this.updateAttendance(status);
    },
    async updateAttendance(status, reason) {
      var mapStatus = {
        1: "出勤",
        2: "请假",
        3: "旷课",
        4: "未处理",
      };
      await this.$confirm("您确认要更改为" + mapStatus[status] + "吗", "提示");
      var params = {
        status,
      };
      if (params.status == 2) {
        params.reason = this.leaveFormData.reason;
        var bool = await this.$refs.refLeaveForm.validate().catch((err) => {});
        if (!bool) return;
      }
      params.status == 2 && (params.reason = this.leaveFormData.reason);
      this.isCanClick = false;
      updateAttendance
        .call(this, this.selectAttendanceId, params)
        .then((res) => {
          if (res) {
            this.selectAttendanceStatus = status;
            var index = this.studentLists.findIndex((item) => {
              return item.id == this.selectStudentId;
            });
            index != -1 && (this.studentLists[index].a_status = status); //同步改变学员信息考勤状态，避免再次调用接口
            status == 2 && (this.leaveDialogShow = false);
          }
          this.isCanClick = true;
        })
        .catch((err) => (this.isCanClick = true));
    },
    getStudentClass() {
      getStudentClass
        .call(this, this.$route.query)
        .then((res) => {
          if (res) {
            this.isLoading = true;
            this.studentLists = res;
            var index = this.studentLists.findIndex((item) => {
              return item.id == this.selectStudentId;
            });
            this.studentLists.length > 0 &&
              this.getStudentWorks(this.studentLists[index == -1 ? 0 : index]);
          }
        })
        .catch((err) => (this.isLoading = true));
    },
    getStudentWorks(item) {
      if (this.selectStudentId == item.id) return;
      this.isLoading_work = false;
      this.selectStudentId = item.id;
      this.resetData();
      getStudentWorks
        .call(this, item.id, this.$route.query)
        .then((res) => {
          if (res) {
            this.selectAttendanceStatus = res.a_status;
            this.selectAttendanceId = res.id;
            this.comment = res.comment ? UTFtoString(res.comment) : "";
            res.works && (this.works = JSON.parse(res.works));
          }
          this.isLoading_work = true;
        })
        .catch((err) => (this.isLoading_work = true));
    },
    resetData() {
      this.works = [];
      this.comment = "";
    },
  },
};
</script>
<style scoped lang="less">
.classInfoStyle {
  border: 1px solid #ebeef5;
  padding: 10px 0;
  .infoTitle {
    color: #101010;
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .infoBox {
    border-top: 1px solid #ebeef5;
    padding-left: 20px;
    .itemTitle {
      padding: 10px 0;
      color: #9c9c9c;
    }
    .itemValue {
      font-weight: 700;
    }
  }
}
.c-555 {
  color: #555555;
}

.boxDel {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #999;
  text-align: center;
  line-height: 20px;
  border-radius: 2px;
}

.boxScroll {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding: 15px;
  height: 172px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.boxScroll::after {
  content: "";
  display: block;
  clear: both;
}

.selectStudent {
  border: 1px solid #fdd23e !important;
  box-shadow: 0 0 4px #fdd23e50 !important;
}

.boxStudent {
  float: left;
  width: 147px;
  height: 140px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  margin-right: 20px;
  position: relative;
  overflow: hidden;
}

.boxImage {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
}

.selectStudent2 .boxSign {
  background-color: #fdd23e !important;
}

.boxSign {
  background-color: #e6e6e6;
  /* fdd23e*/
  width: 50px;
  height: 50px;
  position: absolute;
  top: -25px;
  right: -25px;
  transform: rotate(45deg);
  z-index: 3;
}

.boxSign2 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 5;
}

.boxAttendance {
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.selectAttendance {
  background-color: #fdd23e !important;
  border: 1px solid #fdd23e !important;
  color: #fff !important;
}

.attendance {
  padding: 5px 20px;
  background-color: #fff;
  border-radius: 50px;
  margin-right: 20px;
  color: #555;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.allAttendance {
  background-color: #61aeff !important;
  border: 1px solid #61aeff;
  color: #fff;
}

.boxConfirm {
  padding: 8px 80px;
  display: inline-block;
  background-color: #fdd23e;
  color: #555;
  border-radius: 50px;
}

.el-icon-plus {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.boxFile {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  background-color: #f4f5f6;
  color: #606266;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}

.boxFile:hover {
  .fileIcon {
    opacity: 1;
  }
}

.fileIcon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  opacity: 0;
  color: #fff;
}

/deep/ .boxComment textarea:focus-within {
  border: 1px solid #fdd23e;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}

/deep/ .el-main {
  background-color: #0086b3 !important;
}

@media screen and (max-width: 1499px) {
  .title {
    font-size: 15px;
  }

  .boxStudent {
    width: 127px;
    height: 120px;
    margin-right: 10px;
  }

  .attendance {
    padding: 5px 15px;
    border-radius: 50px;
    margin-right: 10px;
  }

  .boxFile {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .fileIcon {
    font-size: 14px;
  }

  .el-icon-plus {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  /deep/ .el-upload--picture-card {
    width: 60px;
    height: 60px;
  }

  /deep/ .el-upload {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  /deep/ .avatar {
    width: 60px;
    height: 60px;
  }

  .boxConfirm {
    padding: 6px 50px;
  }

  .loading-two {
    font-size: 40px;
    margin-top: 260px;
  }

  .loading-one {
    margin-top: 25px;
    font-size: 25px;
  }

  .boxScroll {
    height: 155px;
  }
}

@media screen and (min-width: 1500px) {
  .title {
    font-size: 16px;
  }

  .loading-two {
    font-size: 60px;
    margin-top: 260px;
  }

  .loading-one {
    margin-top: 37px;
    font-size: 50px;
  }
}
</style>
