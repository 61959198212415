/* 教师配置 */
export const JOB_STATUS = [
	{
		id: 1,
		name: '在职'
	},
	{
		id: 2,
		name: '离职'
	}
]