<!-- 学员信息表格 -->
<template>
	<div>
		<!-- 表格 -->
		<el-table :data="tableData.data" @selection-change='selection_change' @current-change='current_change' class="fsMedia"
		 style="width: 100%" highlight-current-row>
			<!-- 姓名昵称 -->
			<el-table-column prop="name_nickname" label="姓名" min-width="180">
			</el-table-column>
			<!-- 姓名昵称 -->
			<!-- 性别 1  -->
			<el-table-column prop="sex" label="性别" min-width="180">
				<template v-slot='scoped'>
					<span v-if="scoped.row.sex == 1">男</span>
					<span v-if="scoped.row.sex == 2">女</span>
				</template>
			</el-table-column>
			<!-- 性别   -->
			<!-- 年龄 -->
			<el-table-column prop="age" label="年龄" min-width="180">
			</el-table-column>
			<!-- 年龄/ -->
			<!-- 联系电话 -->
			<el-table-column prop="tel" label="联系电话" min-width="180">
			</el-table-column>
			<!-- 联系电话/ -->
			<!-- 招生老师 -->
			<!-- <el-table-column
				prop="recuritTeacher"
				label="招生老师"
				min-width="180">
			</el-table-column>     -->
			<!-- 招生老师/ -->
			<!-- 登记时间 -->
			<el-table-column prop="created_at" label="登记时间" min-width="180">
			</el-table-column>
			<!-- 登记时间/ -->
			<!-- 操作 -->
			<el-table-column v-if="table_config.button.show" label="操作" min-width="60">
				<!-- <template v-slot='scoped'>
					<el-button v-if="scoped.row.status != 1" size="small" type="primary"
					@click='click_del(scoped.row)'
					>删除</el-button>
					<el-button v-if="scoped.row.status != 1" size="small" type="primary"
					@click='click_adjustment(scoped.row)'
					>调整到指定时间</el-button>
				</template> -->
			</el-table-column>
			<!-- 操作 -->
		</el-table>
		<!-- 表格/	 -->
		<!-- 分页 -->
		<pagination-c v-if="table_config.pagination.show" :currentPage='tableData.current_change' :total="tableData.total"
		 @handleCurrentChange='handleCurrentChange($event)' class="mg-t-10"></pagination-c>
		<!-- 分页/ -->
	</div>
</template>
<script>
	import paginationC from '../common/v-pagination.vue'
	import {
		getAge_range
	} from '../../assets/js/myMethods'
	export default {
		name: 'TableStudent',
		props: {
			id: {
				type: String,
				default: ''
			},
			table_config: {
				type: Object,
				default: function() {
					return {
						button: {
							show: false
						},
						pagination: {
							show: true
						}
					}
				}
			},
			type: { //获取学员的类型 1 是常规学员 2 是预约学员
				default: ''
			},
			screenKey: { //筛选条件key
				default: '' 
			}
		},
		data() {
			return {
				tableData: {}
			}
		},
		methods: {
			selection_change(seletion) {
				this.$emit('selection-change', {
					id: selection
				})
			},
			current_change(e) {
				this.$emit('current_change', {
					id: e
				})
			},
			// 更新课时数据
			async upData(page) {
				var data = await this.getStudent(page);
				this.tableData = this.upData_before(data);
			},
			// 处理请求后台的数据 --- 主要处理 姓名和名称合并 
			upData_before(data) {
				// 处理课时
				var d = data.data
				d.forEach(e => {
					e.name_nickname = e.name
				})
				// 处理分页
				return data
			},

			// 当前页码改变
			handleCurrentChange(e) {
				this.upData(e.page)
			},
			// 获取学员信息
			async getStudent(page) {
				var params = {
					page: 1,
					type: this.type,
					key: this.screenKey
				}
				page && (params.page = page)
				const {
					data: res
				} = await this.$http.get(`/student/info`, {
					params
				});
				if (res.code === 200) {
					return res.data
				}
			}
		},
		components: {
			paginationC
		},
		created() {
			this.upData();
			// this.id && this.upData()
			this.$emit('upData', {
				func: this.upData
			})
		}
	}
</script>
<style scoped lang="less">

</style>
