// 2021-04-12由FZY创建 用于班级课时请求


/* 
	传参字典
		课时
			page: 当前页
			date_start: 开始日期 YYYY-MM-DD
			date_end: 结束日期
			sid: 学生id
			id: 班级id | 课时id
			cid: 班级id
			date: 上课日期
			name: 班级名称
			course_content: 上课内容
		课时-考勤
			date_start: 开始日期 YYYY-MM-DD,
			date_end: 结束日期,
			course_classification_id: 课程id,
			a_status: 考勤状态,
			w_status: 作品上传状态,
			age_start: 起始年龄,
			age_end: 结束年龄,
			page: 当前页,
			key: 学生姓名、班级名称、老师名称,
			type: 班级类型 1- 常规班级 3- 体验班级
 */

/* 
	@describe 获取上课课时(调出用)
	@date 2021-04-12
	@by FZY
	@params
		page: Number
		date_start: Stirng
		date_end: String
	@data
		id: String | Number
 */
export async function getClassHour_bringUp(classId, params) {
	const { data: res } = await this.$http.get('/education/classTime/' + classId, {
		params
	});
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

/* 
	@describe 获取上课课时(调入用)
	@date 2021-04-12
	@by FZY
	@params
		page: Number
		sid: Number | String
 */
export async function getClassHour_callIn(params) {
	const { data: res } = await this.$http.get('/education/attendance/student', {
		params
	});
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}


/* 
	@describe 添加课时
	@date 2021-04-12
	@by FZY
	@data
		page: Number
		sid: Number | String
		course_content： String
 */
export async function addClassHour(calssId, data) {
	const { data: res } = await this.$http.post('/education/classTime/' + calssId, data);
	if (res.code === 200) {
		this.$message.success('添加课时成功')
		return res.data
	} else this.$message.error(res.msg || res.message)
}


/* 
	@describe 修改课时
	@date 2021-04-12
	@by FZY
	@data
		page: Number
		sid: Number | String
 */
export async function modifyClassHour(classHourId, data) {
	const { data: res } = await this.$http.patch('/education/classTime/' + classHourId, data);
	if (res.code === 200) {
		this.$message.success('修改课时成功')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

/* 
	@describe 顺延
	@date 2021-04-12
	@by FZY
	@data
		cid: Number
		name: String
		date: String
		id: Number
 */
export async function postPhone(data) {
	const { data: res } = await this.$http.patch('/education/classTimes/postPhone', data);
	if (res.code === 200) {
		this.$message.success('顺延成功')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

/* 
	@describe 删除课时
	@date 2021-04-12
	@by FZY
	@params
		date: String,
		time_start: String,
		time_end: String,
	@data
		classHourId: Number
 */
export async function delClassHour(classHourId, params) {
	const { data: res } = await this.$http.delete('/education/classTime/' + classHourId, {
		params
	});
	if (res.code === 200) {
		this.$message.success('删除成功')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

/* 
	@describe 获取课时---考勤
	@date 2021-04-14
	@by FZY
	@params
		date_start: String,
		date_end: String,
		course_classification_id: Number | String,
		a_status: Number | String,
		w_status: Number | String,
		age_start: Number | String,
		age_end: Number | String,
		page: Number,
		key: String,
		type: Number | String
 */
export async function getAnalysisList(params) {
	const { data: res } = await this.$http.get('/education/getNewAttendance', {
		params
	});
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}




