<template>
	<!-- 上课场地管理 -->
	<div class="">
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>机构管理</el-breadcrumb-item>
		  <el-breadcrumb-item>上课场地管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<div class="body">
				<classrooms-basicInfo></classrooms-basicInfo>
			</div>
			<!-- 表格区域/ -->
		</el-card>
	</div>
</template>

<script>
	/* 导入上课场地基本信息 */
	import classroomsBasicInfo from './form/classrooms-basicInfo.vue'
	export default{
		name: 'ClassRooms',
		data(){
			return{
				
			}
		},
		components:{
			classroomsBasicInfo
		},
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
</style>
