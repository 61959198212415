<template>
	<!-- 薪资标准 -->
	<div class="">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>机构管理</el-breadcrumb-item>
			<el-breadcrumb-item>薪资标准</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="cardHeight">
			<div class="scroll cardHeight">
				<el-collapse accordion :value='collapse'>
					<!-- 课时费收费标准 -->
					<el-collapse-item name='classFee'>
						<template slot="title">
							<span class="title">课时费收费标准</span>
							<!-- <i class="header-icon el-icon-info"></i> -->
						</template>
						<div class="">
							<div class="flex align-center">
								<div style="width: 40px;margin-right: 10px;box-sizing: border-box;" class="line"></div>
								<div class='cursor-p add' @click="dialogShowClassFee = true" style="margin: 0 0 10px 0;">添加课时费收费标准</div>
								<div style="" class="line flex-1_1 mg-l-10"></div>
							</div>
							<div class="fs-14 text-align-c h-ab-60" style="color: #aaa;line-height: 60px;" v-if="dataFormClassFee.length == 0">暂无课时费收费标准,请联系管理人员添加</div>
								
							<div v-else v-for="(item, index) in dataFormClassFee" :key='index' class="flex align-center" :class="{'mg-t-10': index > 0}">
								<div style="width: 40px;" class="text-align-r">{{item | getLabel}}</div>
								<div class="flex-1 mg-l-10">
									<el-input size="medium" v-model="dataFormClassFee[index].classfee_standard" @blur="modifyClassFee(item, index)" @focus="temporaryClassfee_standard = item.classfee_standard">
										<template slot="append">元/课时</template>
									</el-input>
								</div>
							</div>
						</div>
					</el-collapse-item>
					<!-- 奖励与提成 -->
					<el-collapse-item name="reward">
						<template slot="title">
							<span class="title">奖励与提成</span>
							<!-- <i class="header-icon el-icon-info"></i> -->
						</template>
						<el-form label-position="right" label-width="130px" ref='refFormRewardOther' :rules="rules" :model="dataFormReward">
							<el-form-item label="新学员报名提成" prop="new_registration_student">
								<el-input size="medium" v-model='dataFormReward.new_registration_student'>
									<template slot="append">%</template>
								</el-input>
							</el-form-item>
							<el-form-item label="老学员续费提成" prop="old_registration_student">
								<el-input size="medium" v-model='dataFormReward.old_registration_student'>
									<template slot="append">%</template>
								</el-input>
							</el-form-item>
							<el-form-item label="全勤奖" prop="full_time">
								<el-input size="medium" v-model="dataFormReward.full_time">
									<template slot="append">元</template>
								</el-input>
							</el-form-item>
							<el-form-item label="月度备课奖励" prop="bonus_monthly_lesson">
								<el-input size="medium" v-model="dataFormReward.bonus_monthly_lesson">
									<template slot="append">元</template>
								</el-input>
							</el-form-item>
							<el-form-item label="月度回访奖励" prop="bonus_monthly_visit">
								<el-input size="medium" v-model="dataFormReward.bonus_monthly_visit">
									<template slot="append">元</template>
								</el-input>
							</el-form-item>
						</el-form>
						<div class="flex align-center">
							<div style="width: 120px;margin-right: 10px;box-sizing: border-box;" class="line"></div>
							<el-button type="primary" size="medium" @click='modifySalaryTemplateOther'>保存</el-button>
							<div style="" class="line flex-1_1 mg-l-10"></div>
						</div>						
					</el-collapse-item>
					<!-- 增值福利 -->
					<el-collapse-item name='salary'>
						<template slot="title">
							<span class="title">增值福利</span>
							<!-- <i class="header-icon el-icon-info"></i> -->
						</template>
						<div class="">
							<!-- 月度转化奖 start -->
							<div class="flex align-center">
								<div class="subTitle">月度转化奖</div>
								<div class='cursor-p add' @click="showInfoReward('month', 'add')" style="">添加月度转化奖项</div>
								<div class="line flex-1_1 mg-l-10"></div>
							</div>
							<div style="position: relative;">
								<el-table :data="awardMonth">
									<el-table-column label="">
										<template v-slot='scope'>
											<div class="flex align-center" v-if="scope.row.name[1] == 999">
												月转化 <span class="tag">≥ {{scope.row.name[0]}}</span>人
											</div>
											<div class="flex align-center" v-else>月转化<span class="tag">{{scope.row.name[0]}}</span>-<span
												 class="tag">{{scope.row.name[1]}}</span>人</div>											
										</template>
									</el-table-column>
									<el-table-column label="奖励(元)" width="150">
										<template v-slot="scope">
											{{scope.row.money}}
										</template>
									</el-table-column>
									<el-table-column label="操作" width="160">
										<template v-slot='scope'>
											<el-button type="primary" size="mini" @click='showInfoReward("month", "modify", scope.row)'>编辑</el-button>
											<el-button type="primary" size="mini" @click="deleteSalaryTemplate(scope.row, 'month')">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<!-- 月度转化奖 end -->
							<!-- 季度转介绍奖 start -->
							<div class="flex align-center mg-t-20">
								<div class="subTitle">季度转介绍奖</div>
								<div class='cursor-p add' @click="showInfoReward('quarterly', 'add')" style="">添加季度转介绍奖项</div>
								<div class="line flex-1_1 mg-l-10"></div>
							</div>
							<div style="position: relative;">
								<el-table :data="awardQuarterly">
									<el-table-column label="">
										<template v-slot='scope'>
											<div class="flex align-center" v-if="scope.row.name[1] == 999">
												转介绍 <span class="tag">≥ {{scope.row.name[0]}}</span>人
											</div>
											<div class="flex align-center" v-else>转介绍<span class="tag">{{scope.row.name[0]}}</span>-<span
												 class="tag">{{scope.row.name[1]}}</span>人</div>											
										</template>
									</el-table-column>
									<el-table-column label="奖励(元)" width="150">
										<template v-slot="scope">
											{{scope.row.money}}
										</template>
									</el-table-column>
									<el-table-column label="操作" width="160">
										<template v-slot='scope'>
											<el-button type="primary" size="mini" @click='showInfoReward("quarterly", "modify", scope.row)'>编辑</el-button>
											<el-button type="primary" size="mini" @click="deleteSalaryTemplate(scope.row, 'quarterly')">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<!-- 季度转介绍奖 end -->
							<!-- 半年度续费奖 start -->
							<div class="flex align-center mg-t-20">
								<div class="subTitle">半年度续费奖</div>
								<div class='cursor-p add' @click="showInfoReward('half', 'add')" style="">添加半年度续费奖项</div>
								<div class="line flex-1_1 mg-l-10"></div>
							</div>
							<div style="position: relative;">
								<el-table :data="awardSemiannual">
									<el-table-column label="">
										<template v-slot='scope'>
											<div class="flex align-center" v-if="scope.row.name[1] == 100">
												<span style="color: #aaaaaa;">续费率</span> <span style="margin: 0 -5px 0 5px;">＞</span><span class="tag">{{scope.row.name[0]}}%</span>
											</div>
											<div class="flex align-center" v-else>
												<span class="tag">{{scope.row.name[0]}}%</span>
												<span style="margin: 0 5px 0 -5px">＜</span>
												<span style="color: #aaaaaa;">续费率</span>
												<span style="margin: 0 5px 0 5px">≥</span>
												<span class="tag">{{scope.row.name[1]}}%</span>
											</div>											
										</template>
									</el-table-column>
									<el-table-column label="奖励(元)" width="150">
										<template v-slot="scope">
											{{scope.row.money}}
										</template>
									</el-table-column>
									<el-table-column label="操作" width="160">
										<template v-slot='scope'>
											<el-button type="primary" size="mini" @click='showInfoReward("half", "modify", scope.row)'>编辑</el-button>
											<el-button type="primary" size="mini" @click="deleteSalaryTemplate(scope.row, 'half')">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<!-- 半年度续费奖 end -->
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</el-card>
		<!-- 奖励详细信息 -->
		<el-dialog :visible.sync="dialogShowReward" :title="formOption.title" center width="30%" @close="close('reward')">
			<el-form :model="formReward" label-position="right" label-width="80px" :rules="rules" ref='refFormReward'>
				<el-row>
					<el-col :span="12">
						<el-form-item :label="formOption.label" prop="name0">
							<el-input placeholder="" v-model="formReward.name0" @blur="blur1">
								<template slot="append">{{formOption.slot}}</template>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="——" prop="name1" class="name1">
							<el-input v-model="formReward.name1" @blur="blur2">
								<template slot="append">{{formOption.slot}}</template>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="奖励" prop="money">
					<el-input v-model="formReward.money"></el-input>
				</el-form-item>
			</el-form>
			<div class="flex">
				<p style="width: 68px;text-align: right;">已有范围</p>
				<div class="range">
					<div class='rangeTag' v-for="(item, index) in range" :key='index'>
						<div v-if="formReward.type == 'half'">
							<span v-if="item[1] == 100">≥{{item[0]}}</span>
							<span v-else>{{item[0]}}-{{item[1]}}</span>
						</div>
						<div v-else>
							<span v-if="item[1] == 999">≥{{item[0]}}</span>
							<span v-else>{{item[0]}}-{{item[1]}}</span>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogShowReward = false">取 消</el-button>
				<el-button type="primary" @click="addORmodifyReward">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 课时费 -->
		<el-dialog title="添加课时费收费标准" :visible.sync="dialogShowClassFee" width="30%" center @close='close("classFee")'>
			<el-form :model='classFee' label-position='right' label-width='80px' ref='refFormClassFee' :rules="rules">
				<el-form-item label='星级' prop="level">
					<level-select-c :select.sync='classFee.level' :defaultSelect='classFee.level' :haveLevel='haveLevel'></level-select-c>
				</el-form-item>
				<el-form-item label='课时费' prop="classfee_standard">
					<el-input v-model='classFee.classfee_standard'></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogShowClassFee = false">取 消</el-button>
				<el-button type="primary" @click="addClassFee">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import validate from '../../assets/js/validate.js'
	import {
		addSalaryTemplate,
		deleteSalaryTemplate,
		modifySalaryTemplate,
		getSalaryTemplate,
		addSalaryTemplateOther,
		modifySalaryTemplateOther,
		getClassFee,
		modifyClassFee,
		delClassFee,
		addClassFee
	} from '../../assets/js/API/salary.js'
	import {
		extend
	} from '../../assets/js/util.js'
	import levelSelectC from '../common/v-level-select.vue'
	const validatorCommon = validate.validatorCommon;
	const checkRange = validate.checkRange;
	var rangeM,
		rangeQ,
		rangeH;
	export default {
		name: 'ClassFee',
		data() {
			return {
				haveLevel: [],
				collapse: 'classFee',
				classFee: {
					level: '',
					classfee_standard: ''
				},
				range: [],
				name1Required: false,
				formOption: {
					label: '',
					slot: '',
					title: ''
				},
				isChangeName1: false,
				isChangeName0: false,
				addORmodify: '',
				dialogShowReward: false,
				dialogShowClassFee: false,
				dataFormReward: {
					new_registration_student: '',
					old_registration_student: ''
				},
				dataFormReward: {
					new_registration_student: '',
					old_registration_student: '',
					full_time: '',
					bonus_monthly_lesson: '',
					bonus_monthly_visit: '',
					id: ''
				},
				dataFormClassFee: [],
				temporaryClassfee_standard: 0,
				rules: {
					new_registration_student: [
						...validatorCommon.mandatory('请输入提成比例').concat(validatorCommon.price),
						{
							validator: (rule, value, callback) => {
								if (value < 0 || value > 100) return callback(new Error('请输入0-100之间的数'));
								callback()
							}
						}
					],
					old_registration_student: [
						...validatorCommon.mandatory('请输入提成比例').concat(validatorCommon.price),
						{
							validator: (rule, value, callback) => {
								if (value < 0 || value > 100) return callback(new Error('请输入0-100之间的数'));
								callback()
							}
						}
					],
					level: [
						{ required: true, message: '请联系管理人员添加', trigger: 'change'},
					],
					classfee_standard: validatorCommon.isPrice,
					field4: validatorCommon.isPrice,
					field5: validatorCommon.isPrice,
					full_time: validatorCommon.isPrice,
					bonus_monthly_lesson: validatorCommon.isPrice,
					bonus_monthly_visit: validatorCommon.isPrice,
					field9: validatorCommon.isPrice,
					new_registration_student0: validatorCommon.isPrice,
					name0: [
						...validatorCommon.mandatory('请输入'),
						{
							validator: (rule, value, callback) => {								
								var formReward = this.formReward;
								if (value == '') {
									this.isChangeName0 = false;
									return callback()
								}
								if (formReward.type == 'half') {
									var reg = /^[\d]+(?:\.[\d]{1,2})?$/;
									if (!reg.test(value) || value < 0 || value > 100) {
										this.isChangeName0 = false;
										return callback(new Error('请输入0-100之间的数,小数位最多2位'))
									}								
								} else {
									var reg =  /^[\d]*$/;									
									if ( !reg.test(value) || value < 0 || value > 999) {
										this.isChangeName0 = false;
										return callback(new Error('请输入0-999之间的整数'))
									}	
								}
								var have,
									range = formReward.type == 'month' ? rangeM : (formReward.type == 'quarterly' ? rangeQ : rangeH),
									type = formReward.type == 'half' ? true : false;
								if (this.addORmodify == 'add') {
									have = range.isHave(formReward.name0, 'add', type);
								} else if (this.addORmodify == 'modify') {
									have = range.isHave(formReward.name0, 'modify', type);
								}
								if (have.bool) {
									this.isChangeName0 = false;
									return callback(new Error(have.value));
								}
								if (formReward.name1 != '' && formReward.name0 - 0 > formReward.name1 - 0) {
									this.isChangeName0 = false;
									return callback(new Error('不能大于' + formReward.name1))
								}
								if (formReward.name0 == '' || formReward.name1 == '') {
									this.isChangeName0 = true;
									return callback();
								}
								var basis = [formReward.name0, formReward.name1],
									haveRange;
								if (this.addORmodify == 'add') {
									haveRange = range.isHave(basis, 'add', type);
								} else if (this.addORmodify == 'modify') {
									haveRange = range.isHave(basis, 'modify', type)
								}
								if (haveRange.bool) {
									this.isChangeName0 = false
									return callback(new Error(haveRange.value))
								}								
								this.isChangeName0 = true;
								callback()
							},
							trigger: ['change', 'blur']
						}
					],
					name1: [
						{
							validator: (rule, value, callback) => {
								var formReward = this.formReward;
								if (this.name1Required) {
									if (value == '') {
										this.isChangeName1 = false;
										return callback(new Error('请输入'))
									}
								}								
								if (formReward.type == 'half') {
									var reg = /^([\d]+(?:\.[\d]{1,2})?)*$/;
									if (!reg.test(value) || value < 0 || value > 100) {
										this.isChangeName1 = false;
										return callback(new Error('请输入0-100之间的数,小数位最多2位'))
									}								
								} else {
									var reg =  /^[\d]*$/;									
									if ( !reg.test(value) || value < 0 || value > 999) {
										this.isChangeName1 = false;
										return callback(new Error('请输入0-999之间的整数'))
									}	
								}								
								var have,
									range = formReward.type == 'month' ? rangeM : (formReward.type == 'quarterly' ? rangeQ : rangeH),
									type = formReward.type == 'half' ? true : false
								if (this.addORmodify == 'add') {
									have = range.isHave(formReward.name1, 'add', type);
								} else if (this.addORmodify == 'modify') {
									have = range.isHave(formReward.name1, 'modify', type);
								}
								if (have.bool) {
									this.isChangeName1 = false;
									return callback(new Error(have.value));
								}
								if (formReward.name0 == '') {
									this.isChangeName1 = true;
									return callback()
								}
								if (formReward.name0 != '' && formReward.name1 != '' && formReward.name0 - 0 > formReward.name1 - 0) {
									this.isChangeName1 = false;
									return callback(new Error('不能小于' + formReward.name0))
								}
								var name1 = formReward.name1 == '' ? (formReward.type == 'half' ? 100 : 999) : formReward.name1 - 0;																				
								var basis = [formReward.name0, formReward.name1],
									haveRange;								
								if (this.addORmodify == 'add') {
									haveRange = range.isHave(basis, 'add', type);
								} else if (this.addORmodify == 'modify') {
									haveRange = range.isHave(basis, 'modify', type)
								}
								if (haveRange.bool) {
									this.isChangeName1 = false;
									return callback(new Error(haveRange.value))
								}
								this.isChangeName1 = true;
								return callback()
							},
							trigger: ['change', 'blur']
						}
					],
					money: validatorCommon.isPrice
				},
				formReward: {
					name: [],
					name0: '',
					name1: '',
					money: '',
					type: ''
				},
				awardMonth: [],
				awardQuarterly: [],
				awardSemiannual: [],
				selectAwardId: '',
				isCanConfirmSalaryTemplateOther: true
			}
		},
		methods: {
			modifyClassFee(item, index) {
				var data = this.dataFormClassFee[index];
				if (!(/^[\d]+(?:\.[\d]+)*$/.test(data.classfee_standard))){
					data.classfee_standard = this.temporaryClassfee_standard;
					return this.$message.error('课时费只允许整数或小数')
				}
				if (data.classfee_standard == this.temporaryClassfee_standard) return;
				modifyClassFee.call(this, this.dataFormClassFee[index], item.id)
					.then(res => {
						this.getClassFee()
					})
					.catch(err => {})
			},
			async addClassFee() {
				var bool = await this.$refs.refFormClassFee.validate().catch( err => {});
				if(!bool) return;
				addClassFee.call(this, this.classFee)
					.then(res => {
						this.dialogShowClassFee = false;
						this.getClassFee()
					})
					.catch(err => {})
			},
			getClassFee() {
				getClassFee.call(this)
					.then(res => {
						this.dataFormClassFee = res.data;
						this.dataFormClassFee.sort( (two, one) => {
							return two.level - one.level
						})
						this.dataFormClassFee.forEach( item => {
							this.haveLevel.push(item.level)
						});
					})
					.catch(err => {})
			},
			async modifySalaryTemplateOther() {
				var bool = await this.$refs.refFormRewardOther.validate().catch( err => {});
				if (!bool) return;
				if (this.isCanConfirmSalaryTemplateOther) {
					this.isCanConfirmSalaryTemplateOther = false;
					setTimeout ( () => {
						this.isCanConfirmSalaryTemplateOther = true
					}, 1500)
					var data = this.dataFormReward;
					if (data.id) { //有id时修改
						modifySalaryTemplateOther.call(this, this.dataFormReward)
							.then(res => {
							   this.getSalaryTemplate()
							})
							.catch(err => {})
					} else { //没有就时添加
						this.addSalaryTemplateOther()
					}
				} else {
					this.$message.info('请稍后再试')
				}
								
			},
			addSalaryTemplateOther() {
				var params = {
					new_registration_student: '',
					old_registration_student: '',
					bonus_monthly_lesson: '',
					bonus_monthly_visit: '',
					full_time: ''
				}
				extend(params, this.dataFormReward);
				addSalaryTemplateOther.call(this, params)
									  .then(res => {
									      this.getSalaryTemplate()
									  })
									  .catch(err => {})
			},
			blur1() {
				var formReward = this.formReward,
					range = formReward.type == 'month' ? rangeM : (formReward.type == 'quarterly' ? rangeQ : rangeH),
					type = formReward.type == 'half' ? true : false;
				if (formReward.name1 == '' && this.isChangeName0) {
					if (this.addORmodify == 'add') {
						formReward.name1 = range.find(formReward.name0, 'add', 'add',  type);
					} else if (this.addORmodify == 'modify') {
						formReward.name1 = range.find(formReward.name0, 'add', 'modify', type);
					}
					this.isChangeName0 = false
				}
			},
			blur2() {
				var formReward = this.formReward,
					range = formReward.type == 'month' ? rangeM : (formReward.type == 'quarterly' ? rangeQ : rangeH),
					type = formReward.type == 'half' ? true : false;
				if (formReward.name0 == '' && this.isChangeName1) {
					if (this.addORmodify == 'add') {
						formReward.name0 = range.find(formReward.name1, 'reduce', 'add', type);
					} else if (this.addORmodify == 'modify') {
						formReward.name0 = range.find(formReward.name1, 'reduce', 'modify', type);
					}					
					this.isChangeName1 = false
				}
			},
			close(key) {
				if (key == 'reward') {
					this.$refs.refFormReward.resetFields();
					this.formReward.name0 = '',
						this.formReward.name1 = '';
					this.formReward.money = ''
				} else if (key == 'classFee') {
					this.$refs.refFormClassFee.resetFields()
				}				
			},
			showInfoClassFee() {
				this.dialogShowClassFee = true
			},
			showInfoReward(key, type, row) {
				if (type == 'add') {
					this.range = key == 'month' ? this.getRange(this.awardMonth) : (key == 'quarterly' ? this.getRange(this.awardQuarterly) : this.getRange(this.awardSemiannual))
				} else if (type == 'modify') {
					this.formReward.name0 = row.name[0];
					this.formReward.name1 = row.name[1];
					this.formReward.money = row.money;
					this.selectAwardId = row.id;
					this.range = key == 'month' ? this.getRange(this.awardMonth, row.name) : (key == 'quarterly' ? this.getRange(this.awardQuarterly, row.name) : this.getRange(this.awardSemiannual, row.name))
				}
				this.dialogShowReward = true;
				this.formReward.type = key;
				this.addORmodify = type;
				this.formOption.title = key == 'month' ? '添加月度转化奖项' : (key == 'half' ? '添加半年度续费奖项' : '添加季度转介绍奖项');
				this.formOption.label = key == 'month' ? '月转化' : (key == 'half' ? '续费率' : '转介绍');
				this.formOption.slot = (key == 'month' || key == 'quarterly') ? '人' : '%';
				var range = this.formReward.type == 'month' ? rangeM : (this.formReward.type == 'quarterly' ? rangeQ : rangeH);								
				if (type == 'modify') {
					range.getBssis_modify(row.name);
				}
				var basis = type == 'add' ? range.BASIS : range.BASIS_MODIFY,
					max = range.MAX;
				this.name1Required = basis.some( item => {
					if (key == 'half' && item[1] == 100) return true
					if ((key == 'month' || key == 'quarterly') && item[1] == 999) return true
				})
			},
			async addORmodifyReward() {
				var bool = await this.$refs.refFormReward.validate().catch(err => {})
				if (!bool) return;
				var data = this.formReward;
				var params = {
					name: [data.name0, data.name1 == '' ? (data.type == 'half' ? 100 : 999) : data.name1],
					money: data.money,
					type: data.type
				}
				if (this.addORmodify == 'add') {
					var res = await addSalaryTemplate.call(this, params);
					if (res) {
						this.dialogShowReward = false;
						this.getSalaryTemplate()
					}
				} else if (this.addORmodify == 'modify') {
					params.id = this.selectAwardId;
					var res = await modifySalaryTemplate.call(this, params);
					if (res) {
						this.dialogShowReward = false;
						this.getSalaryTemplate()
					}
				}
			},
			async deleteSalaryTemplate(row, key) {
				this.$confirm('您确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var res = await deleteSalaryTemplate.call(this, {
						id: row.id,
						type: key
					});
					if (res) {
						this.getSalaryTemplate()
					}

				}).catch(() => {})
			},
			async getSalaryTemplate() {
				var res = await getSalaryTemplate.call(this);
				if (res) {
					this.awardMonth = this.sort(res.awardMonth);
					this.awardQuarterly = this.sort(res.awardQuarterly);
					this.awardSemiannual = this.sort(res.awardSemiannual);
					res.awardOther.length > 0 && extend(this.dataFormReward, res.awardOther[0]);
					rangeM = new checkRange(...this.getR(this.awardMonth), {
						max: '',
						min: 1
					});
					rangeQ = new checkRange(...this.getR(this.awardQuarterly), {
						max: '',
						min: 1
					});
					rangeH = new checkRange(...this.getR(this.awardSemiannual), {
						max: '',
						min: 0
					});
				}
			},
			sort (arr) {
				arr.sort((two, one) => {
					return two.name[0] - one.name[0]
				});
				return arr
			},
			getR(table) {
				var data = [];
				table.forEach(item => {
					data.push(item.name)
				})
				return data
			},
			getRange(sorce, reduce) {
				sorce = [...sorce];
				var range = [];
				if (reduce) { //编辑
					sorce.forEach(item => {
						if (item.name[1] != reduce[1]) range.push(item.name)
					})
				} else { //添加

					sorce.forEach(item => {
						range.push(item.name)
					})
				}
				return range
			}
		},
		components: {
			levelSelectC
		},
		filters: {
			getLabel(val) {
				const LEVEL = ['', '一星', '二星', '三星', '四星', '五星', '六星', '七星', '八星', '九星', '十星']
				return LEVEL[val.level]
			}
		},
		created() {
			this.getSalaryTemplate();
			this.getClassFee()
			// this.addSalaryTemplateOther()
		}
	}
</script>

<style lang="less" scoped>
	.line {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
	.cardHeight {
		height: calc(100vh - 154px);
	}

	.scrollHeightLeft {
		height: calc(100vh - 214px - 61px - 160px);
	}

	.scrollHeightRight {
		height: calc(100vh - 204px - 260px);
	}

	.boxLeft {
		padding: 10px;
		box-sizing: border-box;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		height: calc(100vh - 184px);
	}

	.scroll {
		overflow-y: auto;
	}

	.boxRight {
		padding: 10px;
		box-sizing: border-box;
		height: calc(100vh - 184px);
	}

	.title {
		font-size: 17px;
		color: rgb(63 77 125) !important;
		font-weight: bold;
	}

	.subTitle {
		width: 90px;
		margin-left: 10px;
		font-size: 14px;
		font-weight: bold;
	}

	.add {
		background-color: rgb(123 138 191);
		color: #ffffff;
		font-size: 12px;
		padding: 2px 8px;
		border-radius: 4px;
		margin-left: 30px;
	}

	.add:hover {
		opacity: 1
	}

	.tag {
		margin: 0 5px;
		color: rgb(107 192 243);
	}

	.range {
		margin-left: 12px;
		flex: 1;
		margin-top: -5px;
	}

	.range:::after {
		content: '';
		display: block;
		clear: both;
	}

	.rangeTag {
		float: left;
		background-color: rgb(105 143 187);
		border-radius: 3px;
		color: #ffffff;
		padding: 1px 5px;
		margin-right: 10px;
		margin-top: 5px;
	}

	/deep/.name1 .el-form-item__label {
		text-align: center;
		transform: translate(6px, 0);
	}
</style>
