<template>
  <div class="app-container">
    <el-button icon="el-icon-plus" type="primary" size="small" @click="add">添加</el-button>
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column prop="name" label="主体名称">
      </el-table-column>
      <el-table-column prop="entity_name" label="关联校区" align="center">
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center">
        <template slot-scope="scoped">
          {{scoped.row.remark?scoped.row.remark:'-'}}
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="操作" align="center">
        <template slot-scope="scoped">
          <el-button type="primary" size="small" @click="edit(scoped.row)">编辑</el-button>
          <el-button type="danger" size="small" @click="deleteRow(scoped.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%" @closed="dialogBeforeClose">
      <div>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item prop="name" label="主体名称:" size="mini">
            <el-input v-model="form.name" style="width:250px" placeholder="请输入主体名称"></el-input>
          </el-form-item>
          <el-form-item prop="school_id" label="关联校区:" v-if="dialogTitle=='添加主体'" size="mini">
            <el-select v-model="form.school_id" style="width:250px" placeholder="请选择">
              <el-option v-for="(item,index) in optionList" :key="'option'+index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="school_entity" label="关联校区:" v-else size="mini">
            <el-select v-model="form.school_entity" style="width:250px" placeholder="请选择">
              <el-option v-for="(item,index) in optionList" :key="'option'+index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="form.remark" style="width:300px" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSchooleList, addEntity, upEntity, delEntity } from '../../api/financial';
import {
  extend
} from '../../assets/js/util.js'
export default {
  data() {
    return {
      tableData: [],
      dialogTitle: '',
      dialogVisible: false,
      form: {
        name: '',
        school_id: '',
        remark: '',
        school_entity: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入主体名称', trigger: 'blur' },
        ],
        school_id: [
          { required: true, message: '请选择关联校区', trigger: 'change' }
        ],
        school_entity: [
          { required: true, message: '请选择关联校区', trigger: 'change' }
        ],
      },
      listParams: {
        page: 1,
        number: 999999,
        is_entity: 1
      },
      optionList: [],
      old: '',
    }
  },
  methods: {
    add() {
      this.dialogTitle = '添加主体'
      this.dialogVisible = true
    },
    edit(val) {
      this.form.name = val.name
      this.form.remark = val.remark
      this.form.school_id = val.id
      this.form.school_entity = val.school_entity
      this.old = val.school_entity
      this.dialogTitle = '编辑主体'
      this.dialogVisible = true
    },
    dialogBeforeClose() {
      this.$refs.form.resetFields();
      this.form = {
        name: '',
        school_id: '',
        remark: '',
        school_entity: ''
      }
      this.old = ''
      this.dialogVisible = false;
    },
    getTableData() {
      getSchooleList(this.listParams).then(res => {
        this.tableData = res.data.data.data
      }).catch(err => console.log(err))
    },
    getOptionList() {
      getSchooleList({
        page: 1,
        number: 999999,
      }).then(res => {
        this.optionList = res.data.data.data
      }).catch(err => console.log(err))
    },
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.dialogTitle == '编辑主体') {
            let old = this.old
            upEntity({
              ...this.form,
              old,
            }).then(res => {
              if (res.data.code == 200) {
                this.$message.success('编辑主体校区成功')
                this.getTableData()
              } else {
                this.$message.error('编辑主体校区失败')
                return
              }
            })
          } else {
            addEntity(this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success('添加主体校区成功')
                this.getTableData()
              } else {
                this.$message.error('添加主体校区失败')
                return
              }
            })
          }
          this.dialogVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    deleteRow(val) {
      this.$confirm('确定删除主体?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        delEntity({ school_id: val.id }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.getTableData()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    }
  },
  created() {
    this.getTableData()
    this.getOptionList()
  }
}
</script>

<style lang="scss" scoped>
</style>
