import request from '../request/request'
export const getSchooleList = (params) => {
  return request({
    url: '/organization/schoole',
    method: 'get',
    params
  })
}
export const addEntity = (data) => {
  return request({
    url: '/organization/financial/addEntity',
    method: 'post',
    data
  })
}
export const upEntity = (data) => {
  return request({
    url: '/organization/financial/upEntity',
    method: 'post',
    data
  })
}
export const delEntity = (params) => {
  return request({
    url: '/organization/financial/delEntity',
    method: 'DELETE',
    params
  })
}