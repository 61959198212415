<!-- 添加班级 -->
<template>
	<div>
		<div v-show="loading" class='loadingHeight boxLoading'>
			<div style="" class="loading">
				<i class="el-icon-loading" style="display: block;font-size: 40px;"></i>
				<div>数据正在存储，请耐心等待...</div>
			</div>
		</div>
		<!-- 面包屑 -->
		<bread-c :value_breadcrumb='bread'></bread-c>
		<el-form :model="addClassData" label-position="right" :rules="rules" ref="refForm" :inline="true" label-width="80px"
		 class="mg-t-20">
			<el-row>
				<el-col :span="11">
					<el-form-item label="班级名称" prop="name">
						<el-input type="text" placeholder="课程名称-周几-上课时间" v-model="addClassData.name" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="11" :offset='2'>
					<el-form-item label="课程" prop="course_classification.id">
						<el-input type="text" :disabled="addClassData.type == 4" :placeholder="addClassData.type == 4 ? '活动班级不需要选择课程' : '请选择课程'" v-model="addClassData.course_classification.name" readonly @focus="show('课程')"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="上课场地" prop="classRoom.id">
						<el-input type="text" placeholder="请选择上课场地" v-model="addClassData.classRoom.name" readonly @focus="show('上课场地')"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="11" :offset='2'>
					<el-form-item label="班级容量" prop="classCapacity">
						<!-- '请输入班级容量,不能超过' + classCapacity + '人' -->
						<el-input type="text" placeholder="请输入班级容量" v-model="addClassData.classCapacity">
							<template slot="append">人</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="上课时间" prop="class_time" class="flex w-per-100">
						<el-input type="text" placeholder="请选择上课的时间" @focus="showCourseTime" :value="addClassData.class_time" class="w-per-100">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="11" :offset='2'>
					<el-form-item label="班级类型" prop="type" class="flex">
						<el-select placeholder="请选择班级类型" style="width: 100%;" v-model="addClassData.type" disabled>
							<el-option v-for="item in classTypeLists" :key=" 'classType' + item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="开班日期" prop="date">
						<el-date-picker v-model="addClassData.date" type="daterange" range-separator="至" start-placeholder="开始日期" @change="$event == null && (minDate = '')"
						 end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;" :picker-options="pickerOptions">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="11" :offset='2'>
					<el-row class="">
						<el-col :span="11">
							<el-form-item label="年龄范围" prop="age_start" class="flex">
								<el-input type="text" v-model="addClassData.age_start" placeholder="请输入起始年龄">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11" :offset='2'>
							<el-form-item prop="age_end">
								<el-input type="text" v-model="addClassData.age_end" placeholder="请输入截止年龄"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="课时总数" prop="lesson">
						<el-input type="text" v-model="addClassData.lesson"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="11" :offset='2'>
					<el-form-item label="消耗课时" prop="consume_lesson">
						<el-input type="text" v-model="addClassData.consume_lesson"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="主课老师" prop="teacher.id">
						<el-input type="text" placeholder="请选择主课老师" v-model="addClassData.teacher.name" readonly @focus="show('主课老师')"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="11" :offset='2'>
					<el-form-item label="配课老师" prop="mainTeacher.id">
						<el-input type="text" placeholder="请选择配课老师" v-model="addClassData.mainTeacher.name" readonly @focus="show('配课老师')"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="备选老师" prop="alternativeTeacher.id">
						<el-input type="text" placeholder="请选择配选老师" v-model="addClassData.alternativeTeacher.name" readonly @focus="show('备选老师')"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div class="mg-t-10 flex align-center justify-between">
			<div></div>
			<el-button type="primary" style="width: 30%;margin-right: 20px;" @click="confirm">添加班级</el-button>
		</div>
		<el-dialog :visible.sync="visible" title="老师" width="50%" :title="dialogTitle" center>
			<components :is='getComponent' :is-add-class='1' :operation="false" :isHightlight="true" @current_change='currentChange'
			 ref='refComponents' :selectRowIndex='getSelectRowIndex' class='mg-t-10'></components>
		</el-dialog>
		<!-- /上课时间 -->
		<course-time-c v-if="courseTimeVisible" ref="courseTimeRef" @sub="sub" :week='week' :startTime='startTime' :endTime='endTime'></course-time-c>
	</div>
</template>
<script>
	import breadC from '../../common/v-breadcrumb.vue'
	import classRoomC from '../../common/iteration-classroom-table.vue'
	import courseC from '../../common/iteration-course-table.vue'
	import teacherC from '../../common/iteration-teacher-table.vue'
	import courseTimeC from '../../common/v-courseTime.vue'
	import vali from '../../../assets/js/validate.js'
	import {
		extend
	} from '../../../assets/js/util.js'
	import {
		formatDate,
		WEEKMAP
	} from '../../../assets/js/myMethods.js'
	import {
		addClass
	} from '../../../assets/js/API/class.js'
	import {
		CLASS_TYPE
	} from '../../../assets/js/CONFIG/class.js'
	export default {
		name: 'AddClass',
		components: {
			breadC,
			classRoomC,
			courseC,
			teacherC,
			courseTimeC
		},
		props: ['type'], //type创建的班级类型1- 正式班 3- 体验班 4- 活动班
		data() {
			return {
				classTypeLists: CLASS_TYPE,
				courseTimeVisible: false,
				visible: false,
				dialogTitle: '',
				pickerOptions: {
					disabledDate: (val) => {
						if (this.addClassData.type == 3) {
							if (this.minDate == '') return false
							if (val.getTime() == this.minDate) return false;
							return true
						} return false
					},
					onPick: date => {
						this.minDate = date.minDate.getTime()
					}
				},
				minDate: '',
				rules: {
					class_time: vali.validatorCommon.mandatory('请选择上课时间', 'change'),
					date: vali.validatorCommon.mandatory('请选择开班日期', 'change'),
					age_start: [
						...vali.validatorCommon.mandatory('请输入最低年龄'),
						...vali.validatorCommon.number,
						{
							validator: (rule, val, callback) => {
								if (this.addClassData.age_end == '') return callback()
								if (val - this.addClassData.age_end > 0) {
									return callback(new Error('最小年龄不能大于最大年龄'))
								}
								return callback()
							},
							trigger: 'blur'
						}
					],
					classCapacity: [
						...vali.validatorCommon.mandatory('请输入班级容量'),
						...vali.validatorCommon.number,
						/* {
							validator:  (rule, value, callback) => {
								if(value > this.classCapacity) callback(new Error('班级容量不能大于场地容量'))
								callback()
							},
							trigger: 'blur'
						} */
					],
					age_end: [
						...vali.validatorCommon.mandatory('请输入最大年龄'),
						...vali.validatorCommon.number,
						{
							validator: (rule, val, callback) => {
								if (this.addClassData.age_start == '') return callback()
								if (val - this.addClassData.age_start < 0) {
									return callback(new Error('最大年龄不能小于最小年龄'))
								}
								return callback()
							},
							trigger: 'blur'
						}
					],
					lesson: [
						...vali.validatorCommon.mandatory('请输入课时总数'),
						...vali.validatorCommon.number
					],
					consume_lesson: [
						...vali.validatorCommon.mandatory('请输入消耗课时'),
						...vali.validatorCommon.price,
					],
					'course_classification.id': [],
					'classRoom.id': vali.validatorCommon.mandatory('请选择上课场地', 'change'),
					'teacher.id': [],
				},
				week: '',
				startTime: '',
				endTime: '',
				showTime: [],
				classCapacity: 6, //选择的上课场地最大容量
				loading: false,
				bread: ['班级信息', '添加班级'],
				addClassData: {
					name: '',
					course_classification: {
						id: '',
						name: ''
					},
					classRoom: {
						id: '',
						name: ''
					},
					teacher: {
						id: '',
						name: ''
					},
					age_end: '',
					age_start: '',
					date: [],
					class_time: '',
					consume_lesson: '',
					classCapacity: 6,
					alternativeTeacher: {
						id: '',
						name: ''
					},
					mainTeacher: {
						id: '',
						name: ''
					}
				}
			}
		},
		computed: {
			getComponent() {
				var map = {
					'课程': 'course-c',
					'上课场地': 'class-room-c',
					'主课老师': 'teacher-c',
					'配课老师': 'teacher-c',
					'备选老师': 'teacher-c',
					'': ''
				}
				return map[this.dialogTitle]
			},
			getSelectRowIndex() {
				var map = this.getMap()
				return this.dialogTitle ? this.addClassData[map[this.dialogTitle]].id : ''
			},
		},
		watch: {
			'addClassData.class_time': function(val) {
				var className = '',
				    week = WEEKMAP[this.showTime[0].week[0]],
					time = this.showTime[0].start_time;
				if (this.addClassData.type == 4) className = '活动班级'
				else className = this.addClassData.course_classification.name ? this.addClassData.course_classification.name : ''
				this.addClassData.name = `${className} - 周${week} - ${time}`
			},
			'addClassData.course_classification.name': function(val) {
				var className = '',
				    week = WEEKMAP[this.showTime[0].week[0]],
					time = this.showTime[0].start_time;
				if (this.addClassData.type == 4) className = '活动班级'
				else className = val ? val : ''
				this.addClassData.name = `${className} - 周${week} - ${time}`			
			}
		},
		created() {
			var initClass = JSON.parse(sessionStorage.getItem('initClass'));
			this.addClassData.type = (this.$route.query.type || this.type) ? (this.$route.query.type || this.type) : '1';
			this.setRule()
			if (initClass) {
				var {
					week,
					date,
					year,
					time_start,
					time_end,
					capacity
				} = initClass;
				/* this.classCapacity = capacity; */ //选择上课场地同步设置最大的班级容量
				extend(this.addClassData.classRoom, initClass.classroom)
				this.week = week;
				this.startTime = time_start;
				this.endTime = time_end;
				var date = year + '-' + date;
				this.addClassData.date = [date, date];
				this.sub([{
					week: [this.week],
					start_time: this.startTime,
					end_time: this.endTime,
					checkAll: false,
					indeterminate: true
				}])
			} else if (this.addClassData.type == 3) {
				var date = formatDate.detail(new Date(), 'yyyy-mm-dd');
				this.addClassData.date = [date, date]
			}
		},
		destroyed() {
			sessionStorage.removeItem('initClass')
		},
		methods: {
			addClass (params) {
				this.loading = true
				addClass.call(this, params)
					.then( res => {
						if (res) {
							this.loading = false
							setTimeout( () => {
								this.$router.push('/education/educationFastTypesetting');
							},300)
						}
					})
					.catch( err => this.loading = false)
			},
			async confirm() {
				await this.$refs.refForm.validate().catch()
				var params = {...this.addClassData};							
				params.classRoomId = params.classRoom.id
				params.teacherId = params.teacher.id
				params.mainTeacher = params.mainTeacher.id
				params.alternativeTeacher = params.alternativeTeacher.id
				/* 活动班级不需要课程 */
				if (params.type != 4) params.course_classification_id = params.course_classification.id
				delete params.course_classification;
				delete params.classRoom
				delete params.teacher
				params.class_time = this.showTime;
				this.addClass(params)
			},
			show(title) {
				this.dialogTitle = title;
				this.visible = true
			},
			currentChange(row) {
				if (row === null) return
				var map = this.getMap()
				extend(this.addClassData[map[this.dialogTitle]], row)
				this.visible = false
			},
			getMap() {
				return {
					'课程': 'course_classification',
					'上课场地': 'classRoom',
					'主课老师': 'teacher',
					'配课老师': 'mainTeacher',
					'备选老师': 'alternativeTeacher',
				}
			},
			setRule() {
				var _this = this
				function getRule(message) {
					return {
						validator: (rule, value, callback) => {
							// 体验班级和活动班可以不选
							if (_this.addClassData.type != 1) return callback()
							// 常规班级必选
							if (/.+/.test(value)) return callback()
							else callback(new Error(message))
						},
						tirgger: 'change'
					}
				}
				if (_this.addClassData.type != 1) {
					_this.rules['course_classification.id'].push(getRule('请选择课程'))
					_this.rules['teacher.id'].push(getRule('请选择主课老师'))
				} else {
					_this.rules['course_classification.id'].push(...vali.validatorCommon.mandatory('请选择课程', 'change'))
					_this.rules['teacher.id'].push(...vali.validatorCommon.mandatory('请选择主课老师', 'change'))
				}
			},
			showCourseTime() {
				this.courseTimeVisible = true;
				this.$nextTick(() => {
					this.$refs.courseTimeRef.visible = true;
					this.$refs.courseTimeRef.item = this.showTime;
				})
			},
			sub(row) {
				this.showTime = [];
				row.forEach((item) => {
					this.showTime.push({
						start_time: item.start_time,
						end_time: item.end_time,
						week: item.week,
						checkAll: item.week.length == 7 ? true : false
					})
				});
				this.addClassData.class_time = this.getClassTime(this.showTime);
				this.courseTimeVisible = false;
				this.$forceUpdate()
			},
			getClassTime(row) {
				let str = '';
				row.forEach((item) => {
					let weekStr = '';
					item.week.forEach((item) => {
						switch (item) {
							case 1:
								weekStr = weekStr + '星期一  ';
								break;
							case 2:
								weekStr = weekStr + '星期二  ';
								break;
							case 3:
								weekStr = weekStr + '星期三  ';
								break;
							case 4:
								weekStr = weekStr + '星期四  ';
								break;
							case 5:
								weekStr = weekStr + '星期五  ';
								break;
							case 6:
								weekStr = weekStr + '星期六  ';
								break;
							case 7:
								weekStr = weekStr + '星期天  ';
								break;
						}
					})
					weekStr = "   " + weekStr + " | " + item.start_time + "   " + item.end_time + ',';
					str = str + weekStr;
				})
				return str;
			}
		}
	}
</script>
<style scoped>
	.loadingHeight {
		height: calc(100vh - 70px);
	}

	.boxLoading {
		transform: translate(0, 70px);
		z-index: 9999;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.44);
		text-align: center;
		display: flex;
		align-items: center;
	}

	.loading {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		color: #FFFDEF;
	}

	.isShow_button {
		display: none;
	}

	.button {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	.box_left {
		text-align: right;
		padding: 0 1%;
		box-sizing: border-box;
		width: 50%;
	}

	.box_right {
		text-align: left;
		padding: 0 1%;
		box-sizing: border-box;
		width: 50%;
	}


	.opacity_0 {
		opacity: 0;
	}

	.classTypeImgSize {
		vertical-align: middle;
		width: 40%;
	}

	/deep/ .el-form--inline .el-form-item {
		display: flex;
		margin-right: 0;
	}

	/deep/ .el-form--inline .el-form-item__content {
		flex: 1;
	}
</style>
