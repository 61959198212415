<template>
	<!-- 账号管理 -->
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>系统管理</el-breadcrumb-item>
		  <el-breadcrumb-item>账号管理</el-breadcrumb-item>
		  <el-breadcrumb-item>教师管理</el-breadcrumb-item>
		</el-breadcrumb>
		<user-c></user-c>
	</div>
</template>

<script>
	import userC from '../../common/iteration-teacher-table.vue'
	export default {
		name: 'AccountUser',
		data(){
			return{
				
			}
		},
		components:{
			userC
		}
	}
	
</script>
<style>
</style>
