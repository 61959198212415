/**
 * superMan
 */
<template>
	<!-- step通用 -->
	<!-- 步骤条 -->
	<div class="h-per-100">
		<el-steps :active="active - 0" finish-status="success" align-center :lazy='true' v-if="isHavaStep" >
		  <el-step v-for="(item,index) in src" :key="index" :title="item.title"
			></el-step>
		</el-steps>
		<!-- 导航条 -->
		<el-tabs :tab-position="position" v-model="active" @tab-click="getkind" :before-leave="isLeave" v-if='isHaveTab' class="h-per-100">
			<el-tab-pane  v-for="(item,index) in src" :key="index"  class="h-per-100"
			:label="item.title" :name="item.name"
			><slot :name="item.slotName"></slot></el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	export default{
		name: 'Step',
		/* 数据 */
		props:{
			src:{	
				type:Array,
				default: function () {
						return [{title:'',name:'', slotName: ''}]
				}
			},
			index:{
				type:String,
				default:'0'
			},
			isHaveTab: {
				type: Boolean,
				default: true
			},
			isHavaStep: {
				type: Boolean,
				default: true
			},
			position: {
				type: String,
				default: 'left'
			}
		},
		data(){
			return{
				active: 0
			}
		},
		methods:{
			// 更新步骤条的状态-下一步
			upStep_next (callback) {
				if (this.active === this.src.length) return
				this.active++;
				callback(this.active, this.src.length)
			},
			// 更新步骤条的状态-上一步
			upStep_pre (callback) {
				if (this.active === 0) return
				this.active--;
				callback(this.active, this.src.length)
			},
			getkind(){
				
			},
			// 我的临时
			isLeave () {}
			/* 切换之前的钩子函数 */
			/* isLeave(index){
				if(this.active < index){
					this.$message.info('请先保存当前页信息...')
					return false;
				}
			} */
		},
		created () {
			this.$emit('upStep_pre', { func: this.upStep_pre })
			this.$emit('upStep_next', { func: this.upStep_next });
			this.active = this.index
		}
	}
</script>

<style scoped  lang="less">
	.el-tabs__header,.is-left {
		display: none !important;
	}
	.el-tab-pane{
		font-size: 12px !important;
	}
	.el-tabs__content{
		height: 100%;width: 100%;
	}
</style>
