<template>
  <!-- 流水记录 -->
  <div>
    <el-row>
      <el-col :span="4">
        <el-input size="small" clearable placeholder="请输入学员姓名" v-model="screen.name" @change="getList" style="width: 100%;">
          <i slot="suffix" class="el-input__icon el-icon-search cursor-p" @click="getList"></i>
        </el-input>
      </el-col>
      <el-col :span="19" :offset='1'>
        <data-analysis-search :defaultData="defaultData" @getScreen='getScreen' lastMonth='上月' thisMonth='当月' yesterday="昨天" today="今天" sevenDay="7天"></data-analysis-search>
      </el-col>
    </el-row>
    <el-table :data='tableData' stripe v-if="newPeople">
      <el-table-column prop="school_name" label='校区' width="150"></el-table-column>
      <el-table-column prop="student_name" label='学员名称' width="120"></el-table-column>
      <el-table-column prop="teacher_name" label='教师名称' width="80"></el-table-column>
      <el-table-column prop="cost_lesson" label='购买课时' width="80"></el-table-column>
      <el-table-column prop="give_lesson" label='赠送课时' width="80"></el-table-column>
      <el-table-column prop="lesson" label='总课时' width="100"></el-table-column>
      <el-table-column prop="discount" label='折扣' width="100"></el-table-column>
      <el-table-column prop="reduction_amount" label='减免价格' min-width="100"></el-table-column>
      <el-table-column prop="total_cost" label='原价' min-width="150"></el-table-column>
      <el-table-column prop="paid_amount" label='实付金额' width="180"></el-table-column>
      <el-table-column prop="created_at" label='购买时间' width="180"></el-table-column>
    </el-table>
    <el-table :data='tableData' stripe v-else>
      <el-table-column prop="school_name" label='校区' width="150"></el-table-column>
      <el-table-column prop="name" label='学员名称' width="120"></el-table-column>
      <el-table-column v-if="use === 'record' && !achievement" prop="pay_type" label='缴费方式' width="80"></el-table-column>
      <el-table-column v-if="use === 'record'" prop="change_desc" label='变动描述' width="80"></el-table-column>
      <el-table-column v-if="use === 'record'" prop="type" label='课程类型' width="80"></el-table-column>
      <el-table-column v-if="use === 'record'" prop="teacher_name" label='教师' width="100"></el-table-column>
      <el-table-column prop="amount" :label="defaultKind" min-width="50">
        <template v-slot="scope">
          {{scope.row.amount | insertSeparator}}
        </template>
      </el-table-column>
      <el-table-column v-if="use === 'record'" prop="course_classification_name" label='课程' min-width="100"></el-table-column>
      <el-table-column v-if="use === 'record'" prop="course_package_name" label='课包' min-width="150"></el-table-column>
      <el-table-column prop="created_at" label='购买时间' width="180"></el-table-column>
    </el-table>

  </div>
</template>

<script>
import dataAnalysisSearch from "./data-analysis-search.vue";
import { extend, insertMoneySeparator } from "../../../assets/js/util.js";
export default {
  name: "RecordList",
  props: {
    defaultKind: {
      type: String,
      default: "",
    },

    /**
     * @description 用在哪里 分为退费 和 流水
     */
    use: {
      type: String,
      default: "record", // record- 流水 refund- 退费
    },
    achievement: {
      type: Boolean,
      default: false,
    },
    newPeople: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      screen: {
        kind: "", // 1流入 2流出
        name: "",
        date_start: "",
        date_end: "",
        school_id: "",
      },
      defaultData: {},
    };
  },
  filters: {
    insertSeparator(val) {
      return insertMoneySeparator(val);
    },
  },
  methods: {
    getKind() {
      var mapKind = {
        流入金额: 1,
        流出金额: 2,
        退费人次: "",
      };
      return mapKind[this.defaultKind];
    },
    getScreen(screen) {
      extend(this.screen, screen);
      this.getList();
    },
    async getList() {
      var params = { ...this.screen };
      if (this.achievement) {
        var url = "/statistics/getAchievementList";
      } else if (this.newPeople) {
        var url = "/statistics/getNewSignList";
      } else {
        var url = "/organization/financial";
        if (params.kind) {
          url += "/flowIntoAndOut";
        } else {
          delete params.kind;
          url += "/returnPremium";
        }
      }
      var { data: res } = await this.$http.get(url, {
        params: params,
      });
      if (res.code == 200 || res.code == 0) {
        if (this.newPeople) {
          this.tableData = res.data.data;
        } else {
          this.tableData = res.data;
        }
      } else {
        this.$message.error(res.message);
      }
      return;
      var { data: res } = await this.$http.get("/statistics/turnoverrecord", {
        params: this.screen,
      });
      if (res.code == 0) {
        console.log(res, 11111);

        this.tableData = res.data.data;
        this.screen.page = res.data.page;
        this.total = res.data.total;
        this.inflow_total = res.data.inflow_total;
        this.outflow_total = res.data.outflow_total;
      } else {
        this.$message.error(res.message);
      }
    },
  },
  components: {
    dataAnalysisSearch,
  },
  created() {
    this.screen.kind = this.getKind();
    var { defaultScreen } = this.$attrs;
    if (defaultScreen) {
      this.defaultData = { ...defaultScreen };
      extend(this.screen, defaultScreen);
      this.screen.date_start = defaultScreen.date[0];
      this.screen.date_end = defaultScreen.date[1];
      this.getList();
    }
  },
};
</script>

<style scoped>
</style>
