<template>
	<!-- 校区管理 -->
	<div>
		<el-card>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>机构管理</el-breadcrumb-item>
				<el-breadcrumb-item>校区管理</el-breadcrumb-item>
			</el-breadcrumb>
			<!-- 添加 -->
			<el-button type="primary" size="small" @click="click_add" class="mg-t-10">添加校区</el-button>
			<!-- 表格 -->
			<el-table class="mg-t-10 fsMedia" :data="tableData.data">
				<el-table-column label="校区名称" prop="name" v-if="true"></el-table-column>
				<el-table-column label="操作">
					<template v-slot="scoped">
						<el-button type="primary" size="mini" @click="click_show(scoped.row)">编辑</el-button>
						<el-button type="primary" size="mini" @click="entry(scoped.row)">{{scoped.row | filterSelectSchoole}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination background layout="total, prev, pager, next" :total="tableData.total" :page-size='50' :current-page="tableData.current_page"
			 class="mg-t-10" @current-change='current_change'></el-pagination>
		</el-card>

		<!-- 查看校区信息或者添加校区对话框 -->
		<el-dialog :title="title_dialog" :visible.sync="dialogVisible" width="30%" center :before-close="before_close" @close="close_dialog">
			<el-form :model="form" ref="form" label-position="left" :rules="rules" label-width="80px">
				<el-form-item prop="name" label="校区名称">
					<el-input v-model="form.name" placeholder="请输入2-15个中文"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="click_dialog_confirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import vali from "../../assets/js/validate";
	export default {
		name: 'Schoole',
		data() {
			return {
				rules: {
					name: vali.validatorCommon.name,
				},
				//   当前是添加还是修改
				addOrmodify: "add",
				dialogVisible: false,
				title_dialog: "",
				form: {
					name: "",
				},
				tableData: {},
				id_modify: "",
			};
		},
		methods: {
			current_change(p) {
				this.upTable({
					page: p
				})
			},
			// 是否验证通过
			async isVali() {
				return new Promise((resolve, rejected) => {
					this.$refs.form.validate(bool => {
						if (bool) resolve(true)
						else rejected(false)
					})
				}).catch(e => {})
			},
			close_dialog() {
				this.$refs.form.resetFields();
				this.form.name = "";
			},
			before_close(done) {
				done();
			},
			click_show(scoped) {
				this.id_modify = scoped.id;
				this.addOrmodify = "modify";
				this.dialogVisible = true;
				this.title_dialog = "修改校区";
				this.form.name = scoped.name;
			},
			async click_dialog_confirm() {
				if (!await this.isVali()) return
				switch (this.addOrmodify) {
					case "add":
						if (await this.addSchoole(this.form)) {
							this.dialogVisible = false;
							this.$message.success("添加校区成功");
							this.upTable();
						}
						break;
					case "modify":
						if (await this.modifySchoole(this.id_modify, this.form)) {
							this.dialogVisible = false;
							this.$message.success("修改校区成功");
							this.upTable();
						}
						break;
					default:
						break;
				}
			},
			// 更新表格数据
			async upTable(param) {
				this.tableData = await this.getSchoole(param);
			},
			//   添加校区按钮点击
			click_add() {
				this.addOrmodify = "add";
				this.dialogVisible = true;
				this.title_dialog = "添加校区";
			},
			// 获取校区
			async getSchoole(param) {
				var params = {
					page: 1,
				};
				Object.assign(params, param);
				const {
					data: res
				} = await this.$http.get("/organization/schoole", {
					params,
				});
				if (res.code == 200) {
					return res.data;
				}
				return false;
			},
			// 修改校区
			async modifySchoole(id, param) {
				var params = {};
				Object.assign(params, param);
				const {
					data: res
				} = await this.$http.patch(
					"/organization/schoole/" + id,
					params
				);
				if (res.code == 200) {
					console.log(res.data, "修改校区");
					return true;
				}
				return false;
			},
			// 添加校区
			async addSchoole(param) {
				var params = {};
				Object.assign(params, param);
				const {
					data: res
				} = await this.$http.post(
					"/organization/schoole",
					params
				);
				if (res.code == 200) {
					return true;
				}
				return false;
			},
			/* 进入该校区 */
			async entry(row) {
				if (window.sessionStorage.getItem('checkSchoole') == row.id) {
					this.$message.info('无需切换，正在该校区');
					return;
				}
				const {
					data: res
				} = await this.$http.get('organization/schoole/' + window.sessionStorage.getItem('username') + '?schoole_id=' +
					row.id);
				if (res.code == 200) {
					window.sessionStorage.setItem('checkSchoole', row.id);
					this.$router.go(0);
				} else {
					this.$message.info("您无权限访问该校区");
				}
			}
		},
		filters: {
			filterSelectSchoole(val) {
				if (val.id == sessionStorage.getItem('checkSchoole')) {
					return '已在该校区'
				} else return '进入'
			}
		},
		created() {
			~(async () => {
				this.tableData = await this.getSchoole();
			})();
		},
	};
</script>

<style scoped lang="less">

</style>
