<!-- 班级信息表格 -->
<template>
	<div>
		<!-- 搜索 -->
		<el-input size="medium" v-if='showScreen' placeholder="请输入学员名字" style="width: 300px;" clearable v-model="screen.key" @change="changeKey" @clear="changeKey">
			<i slot="suffix" class="el-input__icon el-icon-search"></i>
		</el-input>
		<!-- 表格 -->
		<el-table :data="tableData.data" :highlight-current-row='show_table.isSingle' @current-change='handleCurrentChange'
		 :stripe='show_table.isStripe' ref="singleTable" @selection-change='selection_change' class="fsMedia mg-t-20" row-key="getRowKey"
		 :empty-text='emptyText'>
			<el-table-column v-if="show_table.isMultiple" type="selection" :reserve-selection="true">
			</el-table-column>
			<el-table-column v-for="(item, key, index) in data_table" :key="index" v-if="item.type" :width="item.width"
			 :min-width="item.minWidth" :align="item.center" :label="key" :prop="item.prop">
				<template v-if="item.slot" v-slot='scoped'>
					<a @click="click_table_slot(scoped)" href="javascript:;" class="c-409eff">{{item.value}}</a>
				</template>
			</el-table-column>
			<el-table-column label="操作" v-if="show_table.isOperation" :width="width" fixed="right">
				<template v-slot="scoped">
					<el-button size="small" :type="button.button1.type" @click="click_button_confirm(scoped.row)" v-if="button.button1.state">{{button.button1.value}}</el-button>
					<el-button v-if='buttonCallIn.show' size="small" type="primary" @click="$emit('click_button_callIn', scoped.row)">{{buttonCallIn.value}}</el-button>					
					<el-button size="small" :type="button.button2.type" @click="click_button_adjustment(scoped.row)" v-if="button.button2.state">{{button.button2.value}}</el-button>
					<el-button size="small" :type="button.button3.type" @click="click_button_del(scoped.row)" v-if="button.button3.state">{{button.button3.value}}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 表格 -->
		<!-- 分页 -->
		<template v-if="isHavePagination">
			<div class="block mg-t-10">
				<el-pagination background @size-change="handleSizeChangePagination" @current-change="handleCurrentChangePagination"
				 :current-page.sync="tableData.current_page" :page-size="10" layout="total, prev, pager, next" :total="tableData.total">
				</el-pagination>
			</div>
		</template>
		<!-- 分页 -->

	</div>
</template>
<script>
	import params from '../../assets/js/params.js'
	export default {
		name: 'TableClass',
		props: {
			buttonCallIn: {
				type: Object,
				default: function () {
					return {
						show: false,
						value: ''
					}
				}
			},
			showScreen: {
				type: Boolean,
				default: false
			},
			emptyText: '暂无数据',
			// 选中的行--多选
			selectRow: '',
			width: {
				type: String,
				default: '250'
			},
			isHavePagination: {
				trye: Boolean,
				default: true
			},

			// 表格数据
			tableData: '',
			// 表格的样式
			show_table: {
				type: Object,
				default: function() {
					return {
						// 单选
						isSingle: true,
						// 斑马纹
						isStripe: false,
						// 多选
						isMultiple: false,
						// 是否要操作
						isOperation: true,
						// 排序
						isSortable: true,
					}
				}
			},
			// 表格按钮状态
			button: {
				type: Object,
				default: function() {
					return {
						button1: {
							// 是否显示
							state: true,
							// 按钮类型
							type: 'primary',
							// 按钮文本
							value: '选择',
						},
						button2: {
							state: true,
							type: 'warning',
							value: '编辑',
						},
						button3: {
							state: true,
							type: 'danger',
							value: '删除'
						}
					}
				}
			},
			// 表格配置
			data_table: {
				type: Object,
				default: function() {
					return {

					}
				}
			}
		},
		data() {
			return {
				// data_table: params.table_class
				screen: {
					key: ''
				} 
			}
		},
		methods: {
			changeKey (val) {
				if (val == undefined) {
					val = this.screen.key
				}
				this.$emit('changeKey', val)
			},
			getRowKey(row) {

			},
			toggleRowSelection(row, bool = true) {
				this.$refs.singleTable.toggleRowSelection(row, bool)
			},
			selectRowMuli(val) {
				this.$refs.singleTable && this.$refs.singleTable.clearSelection()
				this.$nextTick(() => {
					val instanceof Array && val.forEach(e => {
						this.toggleRowSelection(e)
					})
				})

			},
			// 页码改变
			handleSizeChangePagination(p) {},
			// 当前页改变
			handleCurrentChangePagination(p) {
				this.$emit('handleCurrentChangePagination', p)
			},


			// 取消单选表格选中状态
			noSelect() {
				this.$refs.singleTable.setCurrentRow()
			},
			// 表格选择项发生变化时
			selection_change(selection) {
				this.$emit('selection_change', {
					id: selection
				})
			},
			// 收费标准对话框的状态
			changeStatusDialog() {},
			// 收费表格内部点击事件
			click_table_slot(scoped) {
				this.changeStatusDialog(true)
				this.$emit('click_show', {
					id: scoped
				})
			},
			// 表格复选框单选
			handleSelect(section, row) {
				this.$emit('handleSelect', {
					id: selection
				})
			},
			// 表格复选框全选
			handleSelectALL(selection) {
				this.$emit('handleSelectALL', {
					id: selection
				})
			},
			// 表格行选择事件
			handleCurrentChange(currentRow, oldcurrentRow) {
				this.$emit('handleCurrentChange', {
					id: currentRow
				})
				this.$emit('noSelect', {
					func: this.noSelect
				})
			},
			// 表格按钮点击
			click_button_confirm(id) {
				this.$emit('click_button_confirm', {
					id
				})
			},
			click_button_adjustment(id) {

				this.$emit('click_button_adjustment', {
					id
				})
			},
			click_button_del(id) {
				this.$emit('click_button_del', {
					id
				})
			},
		},
		created() {
			this.selectRowMuli(this.selectRow);			
		},
		watch: {
			selectRow(val) {
				this.selectRowMuli(val)
			}
		}
	}
</script>
<style scoped lang="less">
</style>
