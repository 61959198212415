// 2021-03-12 有FZY创建 用于 展示学员上课课表
<template>
	<div class="timeTable">
		<div class="flex align-center justify-between">
			<el-date-picker v-model="week" type="week" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" :picker-options="pickerOptions"
			 @change="screenChange" :clearable="false" size="small">
				>
			</el-date-picker>
			<el-radio-group v-model="screen.type" size="small" @change="getFormalClassSchedule">
				<el-radio-button v-for="item in classTypeOptions" :label="item.id" :key="item.id">{{item.name}}</el-radio-button>
			</el-radio-group>
		</div>
		<div class="flex align-center header mg-t-10">
			<div class="boxImg flex align-center flex-center">
				<img src="../../../assets/image/logo-time-table.png" alt="LOGO">
			</div>
			<div class="text-align-c flex-1_1 date">
				<span>{{screen.date[0]}} - {{screen.date[1]}}</span>
			</div>
		</div>
		<el-table :data='tableData' :span-method="arraySpanMethod" border size='small' :cell-style='cellStyle' class="el-table">
			<el-table-column label='星期' :width="weekWidth" align="center" fixed="left">
				<template slot-scope="scope">
					{{scope.row.date.week}}
				</template>
			</el-table-column>
			<el-table-column prop='classTime' label='上课时间' width="130" align="center">
				<template v-slot="scope">
					<span class="cursor-p" style="color: #00ace5;border-bottom: 1px dotted;" @click="navitation(1, scope.row)">
						{{scope.row.classTime}}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop='class' label='班级' width="180" align="center">
				<template v-slot="scope">
					<span class="cursor-p" style="color: #00ace5;border-bottom: 1px dotted;" @click="navitation(2, scope.row)">{{scope.row.class.name}}</span>
				</template>
			</el-table-column>
			<el-table-column prop='size' label='已有学位' width="70" align="center"></el-table-column>
			<el-table-column prop='teacherName' label='上课老师' width="70" align="center"></el-table-column>
			<el-table-column label='学员名字' min-width="100" align="left">
				<template slot-scope="scope">
					<span v-for="(item, index) in scope.row.studentInfo" :key='index'>{{item.name}}({{item.birthday ? item.birthday : '无生日'}}){{index >= scope.row.studentInfo.length - 1 ? '' : '、'}}</span>
				</template>
			</el-table-column>
		</el-table>
		<div class="boxLoading" v-show="flag_loading">
			<i class="el-icon-loading loading"></i>
		</div>
	</div>
</template>
<script>
	import {
		getFormalClassSchedule
	} from '../../../assets/js/API/student.js'
	import {
		formatDate
	} from '../../../assets/js/myMethods.js'
	import {
		CLASS_TYPE
	} from '../../../assets/js/CONFIG/class.js'
	var DATE_NOW = new Date();
	const DATE_ARR = [
		//本周
		{
			haveMonth: [formatDate.computed(formatDate.getWeekStartAndEnd(DATE_NOW).date_start, 1, 'add', 'day', 'yyyy-mm-dd'),
				formatDate.computed(formatDate.getWeekStartAndEnd(DATE_NOW).date_end, 1, 'add', 'day', 'yyyy-mm-dd')
			],
			noMonth: [formatDate.detail(formatDate.computed(formatDate.getWeekStartAndEnd(DATE_NOW).date_start, 1, 'add', 'day',
				'yyyy-mm')), formatDate.computed(formatDate.getWeekStartAndEnd(DATE_NOW).date_end, 1, 'add', 'day', 'yyyy-mm-dd')]
		}
	]
	DATE_NOW = null;
	const COLOR_TIP = {
		'NORMAL': '#fff2cc',
		'DANGER': '#e99899',
		'WEEK': '#e9e9e9'
	}
	var weekWidth = window.outerWidth
	export default {
		name: 'StudentTimeTable',
		data() {
			return {
				classTypeOptions: CLASS_TYPE,
				flag_loading: true,
				week: DATE_ARR[0].haveMonth[0],
				pickerOptions: {
					firstDayOfWeek: 1
				},
				weekWidth: 0,
				screen: {
					date: DATE_ARR[0].haveMonth,
					type: 1,
					schoole_id: sessionStorage.getItem('checkSchoole')
				},
				tableData: [],
				cellStyle: ({
					row,
					column,
					rowIndex,
					columnIndex
				}) => {
					if (columnIndex == 0) {
						return {
							'background-color': COLOR_TIP.WEEK,
						}
					} else {
						if (row.size >= row.maxSize) return {
							'background-color': COLOR_TIP.DANGER
						}
						else return {
							'background-color': COLOR_TIP.NORMAL
						}
					}
				}
			}
		},
		methods: {
			navitation(key, row) {
				var classTimeArr = row.classTime.split('-');
				if (key == 1) {
					this.$router.push({
						path: '/education/student-state',
						query: {
							cid: row.class.id,
							date: row.date.date,
							time_start: classTimeArr[0],
							time_end: classTimeArr[1],
							name: row.class.name,
							course_content: row.course_content,
							teacher_name: row.teacherName,
						}
					})
					/* window.open('/#/education/student-state?cid=' + row.class.id + '&date=' + row.date.date + '&time_start=' +
						classTimeArr[0] + '&time_end=' + classTimeArr[1] + '&name=' + row.class.name + '&course_content=' + row.course_content); */
				}
				else {
					this.$router.push('/education/showClass/' + row.class.id)
					/* window.open('/#/education/showClass/' + row.class.id) */
				}
				return
				this.$router.push({
					path: '/education/student-state',
					query: {
						cid: row.class.id,
						date: row.date.date,
						time_start: classTimeArr[0],
						time_end: classTimeArr[1],
						name: row.class.name
					}
				})
			},
			screenChange(val) {
				this.screen.date[0] = formatDate.computed(this.week, 1, 'reduce', 'day', 'yyyy-mm-dd');
				this.screen.date[1] = formatDate.computed(this.week, 5, 'add', 'day', 'yyyy-mm-dd');
				this.flag_loading = true;
				this.getFormalClassSchedule()
			},
			arraySpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex == 0) {
					var _row = 1,
						_col = 1;
					for (let i = rowIndex; i < this.tableData.length; i++) {
						if (this.tableData[i - 1] && this.tableData[i - 1].date.date == this.tableData[i].date.date && _row == 1) {
							_col = 0;
							break;
						}
						if (this.tableData[i + 1] && this.tableData[i + 1].date.date == this.tableData[i].date.date) _row++
						else {
							break
						}
					}
					return {
						rowspan: _row,
						colspan: _col
					}
				} else return {
					rowspan: 1,
					colspan: 1
				}
			},
			getFormalClassSchedule() {
				this.flag_loading = true;
				var params = { ...this.screen
				};
				params.date_start = params.date[0];
				params.date_end = params.date[1];
				delete params.date
				getFormalClassSchedule.call(this, params)
					.then(res => {
						this.flag_loading = false;
						this.sortData(res);
						this.tableData = res
					})
					.catch(err => this.flag_loading = false)
			},
			sortData(data) {
				data.sort((two, one) => {
					return +new Date(two.date.date.replace(/-/g, '/') + ' ' + two.classTime.split('-')[0]) - +new Date(one.date.date
						.replace(/-/g, '/') + ' ' + one.classTime.split('-')[0])
				})
			}
		},
		created() {
			this.weekWidth = weekWidth > 1499 ? 140 : 100;
			weekWidth = null;
			this.getFormalClassSchedule()
		}
	}
</script>
<style scoped>
	.header {
		height: 45px;
		border: 1px solid #999;
		border-bottom: none;
	}

	.boxImg {
		width: 140px;
		max-width: 140px;
		height: 100%;
		border-right: 1px solid #999;
	}

	.boxImg img {
		width: 125px;
	}

	.date {
		font-size: 16px;
	}

	.timeTable {
		overflow-y: auto;
		position: relative;
	}

	.el-table>>>.el-table__row td,
	.el-table>>>.el-table__header th {
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		color: #666 !important;
	}

	.el-table {
		border: 1px solid #999;
		color: #666 !important;
		margin-top: 0;
	}

	.boxLoading {
		width: 100%;
		height: 100%;
		font-size: 40px;
		background-color: #00000010;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		color: #999;
	}

	.loading {
		position: absolute;
		z-index: 999;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@media screen and (max-width: 1499px) {
		.boxImg {
			width: 100px;
			max-width: 100px;
		}

		.boxImg img {
			width: 95px;
		}
	}
</style>
