/* 常用的公共正则验证 */
const validatorCommon = {
	name: [{
			required: true,
			message: '必填',
			trigger: 'blur'
		},
		{
			min: 2,
			max: 15,
			message: '长度在 2 到 15 个字符'
		},
		{
			pattern: /^[\u4E00-\u9FA5]+$/,
			message: '只能为中文'
		},
	],
	//{ pattern:/^[a-zA-Z]w{1,4}$/, message: '以字母开头，长度在2-5之间， 只能包含字符、数字和下划线'}],
	password: [{
			required: true,
			message: '请输入密码',
			trigger: 'blur'
		},
		{
			min: 5,
			max: 25,
			message: '长度在 5 到 25个字符'
		},
		{
			pattern: /^(\w){5,25}$/,
			message: '只能输入5-25个字母、数字、下划线'
		},
	],
	phone: [{
			required: true,
			message: '请输入手机号码',
			trigger: 'blur'
		},
		{
			validator: function(rule, value, callback) {
				if (/^1[34578]\d{9}$/.test(value) == false) {
					callback(new Error("请输入正确的手机号"));
				} else {
					callback();
				}
			},
			trigger: 'blur'
		}
	],
	//pattern: /^1[34578]\d{9}$/, message: '目前只支持中国大陆的手机号码' }
	idCard: [{
			required: true,
			message: '请输入身份证ID',
			trigger: 'blur'
		},
		{
			pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
			message: '你的身份证格式不正确'
		},
	],
	carId: [{
			required: true,
			message: '请输入车牌号',
			trigger: 'blur'
		},
		{
			pattern: /(^[\u4E00-\u9FA5]{1}[A-Z0-9]{6}$)|(^[A-Z]{2}[A-Z0-9]{2}[A-Z0-9\u4E00-\u9FA5]{1}[A-Z0-9]{4}$)|(^[\u4E00-\u9FA5]{1}[A-Z0-9]{5}[挂学警军港澳]{1}$)|(^[A-Z]{2}[0-9]{5}$)|(^(08|38){1}[A-Z0-9]{4}[A-Z0-9挂学警军港澳]{1}$)/,
			message: '常规格式：晋B12345'
		}
	],
	email: [{
			required: true,
			message: '请输入邮箱号',
			trigger: 'blur'
		},
		{
			validator: function(rule, value, callback) {
				if (value === '') {
					callback(new Error('请正确填写邮箱'));
				} else {
					if (value !== '') {
						var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
						if (!reg.test(value)) {
							callback(new Error('请输入有效的邮箱'));
						}
					} else {
						callback();
					}
				}
			},
			trigger: 'blur'
		}
	],
	/* 价格 */
	isPrice: [{
			required: true,
			message: '请输入价格',
			trigger: 'blur'
		},
		{
			validator: function(rule, value, callback) {
				if (!value) {
					callback();
				} else {
					var reg = /^-?[\d]+(?:\.\d+)?$/;
					if (reg.test(value)) {
						callback();
					} else {
						callback(new Error("仅支持整数和小数")); //如:1 或1.8 或1.85
					}
				}
			}
		}
	],
	// 地址

	// 身份证
	id_card: [{
		pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
		message: '你的身份证格式不正确'
	}, ],


	/*缴费名称*/
	name_cost: [{
		required: true,
		message: '请输入缴费名称',
		trigger: 'blur'
	}, ],

	// 名称
	name_mime: [{
			min: 0,
			max: 7,
			message: '长度在 0 到 7 个字符'
		},
		{
			pattern: /^[\u4E00-\u9FA5]+$/,
			message: '只能为中文'
		},
	],

	/* 课时 */
	hours_class: [{
		validator: function(rule, value, callback) {
			if (value + ''.trim().length === 0) return callback()
			var reg = /^[\d]*$/;
			if (reg.test(value)) return callback()
			return callback(new Error('请输入数字'))
		}
	}],
	// 最小年龄
	age_start: [{
		required: true,
		message: '请输入最小年龄',
		trigger: 'blur'
	}, ],
	// 最大年龄
	age_end: [{
		required: true,
		message: '请输入最大年龄',
		trigger: 'blur'
	}, ],

	// 必填
	required_blur: [{
		required: true,
		message: '这是必填的',
		trigger: 'blur'
	}],
	required_change: [{
		required: true,
		message: '这是必填的',
		trigger: 'change'
	}],

	mandatory: function(message, trigger = 'blur') {
		return [{
			required: true,
			message,
			trigger
		}]
	},
	mandatoryArray: function(message, trigger = 'blur') {
		return [{
			validator: (rule, value, callback) => {
				if (value.length == 0) return callback(new Error(message))
				callback()
			},
			trigger
		}]
	},
	number: [{
		validator: function(rule, value, callback) {
			if (/^[\d]*$/.test(value) == false) {
				callback(new Error("只能输入整数"));
			} else {
				callback();
			}
		},
		trigger: 'blur'
	}],
	float: function(message, trigger = 'blur') {
		return [{
			validator: (rule, value, callback) => {
				if (!value) return callback()
				var reg = /^(?:[\d]+)?(?:\.[\d]+)*$/;
				if (!reg.test(value)) return callback(new Error(message))
				callback()
			},
			trigger
		}]
	},
	price: [{
		validator: function(rule, value, callback) {
			if (!value) {
				callback();
			} else {
				var reg = /^-?[\d]+(?:\.\d{1,2})?$/;
				if (reg.test(value)) {
					callback();
				} else {
					callback(new Error("仅支持整数和小数")); //如:1 或1.8 或1.85
				}
			}
		}
	}],
	tel: [{
		validator: function(rule, value, callback) {
			if (/^1[123456789]\d{9}$/.test(value) == false) {
				callback(new Error("请输入正确的手机号"));
			} else {
				callback();
			}
		},
		trigger: 'blur'
	}],
	bankCard: [{
		validator: function(rule, value, callback) {
			if (!value) {
				callback();
			} else {
				var reg = /^([1-9]{1})(\d{14}|\d{18})$/;
				if (reg.test(value)) {
					callback();
				} else {
					callback(new Error("银行卡格式不正确"));
				}
			}
		},
		trigger: 'blur'
	}]
}


class checkRange { //没有做检测，所有注意传的参数
	constructor() { //参数接受一群一维数组，长度为2[1， 2]， [3, 4].....最后一个参数可以设置时最小的值和最大的值
		this.BASIS = [];
		this.BASIS_MODIFY = [];
		var arg = Array.from(arguments);
		this.MAX = '';
		this.MIN = '1';
		this.resetBASIS(...arg);
	}
	resetBASIS() {
		var arg = Array.from(arguments);
		if (Array.isArray(arg[arg.length - 1])) {
			this.BASIS = arg
		} else {
			this.MAX = arg[arg.length - 1].max;
			this.MIN = arg[arg.length - 1].min;
			arg.pop();
			this.BASIS = arg;
			this.stringToNumber(this.BASIS);
			this.sort(this.BASIS);
		}
	}
	stringToNumber(arr) {
		arr.forEach(item => {
			item[0] -= 0;
			item[1] -= 0
		})
	}
	sort(arr) {
		arr.sort((two, one) => {
			return two[1] - one[1]
		})
	}
	getBasis(arr) {
		var basis = [];
		for (let i = arr[0] - 0, y = 0; i <= arr[1] - 0; i++, y++) {
			basis[y] = i - 0;
		}
		return basis
	}
	getBssis_modify(arr1) {
		var bassis_modify = [...this.BASIS];
		this.BASIS_MODIFY = bassis_modify.filter(item => {
			return item[0] != arr1[0]
		})
		this.sort(this.BASIS_MODIFY)
	}
	isHave(sorce, key, type) {
		//type决定是大于是大于还是大于或等于 true是大于（小数） false是大于或等于（整数）
		var arr = key == 'add' ? this.BASIS : (key == 'modify' ? this.BASIS_MODIFY : []),
			result = {
				bool: false,
				value: ''
			};
		if (Array.isArray(sorce)) {
			var basis = this.getBasis(sorce);
			if (type) {
				result.bool = basis.every(item => {
					var bool = arr.some(item1 => {
						if (item - 0 > item1[0] && item - 0 < item1[1]) {
							result.value = this.throwError(item)
							return true
						}
					})
					return !bool
				})
				result.bool = !result.bool
			} else {
				result.bool = basis.every(item => {
					var bool = arr.some(item1 => {
						if (item - 0 >= item1[0] && item - 0 <= item1[1]) {
							result.value = this.throwError(item)
							return true
						}
					})
					return !bool
				})
				result.bool = !result.bool
			}
		} else {
			result.bool = arr.some(item => {
				if (type) {
					if (sorce - 0 > item[0] && sorce - 0 < item[1]) {
						result.value = this.throwError(sorce);
						return true
					}
				} else {
					if (sorce - 0 >= item[0] && sorce - 0 <= item[1]) {
						result.value = this.throwError(sorce);
						return true
					}
				}
			})
		}
		return result
	}
	find(str, direction, key, type) { //direction决定查询的顺序，key决定在哪里查询key接受两个add和modify, type决定是整数还是小数true是小数 false是整数
		var value,
			arr = key == 'add' ? this.BASIS : (key == 'modify' ? this.BASIS_MODIFY : []);
		if (type) {
			if (direction == 'add') {
				// 往后找
				for (let i = 0; i < arr.length; i++) {
					if (arr[i][0] >= str - 0) {
						value = arr[i][0];
						break;
					}
				}
				if (value == undefined) value = this.MAX
			} else if (direction == 'reduce') {
				// 往前找
				for (let i = arr.length; i < 0; i--) {
					if (arr[i][1] < str - 0) {
						value = arr[i][1];
						break;
					}
				}
				if (value == undefined) value = this.MIN
			}
		} else {
			if (direction == 'add') {
				// 往后找
				for (let i = 0; i < arr.length; i++) {
					if (arr[i][0] > str - 0) {
						value = arr[i][0] - 1;
						break;
					}
				}
				if (value == undefined) value = this.MAX
			} else if (direction == 'reduce') {
				// 往前找
				for (let i = arr.length; i < 0; i--) {
					if (arr[i][1] < str - 0) {
						value = arr[i][1] + 1;
						break;
					}
				}
				if (value == undefined) value = this.MIN
			}
		}
		return value
	}
	throwError(error) {
		return `${error}已经存在了，请检查输入`
	}
}


export default {
	validatorCommon,
	checkRange
}
