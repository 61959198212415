<!-- 学员管理 - 学员登记 - 课程报名/分班 - 按钮组 -->
<template>
	<div class="button">
				<!-- 上一步 -->		
				<div class="box_left">
					<el-button size="medium" type="primary" @click='$emit("pre-step")' 
					v-if="isShow_button1"
					>{{value_button1}}</el-button>
				</div>
			    <!-- 上一步/ -->
				<!-- 下一步 -->				
				<div class="box_right" v-if="isShow_button2">
					<el-button size="medium" type="primary" @click='$emit("next-step")'>{{value_button2}}</el-button>
				</div>
				<!-- 下一步/ -->	
	</div>
</template>
<script>
 export default {
	 name: 'StudentCourseRegisterButton',
		props: {
			// 下一步按钮文本
			value_button2: {
				type: String,
				default: '下一步'
			},
			// 上一步按钮是否显示
			isShow_button1: {
				type: Boolean,
				default: true
			},
			isShow_button2: {
				type: Boolean,
				default: true				
			},
			value_button1: {
				type: String,
				default: '上一步'
			}
		},
		data () {
			return {
			}
		},
		methods: {

		},
		created () {
		}
}
</script>
<style scoped>
	.isShow_button{
		display: none;
	}
	.button{
		display: flex;align-items: center;
	}
	.box_left{
		text-align: right;padding: 0 1%;box-sizing: border-box;width: 50%;
	}
	.box_right{
		text-align: left;padding: 0 1%;box-sizing: border-box;width: 50%;
	}
	.el-button{
		width: 30%;padding: 15px 0;box-sizing: border-box;
	}
	.opacity_0{
		opacity: 0;
	}
</style>

