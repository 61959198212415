/* *********************************** 压缩图片 ******************************************* */
/* 步骤
1.使用new FileReader()构造函数读取文件，调用构造函数里的readAsDataURL()返回一个文件的base64格式 
2.创建一个image节点对象，节点属性src为那个文件的base64
3.如果是IOS系统，根据拍摄角度来旋转图片（这边依赖第三方exif.js文件）
4.创建一个canvas节点对象，使用drawImage()方法画到画布中，再调用toDataURL()方法返回一个文件的base64格式
5.转成二进制形式（文件对象或者bolb对象）*/

/* 原理是使用canvas再次绘制图片并缩小，然后再转成二进制形式给到后台 */


import './exif.js'
/*判断系统 */
const U = navigator.userAgent;
const isAndroid = U.indexOf('Android') > -1 || U.indexOf('Adr') > -1; //android终端
const isiOS = !!U.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

function getCanvasWH(image, callback) {
    EXIF.getData(image, function(e) { //根据拍摄角度旋转图片  分三步，第一步获取拍摄角度，第二步根据拍摄角度计算画布的宽高和旋转角度 第三步旋转再画
        var Orientation = EXIF.getTag(this, "Orientation"); //只有IOS系统有这个拍摄角度。默认是Home键在右侧
        var option = {
            width: 0, //暂存宽度
            height: 0, //暂存高度
            drawWidth: 0, //画图的宽度
            drawHeight: 0, //画图的高度
            canvasWidth: image.width, //画布的宽度
            canvasHeight: image.width, //画布的高度
            degree: 0 //旋转角度
        }

        //以下改变一下图片大小
        var maxSide = Math.max(option.drawWidth, option.drawHeight);
        if (maxSide > 1024) {
            var minSide = Math.min(option.drawWidth, option.drawHeight);
            minSide = minSide / maxSide * 1024;
            maxSide = 1024;
            if (option.drawWidth > option.drawHeight) {
                option.drawWidth = maxSide;
                option.drawHeight = minSide;
            } else {
                option.drawWidth = minSide;
                option.drawHeight = maxSide;
            }
        }
        option.canvasWidth = option.width = option.drawWidth;
        option.canvasHeight = option.height = option.drawHeight;
        switch (Orientation) {
            case 1: //Home键在右侧
                break;
            case 8: //Home键在上侧
                option.canvasWidth = option.height;
                option.canvasHeight = option.width;
                option.degree = 270;
                option.drawWidth = -option.width;
                option.drawHeight = option.height;
                break;
            case 3: //Home键在左
                option.degree = 180;
                option.drawWidth = -option.width;
                option.drawHeight = -option.height;
                break;
            case 6: //Home键在下
                option.canvasWidth = option.height;
                option.canvasHeight = option.width;
                option.degree = 90;
                option.drawWidth = option.width;
                option.drawHeight = -option.height;
                break;
            case 2:
                break;
            case 7:
                break;
            case 4:
                break;
            case 5:
                break;
            default:
                break;
        }
    });
    callback(option)
}

function dataURLToBolb(base, type) { //文件转成Bolb对象 
    // base   ----  文件的base64字符串， type  -- 是文件类型 ，不如图片 image/jpeg
    var text = window.atob(base.split(',')[1]); //解码
    var buffer = new ArrayBuffer(text.length); //在内存开辟一定长度的区域
    return new window.Blob([buffer], { //返回一个File对象或Blob对象
        type
    })
}

function dataURLtoFile(dataurl) { //文件转成File对象
    //dataurl   --文件的base64字符串
      
    var arr = dataurl.split(',');   
    var  mime = (arr[0].match(/:(.*?);/))[1];  
    var  bstr = atob(arr[1]); //解码
       
    var  n = bstr.length;   
    var  u8arr = new Uint8Array(n);  
    while (n--) {     u8arr[n] = bstr.charCodeAt(n);   }  
    return new File([u8arr], { type: mime });
}

function compress(file) { //缩放
    return new Promise(resolve => {
        const canvas = document.createElement('canvas');
        const image = new Image();
        const ctx = canvas.getContext('2d');
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(e) {
            image.src = reader.result
            image.onload = function() {
                if (isiOS) {
                    getCanvasWH(image, function(option) {
                        canvas.width = option.canvasWidth;
                        canvas.height = option.canvasHeight;
                        ctx.rotate(option.degree * Math.PI / 180);
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                        ctx.drawImage(image, 0, 0, option.drawWidth, option.drawHeight);
                        FUNcompress()
                    })
                } else {
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                    FUNcompress()
                }

                function FUNcompress() {
                    var fileSize = parseFloat(parseInt(file.size) / 1024 / 1024).toFixed(2);
                    let base64 = '';
                    if (fileSize < 1) {
                        base64 = canvas.toDataURL(file.type, 0.7)
                    } else if (fileSize >= 1 && fileSize < 2) {
                        //如果图片大于1M并且小于2M 那么压缩0.5
                        base64 = canvas.toDataURL(file.type, 0.5);
                    } else {
                        //如果图片超过2m 那么压缩0.2
                        base64 = canvas.toDataURL(file.type, 0.2);
                    }
                    resolve(dataURLtoFile(base64))
                }
            }
        }

    })

}

export default compress