<template>
	<!-- 调课 -->
	<div>
		<el-card>
			<!-- 面包屑 -->
			<bread-c :value_breadcrumb="bread"></bread-c>
			<!-- 面包屑/ -->
			<!-- 添加步骤条 -->
			<v-step :src="navigation" :index="activeIndex" ref="stepRef" :isHaveTab="false" @upStep_pre="upStep_pre = $event.func"
			 @upStep_next="upStep_next = $event.func" class="mg-t-10"></v-step>
			<!-- 选择班级 -->
			<div v-if="index === 0" class="mg-t-10">
				<div class="flex">
					<el-input size="small" class="mg-r-10" @blur="screen_class" v-model="value_key">
						<i slot="suffix" class="el-input__icon el-icon-search"></i>
					</el-input>
					<el-select @change="screen_class" v-model="value_select_course" size="small" class="mg-lr-10" placeholder="请选择课程" clearable>
						<el-option v-for="(item,index) in courseLists" :label="item.name" :value="item.id" :key="index"></el-option>
					</el-select>
					<!-- <el-button size="small" type="primary" @click="$router.push('/education/courseManager/addClass')">新增班级</el-button> -->
				</div>
				<table-c class="mg-t-10" :show_table="show_table_class" :button="button_class" :data_table="data_table_class"
				 :tableData="tableData_class" @handleCurrentChange="selection_change_class($event)" @handleCurrentChangePagination='handleCurrentChangePagination($event)'></table-c>
			</div>

			<!-- 调入课时 -->
			<div v-if="index === 1" class="mg-t-10">
				<table-class-time-c class="mg-t-10" :myid="id_class" :showAddClassHours="'bringUp'" @upData="upData=$event.func"
				 @current_change="current_change_classhours($event)"></table-class-time-c>
			</div>
			<!-- 选择学员 -->
			<div v-if="index === 2" class="mg-t-10">
				<div class="flex">
					<el-input size="small" @change="getStudentByCourse" v-model="screen.name" clearable>
						<i slot="suffix" class="el-input__icon el-icon-search" @click="getStudentByCourse"></i>
					</el-input>
				</div>
				<el-table :data="studentLists" @current-change="current_change_student" highlight-current-row>
					<el-table-column prop="name" label="姓名"></el-table-column>
					<el-table-column prop="sex" label="性别">
						<template v-slot='scope'>
							{{scope.row.sex == 1 ? '男' : (scope.row.sex == 2 ? '女' : '未知')}}
						</template>
					</el-table-column>					
					<el-table-column prop="tel" label="联系方式"></el-table-column>					
				</el-table>
				<el-pagination class="mg-t-10" background layout="total, prev, pager, next" :current-page.sync="screen.page" :total="pagination.total" @current-change="getStudentByCourse">
				</el-pagination>
			</div>
			<!-- 调出课时 -->
			<div v-if="index === 3" class="mg-t-10">
				<table-class-time-c class="mg-t-10" :myid="id_class" :sid='value_student.id' :showAddClassHours="'callIn'"
				 @current_change="current_change_attendance($event)"></table-class-time-c>
			</div>
			<!-- 控制步骤按钮 -->
			<button-c :value_button2="value_button" :isShow_button1="isShow_button" @next-step="next_step" @pre-step="pre_step"
			 class="mg-t-10"></button-c>
			<!-- 控制步骤按钮/ -->
		</el-card>
	</div>
</template>

<script>
	import vali from "../../../assets/js/validate";
	import vStep from "../../common/v-step.vue";
	import tableC from "../../common/v-table-class.vue";
	import tableClassTimeC from "../../common/v-table-classhour";
	import params from "../../../assets/js/params.js";
	import {
		table_test,
		getAge_range,
		isSpace,
	} from "../../../assets/js/myMethods.js";
	import buttonC from "../../student/form/studentCourseRegister-button.vue";
	import breadC from "../../common/v-breadcrumb";
	import dialogC from "../../common/v-dialog";
	import courseManagerCourseInfo from "./courseManager-courseInfo";
	import {
		getStudentByCourse
	} from '../../../assets/js/API/student.js'
	import {
		getCourse
	} from '../../../assets/js/API/course.js'
	export default {
		name: 'BatchAdjustMakeUpHour',
		data() {
			return {
				screen: {
					page: 1,
					name: ''
				},
				pagination: {
					total: 0
				},
				studentLists: [],
				course_classification_id: '', //选中的班级里面的课程分类id				
				courseLists: [],
				value_select_course: '',
				form_http: {
					date: "",
					sid: 1,
					time_start: "",
					time_end: "",
					cid: "",
					name: "",
					attendanceId: ''
				},
				value_student: {
					id: ''
				},
				value_classhours: "",
				value_key_student: "",
				value_select_studentState: "",
				value_key: "",
				currentRow: "",
				dialogVisible_adjustment: false,
				dialog_course: {
					show: false,
					title: "所属课程",
					button: true,
					width: "80%",
				},
				dialog_class_houer: {
					show: false,
					title: "新增课时",
					button: true,
					width: "50%",
				},
				// 所属课程信息表格选中的值
				value_select_class_course: "",

				options: [{
						label: "未分班",
						value: "0"
					},
					{
						label: "已分班",
						value: "1"
					},
				],
				id_class: "",
				bread: ["教务管理", "调课"],
				index: 0,
				value_button: "下一步",
				isShow_button: false,
				// 表格的样式
				show_table_class: {
					// 单选
					isSingle: true,
					// 斑马纹
					isStripe: false,
					// 多选
					isMultiple: false,
					// 是否要操作
					isOperation: false,
					// 排序
					isSortable: true,
				},
				// 表格按钮状态
				button_class: {
					button1: {
						// 是否显示
						state: true,
						// 按钮类型
						type: "primary",
						// 按钮文本
						value: "选择",
					},
					button2: {
						state: true,
						type: "warning",
						value: "编辑",
					},
					button3: {
						state: true,
						type: "danger",
						value: "删除",
					},
				},
				// 表格配置
				data_table_class: [],
				data_table_class1: {
					range_age: "",
					capacity: 22,
					className: "123",
					// class_time: "",
					classroomName: "321",
					id: 85,
					lesson: 12,
					status: 1,
					studentCount: 1,
					teacherName: "超级管理员",
				},
				tableData_class: {
					current_page: 0,
					data: [],
					total: 0,
				},
				id_modify: "",
				// 要提交到后台的数据
				data_addClass_http: {
					courseId: "",
					classRoomId: "",
					teacherId: "",
					name: "",
					class_time: "",
					date: "",
					age_start: "",
					age_end: "",
					lesson: "",
					mainTeacher: "",
					alternativeTeacher: 0,
					student: [],
				},
				navigation: [{
						title: "选择班级",
						name: "0",
						slotName: "class"
					},
					{
						title: "调入课时",
						name: "1",
						slotName: "bringUp"
					},
					{
						title: "选择学员",
						name: "2",
						slotName: "student"
					},
					{
						title: "调出课时",
						name: "3",
						slotName: "callIn"
					},
				],
				activeIndex: "0",
				courseTimeVisible: false,
				userVisible: false,
				form: {
					name: "",
					class_time: "",
					date: "",
					age_start: "",
					age_end: "",
					lesson: "",
					mainTeacher: "",
					alternativeTeacher: "",
					student: "",
				},
				pickOptions: {
					start: "07:00",
					end: "19:00",
					step: "00:10",
				},
				userDialogVisible: false,
				userAlternativeDialogVisible: false,
				classStudentDialogVisible: false,
				showTime: [],
			};
		},
		components: {
			vStep,
			tableC,
			buttonC,
			breadC,
			tableClassTimeC,
			dialogC,
			courseManagerCourseInfo,
		},
		methods: {
			getStudentByCourse () {
				var params = {...this.screen};
				params.course_classification_id = this.course_classification_id
				getStudentByCourse.call(this, params)
								  .then( res => {
									  if (res) {
										  this.pagination.total = res.total
										  this.studentLists = res.data
									  }
								  })
								  .catch( err => console.log(err))
			},
			getCourse () {
				getCourse.call(this)
						 .then( res => {
							 if (res) this.courseLists = res 
						 })
						 .catch( err => console.log(err))
			},
			handleCurrentChangePagination(page) {
				this.upTable_class({
					page
				})
			},
			current_change_classhours(e) {
				this.value_classhours = e.id;
			},
			current_change_attendance(e) {
				this.form_http.attendanceId = e.id.id
			},
			current_change_student(row) {
				this.value_student = row ? row : { id: '' };
			},
			// 班级筛选
			screen_class() {
				// 筛选条件
				var p = {
					course_classification_id: this.value_select_course,
					key: this.value_key,
				};
				~(function() {
					isSpace(p.key) ? delete p.key : "";
					isSpace(p.course_classification_id) ? delete p.course : "";
				})();
				~(async () => {
					this.upTable_class(p)
				})();
			},
			// 是否可以跳转到下一步骤
			isLeave() {
				if (this.index === 0 && length(this.id_class) === 0) return true;
				if (this.index === 1 && length(this.value_classhours) === 0) return true;
				if (this.index === 2 && length(this.value_student.id) === 0) return true;
				if (this.index === 3 && length(this.form_http.attendanceId) === 0) return true;
				return false;

				function length(val) {
					return String(val).trim().length;
				}
			},
			state_step(val) {
				switch (val) {
					case 0:
						this.step_one();
						break;
					case 1:
						this.step_two();
						break;
					case 2:
						this.step_three();
						break;
					case 3:
						this.step_four();
						break;
					default:
						break;
				}
			},
			// 上一步
			pre_step() {
				this.upStep_pre((active, length) => {
					this.index = active;
					this.state_step(active);
				});
			},
			// 下一步
			async next_step() {
				if (this.isLeave()) {
					this.$message.info("请填写完整");
					return;
				}
				if (this.index === 1) {
					this.upData();
				}
				this.addCourse_before()
				if (this.index === 3 && (await this.addCourse())) {
					this.$message.success("调课成功");
					this.$router.push("/education");
					return;
				}
				this.upStep_next((active, length) => {
					this.index = active;
					this.state_step(active);
				});
			},
			// 步骤一的状态
			step_one() {
				this.isShow_button = false;
				this.value_button = "下一步";
			},
			// 步骤二的状态
			step_two() {
				this.isShow_button = true;
				this.value_button = "下一步";
			},
			// 步骤三的状态
			step_three() {
				this.isShow_button = true;
				this.value_button = "下一步";
				this.getStudentByCourse()
			},
			// 步骤四的状态
			step_four() {
				this.isShow_button = true;
				this.value_button = "完成";
			},

			selection_change_class(e) {
				this.id_class = e.id.id;
				this.course_classification_id = e.id.course_classification_id;
			},

			// 添加课程之前对数据进行处理
			addCourse_before() {
				this.form_http.cid = this.id_class;
				this.form_http.sid = this.value_student.id;
				this.form_http.name = this.getWeek(this.value_classhours.date);
				Object.assign(this.form_http, this.value_classhours);
			},
			getWeek(d) {
				var date;
				if (this.isNull(d)) {
					date = new Date();
				} else {
					var dateArray = d.split("-");
					date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
				}
				return "星期" + "日一二三四五六".charAt(date.getDay());
			},
			isNull(object) {
				if (object == null || typeof object == "undefined") {
					return true;
				}
				return false;
			},
			// 添加临时的加课
			async addCourse() {
				this.addCourse_before();
				const {
					data: res
				} = await this.$http.post(
					"education/addClassTime",
					this.form_http
				);
				if (res.code === 200) {
					return true;
				}
				return false;
			},
			async upTable_class_before(param) {
				var data = await this.getClass(param);
				if (data) {
					data.data.forEach((e) => {
						e.range_age = getAge_range(e);
					});
				}
				return data;
			},
			async upTable_class(param) {
				this.tableData_class = await this.upTable_class_before(param);
			},
			/* 获取班级信息 */
			async getClass(param) {
				var params = {
					page: 1,
					age_start: 1,
					age_end: 999,
					type: 1
				};
				param && Object.assign(params, param);
				const {
					data: res
				} = await this.$http.get("/education/classes", {
					params,
				});
				if (res.code === 200) {
					return res.data;
				}
				return false;
			},
		},
		async created() {
			this.getCourse()
			this.data_table_class = table_test(
				this.data_table_class1,
				params.table_class
			);
			this.upTable_class();
		},
		watch: {
			"form.age_start": function(val) {
				if (isNaN(val - 0)) return;
				var s = this.form.age_end === "" ? 999 : this.form.age_end;
				if (val > s) {
					this.$message.error("最小年龄不能大于最大的年龄");
					this.form.age_start = 1;
				}
				if (val < 0) {
					this.$message.error("最小年龄不能小于0");
					this.form.age_start = 1;
				}
			},
			"form.age_end": function(val) {
				if (isNaN(val - 0)) return;
				var s = this.form.age_start === "" ? 1 : this.form.age_start;
				if (val < s) {
					this.$message.error("最大年龄不能小于最小的年龄");
					this.form.age_end = 999;
				}
				if (val > 999) {
					this.$message.error("最大年龄不能大于999");
					this.form.age_start = 999;
				}
			},
		},
	};
</script>

<style scoped>
	.el-input {
		width: 25% !important;
	}
</style>
