/*************************** 薪资标准API*************************** */
function stringToNumber (params) {
	for(let i in params) {
		params[i] = Number(params[i])
	}
}

export async function getSingle (params) {
	/* {
		page: 1,
		time: '月份筛选'
	} */
	const { data: res } = await this.$http.get('/organization/salary/single/salary', {
		params
	})
	if (res.code == 200) {
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function modifySalaryTemplateOther (params) {
	/* {
		new_registration_student: '新学员报名提成',
		old_registration_student: '老学员续费提成',
		bonus_monthly_lesson: '月度备课奖励',
		bonus_monthly_visit: '阅读回访奖励',
		full_time: '全勤奖'
	} */
	const { data: res } = await this.$http.patch('/organization/salaryTemplate', params)
	if (res.code == 200) {
		this.$message.success('保存成功')
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function addSalaryTemplateOther (params) {
	/* {
		new_registration_student: '新学员报名提成',
		old_registration_student: '老学员续费提成',
		bonus_monthly_lesson: '月度备课奖励',
		bonus_monthly_visit: '阅读回访奖励',
		full_time: '全勤奖'
	} */
	stringToNumber(params);
	const { data: res } = await this.$http.post('/organization/salaryTemplate/other', params)
	if (res.code == 200) {
		this.$message.success('保存成功')
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function addSalaryTemplate (params) {
	/* {
		name: [1, 3],  设置规则
		money: 122,  奖励
		type: 'monty, half, quarterly'   month - 月度转化奖励  half - 半年续费奖励  quarterly - 季度转化奖励
	} */
	const { data: res } = await this.$http.post('/organization/salaryTemplate', params)
	if (res.code == 200) {
		this.$message.success('添加成功')
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function deleteSalaryTemplate (params) {
	/* {
		id: '奖励规则id'
		type: 'monty, half, quarterly'   month - 月度转化奖励  half - 半年续费奖励  quarterly - 季度转化奖励
	} */
	const { data: res } = await this.$http.delete('/organization/salaryTemplate', {
		params
	})
	if (res.code == 200) {
		this.$message.success('删除成功')
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function modifySalaryTemplate (params) {
	/* {
		name: [1, 3],  设置规则
		money: 122,  奖励
		type: 'monty, half, quarterly'   month - 月度转化奖励  half - 半年续费奖励  quarterly - 季度转化奖励
	} */
	const { data: res } = await this.$http.patch('/organization/salaryTemplate/updateAwardMonthly', params)
	if (res.code == 200) {
		this.$message.success('修改成功')
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function getSalaryTemplate () {
	const { data: res } = await this.$http.get('/organization/salaryTemplate')
	if (res.code == 200) {
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function getClassFee (page) {
	//page当前页
	page = page ? page : 1
	const { data: res } = await this.$http.get('/organization/classFee?page=' + page);
	if (res.code == 200) {
		return res.data
	}	
}

export async function addClassFee (params) {
	/* {
		level: 1, //星级
		classfee_standard: ''  //课时费
	} */
	const { data: res } = await this.$http.post('/organization/classFee', params)
	if (res.code == 200) {
		this.$message.success('添加成功')
		return true
	} else {
		this.$message.error(res.msg)
	}
}

export async function modifyClassFee (params, id) {
	/* {
		level: 1, //星级
		classfee_standard: ''  //课时费
	} 
	id 星级id*/
	const { data: res } = await this.$http.patch('/organization/classFee/' + id, params)
	if (res.code == 200) {
		this.$message.success('修改成功')
		return true
	} else {
		this.$message.error(res.msg)
	}
}

export async function delClassFee ( id) {
	//id 星级id
	const { data : res } = await this.$http.delete('/organization/classFee/' + id);
	if (res.code == 200) {
		this.$message.success('删除成功');
		return true
	} else {
		this.$message.error(res.msg)
	}
}


export async function getTeacherSalary (teacherId, page) {
	const { data : res } = await this.$http.get('/organization/salary/' + teacherId, {
		params: {
			page
		}
	});
	if (res.code == 200) {
		return res.data
	} else {
		// this.$message.error(res.message)
	}
}

export async function getTeacherSalaryDetail (salaryId) {
	const { data : res } = await this.$http.get('/organization/salary/detail/' + salaryId);
	if (res.code == 200) {
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

export async function addTeacherSalary (params) {
	const { data : res } = await this.$http.post('/organization/salary', params);
	if (res.code == 200) {
		this.$message.success('添加成功')
		return true
	} else {
		this.$message.error(res.msg)
	}
}

export async function modifyTeacherSalary (params, salaryId) {
	const { data : res } = await this.$http.patch('/organization/salary/' + salaryId, params);
	if (res.code == 200) {
		this.$message.success('修改成功')
		return true
	} else {
		this.$message.error(res.msg)
	}
}

export async function attendanceUpdate (params) {
	/* {
		time: 2020-09,
		day: '实际出勤天数'
	} */
	const { data : res } = await this.$http.patch('/organization/salary/attendance/update', params);
	if (res.code == 200) {
		this.$message.success('修改成功')
		return true
	} else {
		this.$message.error(res.msg)
	}
}

