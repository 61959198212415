<template>
  <!-- 上课场地基础信息 -->
  <div>
    <div class="header">
      <div>
        <el-button type="primary" @click="addClassRoom" size="small">新增上课场地</el-button>
      </div>
    </div>
    <!-- 表格区域 -->
    <el-table :data="classroomsList" @current-change='current_change($event)' row-key="id" class="fsMedia" :highlight-current-row='isHightlight' :row-class-name="tableRowClassName">
      <el-table-column prop="name" show-overflow-tooltip label="场地名称" width="150px">
      </el-table-column>

      <el-table-column prop="capacity" label="可容纳人数" show-overflow-tooltip align="center" width="100px">
      </el-table-column>

      <el-table-column label="关联班级" show-overflow-tooltip min-width="100px">
        <template slot-scope="scope">
          <el-tag size="small" v-for="(item, index) in scope.row.classroomsHasClass" :key="index" class="mg-r-10" v-if="item != ''">{{item}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" fixed="right" width="150px">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="formVisibleByLook(scope.row)">查看</el-button>
          <el-button size="small" type="primary" @click="delVisible(scope.row)" v-if="scope.row.classroomsHasClass.length <= 0">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- form -->
    <el-dialog title="上课场地" :visible.sync="formVisible" width="40%" @close="formClose">
      <el-form :model="formClassRoom" ref="formClassRoomRef">
        <el-form-item label="场地名称" prop="name" :rules="formValidate.name">
          <el-input type="text" placeholder="请输入场地名称" v-model="formClassRoom.name"></el-input>
        </el-form-item>

        <el-form-item label="可容纳人数" prop="capacity" :rules="formValidate.capacity">
          <el-input type="text" placeholder="请输入可容纳人数" v-model.number="formClassRoom.capacity"></el-input>
        </el-form-item>

        <el-form-item label="详细信息" prop="detail" :rules="formValidate.detail">
          <el-input type="text" placeholder="请输入详细信息" v-model="formClassRoom.detail"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="formVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateClassRoomByCommit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- form/ -->

    <el-pagination background layout="prev, pager, next" :current-page="page" @current-change="pageChange" :total="total">
    </el-pagination>
  </div>
</template>

<script>
/* 引入拖拽组件 */
import Sortable from 'sortablejs'
/* 验证 */
import classRoomsValidate from '../../../assets/validate/organization/classroomsValidate.js'
import pagiC from '../../common/v-pagination.vue'
export default {
  name: 'ClassRoomInfo',
  props: {
    isHightlight: {
      type: Boolean,
      default: false
    },
    selectRowIndex: {
      default: -1
    }
  },
  data() {
    return {
      rowIndex: -1,
      classroomsList: [],
      /* 上课场地form */
      formVisible: false,
      formClassRoom: {
        id: '',
        name: '',
        capacity: '',
        detail: ''
      },
      formValidate: '',
      isAdd: false,
      page: 1,
      total: 0
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (this.rowIndex == rowIndex) return 'hight-light-row'
      return ''
    },
    /* 新增场地信息 */
    addClassRoom() {
      this.formVisible = true;
      this.formClassRoom = {
        id: '',
        name: '',
        capacity: '',
        detail: ''
      }
      this.isAdd = true;
    },
    // 表格行选中事件
    current_change($event) {
      this.rowIndex = -1;
      this.$emit('current_change', {
        id: $event
      })
    },
    /* 改变页码事件 */
    pageChange(page) {
      this.page = page;
      this.getClassRooms();
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      var newArr = JSON.parse(JSON.stringify(_this.classroomsList))
      Sortable.create(tbody, {
        onEnd({
          newIndex,
          oldIndex
        }) {
          const currRow = _this.classroomsList.splice(oldIndex, 1)[0]
          _this.classroomsList.splice(newIndex, 0, currRow)
          _this.sortClassRooms();
        }
      })
    },
    /* 排序上课场地 */
    async sortClassRooms() {
      let ids = []
      this.classroomsList.forEach((item) => {
        ids.push(item.id);
      })
      const {
        data: res
      } = await this.$http.patch('organization/classrooms', {
        'ids': ids
      });
      if (res.code == 200) {
        this.getClassRooms();
        this.$message.success("排序成功");
      }

    },
    /* 获取上课场地信息 */
    async getClassRooms() {
      const {
        data: res
      } = await this.$http.get('organization/classrooms?page=' + this.page);
      if (res.code == 200) {
        this.classroomsList = res.data.data;
        this.page = res.data.current_page;
        this.total = res.data.total;
        this.returnIndex()
      }
    },
    /* 更新某个场地信息 */
    async updateClassRoomByCommit() {
      if (this.isAdd) {
        const {
          data: res
        } = await this.$http.post('organization/classrooms', this.formClassRoom);
        if (res.code == 200) {
          this.getClassRooms();
          this.formVisible = false;
          this.isAdd = false;
          this.$message.success("成功");
        }
        return;
      }
      const {
        data: res
      } = await this.$http.patch('organization/classrooms/' + this.formClassRoom.id, this.formClassRoom);
      if (res.code == 200) {
        this.getClassRooms();
        this.formVisible = false;
        this.isAdd = false;
        this.$message.success("修改成功");
      }
    },
    /* 显示form */
    formVisibleByLook(row) {
      this.formClassRoom.name = row.name;
      this.formClassRoom.capacity = row.capacity;
      this.formClassRoom.detail = row.detail;
      this.formClassRoom.id = row.id;
      this.formVisible = true;
    },
    /* 获取上课场地详细信息 */
    async getClassRoomsDetail(id) {
      const {
        data: res
      } = await this.$http.get('organization/classrooms' + id);
      if (res.code == 200) {
      }
    },
    /* form关闭 */
    formClose() {
      this.$refs.formClassRoomRef.resetFields();
      this.formClassRoom = {
        id: '',
        name: '',
        capacity: '',
        detail: ''
      }
    },
    /* 删除上课场地 */
    async delVisible(row) {
      const result = await this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err);
      if ('confirm' == result) {
        const {
          data: res
        } = await this.$http.delete('organization/classrooms/' + row.id);
        if (res.code == 200) {
          this.getClassRooms();
          this.$message.success("删除成功");
        } else {
          this.$message.error("删除失败，该场地有班级情况不能删除");
        }
      }
    },
    /* 根据上课场地获取在已有上课场地的Index */
    returnIndex() {
      this.rowIndex = this.classroomsList.findIndex(item => {
        return item.id == this.selectRowIndex
      });
      this.rowIndex >= 0 && (this.$emit('update:courseNumber', this.classroomsList[this.rowIndex].capacity))
    }
  },
  mounted() {
    this.getClassRooms();
    /* 实例化拖拽组件方法 */
    //使每次都可以拖拽
    setTimeout(() => {
      this.rowDrop()
    }, 100);
    this.formValidate = classRoomsValidate.formValidate;
  },
  components: {
    /* 拖拽组件 */
    Sortable,
    pagiC
  },
  watch: {
    'selectRowIndex': function (val) {
      this.returnIndex()
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #888888;
}
/deep/ .el-table .hight-light-row {
  background-color: #fed90e;
}
</style>
