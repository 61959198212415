<template>
  <!-- 薪资管理 -->
  <div>
    <breadcrumb-c :value_breadcrumb='value_breadcrumb'></breadcrumb-c>
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-date-picker v-model="time" type="month" align="right" unlink-panels @change="timeChange" format="yyyy 年 MM" value-format="yyyy-MM" :clearable="false" style="width: 100%;" size="small">
          </el-date-picker>
        </el-col>
        <el-col v-if="getRole !== '教务老师' && getRole !== '教学老师' && getRole !== '招生老师'" :span='10' :offset='1'>
          <div class="flex align-center">
            <div style="min-width: 120px" class="fs-subtitle"><span style="color: rgb(64 158 255);">{{time.split('-')[1]}}</span>月应出勤天数</div>
            <el-input v-model="attendance_day" placeholder="应该出勤天数" size="small" class="flex_1-1"></el-input>
            <el-button class='mg-l-10' type="primary" size="small" style="margin-left: 10px;" @click='attendanceUpdate'>确定</el-button>
            <!-- <el-select v-model="attendance_day" :style="{width: '100%'}" @change="dayChange">
							<el-option v-for="item in dayOption" :key='item.value' :value='item.value' :label="item.label"></el-option>
						</el-select> -->
          </div>
        </el-col>
      </el-row>
      <el-table :data="user" class="fsMedia" stripe size='small'>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="tel" label="联系电话"></el-table-column>
        <el-table-column prop="level" label="星级(星)" align="center"></el-table-column>
        <el-table-column prop="base_salary" label="底薪(/元)" align="center"></el-table-column>
        <el-table-column prop="moth_performance" min-width="150" label="当月绩效(/元)" align="center">
          <template v-slot="scope">
            {{scope.row | getMothPerformance}}
          </template>
        </el-table-column>
        <el-table-column prop="classFee" min-width="120" label="课时费(/元)" align="center">
          <template v-slot='scope'>
            {{scope.row.classFee == null ? 0 : scope.row.classFee}}
          </template>
        </el-table-column>
        <el-table-column prop="month_salary" min-width="150" label="当月应发(/元)" align="center">
          <template v-slot="scope">
            {{scope.row | getMonthSalary}}
          </template>
        </el-table-column>
        <el-table-column prop="attendance_day" min-width="180" label="本月应出勤天数(/天)" align="center"></el-table-column>
        <el-table-column prop="real_attendance_day" min-width="180" label="本月实际出勤天数(/天)" align="center"></el-table-column>
        <el-table-column label="操作" fixed='right' width='220px'>
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="$router.push('/organization/salary-all?teacherId=' + scope.row.sid)">所有信息</el-button>
            <el-button v-if="getRole !== '教务老师' && getRole !== '教学老师' && getRole !== '招生老师'" type="primary" size="mini" @click="$router.push('/organization/salaryInfo?teacherId=' + scope.row.sid + '&salaryId=' + scope.row.id)">详细信息</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="page" :total="total" layout="total, prev, pager, next" background class="mg-t-10" @current-change='pageChange'></el-pagination>
    </el-card>
  </div>
</template>

<script>
import breadcrumbC from '../common/v-breadcrumb.vue'
import {
  getSingle,
  attendanceUpdate
} from '../../assets/js/API/salary.js'
import {
  formatDate
} from '../../assets/js/myMethods.js'
import {
  getRole
} from '../../assets/js/common.js'
import {
  getMothPerformance,
  getMonthSalary
} from '../../assets/js/CONFIG/salary.js'
export default {
  name: 'Salary',
  data() {
    return {
      value_breadcrumb: ['机构管理', '薪资管理'],
      user: [],
      page: 1,
      time: '',
      attendance_day: '',
      total: 0,
      dayOption: []
    }
  },
  computed: {
    getRole() {
      return getRole()
    }
  },
  methods: {
    attendanceUpdate() {
      var reg = /^[\d]+$/;
      if (!reg.test(this.attendance_day)) {
        return this.$message.error('请输入整数')
      }
      if (this.attendance_day > this.dayOption.length) {
        return this.$message.error('应出勤天数不应该大于本月最大的日期' + this.dayOption[this.dayOption.length - 1].value)
      }
      attendanceUpdate.call(this, { time: this.time, day: this.attendance_day })
        .then(res => {

        })
        .catch(err => {
          console.log(err)
        })
    },
    pageChange(page) {
      this.page = page;
      this.getSingle()
    },
    timeChange() {
      this.page = 1;
      this.attendance_day = '';
      this.getSingle()
    },
    getSingle() {
      getSingle.call(this, { page: this.page, time: this.time })
        .then(res => {
          this.user = res.data;
          this.page = res.current_page;
          this.total = res.total
        })
        .catch(err => { console.log(err) })
    },
    computedTime() {
      var date = new Date();
      this.time = formatDate.computed(date, 1, 'reduce', 'mouth', 'yyyy-mm')
    },
    computedDay(date) {
      const DATE = date ? new Date(date) : new Date(),
        year = DATE.getFullYear(),
        month = DATE.getMonth();
      var attendance_day = new Date(year, month + 1, 0).getDate();
      for (let i = 1; i <= attendance_day; i++) {
        this.dayOption.push({
          value: i,
          label: i + '天'
        })
      }
    }
  },
  components: {
    breadcrumbC
  },
  filters: {
    getMothPerformance(val) {
      return getMothPerformance(val)
    },
    getMonthSalary(val) {
      return getMonthSalary(val)
    }
  },
  created() {
    this.computedTime();
    this.computedDay();
    this.getSingle();
  }
}
</script>

<style>
</style>
