/*
	2021-03-010 由FZY创建 
	@describe 学员作品记录
*/
<template>
	<div>
		<el-date-picker v-model="screen.date" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" size="medium" @change="screenChange" :cleclearable="false"></el-date-picker>
		<div class="fs-18 fontWeightB text-align-c mg-t-10" style="color: #888;" v-if="workLists.length == 0">暂无作品记录</div>
		<el-timeline class='mg-t-10' v-else>
		   <el-timeline-item :timestamp="item.date + ' ' + item.week" placement="top" v-for='(item, index) in workLists' :key='item.id' style='position: relative;' :id="'work' + index">
		     <el-card>
				<div class="title">{{item.name}}</div>
				<p v-html="item.comment" style="white-space: pre-line;" class="comment"></p>
				<div style="white-space: nowrap;overflow-x: auto;overflow-y: hidden;" class="mg-t-10">
					<div  class="boxImg" v-for="(img, i) in item.works" :key='i' @click="preview(img)" :style="{ 'background-color': img.success ? '' : 'rgba(0, 0, 0, 0.08)'}">
						<img :src="img.url" alt="作品请求失败" style="width: 100%;height: 100%;" v-if="imgORvideoM(img)" @load='load(index, i, $event)' @error='error(index, i, $event)'>
						<video v-else style="width: 100%;height: 100%;">
							<source :src="img.url" type="video/mp4"></source>
							<source :src="img.url" type="video/ogg"></source>
							<source :src="img.url" type="video/webm"></source>
							您的浏览器不支持看视频
						</video>
						<div class="loading" v-show="!img.success">
							<i class="el-icon-loading"></i>
						</div>
					</div>					
				</div>
		     </el-card>
		   </el-timeline-item>
		   <div class="iconfont icon-z044 more" title="点击加载更多" @click="more"></div>
		   <div class="returnTop el-icon-caret-top" @click="goAnchor('#work0')" v-show="workLists.length > 3"></div>
		 </el-timeline>
		 <!-- 作品展示 -START -->
		 <el-dialog :visible.sync="previewWorkDialogShow" :modal-append-to-body='false' append-to-body>
		 	<div class="text-align-c">
		 		<img width="100%" :src="previewWork.url" alt="作品" v-if="imgORvideoM(previewWork)">
		 		<video controls height="300" v-else>
		 			<source :src="previewWork.url" type="video/mp4"></source>
		 			<source :src="previewWork.url" type="video/ogg"></source>
		 			<source :src="previewWork.url" type="video/webm"></source>
		 			您的浏览器不支持看视频
		 		</video>
		 	</div>						
		 </el-dialog>
		 <!-- 作品展示 -END -->
	</div>
</template>
<script>
	import {
		formatDate
	} from '../../../assets/js/myMethods.js'
	import {
		extend,
		checkFileType
	} from '../../../assets/js/util.js'
	import {
		StringToUTF
	} from '../../../assets/js/utf.js'
	import {
		getStudentAttendanceByTime
	} from '../../../assets/js/API/student.js'
	var DATE_NOW = new Date();
	const DATE_ARR = [
	    //本周
	    {
	        haveMonth: [formatDate.getWeekStartAndEnd(DATE_NOW).date_start, formatDate.getWeekStartAndEnd(DATE_NOW).date_end],
	        noMonth: [formatDate.detail(formatDate.getWeekStartAndEnd(DATE_NOW).date_start, 'yyyy-mm'), formatDate.detail(formatDate.getWeekStartAndEnd(DATE_NOW).date_end, 'yyyy-mm')]
	    }
	]
	DATE_NOW = null;
	export default {
		name: 'StudentWorkLists',
		props: {
			studentId: {
				default: ''
			}
		},
		data () {
			return {
				isCanClick: true,
				number: 5,
				currentIndex: 0,
				previewWork: {},
				previewWorkDialogShow: false,
				screen: {
					sid: '',
					date: []
				},
				workLists: [],
				  
				workLists_cache: []
			}
		},
		methods: {
			screenChange () {
				this.workLists = [];
				this.workLists_cache = [];
				this.getStudentAttendanceByTime()
			},
			load (index, i, e) {
				this.workLists[index].works[i].success = true;
				this.$forceUpdate()
			},
			error (index, i, e) {
				this.workLists[index].works[i].success = true;
				this.$forceUpdate()
			},
			goAnchor (selector) {
				var el = document.querySelector(selector);
				el.scrollIntoView({
					behavior: 'smooth'
				})
			},
			resetClick () {
				setTimeout( () => this.isCanClick = true, 500)
			},
			more () {
				if (!this.isCanClick) return this.$message.error('正在处理中...');
				if (this.currentIndex >= this.workLists_cache.length - 1) return this.$message.info('已经到底了');
				this.isCanClick = false;
				this.currentIndex++;				
				this.workLists.push(...this.formatData());
				this.resetClick()
			},
			preview (img) {
				this.previewWork = img;
				this.previewWorkDialogShow = true;				
			},
			imgORvideoM (file) {
				var imgORvideo = checkFileType(file.type ? file.type : '');
				if (imgORvideo.img) return true
				else if (imgORvideo.video) return false				
			},
			getStudentAttendanceByTime () {
				var params = { ...this.screen}
				params.date_start = params.date[0];
				params.date_end = params.date[1];
				delete params.date
				getStudentAttendanceByTime.call(this, params)
					.then( res => {
						if (res) {
							var arr = [];
							for (let i = 0; i < res.length / this.number; i++) {
								arr[i] = res.slice(this.number * i, this.number * i + this.number)
							}
							this.workLists_cache = arr;
							this.workLists_cache.length > 0 && this.workLists.push(...this.formatData());
						}
					})
			},
			formatData () {
				return this.workLists_cache[this.currentIndex].map( item => {
					if (typeof item.works == 'string') {
						try {
							item.works = JSON.parse(item.works);
						} catch(e) {
							item.works = []
						}
					}
					item.comment = StringToUTF(item.comment);
					item.success = false
					return item
				})
			}
		},
		created() {
			this.screen.sid = this.$route.query.id || this.studentId;
			this.getStudentAttendanceByTime()
		}
	}
</script>

<style scoped>
	.title{
		font-weight: bold;
		font-size: 16px;
	}
	.comment{
		font-size: 14px;
		margin-top: 5px
	}
	.boxImg {
		margin-right: 10px;
		display: inline-block;
		width: 100px;
		height: 100px;
		overflow: hidden;
		border-radius: 5px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
		cursor: pointer;
		position: relative;
	}
	.more{
		font-size: 20px;
		cursor: pointer;
		position: absolute;
		bottom: 0px;
		left: 50%;
		transform: translate(-50%, 0);
		opacity: 0.2;
	}
	.returnTop{
		color: #888;
		width: 35px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.07);
		border-radius: 4px;
		font-size: 35px;
		cursor: pointer;
		position: fixed;
		bottom: 50px;
		right: 70px;
	}
	.loading{		
		font-size: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@media screen and (max-width: 1499px) {
		.boxImg{
			width: 80px;
			height: 80px;
		}
		.more{
			font-size: 15px;
		}
		.returnTop{
			width: 25px;
			height: 25px;
			line-height: 25px;
			border-radius: 2px;
			font-size: 25px;
		}
		.loading{
			font-size: 25px;
		}
	}
	@media screen and (min-width: 1500px) {
		
	}
</style>
