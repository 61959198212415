<template>
  <div>
    <breadcrumb-c :value_breadcrumb="value_breadcrumb"></breadcrumb-c>
    <el-button type="primary" size="small" icon="el-icon-plus" style="margin-bottom:10px" @click="createdEdit('created')">添加</el-button>
    <el-card>
      <!-- 表格 -->
      <el-table :data="tableData" @expand-change="expandChange" :expand-row-keys="expands" :row-key="getRowKey">
        <el-table-column type="expand" width="20">
          <template slot-scope="scoped">
            <el-tabs type="border-card" v-model="activeTab" @tab-click="handleTabClick">
              <el-tab-pane label="周边小区" name="first">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addPeriphery(1)">添加</el-button>
                <div class="childTableClass">
                  <el-table :data="peripheryData">
                    <el-table-column type="index" label="序号" align="center" width="50">
                    </el-table-column>
                    <el-table-column label="小区名称" prop="house_name" min-width="150" show-overflow-tooltip align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.house_name?scoped.row.house_name:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="详细地址" prop="detail" min-width="150" show-overflow-tooltip align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.detail?scoped.row.detail:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="距离(米)" prop="distance" show-overflow-tooltip align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.distance?scoped.row.distance:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="小区均价" prop="house_count" show-overflow-tooltip align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.house_count?scoped.row.house_count:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="入住人口(人)" min-width="150" prop="population_in" show-overflow-tooltip align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.population_in?scoped.row.population_in:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="入住率" prop="occupancy_rate" show-overflow-tooltip align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.occupancy_rate|rateFilters}}
                      </template>
                    </el-table-column>
                    <el-table-column label="联系方式" min-width="150" prop="tel" show-overflow-tooltip align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.tel?scoped.row.tel:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="活动费用(元/天)" min-width="200" show-overflow-tooltip prop="activity_cost" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.activity_cost?scoped.row.activity_cost:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="200" align="center" fixed="right">
                      <template slot-scope="scoped">
                        <el-button type="primary" size="small" @click="addPeriphery(2,scoped.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="delRow(1,scoped.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="周边学校" name="second">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="showSchoolDialog(1)">添加</el-button>
                <div class="childTableClass">
                  <el-table :data="schoolData">
                    <el-table-column type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column label="学校名称" show-overflow-tooltip min-width="150" prop="campus_name" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.campus_name?scoped.row.campus_name:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="学校类型" show-overflow-tooltip prop="campus_type" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.campus_type?scoped.row.campus_type:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="详细地址" min-width="120" show-overflow-tooltip prop="detail" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.campus_type?scoped.row.campus_type:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="生源数" show-overflow-tooltip prop="student_source_num" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.campus_type?scoped.row.campus_type:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="费用区间(元)" show-overflow-tooltip min-width="150" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.price_min}} - {{scoped.row.price_max}}
                      </template>
                    </el-table-column>
                    <el-table-column label="放学时间" show-overflow-tooltip prop="after_school_time" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.after_school_time?scoped.row.after_school_time:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="兴趣班开设" show-overflow-tooltip min-width="150" prop="interest_class" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.interest_class?scoped.row.interest_class:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="备注" show-overflow-tooltip prop="remark" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.remark?scoped.row.remark:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="200" align="center" fixed="right">
                      <template slot-scope="scoped">
                        <el-button type="primary" size="small" @click="showSchoolDialog(2,scoped.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="delRow(2,scoped.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="培训机构" name="third">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="showTrainDialog(1)">添加</el-button>
                <div class="childTableClass">
                  <el-table :data="trainData">
                    <el-table-column type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column label="机构名称" min-width="150" show-overflow-tooltip prop="compete_name" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.compete_name?scoped.row.compete_name:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="校区规模(㎡)" show-overflow-tooltip min-width="150" prop="scale" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.scale?scoped.row.scale:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="课程设置(科目/理念)" show-overflow-tooltip prop="course_set" align="center" min-width="180">
                      <template slot-scope="scoped">
                        {{scoped.row.course_set?scoped.row.course_set:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="开业时间" prop="open_time" show-overflow-tooltip min-width="120" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.open_time?scoped.row.open_time:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="学员基数" show-overflow-tooltip prop="student_base" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.student_base?scoped.row.student_base:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="收费区间(元/节)" show-overflow-tooltip min-width="180" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.price_min}} - {{scoped.row.price_max}}
                      </template>
                    </el-table-column>
                    <el-table-column label="招生方式" show-overflow-tooltip prop="enrollment_mode" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.enrollment_mode?scoped.row.enrollment_mode:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="综合实力" show-overflow-tooltip prop="comprehensive" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.comprehensive?scoped.row.comprehensive:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="200" align="center" fixed="right">
                      <template slot-scope="scoped">
                        <el-button type="primary" size="small" @click="showTrainDialog(2,scoped.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="delRow(3,scoped.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="店铺" name="fourth">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="showShopDialog(1)">添加</el-button>
                <div class="childTableClass">
                  <el-table :data="shopData">
                    <el-table-column type="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column label="店铺名称" min-width="150" show-overflow-tooltip prop="shop_name" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.shop_name?scoped.row.shop_name:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="联系方式" min-width="150" show-overflow-tooltip prop="tel" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.tel?scoped.row.tel:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="详细地址" min-width="150" show-overflow-tooltip prop="address" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.address?scoped.row.address:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="租金" show-overflow-tooltip prop="rent" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.rent?scoped.row.rent:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="付款方式" show-overflow-tooltip prop="pay_type" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.pay_type?scoped.row.pay_type:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="建筑面积" show-overflow-tooltip prop="build_area" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.build_area?scoped.row.build_area:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="使用面积" show-overflow-tooltip prop="use_area" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.use_area?scoped.row.use_area:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="照片" prop="images" align="center">
                      <template v-slot="scope">
                        <span class v-if="scope.row.images==''||!scope.row.images">-</span>
                        <el-image v-else style="width: 30px; height: 30px" :src="scope.row.images[0].url" :preview-src-list="getPreviewLists(scope.row.images)"></el-image>
                      </template>
                    </el-table-column>
                    <el-table-column label="备注" show-overflow-tooltip prop="remark" align="center">
                      <template slot-scope="scoped">
                        {{scoped.row.remark?scoped.row.remark:'-'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="200" align="center" fixed="right">
                      <template slot-scope="scoped">
                        <el-button type="primary" size="small" @click="showShopDialog(2,scoped.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="delRow(4,scoped.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </template>
        </el-table-column>
        <el-table-column label="商圈名称" prop="name" align="center"></el-table-column>
        <el-table-column label="详细地址" align="center" prop="detail" min-width="120"></el-table-column>
        <el-table-column label="商圈是否成熟" align="center" prop="is_mature" min-width="120">
          <template v-slot="scoped">
            {{scoped.row.is_mature==2?'不成熟':'成熟'}}
          </template>
        </el-table-column>
        <el-table-column label="联系方式" align="center" prop="tel" min-width="120"></el-table-column>
        <el-table-column label="备注" align="center" prop="remark" min-width="100"></el-table-column>
        <el-table-column label="评论" prop="comment" min-width="70" align="center">
          <template v-slot="scope">
            <span class="cursor-p c-409eff" @click="viewComment(scope.row)">查看评论</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="created_at" min-width="120"></el-table-column>
        <el-table-column width="150px" align="center" label="操作">
          <template v-slot="scoped">
            <el-button type="primary" size="mini" @click="createdEdit('updated',scoped.row)">编辑</el-button>
            <el-button type="primary" size="mini" @click="onComment(scoped.row)">评论</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="total, prev, pager, next" :current-page.sync="listQuery.page" :total="total" :page-size='10' class="mg-t-10" @current-change='getTableData'></el-pagination>
    </el-card>

    <!-- 添加/编辑信息对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="35%" center :before-close="before_close" @close="close_dialog" :modal-append-to-body="false">
      <div class="dialogContent">
        <el-form :model="form" ref="form" label-position="left" :rules="rules" label-width="105px">
          <el-form-item prop="name" size="mini" label="商圈名称:">
            <el-input v-model="form.name" placeholder="请输入商圈名称" style="width: 200px" clearable></el-input>
          </el-form-item>
          <el-form-item prop="detail" size="mini" label="详细地址:">
            <el-input v-model="form.detail" placeholder="请输入详细地址" style="width: 200px" clearable></el-input>
          </el-form-item>
          <el-form-item prop="is_mature" size="mini" label="商圈是否成熟:">
            <el-select v-model="form.is_mature" placeholder="请选择" style="width: 200px">
              <el-option label="成熟" :value="1">
              </el-option>
              <el-option label="不成熟" :value="2">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="tel" size="mini" label="联系方式:">
            <el-input v-model="form.tel" placeholder="请输入联系方式" style="width: 200px" clearable></el-input>
          </el-form-item>
          <el-form-item prop="remark" size="mini" label="备注:">
            <el-input v-model="form.remark" type="textarea" :rows="3" placeholder="请输入备注内容" style="width: 300px" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hide">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加评论" :visible.sync="commentVisible" width="35%" center :modal-append-to-body="false" append-to-body>
      <el-form :model="commentForm" ref="commentForm" label-position="left" :rules="commentRules" label-width="80px">
        <el-form-item prop="text" label="评论">
          <el-input type="textarea" v-model="commentForm.text" placeholder="请输入评论内容"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="commentVisible=false">取 消</el-button>
        <el-button type="primary" @click="commentConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看评论 -START-->
    <el-drawer size="40%" :visible.sync="drawerVisible" class="drawer">
      <div v-if="commentLists.length == 0" style="text-align: center;height: 50px;line-height: 50px;font-size: 16px;">暂无评论</div>
      <el-timeline v-else style="padding: 0 10px;">
        <el-timeline-item v-for="(item, index) in commentLists" :key="'comment' + index" :timestamp="item.created_at" placement="top">
          <el-card>
            <p class="fs-12">{{item.name}}</p>
            <h4 class="fs-14">{{item.content}}</h4>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-drawer>
    <!-- 查看评论 -END -->
    <el-dialog :title="dialogPeripheryTitle" :visible.sync="dialogPeriphery" width="35%" center :modal-append-to-body="false" append-to-body>
      <div class="dialogContent">
        <el-form ref="peripheryForm" :model="peripheryForm" label-width="80px" :rules="peripheryRules">
          <el-form-item size="mini" label="小区名称:" prop="house_name">
            <el-input v-model="peripheryForm.house_name" style="width: 180px" clearable></el-input>
          </el-form-item>
          <el-form-item size="mini" label="详细地址:" prop="detail">
            <el-input v-model="peripheryForm.detail" style="width: 180px" clearable></el-input>
          </el-form-item>
          <el-form-item size="mini" label="距离(米):" prop="distance">
            <el-input v-model="peripheryForm.distance" style="width: 180px" clearable></el-input>
          </el-form-item>
          <el-form-item size="mini" label="小区均价:" prop="price">
            <el-input v-model="peripheryForm.price" style="width: 180px" clearable></el-input>&nbsp;&nbsp;元/平方
          </el-form-item>
          <el-form-item size="mini" label="入住人口:" prop="population_in	">
            <el-input v-model="peripheryForm.population_in" style="width: 180px" clearable></el-input>
          </el-form-item>
          <el-form-item size="mini" label="入住率:" prop="occupancy_rate">
            <el-select v-model="peripheryForm.occupancy_rate" placeholder="请选择" style="width: 180px">
              <el-option label="低" :value="1">
              </el-option>
              <el-option label="中" :value="2">
              </el-option>
              <el-option label="高" :value="3">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="mini" label="联系方式:" prop="tel">
            <el-input v-model="peripheryForm.tel" style="width: 180px" clearable></el-input>
          </el-form-item>
          <el-form-item size="mini" label="活动费用:" prop="activity_cost">
            <el-input v-model="peripheryForm.activity_cost" style="width: 180px" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialogPeriphery=false">取 消</el-button>
        <el-button type="primary" @click="peripheryConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="dialogSchoolTitle" :visible.sync="dialogSchool" width="35%" center :modal-append-to-body="false" append-to-body>
      <div class="dialogContent">
        <el-form ref="schoolForm" :model="schoolForm" label-width="90px" :rules="schoolFormRules">
          <el-form-item size="mini" label="学校名称:" prop="campus_name">
            <el-input v-model="schoolForm.campus_name" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="学校类型:" prop="campus_type">
            <el-input v-model="schoolForm.campus_type" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="详细地址:" prop="detail">
            <el-input v-model="schoolForm.detail" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="生源数:" prop="student_source_num">
            <el-input v-model="schoolForm.student_source_num" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="费用区间:" prop="price_min">
            <el-input v-model="schoolForm.price_min" style="width: 130px"></el-input> — <el-input v-model="schoolForm.price_max" style="width: 130px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="放学时间:" prop="after_school_time">
            <el-time-select v-model="schoolForm.after_school_time" style="width: 180px" placeholder="放学时间" value-format="HH:mm" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}">
            </el-time-select>

          </el-form-item>
          <el-form-item label="兴趣班开设:" size="mini" prop="interest_class">
            <el-input v-model="schoolForm.interest_class" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item prop="remark" size="mini" label="备注:">
            <el-input v-model="schoolForm.remark" type="textarea" :rows="3" placeholder="请输入备注内容" style="width: 200px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialogSchool=false">取 消</el-button>
        <el-button type="primary" @click="schoolConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="dialogTrainTitle" :visible.sync="dialogTrain" width="35%" center :modal-append-to-body="false" append-to-body>
      <div class="dialogContent">
        <el-form ref="trainForm" :model="trainForm" label-width="120px" :rules="trainRules">
          <el-form-item size="mini" label="机构名称:" prop="compete_name">
            <el-input v-model="trainForm.compete_name" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="校区规模(㎡):" prop="scale">
            <el-input v-model="trainForm.scale" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="详细地址:" prop="detail">
            <el-input v-model="trainForm.detail" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="课程设置:" prop="course_set">
            <el-input v-model="trainForm.course_set" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="开业时间:" prop="open_time">
            <el-date-picker v-model="trainForm.open_time" type="date" placeholder="选择开业时间" style="width: 200px" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item size="mini" label="收费区间(元/节):">
            <el-input v-model="trainForm.price_min" style="width: 50px"></el-input> — <el-input v-model="trainForm.price_max" style="width: 50px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="招生方式:" prop="enrollment_mode">
            <el-input v-model="trainForm.enrollment_mode" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item prop="comprehensive" size="mini" label="综合实力:">
            <el-input v-model="trainForm.comprehensive" type="textarea" :rows="3" placeholder="请输入相关信息" style="width: 200px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialogTrain=false">取 消</el-button>
        <el-button type="primary" @click="trainConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="dialogShopTitle" :visible.sync="dialogShop" width="35%" center :modal-append-to-body="false" append-to-body>
      <div class="dialogContent">
        <el-form ref="shopForm" :model="shopForm" label-width="80px" :rules="shopRules">
          <el-form-item size="mini" label="店铺名称:" prop="shop_name">
            <el-input v-model="shopForm.shop_name" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="联系方式:" prop="tel">
            <el-input v-model="shopForm.tel" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="详细地址:" prop="address">
            <el-input v-model="shopForm.address" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="租金:" prop="rent">
            <el-input v-model="shopForm.rent" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="付款方式:" prop="pay_type">
            <el-input v-model="shopForm.pay_type" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="建筑面积:">
            <el-input v-model="shopForm.build_area" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item size="mini" label="使用面积:">
            <el-input v-model="shopForm.use_area" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item label="照片:">
            <upload-c :file-lists="computedFile(shopForm.images)" accept="image/jpeg,image/png" @success="success" @remove="remove"></upload-c>
          </el-form-item>
          <el-form-item prop="remark" size="mini" label="备注:">
            <el-input v-model="shopForm.remark" type="textarea" :rows="3" placeholder="请输入备注内容" style="width: 180px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialogShop=false">取 消</el-button>
        <el-button type="primary" @click="shopConfirm">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import breadcrumbC from "../common/v-breadcrumb.vue";
import uploadC from "../common/iteration-upload.vue";
import {
  selectGetList,
  selectAddOrgan,
  selectUpOrgan,
  selectAddComment,
} from "../../assets/js/API/siteInfo.js";
import {
  reGetList,
  reAddOrgan,
  AddOrganHouse,
  AddOrganCampus,
  AddOrganCompete,
  AddOrganShops,
  getOrganChildList
} from "@/api/selWhere";
import vali from "../../assets/js/validate";
import { extend } from "../../assets/js/util.js";
export default {
  data() {
    return {
      value_breadcrumb: ["机构管理", "选址信息"],
      listQuery: {
        page: 1,
        number: 10
      },
      total: 0,
      tableData: [],
      villageData: [],
      dialogVisible: false,
      dialogTitle: '添加商圈',
      rules: {
        name: vali.validatorCommon.mandatory("请输入商圈名称"),
        detail: vali.validatorCommon.mandatory("请输入详细地址"),
        is_mature: vali.validatorCommon.mandatory("请选择商圈是否成熟"),
        tel: vali.validatorCommon.mandatory("请输入联系方式"),
        num: [
          ...vali.validatorCommon.mandatory("请输入小区总户数"),
          ...vali.validatorCommon.number,
        ],
        num_in: [
          ...vali.validatorCommon.mandatory("请输入已入住户数"),
          ...vali.validatorCommon.number,
          {
            validator: (rule, value, callback) => {
              var index = rule.field.split(".")[1];
              var num = this.villageSchoolForm.village[index].num - 0;
              if (num < value - 0)
                return callback(new Error("已入住户数不能大于小区总户数"));
              callback();
            },
            trigger: "blur",
          },
        ],
        campus_name: vali.validatorCommon.mandatory("请输入学校名称"),
        campus_type: vali.validatorCommon.mandatory("请输入学校类型"),
        student_source_num: [
          ...vali.validatorCommon.mandatory("请输入生源数"),
          ...vali.validatorCommon.number,
        ],
        price: [
          ...vali.validatorCommon.mandatory("请输入学费价格"),
          ...vali.validatorCommon.float("学费价格格式不正确"),
        ],
        compete_name: vali.validatorCommon.mandatory("请输入名称"),
        compete_price: [
          ...vali.validatorCommon.mandatory("请输入课单价"),
          ...vali.validatorCommon.float("课单价格式不正确"),
        ],
        student_source_count: [
          ...vali.validatorCommon.mandatory("请输入生源数"),
          ...vali.validatorCommon.number,
        ],
        shop_name: vali.validatorCommon.mandatory("请输入店铺名称"),
        rent: [
          ...vali.validatorCommon.mandatory("请输入租金"),
          ...vali.validatorCommon.float("租金格式不正确"),
        ],
        area: [
          ...vali.validatorCommon.mandatory("请输入面积（㎡）"),
          ...vali.validatorCommon.number,
        ],
        address: vali.validatorCommon.mandatory("请输入详细地址"),
      },

      peripheryRules: {
        house_name: vali.validatorCommon.mandatory("请输入小区名称"),
        detail: vali.validatorCommon.mandatory("请输入详细地址"),
        price: vali.validatorCommon.mandatory("请输入小区均价"),
        occupancy_rate: vali.validatorCommon.mandatory("请选择入住率"),
      },
      schoolFormRules: {
        campus_name: vali.validatorCommon.mandatory("请输入学校名称"),
        campus_type: vali.validatorCommon.mandatory("请输入学校类型"),
        detail: vali.validatorCommon.mandatory("请输入详细地址"),
      },
      trainRules: {
        compete_name: vali.validatorCommon.mandatory("请输入机构名称"),
        scale: vali.validatorCommon.mandatory("请输入规模"),
        detail: vali.validatorCommon.mandatory("请输入详细地址"),
      },
      shopRules: {
        shop_name: vali.validatorCommon.mandatory("请输入店铺名称"),
        tel: vali.validatorCommon.mandatory("请输入联系方式"),
        address: vali.validatorCommon.mandatory("请输入详细地址"),
        rent: vali.validatorCommon.mandatory("请输入租金"),
        pay_type: vali.validatorCommon.mandatory("请输入付款方式"),
      },
      form: {
        id: "",
        name: "",
        detail: "",
        tel: "",
        is_mature: "",
        remark: ""
      },
      expands: [],
      villageSchoolForm: {
        village: [
          {
            house_name: "",
            num: "",
            num_in: "",
          },
        ],
        school: [
          {
            campus_name: "",
            campus_type: "",
            student_source_num: "",
            price: "",
          },
        ],
        compete: [
          {
            compete_name: "",
            compete_price: "",
            student_source_count: "",
          },
        ],
        shops: [
          {
            shop_name: "",
            rent: "",
            area: "",
            address: "",
          },
        ],
      },
      commentLists: [],
      commentVisible: false,
      drawerVisible: false,
      commentForm: {
        text: "",
      },
      commentRules: {
        text: vali.validatorCommon.mandatory("请输入评论信息"),
      },
      updateData: {},
      previewLists: [],
      villageSchoolDel: {
        village: [],
        school: [],
        compete: [],
        shops: [],
      },
      activeTab: 'first',
      tabsList: [
        {
          name: "community",
          label: "周报小区",
        },
        {
          name: "school",
          label: "周边学校",
        },
        {
          name: "organization",
          label: "培训机构",
        },
        {
          name: "shop",
          label: "店铺",
        },
      ],
      peripheryData: [],
      schoolData: [],
      trainData: [],
      shopData: [],
      dialogPeriphery: false,
      dialogPeripheryTitle: '',
      peripheryForm: {
        id: '',
        house_name: '',
        distance: '',
        price: '',
        detail: '',
        occupancy_rate: '',
        population_in: '',
        tel: '',
        activity_cost: '',
      },
      dialogSchool: false,
      dialogSchoolTitle: '',
      schoolForm: {
        id: '',
        campus_name: '',
        campus_type: '',
        detail: '',
        student_source_num: '',
        price_min: '',
        price_max: '',
        after_school_time: '',
        activity_cost: '',
        interest_class: '',
        remark: '',
      },
      dialogTrain: false,
      dialogTrainTitle: '',
      trainForm: {
        compete_name: '',
        scale: '',
        detail: '',
        course_set: '',
        price_min: '',
        price_max: '',
        open_time: '',
        student_base: '',
        enrollment_mode: '',
        comprehensive: '',
        id: ''

      },
      childTableQuery: {
        organ_select_id: '',
        type: 1
      },
      childTableData: [],
      dialogShop: false,
      dialogShopTitle: '',
      shopForm: {
        shop_name: '',
        tel: '',
        address: '',
        rent: '',
        pay_type: '',
        build_area: '',
        use_area: '',
        images: '',
        id: '',
        remark: '',
      },

    };
  },
  computed: {
    title_dialog() {
      return `${this.actionType == "created" ? "添加" : "编辑"}选址信息`;
    },
  },
  methods: {
    getRowKey(row) {
      return row.id;
    },
    computedFile(imgs) {
      if (typeof lists === "string") imgs = imgs.split("");
      if (imgs == '' || imgs == null || imgs == undefined) return
      if (imgs.length) return imgs;
      return [];
    },
    viewComment(row) {
      this.commentLists = row.comment;
      this.drawerVisible = true;
    },
    getPreviewLists(lists) {
      if (typeof lists === "string") return [];
      return lists.map((item) => item.url);
    },
    getTableData() {
      reGetList(this.listQuery).then(res => {
        this.tableData = res.data.data.data
        this.total = res.data.data.total
      }).catch(err => {
        console.log(err)
      })
    },
    getOrganChildList() {
      getOrganChildList(this.childTableQuery).then(res => {
        if (this.childTableQuery.type == 1) {
          this.peripheryData = res.data.data
        } else if (this.childTableQuery.type == 2) {
          this.schoolData = res.data.data
        } else if (this.childTableQuery.type == 3) {
          this.trainData = res.data.data
        } else {
          this.shopData = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add(type) {
      let form;
      if (type == "village") {
        form = {
          house_name: "",
          num: "",
          num_in: "",
        };
      } else if (type == "school") {
        form = {
          campus_name: "",
          campus_type: "",
          student_source_num: "",
          price: "",
        };
      } else if (type == "compete") {
        form = {
          compete_name: "",
          compete_price: "",
          student_source_count: "",
        };
      } else {
        form = {
          shop_name: "",
          rent: "",
          area: "",
          address: "",
        };
      }
      this.villageSchoolForm[type].push(form);
    },
    del(type, index, item) {
      if (item.id) {
        var dels = this.villageSchoolForm[type].splice(index, 1);
        dels[0].del = 1;
        this.villageSchoolDel[type].push(...dels);
      } else this.villageSchoolForm[type].splice(index, 1);
    },
    // 弹出创建/编辑
    createdEdit(type, val) {
      if (type == "created") {
        this.form = {
          id: "",
          name: "",
          detail: "",
          tel: "",
          is_mature: "",
          remark: ""
        };
        this.dialogTitle = '添加商圈'

      } else {
        extend(this.form, val)
        this.dialogTitle = '编辑商圈'
      }
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    confirm() {
      this.$refs.form.validate((res) => {
        reAddOrgan(this.form).then(resp => {
          if (resp.data.code == 200) {
            this.getTableData()
            if (this.form.id == '') {
              this.$message.success('添加商圈成功')
            } else {
              this.$message.success('编辑商圈成功')
            }
            this.dialogVisible = false
          }
        }).catch(err => {
          console.log(err)
        })
      });
    },
    close_dialog() {
      this.$refs.form.resetFields();
    },
    before_close(done) {
      done();
    },
    // 图片上传成功
    success({ lists }) {
      this.shopForm.images = lists;
    },
    remove({ lists }) {
      this.shopForm.images = lists;
    },
    onComment(row) {
      this.updateData = row;
      this.commentVisible = true;
    },
    commentConfirm() {
      let params = {
        content: this.commentForm.text,
        organ_select_id: this.updateData.id,
      };
      selectAddComment.call(this, params).then((res) => {
        this.commentVisible = false;
        this.commentForm.text = "";
        this.getTableData();
      });
    },
    handleTabClick() {
      switch (this.activeTab) {
        case 'first':
          this.childTableQuery.type = 1
          break;
        case 'second':
          this.childTableQuery.type = 2
          break;
        case 'third':
          this.childTableQuery.type = 3
          break;
        case 'fourth':
          this.childTableQuery.type = 4
          break;
      }
      this.getOrganChildList()
    },
    delRow(type, val) {
      this.$confirm('确定删除此信息', '提示', { type: 'warning' })
        .then(() => {
          switch (type) {
            case 1:
              extend(this.peripheryForm, val)
              AddOrganHouse({ is_delete: 1, organ_select_id: this.childTableQuery.organ_select_id, ...this.peripheryForm }).then(resp => {
                if (resp.data.code == 200) {
                  this.getOrganChildList()
                  this.$message.success('删除周边小区成功')

                }
              })
              break;
            case 2:
              extend(this.schoolForm, val)
              AddOrganCampus({ is_delete: 1, organ_select_id: this.childTableQuery.organ_select_id, ...this.schoolForm }).then(resp => {
                if (resp.data.code == 200) {
                  this.getOrganChildList()
                  this.$message.success('删除周边学校成功')

                }
              })
              break;
            case 3:
              extend(this.trainForm, val)
              AddOrganCompete({ is_delete: 1, organ_select_id: this.childTableQuery.organ_select_id, ...this.trainForm }).then(resp => {
                if (resp.data.code == 200) {
                  this.getOrganChildList()
                  this.$message.success('删除培训机构成功')

                }
              })
              break;
            case 4:
              extend(this.shopForm, val)
              AddOrganShops({ is_delete: 1, organ_select_id: this.childTableQuery.organ_select_id, ...this.shopForm }).then(resp => {
                if (resp.data.code == 200) {
                  this.getOrganChildList()
                  this.$message.success('删除店铺成功')
                }
              })
              break;
          }
        })
        .catch(e => {
          this.$message.info('已取消删除')
        })
    },
    expandChange(row, expandedRows) {
      if (expandedRows.length) {
        this.childTableQuery.organ_select_id = row.id
        this.childTableQuery.type = 1
        this.activeTab = 'first'
        this.getOrganChildList()
        this.expands = []
        if (row) {
          this.expands.push(row.id)
        }
      } else {
        this.expands = []
      }
    },
    addPeriphery(type, val) {
      if (type == 1) {
        this.peripheryForm = {
          id: '',
          house_name: '',
          distance: '',
          price: '',
          detail: '',
          occupancy_rate: '',
          population_in: '',
          tel: '',
          activity_cost: '',
        }
        this.dialogPeripheryTitle = '添加周边小区'
      } else {
        this.dialogPeripheryTitle = '编辑周边小区'
        extend(this.peripheryForm, val)
      }
      this.dialogPeriphery = true
    },
    showSchoolDialog(type, val) {
      console.log(val)
      if (type == 1) {
        this.schoolForm = {
          id: '',
          campus_name: '',
          campus_type: '',
          detail: '',
          student_source_num: '',
          price_min: '',
          price_max: '',
          after_school_time: '',
          activity_cost: '',
          interest_class: '',
          remark: '',
        }
        this.dialogSchoolTitle = '添加周边学校'
      } else {
        this.dialogSchoolTitle = '编辑周边学校'
        extend(this.schoolForm, val)
      }
      this.dialogSchool = true
    },
    showTrainDialog(type, val) {
      if (type == 1) {
        this.trainForm = {
          compete_name: '',
          scale: '',
          detail: '',
          course_set: '',
          price_min: '',
          price_max: '',
          open_time: '',
          student_base: '',
          enrollment_mode: '',
          comprehensive: '',
          id: ''
        }
        this.dialogTrainTitle = '添加培训机构'
      } else {
        this.dialogTrainTitle = '编辑培训机构'
        extend(this.trainForm, val)
      }
      this.dialogTrain = true
    },
    showShopDialog(type, val) {
      if (type == 1) {
        this.shopForm = {
          shop_name: '',
          tel: '',
          address: '',
          rent: '',
          pay_type: '',
          build_area: '',
          use_area: '',
          images: '',
          id: '',
          remark: '',
        }
        this.dialogShopTitle = '添加店铺'
      } else {
        this.dialogShopTitle = '编辑店铺'
        extend(this.shopForm, val)
      }
      this.dialogShop = true
    },
    peripheryConfirm() {
      this.$refs.peripheryForm.validate((res) => {
        AddOrganHouse({ organ_select_id: this.childTableQuery.organ_select_id, ...this.peripheryForm }).then(resp => {
          if (resp.data.code == 200) {
            this.getOrganChildList()
            if (this.peripheryForm.id == '') {
              this.$message.success('添加周边小区成功')
            } else {
              this.$message.success('编辑周边小区成功')
            }
          }
          this.dialogPeriphery = false
        })
      })
    },
    schoolConfirm() {
      this.$refs.schoolForm.validate((res) => {
        AddOrganCampus({ organ_select_id: this.childTableQuery.organ_select_id, ...this.schoolForm }).then(resp => {
          if (resp.data.code == 200) {
            this.getOrganChildList()
            if (this.schoolForm.id == '') {
              this.$message.success('添加周边学校成功')
            } else {
              this.$message.success('编辑周边学校成功')
            }
          }
          this.dialogSchool = false
        })
      })
    },
    trainConfirm() {
      this.$refs.trainForm.validate((res) => {
        AddOrganCompete({ organ_select_id: this.childTableQuery.organ_select_id, ...this.trainForm }).then(resp => {
          if (resp.data.code == 200) {
            this.getOrganChildList()
            if (this.trainForm.id == '') {
              this.$message.success('添加培训机构成功')
            } else {
              this.$message.success('编辑培训机构成功')
            }
          }
          this.dialogTrain = false
        })
      })
    },
    shopConfirm() {
      this.$refs.shopForm.validate((res) => {
        AddOrganShops({ organ_select_id: this.childTableQuery.organ_select_id, ...this.shopForm }).then(resp => {
          if (resp.data.code == 200) {
            this.getOrganChildList()
            if (this.shopForm.id == '') {
              this.$message.success('添加店铺成功')
            } else {
              this.$message.success('编辑店铺成功')
            }
          }
          this.dialogShop = false
        })
      })
    },
  },
  mounted() {
    this.getTableData();
  },
  components: {
    breadcrumbC,
    uploadC,
  },
  filters: {
    rateFilters(val) {
      if (val == 1) {
        return '低'
      } else if (val == 2) {
        return '中'
      } else if (val == 3) {
        return '高'
      } else {
        return '-'
      }
    }
  },
  computed: {
  }
}
</script>
<style lang="less" scoped>
.village-wrap {
  padding: 20px 5px 5px 5px;
  border: 1px solid #eee;
  margin: 10px 0;
  position: relative;

  .village-del {
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.dialogContent {
  display: flex;
  justify-content: center;
}
/deep/ .drawer .el-drawer {
  overflow-y: auto;
  .el-card__body {
    padding: 0 20px;
  }
}
.childTableClass {
  /deep/ .el-table__fixed-right {
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
}
</style>
