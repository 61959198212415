/* 
	@describe 查询选址信息
	@date 2021-04-14
	@by LZW
*/
export async function selectGetList (params) {
	const { data: res } = await this.$http.get('/organization/select/getList',{params})
	if (res.code == 200) {
		return res.data
	} else {
		this.$message.error(res.msg)
	}
}

/* 
	@describe 添加选址信息
	@date 2021-04-14
	@by LZW
*/
export async function selectAddOrgan (params) {
	const { data: res } = await this.$http.post('/organization/select/addOrgan',params)
	if (res.code == 200) {
		this.$message.success('添加选址信息成功')
		return res.data
	}
}

/* 
	@describe 编辑选址信息
	@date 2021-04-14
	@by LZW
*/
export async function selectUpOrgan (params) {
	const { data: res } = await this.$http.post('/organization/select/upOrgan',params)
	if (res.code == 200) {
		this.$message.success('编辑选址信息成功')
		return res.data
	}
}

/* 
	@describe 选址信息评论
	@date 2021-04-14
	@by LZW
*/
export async function selectAddComment (params) {
	const { data: res } = await this.$http.post('/organization/select/addComment',params)
	if (res.code == 200) {
		this.$message.success('评论成功')
		return res.data
	}
}


