<template>
  <!-- 营收数据分析 -->
  <div>
    <el-card class="revenue-card" shadow="always">
        <div slot="header">
          <el-row type="flex" justify="space-between">
              <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
                <div class="revenue-label">校区营收数据</div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="20" :xl="20" style="text-align:right">
              <span><el-date-picker
                      style="width: 360px;"
                      size="medium"
                      v-model="time"
                      type="daterange"
                      unlink-panels
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="dataOptions"
                      :clearable="false"
                      @change="serach">
                  </el-date-picker>
              </span>
              <span style="margin-left:10px">
                  <el-select size="medium" clearable placeholder="选择校区" style="width: 150px;" @change="serach" v-model="campusId">
                      <el-option v-for="item in organizationOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
              </span>
              <!-- <span style="margin-left:10px">
                <el-button  size="medium" type="primary" icon="el-icon-upload2" @click="exportEvent">导出课耗</el-button>
              </span> -->
              </el-col>
          </el-row>
        </div>
        <div v-loading="dataLoading">
          <el-row :gutter="20">
            <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4">
              <div class="card-label">业绩(元)</div>
              <div class="card-price">{{revenueData['performance']||0 | filterPrice | insertSeparator}}</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4">
              <div class="card-label">流入金额(元)</div>
              <div class="card-price">{{revenueData['flow_in']||0 | filterPrice | insertSeparator}}</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4">
              <div class="card-label">流出金额(元)</div>
              <div class="card-price">{{revenueData['flow_out']||0 | filterPrice | insertSeparator}}</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4">
              <div class="card-label">新签人次</div>
              <div class="card-price">{{revenueData['new_sign_number']||0}}</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4">
              <div class="card-label">续费人次</div>
              <div class="card-price">{{revenueData['renew_number']||0}}</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4">
              <div class="card-label">退费人次</div>
              <div class="card-price">{{revenueData['refund_number']||0}}</div>
            </el-col>
          </el-row>
        </div>
    </el-card>
    <el-card class="revenue-card" shadow="always">
        <div slot="header">
          <div class="revenue-label">预收数据</div>
        </div>
        <div v-loading="dataLoading">
          <el-row :gutter="20">
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="card-wrap">
                <div class="card-label">剩余未消耗课时</div>
                <div class="card-price">{{calssData['lave_lesson']||0}}</div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="card-wrap">
                <div class="card-label">总课时</div>
                <div class="card-price">{{revenueData['total_class_hour']||0}}</div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="card-wrap">
                <div class="card-label">剩余未消耗金额(元)</div>
                <div class="card-price">{{calssData['lave_amount']||0 | filterPrice | insertSeparator}}</div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="card-wrap">
                <div class="card-label">总金额</div>
                <div class="card-price">{{revenueData['total_money']||0 | filterPrice | insertSeparator}}</div>
              </div>
            </el-col>
          </el-row>
        </div>
    </el-card>

    <el-card class="revenue-card" shadow="always">
        <div slot="header">
          <el-row>
            <el-col :xs="12" :sm="12" :md="12" :lg="3" :xl="3">
              <div class="revenue-label">经营数据</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="21" :xl="21">
              <div style="text-align:right">
                <span>
                  <el-radio-group size="medium" v-model="timeType" @change="canvasCheck">
                      <el-radio-button label="daterange">日</el-radio-button>
                      <el-radio-button label="monthrange">月</el-radio-button>
                    </el-radio-group>
                </span>
                <span style="margin-left:15px">
                    <el-date-picker
                        v-if="timeType=='daterange'"
                        style="width: 360px;"
                        size="medium"
                        v-model="canvasTime"
                        type="daterange"
                        unlink-panels
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @change="canvasSerach($event,'day')">
                    </el-date-picker>
                    <el-date-picker
                        v-else
                        style="width: 360px;"
                        size="medium"
                        v-model="canvasTime"
                        :type="timeType"
                        unlink-panels
                        value-format="yyyy-MM"
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份"
                        :picker-options="monthOptions"
                        @change="canvasSerach($event,'month')">
                    </el-date-picker>
                </span>
                <span style="margin-left:15px">
                  <el-select size="medium" clearable multiple placeholder="选择校区" style="width: 300px;" @change="canvasSerach" v-model="canvasCampusId">
                        <el-option v-for="item in campusOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-loading="canvasLoading">
          <el-row :gutter="12" class="analysis-canvas">
            <el-col class="analysis-item" :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-for="(item,index) in marketAnalysis" :key="index">
              <el-card shadow="always">
                {{item.label}}
                <analysis-canvas :dataItem="item" :loading="canvasLoading" :monthArr="marketArr" :organization="organizationOptions" :campusId="canvasCampusId" :lineId="'market'+(index+1)"/>
              </el-card>
            </el-col>
          </el-row>
        </div>
    </el-card>
  </div>
</template>
<script>
import axios from 'axios'
import AnalysisCanvas from "./AnalysisCanvas"
import {
  insertMoneySeparator,
} from "../../assets/js/util.js";
export default {
  name: "DataAnalysis",
  components:{
    AnalysisCanvas
  },
  data() {
    return {
      canvasLoading:false,
      revenueData:{
        performance:0,
        flow_in:0,
        flow_out:0,
        new_sign_number:0,
        renew_number:0,
        refund_number:0,
        total_class_hour:0,
        total_money:0
      },
      calssData:{
        lave_lesson:0,
        total_money:0
      },
      dataLoading:false,
      time:"",
      campusId:0,
      organizationOptions:[
        {
          id:0,
          name:"全部校区"
        }
      ],
      timeType:"daterange",
      canvasTime:"",
      canvasCampusId:null,
      campusOptions:[],
      marketArr:[],
      marketAnalysis:[],
      dataOptions:{
        disabledDate: (time) => {
          return time.getTime() >= (Date.now()-8.64e7);
        }
      },
      monthOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }],
        disabledDate: (time) => {
          return time.getTime() >= (Date.now()-8.64e7);
        }
      },
      pickerOptions:{
        disabledDate(date) {
          let zero = new Date();
          var nowdays = new Date();
          var year = nowdays.getFullYear();
          var month = nowdays.getMonth();
          if (month == 0) {
            month = 12;
            year = year - 1;
          }
          if (month < 10) {
            month = "0" + month;
          }
          var startDate = new Date(year + "-" + month + "-01 00:00:00"); //上个月第一天
          if (date.getTime() > zero || date.getTime() < startDate) {
            return true;
          }
          return false;
        },
      }
    }
  },
  filters: {
    insertSeparator(val) {
      return insertMoneySeparator(val);
    },
  },
  created(){
    this.getSchool()
  },
  methods: {
    /* 获取校区 */
    async getSchool() {
      let school = window.localStorage.getItem('schooleLists')
      if(school){
        school = JSON.parse(school)
        var arr = [
          {
            id: 0,
            name: "全部校区",
          },
        ];
        arr.push(...school);
        this.organizationOptions = [...arr];
        this.campusOptions = school||[]
      }else{
        var params = {
            page: 1,
            number: 999999,
          };
          const { data: res } = await this.$http.get("/organization/schoole", {
            params,
          });
          if (res.code == 200) {
            var arr = [
              {
                id: 0,
                name: "全部校区",
              },
            ];
            arr.push(...res.data.data);
            this.organizationOptions = [...arr];
            this.campusOptions = res.data.data||[]
          }
      }
      this.newMonth()
      this.serach()
      this.canvasSerach(1,'day')
    },
    async initClass(){
      let params = {}
      if(this.campusId) params['campus_id'] = this.campusId
        let url = `/edu/classHourAndMoney`
        await axios.get(url,{params}).then(response=>{
            if(response.data.code==200){
              this.calssData = response.data&&response.data.data||[]
            }
        })
    },
    // 营收数据、预收数据
    async serach(){
      this.dataLoading = true
      await this.initClass()
      let params = {}
      if(this.time&&this.time.length){
        params['date_start'] = this.time[0]
        params['date_end'] = this.time[1]
      }
      if(this.campusId) params['campus_id'] = this.campusId
        let url = `/edu/dataNumber`
        axios.get(url,{params}).then(response=>{
            if(response.data.code==200){
              this.revenueData = response.data&&response.data.data||[]
            }else{
              this.$message.error('加载失败')
            }
            setTimeout(() => {
                this.dataLoading = false
            }, 500);
        }).catch(err=>{
            this.$message.error('加载失败')
            setTimeout(() => {
                this.dataLoading = false
            }, 3*500);
        })
    },
    exportEvent(){},
    canvasCheck(){
      this.canvasSerach(1,this.timeType=='daterange'?'day':'month')
    },
    // 经营数据
    canvasSerach(e,type){
      if(type=='day'){
        let time = this.getDayAll(this.canvasTime[0], this.canvasTime[1])
        this.marketArr = time
      }else if(type=='month'){
        this.initMonthArr(this.canvasTime)
      }
      if(!this.canvasCampusId||!this.canvasCampusId.length){
        this.canvasCampusId = 0
      }

      let params = {
        'is_month':type=='month'?1:2
      }
      if(this.canvasTime&&this.canvasTime.length){
        params['date_start'] = this.canvasTime[0]
        params['date_end'] = this.canvasTime[1]
      }
      this.canvasLoading = true
      if(this.canvasCampusId) {
        params['campus_id'] = this.canvasCampusId.join(",")
      }
        let url = `/edu/viewNumber`
        axios.get(url,{params}).then(response=>{
            if(response.data.code==200){
              let data = response.data&&response.data.data||{}
              let marketAnalysis = this.marketAnalysis
              if(this.canvasCampusId&&this.canvasCampusId.length){
                for(let item of marketAnalysis){
                  var typeData = {}
                  for(let c in data){
                    typeData[c] = []
                    for(let t in data[c][item.type]){
                      typeData[c].push(Number(data[c][item.type][t]))
                    }
                  }
                  item['dataShow'] = true
                  item.datas = typeData
                }
                this.marketAnalysis = marketAnalysis
              }else{
                for(let item of marketAnalysis){
                  var typeData = []
                  for(let t in data[item.type]){
                    typeData.push(Number(data[item.type][t]))
                  }
                  item['dataShow'] = false
                  item.data = typeData
                }
                this.marketAnalysis = marketAnalysis
              }
              setTimeout(() => {
                if(e==1) this.canvasCampusId = 0
                this.canvasLoading = false
              }, 500);
            }else{
              setTimeout(() => {
                  this.canvasLoading = false
              }, 500);
            }
        }).catch(err=>{
            // this.$message.error('加载失败')
            setTimeout(() => {
                if(e==1) this.canvasCampusId = 0
                this.canvasLoading = false
            }, 3*500);
        })
    },
    newMonth(){
      let date = new Date()
      var yy = date.getFullYear();
      var mm = date.getMonth()+1;
      var dd = date.getDate();

      date.setMonth(date.getMonth()-1);
      var y = date.getFullYear();
      var m = date.getMonth()+1;
      var d = date.getDate();
      var end = yy+'-'+mm+'-'+dd;
      var start = y+'-'+m+'-'+d;
      let time = this.getDayAll(start, end)
      let timeArr = [start, end]
      this.time = timeArr
      this.canvasTime = timeArr
      this.initMonthArr(timeArr)
      this.marketArr = time

      let data = []
      for(let i=0;i<this.marketArr.length;i++){
        data.push(0)
      }
      this.marketAnalysis = [
        {id:1,label:"流入",data:data,datas:{},type:"flow_in"},
        {id:2,label:"流出",data:data,datas:{},type:"flow_out"},
        {id:4,label:"出勤人次",data:data,datas:{},type:"attendance"},
        {id:5,label:"出勤率",data:data,datas:{},type:"attendance_percent"},
        {id:6,label:"消耗课时数",data:data,datas:{},type:"classHour"},
      ]
      
    },
    initMonthArr(pickers){
      let monthArr = []
      let {end_y,end_m,differ} =  this.monthsBetw(pickers)
      monthArr = this.monthDate(end_y,end_m,differ)
      this.marketArr = monthArr
    },
    // 计算月份相差数组
    monthDate(year,month,differ){
      let datelist = []
      let Y = year
      let M = month
      for (let i = 0; i < differ; i++) {
        let dateoption = ''
        if (M - 1 >= 0) {
        } else {
          M = 12
          Y = Y - 1
        }
        let m = M
        m = m < 10 ? '0' + m : m
        dateoption = Y + '/' + m
        M--
        datelist.unshift(dateoption)
      }
      return datelist
    },
    // 计算相差月数
    monthsBetw(data){
      let srart_data = data[0],end_data = data[1];
      srart_data = srart_data.split('-')
      end_data = end_data.split('-')
      let srart_y = parseInt(srart_data[0]),
          srart_m = parseInt(srart_data[1]),
          end_y = parseInt(end_data[0]),
          end_m = parseInt(end_data[1]),
          differ = (end_y-srart_y)*12 + (end_m-srart_m)+1;
      return {end_y,end_m,differ}
    },
    // 获取时间日期数组
    getDayAll(starDay, endDay) {
    　var arr = [];
      var dates = [];
      // 设置两个日期UTC时间
      var db = new Date(starDay);
      var de = new Date(endDay);
      // 获取两个日期GTM时间
    　var s = db.getTime() - 24 * 60 * 60 * 1000;
    　var d = de.getTime() - 24 * 60 * 60 * 1000;
      // 获取到两个日期之间的每一天的毫秒数
  　　for (var i = s; i <= d;) {
  　　　i = i + 24 * 60 * 60 * 1000;
        arr.push(parseInt(i))
  　　}
      // 获取每一天的时间  YY-MM-DD
      for( var j in arr ){
          var time = new Date(arr[j]);
          var year = time.getFullYear(time);
          var mouth = (time.getMonth() + 1)>=10?(time.getMonth() + 1):('0'+(time.getMonth() + 1));
          var day = time.getDate()>=10?time.getDate():('0'+time.getDate());
          var YYMMDD = year + '-' + mouth + '-' + day;
          dates.push(YYMMDD)
      }
      return dates
    }
  },
};
</script>

<style scoped lang="less">
.revenue-card{
  margin: 15px 0;
}
.revenue-label{
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
}
.card-wrap{
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #eee;
  margin: 15px 5%;
}
.card-label{
  color: #666;
  font-size: 16px;
  line-height: 2;
}
.card-price{
  color: #333;
  font-size: 20px;
  font-weight: bold;
  line-height: 2;
}
.analysis-canvas{
    margin: 15px 0;
    .analysis-item{
      margin: 10px 0;
    }
  }
</style>
