import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'

import './assets/css/global.css'
import './assets/css/common.css'
import './plugins/element.js'
import { regionData, CodeToText } from "element-china-area-data";
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$regionData = regionData;
Vue.prototype.$CodeToText = CodeToText;
Vue.prototype.$moment = moment
/* 导入图标 */
import './assets/fonts/iconfont.css'
/* 防止重复点击 */
import preventReClick from './assets/js/preventReClick';

/* 配置axios */
import axios from 'axios'

/* 配置打印 */
import Print from 'vue-print-nb'
Vue.use(Print);  //注册

const BASE_URL = process.env.VUE_APP_BASE_URL + '/admin/v1';

/* baseurl */
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/admin/v1' : BASE_URL
/* axios.defaults.baseURL = BASE_URL */
// 开发环境
/* 设置拦截器 */
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.sessionStorage.getItem('token');
    config.headers.ticket = window.sessionStorage.getItem('checkSchoole');
    return config;
})

axios.interceptors.response.use(response => {
    return response
},
    error => {
        if (error.response) {

            switch (error.response.status) {
                case 200:
                    break;
                case 400:
                    Vue.prototype.$message({
                        type: "error",
                        message: error.response.data.msg || error.response.data.message
                    });
                    break;
                case 401:
                    Vue.prototype.$message({
                        type: "error",
                        message: error.response.data.msg || error.response.data.message
                    });
                    router.replace({
                        path: '/login'
                    });
                    break;
                case 403:
                    Vue.prototype.$message({
                        type: "error",
                        message: error.response.data.msg || error.response.data.message
                    });
                    router.replace({
                        path: '/login'
                    });
                    break;
                default:
                    this.$message.error(error.response.data.msg || error.response.data.message);
                    break;
            }
        }
        return Promise.reject(error.response.data)
    }
)
/* 挂载原型对象 */
Vue.prototype.$http = axios;
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (!to.meta.title) {
        document.title = "小河码教务管理系统"
    }
    next()
})


Vue.config.productionTip = false
/* VChat图表 */
/* 饼图 */
import Vepie from 'v-charts/lib/pie.common'
/* 柱状图 */
import VeHistogram from 'v-charts/lib/histogram.common'
/*水球图 */
import VeLiquidfill from "v-charts/lib/liquidfill.common"
/* 折线图 */
import VeLine from 'v-charts/lib/line.common'
Vue.component(Vepie.name, Vepie);
Vue.component(VeHistogram.name, VeHistogram);
Vue.component(VeLiquidfill.name, VeLiquidfill);
Vue.component(VeLine.name, VeLine);


/* 全局引入param  */
import params from './assets/js/params.js'
import menu from './assets/js/menu.js'
import { filter } from './assets/js/myMethods.js'
Vue.prototype.$param = params;
Vue.prototype.$menu = menu;


// 判断环境
if (process.env.NODE_ENV === 'development') { } else if (process.env.NODE_ENV === 'production') {
    console.log = function () { }
}
/* 全局过滤 */
Vue.filter('filterPrice', filter.filterPrice)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')