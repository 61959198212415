/**
@describe 小导航
@date 2021-04-08
@by FZY
*/
<template>
	<div v-show="defaultShow" class="navigation">
		<div v-for="(item, index) in routeLists" :key="'route' + index" :class="{'selectTag': selectRoute == index }" class="tag" @click.self="navigationTo(item, index)">
			{{item.name}}
			<i class="el-icon-close" @click.stop="remove(index)"></i>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		REMOVE_ROUTERLIST,
		SET_SELECTROUTE
	} from '../../store/mutations-type.js'
	export default {
		name: 'Navigation',
		props: {
			defaultShow: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				
			}
		},
		computed: {
			...mapState([
				'routeLists',
				'selectRoute'
			])
		},
		methods: {
			...mapMutations([
				REMOVE_ROUTERLIST,
				SET_SELECTROUTE
			]),
			remove (index) {
				this[REMOVE_ROUTERLIST](index)
			},
			navigationTo (item, index) {
				sessionStorage.setItem('activePath', item.path);
				this.$router.push(item.path)
			}
		}
	}
</script>

<style scoped>
	.navigation {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.tag {
		background-color: #3E9BF790;
		color: #fff;
		font-size: 12px;
		margin-bottom: 8px;
		cursor: pointer;
	}
	.tag.selectTag{
		background-color: #3E9BF7;
	}

	@media screen and (min-width: 1500px) {
		.tag {
			height: 25px;
			line-height: 25px;
			padding: 0px 8px;
			border-radius: 3px;
			margin-right: 10px;
		}
	}

	@media screen and (max-width: 1499px) {
		.tag {
			height: 23px;
			line-height: 23px;
			padding: 0px 7px;
			border-radius: 2px;
			margin-right: 5px;
		}
	}
</style>
