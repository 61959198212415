<template>
	<!--获取课程基本信息  -->
	<div>
		<!-- 表格区域 -->
		<el-table :data="courseList.data" @current-change='current_change' highlight-current-row style="width: 100%" class="fsMedia" :row-class-name="tableRowClassName">
			<el-table-column prop="name" label="课程名称" width="180">
			</el-table-column>
			<el-table-column prop="age" label="适龄范围" width="180">
			</el-table-column>
			<el-table-column prop="mode" label="上课模式" width="180">
				<template v-slot="scoped">
					{{scoped.row.mode == 1 ? "班级教学" : "一对一"}}
				</template>
			</el-table-column>
			<el-table-column prop="mode" label="考勤规则" min-width="180">
				<template v-slot="scoped">
					{{scoped.row.mode == 0 ? "不限制" : "可请假次数: " + scoped.row.leave_times + "次"}}
				</template>
			</el-table-column>
			<!-- 	<el-table-column
				prop="lesson"
				label="课时总数"
				width="180">
			</el-table-column> -->
			<el-table-column prop="frequency" label="上课频率" width="180">
			</el-table-column>
			<el-table-column prop="status" label="启用状态" width="180">
				<template v-slot="scoped">
					{{scoped.row.status == 1 ? "启用" : "关闭"}}
				</template>
			</el-table-column>
			<el-table-column label="操作" width="80" fixed="right" v-if='isHaveOperation'>
				<template slot-scope="scope">
					<el-button type="primary" size="small" @click="look(scope.row)">查看</el-button>
					<!-- 					<el-button type="primary" size="small">查看班级</el-button>
 -->
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<pagi-c class="mg-t-10" :currentPage='courseList.current_page' :total="courseList.total" @handleCurrentChange='handleCurrentChange_p($event)'></pagi-c>
		<!-- 分页 -->
	</div>
</template>

<script>
	import pagiC from '../../common/v-pagination.vue'
	import {
		getAge_range
	} from '../../../assets/js/myMethods'
	export default {
		name: 'CourseInfo',
		props: {
			isHaveOperation: {
				type: Boolean,
				default: true
			},
			selectRowIndex: {
				default: ''
			}
		},
		data() {
			return {
				courseList: {
					data: []
				},
				rowIndex: -1
			}
		},
		methods: {
			tableRowClassName({ row, rowIndex }) {
				if (this.rowIndex == rowIndex) return 'hight-light-row'
				return ''
			},
			current_change(currentRow, old) {
				this.rowIndex = -1;
				this.$emit('current_change', {
					id: currentRow.id
				})
			},
			handleCurrentChange_p(e) {
				this.upTable(e)
			},
			// 更新表格前对数据进行处理
			async upTable_before(e) {
				var data = await this.getCourse(e);
				if (data) {
					data.data.forEach(e => {
						e.age = getAge_range(e)
					})
					return data
				}
			},
			// 更新表格
			async upTable(e) {
				Object.assign(this.courseList, await this.upTable_before(e))
			},
			/* 获取课程信息 */
			async getCourse(param) {
				var params = {
					page: 1
				}
				param && Object.assign(params, param)
				const {
					data: res
				} = await this.$http.get('/education/courses', {
					params: params
				});
				if (res.code == 200) {
					return res.data
				}
				return false
			},
			/* 查看課程詳細信息 */
			look(row) {
				this.$router.push('/education/courseManagerBasicInfo/' + row.id);
			},
			returnIndex () {
				this.rowIndex = this.courseList.findIndex( item => {
					return item.id == this.selectRowIndex
				})
			}
		},
		created() {
			this.upTable()
		},
		watch: {
			'selectRowIndex': function (val) {
				this.returnIndex()
			}
		},
		components: {
			pagiC
		}
	}
</script>

<style scoped>
	/deep/ .el-table .hight-light-row{
		background-color: #fed90e;
	}
</style>
