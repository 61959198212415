<template>
    <!-- 预约试课 -->
    <div>
        <el-card>
            <!-- 面包屑 -->
            <bread-c :value_breadcrumb='bread'></bread-c>
            <!-- 面包屑/ -->
            <!-- 步骤条 -->
            <step-c :src="src" :isHaveTab="false"
            @upStep_pre='upStep_pre = $event.func' 
            @upStep_next='upStep_next = $event.func'  class="mg-t-10"
            ></step-c>
            <!-- 步骤条/ -->
            <div v-if="index === 0" class="mg-t-10">
                <!-- 筛选 -->
                    <div class="flex align-center w-per-30">
                    <!-- 搜索框 -->
                            <el-input
                                placeholder="" size="small"
                                v-model="value_search" @change="change_input('student')"
                                >
                                <i slot="suffix" class="el-input__icon el-icon-search"
                                @click="click_search('class')"
                                ></i>
                            </el-input>
                        <!-- 添加学员 -->
                        <div class="mg-l-10">
                            <el-button size="small" @click="click_addStudnet" type="primary"
                            >添加学员</el-button>
                        </div>

                    </div>
                    <!-- 表格 -->
                        <table-student-c class="mg-t-10"
                        @selection_change='selection_change($event, "student")'
                        ></table-student-c>
            </div>
            <div v-if="index === 1" class="mg-t-10">
                <!-- 搜索框 -->
                    <div class="flex align-center w-per-30 ">
                    <!-- 搜索框 -->
                            <el-input
                                placeholder="" size="small"
                                v-model="value_search" @change="change_input('class')"
                                >
                                <i slot="suffix" class="el-input__icon el-icon-search"
                                @click="click_search('class')"
                                ></i>
                            </el-input>
                    </div>
                <!-- 表格 -->
                <table-class-c  
                :data_table="table_config_class" :tableData="tableData_class" class="mg-t-10"
                @selection_change='selection_change($event, "class")' :show_table="show_table_class"
                @click_show='click_showClassTime($event)'
                ></table-class-c>
            </div>
            <div v-if="index === 2" class="mg-t-10">
                <div class="flex align-center space-around w-per-50 fs-14  pd-20 box">
                    <span class="w-ab-70">试课费用</span>
                    <el-input v-model="form_http.value_fee" :rules='rules' prop='fee'></el-input>
                </div>
            </div>
            <!-- 表格/ -->
            <!-- 按钮组 -->
            <button-c
            :value_button2='value_button2' :isShow_button1='isShow_button1'
            @next-step='next_step' @pre-step='pre_step'  class="mg-t-10"
            ></button-c>            
            <!-- 按钮组/ -->
      </el-card>

      <!-- 添加学员对话框 -->
      <!-- <dialog-c
      :dialog="dialog_addStudent"
      @dialog_cancel='dialog_cancel_addStudnet($event)' @dialog_confirm='dialog_confirm_addStudent($event)'
      >
        <template v-slot:body>
            <add-student-c></add-student-c>
        </template>
      </dialog-c> -->
    </div>
</template>
<script>
import stepC from '../../common/v-step.vue'
import breadC from '../../common/v-breadcrumb'
import tableStudentC from '../../common/v-table-student.vue'
import tableClassC from '../../common/v-table-class.vue'
import buttonC from './studentCourseRegister-button.vue'
import params from '../../../assets/js/params.js'
import { table_test } from '../../../assets/js/myMethods.js'
import vali from '../../../assets/js/validate'
import dialogC from '../../common/v-dialog.vue'
// import addStudentC from './studentBasicInformation'

export default {
	name: 'StudentAppointCourse',
    data () {
        return {
            dialog_addStudent: {
                show: false,
                title: '添加学员',
                width: '80%',
                button: false
            },
            value_search: '',
            currentRow: '',
            rules: {
                fee: vali.validatorCommon.isPrice
            },
            tableData_class: {
                data: []
            }, 
            show_table_class: {
						// 单选
						isSingle: true,
						// 斑马纹
						isStripe: false,
						// 多选
						isMultiple: false,
						// 是否要操作
						isOperation: false,
						// 排序
						isSortable: true
            },
            table_config_class: [],
            table_config_class2: {
					range_age: '',
					className: "123",
					class_time: "",
					lesson: 12,
					studentCount: 1,
					teacherName: ""
            },
            value_button2: '下一步',
            isShow_button1: false,
            index: 0,
            bread: ['学员管理', '预约试课'],
            src: [
                { title: '选择学员' },
                { title: '预约试课' },
                { title: '试课费用' }                
            ],
            form_http: {
                student: '',
                class: '',
                value_fee: ''
            },
            value_screen: {
                // 关键词
                key: ''
            }
        }
    },
    methods: {
        // dialog_cancel_addStudnet (e) {

        // },
        // dialog_confirm_addStudent (e) {
        //     // 点击确定就要添加学员
        // },
        click_showClassTime (e) {

        },     
        click_addStudnet () {
            this.dialog_addStudent.show = true
        },     
        click_search (key) {
            switch (key) {
                case 'student':
                    // data
                    break;
                case 'class':
                    break;
                default:
                    break;                                        
            }               
        },     
        change_input (key) {
            switch (key) {
                case 'student':
                    // data
                    break;
                case 'class':
                    break;
                default:
                    break;                                        
            }   
        },
        selection_change (e, key) {
            switch (key) {
                case 'student':
                    // data
                    break;
                case 'class':
                    break;
                default:
                    break;                                        
            }            
        },
        // 是否可以跳转到下一步骤
        isLeave () {
                    // if (this.index === 0 && length(this.form.student) === 0) return true
                    // if (this.index === 1 && length(this.form.class) === 0) return true
                    // if (this.index === 2 && length(this.form_http.value_fee) === 0 ) return true
                    if (this.index === 2) {
                        var length = length(this.form_http.value_fee)
                        if (length === 0) return true
                        if (length > 0 && /^[^\d]+$/.test(this.form_http.value_fee)) return true
                    }
                    return false
                    function length (val) {
                        return  String(val).trim().length
                    }
        },
        state_step (val) {
                    switch (val) {
                        case 0:
                            this.step_one()
                            break;
                        case 1:
                            this.step_two()
                            break;
                        case 2:
                            this.step_three()
                            break;                        
                        default:
                            break;																		
                    }
        },     
        step_one () {
            this.value_button2 = '下一步';
            this.isShow_button1 = false
        },
        step_two () {
            this.value_button2 = '下一步';
            this.isShow_button1 = true
        },
        step_three () {
            this.value_button2 = '完成';
            this.isShow_button1 = true
        },    
        pre_step () {
                    this.upStep_pre((active, length) => {
                        this.index = active; this.state_step(active)
                    })
        },
        next_step () {
            if (this.isLeave()) {this.$message.info('请填写完整'); return}
                    this.upStep_next((active, length) => {
                        this.index = active; this.state_step(active)
                    })        
        },


        // 筛选条件进行判断
        screen_judge () {

        },
        // 更新表格
        upData_table (key) {
            switch (key) {
                case 'student':
                    // data
                    break;
                case 'class':
                    break;
                default:
                    break;                                        
            }
        },
        // 更新表格前对数据进行处理  学员要处理 姓名昵称  班级要处理适龄范围
        upData_table_before (key, data) {
            var d;
            switch (key) {
                case 'student':
                    // data
                    break;
                case 'class':
                    break;
                default:
                    break;                                        
            }
            return d
        },

        // 添加预约试课学员
        async addAppointCourse () {

        },
        // 获取学员信息
        async getStudent () {

        },
        // 获取班级信息
        async getClass () {

        }        
        // 获取学员信息 => 对数据进行处理 => 处理完就更新表格
        // 筛选
            // 根据条件筛选 => 拿到条件 => 对条件进行判断 => 请求接口 => 更新数据（这边又是获取信息那一块）
    },
    created () {
        this.table_config_class = table_test(this.table_config_class2, params.table_class)
    },
    components: {
        stepC,
        breadC,
        tableStudentC,
        tableClassC,
        buttonC,
        dialogC,
        // addStudentC
    }
}
</script>
<style scoped>
    .box{
        margin: 20px auto 0
    }
</style>


