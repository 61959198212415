<template>
  <!-- 学员购买课包信息 -->
  <div class="flex height">
    <div class="w-per-40">
      <el-button type="primary" size="medium" @click="operation('CostLesson', 0)">购买课包</el-button>
      <div class="scrollHeightLeft">
        <div class="text-align-c fs-14 mg-t-10" style="color: #aaa;" v-if="costLesson.length == 0">暂无购买记录</div>
        <div v-else v-for="(item, index) in costLesson" :key='index' class="cursor-p card mg-t-10" @click='getCostLessonInfo(item)' style="position: relative;">
          <div class="flex align-center justify-between card-header">
            <div style="position: relative;">{{item.course_package_name}}/{{item.course_classification_name}}
              <div v-if='item.pay_type == 5' style="position: absolute;top: 22px;left: 0;font-size: 12px;padding: 1px 5px;background-color: #409EFF;color: #fff;border-radius: 2px;">转赠套餐</div>
            </div>
            <div>
              <span class="fs-15">支付金额：</span><span class="c-8ec1ff">{{item.paid_amount}}</span>元
            </div>
          </div>
          <div class="flex align-center justify-between card-body">
            <div>
              <p class="flex align-center"><span class="text-align-r fs-14" style="width: 100px;">购买课时：</span><span class="c-8ec1ff">{{item.cost_lesson}}</span></p>
              <p class="flex align-center"><span class="text-align-r fs-14" style="width: 100px;">赠送课时：</span><span class="c-8ec1ff">{{item.give_lesson}}</span></p>
              <p class="flex align-center"><span class="text-align-r fs-14" style="width: 100px;">其它赠送课时：</span><span class="c-8ec1ff">{{item.other_lesson}}</span></p>
              <p class="flex align-center"><span class="text-align-r fs-14" style="width: 100px;">总课时：</span><span class="c-8ec1ff">{{item.lesson}}</span></p>
              <p class="flex align-center"><span class="text-align-r fs-14" style="width: 100px;">剩余课时：</span><span class="c-8ec1ff">{{item.lave_lesson}}</span></p>
            </div>
            <div class="text-align-r">
              <p class="flex align-center"><span class="text-align-r fs-14 W70PX">课包类型：</span><span class="c-8ec1ff fs-14">{{item.type}}</span></p>
              <p class="flex align-center"><span class="text-align-r fs-14 W70PX">推荐人：</span><span class="c-8ec1ff fs-14">{{item.teacher_name}}</span></p>
              <p class="flex align-center"><span class="text-align-r fs-14 W70PX">课包价格：</span><span class="c-8ec1ff">{{item.total_cost}}</span>元</p>
              <p class="flex align-center"><span class="text-align-r fs-14 W70PX">购买时间：</span><span class="c-8ec1ff">{{item.created_at}}</span></p>
              <div class="flex align-center justify-between">
                <div></div>
                <div>
                  <div class="mg-t-10 cursor-p button" @click.stop="operation('Turn', 0, item)" v-if="item.is_refund == 1 && (item.pay_type == 1 || item.pay_type == 3 || item.pay_type == 4)" style="background-color: #66b1ff;">转赠课包</div>
                  <div class="mg-t-10 cursor-p button  mg-l-10" @click.stop="operation('CostLesson', 3, item)" v-if="item.is_refund == 1 && (item.pay_type == 1 || item.pay_type == 3 || item.pay_type == 4)" style="background-color: #66b1ff;">升级</div>
                  <div class="mg-t-10 cursor-p button mg-l-10" @click.stop="operation('CostLesson', 2, item)" v-if="item.is_refund == 1 && (item.pay_type == 1 || item.pay_type == 3 || item.pay_type == 4)" style="background-color: #66b1ff;">更换套餐</div>
                  <div class="mg-t-10 cursor-p button mg-l-10" @click.stop="print(item)" style="background-color: #66b1ff;">打印收据</div>
                  <div class="mg-t-10 cursor-p button bg-normal mg-l-10" @click.stop="showPaymentAmountInfo(item)" v-if="item.is_refund == 1 && item.pay_type == 2">补缴</div>
                  <div class="mg-t-10 cursor-p button bg-warning mg-l-10" @click.stop="deleteCostLesson(item)" v-if="item.is_refund == 1 && item.pay_type != 3 && item.pay_type != 5 && item.lesson == item.lave_lesson">删除</div>
                </div>
              </div>
            </div>
          </div>
          <div :class="{'select': select == item.id}"></div>
          <div class="isRefund" v-if="item.is_refund == 2">已退费</div>
          <div class="isRefund" v-else-if="item.is_refund == 3">已结转</div>
          <div class="isRefund" v-else-if="item.is_refund == 4">已升级</div>
          <div class="isRefund" v-else-if="item.is_refund == 5">已转赠</div>
          <!-- <div class="isRefund" v-if="item.pay_type == 5">转赠套餐</div> -->
        </div>
        <div class="fs-14 text-align-c cursor-p mg-t-10" @click="getMore" v-if="false">加载更多++</div>
      </div>
    </div>
    <div class="flex-1_1 mg-l-10 scrollHeightRight" style="margin-top: 46px;">
      <cost-lesson-c :buttonShow="buttonShow" :student-id="studentId" propType='1' :form='costLessonInfo' :isGetCostLesson.sync='isGetCostLesson' @upFileList='upFileList = $event' @resetFiled='resetFiledModify = $event' @clearFileList='clearFileListModify = $event'></cost-lesson-c>
    </div>
    <el-dialog :visible.sync="dialogShowCostLesson" width="80%" center :title="type == 0 ? '购买课包' : (type == 2 ? '结转套餐' : '升级套餐')" :modal-append-to-body='false' append-to-body @close="closeCostLesson">
      <cost-lesson-c v-if="dialogShowCostLesson" :student-id="studentId" :propType='type' :isGetCostLesson.sync='isGetCostLesson' @resetFiled='resetFiledAdd = $event' @clearFileList='clearFileListAdd = $event' :course_id='selectCostLessonId'></cost-lesson-c>
    </el-dialog>
    <el-dialog :visible.sync="dialogShowPaymentAmount" width="30%" center title='补缴金额' @close="closePaymentAmount" :modal-append-to-body='false' append-to-body>
      <el-form label-position="right" label-width="80px">
        <el-form-item label="补缴金额">
          <el-input :placeholder="payment_amountPlaceholder" v-model='selectCostLesson.payment_amount'></el-input>
        </el-form-item>
      </el-form>
      <div class="flex" style="color: #aaa;">
        <div style="width: 80px;"></div>
        补缴金额 = 应付金额<span style="color: #0086B3;margin-right: 5px;">{{selectCostLesson.amount_payable}}</span> - 实际支付金额<span style="color: #0086B3;">{{selectCostLesson.paid_amount}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShowPaymentAmount = false">取 消</el-button>
        <el-button type="primary" @click="paymentAmount">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转赠 -START -->
    <el-dialog :visible.sync="dialogShowTurn" title='转赠课包' center @close='$refs.refFormTurn.resetFields()' :modal-append-to-body='false' append-to-body>
      <el-form :model="formData" :rules="rules" ref='refFormTurn' label-position="right" label-width="90px">
        <el-form-item label="转赠类型" prop="donate_type">
          <el-select v-model="formData.donate_type" size="small" placeholder="请选择转赠类型" style="width: 100%;">
            <el-option v-for="(item, index) in turnTypeOption" :key="'turnType' + index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="formData.donate_type == 2" label="转赠课时数" prop="donate_lesson" required>
          <el-input v-model.number="formData.donate_lesson" size="small" placeholder="请输入转赠课时数"></el-input>
        </el-form-item>

        <el-form-item label="学员ID" prop="sid">
          <el-input size="small" v-model="formData.sid" placeholder="请输入学员ID">
            <template slot="append">
              <el-button size="mini" @click='getStudentInfo'>获取学员信息</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="学员姓名" prop="name">
          <el-input size="small" v-model="formData.name" placeholder="请点击获取学员信息" disabled></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-input size="small" v-model="formData.sex" placeholder="请点击获取学员信息" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="tel">
          <el-input size="small" v-model="formData.tel" placeholder="请点击获取学员信息" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input size="small" v-model="formData.remark" placeholder="请输入备注" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShowTurn = false">取 消</el-button>
        <el-button type="primary" @click="turn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转赠 -END -->
    <!-- 生成打印视图 -->
    <el-dialog :visible.sync="printVisible" width="40%" top="2%" center @close="closePrint" :show-close="false" class="dialog" :modal-append-to-body='false' append-to-body>
      <div id="printContent" class="print_content" style="position: relative;">
        <div style="font:20px '微软雅黑';font-weight: bold;width: 232px;">小河码教育专用收据</div>
        <div class="print-des" style="display: flex;flex-direction: column;justify-content: flex-start;">
          <div>编号: &nbsp;{{print_fee.number}}</div>
          <div>打印日期: &nbsp;{{print_fee.print_date}}</div>
          <div>缴费日期: &nbsp;{{print_fee.fee_date}}</div>
          <div>收费方式: &nbsp;{{print_fee.fee_kind}}</div>
          <div>课程类型: &nbsp;{{print_fee.course_kind}}</div>
          <div>课包名称: &nbsp;{{print_fee.class_package}}</div>
          <div>学生名称: &nbsp;{{print_fee.student}}</div>
          <div>缴费金额: &nbsp;{{print_fee.fee_money,}}</div>
          <div>金额大写: &nbsp;{{print_fee.fee_money_chies,}}</div>
          <div>收费单位: &nbsp;{{print_fee.schoole}}</div>
          <div>打印次数: &nbsp;{{print_fee.print_count}}</div>
        </div>
        <div class="print-footer">
          <div>用户签字: &nbsp;</div>
          <div>打印人: &nbsp;{{print_fee.name}}</div>
          <div style="margin-top: 20px;">(商户存根)</div>
        </div>
        <hr style="border-top:1px dashed black;height: 1px;overflow:hidden;margin: 40px 0px; " noshade="noshade" size="1" width="100%" />
        <div style="font:20px '微软雅黑';font-weight: bold;width: 232px;">小河码教育专用收据</div>
        <div class="print-des" style="display: flex;flex-direction: column;justify-content: flex-start;">
          <div>编号: &nbsp;{{print_fee.number}}</div>
          <div>打印日期: &nbsp;{{print_fee.print_date}}</div>
          <div>缴费日期: &nbsp;{{print_fee.fee_date}}</div>
          <div>收费方式: &nbsp;{{print_fee.fee_kind}}</div>
          <div>课程类型: &nbsp;{{print_fee.course_kind}}</div>
          <div>课包名称: &nbsp;{{print_fee.class_package}}</div>
          <div>学生名称: &nbsp;{{print_fee.student}}</div>
          <div>缴费金额: &nbsp;{{print_fee.fee_money}}</div>
          <div>金额大写: &nbsp;{{print_fee.fee_money_chies}}</div>
          <div>收费单位: &nbsp;{{print_fee.schoole}}</div>
          <div>打印次数: &nbsp;{{print_fee.print_count}}</div>
        </div>
        <div class="print-footer">
          <div>用户签字: &nbsp;</div>
          <div>打印人: &nbsp;{{print_fee.name}}</div>
          <div style="margin-top: 20px;">(客户存根)</div>
        </div>
      </div>
      <div style="width: 100%;text-align: center;margin-top: 10px;">
        <el-button v-print="'#printContent'" type="primary" @click="printConfirm">打印</el-button>
      </div>
    </el-dialog>
    <!-- 生成打印视图 end -->
  </div>
</template>

<script>
import commonJs from "../../../assets/js/validate.js";
import {
  getCostLesson,
  addCostLesson,
  modifyCostLesson,
  getCostLessonInfo,
  deleteCostLesson,
  paymentAmount,
  donate,
} from "../../../assets/js/API/cost-lesson.js";
import { getStudentInfo } from "../../../assets/js/API/student.js";
import { COURSE_TYPE } from "../../../assets/js/CONFIG/course.js";
import { TURN_TYPE } from "../../../assets/js/CONFIG/cost-lesson.js";

import costLessonC from "./costLessonBaseInfo.vue";
import { extend } from "../../../assets/js/util.js";
import { SEX } from "../../../assets/js/CONFIG/student.js";
export default {
  name: "StudentCostLessonInfo",
  components: {
    costLessonC,
  },
  props: {
    studentId: {
      default: "",
    },
    studentName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCanHttp: true,
      turnTypeOption: TURN_TYPE,
      courseType: COURSE_TYPE,
      dialogShowTurn: false,
      rules: {
        sid: [
          ...commonJs.validatorCommon.mandatory("请输入学员ID"),
          ...commonJs.validatorCommon.number,
        ],
        sex: commonJs.validatorCommon.mandatory("请输入性别"),
        name: commonJs.validatorCommon.mandatory("请输入名字"),
        tel: commonJs.validatorCommon.mandatory("请输入联系方式"),
        remark: commonJs.validatorCommon.mandatory("请输入备注"),
        donate_lesson: [
          {
            validator: (rule, value, callback) => {
              if (this.formData.donate_type == 1) return callback();
              else {
                var lave_lesson = this.costLesson.find(
                  (item) => item.id == this.selectCostLessonId
                ).lave_lesson;
                if (value != "" && lave_lesson >= value - 0) return callback();
                else
                  return callback(
                    new Error(`转赠课时不能大于剩余课时${lave_lesson}`)
                  );
              }
            },
            trigger: "blur",
          },
        ],
      },
      formData: {
        donate_type: 1,
        donate_lesson: "",
        sid: "",
        sex: "",
        name: "",
        tel: "",
        remark: "",
      },
      buttonShow: true,
      type: 0,
      selectCostLessonId: "",
      selectCostLesson: {
        id: "",
        payment_amount: "", //补缴金额
        paid_amount: "", //实际支付
        amount_payable: "", //应付金额
        sid: "",
        earnest_money: "",
      },
      payment_amountPlaceholder: "请输入补缴金额",
      dialogShowPaymentAmount: false,
      /* 打印视图显示 */
      printVisible: false,
      isGetCostLesson: false,
      dialogShowCostLesson: false,
      costLesson: [],
      costLessonInfo: {},
      page: 1,
      isGetCostLessonThrottle: true,
      select: -1,
      print_fee: {},
      text: "0",
      num: 0,
      unit: new Array("仟", "佰", "拾", "", "仟", "佰", "拾", "", "角", "分"),
      confirmSelect: {},
    };
  },
  watch: {
    isGetCostLesson: function (val) {
      if (val) {
        this.getCostLesson();
        this.dialogShowCostLesson = false;
        this.$nextTick(() => {
          this.isGetCostLesson = false;
        });
      }
    },
  },
  created() {
    this.getCostLesson();
  },
  methods: {
    operation(key, type, item) {
      this.type = type;
      item && (this.selectCostLessonId = item.id);
      this["dialogShow" + key] = true;
    },
    async turn() {
      var flag = await this.$refs.refFormTurn.validate().catch((err) => { });
      if (!flag) return;
      if (!this.isCanHttp) return this.$message.info("正在处理中...");
      this.isCanHttp = false;
      this.donate();
    },
    donate() {
      var params = { ...this.formData };
      params.cid = this.selectCostLessonId;
      params.donate_type == 1 && delete params.donate_lesson;
      delete params.sex;
      delete params.name;
      delete params.tel;
      donate
        .call(this, params)
        .then((res) => {
          this.isCanHttp = true;
          if (res) {
            this.dialogShowTurn = false;
            this.getCostLesson();
          }
        })
        .catch((err) => (this.isCanHttp = true));
    },
    getStudentInfo() {
      if (!this.isCanHttp) return this.$message.info("正在处理中...");
      if (this.formData.sid.trim() == "") return;
      this.isCanHttp = false;
      getStudentInfo
        .call(this, {
          sid: this.formData.sid,
        })
        .then((res) => {
          this.isCanHttp = true;
          if (res) {
            extend(this.formData, res);
            this.formData.sex = SEX[this.formData.sex - 1].name;
          }
        })
        .catch((err) => (this.isCanHttp = true));
    },
    paymentAmount() {
      var payment_amount =
        this.selectCostLesson.amount_payable -
        this.selectCostLesson.paid_amount;
      if (
        this.selectCostLesson.payment_amount !=
        this.selectCostLesson.amount_payable - this.selectCostLesson.paid_amount
      )
        return this.$message.error("补缴金额不正确，应该是" + payment_amount);
      paymentAmount
        .call(this, this.selectCostLesson)
        .then((res) => {
          this.dialogShowPaymentAmount = false;
          this.getCostLesson();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showPaymentAmountInfo(item) {
      var selectCostLesson = this.selectCostLesson;
      selectCostLesson.amount_payable =
        item.amount_payable == 0 ? item.paid_amount : item.amount_payable;
      selectCostLesson.paid_amount = item.paid_amount;
      selectCostLesson.payment_amount =
        selectCostLesson.amount_payable - selectCostLesson.paid_amount;
      selectCostLesson.earnest_money = item.earnest_money;
      selectCostLesson.id = item.id;
      selectCostLesson.sid = this.$route.query.id || this.studentId;
      this.payment_amountPlaceholder = "补缴金额 = " + this.payment_amount;
      this.dialogShowPaymentAmount = true;
    },
    closePaymentAmount() { },
    closeCostLesson() {
      this.resetFiledAdd();
      this.clearFileListAdd();
      this.selectCostLessonId = "";
    },
    /* 打印 */
    async printConfirm() {
      const { data: res } = await this.$http.put(
        "student/cost/lesson/print?id=" + this.confirmSelect.id
      );
      this.getCostLesson();
    },
    /* 关闭打印 */
    closePrint() {
      this.printVisible = false;
    },
    print(item) {
      this.confirmSelect = item;
      this.getPrintContent(item);
    },
    toDx(n) {
      //阿拉伯数字转换函数
      switch (n) {
        case "0":
          return "零";
        case "1":
          return "壹";
        case "2":
          return "贰";
        case "3":
          return "叁";
        case "4":
          return "肆";
        case "5":
          return "伍";
        case "6":
          return "陆";
        case "7":
          return "柒";
        case "8":
          return "捌";
        case "9":
          return "玖";
      }
    },
    /* 数字转换 */
    toChies(m) {
      //形参
      m *= 100;
      m += "";
      var length = m.length;

      var result = "";
      for (var i = 0; i < length; i++) {
        if (i == 2) {
          result = "元" + result;
        } else if (i == 6) {
          result = "万" + result;
        }
        if (m.charAt(length - i - 1) == 0) {
          if (i != 0 && i != 1) {
            if (
              result.charAt(0) != "零" &&
              result.charAt(0) != "元" &&
              result.charAt(0) != "万"
            ) {
              result = "零" + result;
            }
          }
          continue;
        }
        result =
          this.toDx(m.charAt(length - i - 1)) +
          this.unit[this.unit.length - i - 1] +
          result;
      }
      result += result.charAt(result.length - 1) == "元" ? "整" : "";
      return result;
    },
    /* 获取小票内容 */
    async getPrintContent(item) {
      //编号
      var checkSchoole = window.sessionStorage.getItem("checkSchoole");
      var number = "No." + Date.parse(new Date()) + "_" + checkSchoole;
      //打印时间
      var print_date = this.getNowDate();
      //缴费时间
      var fee_date = item.created_at;
      //支付类型
      var fee_kind = "";
      this.$param.payType.forEach((val, index) => {
        if (val.val == item.pay_method) {
          fee_kind = val.name;
          return false;
        }
      });
      //课程类型
      var course_kind = item.course_classification_name;
      //课包名称
      var class_package = item.course_package_name;
      //学生名字
      var student = this.$route.query.name || this.studentName;
      //缴费金额
      var fee_money = item.paid_amount;
      //金额大写
      var fee_money_chies = this.toChies(fee_money);
      //收费单位
      var schooles = window.sessionStorage.getItem("schoole");
      var schoole = "小河码-";
      JSON.parse(schooles).forEach((val, index) => {
        if (val.id == checkSchoole) {
          schoole = schoole + val.name;
          return false;
        }
      });
      //打印次数
      var print_count = item.print_count;
      //打印人
      var name = window.sessionStorage.getItem("name");
      this.print_fee = {
        number: number,
        print_date: print_date,
        fee_date: fee_date,
        fee_kind: fee_kind,
        course_kind: course_kind,
        class_package: class_package,
        student: student,
        fee_money: fee_money,
        schoole: schoole,
        fee_money_chies: fee_money_chies,
        name: name,
        print_count: print_count,
      };
      this.printVisible = true;
    },
    /* 获取当前日期 */
    getNowDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    },
    reset() {
      this.page = 1;
      this.costLesson = [];
    },
    getMore() {
      return;
      if (this.isGetCostLessonThrottle) {
        this.page++;
        this.getCostLesson();
        this.isGetCostLessonThrottle = false;
        setTimeout(() => {
          this.isGetCostLessonThrottle = true;
        }, 1500);
      }
    },
    deleteCostLesson(item) {
      this.$confirm("您确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var params = {
            sid: this.$route.query.id || this.studentId,
            lesson: item.lesson,
            id: item.id,
          };
          deleteCostLesson
            .call(this, params)
            .then((res) => {
              if (item.id == this.select) {
                this.resetFiledModify();
                this.clearFileListModify();
              }
              this.getCostLesson();
            })
            .catch((err) => { });
        })
        .catch(() => { });
    },
    getCostLesson() {
      getCostLesson
        .call(this, {
          sid: this.$route.query.id || this.studentId,
          page: 1,
          number: 9999999,
        })
        .then((res) => {
          this.costLesson = res.data;
          var selctCostLesson = this.costLesson.filter((item) => {
            return item.id == this.select;
          });
          this.costLesson.length > 0 &&
            selctCostLesson.length > 0 &&
            this.getCostLessonInfo(selctCostLesson[0]);
          this.costLesson.length > 0 &&
            (this.select == -1 || selctCostLesson.length == 0) &&
            this.getCostLessonInfo(this.costLesson[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCostLessonInfo(item) {
      if (item.id == this.select) return;
      this.buttonShow = item.is_refund == 1 ? true : false;
      this.select = item.id;
      this.clearFileListModify();
      getCostLessonInfo
        .call(this, item.id)
        .then((res) => {
          this.resetFiledModify();
          this.costLessonInfo = res;
          console.log(this.costLessonInfo)
          this.costLessonInfo.lave_lesson =
            item.lave_lesson - item.other_lesson;
          this.costLessonInfo.remark =
            this.costLessonInfo.remark == 0 ? "" : this.costLessonInfo.remark;
          if (
            !this.costLessonInfo.hasOwnProperty("recommender") ||
            this.costLessonInfo.recommender == 0
          )
            this.costLessonInfo.recommender = "";
          this.upFileList([...this.costLessonInfo.contract]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="less">
.print_content {
  margin-left: 30%;
  transform: translate(-25%);
}

.bg-warning {
  background-color: rgb(214 106 106);
}

.bg-normal {
  background-color: #409eff;
}

.isRefund {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 30px;
  font-weight: bold;
  color: #aaa;
  opacity: 0.4;
  transform: translate(-50%, -50%);
}

@page {
  size: auto A4 landscape;
  margin: 3mm;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.print-des > div {
  width: 232px;
  margin-top: 15px;
  font: 12px "微软雅黑";
}

.print-footer > div {
  width: 232px;
  font: 12px "微软雅黑";
  margin-top: 20px;
}

.select {
  position: absolute;
  background-color: rgb(201 229 239);
  width: 50px;
  height: 50px;
  top: -25px;
  right: -25px;
  transform: rotate(45deg);
}

.W70PX {
  width: 70px;
  display: block;
}

.card {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}

.card-header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}

.card-body {
  padding: 20px;
}

.button {
  color: #ffffff;
  padding: 2px 5px;
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
}

.height {
  height: calc(100vh - 300px);
}

.scrollHeightLeft {
  height: calc(100vh - 300px - 50px);
  overflow-y: auto;
}

.scrollHeightRight {
  height: calc(100vh - 300px - 50px);
  overflow-y: auto;
}
</style>
