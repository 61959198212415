/* 学生配置 */
export const STUDENTSOURCE = [{
		name: '到访',
		val: 1
	},
	{
		name: '地推',
		val: 2
	},
	{
		name: '转介绍',
		val: 3
	},
	{
		name: '微官网',
		val: 4
	},
	{
		name: '招生活动',
		val: 5
	},
	{
		name: '其他',
		val: 6
	}
]

export const SEX = [{
		name: '男',
		val: 1
	},
	{
		name: '女',
		val: 2
	}
]

export const STUDENT_STATE = [{
		id: 1,
		name: '正常'
	},
	{
		id: 2,
		name: '监控中'
	}
]

export const ATTENDANCE_STATUS = [{
		name: '出勤',
		id: 1,
		color: '#47bd12'
	},
	{
		name: '请假',
		id: 2,
		color: '#ffc500'
	},
	{
		name: '旷课',
		id: 3,
		color: '#ff551c'
	},
	{
		name: '未处理',
		id: 4,
		color: ''
	}
]

export const ATTENDANCE_STATUS_PARENT = [
	{
		name: '全部',
		id: 0
	},
	{
		name: '待处理',
		id: 1
	},
	{
		name: '同意',
		id: 2
	},
	{
		name: '不同意',
		id: 3
	}
]
