<template>
  <!-- 教师信息表格 -->
  <div class="teacher-table">
    <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="show('add')" v-if="operation && flagOperation">添加老师</el-button>
    <el-table :data="user" ref="refTable" class="fsMedia" :highlight-current-row='isHightlight' @current-change="handleCurrentChange" :row-class-name="tableRowClassName" row-key="id">
      <el-table-column align="center" prop="name" label="姓名"></el-table-column>
      <el-table-column align="center" prop="username" label="联系电话"></el-table-column>
      <el-table-column align="center" prop="role.name" label="角色"></el-table-column>
      <el-table-column align="center" prop="base_salary" label="薪资(/元)"></el-table-column>
      <el-table-column align="center" prop="level" label="星级(星)"></el-table-column>
      <el-table-column align="center" label="是否授课">
        <template slot-scope="scope">
          <span v-if="!operation">{{scope.row.is_teacher == 1 ? '授课' : '不授课'}}</span>
          <el-switch v-else v-model="scope.row.is_teacher" :disabled="isHaveRoleoperation(scope.row)" :active-value="1" :inactive-value="2" @change="changeTeacherStatus(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <span>{{jobOption[scope.row.job_status - 1].name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" v-if="operation && flagOperation" width="180" fixed='right'>
        <template slot-scope="scope">
          <el-button :disabled="isHaveRoleoperation(scope.row)" type="primary" size="small" @click.native="show('modify', scope.row)">编辑</el-button>
          <el-button :disabled="isHaveRoleoperation(scope.row)" type="primary" size="small" @click.native="show('modifyPassword', scope.row)">修改密码</el-button>

        </template>
      </el-table-column>
    </el-table>
    <!-- /分页 -->
    <el-pagination class="mg-t-10" :current-page.sync="screen.page" :total="pagination.total" layout="total, prev, pager, next" background @current-change='getUser'></el-pagination>

    <el-dialog :title="getTitle" :visible.sync="visible" width="30%" @close="dialogClose" :close-on-click-modal='false' center>
      <el-form :model="formData" :rules="addFormRules" ref="refForm" label-width="80px">
        <el-form-item v-show="getTitle !== '修改密码'" label="姓名" prop="name">
          <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item v-show="getTitle !== '修改密码'" label="电话" prop="username" required>
          <el-input v-model.number="formData.username" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item v-show="getTitle === '添加教师' || getTitle === '修改密码'" label="密码" prop="password">
          <el-input placeholder="请输入密码" v-model="formData.password" show-password></el-input>
        </el-form-item>
        <el-form-item v-show="getTitle !== '修改密码'" label="星级" prop="level">
          <level-select-c :select.sync='formData.level' :defaultSelect='formData.level' :isGetClassFee="true"></level-select-c>
        </el-form-item>
        <el-form-item v-show="getTitle !== '修改密码'" label="薪资" prop="base_salary">
          <el-input placeholder="请输入默认薪资" v-model="formData.base_salary"></el-input>
        </el-form-item>
        <el-form-item v-show="getTitle !== '修改密码'" label="角色" prop="role">
          <el-select v-model="formData.role" clearable placeholder="请选择所属角色" style='width: 100%;'>
            <el-option v-for="item in getRoleOption" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="getTitle !== '修改密码'" label="校区" prop="schoole" required>
          <el-select v-model="formData.schoole" clearable placeholder="请分配校区" style='width: 100%;' multiple :disabled="schooleDisabled">
            <el-option v-for="item in schooleOption" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="getTitle !== '修改密码'" label="状态" prop="job_status" required>
          <el-select v-model="formData.job_status" clearable placeholder="请选择状态" style='width: 100%;'>
            <el-option v-for="item in jobOption" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <loading-c :show-loading="loading"></loading-c>
  </div>
</template>

<script>
import levelSelectC from "./v-level-select.vue";
import loadingC from "./loading.vue";
import { extend } from "../../assets/js/util.js";

import { JOB_STATUS } from "../../assets/js/CONFIG/teacher.js";

import { getSchoole } from "../../assets/js/API/schoole.js";
import {
  getTeacher,
  modifyTeacher,
  addTeacher,
  changeTeacherStatus,
  getRole,
  modifyPassword,
} from "../../assets/js/API/teacher.js";
import vali from "../../assets/js/validate.js";
export default {
  name: "TeacherTable",
  components: {
    levelSelectC,
    loadingC,
  },
  props: {
    isHightlight: {
      type: Boolean,
      default: false,
    },
    selectRowIndex: {
      default: -1,
    },
    operation: {
      type: Boolean,
      default: true,
    },
    isAddClass: {
      // 是否是在添加班级是调用 1- 是 0- 不是
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,

      flagOperation: false, //是否具有操作权限 true有 false 没有

      roleId: window.sessionStorage.getItem("role"),
      isMyself: false, //用来判断修改权限时，所有角色的的列表，true角色列表会包含自身等级， false不包含自身
      schooleDisabled: false,
      rowIndex: -1,
      levelOption: this.$param.level,
      jobOption: JOB_STATUS,
      screen: {
        page: 1,
      },
      pagination: {
        total: 0,
      },
      user: [],
      visible: false,
      role: [],
      roleSameLevel: [],
      formData: {
        id: "",
        name: "",
        username: "",
        password: "",
        role: "",
        schoole: [],
        level: 1,
        base_salary: 2000,
        job_status: 1,
      },
      userId: '',
      schooleOption: [],
      addFormRules: {
        name: [
          ...vali.validatorCommon.mandatory("请输入姓名"),
          {
            min: 2,
            max: 7,
            message: "长度在 2 到 7 个字符",
          },
          {
            pattern: /^[\u4E00-\u9FA5]+$/,
            message: "只能为中文",
          },
        ],
        username: vali.validatorCommon.tel,
        base_salary: [
          ...vali.validatorCommon.mandatory("请输入薪资"),
          ...vali.validatorCommon.price,
        ],
        password: vali.validatorCommon.mandatory("请输入密码"),
        role: vali.validatorCommon.mandatory("请选择角色", "change"),
        level: vali.validatorCommon.mandatory("请选择星级", "change"),
        schoole: vali.validatorCommon.mandatoryArray("请分配校区", "change"),
        job_status: vali.validatorCommon.mandatory("请选择状态", "change"),
      },
      operate: "",
    };
  },
  computed: {
    /**
     * @description
     */
    getRoleOption() {
      var teacherId = sessionStorage.getItem("teacherID");
      if (this.formData.id == teacherId) return this.roleSameLevel;
      else return this.role;
    },

    getTitle() {
      var map = {
        "": "",
        add: "添加教师",
        modify: "修改教师信息",
        modifyPassword: "修改密码",
      };
      return map[this.operate];
    },
  },
  watch: {
    selectRowIndex: function (val) {
      this.$refs.refTable.setCurrentRow();
      this.returnIndex();
    },
  },
  created() {
    var map = ["1", "2", "8"];
    this.flagOperation = map.includes(this.roleId);
    if (this.flagOperation) {
      var schoole = sessionStorage.getItem("checkSchoole");
      this.formData.schoole.push(schoole - 0);
      this.getSchoole();
      this.getRole();
    }
    this.getUser();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (this.rowIndex == rowIndex) return "hight-light-row";
      return "";
    },
    returnIndex() {
      this.rowIndex = this.user.findIndex((item) => {
        return item.id == this.selectRowIndex;
      });
    },
    changeTeacherStatus(row) {
      changeTeacherStatus.call(this, { tid: row.id, status: row.is_teacher });
    },
    /* 监听选择 */
    handleCurrentChange(row) {
      if (row) {
        this.$emit("current_change", row);
      }
    },
    /* 获取用户 */
    getUser() {
      let username = window.sessionStorage.getItem("username");
      let params = {}
      if (this.$route.name == "教室管理") {
        params = {
          page: this.screen.page,
          is_add_class: this.isAddClass,
          number: 9999,
        };
      } else {
        params = {
          page: this.screen.page,
          is_add_class: this.isAddClass,
          number: 9999,
          job_status: 1,
          is_teacher: 1,
        };
      }
      this.loading = true;
      getTeacher
        .call(this, username, params)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.user = res.data;
            this.pagination.total = res.total;
            this.returnIndex();
          }
        })
        .catch((err) => (this.loading = false));
    },

    dialogClose() {
      this.$refs.refForm.resetFields();
      var schoole = sessionStorage.getItem("checkSchoole");
      this.formData = {
        id: "",
        name: "",
        username: "",
        password: "",
        role: "",
        schoole: [schoole - 0],
        level: "",
        base_salary: 2000,
        job_status: 1,
      };
    },

    show(key, row) {
      switch (key) {
        case "add":
          this.operate = key;
          this.visible = true;
          break;

        case "modify":
          if (this.isHaveRoleoperation(row)) return;
          extend(this.formData, row);
          this.formData.role = row.role.id;
          this.formData.schoole.forEach((item, index) => {
            this.formData.schoole[index] -= 0;
          });
          this.operate = key;
          this.visible = true;
          break;

        case "modifyPassword":
          if (this.isHaveRoleoperation(row)) return;
          extend(this.formData, row);
          this.formData.password = "";
          this.operate = key;
          this.visible = true;
          break;

        default:
          break;
      }
    },

    confirm() {
      this.$refs.refForm.validate(async (valid) => {
        if (!valid) return;
        this[this.operate]()
          .then((res) => {
            if (res) {
              this.visible = false;
              this.screen.page = 1;
              this.getUser();
            }
          })
          .catch((err) => console.log(err));
      });
    },

    add() {
      var params = { ...this.formData };
      delete params.id;
      return addTeacher.call(this, params);
    },

    modify() {
      var params = { ...this.formData };
      delete params.password;
      return modifyTeacher.call(this, params);
    },

    modifyPassword() {
      var params = {
        id: "",
        password: "",
      };
      extend(params, this.formData);
      return modifyPassword.call(this, params);
    },

    /* 获取角色列表 */
    getRole() {
      var map = [1, 6, 7]; //校长和督导不能添加的角色id
      var PRINCIPALROLEId = 2; //校长权限id
      var SUPERVISIONROLEID = 8; //督导权限id
      getRole
        .call(this)
        .then((res) => {
          if (res) {
            if (
              this.roleId == PRINCIPALROLEId ||
              this.roleId == SUPERVISIONROLEID
            ) {
              map.push(
                this.roleId == PRINCIPALROLEId
                  ? SUPERVISIONROLEID
                  : PRINCIPALROLEId
              );
              res = res.filter((item) => !map.includes(item.id));
            }
            this.role = res.filter((item) => {
              if (this.roleId == SUPERVISIONROLEID) return item.leave > 1;
              else return item.leave > this.roleId - 1;
            });
            this.roleSameLevel = res.filter((item) => {
              if (this.roleId == SUPERVISIONROLEID) return item.leave >= 1;
              else return item.leave >= this.roleId - 1;
            });
          }
        })
        .catch((err) => conosle.log(err));
    },
    getSchoole() {
      getSchoole
        .call(this, { page: 1, number: 999999 })
        .then((res) => {
          if (res) this.schooleOption = res;
        })
        .catch((err) => console.log(err));
    },

    isHaveRoleoperation(teacherInfo) {
      var SUPERVISIONROLEID = 8; //督导权限id
      var teacherId = sessionStorage.getItem("teacherID");
      if (teacherId == 1) {
        return false
      }
      if (
        teacherId != teacherInfo.id &&
        (this.roleId == SUPERVISIONROLEID
          ? teacherInfo.role.leave <= 1
            ? true
            : false
          : this.roleId > teacherInfo.role.leave)
      ) {
        /* this.$message.info('您无权限操作，请联系管理员！！！'); */
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.teacher-table {
  position: relative;
}

/deep/ .el-table .hight-light-row {
  background-color: #fed90e !important;
}
</style>
