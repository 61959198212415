<template>
	<!-- 课程 -->
	<div>
		<!-- 日志 -->
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>系统管理</el-breadcrumb-item>
		  <el-breadcrumb-item>日志管理</el-breadcrumb-item>
		  <el-breadcrumb-item>考勤日志</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 卡片 -->
		<el-card>
			<!-- 搜索区域 -->
			<div slot="header">
				<el-select
				 v-model="classs"
				 placeholder="请筛选需要查找的班级"
				 @change="getChooseCourse"
				 clearable
				>
					<el-option
					v-for="(item,index) in classes"
					:key="index"
					:label="item.name"
					:value="item.id"
					>
					</el-option>
				</el-select>
				<el-date-picker
				style="margin-left: 15px;"
				v-model="date"
				type="daterange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				value-format="yyyy-MM-dd"
				@change="dateChange"
				>
					
				</el-date-picker>
				
			</div>
			<!-- 表格区域 -->
			<div>
				<el-table
					:data="classLog"
				>
					<el-table-column
						label="事件"
						prop="event"
					>
					</el-table-column>
					<el-table-column
						align="center"
						label="记录时间"
						prop="created_at"
					>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination
				    layout="prev, pager, next"
					@current-change="paginateChange"
					:page-size=20
				    :total="total">
				  </el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default{
		name: 'LogAttendance',
		data(){
			return{
				/* 课程 */
				classs:[],
				class:'',
				date:[],
				/* 日志表格 */
				classLog:[],
				/* 分页用到 */
				currentPage:1,
				total:0,
			}
		},
		methods:{
			/* 获取课程日志 */
			async getClass(){
				const {data:res} = await this.$http.get('/education/attendance/system/log?page='+this.currentPage+'&class=' + this.classs + '&date='+ this.date+'&type='+this.type);
				if(res.code == 200){
					this.classLog = res.data.data;
					this.total = res.data.total;
					this.currentPage = res.data.currentPage;
				}
			},
			/* 获取课程 */
			async getClassByNotPaginate(){
				const {data:res} = await this.$http.get('/education/classes/system/get');
				if(res.code == 200){
					this.classes = res.data;
				}
			},
			/* 监听课程筛选的改变 */
			getChooseCourse(item){
				this.currentPage = 1;
				this.getClass();
			},
			/* 监听时间的改变 */
			dateChange(item){
				this.currentPage = 1;
				this.getClass();
			},
			/* 页码改变 */
			paginateChange(item){
				this.currentPage = item;
				this.getClass();
			}
		},
		created() {
			/* 获取课程日志 */
			this.getClass();
			/* 获取课程 */
			this.getClassByNotPaginate();
		}
	}
</script>

<style>
</style>
