<template>
	<!-- 考勤记录列表 -->
	<div>
		<el-row>
			<el-col :span="4">
				<el-select v-model="screen.type" style="width: 100%" @change="typeChange">
					<el-option v-for="(item, index) in attendanceOption" :key='index' :label='item.name' :value="item.val"></el-option>
				</el-select>
			</el-col>
			<el-col :span="19" :offset='1'>
				<data-analysis-search :defaultData="defaultData" @getScreen='getScreen' lastMonth='上月' thisMonth='当月' yesterday="昨天" today="今天" sevenDay="7天"></data-analysis-search>
			</el-col>
		</el-row>
		<el-table :data='tableData' stripe>
			<el-table-column prop="school_name" label='校区' width="150"></el-table-column>
			<el-table-column prop="student_name" label='学员名称'></el-table-column>
			<el-table-column prop="class_name" label='班级名称'></el-table-column>
			<el-table-column prop="" label='上课时间' min-width="200px">
				<template v-slot="scope">
					{{scope.row | getClassTime}}
				</template>
			</el-table-column>
		</el-table>
		<el-pagination :current-page="screen.page" :total="total" background layout="total, prev, pager, next" class="mg-t-10" @current-change='pageChange'></el-pagination>
	</div>
</template>

<script>
	import dataAnalysisSearch from './data-analysis-search.vue'
	import {
		extend
	} from '../../../assets/js/util.js'	
	export default {
		name: 'AttendanceList',
		data () {
			return {
				attendanceOption: this.$param.attendanceStatus,
				total: 0,
				tableData: [],
				screen: {
					page: 1,
					type: '',
					date_start: '',
					date_end: '',
					school_id: '',
					number: 10
				},
				defaultData: {}
			}
		},
		methods: {
			pageChange (page) {
				this.screen.page = page;
				this.getAttendance()
			},
			typeChange () {
				this.screen.page = 1;
				this.getAttendance()
			},
			getScreen (screen) {
				this.screen.page = 1;
				extend(this.screen, screen)
				this.getAttendance()
			},
			async getAttendance () {
				var { data: res } = await this.$http.get('/statistics/attendance/list', {
					params: this.screen
				})
				if (res.code == 0) {
					this.tableData = res.data.data;
					this.screen.page = res.data.page;
					this.total = res.data.total;
				} else {this.$message.error(res.message)}
			}
		},
		components: {
			dataAnalysisSearch
		},
		filters: {
			getClassTime (val) {
				var start = String(val.time_start).split(':'),
					end = String(val.time_end).split(':');
				return val.date + ' ' + start.join(':') + '-' + end.join(':')
			}
		},
		created() {
			var { defaultScreen, type } = this.$attrs;
			this.screen.type = type ? type : 1
			if (defaultScreen) {
				this.defaultData = {...defaultScreen};
				extend(this.screen, defaultScreen);
				this.screen.date_start = defaultScreen.date[0];
				this.screen.date_end = defaultScreen.date[1]
				this.getAttendance()
			}						
		}
	}
</script>

<style>
</style>
