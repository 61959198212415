/**
@describe 家长通知配置
@date 2021-04-09
@by FZY
*/
<template>
	<div>
		<el-card style="position: relative;">
			<el-table :data="tableData" stripe>
				<el-table-column label='类型' prop='label' min-width='150px'></el-table-column>
				<el-table-column label='时间' prop='value' width='250px'>
					<template v-slot="scope">
						<!-- /剩余课时提醒 -->
						<el-select v-if="scope.row.key == 'lave_lesson_notice'" v-model="scope.row.value" size="mini" placeholder="" style="width: 100%;" @change="modify">
							<el-option v-for="(item, index) in restClass" :key="'lave_lesson_notice' + index" :label="item" :value="item"></el-option>
						</el-select>
						<!-- /有效期不足 -->
						<el-select v-else-if="scope.row.key == 'lave_expriation_notice'" v-model="scope.row.value" size="mini" style="width: 100%;"
						 placeholder="" @change="modify">
							<el-option v-for="(item, index) in restClass" :key="'lave_lesson_notice' + index" :label="item" :value="item"></el-option>
						</el-select>
						<!-- /前一日提醒 -->
						<el-time-picker v-else-if="scope.row.key == 'today_before_class_notice'" v-model="scope.row.value" :arrow-control='false'
						 size="mini" :editable='false' format="HH:mm:ss" value-format="HH:mm:ss" style="width: 100%;" @change="modify">
						</el-time-picker>
						<!-- /当日上课提醒 -->
						<el-select v-else-if="scope.row.key == 'today_class_notice'" v-model="scope.row.value" size="mini" placeholder="" style="width: 100%;" @change="modify">
							<el-option v-for="(item, index) in classRemind" :key="'lave_lesson_notice' + index" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<!-- /请假时间限制 -->
						<el-select v-else-if="scope.row.key == 'leave_limit'" v-model="scope.row.value" size="mini" placeholder="" style="width: 100%;" @change="modify">
							<el-option v-for="(item, index) in expiration" :key="'lave_lesson_notice' + index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</template>
				</el-table-column>
			</el-table>
			<loading-c :show-loading='loading'></loading-c>
		</el-card>
	</div>
</template>

<script>
	import {
		getNoticeConfigClass,
		getNoticeConfigRenew,
		modifyNoticeConfig
	} from '../../../assets/js/API/notification.js'
	import {
		CLASS_REMIND,
		EXPIRATION,
		REST_CLASS,
		LEAVE_TIME
	} from '../../../assets/js/CONFIG/notification.js'
	import loadingC from '../../common/loading.vue'
	export default {
		name: 'NotificationConfig',
		components: {
			loadingC
		},
		data() {
			return {
				loading: true,
				restClass: REST_CLASS,
				classRemind: CLASS_REMIND,
				expiration: EXPIRATION,
				tableData: [{
						value: '',
						key: 'lave_lesson_notice',
						label: '剩余课时提醒(课时)'
					},
					{
						value: '',
						key: 'lave_expriation_notice',
						label: '有效期不足(天)'
					},
					{
						value: '',
						key: 'today_before_class_notice',
						label: '前一日提醒'
					},
					{
						value: '',
						key: 'today_class_notice',
						label: '当日上课提醒',
					},
					{
						value: '',
						key: 'leave_limit',
						label: '请假时间限制'
					}
				]
			}
		},
		created() {
			this.getNoticeConfigRenew();
		},
		methods: {
			modify () {
				this.loading = true;
				var params = {
					lave_lesson_notice: '',
					lave_expriation_notice: '',
					today_before_class_notice: '',
					today_class_notice: '',
					leave_limit: ''
				}
				this.tableData.forEach( item => { 
					params[item.key] = item.value
				})
				modifyNoticeConfig.call(this,params)
					.then( res => {
						this.loading = false;
						this.getNoticeConfigRenew()
					})
					.catch( err => {
						this.loading = false;
						this.getNoticeConfigRenew()
					})
			},
			setTableData(data) {
				for (let i in data) {
					var index = this.tableData.findIndex(item => item.key == i)
					if (index != -1) this.tableData[index].value = data[i]
				}
			},
			getNoticeConfigClass() {
				getNoticeConfigClass.call(this)
					.then(res => {
						this.loading = false;
						if (res) this.setTableData(res)
					})
					.catch(err => this.loading = false)
			},
			getNoticeConfigRenew() {
				this.loading = true;
				getNoticeConfigRenew.call(this)
					.then(res => {
						if (res) this.setTableData(res)
						this.getNoticeConfigClass()
					})
					.catch(err => {
						this.getNoticeConfigClass()
					})
			}
		}
	}
</script>

<style scoped>
</style>
