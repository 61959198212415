<template>
  <div>
    <!-- 薪资详细信息 -->
    <breadcrumb-c :value_breadcrumb='value_breadcrumb' v-if="isHaveBreadCrumb"></breadcrumb-c>
    <el-card style="position: relative;">
      <el-row :gutter="10">
        <el-form :model="formData" size="small" label-width="102px" :rules="rules" ref='refForm'>
          <!-- 基本信息 start-->
          <el-col class="title fs-subtitle">
            基本信息
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formData.name" placeholder="请输入姓名" clearable disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星级" prop="level">
              <level-select-c :defaultSelect='formData.level' :disabled='!isSave' @update:select="changeLevel" :isGetClassFee='true'></level-select-c>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="底薪" prop="base_salary">
              <el-input v-model="formData.base_salary" placeholder="请输入底薪" clearable :style="{width: '100%'}" :disabled='disabledBaseSalary'>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月份" prop="date">
              <el-date-picker v-model="formData.date" format="yyyy-MM" value-format="yyyy-MM" :style="{width: '100%'}" placeholder="请输入月份" clearable type="month"></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- 基本信息 end -->
          <!-- 课时/缴费 start-->
          <el-col class="title fs-subtitle">
            课时<span class="fs-main prompt">( 课时费 = 课时费标准 × 课时数 )</span>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="15">
              <el-col :span="7">
                <el-form-item label="课时费标准" prop="classFeeStandar">
                  <el-input v-model="formData.classFeeStandar" placeholder="请输入课时费标准" clearable :style="{width: '100%'}" disabled>
                    <template slot="append">元/课时</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-col :span="14">
                  <el-form-item label="课时数" prop="classTime">
                    <el-input v-model="formData.classTime" placeholder="请输入课时数" disabled>
                      <template slot="append">课时</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button type="success" size="mini" @click="updateSalary(1,'classTime')">更新数据</el-button>
                </el-col>
                <el-col :span="4">
                  <el-button type="primary" size="mini" @click="showDetailDialog('当月课时数明细')">查看明细</el-button>
                </el-col>
              </el-col>
              <el-col :span="7">
                <el-form-item label="课时费" prop="classFee">
                  <el-input v-model="classFee" placeholder="请输入课时费" clearable :style="{width: '100%'}" disabled>
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <!-- 课时 end-->
          <!-- 奖励与提成 start-->
          <el-col class="title fs-subtitle">
            奖励与提成<span class="fs-main prompt">( 续费绩效 = 续费金额 × 提成点； 体验课绩效 = 体验课缴费 × 提成点； 当月绩效 = 续费绩效 + 体验课绩效 + 月度备课 + 月度回访 + 月度转化 + 季度转介绍 + 全勤奖 + 半年续费 + 其他奖惩 )</span>
          </el-col>
          <el-col :span="12">
            <el-col :span="14">
              <el-form-item label="续费金额" prop="renewal_money">
                <el-input v-model="formData.renewal_money" placeholder="请输入续费金额" disabled>
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-button type="success" size="mini" @click="updateSalary(3,'renewal_money')">更新数据</el-button>
            </el-col>
            <el-col :span="3">
              <el-button type="primary" size="mini" style="margin-left: 10px" @click="showDetailDialog('当月缴费金额明细')">查看明细</el-button>
            </el-col>
          </el-col>
          </el-col>
          <el-col :span="12">
            <el-form-item label="续费绩效" prop="renewal_performance">
              <el-input v-model="performanceRenew" placeholder="请输入续费绩效" disabled :style="{width: '100%'}" readonly>
                <template slot="append">
                  {{formData.old_registration_student}}%
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-col :span="14">
              <el-form-item label="体验课缴费" prop="experience_money">
                <el-input v-model="formData.experience_money" placeholder="请输入体验课缴费" disabled>
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-button type="success" size="mini" @click="updateSalary(2,'experience_money')">更新数据</el-button>
            </el-col>
            <el-col :span="3">
              <el-button type="primary" size="mini" style="margin-left: 10px" @click="showDetailDialog('当月体验课明细')">查看明细</el-button>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体验课绩效" prop="experience_performance">
              <el-input v-model="performanceExperience" placeholder="请输入体验课绩效" disabled :style="{width: '100%'}" readonly>
                <template slot="append">
                  {{formData.new_registration_student}}%
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月度备课" prop="preparation_lession">
              <el-input v-model="formData.preparation_lession" placeholder="请输入月度备课" clearable :style="{width: '100%'}">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月度转化" prop="transformation">
              <el-input v-model="formData.transformation" placeholder="请输入月度转化" clearable :style="{width: '100%'}">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月度回访" prop="return_visit">
              <el-input v-model="formData.return_visit" placeholder="请输入月度回访" clearable :style="{width: '100%'}">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="季度转介绍" prop="quarterly_referral">
              <el-input v-model="formData.quarterly_referral" placeholder="请输入季度转介绍" clearable :style="{width: '100%'}">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="全勤奖" prop="perfect_attendance">
              <el-input v-model="getPerfectAttendance" placeholder="请输入全勤奖" clearable :style="{width: '100%'}" readonly>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="半年续费" prop="halfyear_renewal">
              <el-input v-model="formData.halfyear_renewal" placeholder="请输入半年续费" clearable :style="{width: '100%'}">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他奖惩" prop="other_awards">
              <el-input v-model="formData.other_awards" placeholder="请输入其他奖惩,惩罚请写-号" clearable :style="{width: '100%'}">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="当月绩效" prop="moth_performance">
                  <el-input v-model="Allperformance" placeholder="请输入当月绩效" clearable :style="{width: '100%'}" disabled>
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <!-- 奖励与提成 end-->
          <!-- 出勤 start-->
          <el-col class="title fs-subtitle">
            出勤<span class="fs-main prompt">( 实际底薪 = 实际出勤 × 日薪；当月应发 = 课时费 + 当月绩效 + 实际底薪 )</span>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="本月应出勤" prop="attendance_day">
                  <el-select v-model="formData.attendance_day" :style="{width: '100%'}">
                    <el-option v-for="item in dayOption" :key='item.value' :value='item.value' :label="item.label"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际出勤" prop="real_attendance_day">
                  <el-input v-model="formData.real_attendance_day" placeholder="请输入实际出勤" clearable :style="{width: '100%'}">
                    <template slot="append">天</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际底薪" prop="actual_base_salary">
                  <el-input v-model="actualBaseSalary" placeholder="请输入实际底薪" clearable :style="{width: '100%'}" disabled>
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-form-item label="当月应发" prop="month_salary">
              <el-input v-model="Allsalary" placeholder="请输入当月应发" clearable :style="{width: '100%'}" disabled>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- 出勤和应发工资 end-->
        </el-form>
      </el-row>
      <!-- 帮助解析 -->
      <div style="position: absolute;top: 5px;right: 10px;color: #555;cursor: help;" class="iconfont icon-yiwen"></div>
      <!-- 保存 -->
      <div class="flex align-center">
        <div style="width: 92px;margin-right: 10px;box-sizing: border-box;" class="line"></div>
        <el-button type="primary" size="small" @click='cancel'>返回</el-button>
        <el-button type="primary" size="small" @click='save' style="margin-left: 10px;" v-if="isSave">保存</el-button>
        <span style="color: #ea9d66;" class="fs-14 mg-l-10" v-else>不支持修改,如有疑问请联系管理员</span>
        <div style="" class="line flex-1_1 mg-l-10"></div>
      </div>
    </el-card>
    <el-dialog :title="salaryDialogTitle" :visible.sync="salaryDialog" width="50%" @close="salaryDialogClose">
      <el-table :data="salaryTableData" stripe :header-cell-style="{background:'#f2f2f2'}">
        <el-table-column prop='student_name' label='学员姓名' key="student_name"></el-table-column>
        <el-table-column prop='teacher_name' label='主课老师' key="teacher_name" v-if="salaryDialogTitle=='当月课时数明细'"></el-table-column>
        <el-table-column prop='a_status_desc' label='考勤状态' key="a_status_desc" v-if="salaryDialogTitle=='当月课时数明细'"></el-table-column>
        <el-table-column prop='class_name' label='班级' key="class_name" min-width="190" v-if="salaryDialogTitle=='当月课时数明细'"></el-table-column>
        <el-table-column prop='class_time' label='课时' key="class_time" min-width="250" v-if="salaryDialogTitle=='当月课时数明细'"></el-table-column>
        <el-table-column prop='class_time_num' label='课时数' key="class_time_num" v-if="salaryDialogTitle=='当月课时数明细'"></el-table-column>
        <el-table-column prop='course_type' label='课程类型' key="course_type" v-if="salaryDialogTitle!='当月课时数明细'"></el-table-column>
        <el-table-column prop='course_package_name' label='课包' key="course_package_name" min-width="160" v-if="salaryDialogTitle!='当月课时数明细'"></el-table-column>
        <el-table-column prop='paid_amount' label='价格/元' key="paid_amount" v-if="salaryDialogTitle!='当月课时数明细'"></el-table-column>
        <el-table-column prop='created_at' label='购买时间' key="created_at" min-width="160" v-if="salaryDialogTitle!='当月课时数明细'"></el-table-column>
      </el-table>
      <el-pagination :total="salaryDialogParams.total" :current-page="salaryDialogParams.page" layout="total, prev, pager, next" background class="mg-t-10" @current-change='pageChange'></el-pagination>
    </el-dialog>
    <loading-c :show-loading='loading'></loading-c>
  </div>
</template>

<script>
import breadcrumbC from "../../common/v-breadcrumb.vue";
import loadingC from "../../common/loading.vue";
import { extend } from "../../../assets/js/util.js";
import {
  getSalaryTemplate,
  addTeacherSalary,
  getTeacherSalaryDetail,
  modifyTeacherSalary,
} from "../../../assets/js/API/salary.js";
import levelSelectC from "../../common/v-level-select.vue";
import validate from "../../../assets/js/validate.js";
import { updateSalary, checkSalaryDetail } from "../../../api/salary.js";
const validatorCommon = validate.validatorCommon;
export default {
  name: "SalaryInfo",
  props: {
    isHaveBreadCrumb: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      disabledBaseSalary: false,
      isSave: true,
      dayOption: [],
      value_breadcrumb: ["机构管理", "薪资管理", "薪资详细信息"],
      rules: {
        name: validatorCommon.mandatory("请输入姓名"),
        level: validatorCommon.mandatory("请选择星级"),
        base_salary: validatorCommon.isPrice,
        date: validatorCommon.mandatory("请选择月份"),
        classFeeStandar: validatorCommon.isPrice,
        classTime: [
          ...validatorCommon.mandatory("请输入课时数"),
          ...validatorCommon.float("课时数格式不正确"),
        ],
        renewal_money: validatorCommon.float("格式不正确"),
        experience_money: validatorCommon.float("格式不正确"),
        preparation_lession: validatorCommon.float("格式不正确"),
        return_visit: validatorCommon.float("格式不正确"),
        transformation: validatorCommon.float("格式不正确"),
        quarterly_referral: validatorCommon.float("格式不正确"),
        perfect_attendance: validatorCommon.float("格式不正确"),
        halfyear_renewal: validatorCommon.float("格式不正确"),
        other_awards: validatorCommon.float("格式不正确"),
        real_attendance_day: [
          ...validatorCommon
            .mandatory("请输入实际出勤天数")
            .concat(validatorCommon.number),
          {
            validator: (rule, value, callback) => {
              if (value > this.formData.attendance_day)
                return callback(
                  new Error(
                    "实际出勤天数不能大于应出勤天数" +
                    this.formData.attendance_day
                  )
                );
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      formData: {
        sid: "",
        name: "",
        level: "",
        base_salary: "",
        student: "",
        date: null,
        classFeeStandar: "",
        classTime: "",
        classFee: "",
        renewal_money: "",
        experience_money: "",
        renewal_performance: "",
        experience_performance: "",
        moth_performance: "",
        perfect_attendance: "",
        preparation_lession: "",
        return_visit: "",
        transformation: "",
        quarterly_referral: "",
        halfyear_renewal: "",
        other_awards: "",
        attendance_day: "",
        real_attendance_day: "",
        actual_base_salary: "",
        month_salary: "",
        new_registration_student: "",
        old_registration_student: "",
        full_time: "",
      },
      // 对话框参数
      salaryDialog: false,
      salaryTableData: [],
      salaryDialogTitle: "",
      salaryDialogParams: {
        page: 1,
        total: 0,
        type: 1,
      },
    };
  },
  methods: {
    changeLevel(val,option){
      if(option==1) return
      let data = {}
      for(let item of option){
        if(item['level']==val){
          data = item
        }
      }
      this.formData['level'] = val
      this.formData['classFeeStandar'] = data['classfee_standard']
    },
    cancel() {
      this.$route.query.salaryId
        ? this.$router.go(-1)
        : this.$emit("update:show", false);
    },
    getSalaryTemplate() {
      getSalaryTemplate
        .call(this)
        .then((res) => {
          var data = this.formData;
          res = res.awardOther[0];
          data.perfect_attendance = res.full_time;
          if (!this.$route.query.salaryId) {
            //增加
            data.full_time = res.full_time;
            data.preparation_lession = res.bonus_monthly_lesson;
            data.return_visit = res.bonus_monthly_visit;
            data.old_registration_student = res.old_registration_student;
            data.new_registration_student = res.new_registration_student;
          }
        })
        .catch((err) => { });
    },
    async addTeacherSalary() {
      var bool = await this.$refs.refForm.validate().catch((err) => { });
      if (!bool) return;
      addTeacherSalary
        .call(this, this.formData)
        .then((res) => {
          this.$emit("isShow", false);
        })
        .catch((err) => { });
    },
    save() {
      this.formData.experience_performance = this.performanceExperience;
      this.formData.renewal_performance = this.performanceRenew;
      this.formData.moth_performance = this.Allperformance;
      this.formData.month_salary = this.Allsalary;
      this.formData.classFee = this.classFee;
      this.formData.perfect_attendance = this.getPerfectAttendance;
      this.formData.actual_base_salary = this.actualBaseSalary;
      this.$route.query.salaryId
        ? this.modifyTeacherSalary()
        : this.addTeacherSalary();
    },
    computedDate(date) {
      //计算发薪资月份和 默认应出勤天数
      const DATE = date ? new Date(date) : new Date(),
        year = DATE.getFullYear(),
        month = DATE.getMonth();
      var attendance_day = new Date(year, month + 1, 0).getDate();
      for (let i = 1; i <= attendance_day; i++) {
        this.dayOption.push({
          value: i,
          label: i + "天",
        });
      }
      !date && (this.formData.date = year + "-" + month);
    },
    computedSalary() {
      //计算薪资
    },
    async getSalaryInfo(id) {
      const { data: res } = await this.$http.get(
        "/organization/salary?teacherId=" + id
      );
      if (res.code == 200) {
        if (this.$route.query.salaryId) {
          this.formData.name = res.data.name;
        } else {
          // extend(this.formData, res.data);
        }
      }
    },
    getTeacherSalaryDetail() {
      getTeacherSalaryDetail
        .call(this, this.$route.query.salaryId)
        .then((res) => {
          extend(this.formData, res);
          this.computedDate(this.formData.date);
        })
        .catch((err) => { });
    },
    modifyTeacherSalary() {
      modifyTeacherSalary
        .call(this, this.formData, this.$route.query.salaryId)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => { });
    },
    // 查看明细
    showDetailDialog(key) {
      this.loading = true;
      switch (key) {
        case "当月课时数明细":
          this.salaryDialogTitle = "当月课时数明细";
          this.checkSalaryDetail(1);
          break;
        case "当月缴费金额明细":
          this.salaryDialogTitle = "当月缴费金额明细";
          this.checkSalaryDetail(3);
          break;
        case "当月体验课明细":
          this.salaryDialogTitle = "当月体验课明细";
          this.checkSalaryDetail(2);
          break;
        default:
          break;
      }
    },
    pageChange(page) {
      this.salaryDialogParams.page = page;
      this.checkSalaryDetail(this.salaryDialogParams.type);
    },
    // 更新数据
    updateSalary(key,name) {
      let id = this.$route.query.salaryId;
      let params = {
        id,
        teacher_id: this.$route.query.teacherId,
        type: key,
        date: this.formData.date,
      };
      updateSalary(params)
        .then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data
            this.$message.success("更新成功");
            this.formData[name] = data[name]
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    //查看明细接口
    checkSalaryDetail(type) {
      this.salaryDialogParams.type = type;
      checkSalaryDetail({
        type,
        date: this.formData.date,
        teacher_id: this.$route.query.teacherId,
        page: this.salaryDialogParams.page,
      })
        .then((res) => {
          this.salaryTableData = res.data.data.data;
          this.salaryDialogParams.total = res.data.data.total;
          this.loading = false;
          this.salaryDialog = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    salaryDialogClose() {
      this.salaryTableData = [];
      this.salaryDialogParams.page = 1;
      this.salaryDialogParams.total = 0;
      this.salaryDialogParams.type = 1;
    },
  },
  computed: {
    Allperformance() {
      var data = this.formData;
      return (
        Number(this.performanceRenew) +
        Number(this.performanceExperience) +
        Number(data.preparation_lession) +
        Number(data.return_visit) +
        Number(data.transformation) +
        Number(data.quarterly_referral) +
        Number(this.getPerfectAttendance) +
        Number(data.halfyear_renewal) +
        Number(data.other_awards)
      ).toFixed(2);
    },
    performanceRenew() {
      var data = this.formData;
      return (
        (data.old_registration_student / 100) *
        data.renewal_money
      ).toFixed(2);
    },
    performanceExperience() {
      var data = this.formData;
      return (
        (data.new_registration_student / 100) *
        data.experience_money
      ).toFixed(2);
    },
    actualBaseSalary() {
      var data = this.formData;
      return (
        (data.base_salary / data.attendance_day) *
        data.real_attendance_day
      ).toFixed(2);
    },
    Allsalary() {
      var data = this.formData;
      return (
        Number(this.Allperformance) +
        Number(this.actualBaseSalary) +
        Number(this.classFee)
      ).toFixed(2);
    },
    classFee() {
      var data = this.formData;
      return (data.classTime * data.classFeeStandar).toFixed(2);
    },
    getPerfectAttendance() {
      var data = this.formData;
      if (data.real_attendance_day < data.attendance_day) return 0;
      return data.full_time;
    },
  },
  created() {
    var teacherId = this.$route.query.teacherId | this.$attrs.teacherId,
      salaryId = this.$route.query.salaryId;
    this.formData.sid = teacherId;
    this.getSalaryInfo(teacherId);
    if (salaryId) {
      //修改
      this.disabledBaseSalary = false;
      this.getTeacherSalaryDetail();
    } else {
      //新增
      this.disabledBaseSalary = true;
      this.getSalaryTemplate();
      this.computedDate();
    }
    var role = window.sessionStorage.getItem("role");
    if (role > 2) this.isSave = false;
  },
  mounted() {
    this.$emit("resetFields", this.$refs.refForm.resetFields);
  },
  components: {
    breadcrumbC,
    levelSelectC,
    loadingC,
  },
};
</script>

<style scoped>
.line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-weight: bold;
}
.prompt {
  color: #de7931;
  margin-left: 5px;
}
.infoBtn {
  background-color: #52cc6f;
  display: inline-block;
  color: #fff;
  padding: 0 5px;
  text-align: center;
  border-radius: 4px;
  margin-left: 10px;
}
.blue {
  background-color: #3291f8;
}
</style>
