const formValidate = {
	 name:[
	 		{required: true,message: '必填',trigger: 'blur'},
	 		{min: 2,max: 20,message: '长度在 2 到 20 个字符'},
	 		{pattern: /([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_]){1,20}$/, message: '只能为数字或者中文'},
	 	],
	 capacity:[
		{required: true,message: '请输入容量',trigger: 'blur'},
		{type:'number',message:'必须为数字类型'},
	 ],
	 detail:[
		 {pattern: /([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_]){1,20}$/, message: '只能为中文'},
	 ]
}

export default{
	formValidate,
}