import { render, staticRenderFns } from "./studentTurnLists.vue?vue&type=template&id=485211dc&scoped=true&"
import script from "./studentTurnLists.vue?vue&type=script&lang=js&"
export * from "./studentTurnLists.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485211dc",
  null
  
)

export default component.exports