<template>
	<!-- 产值记录 -->
	<div>
		<el-row>
			<el-col :span="4">
				<el-input size="medium" clearable placeholder="请输入学生姓名" v-model="screen.key" @blur="keyChange" style="width: 100%;">
					<i slot="suffix" class="el-input__icon el-icon-search cursor-p" @click="keyChange"></i>
				</el-input>
			</el-col>
			<el-col :span="19" :offset='1'>
				<data-analysis-search :defaultData="defaultData" @getScreen='getScreen' lastMonth='上月' thisMonth='当月' yesterday="昨天" today="今天" sevenDay="7天"></data-analysis-search>
			</el-col>
		</el-row>
		<div class="flex mg-t-10 align-center" style="color: #6593a0;">
			<div class="" ><span>消课人次: </span><span class="" style="color: #0086B3;">{{dismissal_total}}</span></div>
			<div class="mg-l-50 "><span>产值小计: </span><span style="color: #0086B3;">{{output_value_total}}</span>元</div>
		</div>
		<el-table :data='tableData' stripe>
			<el-table-column type="expand">
				<template v-slot="scope">	
					<div class="scrollX">
						<div class="scrollChildWidth">
							<div class="card" v-for="(item, index) in scope.row.attendances" :key='index'>
								<div>班级名称: {{item.class_name}}</div>
								<div>课程名称: {{item.course_name}}</div>
								<div>老师名称: {{item.teacher_name}}</div>
								<div>消耗课时: {{item.lesson_times}}</div>
								<div>上课日期: {{item.date}}</div>
								<div>上课时间: {{item | getClassTime}}</div>
							</div>
						</div>						
					</div>					
				</template>
			</el-table-column>
			<el-table-column prop="school_name" label='校区' width="150"></el-table-column>
			<el-table-column prop="student_name" label='学员名称' min-width="100px"></el-table-column>
			<el-table-column prop="lesson" label='消耗课时'></el-table-column>
			<el-table-column prop="output_value" label='产值'>
				<template v-slot="scope">
					{{scope.row.output_value | insertSeparator}}
				</template>
			</el-table-column>
			<el-table-column prop="date" label='日期'></el-table-column>
		</el-table>
		<el-pagination :current-page="screen.page" :total="total" background layout="total, prev, pager, next" class="mg-t-10" @current-change='pageChange'></el-pagination>
	</div>
</template>

<script>
	import dataAnalysisSearch from './data-analysis-search.vue'
	import {
		extend,
		insertMoneySeparator
	} from '../../../assets/js/util.js'
	export default {
		name: 'OutputvaluList',
		data () {
			return {
				output_value_total: 0,
				dismissal_total: 0,
				total: 0,
				tableData: [],
				screen: {
					page: 1,
					key: '',
					date_start: '',
					date_end: '',
					school_id: '',
					number: 10
				},
				defaultData: {}
			}
		},
		filters: {
			insertSeparator (val) {
				return insertMoneySeparator(val)
			}
		},
		methods: {
			pageChange (page) {
				this.screen.page = page;
				this.getValuerecord()
			},
			keyChange () {
				this.screen.page = 1;
				this.getValuerecord()
			},
			getScreen (screen) {
				this.screen.page = 1;
				extend(this.screen, screen)
				this.getValuerecord()
			},
			async getValuerecord () {
				var { data: res } = await this.$http.get('/statistics/valuerecord', {
					params: this.screen
				})
				if (res.code == 0) {
					this.tableData = res.data.data;
					this.screen.page = res.data.page;
					this.total = res.data.total;
					this.dismissal_total = res.data.dismissal_total;
					this.output_value_total = res.data.output_value_total;
				} else {this.$message.error(res.message)}
			}
		},
		components: {
			dataAnalysisSearch
		},
		filters: {
			getClassTime (val) {
				var start = String(val.time_start).split(':'),
					end = String(val.time_end).split(':');
				return start.join(':') + '-' + end.join(':')
			}
		},
		created() {
			var { defaultScreen } = this.$attrs;
			if (defaultScreen) {
				this.defaultData = {...defaultScreen};
				extend(this.screen, defaultScreen);
				this.screen.date_start = defaultScreen.date[0];
				this.screen.date_end = defaultScreen.date[1]
				this.getValuerecord()
			}						
		}
	}
</script>

<style scoped>
	.card {
		display: inline-block;
		background-color: #FFFFFF;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
		width: 250px;
		border-radius: 5px;
		padding: 10px;
		height: 134px;
		box-sizing: border-box;
		margin-right: 10px;
	}
	.scrollX {
		white-space: nowrap;
		width: 100%;
		overflow: auto;
		padding: 10px 10px;
	}
	.scrollChildWidth {
		max-width: calc(100% + 450px);
	}
</style>
