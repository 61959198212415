<!-- 财务审批 -->
<template>
	<div class="flex align-center">
		<el-button type="danger" size="small" @click="approval(3)">驳回</el-button>
		<el-button type="success" size="small" @click="approval(2)">通过</el-button>
	</div>
</template>

<script>
	import {
		processReimbursement,
		processLoan,
		processPayment,
		processInvoice,
		processProcurement,
		processRefund
	} from '../../assets/js/API/financial.js'
	export default {
		name: 'FinancialApproval',
		props: {
			financialId: {
				default: ''
			},
			financialType: {
				default: ''
			}
		},
		data() {
			return {
    
			}
		},
		created() {

		},
		methods: {
			approval(status) {
				var mapHttp = {
					1: processReimbursement,
					2: processLoan,
					3: processPayment,
					4: processInvoice,
					5: processProcurement,
					6: processRefund
				}
				this.$prompt('请输入备注', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /.+/,
					inputErrorMessage: '请输入备注'
				}).then(({ value }) => {
					var params = {
						id: this.financialId,
						financialId: this.financialId, // 付款和采购用的是这个
						remark: value,
						status
					}
					mapHttp[this.financialType].call(this, params)
					    .then( res => {
							if (res) this.$emit('success')
						})
				})
			}
		}
	}
</script>

<style>
</style>
