import Vue from 'vue'
import Vuex from 'vuex'

import {
	INSERT_ROUTELIST,
	REMOVE_ROUTERLIST,
	SET_SELECTROUTE,
} from './mutations-type'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { // 全局变量
	routeLists: [],
	selectRoute: 0,
	routeListsMaxLength: 10
  },
  
  getters: { // 类似于Vue的computed

  },
  mutations: { // 类似于Vue的Methods,修改state的状态的唯一途径
	[INSERT_ROUTELIST] (state, params) {
		var flag = state.routeLists.some( item => item.name == params.name);
		if (flag) return
		if (state.routeLists.length >= state.routeListsMaxLength) Object.assign(state.routeLists[0], params)
		else state.routeLists.push(params)
	},
	[REMOVE_ROUTERLIST] (state, index) {
		state.routeLists.splice(index, 1)
	},
	[SET_SELECTROUTE] (state, index) {
		state.selectRoute = index
	}
  },

  actions: { // 异步的mutations
  },
  modules: { // 模块
  }
})
