<template>
	<!-- 课程分类管理 -->
	<div class="">
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>机构管理</el-breadcrumb-item>
		  <el-breadcrumb-item>课程分类管理</el-breadcrumb-item>
		</el-breadcrumb>
		<course-c></course-c>
	</div>
</template>

<script>
	import courseC from '../common/iteration-course-table.vue'
	export default{
		name: 'Charges',
		data(){
			return{
				
			}
		},
		components:{
			courseC,
		},
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>

</style>
