/* 工具函数 */
export function extend(scoreObj, obj) {
	for (let i in scoreObj) {
		if (obj.hasOwnProperty(i)) {
			scoreObj[i] = obj[i]
		}
	}
}

/* 
	@describe 字符串变成数字
	@date 2021-04-01
	@by FZY	
 */
export function toNumber(str) {
	return isNaN(str - 0) ? str : str - 0
}


/* 
	@describe 金钱插入千位分隔符
	@date 2021-04-02
	@by FZY	
 */
export function insertMoneySeparator(money) {
	var insert = ',';
	var count = 3;
	var negative = money - 0 >= 0 ? '' : '-';
	var lists = String(Math.abs(money)).split('.'); 
	var _lists = lists[0].split(''); // 要操作的列表
	var number = Math.floor((_lists.length) / count); // 要插入多少个
	var position = (_lists.length) % count; // 插入的位置
	for (var i = 0; i < number; i++) {
		if (position != 0) {
			_lists.splice(position, 0, insert);
			position += count + 1
		} else position += 3
	}
	if (lists.length == 2) return negative + _lists.join('') + '.' + lists[1]
	else return negative + _lists.join('')
}

export function formatSameData(data, key) {
	//key值是用来决定对象这个key值一样的放到一个数组,data就是需要格式化的数组
	var result = {},
		length = data.length - 1,
		i = 0;
	closure(data[i])

	function closure(item) {
		var Key = key + item[key];
		(!result.hasOwnProperty(Key)) && (result[Key] = []) //result没有添加了这个key值
		result[Key].push(item)
		i++;
		if (i > length) return result
		return closure(data[i])
	}
	var arr = []
	for (let i in result) {
		arr.push(result[i])
	}
	result = [...arr]
	return result
}

export function checkFileType(fileType, defaultImg = true) { //最好是mp4格式
	var result = {
		video: false,
		img: false
	}
	const VIDEOTYPE = ['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'];
	const IMGTYPE = ['image/gif', 'image/jpeg', 'image/png'];
	if (VIDEOTYPE.indexOf(fileType) != -1) result.video = true
	else if (IMGTYPE.indexOf(fileType) != -1) result.img = true
	else defaultImg && (result.img = true)
	return result
}

/**
	@describe 导出execl
	@date 2021-04-01
	@by FZY
*/
export function exportExcel(data, name, type = 'application/vnd.ms-excel, charset=UTF-8') {
	var blob = new Blob([data], {
		type
	})
	if ('msSaveOrOpenBlob' in navigator) { // IE
		window.navigator.msSaveOrOpenBlob(blob, name)
	} else {
		var a = document.createElement('a');
		var url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = name;
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a)
	}
}

/* 
	@describe 工具函数
	@date 2021-06-17
	@by LZW
	@params 参数
	@api=/user/admin/export
*/
// 列表导出类
export function exportUtils(params,api) {
	let url = httpUrl(`${process.env.VUE_APP_BASE_URL+api}`,params);
	window.open(url,"_self");
}

function httpUrl(url, data) {
	if (typeof url == "undefined" || url == null || url == "") {
	  return "";
	}
	if (
	  typeof data == "undefined" ||
	  data == null ||
	  typeof data != "object"
	) {
	  return "";
	}
	url += url.indexOf("?") != -1 ? "" : "?";
	for (var k in data) {
	  url +=
		(url.indexOf("=") != -1 ? "&" : "") + k + "=" + encodeURI(data[k]);
	}
	return url;
}
