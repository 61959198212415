// 2021-03-10 有FZY创建 用于缴费的配置
export const TURN_TYPE = [{
		id: 1,
		name: '全部转赠'
	},
	{
		id: 2,
		name: '部分转赠'
	}
]

export const PAY_TYPE = [
	// 缴费类型
	{
		id: 1,
		name: '全款'
	},
	{
		id: 2,
		name: '定金'
	}
]

export const REPLACEMENT_TYPE = [
	// 更换套餐的 更换类型
	{
		id: '1',
		name: '新购课包'
	},
	{
		id: '2',
		name: '更换课包'
	}
]
