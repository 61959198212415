<template>
	<!-- 上课场地表格 -->
	<div>
		<div class="header" v-if="operation">
			<el-button type="primary" @click="addClassRoom" size="small">新增上课场地</el-button>
		</div>
		<el-table :data="classroomsList" ref="refTable" @current-change='current_change($event)' row-key="id" class="fsMedia" :highlight-current-row='isHightlight' :row-class-name="tableRowClassName">
			<el-table-column prop="name" show-overflow-tooltip label="场地名称" width="150px">
			</el-table-column>
			<el-table-column prop="capacity" label="可容纳人数" show-overflow-tooltip width="100px">
			</el-table-column>
			<el-table-column label="关联班级" show-overflow-tooltip min-width="100px">
				<template slot-scope="scope">
					<el-tag size="small" v-for="(item, index) in scope.row.classroomsHasClass" :key="index" class="mg-r-10" v-if="item != ''">{{item}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="150px" v-if="operation">
				<template slot-scope="scope">
					<el-button size="small" type="primary" @click="formVisibleByLook(scope.row)">查看</el-button>
					<el-button size="small" type="primary" @click="delVisible(scope.row)" v-if="scope.row.classroomsHasClass.length <= 0">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" :current-page="page" @current-change="pageChange" :total="total" class="mg-t-10">
		</el-pagination>
		
		<el-dialog title="上课场地" :visible.sync="formVisible" width="40%" @close="$refs.formClassRoomRef.resetFields()" center>
			<el-form :model="formClassRoom" ref="formClassRoomRef" :rules="rules">
				<el-form-item label="场地名称" prop="name">
					<el-input type="text" placeholder="请输入场地名称" v-model="formClassRoom.name"></el-input>
				</el-form-item>
				<el-form-item label="可容纳人数" prop="capacity">
					<el-input type="text" placeholder="请输入可容纳人数" v-model.number="formClassRoom.capacity"></el-input>
				</el-form-item>
				<el-form-item label="详细信息" prop="detail">
					<el-input type="text" placeholder="请输入详细信息" v-model="formClassRoom.detail"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" @click="updateClassRoomByCommit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Sortable from 'sortablejs'
	import vali from '../../assets/js/validate.js'
	import {
		getClassRoom,
		modifyClassRoom,
		delClassRoom,
		addClassRoom,
		sortClassRoom
	} from '../../assets/js/API/classroom.js'
	export default {
		name: 'ClassRoomTable',
		props: {
			isHightlight: {
				type: Boolean,
				default: false
			},
			selectRowIndex: {
				default: -1
			},
			operation: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				rowIndex: -1,
				classroomsList: [],
				formVisible: false,
				formClassRoom: {
					id: '',
					name: '',
					capacity: '',
					detail: ''
				},
				isAdd: false,
				page: 1,
				total: 0,
				rules: {
					name: vali.validatorCommon.mandatory('请输入场地名称'),
					capacity: [
						...vali.validatorCommon.mandatory('请输入可容纳人数'),
						...vali.validatorCommon.number
					]
				}
			}
		},
		methods: {
			tableRowClassName({ row, rowIndex }) {
				if (this.rowIndex == rowIndex) return 'hight-light-row'
				return ''
			},
			addClassRoom() {
				this.formVisible = true;
				this.isAdd = true;
			},
			current_change($event) {
				this.rowIndex = -1;
				this.$emit('current_change', $event)
			},
			pageChange(page) {
				this.page = page;
				this.getClassRooms();
			},
			rowDrop() {
				const tbody = document.querySelector('.el-table__body-wrapper tbody')
				const _this = this
				var newArr = JSON.parse(JSON.stringify(_this.classroomsList))
				Sortable.create(tbody, {
					onEnd({
						newIndex,
						oldIndex
					}) {
						const currRow = _this.classroomsList.splice(oldIndex, 1)[0]
						_this.classroomsList.splice(newIndex, 0, currRow)
						_this.sortClassRooms();
					}
				})
			},
			/* 排序上课场地 */
			sortClassRooms() {
				let ids = []
				this.classroomsList.forEach((item) => {
					ids.push(item.id);
				})
				sortClassRoom.call(this, { ids })
							 .then( res => {
								 if (res) this.getClassRooms();
							 })
							 .catch( err => console.log(err))
			},
			getClassRooms() {
				getClassRoom.call(this, this.page)
							 .then( res => {
								 if (res) {
									 this.classroomsList = res.data;
									 this.page = res.current_page;
									 this.total = res.total;
									 this.returnIndex()
								 }
							 })
							 .catch( err => console.log(err))
			},
			async updateClassRoomByCommit() {
				var bool = await this.$refs.formClassRoomRef.validate().catch( err => console.log(err));
				if (!bool) return
				if (this.isAdd) {
					addClassRoom.call(this, this.formClassRoom)
								.then( res => {
									if (res) {
										this.getClassRooms();
										this.formVisible = false;
										this.isAdd = false;
									}
								})
								.catch( err => console.log(err))
				} else {
					modifyClassRoom.call(this, this.formClassRoom.id, this.formClassRoom)
								   .then( res => {
									   if (res) {
										   this.getClassRooms();
										   this.formVisible = false;
										   this.isAdd = false;
									   }
								   })
								   .catch( err => console.log(err))
				}
			},
			/* 显示form */
			formVisibleByLook(row) {
				this.formClassRoom.name = row.name;
				this.formClassRoom.capacity = row.capacity;
				this.formClassRoom.detail = row.detail;
				this.formClassRoom.id = row.id;
				this.formVisible = true;
			},
			/* 删除上课场地 */
			async delVisible(row) {
				const result = await this.$confirm('是否确认删除', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).catch(err => err);
				if ('confirm' == result) {
					delClassRoom.call(this, row.id)
								.then( res => {
									if (res) {
										this.getClassRooms();
									}
								})
								.catch( err => console.log(err))
				}
			},
			/* 根据上课场地获取在已有上课场地的Index */
			returnIndex () {
				this.rowIndex = this.classroomsList.findIndex( item => {
					return item.id == this.selectRowIndex
				});
				this.rowIndex >= 0 && (this.$emit('update:courseNumber', this.classroomsList[this.rowIndex].capacity))				
			}
		},
		mounted() {
			this.getClassRooms();
			/* 实例化拖拽组件方法 */
			//使每次都可以拖拽
			setTimeout(() => {
				this.rowDrop()
			}, 100);
		},
		watch: {
			'selectRowIndex': function (val) {
				this.$refs.refTable.setCurrentRow()
				this.returnIndex()
			}
		}
	}
</script>

<style lang="less" scoped>
	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		color: #888888;
	}
	/deep/ .el-table .hight-light-row{
		background-color: #fed90e;
	}
</style>
