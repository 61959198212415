<!-- 分页 -->
<template>

	<div>
		<!-- 分页 -->		
		<template>
		  <div class="block">
			<el-pagination background
			  @size-change="handleSizeChange"
			  @current-change="handleCurrentChange"
			  :current-page="currentPage"
			  :page-size="pageSize"
			  layout="total, prev, pager, next"
			  :total="total">
			</el-pagination>
		  </div>
		 </template>
		<!-- 分页 -->
	</div>

</template>
<script>
	export default {
		name: 'Pagination',
		props: {
			// 分页
			currentPage: {
				type: Number,
				default: 1
			},
			pageSize: {
				type: Number,
				default: 10
			},
			total: {
				type: Number,
				default: 10
			}
		},
		methods: {
			// 页码改变
			handleSizeChange (p) {
			},
			// 当前页改变
			handleCurrentChange (p) {
				this.$emit('handleCurrentChange', { page: p })
			},
		}
		
	}
</script>
<style scoped lang="less">
	
</style>