/* ----------------班级API------------------ */
export async function addClass (params) {
	const { data: res } = await this.$http.post('/education/classes', params);
	if (res.code === 200) {
		this.$message.success('添加班级成功');
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getClass (params) {
	const { data: res } = await this.$http.get('/education/classes', {
		params
	})
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function delClass (id) {
	const { data: res } = await this.$http.delete('/education/classes/' + id)
	if (res.code === 200) {
		this.$message.success('删除班级成功')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getClassInfo (id) {
	const { data: res } = await this.$http.get('/education/classes/detail/' + id)
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function modifyClass (id, params) {
	const { data: res } = await this.$http.patch('/education/classes/' + id, params)
	if (res.code === 200) {
		this.$message.success('修改成功');
		return res.data
	} else this.$message.error(res.msg || res.message)
}
