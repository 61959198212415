/* 监护人角色 */
const roles = [{
		name: '妈妈',
		val: 1
	},
	{
		name: '爸爸',
		val: 2
	},
	{
		name: '奶奶',
		val: 3
	},
	{
		name: '爷爷',
		val: 4
	},
	{
		name: '外婆',
		val: 5
	},
	{
		name: '外公',
		val: 6
	},
	{
		name: '自己',
		val: 7
	},
	{
		name: '伯母',
		val: 8
	},
	{
		name: '伯父',
		val: 9
	},
	{
		name: '阿姨',
		val: 10
	},
	{
		name: '叔叔',
		val: 11
	},
	{
		name: '舅妈',
		val: 12
	},
	{
		name: '舅舅',
		val: 13
	},
	{
		name: '姨妈',
		val: 14
	},
	{
		name: '姨夫',
		val: 15
	},
	{
		name: '姑姑',
		val: 16
	},
	{
		name: '姑父',
		val: 17
	},
	{
		name: '姐姐',
		val: 18
	},
	{
		name: '哥哥',
		val: 19
	},
	{
		name: '妹妹',
		val: 20
	},
	{
		name: '弟弟',
		val: 21
	},
	{
		name: '干妈',
		val: 22
	},
	{
		name: '干爸',
		val: 23
	},
]
/* 性别*/
const sex = [{
		name: '男',
		val: 1
	},
	{
		name: '女',
		val: 2
	}
]
/* 证件类型*/
const certType = [{
		name: '身份证',
		val: 1
	},
	{
		name: '港澳通行证',
		val: 2
	},
	{
		name: '台湾通行证',
		val: 3
	},
	{
		name: '护照',
		val: 4
	}
]
/* 学生来源*/
const source = [{
		name: '到访',
		val: 1
	},
	{
		name: '地推',
		val: 2
	},
	{
		name: '转介绍',
		val: 3
	},
	{
		name: '微官网',
		val: 4
	},
	{
		name: '招生活动',
		val: 5
	},
	{
		name: '其他',
		val: 6
	}
]
/* 请假次数限制*/
const leaveCount = [{
		name: '不限',
		val: 0
	}, {
		name: '1次',
		val: 1
	}, {
		name: '2次',
		val: 2
	}, {
		name: '3次',
		val: 3
	}, {
		name: '4次',
		val: 4
	}, {
		name: '5次',
		val: 5
	},
	{
		name: '6次',
		val: 6
	}, {
		name: '7次',
		val: 7
	}, {
		name: '8次',
		val: 8
	}, {
		name: '9次',
		val: 9
	}, {
		name: '10次',
		val: 10
	}, {
		name: '11次',
		val: 11
	},
	{
		name: '12次',
		val: 12
	}, {
		name: '13次',
		val: 13
	}, {
		name: '14次',
		val: 14
	}, {
		name: '15次',
		val: 15
	}, {
		name: '16次',
		val: 16
	}, {
		name: '17次',
		val: 17
	},
	{
		name: '18次',
		val: 18
	}, {
		name: '19次',
		val: 19
	}, {
		name: '20次',
		val: 20
	}, {
		name: '21次',
		val: 21
	}, {
		name: '22次',
		val: 22
	}, {
		name: '23次',
		val: 23
	},
	{
		name: '24次',
		val: 24
	}, {
		name: '25次',
		val: 25
	}, {
		name: '26次',
		val: 26
	}, {
		name: '27次',
		val: 27
	}, {
		name: '28次',
		val: 28
	}, {
		name: '29次',
		val: 29
	},
	{
		name: '30次',
		val: 30
	}
]
/* 支付方式 */
const payType = [{
		name: '微信',
		val: 1
	},
	{
		name: '支付宝',
		val: 2
	},
	{
		name: '刷卡',
		val: 3
	},
	{
		name: '现金',
		val: 4
	},
	{
		name: '腾讯微信',
		val: 6
	},
	{
		name: '拉卡拉微信',
		val: 7
	},
	{
		name: '拉卡拉支付宝',
		val: 8
	},
	{
		name: '拉卡拉刷卡',
		val: 9
	},
	{
		name: '其他',
		val: 5
	}
]
const re_type = [{
		id: 1,
		name: '新购课包'
	},
	{
		id: 2,
		name: '续费课包'
	}
]
/* 上课模式 */
const classModel = [{
		name: '班级教学',
		val: 1
	},
	{
		name: '一对一',
		val: 2
	}
]
/* 启用状态 */
const status = [{
		name: '启用',
		val: 1
	},
	{
		name: '关闭',
		val: 2
	}
]
/* 科目 最大只支持三级 */
const subject = [{
	name: '文化',
	icon: 'iconfont icon-wenhua',
	children: [{
			name: '特色文化',
			children: [{
					name: '机器人',
					select: false
				},
				{
					name: '生化人',
					select: false
				},
				{
					name: '丧失人',
					select: false
				},
				{
					name: '圣贤人',
					select: false
				},
			]
		},
		{
			name: '文学历史',
			children: [{
					name: '秦始皇',
					val: 1,
					select: false
				},
				{
					name: '朱元璋',
					val: 2,
					select: false
				},
				{
					name: '朱自清',
					val: 3,
					select: false
				},
				{
					name: '李太白',
					val: 4,
					select: false
				},
				{
					name: '杜飞甫',
					val: 5,
					select: false
				},
			]
		}
	]
}]
/* 班级是否进行中 */
const classisGoing = [{
		name: '已结束',
		val: 1
	},
	{
		name: '进行中',
		val: 2
	}
]
/* 班级是否开课或者关闭 */
const classIsOpen = [{
		name: '已开课',
		val: 1
	},
	{
		name: '已关闭',
		val: 2
	}
]
/* 考勤状态 */
const attendanceStatus = [{
		name: '出勤',
		val: 1
	},
	{
		name: '请假',
		val: 2
	},
	{
		name: '旷课',
		val: 3
	},
	{
		name: '未处理',
		val: 4
	},
]
/* 作品上传状态 */
const workUploadStatus = [{
		name: '已上传',
		val: 1
	},
	{
		name: '未上传',
		val: 2
	}
]
const week = [{
		name: '星期一',
		val: 1,
		check: false
	},
	{
		name: '星期二',
		val: 2,
		check: false
	},
	{
		name: '星期三',
		val: 3,
		check: false
	},
	{
		name: '星期四',
		val: 4,
		check: false
	},
	{
		name: '星期五',
		val: 5,
		check: false
	},
	{
		name: '星期六',
		val: 6,
		check: false
	},
	{
		name: '星期天',
		val: 7,
		check: false
	},
]


// 请假顺延次数
const select_count_leave = [{
		label: '不限',
		value: 'normal'
	},
	{
		label: '0',
		value: '0'
	},
	{
		label: '1',
		value: '1'
	},
	{
		label: '2',
		value: '2'
	},
	{
		label: '3',
		value: '3'
	},
	{
		label: '4',
		value: '4'
	},
	{
		label: '5',
		value: '5'
	},
	{
		label: '6',
		value: '6'
	},
	{
		label: '7',
		value: '7'
	},
	{
		label: '8',
		value: '8'
	},
	{
		label: '9',
		value: '9'
	},
	{
		label: '10',
		value: '10'
	},
	{
		label: '11',
		value: '11'
	},
	{
		label: '12',
		value: '12'
	},
	{
		label: '13',
		value: '13'
	},
	{
		label: '14',
		value: '14'
	},
	{
		label: '15',
		value: '15'
	},
	{
		label: '16',
		value: '16'
	},
	{
		label: '17',
		value: '17'
	},
	{
		label: '18',
		value: '18'
	}
]

/* 教师星级 */
const level = [{
		label: '一星',
		level: 1
	},
	{
		label: '二星',
		level: 2
	},
	{
		label: '三星',
		level: 3
	},
	{
		label: '四星',
		level: 4
	}, {
		label: '五星',
		level: 5
	}, {
		label: '六星',
		level: 6
	}, {
		label: '七星',
		level: 7
	}, {
		label: '八星',
		level: 8
	}, {
		label: '九星',
		level: 9
	}, {
		label: '十星',
		level: 10
	}
]
/* 班级类型 */
const classType = [{
		label: '常规班级',
		value: 1
	},
	/* {
		label: '专题班级',
		value: 2
	}, */
	{
		label: '体验班级',
		value: 3
	}
]

// 课程分班--选择学员表格配置
const table_student_classes = {
	'姓名': {
		prop: 'name',
	},
	'性别': {
		prop: 'sex'
	},
	'年龄': {
		prop: 'age'
	},
	'联系电话': {
		prop: 'tel'
	},
	'学员状态': {
		prop: 'class_status'
	},
	'登记时间': {
		prop: 'created_at'
	}
}
// 课程分班--报名课程表格配置
const table_curriculum_classes = {
	'课程名称': {
		prop: 'name'
	},
	'适龄范围': {
		prop: 'age'
	},
	'上课模式': {
		prop: 'mode'
	},
	'收费标准': {
		prop: 'status',
		slot: true,
		value: '标准',
		key: 'status'
	}
}
// 课程分班--分配班级表格配置
const table_classes_classes = {
	'班级名称': {
		prop: 'className',
	},
	'上课时间': {
		prop: 'class_time',
		slot: true,
		value: '查看',
		key: 'class_time'
	},
	'适龄范围': {
		prop: 'age'
	},
	'班级人数': {
		prop: 'studentCount'
	},
	'主课老师': {
		prop: 'teacherName'
	}
}
// 课程分班--费用结算表格配置
const table_cost_classes = {
	'费用名称': {
		prop: 'name_cost'
	},
	'课时总时': {
		prop: 'time_cost'
	},
	'剩余课时': {
		prop: 'time_surplus'
	},
	'有效期': {
		prop: 'term'
	},
	'关联班级': {
		prop: 'relation_classes'
	}
}
// 学员状态选择器
const state_select = [{
		label: '未分班',
		value: 0
	},
	{
		label: '已分班',
		value: 1
	}
]
// 新增收费类型
const type_cost = [{
		label: '材料',
		value: 'materialScience'
	},
	{
		label: '活动',
		value: 'activity'
	},
	{
		label: '考级',
		value: 'levelExamination'
	},
	{
		label: '比赛',
		value: 'match'
	},
	{
		label: '代买',
		value: 'buy'
	},
	{
		label: '周边',
		value: 'periphery'
	},
	{
		label: '其他',
		value: 'other'
	}
]
// 所属班级分类选择器
const state_select_class = [{
		label: '不限',
		value: 0
	},
	{
		label: '周一DUPLO19:00-21:00',
		value: 1
	},
	{
		label: 'EV3',
		value: 2
	},
	{
		label: 'WEDO课程',
		value: 3
	},
	{
		label: 'EDUPLO课程',
		value: 4
	},
]
// 学员费用---综合筛选
const screen_student = {
	'state_cost': [{
			label: '不限',
			value: '0'
		},
		{
			label: '全额缴费',
			value: '1'
		},
		{
			label: '缴费不足',
			value: '2'
		}
	],
	'time': [{
			label: '不限',
			value: '0'
		},
		{
			label: '正常',
			value: '1'
		},
		{
			label: '即将到期',
			value: '2'
		},
		{
			label: '已经结束',
			value: '3'
		},
		{
			label: '已经欠费',
			value: '4'
		}
	],
	'state_effective': [{
			label: '不限',
			value: '0'
		},
		{
			label: '有效',
			value: '1'
		},
		{
			label: '失效',
			value: '2'
		}
	],
	'state_student': [{
			label: '不限',
			value: '0'
		},
		{
			label: '在读',
			value: '1'
		},
		{
			label: '流失',
			value: '2'
		}
	],
	'houers_surplus': {},
	'date_end': {}
}
// 学员费用 -- 费用一览表格配置
const table_cost_student = {
	'姓名': {
		prop: 'student_name',
		width: 100
	},
	'联系电话': {
		prop: 'tel'
	},
	// '学员状态': {
	// 	prop: 'status'
	// },
	'费用名称': {
		prop: 'name'
	},
	// '关联班级': {
	// 	prop: 'class'
	// },
	'费用有效期': {
		prop: 'expiration'
	},
	'剩余课时': {
		prop: 'lave_lesson'
	},
	'剩余学费': {
		prop: 'lave_fee'
	},
	'缴费状态': {
		prop: 'pay_status'
	},
	'缴费时间': {
		prop: 'created_at',
		minWidth: 100
	},
	'效用': {
		prop: 'utility',
		width: 80
	},
	'有效/失效': {
		prop: 'is_effective'
	}
}
// 学员费用 ---- 操作日志表格配置
const table_log_student = {
	'姓名': {
		prop: 'student_name',
	},
	'费用名称': {
		prop: 'fee_name'
	},
	'操作类型': {
		prop: 'type'
	},
	'实缴金额变更': {
		prop: 'paid_amound_change'
	},
	'总课时变更': {
		prop: 'lesson_change'
	},
	'有效期变更至': {
		prop: 'expiration_change'
	},
	'操作人': {
		prop: 'teacher_name'
	},
	'操作时间': {
		prop: 'created_at'
	},
	// '缴费操作日志': {
	// 	prop: 'log_operation'
	// }
}
// 操作类型选择器
const state_select_operation = [{
		label: '不限',
		value: ''
	},
	{
		label: '新增续费',
		value: 4
	},
	{
		label: '续费',
		value: 1
	},
	{
		label: '补费',
		value: 3
	},
	{
		label: '退费',
		value: 5
	},
	{
		label: '修改',
		value: 2
	}
]
// 图片文件上传地址
const url_img_file = ''
// 学员出勤状态---选择器
const state_attendance_student = [{
		label: '出勤',
		value: 1,
		color: '#47bd12'
	},
	{
		label: '请假',
		value: 2,
		color: '#ffc500'
	},
	{
		label: '旷课',
		value: 3,
		color: '#ff551c'
	},
	{
		label: '未处理',
		value: 4,
		color: ''
	}
]
// 老师签到表格配置
const table_signin_teather = {
	'姓名': {
		prop: 'name'
	},
	'联系电话': {
		prop: 'tel'
	},
	'任课班级': {
		prop: 'class'
	}
}

// 临时调入步骤1表格配置
const table_in1 = {
	'姓名': {
		prop: 'name'
	},
	'年龄': {
		prop: 'age'
	},
	'在读班级': {
		prop: 'class'
	}
}

// 临时调出步骤1和临时调入步骤2表格配置
const table_callout1 = {
	'班级名称': {
		prop: 'name'
	},
	'上课时间': {
		prop: 'time'
	},
	'适龄范围': {
		prop: 'age'
	},
	'主课老师': {
		prop: 'teather'
	}
}

// 临时调入步骤3表格配置
const table_in3 = {
	'课时序号': {
		prop: 'name'
	},
	'课时': {
		prop: 'time'
	},
	'考勤状态': {
		prop: 'state'
	}
}
// 临时调出步骤2表格配置
const table_callout2 = {
	'课时序号': {
		prop: 'name'
	},
	'课时': {
		prop: 'time'
	}
}
// 临时调出班级---所属课程表格配置
const table_lessons = {
	'课程名称': {
		prop: 'name'
	},
	'适龄范围': {
		prop: 'age'
	},
	'上课模式': {
		prop: 'mode'
	},
	'考勤规则': {
		prop: 'rule'
	},
	'课时总时': {
		prop: 'time'
	},
	'上课频率': {
		prop: 'hz'
	}
}

// 临时加课/补课学员表格配置
const table_addlessons = {
	'姓名': {
		prop: 'name'
	},
	'年龄': {
		prop: 'age'
	},
	'在读班级': {
		prop: 'class'
	},
	'关联费用': {
		prop: 'cost'
	}
}



// 班级信息表格配置
const table_class = {
	'班级名称': {
		prop: 'className',
		type: true,
		minWidth: 100
	},
	'上课场地': {
		prop: 'classroomName',
		type: true,
		minWidth: 150
	},
	'适龄范围': {
		prop: 'range_age',
		type: true,
		center: 'center',
		width: 100
	},
	'班级人数': {
		prop: 'studentCount',
		type: true,
		center: 'center',
		width: 100
	},
	'主课老师': {
		prop: 'teacherName',
		type: true,
		width: 120
	},
	'课时总时': {
		prop: 'lesson',
		type: true,
		center: 'center',
		width: 100
	},
	'上课时间': {
		prop: 'class_time',
		type: true,
		slot: true,
		value: '查看',
		center: 'center',
		width: 100
	},
	'班级状态': {
		prop: 'status',
		type: true,
		center: 'center',
		width: 100
	},
	'班级容量': {
		prop: 'classCapacity',
		type: true,
		center: 'center',
		width: 100
	},
	'场地容量': {
		prop: 'capacity',
		type: true,
		center: 'center',
		width: 100
	}
}


// 学员信息表格配置
const table_student = {
	'姓名': {
		prop: 'name',
		type: true
	},
	'年龄': {
		prop: 'age',
		type: true
	},
	'性别': {
		prop: 'sex',
		type: true
	},
	'在读班级': {
		prop: 'class_status',
		type: true
	},
	'关联费用': {
		prop: 'cost',
		type: true
	},
	'联系方式': {
		prop: 'tel',
		type: true
	},
	'source': {
		prop: 'source',
		type: true
	},
	'created_at': {
		prop: 'created_at',
		type: true
	},
	'is_activation': {
		prop: 'is_activation',
		type: true
	},
	'is_leave': {
		prop: 'is_leave',
		type: true
	},
	'recuritTeacher': {
		prop: 'recuritTeacher',
		type: true
	}
}

// 课程信息表格配置
const table_course = {
	'课程名称': {
		prop: 'name',
		type: true,
	},
	'适龄范围': {
		prop: 'range_age',
		type: true,
	},
	'上课模式': {
		prop: 'mode',
		type: true,
	},
	'考勤规则': {
		prop: 'rule',
		type: true,
	},
	'课时总时': {
		prop: 'lesson',
		type: true,
	},
	'上课频率': {
		prop: 'frequency',
		type: true
	}
}
// 老师信息表格配置
const table_teather = {
	'姓名': {
		prop: 'name',
		type: true,
	},
	'联系电话': {
		prop: 'tel',
		type: true,
	},
	'任课班级': {
		prop: 'class',
		type: true
	}
}



// 科目信息
// const subject = {
// 	'文化': [
// 		{ value: '特色文化' },
// 		{ value: '机器人' }
// 	],
// 	'艺术': [
// 		{ value: '美术' }
// 	]
// }
export default {
	roles,
	sex,
	certType,
	source,
	leaveCount,
	payType,
	classModel,
	status,
	subject,
	classisGoing,
	classIsOpen,
	attendanceStatus,
	workUploadStatus,
	week,
	table_student_classes,
	table_curriculum_classes,
	table_classes_classes,
	table_cost_classes,
	state_select,
	type_cost,
	state_select_class,
	screen_student,
	table_cost_student,
	table_log_student,
	state_select_operation,
	url_img_file,
	state_attendance_student,
	table_signin_teather,
	table_in1,
	table_in3,
	table_callout1,
	table_callout2,
	table_lessons,
	table_addlessons,
	table_class,
	table_course,
	select_count_leave,
	table_student,
	table_teather,
	level,
	classType,
	re_type
	/* 	subject

	 */
}
