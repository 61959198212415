<template>
	<!-- 学员流失列表 -->
	<div>
		<data-analysis-search :defaultData="defaultData" @getScreen='getScreen' lastMonth='上月' thisMonth='当月' yesterday="昨天" today="今天" sevenDay="7天"></data-analysis-search>
		<el-table :data='tableData' stripe>
			<el-table-column prop="school_name" label='校区' width="150"></el-table-column>
			<el-table-column prop="name" label='学员名称' min-width="100px"></el-table-column>
			<el-table-column prop="sex" label='学员性别'>
				<template v-slot="scope">
					{{scope.row.sex == 1 ? '男' : '女'}}
				</template>
			</el-table-column>
			<el-table-column prop="tel" label='监护人电话'></el-table-column>
			<el-table-column prop="source" label='学员来源'>
				<template v-slot="scope">
					{{source[scope.row.source - 1].name}}
				</template>
			</el-table-column>
			<el-table-column prop="is_leaveReason" label='流失原因'>
				<template v-slot="scope">
					{{(scope.row.is_leaveReason == '' || scope.row.is_leaveReason == null) ? '无流失原因' : scope.row.is_leaveReason}}
				</template>
			</el-table-column>
			<el-table-column prop="leave_label" label='流失标签'>
				<template v-slot="scope">
					<div class="card">{{scope.row.leave_label.name}}</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination :current-page="screen.page" :total="total" background layout="total, prev, pager, next" class="mg-t-10" @current-change='pageChange'></el-pagination>
	</div>
</template>

<script>
	import dataAnalysisSearch from './data-analysis-search.vue'
	import {
		extend
	} from '../../../assets/js/util.js'
	export default {
		name: 'StudentChurmList',
		data () {
			return {
				total: 0,
				tableData: [],
				screen: {
					page: 1,
					date_start: '',
					date_end: '',
					school_id: '',
					number: 10
				},
				source: this.$param.source,
				defaultData: {}
			}
		},
		methods: {
			pageChange (page) {
				this.screen.page = page;
				this.getStudentChurn()
			},
			keyChange () {
				this.screen.page = 1;
				this.getStudentChurn()
			},
			getScreen (screen) {
				this.screen.page = 1;
				extend(this.screen, screen)
				this.getStudentChurn()
			},
			async getStudentChurn () {
				var { data: res } = await this.$http.get('/statistics/student/churn', {
					params: this.screen
				})
				if (res.code == 0) {
					this.tableData = res.data.data;
					this.screen.page = res.data.page;
					this.total = res.data.total;
				} else {this.$message.error(res.message)}
			}
		},
		components: {
			dataAnalysisSearch
		},
		created() {
			var { defaultScreen } = this.$attrs;
			if (defaultScreen) {
				this.defaultData = {...defaultScreen};
				extend(this.screen, defaultScreen);
				this.screen.date_start = defaultScreen.date[0];
				this.screen.date_end = defaultScreen.date[1]
				this.getStudentChurn()
			}						
		}
	}
</script>

<style scoped>
	.card {
		display: inline-block;
		background-color: #409eff;		
		border-radius: 3px;
		padding: 2px 10px;		
		box-sizing: border-box;
		color: #fff;
	}
	.scrollX {
		width: 100%;
		overflow: auto;
		padding: 10px 10px;
	}
</style>
