<!-- 学员考勤状态和学员作品上传状态表格-->
<template>
	<!-- 考勤记录 -->
	<div class="">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>教务管理</el-breadcrumb-item>
			<el-breadcrumb-item>学员状况</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- header -->
		<div class="header mg-b-10">
			<!-- header-left -->
			<div class="header-left flex align-center w-per-100">
				<div class="mg-lr-10">
					<el-select clearable placeholder="学员考勤与作品状态" size="small" v-model="status" @change="a_statusChange">
						<el-option v-for="(item,index) in options" :label="item.name" :value="item.val"></el-option>
					</el-select>
				</div>
				<div class="align-center">
					<span class="mg-r-10">日期</span>
					<el-date-picker size="small" v-model="date" type="date" placeholder="选择日期" @change="changeDate" format="yyyy-MM-dd"
					 value-format="yyyy-MM-dd"></el-date-picker>
				</div>
				<div class="flex align-center mg-lr-10">
					<el-input type="text" style="width:450px;" size="small" v-model="key" @blur="key_change" placeholder="请输入班级或老师">
						<el-button slot="append" icon="el-icon-search"></el-button>
					</el-input>
				</div>
			</div>
		</div>
		<el-card>
			<!-- table -->
			<el-table :data="analysisList" class="fsMedia" style="width: 100%">
				<el-table-column label="姓名" width="150">
					<template slot-scope="scope">
						{{scope.row.studentName}}
					</template>
				</el-table-column>
				<el-table-column prop="className" label="班级" width="150">
				</el-table-column>
				<el-table-column label="课时" min-width="100">
					<template slot-scope="scope">
						{{scope.row.date}} &nbsp; {{scope.row.name}} &nbsp;{{scope.row.time_start}} &nbsp;{{scope.row.time_end}}
					</template>
				</el-table-column>

				<el-table-column prop="mainTeacher" label="主课老师">
				</el-table-column>
				<el-table-column prop="alternativeTeacher" label="配课老师">
				</el-table-column>
				<el-table-column label="考勤状态">
					<template slot-scope="scope">
						{{scope.row.a_status == 4 ? '未处理' : scope.row.a_status == 3 ? '旷课' : scope.row.a_status == 2 ? '请假' : scope.row.a_status == 1 ? '出勤' : '未知状态' }}
					</template>
				</el-table-column>
				<el-table-column label="作品上传状态">
					<template slot-scope="scope">
						{{scope.row.w_status == 1 ? '已上传' : '未上传'}}
					</template>
				</el-table-column>
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click='clickShowAttendanceRecord(scope.row)'>查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="mg-t-10">
				<el-pagination background layout="prev, pager, next" @current-change="hanldeCurrentChange" :total="total"
				 :current-page='page'>
				</el-pagination>
			</div>

			<!-- /table -->
			<!-- body -->
		</el-card>
	</div>
</template>

<script>
	import {
		getDate
	} from '../../../assets/js/myMethods.js'
	export default {
		name: 'Calendars',
		data() {
			return {
				options: [{
						name: '不限',
						val: ''
					},
					{
						name: '未处理',
						val: 4
					},
					{
						name: '未上传',
						val: 2
					}
				],
				date: '',
				analysisList: [],
				status: '',
				a_status: '',
				w_status: '',
				age_start: '',
				age_end: '',
				key: '',
				uploadWork: {
					works: [],
					comment: '',
					time_start: '',
					time_end: '',
					date: '',
					sid: ''
				},
				page: 1,
				total: 10

			}
		},
		created() {
			var {
				status
			} = this.$route.query;
			status && (this.status = this.w_status = status - 0);
			this.getAnalysisList();
		},
		methods: {
			changeDate() {
				if (!this.date) this.date = '';
				this.getAnalysisList()
			},
			clickShowAttendanceRecord(item) {
				this.$router.push({
					path: '/education/student-state',
					query: {
						cid: item.cid,
						date: item.date,
						time_start: item.time_start,
						time_end: item.time_end,
						name: item.className,
						course_content: item.course_content,
					}
				})
			},
			/* 分页 */
			hanldeCurrentChange(page) {
				this.page = page;
				this.getAnalysisList();
			},
			/* 获取考勤列表 */
			async getAnalysisList() {
				let param = '/education/attendance/getAttendanceByNotWorkAndAttendance?a_status=' + this.a_status +
					'&w_status=' + this.w_status +
					'&key=' + this.key +
					'&page=' + this.page +
					'&date=' + (this.date ? this.date : '');
				const {
					data: res
				} = await this.$http.get(param);
				if (res.code == 200) {
					this.analysisList = res.data.data
					this.total = res.data.total;
				}
			},
			/* 考勤状态改变 */
			a_statusChange(row) {
				if (!this.date) this.date = ''
				if (row == 2) {
					this.w_status = row;
					this.a_status = ''
				} else if (row == 4) {
					this.a_status = row;
					this.w_status = ''
				} else {
					this.w_status = '';
					this.a_status = ''
				}
				this.getAnalysisList();
			},
			key_change() {
				if (!this.date) this.date = ''
				this.getAnalysisList();
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .el-badge__content {
		transform: translate(0, 5px);
	}

	.banner-data {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
	}

	.analysis-container-left,
	.analysis-container-right {
		>div {
			font-size: 10px;
			color: #7d7d7d;
		}
	}
</style>
