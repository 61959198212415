/*
2021-03-04 由FZY创建
@describe 学员备注记录
*/
<template>
	<div>
		<el-button size="medium" type="primary" @click='show(1)'>添加备注</el-button>
		<div class="fs-18 fontWeightB text-align-c mg-t-10" style="color: #888;" v-if="remarkLists.length == 0">暂无备注记录</div>
		<el-timeline class='mg-t-10 timetime' v-else>
			<el-timeline-item :timestamp="item.created_at" placement="top" v-for='(item, index) in remarkLists' :key='item.nodeId'>
				<el-card class="cursor-p">
					<div @click='show(2, item)' class="boxTimeLine flex align-center justify-between" @mouseenter="_mouseenter(item)"
					 @mouseleave="showMask = false">
						<div style="width: 100%;">
							<el-table border size='mini' :data='[item]'>
								<el-table-column label="孩子情况" align='center' prop='student_note'></el-table-column>
								<el-table-column label="家长情况" align='center' prop='parents_note'></el-table-column>
								<el-table-column label="备注情况" align='center' prop='note'></el-table-column>
							</el-table>
							<p>{{item.teacherName}} 添加于 {{item.created_at}}</p>
						</div>						
						<div class='mask flex align-center' v-show="showMask && formData.noteId == item.noteId">
							点击修改备注信息
							<el-button size="small" type="danger" class="mg-l-10" style="position: absolute;z-index: 1;right: 0" @click.stop='delRemark(item)'>删除</el-button>
						</div>
					</div>
				</el-card>
			</el-timeline-item>
		</el-timeline>
		<el-dialog :visible.sync="remarkDialogShow" center :title="addORmodify == 'addRemark' ? '添加备注' : '修改备注信息'" :modal-append-to-body='false' append-to-body @close="$refs.refForm.resetFields()">
			<el-form :model="formData" label-position="right" label-width="80px" :rules="rules" ref='refForm' style="width: 100%;">
				<el-form-item prop="student_note" label="孩子情况">
					<el-input placeholder="请输入孩子情况" type="textarea" :rows="5" v-model="formData.student_note"></el-input>
				</el-form-item>
				<el-form-item prop="parents_note" label="家长情况">
					<el-input placeholder="请输入备注信息" type="textarea" :rows="5" v-model="formData.parents_note"></el-input>
				</el-form-item>
				<el-form-item prop="note" label="备注信息">
					<el-input placeholder="请输入备注信息" type="textarea" :rows="5" v-model="formData.note"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="remarkDialogShow = false">取 消</el-button>
				<el-button type="primary" @click='confirm'>确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getRemarkLists,
		addRemark,
		modifyRemark,
		delRemark
	} from '../../../assets/js/API/remark'
	import {
		extend
	} from '../../../assets/js/util'
	import vali from '../../../assets/js/validate.js'
	export default {
		name: 'StudentRemarkList',
		props: {
			studentId: {
				default: ''
			}
		},
		data() {
			return {
				showMask: false,
				remarkLists: [],
				isCanClick: true,
				screen: {
					sid: ''
				},
				formData: {
					noteId: '',
					note: '',
					student_note: '',
					parents_note: ''
				},
				rules: {
					note: vali.validatorCommon.mandatory('请输入备注信息')
				},
				remarkDialogShow: false,
				addORmodify: 'addRemark',
			}
		},
		created() {
			this.screen.sid = this.studentId || this.$route.query.id;
			this.getRemarkLists();
		},
		methods: {
			async delRemark(info) {
				if (!this.isCanClick) return this.$message.info('正在处理中，请稍等...')
				var bool = await this.$confirm('您确定要删除这条备注信息吗?', '提示').catch();
				if (!bool) return;
				delRemark.call(this, info.noteId)
					.then(res => {
						this.setIsCanClick()
						if (res) this.getRemarkLists()
					})
					.catch(err => this.setIsCanClick())
			},
			_mouseenter(info) {
				this.showMask = true;
				this.formData.noteId = info.noteId
			},
			show(key, info) {
				var map = ['', 'addRemark', 'modifyRemark'];
				this.addORmodify = map[key];
				if (key == 2) {
					this.$nextTick(() => {
						extend(this.formData, info)
					})
				};
				this.remarkDialogShow = true
			},
			setIsCanClick() {
				setTimeout(() => this.isCanClick = true, 500)
			},
			reload() {
				this.getRemarkLists();
				this.remarkDialogShow = false;
				this.note = ''
			},
			addRemark() {
				var params = { ...this.formData
				};
				params.sid = this.screen.sid;
				delete params.noteId;
				addRemark.call(this, params)
					.then(res => {
						this.setIsCanClick();
						if (res) this.reload()
					})
					.catch(err => this.setIsCanClick())
			},
			modifyRemark() {
				if (!this.formData.noteId) {
					this.setIsCanClick();
					return this.$message.error('未知错误')
				}
				var params = { ...this.formData
				};
				modifyRemark.call(this, params)
					.then(res => {
						this.setIsCanClick();
						if (res) this.reload()
					})
					.catch(err => this.setIsCanClick())
			},
			async confirm() {
				if (!this.isCanClick) return this.$message.info('正在处理中，请稍等...');
				var flag = await this.$refs.refForm.validate().catch(err => {});
				if (!flag) return
				this.isCanClick = false;
				this[this.addORmodify]()
			},
			getRemarkLists() {
				getRemarkLists.call(this, this.screen)
					.then(res => {
						if (res) this.remarkLists = res
					})
			}
		},
	}
</script>

<style scoped>
	.timetime .el-card>>>.el-card__body {
		padding: 0 !important;
	}

	.boxTimeLine {
		padding: 20px;
		position: relative;
	}
	.list {
		
	}

	.mask {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #0086B305;
		font-size: 20px;
		font-weight: bold;
		color: #999;
		justify-content: center;
		opacity: 0.8;
		z-index: 100;
	}

	@media screen and (max-width: 1499px) {
		.mask {
			font-size: 14px;
		}
	}
</style>
