const mustValidate = {
	// 必须中文
	// studentName: [{
	// 		required: true,
	// 		message: '必填',
	// 		trigger: 'blur'
	// 	},
	// 	{
	// 		min: 2,
	// 		max: 7,
	// 		message: '长度在 2 到 7 个字符'
	// 	},
	// 	{
	// 		pattern: /^[\u4E00-\u9FA5]+$/,
	// 		message: '名字只能为中文'
	// 	},
	// ],
	// 至少一个字符,不限制形式
	studentName: [{
			required: true,
			message: '必填',
			trigger: 'blur'
		},
		{
			min: 1,
			message: '至少1个字符'
		}
	],
	studentAge: [{
			required: true,
			message: '请输入学员年龄',
			trigger: 'blur'
		},
		{
			type: 'number',
			message: '必须为数字类型'
		},
	],
	studentSex: [{
		required: true,
		message: '性别必须选择'
	}],
	parentTel: [{
			required: true,
			message: '请输入手机号码',
			trigger: 'blur'
		},
		{
			validator: function(rule, value, callback) {
				if (/^1[3456789]\d{9}$/.test(value) == false) {
					callback(new Error("请输入正确的手机号"));
				} else {
					callback();
				}
			},
			trigger: 'blur'
		}
	],
	parentRole: [{
		required: true,
		message: '请选择监护人的角色',
		trigger: 'blur'
	}],
	lessonTime: [
		{
			required: true,
			message: '请选择上课日期',
			trigger: 'blur'
		}		
	]
}
const optionValidate = {
	// 不限制形式
	// studentNickName: [{
	// 		min: 2,
	// 		max: 7,
	// 		message: '长度在 2 到 7 个字符'
	// 	},
	// 	{
	// 		pattern: /^[\u4E00-\u9FA5]+$/,
	// 		message: '名字只能为中文'
	// 	},
	// ],
	studentNickName: [
	],
	parentName: [{
			min: 2,
			max: 7,
			message: '长度在 2 到 7 个字符'
		},
		{
			pattern: /^[\u4E00-\u9FA5]+$/,
			message: '名字只能为中文'
		},
	]
}
export default {
	mustValidate,
	optionValidate
}
