<template>
	<!-- 退费 -->
	<div>
		<div class="container" id="printTest">
			<el-card>
				<div class="header">退费申请</div>
				<div class="header-body flex align-center justify-between">
					<div class="flex">
						<div>深圳睿云产业科技有限公司</div>
						<div class="mg-l-20">申请日期： {{result.time}}</div>
					</div>
					<financial-approval v-show="!flagHide" v-if="approvalInfo.applyStatus === 2 && approvalInfo.flag_approve && ((approvalInfo.approveType === 'trial_ids' && approvalInfo.examineStatus === 1) || (approvalInfo.approveType === 'final_trial_ids' && approvalInfo.isAllPassed && approvalInfo.examineStatus === 1))"
					 :financial-id="id" financial-type="6" @success="getReimbursement"></financial-approval>
				</div>
				<div class="mg-t-10">
					<table>
						<tr>
							<td> 审批编码</td>
							<td> {{result.code}}</td>
						</tr>
						<tr>
							<td> 申请人 </td>
							<td> {{result.application ? result.application.name : ''}} </td>
						</tr>
						<tr>
							<td> 校区选择 </td>
							<td> {{result.schoole.name}} </td>
						</tr>
						<tr>
							<td> 申请类型 </td>
							<td>退费申请 </td>
						</tr>
						<tr>
							<td>退费申请明细</td>
							<td>
								<div>
									<div>学员姓名: {{result.student_name}}</div>
									<div>耗课课时: {{result.dis_lesson}}</div>
									<div>耗课金额: {{result.dis_amount}} 元</div>
									<div>未耗金额: {{result.cost_lesson_amount}}元 </div>
									<div>应退金额: {{result.amount}} </div>
									<div>收款账户: {{result.name}} </div>
									<div>银行卡号: {{result.card}}</div>
									<div>开户地: {{result.account_opening}}</div>
									<div>开户支行: {{result.branch_name}}</div>
									<div>联系人: {{result.contract_name}}</div>
									<div>联系方式: {{result.tel}}</div>
									<div>退费原因: {{result.reason}}</div>
									<div>其他费用: <span v-for="(item,index) in result['other']" :key="index">  {{item.name}}：{{item.amount}}元  </span> </div>
								</div>
							</td>
						</tr>
						<tr>
							<td> 附件 </td>
							<td>
								暂无
							</td>
						</tr>
						<tr>
							<td> 审批流程 </td>
							<td>
								<div>抄送人:</div>
								<div v-for="(re,ins) in result.approves.cc" style="display: inline-block;margin-bottom: 10px;">
									<span style="margin-right: 6px;">{{re.name}} <span v-if="ins + 1 != result.approves.cc.length">,</span></span>
								</div>

								<div style="margin-top: 10px;">初审人:</div>
								<div v-if="result.approves.trial.length == 0">暂无</div>
								<div v-for="(re,ins) in result.approves.trial" style="display: inline-block;margin-bottom: 2px;" v-else>
									<span style="margin-right: 6px;">{{re.name}} </span>
									<span style="margin-right: 6px;">备注: {{re.remark}}</span>
									<!-- <span style="margin-right: 6px;">审批时间: {{re.time}}</span> -->
								</div>

								<div style="margin-top: 10px;">最终审人:</div>
								<div v-for="(re,ins) in result.approves.final_trial_ids" style="display: block;margin-bottom: 2px;">
									<span style="margin-right: 6px;">{{re.name}} </span>
									<span style="margin-right: 6px;">备注: {{re.remark}}</span>
									<!-- <span style="margin-right: 6px;">审批时间: {{re.time}}</span> -->
								</div>
							</td>
						</tr>
					</table>
				</div>
				<div class="footer flex align-center mg-t-10">
					<div>打印时间: {{time}}</div>
					<div class="mg-l-20">打印人: {{name}}</div>
				</div>

			</el-card>
		</div>
        	
		<div style="text-align: center;" class="mg-t-10 button">
			<el-button v-print="'#printTest'" type="primary">打印</el-button>
			<div v-if="approvalInfo.applyStatus === 2 && approvalInfo.flag_approve && ((approvalInfo.approveType === 'trial_ids' && approvalInfo.examineStatus === 1) || (approvalInfo.approveType === 'final_trial_ids' && approvalInfo.isAllPassed && approvalInfo.examineStatus === 1))" class="hide">
				隐藏审批按钮
				<el-switch
				  v-model="flagHide"
				  active-color="#13ce66"
				  inactive-color="#ff4949">
				</el-switch>
			</div>
		</div>	

	</div>
</template>

<script>
	import {
		judgingState
	} from '../../assets/js/CONFIG/financial.js'
	import financialApproval from './financialApproval.vue'
	import {
		extend
	} from '../../assets/js/util.js'
	export default {
		name: 'FinancialRefund',
		components: {
			financialApproval
		},
		data() {
			return {
				flagHide: false,
				approvalInfo: {
					flag_applicant: false,
					flag_approve: false,
					applyStatus: '',
					examineStatus: '', //当前审批人的审批状态
					isAllPassed: '', //true表示所有的初审批人全部通过 false表示没通过
					approveType: ''
				},
				result: [],
				id: 0,
				time: [],
				name: ''
			}
		},
		methods: {
			/* 获取发票申请 */
			async getReimbursement() {
				const {
					data: res
				} = await this.$http.get('/organization/financial/getRefundApplication?id=' + this.id);
				if (res.code == 200) {
					this.result = res.data;
					var appvoarl = {
						applicant: this.result.application,
						applyStatus: this.result.status,
						cc: this.result.approves.cc,
						type: 6,
						final_trial_ids: this.result.approves.final_trial_ids,
						trial_ids: this.result.approves.trial
					}
					extend(this.approvalInfo, judgingState(appvoarl))
				}
			}
		},
		created() {
			this.id = this.$route.params.id;
			this.getReimbursement();
			Date.prototype.Format = function(fmt) { // author: meizz
				var o = {
					"M+": this.getMonth() + 1, // 月份
					"d+": this.getDate(), // 日
					"h+": this.getHours(), // 小时
					"m+": this.getMinutes(), // 分
					"s+": this.getSeconds(), // 秒
					"q+": Math.floor((this.getMonth() + 3) / 3), // 季度
					"S": this.getMilliseconds() // 毫秒
				};
				if (/(y+)/.test(fmt))
					fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
				for (var k in o)
					if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" +
						o[k]).substr(("" + o[k]).length)));
				return fmt;
			}
			this.time = new Date().Format("yyyy-MM-dd hh:mm:ss");
			this.name = window.sessionStorage.getItem('name')
		}
	}
</script>

<style lang="less" scoped>
	.button{
		width: 80%;
		margin: 0 auto;
		position: relative;		
		
		.hide{
			display: flex;
			align-items: center;
			font-size: 12px;
			color: #333;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			
			.el-switch{
				margin-left: 6px;
			}
		}
	}
	
	div {
		font-size: 16px;
	}

	td {
		width: 15px;
	}

	.container {
		margin: 0 auto;
		width: 80%;
	}

	.header {
		font-size: 30px;
		text-align: center;
		font-family: '楷体';
	}

	.main-box>div {
		font-size: 10px;
	}

	table {
		border: 1px solid black;
		width: 100%;
		/*float:right;*/
		margin: 0 auto;
		border-spacing: 0;
		border-collapse: collapse;
	}

	td {
		border: 1px solid black;
		padding: 10px;
	}

	.shouldBorder {
		border-bottom: 1px solid #DCDFE6;
		padding-bottom: 10px;
	}
</style>
