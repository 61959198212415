<template>
	<div class="h-per-100">
		<el-card class="w-per-100 h-per-100 box">
			<!-- <el-button size="small" type="primary" @click="click_class">
      班级信息
      </el-button>-->
			<div>
				<p class="fs-20 text-align-c">课程表</p>
				<!-- 日期选择 -->
				<el-date-picker v-model="dateQueueStart" type="date" range-separator="至" placeholder="开始日期" format="yyyy-MM-dd"
				 value-format="yyyy-MM-dd" :picker-options="pickerOoptionsStart" @change="changeDate" @focus="dateQueueEnd =''" :clearable="false">
				</el-date-picker>
				-
				<el-date-picker v-model="dateQueueEnd" type="date" range-separator="至" placeholder="结束日期" format="yyyy-MM-dd"
				 value-format="yyyy-MM-dd" :picker-options="pickerOoptionsEnd" @change="changeDate">
				</el-date-picker>
				<div class="flex align-baseline mg-t-10">
					当前月份:<span class="mg-l-10">
						<span class="c-409eff fs-20">{{getDate}}&nbsp;</span>月份
					</span>

					<div class="flex-1 boxClassAndColor">
						<span class="fs-14 classAndColor" v-for="(item,index) in classNameAndColor" :key='index'>
							<div class="boxClassColor" :style="{'background-color': item.color}"></div>
							<span class="mg-l-5">{{item.text}}</span>
						</span>
					</div>

				</div>

			</div>
			<div class="kk">
				<v-row>
					<v-col>
						<v-sheet style='height:100%'>
							<v-calendar ref="calendar" :now="today" :value="value" :events="events" type="week" color="#409eff" :event-color="getEventColor"
							 event-text-color="#FFFFFF" @click:event="click_class" :short-intervals='true' first-time='6:00' interval-height='60'
							 interval-width='80' interval-count='18'></v-calendar>
						</v-sheet>
					</v-col>
				</v-row>
			</div>
		</el-card>
		<!-- 当前班级一些信息对话框 -->
		<el-dialog :title="dialog_class.title" :visible.sync="dialog_class.show" width="30%" @close="isShowSeeClass = false"
		 center>
			<div class="pd-10">
				<div class="box_age flex align-center">
					<span>适龄范围:</span>
					<span class="mg-l-10">{{form_class.age}}</span>
				</div>
				<div class="box_count flex align-center mg-t-10">
					<span>报班学员:</span>
					<span class="mg-l-10">{{form_class.student.length}}</span>人
				</div>
				<div class="box_teacher flex align-center mg-t-10">
					<span>主课老师:</span>
					<span class="mg-l-10">{{form_class.mainTeacher.name ? form_class.mainTeacher.name : '无'}}</span>
				</div>
				<!-- <div class="box_count1 flex align-center mg-t-10">
          <span>上课时间:</span>
          <span class="mg-l-10">{{form_class.classTime}}</span>
        </div> -->
				<!-- <div class="box_warning mg-t-10 text-align-c c-fda838">警告</div> -->
				<div class="text-align-c mg-t-17" v-if="isShowSeeClass">
					<el-button size="small" type="primary" @click="$router.push('/education/studentKaoqin/' + form_class.id)">查看考勤</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 当前班级一些信息对话框 -->
	</div>
</template>

<script>
	import {
		getAge_range,
		getTime_course,
		getDate,
		formatDate
	} from "../../../assets/js/myMethods";
	export default {
		name: 'CalendarKaoqin',
		props: {
			id: {
				type: String,
				default: "",
			},
			height: {
				type: String,
				default: "400",
			},
		},
		data() {
			return {
				dateQueueStart: '2020-10-01',
				dateQueueEnd: '',
				pickerOoptionsStart: {
					disabledDate: (data) => {
						var endDate = this.dateQueueEnd;
						// 如果没选结束日期,开始日期不限制
						if (endDate.length == 0) return
						// 根据结束日期来判断开始日期的选择范围
						var date = new Date(endDate),						
							day = date.getDay() + 1;
						var timeSun = new Date(formatDate.computed(date, day, 'reduce', 'day')).getTime();
						return ((data.getTime() <= timeSun) || (data.getTime() >= date.getTime()))
					}
				},
				pickerOoptionsEnd: {
					disabledDate: (data) => {
						var startDate = this.dateQueueStart;
						if (startDate.length == 0) return
						var date = new Date(startDate),						
							day = date.getDay();
						var timeSat = new Date(formatDate.computed(date, 6 - day, 'add', 'day')).getTime();
						return ((data.getTime() >= timeSat) || data.getTime() <= date.getTime())
					}
				},
				classNameAndColor: [],
				times: [],
				color: ['#2196F3', '#00BCD4', '#e8900a', '#353535', '#d05939', '#5fb858', '#7f16e1', '#b941b5', '#3abc8f',
					'#5458f1'
				],
				isShowSeeClass: false,
				form_class: {
					age: "",
					student: [],
					mainTeacher: {},
				},
				dialog_class: {
					show: false,
					title: "",
				},
				value: getDate(), //日历表显示的某一周内的日期
				today: getDate(), //今天的日期
				events: [
					// 日历中的事件 name/start/end必须要有，其它的可以有，到时候会在点击的时候显示出来
				],
				week: {
					'Sun': '星期日',
					'Mon': '星期一',
					'Tue': '星期二',
					'Wed': '星期三',
					'Thu': '星期四',
					'Fri': '星期五',
					'Sat': '星期六',
				},
				time: {
					'1 AM': ' 1:00 上午',
					'2 AM': '2:00 上午',
					'3 AM': '3:00 上午',
					'4 AM': '4:00 上午',
					'5 AM': '5:00 上午',
					'6 AM': '6:00 上午',
					'7 AM': '7:00 上午',
					'8 AM': '8:00 上午',
					'9 AM': '9:00 上午',
					'10 AM': '10:00 上午',
					'11 AM': '11:00 上午',
					'12 PM': '12:00 上午',
					'1 PM': '13:00 下午',
					'2 PM': '14:00 下午',
					'3 PM': '15:00 下午',
					'4 PM': '16:00 下午',
					'5 PM': '17:00 下午',
					'6 PM': '18:00 下午',
					'7 PM': '19:00 下午',
					'8 PM': '20:00 下午',
					'9 PM': '21:00 下午',
					'10 PM': '22:00 下午',
					'11 PM': '23:00 下午',
				}
			}

		},
		methods: {
			changeDate(date) {
				if (this.dateQueueStart.length == 0 || this.dateQueueEnd.length == 0) return
				this.value = this.dateQueueEnd;				
				this.upCalendar({
					date_start: this.dateQueueStart,
					date_end: this.dateQueueEnd
				})
				this.$nextTick(() => {
					this.EnglistToChinese()
				})
				
			},
			EnglistToChinese() {
				// 星期
				var week = document.querySelectorAll('.v-calendar-daily_head-weekday');				
				week.forEach(e => {					
					var name = e.textContent
					e.textContent = this.week[name] || name
				})
				// 时间
				var time = document.querySelectorAll('.v-calendar-daily__interval-text');				
				time.forEach(e => {
					var name = e.textContent
					e.textContent = this.time[name] || name
				})
			},
			eventColorClass(event) {
				var colorObj = {},
					idClass = [],
					className = [];
				event.forEach(e => {
					if (!idClass.includes(e.id)) {
						idClass.push(e.id);
						className.push(e.name.split('  ')[0])
					}
				})
				var arr = [];
				for (let i = 0; i < idClass.length; i++) {
					colorObj[idClass[i]] = this.color[i]
					var obj = {
						color: this.color[i],
						text: className[i]
					}
					arr.push(obj)
				}
				this.classNameAndColor = [...arr]
				return colorObj
			},
			getEventColor(event) {
				return event.color;
			},
			async click_class(e) {
				this.dialog_class.show = true;
				this.upClass(e.event.id);
			},

			// 获取班级信息 => 更新对话框内部前处理数据 => 更新对话框
			async getClass(id) {
				const {
					data: res
				} = await this.$http.get(
					"/education/classes/detail/" + id
				);
				if (res.code === 200) {
					return res.data;
				}
				return false
			},
			async upClass_before(id) {
				var data = await this.getClass(id);
				if (data) this.isShowSeeClass = true
				this.form_class.age = getAge_range(data);
				// this.form_class.classTime = getTime_course(data.class_time)
				this.dialog_class.title = data.name;
				Object.assign(this.form_class, data);
			},
			upClass(id) {
				this.upClass_before(id);
			},

			// 日历事件点击 => 弹出对话框显示当前教室课程（班级）的详细信息 => 可以跳转到当前班级的详细信息或者考勤状态

			// 获得班级上课时间 => 更新日历前处理数据 => 更新日历
			async getClassTime(params) {
				const {
					data: res
				} = await this.$http.get("/education/getClassTimeByEducation", {
					params
				});
				if (res.code === 200) {
					return res.data;
				}
			},
			upCalendar_before(data) {
				var colorEvent = this.eventColorClass(data)
				data.forEach((e) => {
					var name = e.name.split('  ');
					e.name = name[0];
					e.start = e.time_start;
					e.end = e.time_end;
					e.color = colorEvent[e.id]
				});
				return data;
			},
			async upCalendar(param) {
				var data = await this.getClassTime(param);
				this.events = this.upCalendar_before(data)
			},
		},
		created() {
			/* 这周的日期 从周日 到 周六 */
			var date = new Date(),
				day = date.getDay();
			this.dateQueueStart = formatDate.computed(date, day, 'reduce', 'day');
			this.dateQueueEnd = formatDate.computed(date, (6 - day), 'add', 'day');
			this.upCalendar({date_start: this.dateQueueStart, date_end: this.dateQueueEnd});
		},
		computed: {
			getDate() {
				return new Date().getMonth() - 0 + 1;
			},
		},
		mounted() {
			this.$refs.calendar.scrollToTime("8:00 上午");
			this.EnglistToChinese()
		}
	};
</script>

<style scoped lang='less'>
	.boxClassAndColor {
		overflow-x: auto;
		white-space: nowrap;
		overflow-y: hidden;
	}

	.classAndColor {
		margin-left: 25px;
	}

	/* 
	::-webkit-scrollbar {
		display: none;
	} */

	.boxClassColor {
		width: 20px;
		height: 12px;
		border-radius: 5px;
		display: inline-block;
	}

	.box {
		overflow-y: auto;
	}

	.kk {
		height: 100px;
	}

	.my-event {
		overflow: hidden;
		border-radius: 2px;
		background-color: #1867c0;
		color: #ffffff !important;
		border: 1px solid #1867c0;
		font-size: 12px;
		padding: 3px;
		cursor: pointer;
		margin-bottom: 1px;
		left: 4px;
		margin-right: 8px;
		position: relative;
	}

	.my-event.with-time {
		position: absolute;
		right: 4px;
		margin-right: 0px;
	}

	/deep/ .v-calendar-daily__scroll-area {
		overflow-y: auto;
	}


	/deep/ .v-calendar .v-event-timed {
		color: white;
		white-space: pre-wrap;
		margin-left: 5px;
		margin-right: 5px;
	}

	/deep/ .theme--light.v-calendar-events .v-event-timed {
		border: 1px solid rgba(0, 0, 0, 0) !important;
		box-sizing: border-box;
		padding: 10px;
	}

	/deep/ .v-btn--fab.v-size--default {
		margin-top: 10px;
	}

	/deep/ .theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday {
		color: black !important;
	}

	/deep/ .v-present .v-btn__content {
		color: white;
	}
</style>
