<!-- 
	@describe 加载动画
	@date 2021-03-31
	@by FZY
 -->
<template>
	<div class="loading" v-show="loading">
		<i class="el-icon-loading"></i>
	</div>
</template>

<script>
	export default {
		name: 'Loading',
		props: {
			showLoading: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				loading: true
			}
		},
		watch: {
			showLoading(val) {
				this.loading = val
			}
		},
		created() {
			this.loading = this.showLoading
		}
	}
</script>

<style scoped>
	.loading {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #00000005;
		z-index: 999;
		top: 0;
		left: 0;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (min-width: 1500px) {

		.loading {
			font-size: 50px;
		}
	}

	@media screen and (max-width: 1499px) {

		.loading {
			font-size: 30px;
		}
	}
</style>
