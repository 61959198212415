<template>
	<div class="fee-container" v-if="effective.length > 0">
		<!-- 左侧 -->
		 <div class="fee-left" v-if="effective.length > 0">
			<div class="left-header">有效费用</div>
			<div class="left-body">
				<el-timeline >
					<div v-for="item in effective" :key="item.id" @click="chooseFee(item.id)">
						<el-timeline-item placement="top" :timestamp="item.created_at">
						  <el-card>
							<div slot="header" class="clearfix">
								 <span>{{item.name}}</span>
								 <el-button style="float: right; padding: 3px 0" type="text">{{item.paid_amount}}</el-button>
							</div>
							<div class="flex justify-between">
								<div>{{item.pay_status == 1 ? '全额缴款' :'缴费不足'}}</div>
								<div>剩余课时:{{item.lesson}}</div>
							</div>
							<div>
								有效期至:{{item.expiration}}
							</div>
						  </el-card>
						</el-timeline-item>
					</div>
				  </el-timeline>
			</div>
			<div v-if="history.length > 0">		
			<div class="left-header">历史费用</div>
				<div class="left-body" >
					<div  v-for="item in history" :key="item.id" @click="chooseFee(item.id)">
					<el-timeline >
						<el-timeline-item placement="top" :timestamp="item.created_at">
						  <el-card>
							<div slot="header" class="clearfix">
								 <span>{{item.name}}</span>
								 <el-button style="float: right; padding: 3px 0" type="text">{{item.paid_amount}}</el-button>
							</div>
							<div class="flex justify-between">
								<div>{{item.pay_status == 1 ? '全额缴款' :'缴费不足'}}</div>
								<div>剩余课时:{{item.lesson}}</div>
							</div>
							<div>
								有效期至:{{item.expiration}}
							</div>
						  </el-card>
						</el-timeline-item>
					  </el-timeline>
				  </div>
				</div>
			</div>
		</div> 
		<!-- 左侧/ -->
		<!-- 右侧 -->
		<div class="fee-right">
			<!-- tabs -->
				<el-tabs v-model="activeName">
				    <!-- 费用详情-->
					<el-tab-pane label="费用详情" name="first">
						<el-form inline class="form-feeDetail" label-position="top" :model="fee" :rules="rules" ref='formStudentFee'>
							<el-form-item label="缴费名称" prop="name">
								<el-input type="text" placeholder="请输入缴费名称" v-model="fee.name"></el-input>
							</el-form-item>
							<el-form-item label="费用类别">
								<el-select v-model="fee.cost_type">
									<el-option :value=1 label="按课时缴费"></el-option>
									<el-option :value=2 label="按时间缴费"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="购买课时" prop="cost_lesson">
								<el-input type="text" placeholder="请输入购买的课时" v-model="fee.cost_lesson"></el-input>
							</el-form-item>
							<el-form-item label="赠送课时" prop="give_lesson">
								<el-input type="text" placeholder="请输入赠送的课时" v-model="fee.give_lesson" disabled></el-input>
							</el-form-item>
							<el-form-item label="历史课时">
								<el-input type="text" placeholder="请输入历史的课时" v-model="fee.his_lesson" disabled></el-input>
							</el-form-item>
							<el-form-item label="总课时" prop="lesson">
								<el-input type="text" placeholder="请输入总的课时" v-model="fee.lesson" disabled></el-input>
							</el-form-item>
							<el-form-item label="请假限制">
								<el-select v-model="fee.leave">
									<el-option v-for="(item,index) in $param.leaveCount" :value="item.val" :label="item.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="有效期至" prop="expiration"> 
								<el-date-picker
								v-model="fee.expiration"
								      type="date"
								      placeholder="选择日期"
									  format="yyyy-MM-dd"
									  value-format="yyyy-MM-dd"
									  >
								    </el-date-picker>
							</el-form-item>
						<!-- 	
							<el-form-item label="续费提醒">
								<el-switch
								  v-model="fee.renewal_reminder"
								  active-value=1
								  inactive-value=2
								>
								</el-switch>
							</el-form-item> -->
							
							<el-form-item label="原价" prop="origin_price">
								<el-input type="text" placeholder="请输入原价" v-model="fee.origin_price"></el-input>
							</el-form-item>
							<el-form-item label="优惠后金额" prop="price_discount">
								<el-input type="text" placeholder="请输入优惠后的金额" v-model="fee.price_discount"></el-input>
							</el-form-item>
							<el-form-item label="支付方式">
								<el-select v-model="fee.pay_method">
									<el-option v-for="(item,index) in $param.payType" :label="item.name" :value="item.val"></el-option>
								</el-select>
							</el-form-item>
							<!-- <el-form-item label="缴费人">
								<el-input type="text" placeholder="请输入缴费人名称" v-model="fee.payer_name"></el-input>
							</el-form-item><br> -->
							<el-form-item label="减免" prop="deduce_price">
								<el-input type="text" v-model="fee.deduce_price" size=""/>
							</el-form-item>
							<el-form-item label="折扣" prop="discount">
								<el-input type="text" v-model="fee.discount" size="" @blur="blurDiscount" @focus="focusDiscount"/>
							</el-form-item>
							<el-form-item label="折扣" style="opacity: 0;">
								
							</el-form-item>
							<el-form-item label="备注">
								<el-input type="textarea" v-model="fee.remark" size="small"/>
							</el-form-item>
							<el-form-item label="合同">
								<el-upload								  
								  class="avatar-uploader"
								  action="http://upload-z2.qiniup.com"								  
								  multiple
								  list-type="picture-card"								 								  
								  accept="image/png, image/jpeg"
								  :file-list="fileList"
								  :data="formData"
								  :before-upload="handleUploadBefore"
								  :on-success="handleSuccess"
								  :on-remove="handleRemove"
								  :on-preview="handlePreview"
								  >
								  <i class="el-icon-plus"></i>
								</el-upload>        																
								<el-dialog :visible.sync="dialogVisible" :modal-append-to-body='false'>
								    <img width="100%" :src="imageUrl" alt="">
								</el-dialog>
							</el-form-item>
							<el-form-item >
								<el-button type="primary" size="small" @click='editFee'>保存</el-button>
							</el-form-item>
						</el-form>
					</el-tab-pane>
					<!-- 缴费记录 -->
				    <el-tab-pane label="缴费记录" name="consumerFee">
						 <el-timeline>
						    <el-timeline-item  placement="top">
						      <el-card v-for="item in feeLog" :key="item.id">
						        <h4>{{item.type  == 1 ? '续费' : item.type == 2 ? '修改' : item.type == 3 ? '补缴' : item.type == 4 ? '新增缴费' : item.type == 5 ? '退费' : '未知'}}</h4>
						        <div class="flex justify-between">
									<p class="c-blue mg-tb-10">￥{{item.paid_amound_change}}</p>
									<p class="c-blue mg-tb-10">有效期至:{{item.expiration_change}}</p>
								</div>
								<p class="fs-10">操作人：{{item.name}}</p>
						      </el-card>
						    </el-timeline-item>
						  </el-timeline>
					</el-tab-pane> 					
			    </el-tabs>
			<!-- tabs/ -->
		</div>
		<!-- 右侧/ -->
		<!-- form -->
		<!-- <el-drawer
		  title="教师评价"
		  :visible.sync="teacheVisibe"
		  direction="rtl"
		  >
		  <span>我来啦!</span>
		</el-drawer> -->
		<!-- form/ -->
	</div>
	<div v-else>该学员未分班，暂无缴费信息</div>
</template>

<script>
	import vali from '../../../assets/js/validate.js'
	import compress from '../../../assets/js/compress-file.js'
	export default{
		name: 'StudentFeeInfo',
		props: ['studentId'],
		data(){
			return{
				rules: {
					name: vali.validatorCommon.name_cost,
					cost_lesson: vali.validatorCommon.hours_class,
					give_lesson: vali.validatorCommon.hours_class,
					lesson: vali.validatorCommon.hours_class,
					expiration: vali.validatorCommon.required_blur,
					origin_price: vali.validatorCommon.isPrice,
					price_discount: vali.validatorCommon.isPrice,
					deduce_price: vali.validatorCommon.isPrice,
					discount: [{
						validator: (rule, val, callback) => {
							if (val.length === 0) return callback();
							var reg = /^(?:0\.){1}[\d]+(?:\s\([\d]{1}(?:\.[\d]+)*折\))*$/;
							if (reg.test(val)) return callback();
							callback(new Error("打折请输入0-1之间的数"));
						},
						trigger: "blur",
					}, ]
				},
				dialogVisible: false,
				imageUrl: '',
				formData: {
					key: '',
					token: ''
				},
				fileList: [],
				activeName:'first',
				/* 抽屉显示 */
				teacheVisibe:false,
				/*学员id  */
				sid:0,
				effective:[],
				history:[],
				fee: {},
				feeLog:''
			}
		},
		created() {
			this.sid = this.studentId ? this.studentId : this.$route.query.id;
			this.getStudentFeeList();
			this.getToken()
		},
		methods:{
			blurDiscount() {
				this.fee.discount = this.setDiscount(this.fee.discount, 'blur')
			},
			focusDiscount() {
				this.fee.discount = this.setDiscount(this.fee.discount, 'focus')
			},
			/* 折扣显示格式 ---失去焦点格式是0.21 （2.1折）获得焦点时时0.21 */
			setDiscount(val, key) {
				var result = '';
				switch (key) {
					case 'blur':
						var reg = /^(?:0\.)+[\d]+$/;
						if (reg.test(val) && val!= 0) {
							result = val + " (" + (val * 10).toFixed(2) + "折)";
						} else if (val == 0) result = '0.00 (1折)'
						break;
					case 'focus':
						var reg = /^(?:0\.){1}[\d]+(?:\s\([\d]{1}(?:\.[\d]+)*折\))*$/;
						if (reg.test(val)) {
							var s = String(val).split(' ')
							result = s[0]
						} else result = 0
						break;
					default:
						break;
				}
				return result
			},
			// 获得token
			async getToken () {
			  var { data: res } = await this.$http.get('/common/qiniuToken')
			  if (res.code === 200) {
			    this.formData.token = res.data.token
			  }
			},
			handlePreview (file) {
			  this.dialogVisible = true
			  this.imageUrl = file.url
			},
			/* 合同移除前的钩子 */
			handleRemove (file, fileList) {
				this.fileList = fileList
			},
			// 上传之前
			async handleUploadBefore (file) {
			  // 更新key
			  var date = new Date();
			  var currentTime = date.getFullYear() + '-' + (date.getMonth() - 0 + 1) + '-' + date.getDate();
			  this.formData.key = currentTime + '/' + file.uid + '/' + file.name;
			  return await compress(file)
			},
			handleSuccess (response, file, fileList) {
				if(response.key){
					var json = {"name":response.key ,"url":"http://v.hipposteam.com/"+response.key, uid: file.uid}
					this.fileList.push(json);					
				}else{					
					this.$message.error("上传失败");
			    }
			},
			/* 修改费用 --点击 */
			editFee () {
				this.$refs.formStudentFee.validate( (bool, obj) => {
					if (bool) this.editStudentFeeList()
				})				
			},
			/* 修改费用信息 */
			async editStudentFeeList(){
				/* 暂时保存折扣信息，请求失败再次赋值 */
				var discount = this.fee.discount;
				this.fee.discount = (discount.split(' ')[0]) * 100;
				this.fee.paid_amount = this.fee.price_discount; //优惠后的价格 等于 实缴价格
				this.fee.contract = [...this.fileList]; 
				const {data:res} = await this.$http.patch("/student/fee/edit", Object.assign(this.fee, {sid: this.sid}));
				if(res.code == 200){										
					this.$message.success('修改费用信息成功')
					this.getStudentFeeList()
				}
				else {
					this.$message.error(res.msg)
					this.fee.discount = discount
				}
			},
			/* 获取有效费用列表 */
			async getStudentFeeList(){
				const {data:res} = await this.$http.get("student/fee/student?sid="+this.sid);
				if(res.code == 200){
					this.history = res.data.history;
					this.effective = res.data.effective;
					if(this.effective.length > 0 ){
						this.getstudentFee(this.effective[0].id);
						this.getFeeLog(this.effective[0].id);
					}
					
				}
			},
			/* 选择费用信息 */
			chooseFee(id){
				this.getstudentFee(id);
				this.getFeeLog(id);
			},
			/* 根据费用id 获取费用详情 */
			async getstudentFee(id){
				const {data:res} = await this.$http.get("student/fee/detail?id="+id);
				if(res.code == 200){
					this.fee = res.data;
					this.fee.discount = this.setDiscount(this.fee.discount ? this.fee.discount / 100 : 0, 'blur');
					this.fee.contract_info = this.fee.contract ? this.fee.contract : [];
					if (typeof this.fee.contract_info == 'string') {
						this.fee.contract_info = JSON.parse(this.fee.contract_info)
					}
					/* 图片两种不同的形式 url对象数组 和 路径数组 */
					if (this.fee.contract_info.length > 0 && (typeof this.fee.contract_info[0] == 'string')) {
						this.fee.contract_info = this.fee.contract_info.map( e => {
							var reg = /(http|https):\/\/([\w.]+\/?)\S*/;
							if (reg.test(e)) {
								return {
									url: e
								}
							} else {
								return {
									url: 'http://v.hipposteam.com/' + e
								}
							}	
						});
					}
					this.fileList = [...this.fee.contract_info]
				}
			},
			/* 获取缴费记录 */
			async getFeeLog(id){
				const {data:res} = await this.$http.get("student/fee/paymentrecords?id="+id);
				if(res.code == 200){
					this.feeLog = res.data;
				}
			}
		},
		watch: {
			'fee.cost_lesson': function (val) {
				this.fee.lesson = val - 0 + this.fee.give_lesson
			}
		},
		filters: {
			getDeducePrice (val) {
				return (val - 0).toFixed(2)
			},
			getDiscount (val) {
				if (val == 0) return '0 (1折)'
				return val + '(' + val / 10 + '折)'
			}
		}
	}
</script>

<style lang="less" scoped>
	.fee-container{
		width: 100%;
		display: flex;
		justify-content: space-between;
		> .fee-left{
			width: 48%;
			.left-header{
				font-weight: 700;
				margin-bottom: 10px;
			}
		}
		> .fee-right{
			width: 48%;
		}
	}
	.form-feeDetail{
		> .el-form-item,.el-input,.el-select{
			width: 360px;
		}	
	}
	.consumerGradeDetail-header{
		margin: 10px auto;
		width: 86%;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}
	/* 备注 */
	/deep/ .el-textarea textarea{
		height: 80px;
	}
	/* 上传合同 */
	.avatar-uploader .el-upload:hover {
	  border-color: #409EFF;
	}
	.el-icon-plus {
	  width: 80px;height: 80px;line-height: 80px;
	}
	/deep/ .el-upload--picture-card{
	    width: 80px;
	    height: 80px;
	}
	/deep/ .el-upload{
	    width: 80px;
	    height: 80px;
	    line-height: 80px;
	}
	/deep/ .el-upload-list--picture-card .el-upload-list__item{
	    width: 80px;
	    height: 80px;
	    line-height: 80px;
	}
	/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
	    width: 80px;
	    height: 80px;
	    line-height: 80px;
	}
	/deep/ .avatar{
	    width: 80px;
	    height: 80px;
	}
</style>
