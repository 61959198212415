<template>
  <!-- 财务申请列表 -->
  <div class="container">
    <!-- 列表 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>财务审核</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 筛选 -->
      <div class="typeContainer" style="padding-bottom: 10px; display: flex; align-items: center;">
        <el-date-picker v-model="screen.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" @change="timeChange">
        </el-date-picker>

        <el-select v-model="screen.status" placeholder="状态筛选" style="margin-left: 5px;" @change="statusChange" clearable>
          <el-option v-for="(item,index) in financialStatus" :key="index" :label="item.label" :value="item.val">
          </el-option>
        </el-select>

        <el-select v-model="screen.type" placeholder="类型筛选" style="margin-left: 5px;" @change="typeChange" clearable>
          <el-option v-for="(item,index) in financialType" :key="index" :label="item.label" :value="item.val">
          </el-option>
        </el-select>

        <el-select v-model="screen.schoole_id" placeholder="校区筛选" style="margin-left: 5px;" @change="search" clearable>
          <el-option v-for="(item,index) in schooleLists" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-input clearable style="width:300px;margin-left: 5px;" v-model="screen.key" @change="search" placeholder="请输入审批编码、申请人">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="search"></i>
        </el-input>
      </div>

      <el-table :data="list">
        <el-table-column prop="code" label="审批编码" width="200">

        </el-table-column>
        <el-table-column prop="teacher_name" label="申请人" align="center">

        </el-table-column>
        <el-table-column prop="school_name" label="校区" align="center">

        </el-table-column>

        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            {{scope.row.status == 1 ? '待提交' : scope.row.status == 2 ? '待处理' : scope.row.status == 3 ? '已驳回' : scope.row.status == 4 ? '已处理' : scope.row.status == 5 ? '已处理' : '其他' }}
          </template>
        </el-table-column>

        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            {{scope.row.type == 1 ? '报销申请' : scope.row.type == 2 ? '借款申请' : scope.row.type == 3 ? '付款申请' : scope.row.type == 4 ? '发票申请' : scope.row.type == 5 ? '采购申请' : '退费申请' }}
          </template>
        </el-table-column>

        <el-table-column label="申请时间" min-width="150" prop="created_at" align="center">
        </el-table-column>

        <el-table-column prop="cause" label="事项" min-width="150">

        </el-table-column>

        <el-table-column label="操作" align="center" width="90">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" @click="lookType(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px;">
        <el-pagination background layout="total ,prev, pager, next" :current-page.sync="screen.page" @current-change="pageChange" :total="pagination.total">
        </el-pagination>
      </div>

    </el-card>

  </div>
</template>

<script>
export default {
  name: 'FinancialList',
  data() {
    return {
      screen: {
        page: 1,
        type: '',
        status: '',
        date: [],
        schoole_id: '',
        key: ''
      },
      pagination: {
        total: 0
      },
      list: [],
      time: [],
      financialStatus: [
        { label: '待处理', val: 2 },
        { label: '已驳回', val: 3 },
        { label: '已处理', val: 4 },
        { label: '已撤回', val: 5 }
      ],
      financialType: [
        { label: '报销申请', val: 1 },
        { label: '借款申请', val: 2 },
        { label: '付款申请', val: 3 },
        { label: '发票申请', val: 4 },
        { label: '采购申请', val: 5 },
        { label: '退费申请', val: 6 }
      ],
      schooleLists: JSON.parse(localStorage.getItem('schooleLists'))
    }
  },
  methods: {
    /* 获取财务列表 */
    async getFinancialList() {
      var params = { ...this.screen };
      params.date && params.date.length === 2 && (params.date_start = params.date[0], params.date_end = params.date[1]);
      const { data: res } = await this.$http.get('/organization/financial/list', { params });
      if (res.code == 200) {
        this.list = res.data.data;
        this.pagination.total = res.data.total;
      }
    },
    /* 查看详情 */
    lookType(row) {
      switch (row.type) {
        case 1:
          this.$router.push('/financial/financialReimbursement/' + row.id)
          break;
        case 2:
          this.$router.push('/financial/financialLoan/' + row.id)
          break;
        case 3:
          this.$router.push('/financial/financialPayment/' + row.id)
          break;
        case 4:
          this.$router.push('/financial/financialInvoice/' + row.id)
          break;
        case 5:
          this.$router.push('/financial/financialPurchase/' + row.id)
          break
        case 6:
          this.$router.push('/financial/financialRefund/' + row.id)
      }
    },
    /* 页码改变 */
    pageChange(page) {
      this.page = page
      this.getFinancialList();
    },
    /* 时间改变 */
    timeChange(val) {
      this.page = 1;
      if (val == null) {
        this.date_start = ""
        this.date_end = ""
        this.getFinancialList();
      } else {
        this.date_start = val[0]
        this.date_end = val[1]
        this.getFinancialList();
      }
    },
    /* 状态改变  */
    statusChange(row) {
      this.page = 1;
      this.getFinancialList();
    },
    /* 类型改变 */
    typeChange(row) {
      this.page = 1;
      this.getFinancialList();
    },
    /* 搜索财务列表 */
    search() {
      this.page = 1;
      this.getFinancialList();
    }
  },
  created() {
    /* 获取财务列表 */
    this.page = 1;
    this.getFinancialList();
  }
}
</script>

<style>
.container {
  width: 100%;
}
</style>
