/* 公共js库 */
class handleDate {
	/* 用来处理日期 */
	constructor(arg) {

	}
	/* 从一个日期数组里找到离想找到的日期的最近日期 */
	lately(date, ...params) {
		/* {
			date: 'Array -- 必填-- 要查的日期数组',
			findDate: 'String  --选填--依据这个日期找',
			keyObj: {
				date: 'String  --- date为对象时，date日期的key值',
				time: 'String  --- date为对象时，date时间的key值'
			}
		} */
		var findDate = Date.now(),
			keyObj = {
				date: 'date',
				time: 'time'
			};
		/* 依据日期findDate和keyObj不是必选的,这个是用来区分传的参数是哪个 */
		if (params.length == 1) {
			var type = typeof params[0];
			if (type == 'string' || type == 'number') {
				findDate = params[0] - 0
			} else if (params[0] instanceof Object) {
				keyObj = params[0]
			}
		} else if (params.length == 2) {
			findDate = params[0] - 0;
			keyObj = params[1]
		}
		var result = {
			/* 索引 */
			index: '',
			/* 返回的日期 */
			value: '',
			/* 错误信息 没错误为空 */
			err: '',
		};
		/* 检测date数组是不是日期格式的 */
		/* 检测keyObj是否合理，它的prototype>date应该是要查询数组对象的日期key值， time应该是要查询数组对象的时间key值 */
		for (let i = 0; i < date.length; i++) {
			var bool = false;
			if (typeof date[i] == 'string') {
				if (!this.checkDate(date[i])) bool = true
			} else if (date[i] instanceof Object) {
				if (!date[i][keyObj.date] || !date[i][keyObj.time]) {
					result.err = `date应该是要查询数组对象的日期key值， time应该是要查询数组对象的时间key值`;
					return result
				} else if (!this.checkDate(date[i][keyObj.date] + ' ' + date[i][keyObj.time])) bool = true
			}
			if (bool) {
				result.err = `${date[i]}不是日期格式，在索引为${i}的位置`;
				return result
			}
		}
		/* 检测findDate是不是日期格式或者是不是毫秒 */
		if (!this.checkDate(findDate)) {
			result.err = `${findDate}不是日期，也不是毫秒`;
			return result
		}
		/* 要查询的日期数组减去依据日期的绝对值数组 */
		var arr;
		try {
			arr = date.map(e => {
				/* 数组字符串形式  --格式为YYYY-MM-DD HH:MM:SS*/
				if (typeof e == 'string') {
					return Math.abs(new Date(e).getTime() - findDate)
				}
				/* 数组对象形式  --格式为{date: 'YYYY-MM-DD'， time: 'HH-MM-SS'},可以通过keyObj设置想要的键 */
				else if (e instanceof Object) {
					var d = new Date(e[keyObj.date] + ' ' + e[keyObj.time])
					return Math.abs(d.getTime() - findDate)
				}

			})
		} catch (e) {
			this.thorwErr(e);
			result.err = e;
			return result
		}
		var min = Math.min(...arr);
		result.index = arr.findIndex((p) => {
			return p == min;
		});
		result.value = date[result.index]
		return result
	}
	/* 按照格式返回日期 */
	detail(date, format) {
		/* date为要格式化的日期*/
		/* format为要返回的日期格式(现在只精确到日期)，有YYYY-MM-DD   YYYY年MM月DD日  YYYY/MM/DD */
		date = new Date(date);
		var year = date.getFullYear(), //年份
			mouth = date.getMonth() - 0 + 1, //月
			day = date.getDate(), //这个月几号
			nowDay = date.getDay(), //本周第几天
			hours = date.getHours(), //时
			minutes = date.getMinutes(), //分
			seconds = date.getSeconds(), //秒
			time = date.getTime(); //毫秒
		if (mouth < 10) mouth = '0' + mouth
		if (day < 10) day = '0' + day
		if (hours < 10) hours = '0' + hours
		if (minutes < 10) minutes = '0' + minutes
		if (seconds < 10) seconds = '0' + seconds
		var obj = {
			year,
			mouth,
			day,
			hours,
			minutes,
			seconds,
			time,
			nowDay
		}
		if (format) {
			format = format.toLocaleUpperCase();
			format = format.replace('YYYY', obj.year);
			format = format.replace('MM', obj.mouth);
			format = format.replace('DD', obj.day);
			return format
		} else return obj
	}
	/* 日期的计算 */
	computed(date, value, type, key = 'mouth', format = 'YYYY-MM-DD') {
		/* date是初始的日期， value是要加多少或者减多少，type是加还是减，有add和reduce, key是年（year）、月(mouth)、日(day)中的一种 format是最后返回的格式 --有YYYY-MM-DD   YYYY年MM月DD日  YYYY/MM/DD*/
		/* 第一步 -- 是要拿到初始日期 */
		var dateObj = this.detail(date),
			count = 0;
		/* 第二步-- 计算出应该加多少哦或者减多少 */
		switch (key) {
			case 'year':
				var year = dateObj.year;
				/* 闰年有366天 */
				if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {
					count = value * (366 * 24 * 60 * 60 * 1000)
				}
				/* 平年有365天 */
				else {
					count = value * (365 * 24 * 60 * 60 * 1000)
				}
				break;
			case 'mouth':
				/* 默认一个月有30天 ---待优化 闰年2月29天，平年28天 4、6、9、11是30天 1、3、5、7、8、10、12是31天*/
				count = value * 30 * 24 * 60 * 60 * 1000
				break;
			case 'day':
				count = value * 24 * 60 * 60 * 1000
				break;
			default:
				break;
		}
		/* 第三步-- 计算 */
		if (type == 'add') return this.detail(new Date(dateObj.time + count), format)
		else if (type == 'reduce') return this.detail(new Date(dateObj.time - count), format)
	}
	/* 某段时间的一周开始结束日期 */
	getWeekStartAndEnd(val) {
		/* val是日期 */
		let now = '';
		if (val) {
			now = new Date(val); // 日期
		} else {
			now = new Date(); // 日期
		}
		let nowDayOfWeek = now.getDay(); // 本周的第几天
		let nowDay = now.getDate(); // 当前日
		let nowMonth = now.getMonth(); // 当前月
		let nowYear = now.getFullYear(); // 当前年
		let weekStart = this.getWeekStart(nowYear, nowMonth, nowDay, nowDayOfWeek)
		let weekEnd = this.getWeekEnd(nowYear, nowMonth, nowDay, nowDayOfWeek)
		return {
			'date_start': weekStart,
			'date_end': weekEnd
		}
	}
	
	getWeekStartAndEndByChange(val) {
		/* val是日期 */
		let now = '';
		if (val) {
			now = new Date(val); // 日期
		} else {
			now = new Date(); // 日期
		}
		let nowDayOfWeek = now.getDay() == 0 ? 7 :  now.getDay(); // 本周的第几天
		console.log(nowDayOfWeek);
		let nowDay = now.getDate(); // 当前日
		let nowMonth = now.getMonth(); // 当前月
		let nowYear = now.getFullYear(); // 当前年
		let weekStart = this.getWeekStart(nowYear, nowMonth, nowDay, nowDayOfWeek - 1)
		let weekEnd = this.getWeekEnd(nowYear, nowMonth, nowDay, nowDayOfWeek -1 )
		return {
			'date_start': weekStart,
			'date_end': weekEnd
		}
	}
	 getNowFormatDate() {
	        var date = new Date();
	        var seperator1 = "-";
	        var year = date.getFullYear();
	        var month = date.getMonth() + 1;
	        var strDate = date.getDate();
	        if (month >= 1 && month <= 9) {
	            month = "0" + month;
	        }
	        if (strDate >= 0 && strDate <= 9) {
	            strDate = "0" + strDate;
	        }
	        var currentdate = year + seperator1 + month + seperator1 + strDate;
	        return currentdate;
	    }
	
	/* 获得一周的开始  ---周日 */
	getWeekStart(nowYear, nowMonth, nowDay, nowDayOfWeek) {
		/* nowYear(当前年), nowMonth(当前月), nowDay(当前日), nowDayOfWeek(本周的第几天) */
		let weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek)
		return this.detail(weekStartDate, 'YYYY-MM-DD')
	}
	/* 获得一周的结束  ---周六*/
	getWeekEnd(nowYear, nowMonth, nowDay, nowDayOfWeek) {
		/* nowYear(当前年), nowMonth(当前月), nowDay(当前日), nowDayOfWeek(本周的第几天) */
		let weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek))
		return this.detail(weekEndDate, 'YYYY-MM-DD')
	}
	
	getYesterday(date, day) {
		var dd = new Date(date);
		dd.setDate(dd.getDate() + day);
		var y = dd.getFullYear();
		var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
		var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
		return y + "-" + m + "-" + d;
	}
	
	/* 检测是不是日期 */
	checkDate(date) {
		/* 是数字或者字符串的 */
		/* 数字的只要不是负数就行 */
		if (isNaN(date) && !isNaN(Date.parse(date))) {
			return true
		} else if (date - 0 >= 0) {
			return true
		} else return false
	}
	thorwErr(e) {
		console.error(e)
	}
}
var formatDate = new handleDate();

/* 获取离现在 最近的时间点 */
function getIndex(arr) {
	var timestamp = Date.now();
	var min;
	var index = 0;
	arr.forEach(function(item, i) {
		var itemStamp = new Date(item).getTime();
		var interVal = Math.abs(timestamp - itemStamp);
		if (min == void 0) {
			min = interVal
		} else {
			if (min > interVal) {
				min = interVal;
				index = i;
			}
		}
	})
	return index;
}

/* 过滤器 */
class Filters {
	constructor(arg) {

	}
	/* 根据数字获取考勤状态 */
	getAttendance(val) {
		/* 考勤状态 1-出勤、2-请假、3-旷课、4-未处理 */
		switch (val) {
			case 1:
				return '出勤'
				break;
			case 2:
				return '请假'
				break;
			case 3:
				return '旷课'
				break;
			case 4:
				return '未处理'
				break;
			default:
				break;
		}
	}
	/* 删除秒 */
	delSencond(val) {
		var s = val.split(':');
		return s[0] + ':' + s[1]
	}
	/* 价格保留两位小数点 */
	filterPrice (val) {
		return (val - 0).toFixed(2)
	}
}
var filter = new Filters()



export default {
	getIndex,
	formatDate,
	filter,	
}
