<template>
	<div class="dialog">
		<el-dialog
		  :title='dialog.title'
		  :visible.sync="dialog.show"
		  :width="dialog.width" center
		  >
		  <slot name='body'></slot>
		  <span slot="footer" class="dialog-footer" v-if="dialog.button">
		    <el-button @click="dialog_cancel">取 消</el-button>
		    <el-button type="primary" @click="dialog_confirm">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		name: 'Dialog',
		props: {
			dialog: {
				type: Object,
				default: function (){
					return {
						show: false,
						title: '',
						button: true,
						width: '30%',
						center: true
					}
				}
			}
		},
		data () {
			return {}
		},
		methods: {
			callback (callback) {
				callback(this.dialog)
			},
			// 关闭前
			// handleClose (done) {done()},
			// 确定
			dialog_confirm () {
				this.$emit('dialog_confirm', { callback: this.callback })
			},
			// 取消
			dialog_cancel () {
				this.dialog.show = false;
				this.$emit('dialog_cancel', { callback: this.callback })
			}
		},
	}
</script>