/* 班级配置 */
export const CLASSSTATUS = [{
		name: '已分班',
		val: '1'
	},
	{
		name: '未分班',
		val: '2'
	}
]

export const PARENTSTATUS = [
	{
		name: '已激活',
		val: '1'
	},
	{
		name: '未激活',
		val: '2'
	}
]

export const CLASS_TYPE = [
	{
		id: '1',
		name: '常规班级'		
	},
	{
		id: '3',
		name: '体验班级'
	},
	{
		id: '4',
		name: '活动班级'
	}
]
