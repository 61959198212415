// 2021-05-12 由FZY创建 公共方法

import { mapRole } from './data.js'

/**
 * @description 根据role获取角色名称
 * @param {Object} role
 */
export function getRole (role){
	var key = role || window.sessionStorage.getItem("role");
	return mapRole[key]
}