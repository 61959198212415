import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'
import {
    INSERT_ROUTELIST,
    SET_SELECTROUTE
} from '../store/mutations-type.js'

import login from '../components/login.vue'
import index from '../components/index.vue'
import home from '../components/home.vue'
import permission from '../components/system/account/permission.vue'
import role from '../components/system/account/role.vue'
import user from '../components/system/account/user.vue'
/* 系统管理 */
import courseLog from '../components/system/log/course.vue'
import classLog from '../components/system/log/class.vue'
import attendanceLog from '../components/system/log/attendance.vue'
import notificationConfig from '../components/system/notification/notificationConfig.vue'
/* 学员管理 */
import student from '../components/student/student.vue'
/* 学员管理 - 学员登记  */
import studentRegister from '../components/student/form/studentbasicInformation.vue'
/* 学员管理 - 学员登记 - 学员基本信息 - 课程报名/分班 - 缴纳其他费用 */
import studentbasicInformationRegister from '../components/student/form/studentbasicInformation.vue'
import studentCourseRegister from '../components/student/form/studentCourseRegister.vue'
import studentappointCourse from '../components/student/form/student-appointCourse.vue'
/* 学员档案 */
import studentFile from '../components/student/studentFile.vue'
import studentFileSingle from '../components/student/studentFileSingle.vue'
/* 学员档案 - 基本信息 */
// import studentBasicInformation from '../components/student/form/studentBasicInformation.vue'
/* 学员档案 - 班级信息 */
import studentClassInformation from '../components/student/form/studentClassInformation.vue'
/* 学员档案 - 费用信息 - 学员课时信息 */
import studentFeeInformation from '../components/student/form/studentFeeInformation.vue'
/* 学员档案 - 购买课包信息 */
import studentCostLessonInformation from '../components/student/form/studentCostLessonInformation.vue'
import studentClassHoursInfo from '../components/student/form/studentClassHoursInfo.vue'
/* 学员档案 - 退费记录 */
import studentRefundList from '../components/student/form/studentRefundList.vue'
/* 学员信息的导入导出 */
import studentInAndOut from '../components/student/StudentInAndOut.vue'
/* 学员转赠课包记录 */
import studentTurnLists from '../components/student/studentTurnLists.vue'
/* 学员课包变动记录 */
import studentChange from '../components/student/studentChange.vue'

/* ------------------------------机构管理------------------------------------ */
import classrooms from '../components/organization/classrooms.vue'
import charges from '../components/organization/charges.vue'
import schoole from '../components/organization/schoole.vue'
import classFee from '../components/organization/classFee.vue'
import salary from '../components/organization/salary.vue'
import salaryAll from '../components/organization/form/salary-all.vue'
import salaryInfo from '../components/organization/form/salaryInfo.vue'
import departmentLevel from '../components/organization/departmentLevel.vue'
import department from '../components/organization/department.vue'
import siteInfo from '../components/organization/siteInfo.vue'
/* ------------------------------教务管理------------------------------------ */
import education from '../components/education/education.vue'
import educationStudentState from '../components/education/form/education-studentState.vue'
import educationShowClass from '../components/education/form/management_class.vue'
import educationAdjustment from '../components/education/form/education-adjustmentClassHours.vue'
import educationAddendanceRecord from '../components/education/form/education-attendanceRecord.vue'
import educationTempAddHour from '../components/education/form/education-tempAddHour'
import educationBbatchAdjustMakeUpHour from '../components/education/form/education-batchAdjustMakeUpHour'
import educationFastTypesetting from '../components/education/fastTypesetting.vue'
import leaveRecord from '../components/education/leaveRecord.vue'

/* ------------------------------数据分析------------------------------------ */
import dataAnalysisBulletin from '../components/dataAnalysis/data-analysis_bulletin.vue'
import dataAnalysis from '../components/dataAnalysis/data-analysis.vue'

/* ----------------------------消息----------------------------------------- */
import msg from '../components/msg/msg.vue'
/* 财务申请 */
import approval from '../components/financial/approval.vue'
import financialList from '../components/financial/financialList.vue'
import financialAllocation from '../components/financial/financialAllocation.vue'
import financialLoan from '../components/financial/financialLoan.vue'
import financialReimbursement from '../components/financial/financialReimbursement.vue'
import financialPayment from '../components/financial/financialPayment.vue'
import financialInvoice from '../components/financial/financialInvoice.vue'
import financialPurchase from '../components/financial/financialPurchase.vue'
import financialRefund from '../components/financial/financialRefund.vue'
import financialIndicators from '../components/financial/financialIndicators.vue'
import financialRanking from '../components/financial/financialRanking.vue'
import financialExamine from '../components/financial/financialExamine.vue'

/* 课程-基础信息 */
import classManager from '../components/education/classManager.vue'
import addClass from '../components/education/form/addClass.vue'
import attendanceRecords from '../components/education/attendanceRecords.vue'

Vue.use(VueRouter)
const routes = [{
    path: "/",
    redirect: '/login',
    name: '/'
},
{
    path: '/login',
    component: login,
    name: ''
},
{
    path: '/home',
    component: home,
    redirect: '/index',
    name: 'Home',
    children: [
        /* ------------------------------工作台------------------------------------ */
        {
            path: '/index',
            component: index,
            name: '工作台'
        },
        /* ------------------------------系统管理------------------------------------ */
        {
            path: "/system/account/permission",
            component: permission,
            name: '权限管理'
        },
        {
            path: "/system/account/role",
            component: role,
            name: '角色管理'
        },
        {
            path: "/system/account/user",
            component: user,
            name: '教室管理'
        },

        {
            path: "/system/log/course",
            component: courseLog,
            name: '课程日志'
        },
        {
            path: "/system/log/class",
            component: classLog,
            name: '班级日志'
        },
        {
            path: "/system/log/attendance",
            component: attendanceLog,
            name: '考勤日志'
        },
        {
            path: "/system/notificationConfig",
            component: notificationConfig,
            name: '家长通知配置'
        },
        /* ------------------------------学员管理------------------------------------ */
        {
            path: "/student",
            component: student,
            name: '学员管理'
        },
        {
            path: "/student/form/studentRegister",
            component: studentRegister,
            name: ''
        },
        {
            path: '/student/form/studentCourseRegister',
            component: studentCourseRegister,
            name: ''
        },
        {
            path: '/student/form/appointCourse',
            component: studentappointCourse,
            name: ''
        },

        {
            path: "/student/StudentFile",
            component: studentFile,
            name: '学员档案'
        },
        {
            path: "/student/studentFileSingle",
            component: studentFileSingle,
            name: '',
            children: [{
                path: "/student/form/studentBasicInformation",
                component: studentbasicInformationRegister,
                name: ''
            },
            {
                path: "/student/form/studentClassInformation",
                component: studentClassInformation,
                name: ''
            },
            {
                path: "/student/form/studentFeeInformation",
                component: studentFeeInformation,
                name: ''
            },
            {
                path: "/student/form/studentCostLessonInformation",
                component: studentCostLessonInformation,
                name: ''
            },
            {
                path: "/student/form/studentRefundList",
                component: studentRefundList,
                name: ''
            }
            ]
        },
        {
            path: "/student/classHoursInfo",
            component: studentClassHoursInfo,
            name: ''
        },
        {
            path: "/student/impotExport",
            component: studentInAndOut,
            name: '导入/导出'
        },
        {
            path: "/student/studentTurnLists",
            component: studentTurnLists,
            name: '转赠课包记录'
        },
        {
            path: "/student/studentChange",
            component: studentChange,
            name: '课包变动记录'
        },

        /* ------------------------------机构管理------------------------------------ */
        {
            path: "/organization/classrooms",
            component: classrooms,
            name: '上课场地管理'
        },
        {
            path: "/organization/financialAllocation",
            component: financialAllocation,
            name: '财务申请'
        },
        {
            path: "/organization/charges",
            component: charges,
            name: '课程管理'
        },
        {
            path: '/organization/schoole',
            component: schoole,
            name: '校区管理'
        },
        {
            path: '/organization/classFee',
            component: classFee,
            name: '薪资标准'
        },
        {
            path: '/organization/salary',
            component: salary,
            name: '薪资管理'
        },
        {
            path: '/organization/salary-all',
            component: salaryAll,
            name: ''
        },
        {
            path: '/organization/salaryInfo',
            component: salaryInfo,
            name: ''
        },
        {
            path: "/organization/department",
            component: department,
            name: '部门管理'
        },
        {
            path: "/organization/departmentLevel",
            component: departmentLevel,
            name: '部门级别管理'
        },
        {
            path: "/organization/siteInfo",
            component: siteInfo,
            name: '选址信息'
        },
        /* ------------------------------教务管理------------------------------------ */
        {
            path: "/education",
            component: education,
            name: '教务管理'
        },
        {
            path: '/education/calendars',
            component: educationAddendanceRecord,
            name: '学员状况'
        },
        {
            path: '/education/student-state',
            component: educationStudentState,
            name: ''
        },
        {
            path: '/education/showClass/:id',
            component: educationShowClass,
            props: true,
            name: ''
        },
        {
            path: '/education/adjustment/:id',
            component: educationAdjustment,
            props: true,
            name: ''
        },
        {
            path: '/education/tempAddHour',
            component: educationTempAddHour,
            name: ''
        },
        {
            path: '/education/batchAdjustMakeUpHour',
            component: educationBbatchAdjustMakeUpHour,
            name: ''
        },
        {
            path: '/education/educationFastTypesetting',
            component: educationFastTypesetting,
            name: '快速排班'
        },

        {
            path: "/education/classManager",
            component: classManager,
            name: '班级管理'
        },
        {
            path: "/education/courseManager/addClass",
            component: addClass,
            name: ''
        },
        {
            path: "/education/attendanceRecords",
            component: attendanceRecords,
            name: '考勤记录'
        },
        {
            path: "/education/leaveRecord",
            component: leaveRecord,
            name: '请假记录'
        },

        /* ------------------------------数据分析------------------------------------ */
        {
            path: "/dataAnalysisBulletin",
            component: dataAnalysisBulletin,
            name: '数据看板'
        },
        {
            path: "/dataAnalysis",
            component: dataAnalysis,
            name: '数据分析'
        },

        /* ------------------------------消息------------------------------------ */
        {
            path: "/msg",
            component: msg
        },
        /*  ------------------------------财务管理------------------------------------ */
        {
            path: '/approval',
            component: approval,
            name: '财务列表1'
        },
        {
            path: '/financial',
            component: financialList,
            name: '财务列表'
        },
        {
            path: '/financial/config',
            component: financialAllocation,
            name: '财务配置'
        },
        {
            path: '/financial/financialReimbursement/:id',
            component: financialReimbursement,
            name: ''
        },
        {
            path: '/financial/financialLoan/:id',
            component: financialLoan,
            name: ''
        },
        {
            path: '/financial/financialPayment/:id',
            component: financialPayment,
            name: ''
        },
        {
            path: '/financial/financialInvoice/:id',
            component: financialInvoice,
            name: ''
        },
        {
            path: '/financial/financialPurchase/:id',
            component: financialPurchase,
            name: ''
        },
        {
            path: '/financial/financialRefund/:id',
            component: financialRefund,
            name: ''
        },
        {
            path: '/financial/financialExamine',
            component: financialExamine,
            name: '审批主体配置'
        },
        {
            path: '/financial/financialIndicators',
            component: financialIndicators,
            name: '校区经营指标'
        },
        {
            path: '/financial/financialRanking',
            component: financialRanking,
            name: '校区收入排名'
        }
    ]
}
]

/* 解決路由重复点击导致的报错 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes
})

/* 挂载路由守卫*/
router.beforeEach((to, from, next) => {
    if (to.path == '/login') return next();
    //获取token
    const token = window.sessionStorage.getItem('token');
    if (!token) return next('/login');
    if (to.name) {
        store.commit(INSERT_ROUTELIST, {
            path: to.path,
            name: to.name,
            query: to.query,
            params: to.params
        })
    }
    var routeLists = store.state.routeLists,
        selectRoute = store.state.selectRoute;
    var index = routeLists.findIndex(item => item.name == to.name);
    store.commit(SET_SELECTROUTE, index)
    next();
})
export default router