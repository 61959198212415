<template>
	<div class="login-container" :style="note">
		<div class="login-box">
			<div class="avatar-box">
				<img src="../assets/image/logo.svg" alt="">
				<div>小河码教务管理系统</div>
			</div>
			<!-- 登录表单 -->
			<el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" class="form-container">
				<!-- 账号 -->
				<el-form-item prop="username">
					<el-input prefix-icon="iconfont icon-user" v-model="loginForm.username" height="58px" placeholder="请输入您的账号"></el-input>
				</el-form-item>
				<!-- 密码-->
				<el-form-item prop="password">
					<el-input prefix-icon="iconfont icon-3702mima" v-model="loginForm.password" type="password" placeholder="请输入您的密码"></el-input>
				</el-form-item>
				<el-form-item class="btn-item">
					<!-- <el-button type="primary" @click="resetLoginForm">重置</el-button> -->
					<el-button type="primary" @click="login" v-preventReClick style="width: 380px;height:58px;border-radius: 8px;background-color: rgba(0, 159, 232, 1);"><span
						 style="font-size: 20px;">立即登录</span></el-button>
				</el-form-item>
			</el-form>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'Login',
		data() {
			return {
				loginForm: {
					username: "",
					password: ''
				},
				/* 表单验证 */
				loginFormRules: {
					username: [{
							required: true,
							message: '请输入账号',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 15,
							message: '账号长度3到15个字符',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 15,
							message: '密码长度3到15个字符',
							trigger: 'blur'
						}
					]
				},
				note: {
					backgroundImage: "url(" + require("../assets/image/bg.svg") + ")",
					backgroundRepeat: "no-repeat",
					"background-size": "cover"
				},
			}
		},
		methods: {
			/* 重置表单 */
			resetLoginForm: function() {
				this.$refs.loginFormRef.resetFields();
			},
			/* 登录 */
			login: function() {
				this.$refs.loginFormRef.validate(async result => {
					if (!result) return;
					/* true */
					const {
						data: res
					} = await this.$http.post("/login", this.loginForm);
					if (res.code == 200) {
						window.sessionStorage.setItem("token", res.data.token);
						window.sessionStorage.setItem("username", res.data.username);
						window.sessionStorage.setItem("name", res.data.name);
						window.sessionStorage.setItem("teacherID", res.data.id);
						res.data.schoole[0].check = true;
						window.sessionStorage.setItem('schoole', JSON.stringify(res.data.schoole));
						//默认设置展示第一个校区
						window.sessionStorage.setItem('checkSchoole', res.data.schoole[0].id);
						window.sessionStorage.setItem('role', res.data.role.role_id)
						this.$message.success("登录成功");

						this.$router.push("/index");
						return;
					}
					return this.$message.error("账号或密码错误");
				})
			}
		},
		
	}
</script>

<style lang="less" scoped>
	.login-container {
		width: 100%;
		height: 100%;

		.login-box {
			background-color: #FFFFFF;
			position: absolute;
			width: 400px;
			height: 300px;
			left: 28%;
			top: 40%;
			border-radius: 5px;
			transform: translate(-50%, -50%);

			.avatar-box {
				position: absolute;
				top: -58px;
				left: 20px;
				display: flex;
				align-items: center;

				>div {
					padding: 0px 10px;
					font-size: 30px;
					font-weight: bold;
				}

				>img {
					width: 68px;
				}
			}
		}
	}

	.btn-item {
		display: flex;
		justify-content: space-around;

		>div>button {
			margin: 5px 45px;
		}
	}

	.form-container {
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 10px;
		box-sizing: border-box;

		>.el-input__inner {
			width: 190px;
			height: 500px;
		}
	}

	/deep/ .el-input__inner {
		height: 58px;
		border-radius: 10px;
	}

	/deep/ input::-webkit-input-placeholder {
		font-size: 18px !important;
	}

	/deep/ input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		font-size: 18px !important;
	}

	/deep/ input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		font-size: 18px !important;
	}

	/deep/ input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		font-size: 18px !important;
	}
</style>
