<template>
	<div class="container">
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>机构管理</el-breadcrumb-item>
		  <el-breadcrumb-item>财务审核</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<!-- 报销申请 -->
			<div class="expense">
				<div class="header">
					<el-alert type="info" class="header-template" :closable="false">
						<div>报销申请</div>
					</el-alert>
				</div>
				<div class="body">
					<div class="body-content">
						<span>初审人:</span>
						<div>
							<el-select placeholder="请选择报销申请的初审人"  multiple v-model="expense.trial_ids" @change="expenseTrial">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>最终审核人:</span>
						<div>
							<el-select placeholder="请选择报销申请的最终审人"  multiple v-model="expense.final_trial_ids" @change="expenseFinal">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>抄送人:</span>
						<div>
							<el-select placeholder="请选择报销申请的抄送人"  multiple v-model="expense.Cc" @change="expenseCc">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<el-button type="primary" size="small" style="height: 40px;" @click="setApplyApplicationRequestApprover">确认</el-button>
				</div>
				
			</div>
			<!-- 借款申请 -->
			<div class="loan">
				<div class="header">
					<el-alert type="info" class="header-template" :closable="false">
						<div>借款申请</div>
					</el-alert>
				</div>
				<div class="body">
					<div class="body-content">
						<span>初审人:</span>
						<div>
							<el-select placeholder="请选择借款申请的初审人"  multiple v-model="loan.trial_ids " @change="loanTrial" :no-match-text="暂无" :no-data-text="暂无">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>最终审核人:</span>
						<div>
							<el-select placeholder="请选择借款申请的最终审人"  multiple v-model="loan.final_trial_ids" @change="loanFinal">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>抄送人:</span>
						<div>
							<el-select placeholder="请选择借款申请的抄送人"  multiple v-model="loan.Cc" @change="loanCc">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<el-button type="primary" size="small" style="height: 40px;" @click="setLoanApplicationRequestApprover">确认</el-button>
				</div>
			</div>
			<!-- 付款申请 -->
			<div class="payment">
				<div class="header">
					<el-alert type="info" class="header-template" :closable="false">
						<div>付款申请</div>
					</el-alert>
				</div>
				<div class="body">
					<div class="body-content">
						<span>初审人:</span>
						<div>
							<el-select placeholder="请选择付款申请的初审人"  multiple v-model="payment.trial_ids" @change="paymentTrial">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id" :collapse-tags="true">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>最终审核人:</span>
						<div>
							<el-select placeholder="请选择付款申请的最终审人"  multiple v-model="payment.final_trial_ids" @change="paymentFinal">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>抄送人:</span>
						<div>
							<el-select placeholder="请选择付款申请的抄送人"  multiple v-model="payment.Cc" @change="paymentCc">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<el-button type="primary" size="small" style="height: 40px;" @click="setPaymentRequestApprover">确认</el-button>
				</div>
			</div>
			
			<!-- 发票申请 -->
			<div class="invoice">
				<div class="header">
					<el-alert type="info" class="header-template" :closable="false">
						<div>发票申请</div>
					</el-alert>
				</div>
				<div class="body">
					<div class="body-content">
						<span>初审人:</span>
						<div>
							<el-select placeholder="请选择发票申请的初审人"  multiple v-model="invoice.trial_ids" @change="invoiceTrial">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>最终审核人:</span>
						<div>
							<el-select placeholder="请选择发票申请的最终审人"  multiple v-model="invoice.final_trial_ids" @change="invoiceFinal">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>抄送人:</span>
						<div>
							<el-select placeholder="请选择发票申请的抄送人"  multiple v-model="invoice.Cc" @change="invoiceCc">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<el-button type="primary" size="small" style="height: 40px;" @click="setInvoiceApplyRequestApprover">确认</el-button>
				</div>
			</div>
			<!-- 退费申请 -->
			<div class="refund">
				<div class="header">
					<el-alert type="info" class="header-template" :closable="false">
						<div>退费申请</div>
					</el-alert>
				</div>
				<div class="body">
					<div class="body-content">
						<span>初审人:</span>
						<div>
							<el-select placeholder="请选择发票申请的初审人"  multiple v-model="refund.trial_ids" @change="refundTrial">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>最终审核人:</span>
						<div>
							<el-select placeholder="请选择发票申请的最终审人"  multiple v-model="refund.final_trial_ids" @change="refundFinal">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>抄送人:</span>
						<div>
							<el-select placeholder="请选择发票申请的抄送人"  multiple v-model="refund.Cc" @change="refundCc">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<el-button type="primary" size="small" style="height: 40px;" @click="setRefundApplyRequestApprover">确认</el-button>
				</div>
			</div>	
			
			<!-- 采购申请 -->
			<div class="refund">
				<div class="header">
					<el-alert type="info" class="header-template" :closable="false">
						<div>采购申请</div>
					</el-alert>
				</div>
				<div class="body">
					<div class="body-content">
						<span>初审人:</span>
						<div>
							<el-select placeholder="请选择采购申请的初审人"  multiple v-model="purchase.trial_ids" @change="purchaseTrial">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>最终审核人:</span>
						<div>
							<el-select placeholder="请选择采购申请的最终审人"  multiple v-model="purchase.final_trial_ids" @change="purchaseFinal">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="body-content">
						<span>抄送人:</span>
						<div>
							<el-select placeholder="请选择采购申请的抄送人"  multiple v-model="purchase.Cc" @change="purchaseCc">
								<el-option v-for="item in teacher" :key="item.id" :label="item.name" :value="item.id">
									
								</el-option>
							</el-select>
						</div>
					</div>
					<el-button type="primary" size="small" style="height: 40px;" @click="setPurchaseApplyRequestApprover">确认</el-button>
				</div>
				
			</div>	
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'FinancialAllocation',
		data(){
			return {
				/* 可选择的教师 */
				teacher:[],
				/* 报销申请 */
				expense:{id:'',trial_ids:[],final_trial_ids:[],Cc:[]},
				/* 借款申请 */
				loan:{id:'',trial_ids:[],final_trial_ids:[],Cc:[]},
				/* 付款申请 */
				payment:{id:'',trial_ids:[],final_trial_ids:[],Cc:[]},
				/* 发票申请 */
				invoice:{id:'',trial_ids:[],final_trial_ids:[],Cc:[]},
				/* 退费申请 */
				refund:{id:'',trial_ids:[],final_trial_ids:[],Cc:[]},
				/* 采购申请 */
				purchase:{id:'',trial_ids:[],final_trial_ids:[],Cc:[]},
				/* 财务申请配置 */
				financialApplication:[]
			}
		},
		methods:{
			/* 获取可供选择的人 */
			async getExceptTeacherRole(){
				const {data:res} =  await this.$http.get('/organization/financial/getExceptTeacherRole');
				this.teacher = res.data;
				this.getFinancialApplicationConfig();
			},
			/* 设置报销申请 */
			async setApplyApplicationRequestApprover(){
				const {data:res} = await this.$http.patch('/organization/financial/approve',this.expense)
				if(res.code){
					this.$message.success('成功');
				}
			},
			/* 设置借款申请 */
			async setLoanApplicationRequestApprover(){
				const {data:res} = await this.$http.patch('/organization/financial/loan',this.loan);
				if(res.code){
					this.$message.success('成功');
				}
			},
			/* 设置付款申请 */
			async setPaymentRequestApprover(){
				const {data:res} = await this.$http.patch('/organization/financial/payment',this.payment);
				if(res.code){
					this.$message.success('成功');
				}
			},
			/* 设置发票申请 */
			async setInvoiceApplyRequestApprover(){
				const {data:res} = await this.$http.patch('/organization/financial/invoice',this.invoice);
				if(res.code){
					this.$message.success('成功');
				}
			},
			/* 设置退费申请 */
			async setRefundApplyRequestApprover(){
				const {data:res} = await this.$http.patch('/organization/financial/refund',this.refund);
				if(res.code){
					this.$message.success('成功');
				}
			},
			
			/* 设置退费申请 */
			async setPurchaseApplyRequestApprover(){
				console.log(this.purchase)
				const {data:res} = await this.$http.patch('/organization/financial/purchase',this.purchase);
				if(res.code){
					this.$message.success('成功');
				}
			},
			
			/* 获取所有财务申请 */
			async getFinancialApplicationConfig(){
				const {data:res} = await this.$http.get('/organization/financial/getFinancialApplicationConfig');
				this.financialApplication = res.data;
				
				this.loan.id = this.financialApplication.loan.id;
				this.loan.trial_ids = this.financialApplication.loan.trial_ids;
				this.loan.final_trial_ids = this.financialApplication.loan.final_trial_ids;
				this.loan.Cc = this.financialApplication.loan.Cc;
				
				this.payment.id = this.financialApplication.payment.id;
				this.payment.trial_ids = this.financialApplication.payment.trial_ids;
				this.payment.final_trial_ids = this.financialApplication.payment.final_trial_ids;
				this.payment.Cc = this.financialApplication.payment.Cc;
				
				this.expense.id = this.financialApplication.apply.id;
				this.expense.trial_ids = this.financialApplication.apply.trial_ids;
				this.expense.final_trial_ids = this.financialApplication.apply.final_trial_ids;
				this.expense.Cc = this.financialApplication.apply.Cc;
				
				this.invoice.id = this.financialApplication.invoice.id;
				this.invoice.trial_ids = this.financialApplication.invoice.trial_ids;
				this.invoice.final_trial_ids = this.financialApplication.invoice.final_trial_ids;
				this.invoice.Cc = this.financialApplication.invoice.Cc;
				
				this.refund.id = this.financialApplication.refund.id;
				this.refund.trial_ids = this.financialApplication.refund.trial_ids;
				this.refund.final_trial_ids = this.financialApplication.refund.final_trial_ids;
				this.refund.Cc = this.financialApplication.refund.Cc;
				
				this.purchase.id = this.financialApplication.purchase.id;
				this.purchase.trial_ids = this.financialApplication.purchase.trial_ids;
				this.purchase.final_trial_ids = this.financialApplication.purchase.final_trial_ids;
				this.purchase.Cc = this.financialApplication.purchase.Cc;
			},
			expenseTrial(val){
				this.expense.trial_ids = val;
			},
			expenseFinal(val){
				this.expense.final_trial_ids = val
			},
			paymentTrial(val){
				this.payment.trial_ids = val
			},
			paymentFinal(val){
				this.payment.final_trial_ids = val
			},
			loanTrial(val){
				this.loan.trial_ids = val
			},
			loanFinal(val){
				this.loan.final_trial_ids = val
			},
			invoiceTrial(val){
				this.invoice.trial_ids = val
			},
			invoiceFinal(val){
				this.invoice.final_trial_ids = val
			},
			expenseCc(val){
				this.expense.Cc = val
			},
			loanCc (val){
				this.loan.Cc = val
			},
			paymentCc(val){
				this.payment.Cc = val
			},
			invoiceCc(val){
				this.invoice.Cc = val
			},
			refundTrial(val){
				this.refund.trial_ids = val
			},
			refundFinal(val){
				this.refund.final_trial_ids = val
			},
			refundCc(val){
				this.refund.Cc = val
			}
		},
		created() {
			this.getExceptTeacherRole();
		}
	}
</script>

<style lang="less" scoped>
	.header{
		margin-bottom: 20px;
	}
	.header > .header-template {
		line-height: 30px;
	}
	.body{
		margin-left: 24px;
		display: flex;
	}
	.body-content{
		font-size: 15px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		margin-right: 12px;
		>span{
			margin-right: 10px;
		}
	}
	.el-select{
		width: 300px;
	}
</style>
