<!-- 课时信息表格 -->
<template>
	<div>
		<!-- 添加课时 -->
		<el-button type="primary" @click="click_add" class="mg-t-10" size="small">新增课时</el-button>
		<div class="mg-t-10">
			<el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="upData()"
			format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="small"></el-date-picker>
		</div>
		<!-- 表格 -->
		<el-table :data="tableData.data" @current-change="current_change" style="width: 100%" highlight-current-row class="fsMedia mg-t-10">
			<!-- 课时 -->
			<el-table-column prop="classHours" label="课时" min-width="80" fixed></el-table-column>
			<!-- 课时状态 -->
			<el-table-column prop="status" label="课时状态" min-width="180" v-if="showAddClassHours == 'bringUp'">
				<template v-slot="scoped">
					<span v-if="scoped.row.status == 0">未处理</span>
					<span v-if="scoped.row.status == 1">已考勤</span>
					<span v-if="scoped.row.status == 2">已处理</span>
				</template>
			</el-table-column>
			<!-- 班级 -->
			<el-table-column prop="className" label="所属班级" min-width="80" fixed v-if="showAddClassHours == 'callIn'"></el-table-column>
			<el-table-column label="操作" fixed="right" width="300" v-if="showOperation">
				<template v-slot="scoped">
					<el-button v-if="scoped.row.status != 1" size="small" type="primary" @click="click_adjustment(scoped.row)">调整到指定时间</el-button>					
					<el-button v-if="scoped.row.status != 1" size="small" type="primary" @click='clickPostpone(scoped.row)'>顺延</el-button>
					<el-button v-if="scoped.row.status != 1" size="small" type="primary" @click="click_del(scoped.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 表格/	 -->
		<!-- 分页 -->
		<template>
			<div class="block">
				<el-pagination background @current-change="handleCurrentChange" class="mg-t-10" :current-page.sync="tableData.current_page"
				 :page-size="10" layout="total, prev, pager, next" :total="tableData.total"></el-pagination>
			</div>
		</template>
		<!-- 分页/ -->

		<!-- <调整到指定时间对话框 -->
		<el-dialog title="调整课时" :visible.sync="dialogVisible_adjustment" width="30%"  v-show="dialogVisible_adjustment">
			<el-form ref="form" :model="form_adjustment" label-width="120px" :rules="rules_class">
				<el-form-item label="开班日期" prop="date">
					<div class="block">
						<el-date-picker v-model="form_adjustment.date" type="date" placeholder="选择日期" format="yyyy-MM-dd"
						 value-format="yyyy-MM-dd"></el-date-picker>
					</div>
				</el-form-item>
				<el-form-item label="上课开始时间" prop="time_start">
					<el-time-select v-model="form_adjustment.time_start" @change="form_adjustment.time_end = ''" :picker-options="{
                            start: '08:30',
                            step: '00:30',
                            end: '22:30'
                        }"
					 placeholder="开始时间"></el-time-select>
				</el-form-item>
				<el-form-item label="上课结束时间" prop="time_end">
					<el-time-select v-model="form_adjustment.time_end" :picker-options="{
                            start: '08:30',
                            step: '00:30',
                            end: '22:30',
                            minTime: form_adjustment.time_start
                        }"
					 placeholder="结束时间"></el-time-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible_adjustment = false">取 消</el-button>
				<el-button type="primary" @click="click_confirm">确 定</el-button>
			</span>
		</el-dialog>
		<!-- <调整到指定时间对话框/ -->
	</div>
</template>
<script>
	import vali from "../../assets/js/validate";
	export default {
		name: 'TableClassHour',
		// myid是班级id
		props: {
			id: {},
			myid: {},
			sid: {}, //学员id
			showAddClassHours: { //是否显示添加课时按钮 callIn bringUp
				type: String,
				default: 'bringUp'
			},
			showOperation: {  //是否显示操作
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				date: [],
				key: 'add',
				rules_class: {
					name: vali.validatorCommon.required_blur.concat(
						vali.validatorCommon.name_class
					),
					class_time: vali.validatorCommon.required_blur,
					date: vali.validatorCommon.required_blur,
					age_start: vali.validatorCommon.required_blur.concat(
						vali.validatorCommon.hours_class
					),
					age_end: vali.validatorCommon.required_blur.concat(
						vali.validatorCommon.hours_class
					),
					lesson: vali.validatorCommon.required_blur.concat(
						vali.validatorCommon.hours_class
					),
				},
				id_modify: "",
				// 调整课时对话框
				dialogVisible_adjustment: false,
				form_adjustment: {
					date: "",
					time_start: "",
					time_end: "",
				},
				tableData: [],
			};
		},
		methods: {
			// 添加课时按钮点击
			click_add() {					
				this.form_adjustment = {
					date: "",
					time_start: "",
					time_end: "",
				}
				this.dialogVisible_adjustment = true;
				this.key = 'add';
			},
			// 调整课时对话框确认
			click_confirm() {
				this.$refs.form.validate(async (err) => {
					if (!err) return;
					switch (this.key) {
						case 'add':
							if (await this.addClassHours(this.id || this.myid, 'post')) {
								this.dialogVisible_adjustment = false
								this.$message.success('添加课时成功');
								this.upData()
							}

							break;
						case 'modify':
							if (await this.addClassHours(this.id_modify, "patch")) {
								this.dialogVisible_adjustment = false;
								this.$message.success("修改课时成功");
								this.upData();
							}
							break;
						default:
							break;
					}
				});
			},
			current_change(e) {
				this.$emit("current_change", {
					id: e
				});
			},
			async click_del(e) {
				var isConfirm = await this.$confirm("您确认要删除这个课时吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).catch (e => {});
				if (isConfirm === "confirm" && (await this.delClassHours(e))) {
					this.$message.success("删除课时成功");
					this.upData();
				}
			},
			click_adjustment(e) {
				this.key = 'modify';
				this.form_adjustment = e;
				this.dialogVisible_adjustment = true;
				this.id_modify = e.id;
			},
			async clickPostpone (row) {
				var isConfirm = await this.$confirm('确定要顺延吗？', {
					type: 'warning'
				}).catch( err => {});
				if (isConfirm == 'confirm') {
					var {data: res} = await this.$http.patch('/education/classTimes/postPhone', {
						cid: row.cid,
						name: row.name,
						date: row.date,
						id: row.id
					});				
					if (res.code == 200) {
						this.$message.success('顺延成功');
						this.upData()
					}
				}
			},
			// 增加课时 // 调整课时
			async addClassHours(id, method) {
				// var params = {
				//     date: '',
				//     time_start: '',
				//     time_end: ''
				// }
				// param && (Object.assign(params, param))
				const {
					data: res
				} = await this.$http[method](
					`/education/classTime/${id}`,
					this.form_adjustment
				);
				if (res.code === 200) {
					return res.data;
				}
				this.$message.error(res.msg);
				return false
			},

			// 更新课时数据
			async upData(page) {
				var data = await this.getClassHours(this.id || this.myid, page);
				this.tableData = this.upData_before(data);
			},
			// 处理请求后台的数据 --- 主要处理课时，将上课日期 和 上课日（周几）和上课时间合并
			upData_before(data) {
				// 处理课时
				var d = data.data;
				var arr = [];
				for (var i = 0; i < d.length; i++) {
					var obj = {
						classHours: d[i].date +
							" " +
							d[i].name +
							" " +
							d[i].time_start +
							"-" +
							d[i].time_end,
						status: d[i].status,
						id: d[i].id,
						date: d[i].date,
						time_start: d[i].time_start,
						time_end: d[i].time_end,
						name: d[i].name,
						cid: d[i].cid,
						className: d[i].className
					};
					arr.push(obj);
				}
				// 处理分页
				var obj1 = data;
				obj1.data = arr;
				return obj1;
			},

			// 当前页码改变
			handleCurrentChange(e) {
				this.upData(e);
			},

			// 删除课时
			async delClassHours(id) {
				var params = {
					date: id.date,
					time_start: id.time_start,
					time_end: id.time_end,
				};
				const {
					data: res
				} = await this.$http.delete(
					`/education/classTime/${id.id}`, {
						params,
					}
				);
				if (res.code === 200) {
					return res.data;
				}
				return false;
			},
			// 获取课时信息
			async getClassHours(id, page) {				
				/* 如果是调入课时就用这个 */
				var params = {
					page: page ? page : 1,
					date_start: '',
					date_end: ''
				};
				if (this.date != null) {
					(this.date.length > 0) && (params.date_start = this.date[0], params.date_end = this.date[1])
				}
				/* 如果是调出课时就用这个 */
				if (this.showAddClassHours == 'bringUp') {
					const {
						data: res
					} = await this.$http.get(
						`/education/classTime/${id}?page=${params.page}&date_start=${params.date_start}&date_end=${params.date_end}`
					);
					if (res.code === 200) {
						return res.data;
					}
				} else if (this.showAddClassHours == 'callIn') {
					const {
						data: res
					} = await this.$http.get(
						`/education/attendance/student?sid=${this.sid}&page=${params.page}`
					);
					if (res.code === 200) {
						return res.data;
					}
				}				
			},
		},
		components: {},
		created() {
			(this.id || this.myid) && this.upData();
			// this.id = this.myid ? this.myid: this.id
			this.$emit("upData", {
				func: this.upData
			});
		}
	};
</script>
<style scoped lang="less">
</style>
