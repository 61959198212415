/**
@describe 课包变动记录
@date 2021-04-21
@by LZW
*/
<template>
  <div>
    <el-row>
      <el-col :span="3">
        <el-select v-model="screen.course_type" size="small" style="width: 100%;" placeholder="请选择课程类型" clearable>
          <el-option v-for="(item, index) in typeOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" style="padding: 0 10px;">
        <el-date-picker clearable v-model="screen.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" style="width: 100%" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
      </el-col>
      <el-col :span="5" style="padding-right:10px;">
        <el-input size="small" clearable v-model="screen.key" placeholder="请输入学员ID、姓名、联系电话">
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="screenChange">查询</el-button>
      </el-col>
    </el-row>
    <el-table stripe :data="tableData" size="small" class="mg-t-10">
      <el-table-column align="center" prop="sid" label="学员ID"></el-table-column>
      <el-table-column align="center" prop="name" label="姓名"></el-table-column>
      <el-table-column align="center" prop="type" label="课程类型"></el-table-column>
      <el-table-column align="center" prop="course_price" label="课包价格"></el-table-column>
      <el-table-column align="center" prop="paid_amount" label="实际支付"></el-table-column>
      <el-table-column align="center" prop="lesson" label="课时总数"></el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
      <el-table-column align="center" min-width="80" prop="course_name" label="课程"></el-table-column>
      <el-table-column align="center" min-width="80" prop="course_price_name" label="课包"></el-table-column>
      <el-table-column align="center" min-width="100" prop="replace_type_desc" label="变动描述"></el-table-column>
      <el-table-column align="center" min-width="110" label="查看" fixed="right">
        <template v-slot="scoped">
          <el-button type="primary" size="mini" @click="viewInfo(scoped.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <loading-c :show-loading="loading"></loading-c>
    <el-pagination background layout="total, prev, pager, next" :current-page.sync="screen.page" :total="pagination.total" class="mg-t-10" @current-change="getCourseChangeLists">
    </el-pagination>
  </div>
</template>

<script>
import {
  COURSE_TYPE
} from '../../assets/js/CONFIG/course.js'
import {
  getCourseChangeLists
} from "../../assets/js/API/course.js"
import loadingC from "../common/loading.vue";
export default {
  name: 'StudentChange',
  components: {
    loadingC,
  },
  data() {
    return {
      loading: false,
      screen: {
        page: 1,
        key: "",
        date: [],
        course_type: ""
      },
      tableData: [],
      pagination: {
        total: 0,
      },
      typeOptions: COURSE_TYPE,
    };
  },
  created() {
    this.getCourseChangeLists();
  },
  methods: {
    viewInfo(row) {
      this.$router.push(
        "/student/studentFileSingle?id=" +
        row.sid +
        "&name=" +
        row.name
      );
    },
    screenChange() {
      this.screen.page = 1;
      this.getCourseChangeLists()
    },
    getCourseChangeLists() {
      var params = {
        ...this.screen
      };
      params.date &&
        params.date.length == 2 &&
        ((params.date_start = params.date[0]),
          (params.date_end = params.date[1]));
      delete params.date;
      this.loading = true;
      getCourseChangeLists.call(this, params)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.tableData = res.data;
            this.pagination.total = res.total
          }
        })
        .catch((err) => (this.loading = false));
    }
  },
};
</script>

<style scoped>
</style>
