<template>
	<!-- 学员登记- 学员基本信息 -->
	<div class="container">		
		<el-card>
			<div>
				<el-breadcrumb separator="/" class="fs-12">
				  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
				  <el-breadcrumb-item>学员管理</el-breadcrumb-item>
				  <el-breadcrumb-item>学员档案</el-breadcrumb-item>
				</el-breadcrumb>
				<!-- 必填项 -->
				<div class="header">
				<span style="color: red;">* &nbsp;<span style="color:  #81d3f8;">必填项</span></span>
				<span style="color: red;">* &nbsp;<span style="color:  #81d3f8;">选填项</span></span>
				</div>
				<!-- form -->
				<div class="body-container">
					<div class="body">
						<el-form   :model="mustInfo"  ref="mustInfoRef">
							<!-- <el-form-item label="学员头像">
								<el-upload
								class="upload-demo"
								action="https://upload-z2.qiniup.com"

								>
								<img :src="imgUrl" />
								</el-upload>
							</el-form-item>							 -->
							<!-- 学员姓名 -->
							<el-form-item label="学员姓名" prop="studentName" :rules="mustInfoValidate.studentName">
								<el-input type="text" placeholder="请输入学员姓名" v-model="mustInfo.studentName"></el-input>
							</el-form-item>
							<el-form-item label="学员年龄" prop="studentAge" :rules="mustInfoValidate.studentAge">
								<el-input type="text" placeholder="请输入学员年龄" v-model.number="mustInfo.studentAge"
								 @blur="blurAge"
								></el-input>
							</el-form-item>
							<!-- 学员性别 -->
							<el-form-item label="学员性别" prop="studentSex" :rules="mustInfoValidate.studentSex">
								<el-select v-model="mustInfo.studentSex" @change="change_select_sex">
									<el-option v-for="(item) in sex" :label="item.name" :value="item.val">	
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="监护人电话" prop="parentTel" :rules="mustInfoValidate.parentTel">
								<el-input type="text" placeholder="请输入家长的电话" v-model="mustInfo.parentTel"></el-input>
							</el-form-item>
							<!-- 家长角色 -->
							<el-form-item label="家长角色" prop="parentRole" :rules="mustInfoValidate.parentRole">
								<el-select v-model="mustInfo.parentRole">
									<el-option v-for="(item) in roles" :label="item.name" :value="item.val">	
									</el-option>
								</el-select>
							</el-form-item>
							<!-- 上课日期 -->
							<el-form-item label="上课日期" prop="lesson_time" :rules="mustInfoValidate.lessonTime">
								<el-date-picker
								  type="date"
								  placeholder="选择上课日期"
								  format="yyyy-MM-dd"
								  value-format="yyyy-MM-dd"
								  v-model="mustInfo.lesson_time"
								  >
								</el-date-picker>
							</el-form-item>
							</el-form-item>
							
							<el-form-item label="学员备注">
								<el-input type="text" placeholder="请输入学员的备注" v-model="mustInfo.remark"></el-input>
							</el-form-item>
						</el-form>
					<!-- form -->
					</div>
				<!-- 选填项 -->
				<div class="body">
					<el-form  class="form-flex" :model="optionInfo"  ref="optionInfoRef">
						<el-form-item label="学员昵称" prop="studentNickName" :rules="optionInfoValidate.studentNickName">
							<el-input type="text" placeholder="请输入学员的昵称" v-model="optionInfo.studentNickName"></el-input>
						</el-form-item>
						<el-form-item label="学员生日" prop="studentBirthday" :rules="optionInfoValidate.studentBirthday">
							 <el-date-picker
								  type="date"
								  placeholder="选择日期"
								  format="yyyy-MM-dd"
								  value-format="yyyy-MM-dd"
								  v-model="optionInfo.studentBirthday"
								  @change="changeBirth"
								  >
							 </el-date-picker>
						</el-form-item>
						<el-form-item label="学员来源">
							 <el-select v-model="optionInfo.studentSource">
								 <el-option v-for="(item) in source" :label="item.name" :value='item.val'
								 ></el-option>	
							 </el-select>
						</el-form-item>
						<el-form-item label="监护人姓名" prop="parentName" :rules="optionInfoValidate.parentName">
							<el-input type="text" placeholder="请输入监护人姓名" v-model="optionInfo.parentName"></el-input>
						</el-form-item>
						<el-form-item label="证件类型">
							 <el-select v-model="optionInfo.certType">
								 <el-option v-for="(item) in certType" :label="item.name" :value="item.val"
								 ></el-option>	
							 </el-select>
						</el-form-item>
						<el-form-item label="证件号码">
							 <el-input type="text" placeholder="请输入证件号码" v-model="optionInfo.certId"></el-input>
						</el-form-item>
						<el-form-item label="家庭住址">
							<el-cascader
							size="large"
							:options="options"
							@focus="focus"
							v-model="selectedOptions"
							:value="optionInfo.address"
							@change="handleChange()"
							:placeholder="optionInfo.address.toString()"
							>
							</el-cascader>
							
						</el-form-item>
						<el-form-item label="详细住址">
							<el-input type="text" placeholder="请输入详细的家庭地址" v-model="optionInfo.detail"></el-input>
						</el-form-item>
					</el-form>
				</div>
				</div>
			</div>
			<div class=" text-align"><el-button type="primary" @click="saveInfo()" style="width: 280px;">保存</el-button></div> 
		</el-card>
	</div>
</template>

<script>
	import $param from '../../../assets/js/params.js' 
	/* 引入验证 */
	import validates from '../../../assets/validate/student/studentRegisterForm/studentBasicInformationvalidate.js'
	import { getDate, getDetail_date } from '../../../assets/js/myMethods.js'
	export default{
		name: 'StudentInfo',
		props: ['studentId'],
		data(){
			return{
				imgUrl: '',
				options:[],
				codeText:'',
				selectedOptions:[],
				selectOptionsName:[],
				sex:$param.sex,
				roles:$param.roles,
				source:$param.source,
				certType:$param.certType,
				/* 必填信息 */
				mustInfoValidate:'',
				mustInfo:{
					studentName:'',
					studentAge:'',
					studentSex:'',
					parentTel:'',
					parentRole:'',
					lesson_time: '',
					remark:''
				},
				/* 选填信息 */
				optionInfoValidate:'',
				optionInfo:{
					studentAvator:'',
					studentNickName:'',
					studentBirthday:'',
					studentSource:1,
					parentName:'',
					certType:1,
					certId:'',
					address:[],
					detail:'',
					guardian:'',
				},
				sid:0
			}
		},
		created() {
			this.mustInfoValidate = validates.mustValidate;
			this.optionInfoValidate = validates.optionValidate;
			this.getStudentBasicInfo();
		},
		methods:{
			blurAge () {
				var dateObj = getDetail_date(getDate()),
					val = String(this.mustInfo.studentAge).length == 0 ? 0 : this.mustInfo.studentAge
				this.optionInfo.studentBirthday = dateObj.year - val + '-' + dateObj.mouth + '-' + dateObj.day
			},
			changeBirth () {
				var dateObjToday = getDetail_date(getDate()),
					dateObjBrith = getDetail_date(this.optionInfo.studentBirthday);
				this.mustInfo.studentAge = dateObjToday.year - dateObjBrith.year
			},
			change_select_sex (val) {
				switch (val) {
					case 1:
						this,this.optionInfo.studentAvator = 'http://v.hipposteam.com/man.svg'
						break;
					case 2:
						this,this.optionInfo.studentAvator = 'http://v.hipposteam.com/woman.svg'
						break;
					default:
						break
				}
			},		
			/* 保存基本信息 */
			async saveInfo(){
				/* 如果 没有学员id 则证明是添加操作 */
				if(typeof(this.sid) == 'undefined'){
					this.$refs.mustInfoRef.validate(async (bool)=>{
						if(!bool) return false;
						const {data:res} = await this.$http.post('student/info',{"must":this.mustInfo,"optionInfo":this.optionInfo});
						if(res.code == 200){
							this.$message.success("添加学员成功");
							this.$router.push('/student')
						}
					})
				}else{
					/* 否则是修改操作 */
					let data = {
						id:this.sid,
						avatar:this.optionInfo.studentAvator,
						name:this.mustInfo.studentName,
						age:this.mustInfo.studentAge,
						sex:this.mustInfo.studentSex,
						tel:this.mustInfo.parentTel,
						role:this.mustInfo.parentRole,
						guardian:this.optionInfo.guardian,
						nickname:this.optionInfo.studentNickName,
						birthday:this.optionInfo.studentBirthday,
						source:this.optionInfo.studentSource,
						cert_id:this.optionInfo.certId,
						cert_type:this.optionInfo.certType,
						parentName:this.optionInfo.parentName,
						province:this.optionInfo.address[0],
						city:this.optionInfo.address[1],
						district:this.optionInfo.address[2],
						detail:this.optionInfo.detail,
						lesson_time: this.mustInfo.lesson_time,
						remark:this.mustInfo.remark
					}
					this.$refs.mustInfoRef.validate(async (bool)=>{
						if(!bool) return false;
						const {data:res} = await this.$http.patch('student/info/'+this.sid,data);
						if(res.code == 200){
							this.$message.success("修改学员成功");
						}
					})
				}
			},
			/* 获取学员的详细信息 */
			async getStudentBasicInfo(){
				this.sid = this.studentId ? this.studentId : this.$route.query.id;
				if(typeof(this.sid) == 'undefined') return;
				const {data:res} = await this.$http.get('student/info/'+this.sid);
				if(res.code == 200){
					/* 必填 */
					this.mustInfo.studentName  = res.data.student.name;
					this.mustInfo.studentAge = res.data.student.age;
					this.mustInfo.studentSex = res.data.student.sex;
					this.mustInfo.parentTel = res.data.parent.tel;
					this.mustInfo.parentRole = res.data.parent.role;
					this.mustInfo.lesson_time = res.data.student.lesson_time
					/* 选填 */
					this.optionInfo.studentAvator = res.data.student.avatar;
					this.optionInfo.studentNickName = res.data.student.nickname;
					this.optionInfo.studentBirthday = res.data.student.birthday;
					this.optionInfo.studentSource = res.data.student.source
					this.optionInfo.certId = res.data.student.cert_id;
					this.optionInfo.certType = res.data.student.cert_type;
					this.optionInfo.parentName = res.data.parent.name;
					this.optionInfo.detail = res.data.student.detail;
					this.optionInfo.address = [res.data.student.province,res.data.student.city,res.data.student.district]
					this.optionInfo.guardian = res.data.student.guardian;
					this.mustInfo.remark = res.data.student.remark
				}
			},
			focus(){
				this.options = this.$regionData;
				this.codeText = this.$CodeToText;
			},
			/* 城市选择 */
			handleChange() {
			  for (let i = 0; i < this.selectedOptions.length; i++) {
				  this.selectOptionsName[i] =this.codeText[this.selectedOptions[i]];
			  }
			  this.optionInfo.address = this.selectOptionsName;
			},
		}
	}
</script>

<style lang="less" scoped>
	.container{
		// height: 100%;
		max-width: 100% !important;
		> .el-card,div{
			height: 100%;
		}
	}
	.header{
		color: #81d3f8;
		margin-bottom: 10px;
		display: flex;
		padding: 0px 0px;
		justify-content: space-between;
	}
	img{
		width: 80px;
	}
	.body-container{
		display: flex;
		
		height: 100%;
	}
	.form-flex{
		box-sizing: border-box;
	}
	.el-input,.el-select,.el-cascader{
		width: 100% !important;
	}
	.footer-button{
		display: flex;
		justify-content: flex-end;
	}
	.body{
		width: 50%;
		padding: 0 20px;
		height: 100px;
		overflow-y: auto;
	}
</style>
