<template>
	<div>
		<!-- 消息列表 详情表格 -->
		<el-table :data="tableData" style="width: 100%" stripe @row-click.capture="showMessageInfo">
			<!-- 消息内容 -->
			<el-table-column prop="content" label="消息详情(点击查看详情)" min-width="180">
				<template v-slot="scope">
					<div class="flex align-center">
						<div style="
						  width: 50px;
						  height: 50px;
						  background-color: #fed857;
						  line-height: 50px;
						  border-radius: 50%;
						"
						 class="text-align-c">
							<img src="../../../assets/image/logo.svg" style="width: 25px; vertical-align: middle" />
						</div>
						<div class="flex-column mg-l-10">
							<div class="flex justify-between" style="width: 400px;">
								<div class="flex fs-14">
									<span class='tag' :class="{read: scope.row.is_read == 2, noRead: scope.row.is_read == 1}">{{scope.row.is_read == 1 ? '未读' : '已读'}}</span>
									<span style="" class="">{{type[scope.row.type]}}</span>
								</div>						
								<span>{{scope.row.created_at}}</span>
							</div>
							<div class="fs-16" style="margin-top: 5px;">{{scope.row | getContent}}</div>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<el-pagination :current-page="pagitionP.page" :page-size="pagitionP.number" :total="pagitionP.total" background layout="total, prev, pager, next" class="mg-t-10"
		@current-change='handleCurrentChange'>
		</el-pagination>
		<!-- 消息详情 -->
		<el-dialog title="通知详情" :visible.sync="dialogVisible" width="50%" center @close="getMsgInfo">
			<!-- 家长请假 -->
			<div class="" v-if="msgInfo.type == 1">
				<div class="flex align-center justify-between">
					<div class="flex align-center">
						<div style="
                width: 40px;
                height: 40px;
                background-color: #fed857;
                line-height: 40px;
                border-radius: 6px;
              "
						 class="text-align-c">
							<img src="../../../assets/image/logo.svg" style="width: 25px; vertical-align: middle" />
						</div>
						<span class="mg-l-10">{{msgInfo.from.student_name}}</span>
					</div>
					<span>{{msgInfo.created_at}}</span>
				</div>
				<div class="" style="margin-top: 15px">
					<div class="flex" style="margin-top: 6px">
						<span style="width: 40px">班级:</span>
						<span style="">{{msgInfo.content.class.name}}</span>
					</div>
					<div class="flex" style="margin-top: 6px">
						<span style="width: 40px">课时:</span>
						<span style="">{{msgInfo.content.attendance | getClassTime}}</span>
					</div>
					<div class="flex" style="margin-top: 6px">
						<span style="width: 40px">事由:</span>
						<span style="color: #009fd4">{{msgInfo.content.content}}</span>
					</div>
				</div>
			</div>
			<!-- 家长缴费 -->
			<div v-if="msgInfo.type == 2">
				<div class="flex align-center">
					<div style="
					  width: 40px;
					  height: 40px;
					  background-color: #fed857;
					  line-height: 40px;
					  border-radius: 6px;
					"
					 class="text-align-c">
						<img src="../../../assets/image/logo.svg" style="width: 25px; vertical-align: middle" />
					</div>
					<div class="mg-l-10">
						<span style="font-weight: bold">家长缴费</span>
						<span>{{msgInfo.created_at}}</span>
					</div>
				</div>
				<span class="" style="margin-top: 10px; color: #009fd4">
					学员{{msgInfo.from.student_name}}缴纳了一笔费用，请知悉！
				</span>
			</div>
			<!-- 课时不足 -->
			<div v-if="(msgInfo.type == 10 || msgInfo.type == 9)">
				<div class="flex align-center">
					<div style="
              width: 40px;
              height: 40px;
              background-color: #fed857;
              line-height: 40px;
              border-radius: 6px;
            "
					 class="text-align-c">
						<img src="../../../assets/image/logo.svg" style="width: 25px; vertical-align: middle" />
					</div>
					<div class="mg-l-10">
						<span style="font-weight: bold">{{msgInfo.type == 10 ? '课时不足' : '有效期不足'}}</span>
						<span>{{msgInfo.created_at}}</span>
					</div>
				</div>
				<span class="" style="margin-top: 10px; color: #009fd4">
					{{msgInfo | getContent}}
				</span>
				<div class="" style="margin-top: 15px">
					<div style="margin-top: 6px" v-if="msgInfo.type == 10">
						剩余课时: <span>{{msgInfo.content.message.lave_lesson}}次</span>
					</div>
					<div style="margin-top: 6px" v-else>
						剩余天数: <span>{{msgInfo.content.message.lave_expriation}}天</span>
					</div>
					<!-- <div style="margin-top: 6px">
						截止时间: <span>2020-10-18 18：30-20：30</span>
					</div> -->
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	/* API */
	import {
		getMsg
	} from "../../../assets/js/API/msg.js";
	/* 消息配置 */
	import {
		tab,
		type,
		getContent
	} from '../../../assets/js/msgOption.js'
	export default {
		name: 'MsgList',
		props: ["dataTable", 'pagition'],
		data() {
			return {
				type: type,
				dialogVisible: false, //消息通知详情对话框显示
				tableData: [],
				pagitionP: {},
				msgInfo: {} //消息详情
			};
		},
		methods: {
			handleCurrentChange (page) {
				this.$attrs.getMsg(this.msgInfo.type, page)
			},
			getMsgInfo () {
				/* if (this.msgInfo.is_read == 2) return; */
				this.$attrs.getMsg(tab[this.msgInfo.type])
			},
			async showMessageInfo(currentRow) {
				if (currentRow.is_read == 2) {
					this.msgInfo = {...currentRow};					
					this.dialogVisible = true;
					return;
				}
				var { data: res } = await this.$http.put('/notice/new', {
					id: currentRow.id,
					is_read: 2
				})
				if (res.code == 0) {
					this.msgInfo = {...currentRow}
					this.dialogVisible = true;
				} else {
					this.$message.error('网络繁忙,查看消息详情失败')
				}
			},
		},
		watch: {
			dataTable: function(val) {
				if (this.dataTable instanceof Array) {
					this.tableData = [...val];
				}
			},
			pagition: function (val) {
				this.pagitionP = val
			}
		},
		filters: {
			getContent(val) { return getContent(val) },
			getClassTime (val) {
				return val.date + ' ' + val.time_start + '-' + val.time_end
			}
		},
		created() {
			if (this.dataTable instanceof Array) {
				this.tableData = [...this.dataTable];
			};
			this.pagitionP = this.pagition
		}
	};
</script>

<style scoped lang="less">
	.tag{
		margin-right: 10px;
		display: inline-block;
		padding: 0px 6px;
		border-radius: 4px;
		font-size: 12px;		
	}
	.read {
		background-color: #dfdfdf;
		color: #b3b3b3;
	}
	.noRead{
		background-color: #f76429;
		color: white;
	}
</style>
