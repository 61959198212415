/* **********************************费用API***************************** */
export async function getCharge (id) {
	//收费标准id
	var url = id ? '/organization/charges/' + id : '/organization/charges'
	var { data: res } = await this.$http.get(url);
	if (res.code == 200) {
		return res.data
	} else {
		this.$message.error('获取收费标准失败')
	}
}

export async function getStudentRefund (sid) {
	//学员id
	var { data: res } = await this.$http.get('/student/refund/build/message?sid=' + sid);
	if (res.code == 0) {
		return res.data
	}
}


export async function refund (params) {
	/* 太多了，没有写，去看接口文档 */
	var { data: res } = await this.$http.post('/student/refund', params);
	if (res.code == 0) {
		this.$message.success('退费成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function getRefundList (sid) {
	var { data: res } = await this.$http.get('/student/refund/list?sid=' + sid);
	if (res.code == 0) {
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

