<template>
	<!-- 班级管理 -->
	<div class="">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>教务管理</el-breadcrumb-item>
			<el-breadcrumb-item>班级管理</el-breadcrumb-item>
		</el-breadcrumb>
		<class-c :operation='true'></class-c>
	</div>
</template>

<script>
	import classC from '../common/iteration-class-table.vue'
	export default {
		name: 'ClassManger',
		data() {
			return {
				
			}
		},
		created() {
		},
		methods: {
			
		},
		filters: {

		},
		components: {
			classC
		}
	}
</script>

<style scoped>

</style>
