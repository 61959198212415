<template>
  <el-container class="home-container">
    <el-header>
      <div class="header-left">
        <img src="../assets/image/logo.svg" />
        <span style="font-size: 18px; color: #000000" class="mg-l-10"
          >小河码教务管理系统</span
        >
        <!-- 切换校区 -->
        <el-select
          size="medium"
          class="mg-l-20 selectHome"
          style="margin-left: 20px; width: 150px; max-width: 200px"
          v-model="schoolId"
          @change="entry"
        >
          <el-option
            v-for="item in schoolIdOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-right">
        <div class="flex align-center cursor-p" @click="showMSG = !showMSG">
          <div
            class="el-icon-message-solid"
            style="font-size: 30px; color: #333744"
          ></div>
          <div
            style="
              background-color: rgb(247 100 41);
              padding: 1px 5px;
              border-radius: 5px;
              padding: 2px 5px;
              color: white;
              margin-left: -8px;
              margin-top: -10px;
            "
            class="fs-14 c-white"
            v-show="msgInfo.total > 0"
          >
            {{ msgInfo.total }}
          </div>
        </div>
        <div class="mg-l-10">欢迎您，{{ name }}</div>
        <div class="mg-lr-10">|</div>
        <div style="cursor: pointer" @click="loginOut">退出登录</div>
      </div>
    </el-header>
    <el-container class="body">
      <!-- 左侧菜单栏-->
      <el-aside :width="isCollapse ? '64px' : '170px'">
        <div
          class="tolggel-button"
          @click="toggleCollapse"
          style="text-align: center"
        >
          <i class="el-icon-menu"></i>
        </div>
        <el-menu
          :default-active="activePath"
          class="el-menu-vertical-demo"
          background-color="#333744"
          text-color="#fff"
          :collapse-transition="false"
          active-text-color="#578afe"
          unique-opened
          :collapse="isCollapse"
          :router="true"
        >
          <!-- 一级菜单-->
          <el-submenu
            :index="'/' + list.name"
            v-for="list in menuList"
            :key="list.id"
            v-if="list.list"
            @dblclick="toNewBlank"
          >
            <template slot="title">
              <div @click="index(list.name)">
                <div @dblclick="toNewBlank">
                  <i :class="list.icon ? list.icon : ''"></i>
                  <span>{{ list.title }}</span>
                </div>
              </div>
            </template>
            <!-- 二级菜单  有子-->
            <el-submenu
              :index="'/' + item.name"
              v-for="item in list.list"
              :key="item.id"
              v-if="item.list"
              @dblclick="toNewBlank"
            >
              <template v-if="item.list">
                <template slot="title">
                  <div @dblclick="toNewBlank">
                    <i :class="item.icon ? item.icon : ''"></i>
                    <span>{{ item.title }}</span>
                  </div>
                </template>
                <el-menu-item
                  :index="'/' + item_next.name"
                  v-for="item_next in item.list"
                  :key="item_next.id"
                  @click="saveNavPath('/' + item_next.name)"
                >
                  <div @dblclick="toNewBlank">{{ item_next.title }}</div>
                </el-menu-item>
              </template>
            </el-submenu>
            <!-- 二级标题 ，无子-->
            <el-menu-item
              :index="'/' + item.name"
              @click="saveNavPath('/' + item.name)"
              v-else
              @dblclick="toNewBlank"
            >
              <div @dblclick="toNewBlank">{{ item.title }}</div>
            </el-menu-item>
          </el-submenu>
          <!-- 一级标题-->
          <el-menu-item
            :index="'/' + list.name"
            v-else
            @click="saveNavPath('/' + list.name)"
          >
            <div @dblclick="toNewBlank">
              <i :class="list.icon ? list.icon : ''"></i>
              <span @dblclick="toNewBlank">{{ list.title }}</span>
            </div>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <!-- 菜单主体 -->
      <el-main>
        <!-- 这边加上小导航 -->
        <navigation-c :default-show="true"></navigation-c>
        <transition>
          <keep-alive :exclude="exclude">
            <router-view></router-view>
          </keep-alive>
        </transition>
        
      </el-main>
      <!-- 消息通知 -->
      <msg-c
        style="position: absolute; right: 0; width: 550px; z-index: 999"
        :showCloseIcon="true"
        v-show="showMSG"
        :msgInfo="msgInfo"
        @close="showMSG = false"
      >
        <!-- 家长请假 -->
        <template v-slot:leave="{ msgList }">
          <!-- 暂无消息 -->
          <div
            class="text-align-c"
            style="color: #aaa"
            v-if="msgList.length == 0"
          >
            暂无请假消息
          </div>
          <div
            class="flex align-center justify-between fs-14 cursor-p"
            :class="{ 'mg-t-10': index != 0 }"
            @click="navigationTo('/msg?tab=leave')"
            v-for="(item, index) in msgList"
            :key="index"
          >
            <div class="flex align-center flex-1">
              <div style="color: #fed90e">【请假提醒】</div>
              <span class="textNoWarp flex-1_1"
                >学员{{ item.from.student_name }}的家长已对{{
                  item.content.class.name
                }}进行请假</span
              >
            </div>
            <span class="mg-l-10">{{ item.created_at }}</span>
          </div>
        </template>
        <!-- 家长缴费 -->
        <template v-slot:cost="{ msgList }">
          <!-- 暂无消息 -->
          <div
            class="text-align-c"
            style="color: #aaa"
            v-if="msgList.length == 0"
          >
            暂无缴费消息
          </div>
          <div
            class="flex align-center justify-between fs-14 cursor-p"
            :class="{ 'mg-t-10': index != 0 }"
            v-for="(item, index) in msgList"
            :key="index"
            @click="navigationTo('/msg?tab=cost')"
          >
            <div class="flex align-center flex-1">
              <div style="color: #fed90e">【缴费提醒】</div>
              <span class="textNoWarp flex-1_1"
                >学员{{ item.from.student_name }}的家长缴费了,请知悉</span
              >
            </div>
            <span class="mg-l-10">{{ item.created_at }}</span>
          </div>
        </template>
        <!-- 有效期不足 -->
        <template v-slot:term="{ msgList }">
          <!-- 暂无消息 -->
          <div
            class="text-align-c"
            style="color: #aaa"
            v-if="msgList.length == 0"
          >
            暂无有效期消息
          </div>
          <div
            class="flex align-center justify-between fs-14 cursor-p"
            :class="{ 'mg-t-10': index != 0 }"
            v-for="(item, index) in msgList"
            :key="index"
            @click="navigationTo('/msg?tab=term')"
          >
            <div class="flex align-center flex-1">
              <div style="color: #fed90e">【有效期不足】</div>
              <span class="textNoWarp flex-1_1">{{ item | getContent }}</span>
            </div>
            <span class="mg-l-10">{{ item.created_at }}</span>
          </div>
        </template>
        <!-- 课时不足 -->
        <template v-slot:classTime="{ msgList }">
          <!-- 暂无消息 -->
          <div
            class="text-align-c"
            style="color: #aaa"
            v-if="msgList.length == 0"
          >
            暂无课时不足消息
          </div>
          <div
            class="flex align-center justify-between fs-14 cursor-p"
            :class="{ 'mg-t-10': index != 0 }"
            v-for="(item, index) in msgList"
            :key="index"
            @click="navigationTo('/msg?tab=classTime')"
          >
            <div class="flex align-center flex-1">
              <div style="color: #fed90e">【课时不足】</div>
              <span class="textNoWarp flex-1_1">{{ item | getContent }}</span>
            </div>
            <span class="mg-l-10">{{ item.created_at }}</span>
          </div>
        </template>
      </msg-c>
    </el-container>
  </el-container>
</template>
<script>
import msgC from "./common/v-msg.vue";
import navigationC from "./common/navigation.vue";
/* API */
import { getMsg_NoRead } from "../assets/js/API/msg.js";
import { getContent } from "../assets/js/msgOption.js";
export default {
  name: "Home",
  components: {
    msgC,
    navigationC,
  },
  data() {
    return {
      exclude: [
        "TempAddHour",
        "AdjustmentClassHour",
        "AddClass",
        "BatchAdjustMakeUpHour",
        "StudentState",
        "ClassInfo",
        "SalaryAll",
        "SalaryInfo",
        "StudentCourseRegister",
        "StudentInfo",
        "StudentFileSingle",
		"FinancialInvoice",
		"FinancialLoan",
		"FinancialPayment",
		"FinancialPurchase",
		"FinancialRefund",
		"financialReimbursement",
		"StudentClassHourInfo"
      ],
      msgInfo: {}, //消息类型数量
      showMSG: false,
      menuList: null,
      isCollapse: false, //是否折叠
      activePath: "", //被激活的链接地址
      menu: "",
      username: "",
      schoole: [],
      show3: false,
      NotCheckSchoole: [],
      chooseSchoole: "",
      name: "",
      schoolIdOptions: [], //校区选择器配置
      schoolId: "", //选中的校区
    };
  },
  created() {
    getMsg_NoRead
      .call(this)
      .then((res) => {
        this.msgInfo = res;
        setInterval(() => {
          getMsg_NoRead
            .call(this)
            .then((res) => {
              this.msgInfo = res;
            })
            .catch((err) => {
              console.log(err);
            });
        }, 6000000);
      })
      .catch((err) => {
        console.log(err);
      });
    this.getSchool();
    this.getMenu();
    this.activePath = window.sessionStorage.getItem("activePath");
    /* if(this.activePath){
				this.saveNavPath(this.activePath);
			} */
    /* 获取选择的校区 */
    this.schoole = JSON.parse(window.sessionStorage.getItem("checkSchoole"));
    this.NotCheckSchoole = JSON.parse(window.sessionStorage.getItem("schoole"));
    this.NotCheckSchoole.forEach((item) => {
      if (item.id == this.schoole) {
        this.chooseSchoole = item;
      }
    });
  },
  filters: {
    getContent(val) {
      return getContent(val);
    },
  },
  methods: {
    toNewBlank() {
      var href = location.href;
      window.open(href);
    },
    /* 路由跳转 */
    navigationTo(url) {
      this.showMSG = false;
      const route = this.$router.resolve(url);
      window.open(route.href, "_blank");
    },
    // 获取校区
    async getSchool() {
      var params = {
        page: 1,
        number: 999999,
      };
      const { data: res } = await this.$http.get("/organization/schoole", {
        params,
      });
      if (res.code == 200) {
		window.localStorage.setItem('schooleLists', JSON.stringify(res.data.data))
        this.schoolIdOptions = res.data.data;
        this.schoolId = window.sessionStorage.getItem("checkSchoole") + '';
      }
    },
    /* 进入该校区 */
    async entry(id) {
      const { data: res } = await this.$http.get(
        "organization/schoole/" +
          window.sessionStorage.getItem("username") +
          "?schoole_id=" +
          id
      );
      if (res.code == 200) {
        window.sessionStorage.setItem("checkSchoole", id);
        this.$router.go(0);
      } else {
        this.$message.info("您无权限访问该校区");
        this.schoolId = window.sessionStorage.getItem("checkSchoole") + '';
      }
    },
    /* 选择校区 */
    /* chooseSchooles(item){
				window.sessionStorage.setItem('checkSchoole',item);
				this.NotCheckSchoole.forEach((val,index)=>{
					if(val.id == item){
						this.NotCheckSchoole[index].check = true;
						this.chooseSchoole = val;
					}else{
						this.NotCheckSchoole[index].check = false;
					}
				})
				window.sessionStorage.setItem('schoole',JSON.stringify(this.NotCheckSchoole));
				this.schoole = item;
				this.show3 = false;
				this.$router.go(0);
			}, */
    /*跳转页面 */
    index(path) {
      if (path == "organization" || path == "system") {
        return;
      }
      this.$router.push("/" + path);
      this.saveNavPath(path);
    },
    /* 折叠菜单栏 */
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    /* 設置选中菜单高亮 */
    saveNavPath(path) {
      window.sessionStorage.setItem("activePath", path);
      this.activePath = path;
      this.$router.push(this.activePath);
    },
    /* 获取左侧菜单栏 */
    async getMenu() {
      let username = window.sessionStorage.getItem("username");
      let name = window.sessionStorage.getItem("name");
      this.username = username;
      this.name = name;
      const { data: res } = await this.$http.post("/menu/" + username);
      this.menuList = res.data;
      this.useIcon(this.menuList);
      // this.menuList = this.$menu.menu.data
    },
    // 使用本地图标
    useIcon(val) {
      val.forEach((e) => {
        switch (e.name) {
          case "novice":
            e.icon = "el-icon-novice";
            break;
          case "student":
            e.icon = "el-icon-student";
            break;
          case "education":
            e.icon = "el-icon-education";
            break;
          case "organization":
            e.icon = "el-icon-organization";
            break;
          case "system":
            e.icon = "el-icon-system";
            break;
          case "index":
            e.icon = "el-icon-work";
            break;
          case "dataAnalysis":
            e.icon = "el-icon-analysis";
            break;
          case "financial":
            e.icon = "el-icon-financial";
          default:
            break;
        }
      });
    },
    /* 登出操作 */
    async loginOut() {
      const result = await this.$confirm("是否确认退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if ("confirm" == result) {
        window.sessionStorage.clear();
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-aside {
  overflow: hidden;
}

.el-icon-work {
  background: url(../assets/image/menu5.svg) center no-repeat;
  background-size: 17px 17px;
}

.el-icon-work:before {
  content: "\8d3a";
  font-size: 14px;
  visibility: hidden;
}

.el-icon-analysis {
  background: url(../assets/image/menu6.svg) center no-repeat;
  background-size: 17px 17px;
}

.el-icon-analysis::before {
  content: "\8d3a";
  font-size: 14px;
  visibility: hidden;
}

.el-icon-student {
  background: url(../assets/image/menu1.svg) center no-repeat;
  background-size: 17px 17px;
}

.el-icon-student:before {
  content: "\8d3a";
  font-size: 14px;
  visibility: hidden;
}

.el-icon-education {
  background: url(../assets/image/menu2.svg) center no-repeat;
  background-size: 17px 17px;
}

.el-icon-education:before {
  content: "\8d3a";
  font-size: 14px;
  visibility: hidden;
}

.el-icon-organization {
  background: url(../assets/image/menu3.svg) center no-repeat;
  background-size: 17px 17px;
}

.el-icon-organization:before {
  content: "\8d3a";
  font-size: 14px;
  visibility: hidden;
}

.el-icon-financial {
  background: url(../assets/image/menu7.svg) center no-repeat;
  background-size: 17px 17px;
}

.el-icon-financial:before {
  content: "\8d3a";
  font-size: 14px;
  visibility: hidden;
}

.el-icon-system {
  background: url(../assets/image/menu4.svg) center no-repeat;
  background-size: 17px 17px;
}

.el-icon-system:before {
  content: "\8d3a";
  font-size: 14px;
  visibility: hidden;
}

.iconfont {
  padding: 10px;
}

header {
  height: 70px !important;
}

.header-right {
  display: flex;
  padding: 0 10px;
  align-items: center;
  flex-direction: justify-between;

  > div {
    color: #000000;
    font-size: 15px;
  }

  > i {
    font-size: 20px;
    color: rgb(0, 159, 232);
  }
}

.el-header {
  background-color: rgba(254, 217, 14, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: #ffffff;

  > div {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    > img {
      width: 50px;
      height: 50px;
    }
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border: none;
  }
}

.home-container {
  height: 100%;
}

.tolggel-button {
  background-color: #333744;
  color: #ffffff;
  font-size: 20px;
  padding: 5px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.2rem;
  cursor: pointer;
  width: 100%;
}

.el-main {
  height: calc(100vh - 70px);
}

.schoole {
  display: inline-block !important;
  font-size: 18px;
  color: #000000;
  width: 100px;
  text-align: center;
  padding: 2px 5px 2px 5px;
  border-bottom: 2px solid #81d3f8;
  cursor: pointer;
}

.schoole-child {
  color: #000000;
  cursor: pointer;
}

/deep/.selectHome .el-input__inner {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: black;
  // max-width: 150px;
}

/deep/.selectHome .el-input__icon {
  color: black !important;
}
</style>
