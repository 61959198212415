<template>
	<!-- 教务管理 -->
	<div class="">
		<!-- header -->
		<div class="header fsMedia">
			<!-- header - left -->
			<div class="header-left">
				<el-card>
					<div slot="header" class="fsMedia-16">快捷功能</div>
					<div class="left-container">
						<div @click="selectClassTypeShow = true">
							<i class="iconfont icon-banji" style=""></i>
							<div>开设班级</div>
						</div>
						<div @click="click_adjustment">
							<i class="iconfont icon-shijian"></i>
							<div>调整课时</div>
						</div>
						<div @click="$router.push('/education/tempAddHour')">
							<i class="iconfont icon-kechengguanli"></i>
							<div>临时加课</div>
						</div>
						<div @click="$router.push('/education/batchAdjustMakeUpHour')">
							<i class="iconfont icon-buke"></i>
							<div>调课</div>
						</div>
						<div @click="$router.push('/fastClass')" v-if="false">
							<i class="iconfont icon-xingzhuangjiehe"></i>
							<div>体验课排课</div>
						</div>
						<div @click="$router.push('/education/educationFastTypesetting')">
							<i class="iconfont icon-shouye-paibanguanli"></i>
							<div>快速排班</div>
						</div>
					</div>
				</el-card>
				<!-- left - body -->
				<el-card class="mg-t-10 fsMedia">
					 <el-divider content-position="left" class='fsMedia-16'>考勤统计</el-divider>
					 <div class="left-analysis">
					 	<el-date-picker
					 	      v-model="attendanceAnalysis"
					 	      type="daterange"
					 	      range-separator="至"
							  value-format="yyyy-MM-dd"
							  @change="attendanceAnalysisChange"
					 	      start-placeholder="开始日期"
					 	      end-placeholder="结束日期">
					 	    </el-date-picker>
					 </div>
					 <ve-histogram :data="chartDatal" :settings="chartSettings" class='pd-t-10'></ve-histogram>
				</el-card>
				<!-- left footer -->
				<el-card class="mg-t-10">
					 <el-divider content-position="left">作品上传统计</el-divider>
					 <div class="left-analysis">
					 	<el-date-picker
					 	      v-model="workAnalysis"
					 	      type="daterange"
					 	      range-separator="至"
							  @change="worksAnalysisChange"
							  value-format="yyyy-MM-dd"
					 	      start-placeholder="开始日期"
					 	      end-placeholder="结束日期">
					 	    </el-date-picker>
					 </div>
					 <ve-histogram :data="chartDataz" :settings="chartSettings" class='pd-t-10'></ve-histogram>
				</el-card>
			</div>
			<!-- header - right -->
			<div class="header-right mg-l-10">
				<el-card>
					<div class="right-container">
						<!-- header -->
						<div class="right-container-header">
							<!-- 左侧 -->
							<div class="left-analysis">
								<el-date-picker
								      v-model="studyAnalysis"
									  @change="studyAnalysisChange"
									  value-format="yyyy-MM-dd"
								      type="daterange"
								      range-separator="至"
								      start-placeholder="开始日期"
								      end-placeholder="结束日期">
								    </el-date-picker>
							</div>
							<!-- 右侧 -->
							<div class="right-open">
								<!-- <el-button type="primary" size="small">
									打开课表
									<i class="el-icon-arrow-right el-icon-right"></i>
								</el-button> -->
							</div>
						</div>
						<!-- header -body -->
						<el-divider content-position="left">教学统计</el-divider>
						<div class="right-container-body">
							<div
							@click="$router.push('/education/calendars')" class="cursor-p"
							>
								<i>{{attendance == null ? '暂无数据' : attendance }}</i>
								<div>课中考勤（未完成）</div>
							</div>
							<div
							@click="$router.push('/education/calendars')" class="cursor-p"					
							>
								<i>{{study == null ? '暂无数据' : study }}</i>
								<div>课后小结（未完成）</div>
							</div>
						</div>
					</div>
				</el-card>
				<!-- right - body -->
				<el-card class="right-body mg-t-10 fsMedia">
					<el-divider content-position="left">出勤率</el-divider>
					<ve-liquidfill :data="chartDataAttendance" :settings="chartSettings"></ve-liquidfill>
				</el-card>
				<!-- right - footer -->
				<el-card class="right-footer mg-t-10 fsMedia">
					<el-divider content-position="left">作品上传率</el-divider>
					<ve-liquidfill :data="chartDataWork" :settings="chartSettings"></ve-liquidfill>
				</el-card>
			</div>
		</div>

		<!-- 调整课时 选择班级对话框 -->
		<dialog-c :dialog='dialog_class'>
			<template v-slot:body>
				<table-class-c @handleCurrentChange='current_change($event)'
				:tableData='tableData_class' :data_table='data_table_class' :show_table='show_table_class' :button='button_class'
				@handleCurrentChangePagination='handleCurrentChangePagination($event)'
				></table-class-c>
			</template>
		</dialog-c>
		
		<!-- 选择创建班级类型 -->
		<el-dialog :visible.sync="selectClassTypeShow" center title="选择班级类型" width="50%">
			<div class="flex align-center space-around">
				<img src="../../assets/image/openClassBtn1.png" class="classTypeImgSize cursor-p" @click="selectClassType(1)" />
				<img src="../../assets/image/openClassBtn2.png" class="classTypeImgSize cursor-p" @click="selectClassType(3)" />
				<img src="../../assets/image/openClassBtn3.png" class="classTypeImgSize cursor-p" @click="selectClassType(4)" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import dialogC from '../common/v-dialog'
	import tableClassC from '../common/v-table-class'
	import { table_test, getAge_range } from '../../assets/js/myMethods'
	export default{
		name: 'Education',
		data(){
			/* 柱状图 */
			return{
				selectClassTypeShow: false,
				// 表格数据
				tableData_class: {},
				// 表格的样式
				show_table_class: {

							// 单选
							isSingle: true,
							// 斑马纹
							isStripe: false,
							// 多选
							isMultiple: false,
							// 是否要操作
							isOperation: false,
							// 排序
							isSortable: true,
				},
				// 表格按钮状态
				button_class: {
							button1: {
								// 是否显示
								state: true,
								// 按钮类型
								type: 'primary',
								// 按钮文本
								value: '选择',
							},
							button2: {
								state: true,
								type: 'warning',
								value: '编辑',					
							},
							button3: {
								state: true,
								type: 'danger',
								value: '删除'
							}										
				},
				// 表格配置
				data_table_class: [],
				data_table_class1: {
					range_age: '',
					capacity: 22,
					className: "123",
					// class_time: "",
					classroomName: "321",
					id: 85,
					lesson: 12,
					status: 1,
					studentCount: 1,
					teacherName: "超级管理员"					
				},
				dialog_class: {
					show: false,
					title: '选择班级',
					width: '80%'
				},				
				chartSettings:{},
				attendanceAnalysis:[],
				workAnalysis:[],
				studyAnalysis:[],
				attendance:'',
				study:'',
				/* 水球数据1*/
				chartDataAttendance: {
				  columns: ['attendance', 'percent'],
				  rows: []
				},
				/* 水球数据2 */
				chartDataWork: {
				  columns: ['work', 'percent'],
				  rows: []
				},
				/* 柱状图 */
				chartDatal: {
				          columns: ['日期', '出勤', '请假', '旷课','未处理'],
				          rows: []
				        },
				chartDataz: {
				          columns: ['日期', '已上传', '未上传'],
				          rows: []
				        },
				timeOptionRange: '',
						
			}
		},
		created() {
			//获取今日日期
			//获取7天后的日期
			(this.attendanceAnalysis.length == 0) && (this.attendanceAnalysis = [this.getDay(-7), this.getDay(0)]);
			(this.workAnalysis.length == 0) && (this.workAnalysis = [this.getDay(-7), this.getDay(0)]);
			(this.studyAnalysis.length == 0) && (this.studyAnalysis = [this.getDay(-7), this.getDay(0)])
			this.getAttendanceAnalysis();
			this.getWorkAttendanceAnalysis();
			this.getstudyAttendanceAnalysis();

			// 更新班级表格配置
			this.data_table_class = table_test(this.data_table_class1, this.$param.table_class)
		},
		methods:{
			selectClassType(type) {
				this.selectClassTypeShow = false;
				this.$router.push('/education/courseManager/addClass?type=' + type);
			},
			async click_adjustment () {
				this.dialog_class.show = true
				this.upTable_class()
			},		
			/* 获取考勤统计 */
			async getAttendanceAnalysis(){
				this.chartDatal.rows = [];
				this.chartDataAttendance.rows = [];
				const {data:res} = await this.$http.get('education/analysis?date_start=' + this.attendanceAnalysis[0]+"&date_end="+ this.attendanceAnalysis[1]);
				if(res.code == 200){
					if(res.data.length <= 0) return;
					res.data.analysis.forEach((item)=>{
						this.chartDatal.rows.push(
						{
							'日期':item.time,
							'出勤':item.record,
							'请假':item.leaves,
							'旷课':item.absent,
							'未处理':item.untreated
						});
					});
					this.chartDataAttendance.rows.push({attendance:'考勤率',percent:res.data.percent})
				}
			},
			/* 获取作品上传统计 */
			async getWorkAttendanceAnalysis(){
				this.chartDataz.rows = [];
				this.chartDataWork.rows = [];
				const {data:res} = await this.$http.get('education/analysisWork?date_start=' + this.workAnalysis[0]+"&date_end="+ this.workAnalysis[1]);
				if(res.code == 200){
					if(res.data.length <= 0) return;
					res.data.analysis.forEach((item)=>{
						this.chartDataz.rows.push(
						{
							'日期':item.time,
							'已上传':item.upload,
							'未上传':item.notUpload,
						});
					});
					this.chartDataWork.rows.push({attendance:'上传率',percent:res.data.percent})
				}
			},
			/* 获取课中考勤未完成 课后小结未完成 */
			async getstudyAttendanceAnalysis(){
				const {data:res} = await this.$http.get('education/analysisStudy?date_start=' + this.studyAnalysis[0]+"&date_end="+ this.studyAnalysis[1]);
				if(res.code == 200){
					this.attendance = res.data.attendance;
					this.study = res.data.work;
				}
			},
			getDay(day){  
			       var today = new Date();  
			       var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;          
			       today.setTime(targetday_milliseconds); //注意，这行是关键代码
			       var tYear = today.getFullYear();  
			       var tMonth = today.getMonth();  
			       var tDate = today.getDate();  
			       tMonth = this.doHandleMonth(tMonth + 1);  
			       tDate = this.doHandleMonth(tDate);  
			       return tYear+"-"+tMonth+"-"+tDate;  
			},
		    doHandleMonth(month){  
				 var m = month;  
				 if(month.toString().length == 1){  
					m = "0" + month;  
				 }  
				 return m;  
		  },
			/* 时间改变*/
			attendanceAnalysisChange(){
				this.getAttendanceAnalysis();
			},
			/* 作品上传时间改变*/
			worksAnalysisChange(){
				this.getWorkAttendanceAnalysis();
			},
			/* 教学时间改变 */
			studyAnalysisChange(){
				this.getstudyAttendanceAnalysis();
			},
			current_change (e) {
				this.dialog_class.show = false;
				this.$router.push('/education/adjustment/' + e.id.id)
			},
			handleCurrentChangePagination (page) {
				this.upTable_class({
					page
				})
			},

			async upTable_class_before (param) {
				var data = await this.getClass(param)
				if (data) {
					data.data.forEach( e => {
						e.range_age = getAge_range(e)
					})
				}
				return data
			},
			async upTable_class (param) {
				this.tableData_class = await this.upTable_class_before(param)
			},
			/* 获取班级信息 */
			async getClass(param){
				// var param = {
				// 		age_start: 0,
				// 		age_end: 100,
				// 		page: 1,
				// 		// number: 8,
				// 		course: 0,
				// 		key: '',
						// status: ''			
				// };
				var params = { page: 1, age_start: 1, age_end: 999 }
				param && (Object.assign(params, param))
				const {data:res} = await this.$http.get('/education/classes', { params });
				if(res.code === 200){
					return res.data
				}
				return false
			},

		},
		components: {
			dialogC,
			tableClassC
		}
	}
</script>

<style lang="less" scoped>
	.classTypeImgSize {
		vertical-align: middle;
		width: 30%;
	}
	.left-analysis .el-date-picker{
		width: 250px !important;
	}
	.header{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		> .header-left{
			width:65%;
		}
		> .header-right{
			width: 35%;
		}
	}
	.left-container{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		> div{
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;
			> i{
				font-size: 30px;
				color: #81d3f8;
			}
			>div{
				color: #969896;
				padding: 5px;
			}
		}
	}
	.right-container-header{
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		> .left-analysis{
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			> span{
				width: 100px;
			}
		}
		> .right-open{
			width: 47%;
			display: flex;
			justify-content: flex-end;
		}
	}
	.right-container-body{
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		> div{
			display: flex;
			flex-direction: column;
			align-items: center;
			> i{
				padding: 20px 0px;
				color: #ffbc63;
				font-size: 30px;
			}
			> div{
				color: #969896;
				padding: 5px;
			}
		}
	}
	.right-body,.right-footer{
		line-height: 260px;
	}
	/deep/ .el-range-input,/deep/ .el-range-separator
	{
		font-size: 14px !important;
	}
	/deep/ .el-divider__text {
	    font-size: 16px !important;
	}
	@media only screen and (max-width: 1366px) {
		/deep/ .el-range-input,/deep/ .el-range-separator{
			font-size: 12px !important;
		}
		/deep/ .el-divider__text {
		    font-size: 14px !important;
		}
	}		
</style>
