/* ***************************************表情编码解码****************************************** */

/* 编码 */
export function StringToUTF (str) {
	var reg = /([0-9|*|#]\uFE0F\u20E3)|([0-9|#]\u20E3)|([\u203C-\u3299]\uFE0F\u200D)|([\u203C-\u3299]\uFE0F)|([\u2122-\u2B55])|(\u303D)|([\A9|\AE]\u3030)|(\uA9)|(\uAE)|(\u3030)|([\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF])|([\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F])/g
	return str.replace(reg, function (val) {
		return '{{' + val.codePointAt(0).toString(16) + '}}'
	})
}
/* 解码 */
export function UTFtoString (utf) {
	var reg = /{{[0-9A-Za-z]+}}/g;
	return utf.replace(reg, function (val) {
		var value = val.replace(/{{/g, '').replace(/}}/, '');
		return String.fromCodePoint('0x' + value)
	})
}





