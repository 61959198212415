import request from '../request/request'

// 商圈列表
export const reGetList = (params) => {
  return request({
    url: '/organization/select/reGetList',
    method: 'get',
    params
  })
}
export const getOrganChildList = (params) => {
  return request({
    url: '/organization/select/getOrganChildList',
    method: 'get',
    params
  })
}
// 添加商圈
export const reAddOrgan = (data) => {
  return request({
    url: '/organization/select/reAddOrgan',
    method: 'post',
    data
  })
}

// 添加小区
export const AddOrganHouse = (data) => {
  return request({
    url: '/organization/select/AddOrganHouse',
    method: 'post',
    data
  })
}
// 添加学校
export const AddOrganCampus = (data) => {
  return request({
    url: '/organization/select/AddOrganCampus',
    method: 'post',
    data
  })
}
// 添加培训机构
export const AddOrganCompete = (data) => {
  return request({
    url: '/organization/select/AddOrganCompete',
    method: 'post',
    data
  })
}
// 添加店铺
export const AddOrganShops = (data) => {
  return request({
    url: '/organization/select/AddOrganShops',
    method: 'post',
    data
  })
}