<template>
	<!-- 学员课时详细信息 -->
	<div>
		<breadcrumb-c :value_breadcrumb='value_breadcrumb'></breadcrumb-c>
		<el-card>
			<el-button type="primary" size="medium" @click="showDialog('add')">赠送课时</el-button>
			<el-table :data="tableData" style="width: 100%;" stripe class="mg-t-10">
				<el-table-column prop="course_package_name" label="课程名称"></el-table-column>
				<el-table-column prop="lesson" label="赠送课时数量(课时)" width="180"></el-table-column>
				<el-table-column prop="created_at" label="赠送时间" width="180"></el-table-column>
				<el-table-column prop="remark" label="备注" min-width="180"></el-table-column>
				<el-table-column label="操作" width="200px" fixed="right">
					<template v-slot="scope">
						<el-button type="primary" size="mini" @click="showDialog('modify', scope.row)">查看课时</el-button>
						<el-button type="primary" size="mini" @click='delClassHours(scope.row)'>删除课时</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mg-t-10" background layout="total, prev, pager, next" :current-page="page" :page-size="10" :total="total" @current-change='pageChange'>
			</el-pagination>
		</el-card>
		<!-- 课时详细信息 -->
		<el-dialog :visible.sync="dialogShow" center :title="title" width="30%" @close='close'>
			<el-form :model="formData" ref='form' label-position="right" label-width="80px" :rules="rules">
				<el-form-item label="选择课程" prop="cost_lesson_record_id">
					<el-select v-model="formData.cost_lesson_record_id" style="width: 100%;">
						<el-option v-for="(item, index) in courseLists" :key='item.id' :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="赠送课时" prop="lesson">
					<el-input placeholder='请输入赠送课时' v-model="formData.lesson"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input placeholder='请输入备注信息' v-model="formData.remark"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogShow = false">取 消</el-button>
				<el-button type="primary" @click="addORmodify">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import breadcrumbC from '../../common/v-breadcrumb.vue'
	import validate from '../../../assets/js/validate.js'
	import {
		extend
	} from '../../../assets/js/util.js'
	import {
		getCostLesson
	} from '../../../assets/js/API/cost-lesson.js'
	const validatorCommon = validate.validatorCommon
	export default {
		name: 'StudentClassHourInfo',
		data() {
			return {
				courseLists: [],
				selectCHid: '',
				sid: '',
				page: 1,
				total: 0,
				title: '',
				dialogShow: false,
				value_breadcrumb: ['学员管理', '学员费用', '学员费用详细信息'],
				rules: {
					cost_lesson_record_id: validatorCommon.mandatory('请选择课程', 'change'),
					lesson: validatorCommon.mandatory('请输入赠送课时').concat(validatorCommon.number),
					remark: validatorCommon.mandatory('请输入备注信息')
				},
				formData: {
					cost_lesson_record_id: '',
					lesson: '',
					remark: ''
				},
				tableData: []
			}
		},
		methods: {
			getCostLesson () {
				getCostLesson.call(this, { sid: this.sid, page: 1, number: 9999999 })
							 .then( res => {
								if (res) {
									this.courseLists = res.data;
									this.courseLists.forEach( item => {
										item.name = item.course_classification_name + '/' + item.course_package_name
									})
								}
							 })
							 .catch( err => console.log(err))
			},
			pageChange (page) {
				this.page = page;
				this.getClassHours()
			},
			async addORmodify () {
				var bool = await this.$refs.form.validate().catch( err => {});
				if (!bool) return;
				if (this.title == '赠送课时') {
					this.addClassHours()
				} else if (this.title == '修改课时') {
					this.modifyClassHours()
				}
			},
			close() {
				this.$refs.form.resetFields()
			},
			showDialog(key, row) {
				if (key == 'add') this.title = '赠送课时';
				else if (key == 'modify'){
					this.title = '修改课时';
					this.selectCHid = row.id;
					this.getClassHoursInfo(row.id)
				}
				this.dialogShow = true
			},
			delClassHours(row) {
				this.$confirm('您确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then( async () => {
					const { data: res } = await this.$http.delete('/student/give/lesson', {
						data: {
							id: row.id
						}
					});
					if (res.code == 0) {
						this.$message.success('删除课时成功');
						this.getClassHours()
					} else {
						this.$message.error(res.message)
					}
				}).catch( () => {  })
			},
			async getClassHoursInfo (id) {
				const { data: res } = await this.$http.get('/student/give/lesson/detail?id=' + id);
				if (res.code == 0) {
					extend(this.formData, res.data)
				} else {
					this.$message.error(res.message)
				}
			},
			async getClassHours () {
				var params = {
					sid: this.sid,
					page: this.page,
					number: 10
				}
				const { data: res } = await this.$http.get('/student/give/lesson/list', {
					params
				});
				if (res.code == 0) {
					var data = res.data;
					this.tableData = data.data;
					this.page = data.page;
					this.total = data.total;
				}
			},
			async addClassHours () {
				var params = {...this.formData};
				params.sid = this.sid;
				const { data: res } = await this.$http.post('/student/give/lesson', params);
				if (res.code == 0) {
					this.$message.success('赠送课时成功');
					this.getClassHours();
					this.dialogShow = false
				} else {
					this.$message.error(res.message)
				}
			},
			async modifyClassHours () {
				var params = {...this.formData};
				params.id = this.selectCHid;
				const { data: res } = await this.$http.put('/student/give/lesson', params);
				if (res.code == 0) {
					this.$message.success('修改课时信息成功');
					this.getClassHours();
					this.dialogShow = false
				} else {
					this.$message.error(res.message)
				}
			}
		},
		components: {
			breadcrumbC
		},
		created() {
			var { sid } = this.$route.query;
			this.sid = sid;
			sid && this.getClassHours();
			this.getCostLesson()
		}
	}
</script>

<style>
</style>
