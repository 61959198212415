/**
@describe 转增课包记录
@date 2021-03-30
@by FZY
*/
<template>
  <div>
    <el-row>
      <el-col :span="6">
        <el-input size='small' clearable v-model="screen.key" placeholder="请输入学员ID、姓名、联系电话" @change="getTurnLists">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="getTurnLists"></i>
        </el-input>
      </el-col>
      <el-col :span="6" style="padding: 0 10px;">
        <el-date-picker clearable v-model="screen.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" style="width: 100%" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="getTurnLists">
        </el-date-picker>
      </el-col>
    </el-row>
    <el-table stripe :data='tableData' size='small' class="mg-t-10">
      <el-table-column align="center" prop='sid' label="转出学员ID"></el-table-column>
      <el-table-column align="center" prop='s_name' label="转出学员姓名"></el-table-column>
      <el-table-column align="center" prop='s_tel' label="联系电话"></el-table-column>
      <el-table-column align="center" prop='accept_sid' label="转入学员ID"></el-table-column>
      <el-table-column align="center" prop='accept_name' label="转入学员姓名"></el-table-column>
      <el-table-column align="center" prop='accept_tel' label="联系电话"></el-table-column>
      <el-table-column align="center" prop='donate_desc' label="转赠类型"></el-table-column>
      <el-table-column align="center" prop='donate_lesson' label="转赠课时数"></el-table-column>
      <el-table-column align="center" prop='course_classification_name' label="课程"></el-table-column>
      <el-table-column align="center" prop='course_package_name' label="课包"></el-table-column>
      <el-table-column align="center" prop='created_at' label="操作时间" min-width="100"></el-table-column>
      <el-table-column align="center" prop='remark' label="备注" min-width="100"></el-table-column>
      <el-table-column align="center" prop='up_name' label="操作人"></el-table-column>
    </el-table>
    <loading-c :show-loading='loading'></loading-c>
    <!-- <el-pagination background layout="total, prev, pager, next" :total="pagination.total" class="mg-t-10">
		</el-pagination> -->
  </div>
</template>

<script>
import {
  getTurnLists
} from '../../assets/js/API/student.js'
import {
  TURN_TYPE
} from '../../assets/js/CONFIG/cost-lesson.js'
import loadingC from '../common/loading.vue'
export default {
  name: 'StudentTurnLists',
  components: {
    loadingC
  },
  data() {
    return {
      turnType: TURN_TYPE,
      loading: false,
      screen: {
        key: '',
        date: []
      },
      tableData: [],
      pagination: {
        total: 0
      }
    }
  },
  created() {
    this.getTurnLists()
  },
  methods: {
    getTurnLists() {
      var params = { ...this.screen };
      params.date && params.date.length == 2 && (params.date_start = params.date[0], params.date_end = params.date[1]);
      delete params.date;
      this.loading = true;
      getTurnLists.call(this, params)
        .then(res => {
          this.loading = false;
          if (res) {
            this.tableData = res
          }
        })
        .catch(err => this.loading = false)
    }
  }
}
</script>

<style scoped>
</style>
