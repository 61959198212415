<template>
  <!-- 考勤记录 -->
  <div class="">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>教务管理</el-breadcrumb-item>
      <el-breadcrumb-item>考勤记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- header -->
    <div class="header mg-b-10">
      <!-- header-left -->
      <div class="header-left flex align-center w-per-100">
        <div class="mg-lr-10">
          <el-select clearable placeholder="考勤状态" size="small" v-model="screen.a_status" @change="a_statusChange" clearable>
            <el-option v-for="(item,index) in $param.attendanceStatus" :key="'attend'+index" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </div>
        <el-select clearable placeholder="作品上传状态" size="small" v-model="screen.w_status" @change="w_statusChange" clearable>
          <el-option v-for="(item,index) in $param.workUploadStatus" :key="'work'+index" :label="item.name" :value="item.val"></el-option>
        </el-select>
        <div class="flex align-center mg-lr-10">
          <el-input type="text" style="" size="small" v-model="screen.key" @change="key_change" placeholder="请输入姓名,班级,老师" clearable>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <el-date-picker @change="dateChange" size="small" v-model="screen.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-select size="small" v-model="screen.course_classification_id" @change="courseChange" clearable placeholder="请选择课程" class="mg-l-10">
          <el-option v-for="item in courseLists" :key='item.id' :value="item.id" :label="item.name"></el-option>
        </el-select>
      </div>
    </div>
    <el-card>
      <el-divider content-position="left">
        <i class="el-icon-paperclip"></i>
        <span style="font-size: 10px;color: #5E6E5E;">当前考勤、上传统计</span>
      </el-divider>
      <!-- 数据展示 -->
      <div class="banner-data" style="background-color: #EFEFEF;padding: 10px; text-align: center;">
        <!-- left -->
        <div class="analysis-container-left flex justify-between w-per-45 align-center">
          <div class="analysis">
            <el-badge is-dot type="success"></el-badge>
            <span class="mg-l-5">出勤</span>
            <span>&nbsp;{{analysis.attendance.record}}</span>
          </div>
          <div class="analysis">
            <el-badge is-dot type="primary"></el-badge>
            <span class="mg-l-5">请假</span>
            <span>&nbsp;{{analysis.attendance.leave}}</span>
          </div>
          <div class="analysis">
            <el-badge is-dot type="warning"></el-badge>
            <span class="mg-l-5">旷课</span>
            <span>&nbsp;{{analysis.attendance.absent}}</span>
          </div>
          <div class="analysis">
            <el-badge is-dot></el-badge>
            <span class="mg-l-5">未处理</span>
            <span>&nbsp;{{analysis.attendance.untreated}}</span>
          </div>
          <div class="analysis mg-lr-10">
            <el-badge is-dot type="info"></el-badge>
            <span class="mg-l-5">出勤率</span>
            <span>&nbsp;{{analysis.attendance.recordPercent}}</span>
          </div>
        </div>
        <!-- right --> |
        <div class="analysis-container-right flex justify-between w-per-20 align-center mg-lr-10">
          <div class="analysis">
            <el-badge is-dot type="success" class=""></el-badge>
            <span class="mg-l-5">已上传</span>
            <span>&nbsp;{{analysis.upload.upload}}</span>
          </div>
          <div class="analysis">
            <el-badge is-dot></el-badge>
            <span class="mg-l-5">未上传</span>
            <span>&nbsp;{{analysis.upload.notUpload}}</span>
          </div>
          <div class="analysis">
            <el-badge is-dot type="info"></el-badge>
            <span class="mg-l-5">上传率</span>
            <span>&nbsp;{{analysis.upload.uploadPercent}}</span>
          </div>
        </div>
        <div>
        </div>
      </div>
      <!-- table -->
      <el-divider content-position="left">
        <span style="font-size: 10px;color: #5E6E5E;">考勤记录</span>
      </el-divider>
      <div class="tabs mg-t-10">
        <div class="flex tabsHeader">
          <div class='tabsItem' style='border-top-left-radius: 5px;' :class="{selectTabs: screen.type == '1'}" @click="change_select_classType('regularClass', 1)">常规班级</div>
          <div class='tabsItem' style='border-top-right-radius: 5px;' :class="{selectTabs: screen.type == '3'}" @click="change_select_classType('experienceClass', 3)">体验班级</div>
          <div class='tabsItem' style='border-top-right-radius: 5px;' :class="{selectTabs: screen.type == '4'}" @click="change_select_classType('experienceClass', 4)">活动班级</div>
          <div class="flex-1" style="border-bottom: 1px solid #dcdfe6;"></div>
        </div>
        <div class="pd-10">
          <el-table :data="analysisList" class="fsMedia" style="width: 100%">
             <el-table-column align="center" label="ID" width="120">
              <template slot-scope="scope">
                {{scope.row.id}}
              </template>
            </el-table-column>
            <el-table-column align="center" label="姓名">
              <template slot-scope="scope">
                {{scope.row.studentName}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="mainTeacher" label="主课老师">
            </el-table-column>
            <!-- 						<el-table-column prop="alternativeTeacher" label="配课老师">
						</el-table-column> -->
            <el-table-column align="center" label="考勤状态">
              <template slot-scope="scope">
                {{scope.row.a_status == 4 ? '未处理' : scope.row.a_status == 3 ? '旷课' : scope.row.a_status == 2 ? '请假' : scope.row.a_status == 1 ? '出勤' : '未知状态' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="作品上传状态">
              <template slot-scope="scope">
                {{scope.row.w_status == 1 ? '已上传' : '未上传'}}
              </template>
            </el-table-column>
            <el-table-column prop="className" align="center" label="班级" min-width="100">
            </el-table-column>
            <el-table-column label="课时" align="center" min-width="100">
              <template slot-scope="scope">
                {{scope.row.date}} &nbsp; {{scope.row.name}} &nbsp;{{scope.row.time_start}} &nbsp;{{scope.row.time_end}}
              </template>
            </el-table-column>
            <el-table-column label="课时数" align="center" prop="teach_hours">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <el-dropdown>
                  <el-button type="primary" size="small">
                    更改考勤
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="updateAttendance(scope.row,1, '出勤')">出勤</el-dropdown-item>
                    <el-dropdown-item @click.native="showLeaveInfo(scope.row,2)">请假</el-dropdown-item>
                    <el-dropdown-item @click.native="updateAttendance(scope.row,3, '旷课')">旷课</el-dropdown-item>
                    <el-dropdown-item @click.native="updateAttendance(scope.row,4, '未处理')">未处理</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-popconfirm
                  title="确定删除该信息吗？"
                  @confirm="confirmDel(scope.row)"
                  >
                  <span style="padding:0 5px"  slot="reference">
                    <el-button type="danger" size="small">删除</el-button>
                  </span>
                </el-popconfirm>
                
              </template>
            </el-table-column>
          </el-table>
          <div class="mg-t-10">
            <el-pagination background layout="total, prev, pager, next" @current-change="hanldeCurrentChange" :total="total" :current-page='screen.page'>
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- /table -->
      <!-- body -->
    </el-card>

    <el-dialog :visible.sync="leaveDialogVisible" center title="请假事由" width="30%" @close="$refs.refLeaveForm.resetFields()">
      <el-form label-position="right" label-width="80px" :rules="rules" :model="formData" ref='refLeaveForm'>
        <el-form-item label="请假事由" prop="reason">
          <el-input placeholder="请假事由" v-model="formData.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="leaveDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="leave">确 定</el-button>
      </span>
    </el-dialog>
    <loading-c :show-loading="loading"></loading-c>

  </div>
</template>

<script>
import compress from "../../assets/js/compress-file.js";
import vali from "../../assets/js/validate.js";
import { updateAttendance,attendanceDel } from "../../assets/js/API/student.js";
import { getCourse } from "../../assets/js/API/course.js";
import { getAnalysisList } from "../../assets/js/API/class-hour.js";
import loadingC from "../common/loading";
export default {
  name: "AttendanceRecords",
  components: {
    loadingC,
  },
  data() {
    return {
      courseLists: [],
      formData: {
        reason: "",
      },
      rules: {
        reason: vali.validatorCommon.mandatory("请输入请假事由"),
      },
      leaveDialogVisible: false,
      selectAttendance: {
        id: "",
        status: "",
      },
      /* 数据分析 */
      analysis: {
        attendance: {
          record: 0,
          leave: 0,
          absent: 0,
          untreated: 0,
          recordPercent: "0%",
        },
        upload: {
          upload: 0,
          notUpload: 0,
          uploadPercent: "0%",
        },
      },
      loading: false,
      analysisList: [],
      screen: {
        course_classification_id: "",
        a_status: "",
        w_status: "",
        age_start: "",
        age_end: "",
        page: 1,
        key: "",
        type: "1",
      },
      total: 0,
    };
  },
  created() {
    var { a_status } = this.$route.query;
    a_status && (this.screen.a_status = a_status - 0);
    this.getCourse();
    this.getAttendanceAnalysis();
    this.getAnalysisList();
  },
  methods: {
    confirmDel(record){
      attendanceDel.call(this, record['id']).then(res=>{
        if(res){
          this.getAnalysisList();
        }
      })
    },
    dateChange() {
      this.screen.page = 1;
      this.getAnalysisList();
      this.getAttendanceAnalysis();
    },
    courseChange() {
      this.screen.page = 1;
      this.getAnalysisList();
      this.getAttendanceAnalysis();
    },
    getCourse() {
      getCourse
        .call(this, 1)
        .then((res) => {
          if (res) this.courseLists = res;
        })
        .catch((err) => console.log(err));
    },
    async leave() {
      var bool = await this.$refs.refLeaveForm.validate().catch((err) => { });
      if (!bool) return;
      this.updateAttendance(
        this.selectAttendance,
        this.selectAttendance.status
      );
    },
    change_select_classType(key, type) {
      if (this.screen.type == type) return;
      this.screen.type = type;
      this.screen.page = 1;
      this.getAnalysisList();
      this.getAttendanceAnalysis();
    },
    /* 分页 */
    hanldeCurrentChange(page) {
      this.screen.page = page;
      this.getAnalysisList();
    },
    /* 获取考勤统计分析 */
    async getAttendanceAnalysis() {
      var params = { ...this.screen };
      if (params.date && params.date.length == 2) {
        params.date_start = params.date[0];
        params.date_end = params.date[1];
      }
      delete params.date;
      const { data: res } = await this.$http.get(
        "education/attendance/getAnalysis",
        {
          params,
        }
      );
      if (res.code == 200) {
        if (res.data == null) {
          this.analysis = {
            attendance: {
              record: 0,
              leave: 0,
              absent: 0,
              untreated: 0,
              recordPercent: "0%",
            },
            upload: {
              upload: 0,
              notUpload: 0,
              uploadPercent: "0%",
            },
          }
        } else {
          this.analysis = res.data
        }
      }
    },
    /* 获取考勤列表 */
    getAnalysisList() {
      this.loading = true
      var params = { ...this.screen };
      if (params.date && params.date.length == 2) {
        params.date_start = params.date[0];
        params.date_end = params.date[1];
      }
      delete params.date;
      getAnalysisList.call(this, params).then((res) => {
        if (res) {
          this.loading = false
          this.analysisList = res.data;
          this.total = res.total;
          this.page = res.current_page;
        }
      });
    },
    /* 考勤状态改变 */
    a_statusChange(row) {
      this.screen.page = 1;
      this.getAnalysisList();
      this.getAttendanceAnalysis();
    },
    w_statusChange(row) {
      this.screen.page = 1;
      this.getAnalysisList();
      this.getAttendanceAnalysis();
    },
    key_change() {
      this.screen.page = 1;
      this.getAnalysisList();
      this.getAttendanceAnalysis();
    },
    showLeaveInfo(row, status) {
      if (row.a_status == status)
        return this.$message.info("考勤已是这个状态，无需更改");
      this.leaveDialogVisible = true;
      this.selectAttendance.id = row.id;
      this.selectAttendance.status = status;
    },
    /* 更新考勤状态 */
    updateAttendance(row, status, text) {
      if (row.a_status == status)
        return this.$message.info("考勤已是这个状态，无需更改");
      this.$confirm("您确认要更改为" + text, "提示")
        .then(() => {
          updateAttendance
            .call(this, row.id, {
              status,
              reason: this.formData.reason,
            })
            .then((res) => {
              if (res) {
                this.leaveDialogVisible = false;
                this.getAnalysisList();
                this.getAttendanceAnalysis();
              }
            });
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-badge__content {
  transform: translate(0, 5px);
}

.banner-data {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.analysis-container-left,
.analysis-container-right {
  > div {
    font-size: 10px;
    color: #7d7d7d;
  }
}

.tabs {
  /* box-shadow: 0 2px 4px rgba(0,0,0,.12),0 0 6px 0 rgba(0,0,0,.04); */
}

.tabsHeader {
  /* background-color: #f5f7fa; */
  height: 40px;
  line-height: 40px;
  /* border: 1px solid #dcdfe6; */
}

.tabsItem {
  cursor: pointer;
  padding: 0 20px;
  font-size: 14px;
  color: #909399;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid #dcdfe6;
}

.selectTabs {
  /* border: 1px solid transparent; 
		box-sizing: border-box; */
  transition: all 0.3s;
  color: #409eff !important;
  background-color: #fff;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
  border-bottom-color: rgba(0, 0, 0, 0);
}
.analysis{
  margin-left: 8px;
  span{
    vertical-align: middle;
  }
}
</style>
