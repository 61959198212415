<template>
	<!-- 权限管理 -->
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>系统管理</el-breadcrumb-item>
		  <el-breadcrumb-item>账号管理</el-breadcrumb-item>
		  <el-breadcrumb-item>权限管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-button type="primary" icon="el-icon-circle-plus-outline" @click="addPermission(0)" size="small">添加权限</el-button>
			<!-- 表格 -->
			<el-table
				:data="permission"
				row-key="id"
				border
				:tree-props="{children: 'list'}" class="fsMedia"
			>
			<el-table-column
				type="index"
				label="#"
			>
			</el-table-column>
			
			<el-table-column
				prop="title"
				label="名称"
			>
			</el-table-column>
			
			<el-table-column
				prop="name"
				label="路由"
			>
			</el-table-column>
			
			<el-table-column
				label="是否隐藏"
			>
				<template slot-scope='scope'>
					<el-switch v-model="scope.row.is_hide" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)" v-if="scope.row.is_hide">
						<隐藏>
					</el-switch>
					<span v-else>
						<暂无隐藏>
					</span>
				</template>
			</el-table-column>
			
			<el-table-column
				prop="icon"
				label="图标"
			>
			<template slot-scope='scope'>
				<i :class="scope.row.icon"></i>
			</template>
			</el-table-column>
			
			<el-table-column
			  label="操作"
			  width="290px"
			 >
			 <template  slot-scope="scope">
				<el-button type="primary"  size="small" @click="addPermission(scope.row.id)" class="fsMedia">添加</el-button>
				<el-button type="primary" size="small" @click="editPermission(scope.row)" class="fsMedia">编辑</el-button>
				<el-button type="primary" size="small" @click="delPermission(scope.row)" class="fsMedia">删除</el-button>
			 </template>
			</el-table-column>
			</el-table>
		</el-card>
		
		<!-- form -->
		<el-dialog
		  :title="operateName"
		  :visible.sync="addDialogVisible"
		  @close="addDialogClose"
		  width="30%"
		  >
		  <!-- 内容主题区域-->
		  <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
		    <el-form-item label="权限名称" prop="title">
		      <el-input v-model="addForm.title "></el-input>
		    </el-form-item>
			<el-form-item label="权限路由" prop="name">
			  <el-input v-model="addForm.name "></el-input>
			</el-form-item>
			
			<el-form-item label="菜单" prop="is_menu">
			  <el-switch v-model="addForm.is_menu" :active-value="1" :inactive-value="0" @change="changeIsMenu(addForm)">
				  
			  </el-switch>
			</el-form-item>
			<el-form-item label="菜单隐藏" prop="is_hide" v-if="addForm.is_menu" >
			  <el-switch v-model="addForm.is_hide" value="addForm.is_hide" :active-value="1" :inactive-value="0">
				  
			  </el-switch>
			</el-form-item>
			<el-form-item label="菜单图标" prop="icon" v-if="addForm.is_menu">
			  <el-input v-model="addForm.icon "></el-input>
			</el-form-item>
			
		  </el-form>
		  <!-- 内容底部区域-->
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="addDialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="confirmByForm('')">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
	
</template>

<script>
	/*  */
	export default {
		name: 'AccountPermission',
		data(){
			return{
				permission:[],
				is_hide:0,
				operateName:'',
				operate:'',
				addDialogVisible:false,
				addForm:{
					id:0,
					title:'',
					name:'',
					icon:'',
					is_menu:1,
					is_hide:0
				},
				addFormRules:{
					title:[
						{ required: true, message: '请输入名称', trigger: 'blur' },
					],
					name:[
						{ required: true, message: '请输入路由', trigger: 'blur' },
					],
				}
					
			}
		},
		created(){
			this.getPermission();
		},
		methods:{
			/* 监听添加 */
			addDialogClose(){
				this.$refs.addFormRef.resetFields();
			},
			/* 获取权限 */
			async getPermission(){
				const {data:res} = await this.$http.get('/system/account/getPermission');
				this.permission = res.data;
			},
			/* 监听form菜单变化 */
			changeIsMenu(addForm){
				if(!this.addForm.is_menu){
					this.addForm.is_hide = 0;
					this.addForm.icon = '';
				}
			},
			/* 监听状态 */
			async changeSwitch(row){
				const result = await this.$confirm('是否切换菜单隐藏状态', '提示', {
									  confirmButtonText: '确定',
									  cancelButtonText: '取消',
									  type: 'warning'
									}).catch(err => err);
				if(result == 'confirm'){
					
				}else{
					row.is_hide = !row.is_hide;
					return ;
				}
			},
			/* 添加 */
			addPermission(id){
				this.addForm = {
					id:0,
					title:'',
					name:'',
					icon:'',
					is_menu:1,
					is_hide:0
				},
				id != 0 ? this.addForm.id = id :this.addForm.id = 0;
				this.operate = 1;
				this.operateName = '添加权限';
				this.addDialogVisible = true;
			},
			/* 编辑 */
			editPermission(row){
				this.operate = 2;
				this.operateName = '编辑权限';
				this.addForm = {...row};
				this.addDialogVisible = true;
				
			},
			/* 删除 */
			delPermission(row){
				this.operate = 3;
				this.confirmByForm(row.id);
			},
			/* 操作确认 */
			confirmByForm(id){
				if(this.operate == 1){
					this.confirmAddByForm();
				}else if (this.operate == 2) {
					this.confirmEditByForm();
				}else if (this.operate == 3){
					this.confirmDelByForm(id);
				}	
			},
			/* 添加确认 */
			async confirmAddByForm(){
				this.$refs.addFormRef.validate(async valid=>{
					if(!valid) return;
					const {data:res} = await this.$http.post('/system/account/addPermission',this.addForm);
					if(res.code){
						this.$message.success("新增成功");
						this.addDialogVisible = false;
						this.getPermission();
					}else{
						this.$message.error("新增失败");
						return;
					}
				});
			},
			/* 编辑确认 */
			async confirmEditByForm(){
				if(typeof(this.addForm.list)!="undefined"){
					delete this.addForm['list'];
				}
				this.$refs.addFormRef.validate(async valid=>{
					if(!valid) return;
					const {data:res} = await this.$http.post('/system/account/editPermission',this.addForm);
					if(res.code){
						this.$message.success("修改成功");
						this.addDialogVisible = false;
						window.location.reload();
						this.getPermission();
					}else{
						this.$message.error("修改失败");
						return;
					}
				});
			},
			/* 删除确认 */
			async confirmDelByForm(id){
				const result = await this.$confirm('是否确认删除该权限(子菜单也会删除)', '提示', {
									  confirmButtonText: '确定',
									  cancelButtonText: '取消',
									  type: 'warning'
									}).catch(err => err);
				if('confirm' == result){
					await this.$prompt("敏感操作,请输入当前用户名",'提示',{
							 confirmButtonText: '确定',
							 cancelButtonText: '取消',
						}).then(async ({value})=>{
							let username = window.sessionStorage.getItem('username');
							if(username == value){
								const {data:res} = await this.$http.get('/system/account/delPermission/'+id);
								if(res.code){
									this.$message.success("删除成功");
									this.getPermission();
								}else{
									this.$message.error("删除失败");
								}
							}else{
								this.$message.error('输入有误！');
								return;
							}
						}).catch(err => err);
				}
			}
		}
	}
</script>

<style>
</style>
