/* ------课程分类API-------------- */
export async function addCourse (params) {
	const { data: res } = await this.$http.post('/organization/course/classification', params);
	if (res.code == 0) {
		this.$message.success('添加课程分类成功');
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getCourse (page) {
	const { data: res } = await this.$http.get('/organization/course/classification?page=' + page);
	if (res.code == 0) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getCourseDetail (courseId) {
	const { data: res } = await this.$http.get('/organization/course/classification/' + courseId);
	if (res.code == 0) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function delCourse (courseId) {
	const { data: res } = await this.$http.delete('/organization/course/classification/' + courseId);
	if (res.code == 0) {
		this.$message.success('删除课程分类成功');
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function modifyCourse (courseId, params) {
	const { data: res } = await this.$http.put('/organization/course/classification/' +  courseId, params);
	if (res.code == 0) {
		this.$message.success('修改课程分类成功');
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getCourseChangeLists (params) {
	const { data: res } = await this.$http.get('/student/cost/lesson/replace/log', { params });
	if (res.code == 0) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}