<template>
  <!-- 班级信息 -->
  <div class="container" v-if="historyClass.length > 0">
    <div class="container-left">
      <div class="left-header">班级历史</div>
      <el-timeline v-for="item in historyClass" :key="item.id">
        <el-timeline-item :timestamp="item.created_at" placement="top">
          <el-card>
            <div class="flex justify-between align-center">
              <h4 class="mg-tb-10">{{item.name}}</h4>
              <span v-if="item.desc=='在读中'" style="color: #27b148;font-weight: 700;">{{item.desc}}</span>
              <span v-else style="color: #ff2525;font-weight: 700;">{{item.desc}}</span>
            </div>
            <div v-for="(time,index) in item.class_time" :key="'class_'+index">
              <span>{{time.start_time}}-{{time.end_time}}</span>
              <!-- {{time.week.toString()}} -->
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>

    <div class="container-right pd-10">
      <div class="fs-12 flex align-center" style="justify-content: center;">
        视图类型
        <el-radio-group v-model="viewType" size="mini" class="mg-l-10">
          <el-radio-button label="日期视图">日期视图</el-radio-button>
          <el-radio-button label="图表视图">图表视图</el-radio-button>
        </el-radio-group>
      </div>
      <!-- /日期视图 -->
      <div class="mg-t-10" v-show="viewType == '日期视图'">
        <div class="consumerGradeDetail-header">
          <el-badge :value="analysis.record" class="item" type="primary">
            <el-button size="small" circle type="text" disabled>出勤</el-button>
          </el-badge>
          <el-badge :value="analysis.leave" class="item" type="warning">
            <el-button size="small" circle type="text" disabled>请假</el-button>
          </el-badge>
          <el-badge :value="analysis.absent" class="item" type="danger">
            <el-button size="small" circle type="text" disabled>旷课</el-button>
          </el-badge>
          <el-badge :value="analysis.untreated" class="item" type='info'>
            <el-button size="small" circle type="text" disabled>未处理</el-button>
          </el-badge>
        </div>
        <div>
        </div>
        <el-calendar v-model="date">
          <template slot="dateCell" slot-scope="{date, data}">
            <div :class="data.isSelected ? 'is-selected' : ''">
              {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔' : ''}}
            </div>
            <!-- <div class="blue budge"></div> -->
            <div style="" v-for="(item,index) in getTimeArray(attendence_time.date,date)" :key="'attendence_'+index">
              <div style="display: flex; font-size: 10px;background-color: #909399;text-align: center;padding:5px;border-top: #BD2C00 1px solid;color: #FFFDEF;" v-if="attendence_time.status[item] == 4">
                未处理
              </div>
              <div style="display: flex; font-size: 10px;background-color: #F56C6C;text-align: center;text-align: center;padding:5px;" v-if="attendence_time.status[item] == 3">
                旷课
              </div>
              <div style="display: flex; font-size: 10px;background-color: #E6A23C;text-align: center;text-align: center;padding:5px;" v-if="attendence_time.status[item] == 2">
                请假
              </div>
              <div style="display: flex; font-size: 10px;background-color: #409EFF;text-align: center;text-align: center;padding:5px;" v-if="attendence_time.status[item] == 1">
                出勤
              </div>
            </div>

          </template>
        </el-calendar>
        <!-- <div v-if="attendance.length > 0">
					<div class="atten-container mg-l-10">
						<div v-for="item in attendance" class="flex justify-between w-per-100 mg-b-10 pd-lr-20 align-center">
							<div><el-badge is-dot type="primary"></el-badge>&nbsp;{{item.name}}</div>
							<div>{{item.date}}</div>
							<div>{{item.time_start}}</div>
							<div>{{item.time_end}}</div>
							<div>
								<el-select v-model="item.a_status" placeholder="请选择" size="small" @change="attendanceChange(item.id,item.a_status)"
								style="width: 150px;"
								>
								    <el-option
								      v-for="item in $param.attendanceStatus"
								      :key="item.name"
								      :label="item.name"
								      :value="item.val">
								    </el-option>
							    </el-select>
							</div>
						</div>
					</div>
				</div>
				<div v-else>当日没有考勤</div> -->
      </div>
      <!-- /图表视图 -->
      <div v-show="viewType == '图表视图'" class="mg-t-10" style="position: relative;">
        <!-- <div class="text-align-c fs-20">功能准备中...</div> -->
        <el-row v-if="true">
          <el-col :span="8">
            <el-select v-model="screen.a_status" size="small" clearable @change="screenChange">
              <el-option v-for="(item, index) in attendanceStatus" :key="'attendanceSelect' + index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-date-picker v-model="screen.date" type="daterange" size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="screenChange">
            </el-date-picker>
          </el-col>
        </el-row>
        <el-table v-if="true" :data='tableData' size='small'>
          <el-table-column prop='className' label='班级' min-width='100'></el-table-column>
          <el-table-column prop='' label='课时' min-width='100'>
            <template slot-scope="scope">
              {{scope.row.date}} &nbsp; {{scope.row.name}} <br />{{scope.row.time_start}}-{{scope.row.time_end}}
            </template>
          </el-table-column>
          <el-table-column prop='type' label='班级类型' width='70' align="center">
            <template slot-scope="scope">
              {{scope.row.type | getClassType}}
            </template>
          </el-table-column>
          <el-table-column prop='course_content' label='上课内容' min-width='120' align="center"></el-table-column>
          <el-table-column prop='a_status' label='考勤状态' width='70' align="center">
            <template slot-scope="scope">
              {{attendanceStatus[scope.row.a_status - 1].name}}
            </template>
          </el-table-column>
          <el-table-column prop='' label='操作' width='110' fixed="right" align="center">
            <template v-slot="scope">
              <el-dropdown trigger="click" @command="modifyAttendance(scope.row, $event)">
                <el-button type="primary" size="mini">
                  更改考勤
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in attendanceStatus" :key="'attendanceDropown' + index" :command="item.id">{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mg-t-10" small background layout="total, prev, pager, next" :total="pagination.total" :current-page.sync="screen.page" @current-change='getAnalysisList'>
        </el-pagination>
        <loading-c :show-loading='loading'></loading-c>
      </div>
    </div>
  </div>
  <div v-else>该学员暂未分班</div>
</template>

<script>
import { ATTENDANCE_STATUS } from "../../../assets/js/CONFIG/student.js";
import { CLASS_TYPE } from "../../../assets/js/CONFIG/class.js";
import { getAnalysisList } from "../../../assets/js/API/class-hour.js";
import { updateAttendance } from "../../../assets/js/API/student.js";
import loadingC from "../../common/loading.vue";
export default {
  name: "StudentClassInfo",
  components: {
    loadingC,
  },
  props: ["studentId"],
  data() {
    return {
      loading: true,
      flag_getAnalysisList: true, //true表示能请求图表视图的列表 false表示不能
      viewType: "日期视图",
      screen: {
        page: 1,
        date: [],
        a_status: "",
      },
      tableData: [],
      attendanceStatus: ATTENDANCE_STATUS,
      pagination: {
        total: 0,
      },
      classType: CLASS_TYPE,

      date: "",
      sid: 0,
      analysis: "",
      attendance: [],
      historyClass: [],
      attendence_time: {
        date: [],
        status: [],
      },
      count: 0,
    };
  },
  watch: {
    /* 监测时间变化 */
    date(val) {
      this.count++;
      val = new Date(val);
      this.sid = this.studentId ? this.studentId : this.$route.query.id;
      this.date =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      let temp = -1;
      this.attendance.forEach((item, index) => {
        if (item.date == this.date) {
          temp = index;
          return false;
        }
      });
      if (temp != -1 && this.count > 2) {
        var cid = this.attendance[temp].cid;
        var date = this.attendance[temp].date;
        var time_start = this.attendance[temp].time_start;
        var time_end = this.attendance[temp].time_end;
        this.$router.push(
          "/education/student-state?cid=" +
            cid +
            "&date=" +
            date +
            "&time_start=" +
            time_start +
            "&time_end=" +
            time_end +
            "&sid=" +
            this.sid
        );
      }
      this.getAttendance();
      this.getStudentHistoryClass();
    },
    viewType(val) {
      if (val === "图表视图" && this.flag_getAnalysisList)
        this.getAnalysisList();
    },
  },
  filters: {
    getClassType(classType) {
      var index = CLASS_TYPE.findIndex((item) => item.id == classType);
      return CLASS_TYPE[index].name;
    },
  },
  created() {
    this.date = this.getNowFormatDate();
  },
  methods: {
    screenChange() {
      this.screen.page = 1;
      this.getAnalysisList();
    },
    getAnalysisList() {
      this.loading = true;
      var params = { ...this.screen };
      params.date &&
        params.date.length == 2 &&
        ((params.date_start = params.date[0]),
        (params.date_end = params.date[1]));
      params.sid = this.$route.query.id || this.studentId;
      delete params.date;
      this.flag_getAnalysisList = false;
      getAnalysisList
        .call(this, params)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.tableData = res.data;
            this.pagination.total = res.total;
          }
        })
        .catch((err) => (this.loading = false));
    },
    modifyAttendance(row, command) {
      if (row.a_status == command)
        return this.$message.info(
          "考勤状态已是" + this.attendanceStatus[command - 1].name
        );
      var text =
        "是否将考勤状态变更为" + this.attendanceStatus[command - 1].name;
      var params = {
        status: command,
        reason: "",
      };
      if (command == 2) {
        //请假
        this.$prompt("请输入请假原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^.+$/,
          inputErrorMessage: "请输入请假原因",
        })
          .then(({ value }) => {
            params.reason = value;
            this.updateAttendance(row.id, params);
          })
          .catch();
      } else {
        this.$confirm(text, "提示")
          .then((confirm) => {
            this.updateAttendance(row.id, params);
          })
          .catch();
      }
    },
    updateAttendance(attendanceId, params) {
      this.loading = true;
      updateAttendance
        .call(this, attendanceId, params)
        .then((res) => {
          this.loading = false;
          if (res) this.getAnalysisList();
        })
        .catch((err) => (this.loading = false));
    },

    /* 获取时间数组 */
    getTimeArray(arr, dates) {
      let tempArr = [];
      var datetime = this.formatDate(dates);
      arr.forEach((item, index) => {
        if (item == datetime) {
          tempArr.push(index);
        }
      });
      return tempArr;
    },
    formatDate(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    /* 根据时间获取考勤 */
    async getAttendance() {
      const { data: res } = await this.$http.get(
        "education/studentAttendance?sid=" + this.sid + "&date=" + this.date
      );
      if (res.code == 200) {
        this.analysis = res.data.stastics;
        this.attendance = res.data.attendance;
        let tempTime = {
          date: [],
          status: [],
        };
        res.data.attendance.forEach((item, index) => {
          tempTime.date[index] = item.date;
          tempTime.status[index] = item.a_status;
        });
        this.attendence_time = tempTime;
      }
    },
    /* 改变考勤 */
    async attendanceChange(id, status) {
      const { data: res } = await this.$http.patch(
        "education/attendance/" + id,
        {
          status: status,
        }
      );
      if (res.code == 200) {
        this.getAttendance();
        this.$message.success("更新成功");
      }
    },
    /* 获取今天日期 */
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    /*获取学员历史班级  */
    async getStudentHistoryClass() {
      const { data: res } = await this.$http.get(
        "education/classesHistory/" + this.sid
      );
      if (res.code == 200) {
        this.historyClass = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
div.container {
  width: 100%;
  display: flex;
  justify-content: space-between;

  > .container-left {
    width: 30%;
  }

  > .container-right {
    width: 70%;
  }
}

.budge {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  font-size: 10px;
  margin: 5px auto;
}

.red {
  color: #f56c6c;
  background-color: #f56c6c;
}

.grey {
  color: #909399;
  background-color: #909399;
}

.orange {
  color: #e6a23c;
  background-color: #e6a23c;
}

.blue {
  color: #409eff;
  background-color: #409eff;
}

.left-header,
.right-header {
  font-weight: 700;
  margin-bottom: 10px;
}

.is-selected {
  color: #409eff;
}

.time {
  height: 20%;

  > .el-calendar {
    height: 100%;
  }
}

.consumerGradeDetail-header {
  margin: 10px auto;
  width: 86%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

/deep/ .is-dot {
  transform: translate(0, 1px);
}
</style>
