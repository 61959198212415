<template>
  <!-- 班级信息表格 -->
  <div style="position: relative;">
    <div class="header mg-t-10" v-if="operation">
      <el-button type="primary" @click="selectClassTypeShow = true" size="small">新增班级</el-button>
    </div>
    <div class="mg-t-10 mg-b-10" v-if="operation">
      <div class="flex align-center w-per-100">
        <div class="banner-left">
          <el-input type="text" style="width: 300px;" v-model="screen.key" size="small" placeholder="请输入关键字,如班级名称" @blur="keyChange">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="keyChange" clearable></i>
          </el-input>
        </div>
        <el-select v-model="screen.status" size="small" class="mg-lr-10" placeholder="请选择班级状态" clearable @change="statusChange">
          <el-option v-for="(item,index) in $param.classIsOpen" :label="item.name" :value="item.val" :key="index"></el-option>
        </el-select>
        <el-select v-model="screen.course_classification_id" size="small" class="mg-lr-10" placeholder="请选择课程" clearable @change="courseChange">
          <el-option v-for="(item,index) in courseLists" :label="item.name" :value="item.id" :key="index"></el-option>
        </el-select>
        <div class="flex align-center mg-lr-10 w-per-60">
          <div style="font-size: 13px;color: dimgrey;">学员适龄</div>
          <div class=" mg-lr-10">
            <el-input type="text" class="mg-lr-10 w-ab-90" placeholder="1" max="50px" v-model="screen.age_start" size="small"></el-input>-
            <el-input type="text" class="mg-lr-10 w-ab-90" placeholder="999" min="500" v-model="screen.age_end" size="small"></el-input>
            <el-button size="small" type="primary" @click='checkAGE'>确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs mg-t-10" v-if="operation">
      <div class="flex tabsHeader">
        <div class='tabsItem' style='border-top-left-radius: 5px;' :class="{selectTabs: screen.type == 1}" @click="typeChange(1)">常规班级</div>
        <div class='tabsItem' style='' :class="{selectTabs: screen.type == 3}" @click="typeChange(3)">体验班级</div>
        <div class='tabsItem' style='border-top-right-radius: 5px;' :class="{selectTabs: screen.type == 4}" @click="typeChange(4)">活动班级</div>
        <div class="flex-1" style="border-bottom: 1px solid #dcdfe6;"></div>
      </div>
    </div>
    <el-table :data="classLists" ref="refTable" @current-change='current_change($event)' row-key="id" class="fsMedia" :highlight-current-row='isHightlight' :row-class-name="tableRowClassName">
      <el-table-column prop="className" show-overflow-tooltip label="班级名称" align="center" min-width="100"></el-table-column>
      <el-table-column prop="classroomName" label="上课场地" min-width="100" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="适龄范围" show-overflow-tooltip align="center">
        <template v-slot="scope">
          {{scope.row.age_start}}-{{scope.row.age_end}}岁
        </template>
      </el-table-column>
      <el-table-column label="班级人数" show-overflow-tooltip prop="studentCount" align="center"></el-table-column>
      <el-table-column label="主课老师" show-overflow-tooltip prop="teacherName" align="center"></el-table-column>
      <el-table-column label="课时总时" show-overflow-tooltip prop="lesson" align="center"></el-table-column>
      <el-table-column label="上课时间" show-overflow-tooltip prop="class_time" align="center">
        <template v-slot="scope">
          <span style="color: #409eff;" class="cursor-p" @click='showClassTime(scope.row)'>查看</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="每节消耗课时" show-overflow-tooltip width="150px" prop="reservation_fee" align="center"></el-table-column> -->
      <el-table-column label="班级容量" show-overflow-tooltip prop="classCapacity" align="center"></el-table-column>
      <el-table-column label="场地容量" show-overflow-tooltip prop="capacity" align="center"></el-table-column>
      <el-table-column label="操作" fixed="right" align="center" min-width="150px" v-if="operation">
        <template v-slot="scope">
          <el-button size="small" type="primary" @click="$router.push('/education/showClass/' + scope.row.id)">查看</el-button>
          <!-- <el-button v-if='scope.row.course_classification_id != 0' size="small" type="primary" @click="$router.push('/education/tempAddHour?cid=' + scope.row.id + '&course_classification_id=' + scope.row.course_classification_id)">临时调入</el-button> -->
          <el-button size="small" type="primary" @click="$router.push('/education/adjustment/' + scope.row.id)">查看排课</el-button>
          <el-button v-if="scope.row.studentCount == 0" size="small" type="primary" @click="delClass(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="screen.page" layout="total, prev, pager, next" background class="mg-t-10" @current-change='pageChange'></el-pagination>

    <!-- /加载动画 -->
    <loading-c :show-loading="loading"></loading-c>

    <el-dialog :visible.sync="classTimeDialogShow" title="上课时间" center>
      <div v-for="(item, index) in classTime" :key="index">
        <div class="box_top flex align-center class_time">
          <div>
            <el-tag v-for="(item1, index1) in item.week" :key="index1" class="tag">星期{{weekMap[item1]}}</el-tag>
          </div>
        </div>
        <div class="box_buttom">
          <el-tag class="tag">{{item.start_time + '-' + item.end_time}}</el-tag>
        </div>
        <div :class="{'line': index != classTime.length - 1}"></div>
      </div>
    </el-dialog>
    <!-- 选择创建班级类型 -->
    <el-dialog :visible.sync="selectClassTypeShow" center title="选择班级类型" width="30%">
      <div class="flex align-center space-around">
        <img src="../../assets/image/openClassBtn1.png" class="classTypeImgSize cursor-p" @click="createdClass('/education/courseManager/addClass?type=' + 1)" />
        <img src="../../assets/image/openClassBtn2.png" class="classTypeImgSize cursor-p" @click="createdClass('/education/courseManager/addClass?type=' + 3)" />
        <img src="../../assets/image/openClassBtn3.png" class="classTypeImgSize cursor-p" @click="createdClass('/education/courseManager/addClass?type=' + 4)" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getClass,
  delClass
} from '../../assets/js/API/class.js'
import {
  getCourse
} from '../../assets/js/API/course.js'
import {
  WEEKMAP
} from '../../assets/js/myMethods.js'
import {
  extend
} from '../../assets/js/util.js'
import loadingC from './loading.vue'
export default {
  name: 'ClassTable',
  components: {
    loadingC
  },
  props: {
    isHightlight: {
      type: Boolean,
      default: false
    },
    selectRowIndex: {
      default: -1
    },
    operation: {
      type: Boolean,
      default: true
    },
    classType: { //班级类型 1-常规班级 3- 体验班级
      default: ''
    }
  },
  data() {
    return {
      loading: true,
      screen: {
        key: '',
        type: 1,
        course_classification_id: '',
        age_start: 1,
        age_end: 999,
        page: 1,
        status: ''
      },
      courseLists: [],
      classLists: [],
      total: 0,
      classTimeDialogShow: false,
      classTime: [],
      weekMap: WEEKMAP,
      selectClassTypeShow: false,
      rowIndex: -1
    }
  },
  methods: {
    createdClass(url) {
      this.selectClassTypeShow = false;
      this.$router.push(url)
    },
    courseChange(courseId) {
      this.screen.course_classification_id = courseId;
      this.getClass()
    },
    typeChange(type) {
      this.screen.type = type;
      this.screen.page = 1;
      this.getClass()
    },
    keyChange() {
      this.getClass()
    },
    statusChange() {
      this.getClass()
    },
    checkAGE() {
      var reg = /^[\d]+$/;
      if (reg.test(this.screen.age_start) && reg.test(this.screen.age_end) && this.screen.age_start - 0 < this.screen.age_end - 0) {
        this.getClass()
      }
      else this.$message.error('请检查年龄范围输入')
    },
    getCourse() {
      getCourse.call(this)
        .then(res => {
          if (res) this.courseLists = res
        })
        .catch(err => console.log(err))
    },
    delClass(row) {
      this.$confirm('此操作将永久删除该班级, 是否继续?', '提示')
        .then(() => {
          delClass.call(this, row.id)
            .then(res => {
              if (res) this.getClass()
            })
            .catch(err => console.log(err))
        })
        .catch(e => { })
    },
    getClass() {
      var params = { ...this.screen };
      for (let i in params) {
        (params[i] == '') && (delete params[i])
      }
      this.loading = true;
      getClass.call(this, params)
        .then(res => {
          this.loading = false;
          if (res) {
            this.classLists = res.data;
            this.total = res.total
          }
        })
        .catch(err => this.loading = false)
    },
    showClassTime(row) {
      this.classTime = JSON.parse(row.class_time);
      this.classTimeDialogShow = true
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.rowIndex == rowIndex) return 'hight-light-row'
      return ''
    },
    current_change($event) {
      this.rowIndex = -1;
      this.$emit('current_change', $event)
    },
    pageChange(page) {
      this.screen.page = page;
      this.getClass();
    },
    returnIndex() {
      this.rowIndex = this.classLists.findIndex(item => {
        return item.id == this.selectRowIndex
      });
    }
  },
  created() {
    (this.classType) && (this.screen.type = this.classType);
    this.getCourse();
    this.getClass();
  },
  watch: {
    'selectRowIndex': function (val) {
      this.$refs.refTable.setCurrentRow()
      this.returnIndex()
    }
  }
}
</script>

<style lang="less" scoped>
.tabs {
  /* box-shadow: 0 2px 4px rgba(0,0,0,.12),0 0 6px 0 rgba(0,0,0,.04); */
}
.tabsHeader {
  /* background-color: #f5f7fa; */
  height: 40px;
  line-height: 40px;
  /* border: 1px solid #dcdfe6; */
}
.tabsItem {
  cursor: pointer;
  padding: 0 20px;
  font-size: 14px;
  color: #909399;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid #dcdfe6;
}
.selectTabs {
  /* border: 1px solid transparent; 
		box-sizing: border-box; */
  transition: all 0.3s;
  color: #409eff !important;
  background-color: #fff;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
  border-bottom-color: rgba(0, 0, 0, 0);
}
.tag {
  margin: 0 10px 5px 0;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(209, 209, 209, 0.274);
  margin: 10px 0;
}
.classTypeImgSize {
  vertical-align: middle;
  width: 30%;
}
</style>
