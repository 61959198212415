<template>
<!-- 面包屑导航 -->
  <div class="breadcrumb">
			<el-breadcrumb separator="/" class="fs-12">
			  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item v-for="(item, index) in value_breadcrumb"
        :key="index"
        >{{item}}</el-breadcrumb-item>
			</el-breadcrumb>
  </div>
</template>
<script>
export default {
  name: 'BreadCrumb',
  props: ['value_breadcrumb']
}
</script>
<style lang="lass" scoped>

</style>