// 2021-04-09由FZY创建 用于通知配置

export const CLASS_REMIND = [{
		id: '0',
		name: '关闭'
	},
	{
		id: '1',
		name: '1小时'
	},
	{
		id: '2',
		name: '2小时'
	},
	{
		id: '3',
		name: '3小时'
	},
]
export const EXPIRATION = [{
		id: '3',
		name: '不限制'
	},
	{
		id: '1',
		name: '上课前一日'
	},
	{
		id: '2',
		name: '当天'
	},
]
export const REST_CLASS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
export const LEAVE_TIME = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
