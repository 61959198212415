<!-- 班级详情 -->
<template>
  <div>
    <div v-show="loading" class='loadingHeight' style="transform: translate(0,70px);z-index: 9999;position:absolute;top:0;left:0;width: 100%;background-color: rgba(0, 0, 0, 0.44);text-align: center;display: flex;;align-items: center;">
      <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);color: #FFFDEF;">
        <i class="el-icon-loading" style="display: block;font-size: 40px;"></i>
        <div>数据正在存储，请耐心等待...</div>
      </div>
    </div>
    <el-card style="position: relative;">
      <el-breadcrumb separator="/" class="fs-12">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>班级信息</el-breadcrumb-item>
        <el-breadcrumb-item>班级详细信息</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="班级学员" style="position: relative;">
          <div class="flex text-align flex-wrap box">
            <div class="box_student mg-r-10 cursor-p flex flex-column align-center space-around" v-for="(item, index) in form.student" :key="'student_' + index" @click="showStudentInfo(item)">
              <div class="box_img">
                <img :src="item | avatar" alt="头像">
              </div>
              <div class="box_name fs-12 textNoWarp">{{item.name}}</div>
              <i class="el-icon-close icon_close" @click.stop="click_del(item.id)"></i>
            </div>
            <div class="box_icon mg-r-10 cursor-p" @click="click_add">
              <div>
                <i class="el-icon-plus"></i>
                添加
              </div>
            </div>
            <!-- <div class="box_icon mg-r-10 cursor-p">
					<i class="el-icon-minus"></i>
				</div> -->
            <div class="box_icon mg-r-10 cursor-p" @click="click_delAll" v-if="form.student.length > 0">
              <div>
                <i class="el-icon-brush"></i>
                删除所有
              </div>

            </div>
            <!-- <p v-else class="c-ffa939">暂无班级学员</p> -->
          </div>
          <div class="tag-scheduling" @click="$router.push('/education/adjustment/' + id)">查看排课</div>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="班级类型">
              <el-select placeholder="请选择班级类型" style="width: 100%;" v-model="form.type" disabled>
                <el-option v-for="(item,index) in classTypeLists" :key="'classType' + index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="启用状态">
              <template>
                <el-radio v-model="form.status" :label="1">启用</el-radio>
                <el-radio v-model="form.status" :label="2">关闭</el-radio>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="班级名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属课程" prop="course_classification_id">
              <el-select style="width: 100%;" v-model="form.course_classification_id" clearable disabled :placeholder="form.type == 4 ? '活动班级不需要课程' : '课程不能修改'">
                <el-option v-for="(item ,index) in courseLists" :key="'classification_'+index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上课时间">
              <el-input v-model="form.class_time" readonly @focus="focus_time"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="课时总数" prop="lesson">
              <el-input v-model="form.lesson"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="开班日期" prop="start_date">
              <div class="block">
                <el-date-picker v-model="form.start_date" type="date" @change="change_picker" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :disabled="!isNaN(id - 0)" style="width: 100%;">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结束日期" prop="end_date">
              <div class="block">
                <el-date-picker v-model="form.end_date" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="pickerOptions" style="width: 100%;">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="消耗课时" prop="consume_lesson">
              <el-input placeholder="消耗课时" v-model="form.consume_lesson"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="适龄范围">
              <el-col :span="10">
                <el-form-item prop="age_start">
                  <el-input v-model="form.age_start" @blur="blur_age_start"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" class="text-align-c">
                ——
              </el-col>
              <el-col :span="10">
                <el-form-item prop='age_end'>
                  <el-input v-model="form.age_end" @blur="blur_age_end"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上课场地">
              <el-select v-model="form.classroom" placeholder="请选择" @change='change_place' style="width: 100%;">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="主课老师" prop="mainTeacher">
              <el-input v-model="form.mainTeacher.name" readonly @focus="focus_teather('mainTeacher')"></el-input>
              <i class="el-icon-close cursor-p" style="position: absolute;right: 10px;top: 50%;transform: translate(0, -50%);" @click="clearTeacher('mainTeacher')"></i>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级容量" prop="capacity">
              <!-- '请输班级容量,最大不能超过' + classCapacity -->
              <el-input v-model="form.capacity" placeholder="请输班级容量">
                <template slot="append">人</template>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <!-- 				<el-row>
					<el-col :span="12">
						<el-form-item label="备选老师">
							<el-input v-model="form.otherTeacher.name" readonly @focus="focus_teather('otherTeacher')"></el-input>
							<i class="el-icon-close cursor-p" style="position: absolute;right: 10px;top: 50%;transform: translate(0, -50%);"
							 @click="clearTeacher('otherTeacher')"></i>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="配课老师">
							<el-input v-model="form.alternativeTeacher.name" readonly @focus="focus_teather('alternativeTeacher')"></el-input>
							<i class="el-icon-close cursor-p" style="position: absolute;right: 10px;top: 50%;transform: translate(0, -50%);"
							 @click="clearTeacher('alternativeTeacher')"></i>
						</el-form-item>
					</el-col>
				</el-row> -->

      </el-form>
      <div class="boxButton mg-t-10">
        <div class="box_left button" @click="$router.push('/education/classManager')">
          取消
        </div>
        <div class="box_right button" @click="click_button_confirm">
          保存
        </div>
      </div>
    </el-card>
    <!-- 上课时间对话框 -->
    <v-course-time v-if="dialogVisible_time" ref="courseTimeRef" @sub="sub"></v-course-time>
    <!-- 上课时间对话框/ -->
    <!-- 老师对话框 -->
    <el-dialog :title="title_dialog" :visible.sync="dialogVisible_taether" width="50%" v-if="dialogVisible_taether">
      <teacher-c :operation="false" @current_change='teacherCurrentChange' :selectRowIndex='getSelectRowID_teacher' :isHightlight="true"></teacher-c>
    </el-dialog>
    <!-- 添加学员对话框 -->
    <el-dialog title="添加班级学员" :visible.sync="dialogVisible_student" width="30%" center>
      <el-input placeholder="请输入学员姓名" v-model="screen.name" size="small" @blur="getStudentByCourse" style="width: 80%;" clearable @change="getStudentByCourse"></el-input>
      <div style="height: 300px;overflow-y: auto;">
        <el-table ref="multipleTable" :data="studentLists" tooltip-effect="dark" style="width: 100%" @selection-change="studentHandleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="姓名" min-width="150" prop="name"></el-table-column>
          <el-table-column prop="sex" label="性别" width="80">
            <template slot-scope="scope">{{ scope.row.sex == 1 ? '男' : (scope.row.sex == 2 ? '女' : '未知') }}</template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-pagination style="margin-bottom: 10px;margin-top: -20px" background layout="total, prev, pager, next" :current-page.sync="screen.page" :total="pagination.total" @current-change="getStudentByCourse">
        </el-pagination>
        <el-button @click="dialogVisible_student = false">取 消</el-button>
        <el-button type="primary" @click="addStudent">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加学员对话框/ -->
    <!-- 学员信息 -->
    <el-drawer :visible.sync="drawer" size='70%'>
      <div style="overflow-y: auto;" class="drawerHeight" v-if="!destory">
        <student-file-single-c :show-breadcrumb="false" :student-id="selectStudentInfo.id" :student-name="selectStudentInfo.name"></student-file-single-c>
      </div>
    </el-drawer>
  </div>
</template>
<script>
// 上课时间
import vCourseTime from "../../common/v-courseTime.vue";
import { showImg_sex, checkDate, checkAge } from "../../../assets/js/myMethods";
import feeInfo from "../../student/form/studentFeeInformation.vue";

import studentFileSingleC from "../../student/studentFileSingle.vue";
import { getClassRoom } from "../../../assets/js/API/classroom.js";
import { getClassInfo, modifyClass } from "../../../assets/js/API/class.js";
import { getCourse } from "../../../assets/js/API/course.js";
import { extend } from "../../../assets/js/util.js";
import { getStudentByCourse } from "../../../assets/js/API/student.js";
import teacherC from "../../common/iteration-teacher-table.vue";
import studentC from "../../common/iteration-student-table.vue";
import vali from "../../../assets/js/validate.js";
import { CLASS_TYPE } from "../../../assets/js/CONFIG/class.js";
export default {
  name: "ClassInfo",
  components: {
    vCourseTime,
    feeInfo,
    teacherC,
    studentC,
    studentFileSingleC,
  },
  props: ["id"],
  data() {
    return {
      screen: {
        page: 1,
        name: "",
      },
      pagination: {
        total: 0,
      },
      selectStudents: [],
      studentLists: [],
      courseLists: [],
      teacherType: "", //main-主课老师 spare-配课老师 alternative-备选老师
      loading: false,
      destory: true,
      selectStudentInfo: {
        id: "",
        name: "",
      },
      drawer: false,
      classTypeLists: CLASS_TYPE,
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < Date.now();
        },
      },
      classCapacity: 0,
      rules: {
        // name: vali.validatorCommon.required_blur.concat(vali.validatorCommon.name_class),
        lesson: vali.validatorCommon.required_blur.concat(
          vali.validatorCommon.hours_class
        ),
        start_date: [
          vali.validatorCommon.required_blur[0],
          {
            validator: (rule, val, callback) => {
              if (checkDate(val, this.form.end_date)) {
                return callback(new Error("开始日期不能大于结束日期"));
              }
              return callback();
            },
            trigger: "blur",
          },
        ],
        end_date: [
          vali.validatorCommon.required_blur[0],
          {
            validator: (rule, val, callback) => {
              if (checkDate(this.form.start_date, val)) {
                return callback(new Error("结束日期不能小于开始日期"));
              }
              return callback();
            },
            trigger: "blur",
          },
        ],
        age_start: [
          vali.validatorCommon.required_blur[0],
          {
            validator: (rule, val, callback) => {
              if (checkAge(val, this.form.age_end)) {
                return callback(new Error("最小年龄不能大于最大年龄"));
              }
              return callback();
            },
            trigger: "blur",
          },
        ],
        age_end: [
          vali.validatorCommon.required_blur[0],
          {
            validator: (rule, val, callback) => {
              if (checkAge(this.form.age_start, val)) {
                return callback(new Error("最大年龄不能小于最小年龄"));
              }
              return callback();
            },
            trigger: "blur",
          },
        ],
        capacity: [
          ...vali.validatorCommon.number,
          /* {
							validator: (rule, value, callback) => {
								if (value > this.classCapacity) return callback(new Error('班级容量不能大于场地容量'))
								callback()
							},
							trigger: 'blur'
						} */
        ],
        course_classification_id: [
          {
            validator: (rule, value, callback) => {
              if (this.form.type == 1 && value == "")
                return callback(new Error("请选择所属课程"));
              return callback();
            },
            trigger: "change",
          },
        ],
        mainTeacher: [
          {
            validator: (rule, value, callback) => {
              if (this.form.type == 1 && (value.id == "" || value.name == ""))
                return callback(new Error("请选择主课老师"));
              return callback();
            },
            trigger: "change",
          },
        ],
        consume_lesson: [
          ...vali.validatorCommon.mandatory("请输入消耗课时"),
          ...vali.validatorCommon.price,
        ],
      },
      showTime: [],
      teacher: "main", //main主课老师  -- spare配课老师   alternative--备选老师
      title_dialog: "主课老师",

      dialogVisible_taether: false,
      dialogVisible_time: false,
      dialogVisible_student: false,
      options: [],
      form: {
        student: [],
        type: "",
        name: "",
        course_classification_id: "",
        class_time: "",
        lesson: "",
        start_date: "",
        end_date: "",
        age_start: "",
        age_end: "",
        classroom: "",
        mainTeacher: {},
        alternativeTeacher: {},
        otherTeacher: {},
        status: 1,
        capacity: "",
        consume_lesson: "2",
      },
    };
  },
  computed: {
    getSelectRowID_teacher() {
      var id = "";
      this.form[this.teacherType] &&
        this.form[this.teacherType].id &&
        (id = this.form[this.teacherType].id);
      return id;
    },
  },
  watch: {
    drawer: function (val) {
      if (val) this.destory = false;
      else {
        setTimeout(() => {
          this.destory = true;
        }, 100);
      }
    },
  },
  filters: {
    avatar: function (val) {
      if (val.avatar) return val.avatar;
      else {
        return showImg_sex(val.sex);
      }
    },
  },
  created() {
    this.getClassRoom();
    this.getCourse();
    this.getClassInfo();
  },
  methods: {
    addStudent() {
      this.dialogVisible_student = false;
      this.selectStudents.forEach((item) => {
        var bool = this.form.student.some((item1) => {
          return item1.id == item.id;
        });
        if (!bool) this.form.student.push(item);
      });
    },
    studentHandleSelectionChange(selection) {
      this.selectStudents = selection;
    },
    getStudentByCourse() {
      var params = { ...this.screen };
      params.course_classification_id = this.form.course_classification_id
        ? this.form.course_classification_id
        : "";
      getStudentByCourse
        .call(this, params)
        .then((res) => {
          if (res) {
            this.studentLists = res.data;
            this.pagination.total = res.total;
            this.$nextTick(() => {
              this.studentLists.forEach((item) => {
                let bool = this.form.student.some((student) => {
                  return student.id == item.id;
                });
                if (bool)
                  this.$refs.multipleTable.toggleRowSelection(item, true);
              });
            });
          }
        })
        .catch((err) => console.log(err));
    },
    clearTeacher(key) {
      this.form[key].id = "";
      this.form[key].name = "";
    },
    getClassRoom() {
      getClassRoom
        .call(this, 1)
        .then((res) => {
          if (res) this.options = res.data;
        })
        .catch((err) => console.log(err));
    },
    getClassInfo() {
      getClassInfo
        .call(this, this.id)
        .then((res) => {
          if (res) {
            extend(this.form, res);
            this.form.type += "";

            this.form.classroom = res.classroom.id;
            (this.form.mainTeacher == null || this.form.mainTeacher == "") &&
              (this.form.mainTeacher = {
                id: "",
                name: "",
              });
            (this.form.alternativeTeacher == null ||
              this.form.alternativeTeacher == "") &&
              (this.form.alternativeTeacher = {
                id: "",
                name: "",
              });
            (this.form.otherTeacher == null || this.form.otherTeacher == "") &&
              (this.form.otherTeacher = {
                id: "",
                name: "",
              });
            this.form.course_classification_id = res.course_classification_id
              ? res.course_classification_id
              : "";
            this.sub(JSON.parse(this.form.class_time));
            this.classCapacity = res.classroom.capacity;
            this.selectStudents = [...this.form.student];
          }
        })
        .catch((err) => console.log(err));
    },
    getCourse() {
      getCourse
        .call(this, 1)
        .then((res) => {
          if (res) {
            this.courseLists = res;
          }
        })
        .catch((err) => console.log(err));
    },
    teacherCurrentChange(row) {
      this.form[this.teacherType] = {
        id: row.id,
        name: row.name,
      };
      this.dialogVisible_taether = false;
    },
    focus_teather(key) {
      this.teacherType = key;
      this.dialogVisible_taether = true;
    },
    click_add() {
      this.dialogVisible_student = true;
      if (this.studentLists.length !== 0) return;
      this.getStudentByCourse();
    },
    async click_button_confirm() {
      let bool = await this.$refs.form.validate().catch((err) => {});
      if (!bool) return;
      var params = { ...this.form };
      params.class_time = this.showTime;
      params.date = [params.start_date, params.end_date];
      params.classCapacity = params.capacity;
      /* params.mainTeacher = params.mainTeacher.id ? params.mainTeacher.id : '';
				params.alternativeTeacher = params.alternativeTeacher.id ? params.alternativeTeacher.id : '';
				params.otherTeacher = params.otherTeacher.id ? params.otherTeacher.id : ''; */
      params.classroom = this.options.filter((item) => {
        return item.id == params.classroom;
      })[0];
      params.student = params.student.map((item) => {
        return item.id;
      });
      this.loading = true;
      modifyClass
        .call(this, this.id, params)
        .then((res) => {
          if (res) {
            this.loading = false;
            setTimeout(() => {
              this.$router.push("/education/classManager");
            }, 300);
          }
        })
        .catch((err) => (this.loading = false));
    },
    showStudentInfo(item) {
      this.selectStudentInfo.id = item.id;
      this.selectStudentInfo.name = item.name;
      this.$nextTick(() => {
        this.drawer = true;
      });
    },
    change_picker(e) {
      this.form.end_date = "";
    },
    blur_age_start() {
      var val = this.form.age_start;
      if (val <= 0) {
        this.$message.error("最小年龄不能小于1");
        return (this.form.age_start = 1);
      }
    },
    blur_age_end() {
      var val = this.form.age_end;
      if (val > 999) {
        this.$message.error("最大年龄不能大于999");
        return (this.form.age_end = 999);
      }
    },
    async click_delAll() {
      var isConfirm = await this.$confirm(
        "您确认要删除全部的学员吗？",
        "提示"
      ).catch((e) => {});
      if (isConfirm === "confirm") {
        this.form.student = [];
        this.selectStudents = [];
      }
    },
    async click_del(id) {
      var isConfirm = await this.$confirm("您确认要删除这个学员吗？", "提示");
      if (isConfirm === "confirm") {
        var index = this.form.student.findIndex((item) => {
          return item.id == id;
        });
        index != -1 &&
          (this.form.student.splice(index, 1),
          this.selectStudents.splice(index, 1));
      }
    },
    change_place(val) {
      var index = this.options.findIndex((item) => {
        return item.id == val;
      });
      index != -1 && (this.classCapacity = this.options[index].capacity);
    },
    focus_time() {
      this.dialogVisible_time = true;
      this.$nextTick(() => {
        this.$refs.courseTimeRef.visible = true;
        this.$refs.courseTimeRef.item = this.showTime;
      });
    },
    /* 赋值 上课时间 */
    sub(row) {
      this.showTime = [];
      row.forEach((item) => {
        this.showTime.push({
          start_time: item.start_time,
          end_time: item.end_time,
          week: item.week,
          checkAll: item.week.length == 7 ? true : false,
        });
      });
      this.form.class_time = this.getClassTime(this.showTime);
      this.dialogVisible_time = false;
    },
    /* 获取显示上课时间 */
    getClassTime(row) {
      let str = "";
      row.forEach((item) => {
        let weekStr = "";
        item.week.forEach((item) => {
          switch (item) {
            case 1:
              weekStr = weekStr + "星期一  ";
              break;
            case 2:
              weekStr = weekStr + "星期二  ";
              break;
            case 3:
              weekStr = weekStr + "星期三  ";
              break;
            case 4:
              weekStr = weekStr + "星期四  ";
              break;
            case 5:
              weekStr = weekStr + "星期五  ";
              break;
            case 6:
              weekStr = weekStr + "星期六  ";
              break;
            case 7:
              weekStr = weekStr + "星期天  ";
              break;
          }
        });
        weekStr =
          "   " +
          weekStr +
          " | " +
          item.start_time +
          "   " +
          item.end_time +
          ",";
        str = str + weekStr;
      });
      return str;
    },
  },
};
</script>
<style scoped lang="less">
.tag-scheduling {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 2px 4px;
  height: 25px;
  line-height: 25px;
  border-radius: 2px;
  background-color: #3e9bf7;
  color: #fff;
}

.loadingHeight {
  height: calc(100vh - 70px);
}

.drawerHeight {
  height: calc(100vh - 150px);
}

.box {
  max-height: 200px;
  overflow: auto;
}

.box_student {
  padding: 10px 20px;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  max-width: 90px;
  height: 100px;
  box-sizing: border-box;
}

.box_img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  background-color: rgb(243, 83, 83);
  border-radius: 50%;
}

.box_img img {
  width: 100%;
}

.box_name {
  max-width: 50px;
  line-height: 12px;
  margin-top: 6px;
}

.box_icon {
  width: 90px;
  height: 100px;
  box-sizing: border-box;
  background-color: rgb(235, 235, 235);
  display: flex;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  padding-top: 10px;
}

.box_icon i {
  font-size: 40px;
  line-height: 40px;
  transform: translate(-50%, 0);
  margin-left: 50%;
}

.icon_close {
  position: absolute;
  top: 3px;
  right: 3px;
}

.boxButton {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
}

.button {
  padding: 12px 100px;
  box-sizing: border-box;
  background-color: #409eff;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
}

.box_left {
  margin-right: 10px;
}

.box_right {
  margin-left: 10px;
}
</style>
