<template>
	<!-- 角色管理 -->
	<div>
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>系统管理</el-breadcrumb-item>
		  <el-breadcrumb-item>账号管理</el-breadcrumb-item>
		  <el-breadcrumb-item>角色管理</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 卡片 -->
		<el-card>
			<el-button type="primary" icon="el-icon-circle-plus-outline" @click="addRole(0)" size="small">添加角色</el-button>
			<!-- 表格 -->
			<el-table
				:data='role'
				border class="fsMedia"
			>
				<el-table-column type="index" label="#">
					
				</el-table-column>
				
				<el-table-column prop="name" label="名称">
					
				</el-table-column>
				
				<el-table-column prop="des" label="描述">
					
				</el-table-column>
				
				<el-table-column
				  label="操作"
				 >
				 <template  slot-scope="scope">
					<el-button type="primary"  size="small" @click="editRole(scope.row)" class="fsMedia">编辑</el-button>
					<el-button type="primary" size="small" @click="delRole(scope.row)" class="fsMedia">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<!-- form -->
		<el-dialog
		  title="添加权限"
		  :visible.sync="addDialogVisible"
		  width="30%"
		   @close="addDialogClose"
		  >
		  <!-- 内容主题区域-->
		  <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
		    <el-form-item label="角色名称" prop="name">
		      <el-input v-model="addForm.name"></el-input>
		    </el-form-item>
			<el-form-item label="角色描述" prop="des">
			  <el-input v-model="addForm.des"></el-input>
			</el-form-item>
			<el-form-item label="角色权限">
			  <!-- tree-->
			  <el-tree
			     :data="permissionList"
			     show-checkbox
			     node-key="id"
				:default-checked-keys="permissionByRole"
			    :props="defaultProps"
				ref="treeRef">
			  </el-tree>
			</el-form-item>
		  </el-form>
		  
		  <!-- 内容底部区域-->
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="addDialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="Comfirm()">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'AccountRole',
		data(){
			return{
				role:[],
				addDialogVisible:false,
				addForm:{
					id:'',
					name:'',
					des:'',
					permission:'',
				},
				addFormRules:{
					name:[
						{ required: true, message: '请输入名称', trigger: 'blur' },
					],
					des:[
						{ required: true, message: '请输入描述', trigger: 'blur' },
					],
				},
				/* 树形配置 */
				defaultProps: {
				  children: 'list',
				  label: 'title'
				},
				permissionList:[],
				permissionByRole:[],
				operate:'',
			}
		},
		created(){
			this.getRole();
			
		},
		methods:{
			/* 监听添加 */
			addDialogClose(){
				this.$refs.addFormRef.resetFields();
			},
			/* 获取权限根据角色 */
			async getPermissionByRole(){
				this.permissionList = [];
				this.permissionByRole = [];
				const {data:res} = await this.$http.get('/system/account/getRolePermission/'+this.addForm.id);
				if(res.code){
					this.permissionByRole = res.data;
					this.getPermissionId(res.data);
					 this.addDialogVisible = true;
				}
			},
			/* 递归获取树的节点ids */
			getPermissionId(datas){
			   for(var i in datas){
				   if(datas[i].pid == 0 && !datas[i].list){
					    this.permissionByRole.push(datas[i].id) 
				   }
				   if(datas[i].pid != 0 && !datas[i].list ){
					    this.permissionByRole.push(datas[i].id) 
				   }
				   if(datas[i].list){
						this.getPermissionId(datas[i].list);
				   }
				 }
			},
			/* 获取所有权限 */
			async getPermission(){
				const {data:res} = await this.$http.get('/system/account/getPermission');
				this.permissionList = res.data;
				
			},
			/* 编辑角色 */
			editRole(row){
				this.operate = 2;
				this.addForm = {...row};
				this.getPermissionByRole();
				this.getPermission();
				
			},
			/* 添加角色 */
			addRole(){
				this.addForm={
					id:'',
					name:'',
					des:'',
					permission:'',
				}
				this.operate = 1;
				this.permissionByRole = [];
				this.addDialogVisible = true;
				this.getPermission();
			},
			/* 获取角色列表 */
			async getRole(){
				const {data:res} = await this.$http.get('/system/account/getRoles');
				if(res.code){
					this.role = res.data;
				}
			},
			/* 确认 */
			Comfirm(){
				const key = [
					...this.$refs.treeRef.getCheckedKeys(),
					...this.$refs.treeRef.getHalfCheckedKeys()
				]
				this.addForm.permission = key; 
				if(this.operate == 1){
					this.addByConfirm();
				}else if(this.operate == 2){
					this.editConfirm();
				}
			},
			/* 添加确认 */
			async addByConfirm(){
				this.$refs.addFormRef.validate(async valid=>{
					if(!valid) return;
					const {data : res} = await this.$http.post('/system/account/addRole',this.addForm);
					if(res.code){
						this.$message.success(res.msg);
						this.addDialogVisible = false;
						this.getRole();
					}else{
						this.$message.error("失败");
					}
				});
			},
			/* 编辑确认 */
			async editConfirm(){
				this.$refs.addFormRef.validate(async valid=>{
				if(!valid) return;
					const {data : res} = await this.$http.post('/system/account/updateRole',this.addForm);
					if(res.code){
						this.$message.success(res.msg);
						this.addDialogVisible = false;
						this.getRole();
					}else{
						this.$message.error("失败");
					}
				});
			},
			/* 删除确认 */
			async delRole(row){
				const result = await this.$confirm('是否确认删除该角色(权限也会删除)', '提示', {
									  confirmButtonText: '确定',
									  cancelButtonText: '取消',
									  type: 'warning'
									}).catch(err => err);
				if('confirm' == result){
					await this.$prompt("敏感操作,请输入当前用户名",'提示',{
							 confirmButtonText: '确定',
							 cancelButtonText: '取消',
						}).then(async ({value})=>{
							let username = window.sessionStorage.getItem('username');
							if(username == value){
								const {data:res} = await this.$http.get('/system/account/delRole/'+row.id);
								if(res.code){
									this.$message.success("删除成功");
									this.getRole();
								}else{
									this.$message.error("删除失败");
								}
							}else{
								this.$message.error('输入有误！');
								return;
							}
						}).catch((err) => {
							return;
						}
						);
				}
			}
		}
	}
</script>

<style>
</style>
