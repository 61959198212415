<template>
	<!-- 续费学员列表 -->
	<div>
		<data-analysis-search :defaultData="defaultData" @getScreen='getScreen' lastMonth='上月' thisMonth='当月' yesterday="昨天" today="今天" sevenDay="7天"></data-analysis-search>
		<el-table :data='tableData' stripe>
			<el-table-column prop="school_name" label='校区' width="150"></el-table-column>
			<el-table-column prop="student_name" label='学员名称'></el-table-column>
			<el-table-column prop="teacher_name" label='教师名称'></el-table-column>
			<el-table-column prop="cost_lesson" label='购买课时'></el-table-column>
			<el-table-column prop="give_lesson" label='赠送课时'></el-table-column>
			<el-table-column prop="lesson" label='总课时'></el-table-column>
			<el-table-column prop="discount" label='折扣'>
				<template v-slot='scope'>
					{{scope.row.discount == 0 ? '无折扣' : scope.row.discount}}
				</template>
			</el-table-column>
			<el-table-column prop="reduction_amount" label='减免价格'></el-table-column>
			<el-table-column prop="total_cost" label='原价'>
				<template v-slot="scope">
					{{scope.row.total_cost | insertSeparator}}
				</template>
			</el-table-column>
			<el-table-column prop="paid_amount" label='实付金额'>
				<template v-slot="scope">
					{{scope.row.paid_amount | insertSeparator}}
				</template>
			</el-table-column>
			<el-table-column prop="created_at" label='购买时间' min-width="100"></el-table-column>
		</el-table>
		<el-pagination :current-page="screen.page" :total="total" background layout="total, prev, pager, next" class="mg-t-10" @current-change='pageChange'></el-pagination>
	</div>
</template>

<script>
	import dataAnalysisSearch from './data-analysis-search.vue'
	import {
		extend,
		insertMoneySeparator
	} from '../../../assets/js/util.js'
	export default {
		name: 'StudentRenewList',
		data () {
			return {
				total: 0,
				tableData: [],
				screen: {
					page: 1,
					date_start: '',
					date_end: '',
					school_id: '',
					number: 10
				},
				defaultData: {}
			}
		},
		filters: {
			insertSeparator (val) {
				return insertMoneySeparator(val)
			}
		},
		methods: {
			pageChange (page) {
				this.screen.page = page;
				this.getStudentRenew()
			},
			keyChange () {
				this.screen.page = 1;
				this.getStudentRenew()
			},
			getScreen (screen) {
				this.screen.page = 1;
				extend(this.screen, screen)
				this.getStudentRenew()
			},
			async getStudentRenew () {
				var { data: res } = await this.$http.get('/statistics/student/renew', {
					params: this.screen
				})
				if (res.code == 0) {
					this.tableData = res.data.data;
					this.screen.page = res.data.page;
					this.total = res.data.total;
				} else {this.$message.error(res.message)}
			}
		},
		components: {
			dataAnalysisSearch
		},
		created() {
			var { defaultScreen } = this.$attrs;
			if (defaultScreen) {
				this.defaultData = {...defaultScreen};
				extend(this.screen, defaultScreen);
				this.screen.date_start = defaultScreen.date[0];
				this.screen.date_end = defaultScreen.date[1]
				this.getStudentRenew()
			}						
		}
	}
</script>

<style>
</style>
