// 2021-03-30 由FZY创建 用于财务请求


/* 审批消息提示文本 */
var mapText = {
	2: '已同意',
	3: '已驳回'
}

/**
	@describe 报销审批
	@date 2021-04-27
	@by FZY
 */
export async function processReimbursement(data) {
	var { data: res } = await this.$http.post('/organization/financial/reimbursement/process', data);
	if (res.code == 0) {
		this.$message.success(`${mapText[data.status]}`)
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 借款审批
	@date 2021-04-27
	@by FZY
 */
export async function processLoan(data) {
	var { data: res } = await this.$http.post('/organization/financial/loan/process', data);
	if (res.code == 0) {
		this.$message.success(`${mapText[data.status]}`)
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 付款审批
	@date 2021-04-27
	@by FZY
 */
export async function processPayment(data) {
	var { data: res } = await this.$http.post('/organization/financial/payment/audit', data);
	if (res.code == 0) {
		this.$message.success(`${mapText[data.status]}`)
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 发票审批
	@date 2021-04-27
	@by FZY
 */
export async function processInvoice(data) {
	var { data: res } = await this.$http.post('/organization/financial/invoice/process', data);
	if (res.code == 0) {
		this.$message.success(`${mapText[data.status]}`)
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 采购审批
	@date 2021-04-27
	@by FZY
 */
export async function processProcurement(data) {
	var { data: res } = await this.$http.post('/organization/financial/procurement/approve', data);
	if (res.code == 0) {
		this.$message.success(`${mapText[data.status]}`)
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 退费审批
	@date 2021-04-27
	@by FZY
 */
export async function processRefund(data) {
	var { data: res } = await this.$http.post('/organization/financial/refund/process', data);
	if (res.code == 0) {
		this.$message.success(`${mapText[data.status]}`)
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 导出校区经营指标的校区财务运营指标
	@date 2021-04-01
	@by FZY
 */
export async function exportExcelOfCost(params) {
	var { data: res } = await this.$http.get('/organization/financial/exportDataOfCost', { params });
	if (res.code == 200) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 获取校区经营指标的净收入、成本统计、现金利润
	@date 2021-03-30
	@by FZY
 */
export async function getIndicators(params) {
	var { data: res } = await this.$http.get('/organization/financial/indexList', { params });
	if (res.code == 200) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 获取校区经营指标的收入项
	@date 2021-03-30
	@by FZY
 */
export async function getIndicatorsIncome(params) {
	var { data: res } = await this.$http.get('/organization/financial/itemOfReceipt', { params });
	if (res.code == 200) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 获取校区收入排名
	@date 2021-03-31
	@by FZY
 */
export async function getSchooleRanking(data) {
	var { data: res } = await this.$http.post('/organization/financial/wholeSchoolRank', data);
	if (res.code == 200) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 获取校区经营指标的成本项
	@date 2021-03-31
	@by FZY
 */
export async function getCost(params) {
	var { data: res } = await this.$http.get('/organization/financial/getCost', { params });
	if (res.code == 200) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 添加校区经营指标的成本项
	@date 2021-03-31
	@by FZY
 */
export async function addCost(data) {
	var { data: res } = await this.$http.post('/organization/financial/addCost', data);
	if (res.code == 200) {
		this.$message.success('添加成本项成功')
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/**
	@describe 修改校区经营指标的成本项
	@date 2021-03-31
	@by FZY
 */
export async function modifyCost(data) {
	var { data: res } = await this.$http.put('/organization/financial/upCost', data);
	if (res.code == 200) {
		this.$message.success('修改成本项成功')
		return res.data
	} else this.$message.error(res.message || res.msg)
}