<template>
	<!-- 学生退费 -->
	<div class="w-per-100">
		<div class="text-align-r fs-12" style="color: #888;">
			学员ID： {{sid}}
		</div>
		<div class="mg-t-10">
			<h2 class="title">学员课包明细<span class="subTitle mg-l-20">可退金额 = 课包金额 - 剩余购买课时 * (课包金额/购买课时)</span></h2>
			<div class="line"></div>
			<div class="boxCourseBagScroll">
				<div v-for="(item, index) in courseBagInfo" :key='index'>
					<div class="flex">
						<div class="boxCourseBag flex align-center">
							<img src="../../../assets/image/refund1.svg" class="image"/>
							<h5 class="courseBagName mg-l-10">{{item.course_classification_name}}/{{item.course_package_name}}</h5>
						</div>
						<div class="boxLesson align-center flex">
							<div class="text-align-r">
								<div class='subTitle' style="height: 25px;line-height: 25px;">总课时：</div>
								<div class='lave' style="height: 25px;line-height: 25px;">剩余：</div>
							</div>
							<div>
								<div class='subTitle' style="height: 25px;line-height: 25px;">{{item.lesson}}</div>
								<div class='lave' style="height: 25px;line-height: 25px;">{{item.lave_cost_lesson - 0 + item.lave_give_lesson - 0}}</div>
							</div>
						</div>
						<div class="boxCostLesson align-center flex">
							<div class="text-align-r">
								<div class='subTitle' style="height: 25px;line-height: 25px;">购买课时：</div>
								<div class='lave' style="height: 25px;line-height: 25px;">剩余：</div>
							</div>
							<div>
								<div class='subTitle' style="height: 25px;line-height: 25px;">{{item.cost_lesson}}</div>
								<div class='lave' style="height: 25px;line-height: 25px;">{{item.lave_cost_lesson}}</div>
							</div>
						</div>
						<div class="boxGiveLesson align-center flex">
							<div class="text-align-r">
								<div  class='subTitle'style="height: 25px;line-height: 25px;">赠送课时：</div>
								<div class='lave' style="height: 25px;line-height: 25px;">剩余：</div>
							</div>
							<div>
								<div class='subTitle' style="height: 25px;line-height: 25px;">{{item.give_lesson - 0 + item.special_gift_lesson - 0}}</div>
								<div class='lave' style="height: 25px;line-height: 25px;">{{item.lave_give_lesson}}</div>
							</div>
						</div>
						<div class="boxRefundMoney  align-center flex">
							<div class="text-align-r">
								<div class='subTitle' style="height: 25px;line-height: 25px;">课包金额：</div>
								<div class='lave' style="height: 25px;line-height: 25px;">应退金额：</div>
							</div>
							<div>
								<div class='subTitle' style="height: 25px;line-height: 25px;">￥{{item.paid_amount}}</div>
								<div class='lave' style="height: 25px;line-height: 25px;">￥{{item.refund_amount | filterPrice}}</div>
							</div>
						</div>
					</div>
					<div class="line"></div>
				</div>
			</div>
			<div class="boxReturnableMoney text-align-r c-ff7e00 mg-t-10"><span>合计可退：￥{{getReturnableMoney}}</span></div>
		</div>		
		<div v-if="isaddORmodify">
			<h2 class="title">收款信息</h2>
			<div class="line"></div>
			<el-form label-position="right" label-width="100px" :rules="rules" :model="collectionInfo" ref='refForm'>
				<el-row>
					<el-col :span="8">
						<el-form-item label="收款账户" prop="name">
							<el-input v-model="collectionInfo.name" size="medium" placeholder="请输入收款账户"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="联系人" prop="contract_name">
							<el-input v-model="collectionInfo.contract_name" size="medium" placeholder="请输入联系人"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="联系方式" prop="tel">
							<el-input v-model="collectionInfo.tel" size="medium" placeholder="请输入联系方式"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="银行卡号" prop="card">
							<el-input v-model="collectionInfo.card" size="medium" placeholder="请输入银行卡号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="再次输入" prop="card_confirm">
							<el-input v-model="collectionInfo.card_confirm" size="medium" placeholder="请再次输入银行卡号"></el-input>
						</el-form-item>		
					</el-col>
					<el-col :span="8">
						<el-form-item label="开户地" prop="account_opening">
							<el-input v-model="collectionInfo.account_opening" size="medium" placeholder="请输入开户地"></el-input>
						</el-form-item>	
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="开户支行" prop='branch_name'>
							<el-input v-model="collectionInfo.branch_name" size="medium" placeholder="请输入开户支行"></el-input>
						</el-form-item >
					</el-col>
					<el-col :span="8">
						<el-form-item label="退费原因" prop='reason'>
							<el-input v-model="collectionInfo.reason" size="medium" placeholder="请输入退费原因"></el-input>
						</el-form-item >
					</el-col>
				</el-row>
			</el-form>
		</div>
		
		<div>
			<h2 class="title">退费明细<span class="subTitle mg-l-20">应退金额 = 可退金额 - 其他费用(如礼品费用等)</span></h2>
			<div class="line"></div>
			<div class="">其他费用<span class="addFee mg-l-10 cursor-p" @click="addFee" v-if="isaddORmodify">＋添加费用</span><span class="subTitle mg-l-20">费用可以为负数；整数是减去、负数是增加</span></div>
			<div class="boxAddFeeScroll mg-t-10">
				<transition name="fade">
					<div class="flex flex-wrap align-center w-per-100">					
						<div style="" class="boxAddFee flex align-center justify-between" v-for="(item, index) in addFeeInfo" :key="index + '1'" :class="{'margin-left5per': index % 3 != 0}">							
							<el-input placeholder='请输入名称' :disabled="!isaddORmodify" size="medium" v-model="item.name" @focus="clearError(item, 'name')" :class="{'borderRED': (item.error && item.errorKey == 'name')}"></el-input>
							<el-input placeholder='请输入费用' :disabled="!isaddORmodify" size="medium" class="mg-lr-10" @focus="clearError(item, 'amount')" v-model="item.amount" @blur="feeValueBlur(item)" :class="{'borderRED': (item.error && item.errorKey == 'amount')}"></el-input>
							<el-button size="medium" type="info" @click='delFee(item)' :disabled="!isaddORmodify">删除</el-button>
						</div>
					</div>
				</transition>				
			</div>
			<div class="flex justify-between mg-t-10 w-per-100" style="align-items: flex-end;">
				<div class="subTitle" v-if="!isaddORmodify">{{refundTimeDefault}}</div>
				<div v-else></div>
				<div class="text-align-r">
					<div class="subTitle">实退金额 = {{getComputedMoney}}</div>
					<div class="actualMoney c-ff7e00 mg-t-10">实退金额：￥{{getRefundAmount}}</div>
					<el-button type="primary" size="medium" class="mg-t-10" @click='refund' v-if="isaddORmodify">退款申请</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getStudentRefund,
		refund,
		getRefundList
	} from '../../../assets/js/API/charge.js'
	import {
		extend
	} from '../../../assets/js/util.js'
	import vali from '../../../assets/js/validate.js'
	export default {
		name: 'StudentRefund',
		props: ['studentId', 'courseBagInfoDefault', 'addFeeInfoDefault', 'refundTimeDefault'],
		data () {
			return {
				rules: {
					name: vali.validatorCommon.mandatory('请输入收款账户'),
					card: [
						...vali.validatorCommon.mandatory('请输入银行卡号'),
						...vali.validatorCommon.number
					],
					card_confirm: [
						{
							validator: (rule, value, callback) => {
								if (value + ''.trim() != this.collectionInfo.card + ''.trim()) return callback(new Error('两次输入的银行卡号不一致'))
								callback()
							},
							trigger: 'blur'
						}
					],
					account_opening: vali.validatorCommon.mandatory('请输入开户地'),
					branch_name: vali.validatorCommon.mandatory('请输入开户支行'),
					contract_name: vali.validatorCommon.mandatory('请输入联系人'),
					tel: [
						...vali.validatorCommon.mandatory('请输入联系方式'),
						...vali.validatorCommon.tel
					],
					reason: vali.validatorCommon.mandatory('请输入退费原因')
				},
				sid: '',
				courseBagInfo: [],
				addFeeInfo: [],
				collectionInfo: {}
			}
		},
		methods: {
			setDefaultFee (data) {
				var result = [];
				data.forEach( (item, index) => {
					var formData = {
						id: index + 1,
						name: item.name,
						amount: item.amount,
						index: index,
						error: false,
						errorKey: ''
					}
					result.push(formData)
				})
				return result
			},
			async refund () {
				var data = this.addFeeInfo,
					boolConfirm = true,   //调用validate方法返回的值 -- 用来显示提示信息（请确认填写正确）和 验证通过
					boolBlur = true; // 调用费用失去焦点方法返回的值  -- 验证通过
				data.forEach( item => { 
					boolConfirm = this.validate(item)
					boolBlur = this.checkFee(item)					
				});
				var bool = await this.$refs.refForm.validate().catch( err => console.log(err));
				if (!bool) return;
				if (!boolConfirm) return this.$message.error('请确认填写正确')
				if (!boolConfirm && !boolBlur) return				
				var data = this.addFeeInfo;
				var params = {
					sid: this.sid,
					refund_amount: this.getRefundAmount,
					cost_lesson_record: this.courseBagInfo,
					other: [],
					name: '',
					card: '',
					account_opening: '',
					branch_name: '',
					contract_name: '',
					tel: '',
					reason: ''
				}
				extend(params, this.collectionInfo);
				data.forEach( item => {
					var other = {
						name: '',
						amount: ''
					}
					extend(other, item)
					params.other.push(other)
				})
				params.other.forEach( item => {
					item.amount = Number(item.amount)
				})
				refund.call(this, params)
					  .then( res => {
						  this.$emit('refundSuccess', false);
						  this.collectionDialogShow = false;
					  })
					  .catch( err => { console.log(err) })
			},
			clearError (item, key) {
				if (item instanceof Object && key == item.errorKey) {					
					item.error = false;
					item.errorKey = ''
				} else if (item == undefined) {
					var data = this.addFeeInfo;
					data.forEach( item => {
						item.error = false;
						item.errorKey = ''
					})
				}
			},
			validate (item) {
				var result = true
				if (item.name == '' && item.amount == '') {//两个都没输入
					this.delFee(item, 'noConfirm')
				}
				else if (item.name == '' || item.amount == '') { //输入了一个
					item.error = true
					item.name == '' && (item.errorKey = 'name')
					item.amount == '' && (item.errorKey = 'amount')
					result = false
				}
				return result
			},
			checkFee (item) {
				var reg = /^(?:-?[\d]*)(?:\.[\d]{1,2})*$/;
				if (!reg.test(item.amount)) {
					item.error = true;
					item.errorKey= 'amount'
					return false
				} else this.clearError(item, 'amount')
				return true
			},
			feeValueBlur (item) {
				if (!this.checkFee(item)) this.$message.error('费用格式不正确，小数位最多两位');
			},
			delFee (item, key) {
				if (key) {
					var data = this.addFeeInfo;
					var index = data.findIndex( d => {
						return d.index == item.index
					})
					this.addFeeInfo.splice(index, 1);
				} else {
					this.$confirm('您确认要删除这个费用吗?', '提示', {
					          confirmButtonText: '确定',
					          cancelButtonText: '取消',
					          type: 'warning'
					    })
						.then(() => {
							var data = this.addFeeInfo;
							var index = data.findIndex( d => {
								return d.index == item.index
							})
							this.addFeeInfo.splice(index, 1);
						}).catch(() => {});		
				}
		
			},
			addFee () {
				var data = this.addFeeInfo;				
				var formData = {
					id: data.length + 1,
					name: '',
					amount: '',
					index: data.length,
					error: false,
					errorKey: ''
				}
				this.addFeeInfo.push(formData)
			},
			getStudentRefund () {
				getStudentRefund.call(this, this.sid)
								.then( res => {
									console.log(res, 939393)
									this.courseBagInfo = res.data
								})
								.catch( err => {console.log(err)})
			}
		},
		computed: {
			isaddORmodify ()  {  //添加返回true  修改返回false
				if (this.courseBagInfoDefault || this.addFeeInfoDefault) return false
				return true
			},
			getComputedMoney () { // 实退金额计算
				var dataFee = [...this.addFeeInfo],
					result = this.getReturnableMoney;
				dataFee.forEach( item => {
					if (item.name != '' && this.checkFee(item)) {
						if (item.amount - 0 >= 0) {
							result = result + ' - ' + item.amount
						} else {
							result = result + ' + ' + Math.abs(item.amount)
						}
					}
				})
				return result
			},
			getReturnableMoney () {
				var data = this.courseBagInfo,
					result = 0;
				data.forEach( item => {
					result += Number(item.refund_amount)
				})
				return result.toFixed(2)
			},
			getRefundAmount () {
				var dataFee = [...this.addFeeInfo],
					result = this.getReturnableMoney;
				dataFee.forEach( item => {
					console.log(item.name != '' && this.checkFee(item))
					if (item.name != '' && this.checkFee(item)) {
						console.log(item.amount - 0 >= 0,111111)
						if (item.amount - 0 >= 0) {
							result = (result - 0) - (item.amount - 0)
						} else {
							console.log(Number(result) + Math.abs(item.amount-0))
							result = (result - 0) + Math.abs(item.amount-0)
						}
					}
				})
				return Number(result).toFixed(2)
			}
		},
		created() {
			this.studentId && (this.sid = this.studentId, this.getStudentRefund())
		},
		watch: {
			courseBagInfoDefault: function (val) {
				val instanceof Array && (this.courseBagInfo = [...val]);
			},
			addFeeInfoDefault: function (val) {
				val instanceof Array && (this.addFeeInfo = [...val])
			}
		},
		mounted() {
			var courseBag = this.courseBagInfoDefault,
				addFee = this.addFeeInfoDefault;
			courseBag instanceof Array && (this.courseBagInfo = [...courseBag]);
			addFee instanceof Array && (this.addFeeInfo = [...courseBag]);
		}
	}
</script>

<style lang="less" scoped>
	.W100PX{
		width: 100px;
		display: inline-block;
	}
	.fade-enter-active, .fade-leave-active {
	  transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	  opacity: 0;
	}
	.borderRED{
		border: 1px solid red;
	}
	.margin-left5per{
		margin-left: 5%;
	}
	.c-ff7e00{
		color: #ff7e00;
	}
	.line{
		border-bottom: 1px dashed #9b9b9b;
		margin: 15px 0;
	}
	.boxCourseBagScroll{
		padding: 0 0 0 30px;
		max-height: 300px;
		overflow-y: auto;		
	}
	.boxCourseBag{
		width: 30%;
	}
	.boxLesson{
		width: 15%;
	}
	.boxCostLesson{
		width: 15%;
	}
	.boxGiveLesson{
		width: 15%;
	}
	.boxRefundMoney{
		width: 15%;
	}
	.boxAddFeeScroll{
		max-height: 92px;
		overflow-y: auto;
	}
	.title{
		font-weight: 700;
		font-size: 16px;
		color: #333333;
	}
	.subTitle {
		color: #9b9b9b;
		font-size: 14px;
		font-weight: 500;
	}
	.courseBagName{
		font-size: 14px;
		font-weight: 700;
		color: #333333;
	}
	.lave{
		color: #bb0000;
		font-size: 14px;
	}
	.image{
		width: 31px;
		vertical-align: middle;
	}
	.boxReturnableMoney{
		font-size: 14px;
		font-weight: 700;
	}
	.addFee{
		font-weight: 400;
		font-size: 13px;
		color: #0084f9;
	}
	.actualMoney{
		font-size: 16px;
		font-weight: 700;
	}
	.boxAddFee{
		width: 30%;
		max-width: 30%;
		margin-bottom: 10px;
	}
</style>
