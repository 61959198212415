<template>
	<!-- 学员管理 -->
	<div class="containers">
		<!-- 上边 -->
		<div class="top flex fsMedia">
			<!-- 快捷功能 -->
			<el-card class="left-fastOperate w-per-50 box-sizing">
				<div slot="header" class="clearfix">
					<span class='fsMedia-16'>快捷功能</span>
				</div>
				<div class="text-item">
					<!-- <div @click="push('/student/form/studentRegister')">
						<i class="iconfont icon-yixiangxueyuan"></i>
						<div>学员登记</div>
					</div> -->
					<div @click="push('/student/form/studentCourseRegister')">
						<i class="iconfont icon-banji"></i>
						<div>学员报名</div>
					</div>
					<!-- <div @click="$message.info('请从学员档案续费')">
						<i class="iconfont icon-shoufei"></i>
						<div>学员续费</div>
					</div> -->
					<div@click="$message.info('暂不提供')">
						<i class="iconfont icon-buke"></i>
						<div>预约试课</div>
				</div>
				<div @click="$message.info('暂不提供')">
					<i class="iconfont icon-shoufeibaobiao"></i>
					<div>缴纳其他费用</div>
				</div>
		</div>
		</el-card>
		<!-- 学员统计 -->
		<el-card class="right-analyze mg-l-10 w-per-25">
			<div slot="header" class="clearfix">
				<span class='fsMedia-16'>学员统计（人）</span>
			</div>
			<div class="text-item">
				<div>
					<i style="color:#ffbc63;">{{student}}</i>
					<div>登记学员</div>
				</div>
				<div>
					<i style="color:#ffbc63;">{{trash}}</i>
					<div>流失学员</div>
				</div>
			</div>
		</el-card>
		<!-- 分班统计 -->
		<el-card class="mg-l-10 flex-1_1">
			<div slot="header" class="clearfix fsMedia-16">
				分班统计（人次）
			</div>
			<div class="right-header-body text-align-c">
				<div>
					<i style="padding: 10px;">{{hasClass.all}}</i>
					<div>全部</div>
				</div>
				<div>
					<i style="padding: 10px;">{{hasClass.has}}</i>
					<div>已分班</div>
				</div>
				<div>
					<i style="padding: 10px;">{{hasClass.notHas}}</i>
					<div>未分班</div>
				</div>
			</div>
		</el-card>
	</div>
	<!-- 下边 -->
	<div class="bottom flex mg-t-10 fsMedia-16">
		<!-- 新生统计 -->
		<el-card class="w-per-50">
			<div slot="header" class="clearfix">
				<div class="w-per-100  flex align-center">
					<span class="w-ab-120">新生统计</span>
					<el-date-picker v-model="analysis" type="daterange" range-separator="至" format="yyyy-MM-dd " value-format="yyyy-MM-dd"
					 @change="analysisChange" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</div>

			</div>
			<div>
				<ve-histogram :data="chartData"></ve-histogram>
			</div>
		</el-card>
		<!-- 招生来源分布 -->
		<el-card class="mg-l-10 flex-1_1">
			<div slot="header" class="clearfix pd-10">
				招生来源分布
			</div>
			<div>
				<ve-histogram :data="chartDataByz"></ve-histogram>
			</div>
		</el-card>
	</div>
	</div>
</template>

<script>
	export default {
		name: 'Student',
		data() {
			return {
				show_fee: {
					// 头部搜索
					isShow_header: false,
				},
				tableData_fee: {},
				date_start: '',
				date_end: '',
				analysis: [],
				dialog_renewal: false,
				chartData: {
					columns: ['日期', '学员数量'],
					rows: []
				},
				/* 饼 */
				chartDataByz: {
					columns: ['日期', '到访', '地推', '转介绍', '微官网', '招生活动', '其他'],
					rows: []
				},
				student: 0,
				trash: 0,
				hasClass: []
			}
		},
		created() {
			this.analysis = [this.getDay(-7), this.getDay(0)];
			this.getAlysis();
			this.getStudentCount();
			this.getStudentAnalysis();
		},
		methods: {
			/* 获取学员分班统计 */
			async getStudentAnalysis() {
				const {
					data: res
				} = await this.$http.get('student/getStudentAnalysisClass');
				if (res.code == 200) {
					this.hasClass = res.data;
				}
			},
			/* 获取学员数量 */
			async getStudentCount() {
				const {
					data: res
				} = await this.$http.get('student/getStudentCount');
				if (res.code == 200) {
					this.student = res.data.notTrash;
					this.trash = res.data.trash;
				}
			},
			// 将更新对话框的函数保存到本地
			// eve (e) {
			// 	this.setStateDialog = e.func
			// },
			push(name) {
				this.$router.push(name);
			},
			/* 获取分析数据 */
			async getAlysis() {
				this.chartData.rows = [];
				this.chartDataByz.rows = [];
				const {
					data: res
				} = await this.$http.get('student/studentAnalysis?date_start=' + this.analysis[0] + "&date_end=" + this.analysis[1]);
				if (res.code == 200) {
					res.data.student.forEach((item) => {
						this.chartData.rows.push({
							'日期': item.time,
							'学员数量': item.student
						});
					})
					res.data.sources.forEach((item) => {
						this.chartDataByz.rows.push({
							'日期': item.time == null ? 0 : item.time,
							'到访': item.source.df == null ? 0 : item.source.df,
							'地推': item.source.dt == null ? 0 : item.source.dt,
							'转介绍': item.source.zjs == null ? 0 : item.source.zjs,
							'微官网': item.source.wgw == null ? 0 : item.source.wgw,
							'招生活动': item.source.zhaoshen == null ? 0 : item.source.zhaoshen,
							'其他': item.source.qt == null ? 0 : item.source.qt
						});
					})
				}
			},
			getDay(day) {
				var today = new Date();
				var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
				today.setTime(targetday_milliseconds); //注意，这行是关键代码
				var tYear = today.getFullYear();
				var tMonth = today.getMonth();
				var tDate = today.getDate();
				tMonth = this.doHandleMonth(tMonth + 1);
				tDate = this.doHandleMonth(tDate);
				return tYear + "-" + tMonth + "-" + tDate;
			},
			doHandleMonth(month) {
				var m = month;
				if (month.toString().length == 1) {
					m = "0" + month;
				}
				return m;
			},
			analysisChange() {
				this.getAlysis();
			},	
		},
		components: {
		
		},
	}
</script>

<style lang="less" scoped>
	.containers {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: nowrap;
		max-width: 100% !important;

		.left {
			width: 70%;
		}

		.right {
			width: 30%;
		}
	}

	.header {
		>div {
			width: 100%;
		}
	}

	.header-left {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.right-analyze {
			width: 40%;
		}
	}

	.text-item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		>div {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: #969896;
			padding: 10px;
			cursor: pointer;

			>i {
				color: #81d3f8;
				font-size: 30px;
			}
		}

	}

	.right-header-body {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		>div {
			color: #969896;
			padding: 10px;

			>i {
				color: #ffbc63;
				font-size: 30px;
			}
		}
	}

	.footer {
		margin: 10px 0 0 10px;
	}

	.el-date-picker {
		width: 150px;
	}

	/deep/ .el-range-input,
	/deep/ .el-range-separator {
		font-size: 14px !important;
	}

	@media only screen and (max-width: 1366px) {

		/deep/ .el-range-input,
		/deep/ .el-range-separator {
			font-size: 12px !important;
		}
	}

	// .box_renewal{
	// 	display: flex;align-items: center;justify-content: space-between;
	// 	.el-card{
	// 		width: 32%;height: 90px;text-align: center;cursor: pointer;
	// 	}
	// }
</style>
