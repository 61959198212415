import { render, staticRenderFns } from "./education-studentState.vue?vue&type=template&id=39209718&scoped=true&"
import script from "./education-studentState.vue?vue&type=script&lang=js&"
export * from "./education-studentState.vue?vue&type=script&lang=js&"
import style0 from "./education-studentState.vue?vue&type=style&index=0&id=39209718&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39209718",
  null
  
)

export default component.exports