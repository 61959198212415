/**
@describe 请假记录
@date 2021-04-21
@by LZW
*/
<template>
  <div>
    <el-row>
      <el-col :span="3">
        <el-select v-model="screen.status" size="small" style="width: 100%;" placeholder="请选择课程类型" clearable>
          <el-option v-for="(item, index) in statusOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" style="padding: 0 10px;">
        <el-date-picker clearable v-model="screen.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" style="width: 100%" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
      </el-col>
      <el-col :span="5" style="padding-right:10px;">
        <el-input size="small" clearable v-model="screen.key" placeholder="请输入学员ID、姓名、联系电话"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="screenChange">查询</el-button>
      </el-col>
    </el-row>
    <el-table stripe :data="tableData" size="small" class="mg-t-10">
      <el-table-column align="center" prop="sid" label="学员ID"></el-table-column>
      <el-table-column align="center" prop="student_name" label="姓名"></el-table-column>
      <el-table-column align="center" prop="tel" label="联系电话"></el-table-column>
      <el-table-column align="center" prop="leave_type" label="请假类型">
        <template v-slot="scope">
          {{scope.row.leave_type === 1 ? '事假' : '其它'}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="" label="上课时间">
        <template v-slot="scope">
          {{scope.row.date}}<br /> {{scope.row.time_start}}-{{scope.row.time_end}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="leave_time" label="请假时间"></el-table-column>
      <el-table-column align="center" prop="created_at" label="状态">
        <template v-slot="scope">
          {{statusOptions[scope.row.parents_leave_status] ? statusOptions[scope.row.parents_leave_status].name : '-'}}
        </template>
      </el-table-column>
      <el-table-column min-width="130" align="center" prop="reason" label="请假事由"></el-table-column>
      <el-table-column min-width="100" align="center" prop="classes_name" label="上课班级"></el-table-column>
      <el-table-column width="200" align="center" label=" 操作" fixed="right">
        <template v-slot="scoped">
          <el-button v-if="scoped.row.parents_leave_status == 1" type="success" size="mini" @click="actionEvent(2,scoped.row)">同意</el-button>
          <el-button v-if="scoped.row.parents_leave_status == 1" type="danger" size="mini" @click="actionEvent(3,scoped.row)">不同意</el-button>
        </template>
      </el-table-column>
    </el-table>
    <loading-c :show-loading="loading"></loading-c>
  </div>
</template>

<script>
import {
  getLeaveRecord,
  editLeaveRecord,
  updateAttendance
} from "../../assets/js/API/student.js";
import {
  ATTENDANCE_STATUS_PARENT
} from '../../assets/js/CONFIG/student.js'
import loadingC from "../common/loading.vue";
import vali from "../../assets/js/validate";
export default {
  name: 'LeaveRecord',
  components: {
    loadingC,
  },
  data() {
    return {
      loading: false,
      screen: {
        key: "",
        date: [],
        status: 0,
      },
      tableData: [],
      statusOptions: ATTENDANCE_STATUS_PARENT,
    };
  },
  created() {
    this.getLeaveRecord()
  },
  methods: {
    actionEvent(type, row) {
      new Promise((resolve, reject) => {
        switch (type) {
          case 2:
            this.$prompt('请输入请假事由', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /.+/,
              inputErrorMessage: '请输入请假事由'
            }).then(({ value }) => resolve(value))
              .catch(err => reject('false'))
            break;

          case 3:
            this.$confirm("不同意请假记录?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => resolve())
              .catch(err => reject('false'))
            break;

          default:
            reject('false')
            break
        }
      })
        .then(res => {
          this.editLeaveRecord({ aid: row.id, is_agree: type })
            .then(res => {
              if (type == 3) {
                this.loading = false
                return
              }
              this.updateAttendance(row.id, { status: 2, reason: res })
            })
            .catch(err => console.log(err, 2222333))
        })
        .catch(err => { })
    },
    screenChange(e) {
      this.getLeaveRecord()
    },
    updateAttendance(sid, data) {
      updateAttendance.call(this, sid, data)
        .then(res => {
          this.loading = false
          if (res) this.getLeaveRecord()
        })
        .catch(err => this.loading = false)
    },
    editLeaveRecord(data) {
      this.loading = true
      return new Promise((resolve, reject) => {
        editLeaveRecord.call(this, data)
          .then(res => resolve(res))
          .catch(err => {
            this.loading = false
            reject()
          })
      })
    },
    getLeaveRecord() {
      var params = {
        ...this.screen
      };
      params.date &&
        params.date.length == 2 &&
        ((params.date_start = params.date[0]),
          (params.date_end = params.date[1]));
      delete params.date;
      this.loading = true
      getLeaveRecord.call(this, params)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.tableData = res;
          }
        })
        .catch((err) => (this.loading = false));
    }
  },
};
</script>

<style scoped>
</style>
