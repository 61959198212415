// 2021-05-12 由FZY创建 公共数据

export const mapRole = {
	1: "超级管理员",
	2: "校长",
	3: "教务老师",
	4: "教学老师",
	5: "招生老师",
	6: "财务",
	7: "行政",
	8: "督导",
}
