/**
    * @Author LZW
    * @Date 2021年8月18日09:59:01
    * @Title LineCharts 折线图
**/
<template>
    <div>
        <div :id="lineId" :style="lineStyle"></div>
    </div>
</template>
<script>
const echarts = require('echarts');
export default {
    props:{
        option:{
            type:Object,
        },
        lineId:{
            type:String,
            default:'line'
        },
        lineStyle:{
            type:Object,
            default:()=>{
                return {
                    width:'400px',
                    height:'400px',
                }
            }
        }
    },
    data(){
        return{

        }
    },
    mounted(){
        // this.init()
    },
    methods:{
        init(data){
            let Echarts = echarts.init(document.getElementById(this.lineId))
            let option = data||{
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [150, 230, 224, 218, 135, 147, 260],
                    type: 'line'
                }]
            }
            Echarts.clear()
            data && Echarts.setOption(option,true)
        }
    }
}
</script>