// 2021-04-06由 FZY创建 用于课程配置
export const COURSE_TYPE = [{
	id: 1,
	name: '常规课'
},
{
	id: 2,
	name: '小课包'
}
]

export const COURSE_NAMES = [
	{
		id: '',
		name: 'EV3课程'
	},
	{
		id: '',
		name: 'WEDO课程'
	},
	{
		id: '',
		name: '3+DUPLO课程'
	},
	{
		id: '',
		name: '4+DUPLO课程'
	},
	{
		id: '',
		name: '5+DUPLO课程'
	},
	{
		id: '',
		name: 'PYTHON课程'
	},
	{
		id: '',
		name: 'SCRATCH课程'
	}
]
