/* 学生API */
import {
	STUDENT_STATE
} from '../CONFIG/student.js'


/* 
	@describe 处理家长请假
	@date 2021-04-22
	@by FZY
 */
export async function editLeaveRecord (data) {
	const { data: res } = await this.$http.post('/education/editLeaveRecord', data);
	if (res.code === 200) {
		var mapText = {
			1: '待处理',
			2: '同意',
			3: '不同意'
		}
		this.$message.success(`${mapText[data.is_agree]}家长的请假`);
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/* 
	@describe 获取家长请假记录记录
	@date 2021-04-22
	@by FZY
 */
export async function getLeaveRecord (params) {
	const { data: res } = await this.$http.get('/education/getLeaveRecord', {
		params
	});
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/* 
	@describe 获取转增课包记录
	@date 2021-03-29
	@by FZY
 */
export async function getTurnLists (params) {
	const { data: res } = await this.$http.get('/student/cost/lesson/donate/log', {
		params
	});
	if (res.code === 0) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/* 
	@describe 获取学员信息
	@date 2021-03-29
	@by FZY
 */
export async function getStudentInfo (params) {
	const { data: res } = await this.$http.get('/student/cost/lesson/donate/student/info', {
		params
	});
	if (res.code === 0) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}



/* 
	@describe 获取上课课表
	@date 2021-03-12
	@by FZY
 */
export async function getFormalClassSchedule (params) {
	const { data: res } = await this.$http.get('/api/getFormalClassSchedule', {
		params
	});
	if (res.code === 0) {
		return res.data
	}
}

/* 
	@describe 获取学员的作品
	@date 2021-03-10
	@by FZY
 */
export async function getStudentAttendanceByTime (params) {
	const { data: res } = await this.$http.get('/education/getStudentAttendanceByTime', {
		params
	});
	if (res.code === 200) {
		return res.data
	}
}

/* 
	@describe 修改学员预警状态
	@date 2021-03-04
	@by FZY
 */
export async function updateStudentState (params) {
	const { data: res } = await this.$http.patch('/student/updateStudentState', params);
	if (res.code === 200) {
		this.$message.success(`状态已变更为${STUDENT_STATE[params.state - 1].name}`)
		return res.data
	}
}

export async function getStudent (params) {
	const { data: res } = await this.$http.get('/student/info', {
		params
	});
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getStudentByCourse (params) {
	const { data: res } = await this.$http.get('/student/getStudentByCourses', { params });	
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getLeavelStudent (page) {
	const { data: res } = await this.$http.get('student/info/trash/students?page=' + page);	
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}


export async function followUpStudent (id) {
	const { data: res } = await this.$http.patch('/student/info/trash/edit/' + id);
	if (res.code === 200) {
		this.$message.success('学员已重新跟进');
		return res.data
	} else this.$message.error(res.msg || res.message)
}


export async function getLeaveLabel () {
	const { data: res } = await this.$http.get('/student/info/label/all');
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}
export async function addLeaveLabel (params) {
	const { data: res } = await this.$http.post('/student/info/label/add', params);
	if (res.code === 200) {
		this.$message.success('添加标签成功');
		return res.data
	} else this.$message.error(res.msg || res.message)
}
export async function delLeaveLabel (tagId) {
	const { data: res } = await this.$http.delete('/student/info/label/' + tagId);
	if (res.code === 200) {
		this.$message.success('删除标签成功');
		return res.data
	} else this.$message.error(res.msg || res.message)
}
export async function editStudentLeaveConfirm (leaveId, params) {
	const { data: res } = await this.$http.patch('/student/info/leave/' + leaveId, params);
	if (res.code === 200) {
		this.$message.success('学员已流失');
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function updateAttendance (id, params) {
	var { reason, status } = params,
		sid = id;
	if (status != 4) {
		const { data: res } = await this.$http.patch('/education/attendance/' + sid, { status: 4 });
		if (res.code === 200) {
			const { data: res1} = await this.$http.patch('/education/attendance/' + sid, { status, reason });
			if (res1.code == 200) {
				this.$message.success("更改成功");
				return res1.data				
			} else this.$message.error(res1.msg || res1.message)
		} else this.$message.error(res.msg || res.message)
	} else {
		const { data: res } = await this.$http.patch('/education/attendance/' + id, params);
		if (res.code === 200) {
			this.$message.success("更改成功");
			return res.data
		} else this.$message.error(res.msg || res.message)
	}
}

export async function getStudentClass (params) {
	/* 
		@describe 获取班级学员
		@date 2020-02-19
	*/
	const { data: res } = await this.$http.get('/education/attendance/getStudentByDate', {
		params
	});
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function upLoadStudentWorks (params) {
	/* 
		@describe 上传学员作品与评价
		@date 2020-02-06
	*/
	const { data: res } = await this.$http.patch('/education/studentUploadAttendance', params)
	if (res.code === 200) {
		this.$message.success('评语已上传')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function getStudentWorks (id, params) {
	/* 
		@describe 获取班级学员作品
		@date 2020-02-19
		@params cid-班级id date-上课日期 time_start-开始时间 time_end-结束时间
	*/
	const { data: res } = await this.$http.get('/education/attendance/getWorksByStudent/' + id, {
		params
	});
	if (res.code === 200) {
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function allAttendance (params) {
	/* 
		@describe 批量处理考勤（出勤）
		@date 2020-02-19
		@data id-[] status-考勤状态
	*/
	const { data: res } = await this.$http.patch('/education/attendance/patch/all', params);
	if (res.code === 200) {
		this.$message.success('考勤已设置为出勤')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function delStudent (params) {
	/* 
		@describe 删除班级学员
		@date 2020-02-19
		@params aid-考勤id sid-学员Id
	*/
	const { data: res } = await this.$http.delete('/education/attendance/del', { params });
	if (res.code === 200) {
		this.$message.success('学员已从班级移除')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function  callInStudent (params) {
	/* 
		@describe 调入学员到班级
		@date 2020-04-22
	*/
	const { data: res } = await this.$http.post('/education/addClassTimeByIndex' , params)
	if (res.code === 200) {
		this.$message.success('学员已调入')
		return res.data
	} else this.$message.error(res.msg || res.message)
}

export async function  attendanceDel (id) {
	/* 
		@describe 学员考勤删除
		@date 2021-09-2
	*/
	const { data: res } = await this.$http.delete('/education/delAttendance/'+id , {})
	if (res.code === 200) {
		this.$message.success('考勤已删除')
		return res.data
	} else this.$message.error(res.msg || res.message)
}