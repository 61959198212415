/* ******************************************消息API************************************ */
/* 目前只支持type为1 2 5的消息 */
/* 获取未读消息的数量 */
export async function getMsg_NoRead () {
	var { data: res } = await this.$http.get('/notice/type');
	if (res.code == 0) {
		return res.data
	}
}
/* 获取消息列表 */
export async function getMsg (params) {
	/* {
		page: '当前页',
		number: '每页显示的数量，默认5',
		type: '消息类型 1-家长请假、2-家长缴费、3-考勤提醒、4-课后作业、5-费用预警、6-线上预约、7-机构通知、8-系统通知'
	} */
	var { data: res } = await this.$http.get('/notice/news', {
		params
	});
	if (res.code == 0) {
		return res.data
	}
}
/* 标记已读 */
export async function tagMsg (params) {
	/* {
		id: '消息id',
		is_read: '是否已读 1-未读 2-已读'
	} */
	var { data: res } = await this.$http.put('/notice/news', params);
	if (res.code == 0) {
		return res.data
	}
}