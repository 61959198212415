<template>
	<!-- 消息列表 -->
	<div>
		<!-- tab -->
		<msg-c :selectTab="$route.query.tab" @getMsg='getMsg = $event'>
			<template v-slot:leave='{msgList, pagition}'>
				<el-card>
					<msg-list-c :dataTable="msgList" :getMsg='getMsg' :pagition='pagition'></msg-list-c>
				</el-card>				
			</template>
			<template v-slot:cost='{msgList, pagition}'>
				<el-card>
					<msg-list-c :dataTable="msgList" :getMsg='getMsg' :pagition='pagition'></msg-list-c>
				</el-card>	
			</template>
			<template v-slot:term='{msgList, pagition}'>
				<el-card>
					<msg-list-c :dataTable="msgList" :getMsg='getMsg' :pagition='pagition'></msg-list-c>
				</el-card>	
			</template>
			<template v-slot:classTime='{msgList, pagition}'>
				<el-card>
					<msg-list-c :dataTable="msgList" :getMsg='getMsg' :pagition='pagition'></msg-list-c>
				</el-card>	
			</template>
		</msg-c>
	</div>
</template>

<script>
	/* 消息模板 */
	import msgC from '../common/v-msg.vue'
	/* 消息列表模板 */
	import msgListC from './form/msg-list.vue'

	export default {
		name: 'Msg',
		data () {
			return {
				
			}
		},
		components: {
			msgC,
			msgListC
		},
		created() {
			
		}
	}
</script>

<style>
</style>
