<template>
	<!-- 退费记录 -->
	<div class="flex height">
		<div class="w-per-40 scrollHeightLeft">
			<div class="text-align-c fs-14 mg-t-10" style="color: #aaa;" v-if="refundList.length == 0">暂无退费记录</div>
			<div class="cursor-p card mg-t-10" style="position: relative;" v-for="(item, index) in refundList" :key='index' v-else @click="getRefundInfo(item)">
				<div class="flex align-center justify-between card-header">
					<div style="font-weight: bold;color: #aaa;">退费</div>
					<div>
						<span class="fs-15">退费金额：</span><span class="c-8ec1ff">{{item.amount}}</span>元
					</div>					
				</div>
				<div class="flex justify-between card-body" style="align-items: flex-end;">
					<div>
						<p class="flex align-center"><span class="text-align-r fs-14 W100PX">历史消耗课时：</span><span class="c-8ec1ff">{{item.detail.deplete_lesson.history}}</span></p>
						<p class="flex align-center"><span class="text-align-r fs-14 W100PX">本次消耗课时：</span><span class="c-8ec1ff">{{item.detail.deplete_lesson.this_time}}</span></p>
						<p class="flex align-center"><span class="text-align-r fs-14 W100PX">剩余课时：</span><span class="c-8ec1ff">{{item.detail.lave_lesson.lesson}}</span></p>
					</div>
					<div class="text-align-r">
						<p class="flex align-center"><span class="text-align-r fs-14 W100PX">退费时间：</span><span class="c-8ec1ff">{{item.created_at}}</span></p>
					</div>
				</div>
				<div :class="{'select': select == item.id}"></div>
			</div>
		</div>
		<div class="flex-1_1 mg-l-10 mg-t-10">
			<student-refund-list-c ref='refStudnetRefundList' :refundTimeDefault='refundTimeDefault' :courseBagInfoDefault='courseBagInfoDefault' :addFeeInfoDefault='addFeeInfoDefault'></student-refund-list-c>
		</div>
	</div>
</template>

<script>
	import {
		getRefundList
	} from '../../../assets/js/API/charge.js'
	import studentRefundListC from './studentRefund.vue'
	export default {
		name: 'StudentRefundList',
		props: {
			studentId: {
				default: ''
			}
		},
		data () {
			return {
				refundList: [],
				select: '',
				addFeeInfoDefault: [],
				courseBagInfoDefault: [],
				refundTimeDefault: ''
			}
		},
		methods: {
			getRefundList () {
				getRefundList.call(this, this.$route.query.id || this.studentId)
							 .then(res => {
								 this.refundList = res;
								 res.length > 0 && this.getRefundInfo(res[0])
							 })
							 .catch( err => {console.log(err) })
			},
			getRefundInfo (item) {
				if (this.select == item.id) return;
				this.select = item.id;
				this.courseBagInfoDefault = item.detail.cost_lesson_record;
				this.addFeeInfoDefault = this.$refs.refStudnetRefundList.setDefaultFee(item.detail.other);
				this.refundTimeDefault = item.created_at
			}
		},
		components: {
			studentRefundListC
		},
		created() {
			this.getRefundList()			
		}
	}
</script>

<style scoped>
	.select {
		position: absolute;
		background-color: rgb(201 229 239);
		width: 50px;
		height: 50px;
		top: -25px;
		right: -25px;
		transform: rotate(45deg);
	}
	.W100PX {
		width: 100px;
	}
	.scrollHeightLeft{
		height: calc(100vh - 300px);
		overflow-y: auto;
	}
	.card{
		border-radius: 4px;
		border: 1px solid #EBEEF5;
		background-color: #FFF;
		overflow: hidden;
		color: #303133;
		transition: .3s;
	}
	.card-header{
		padding: 18px 20px;
		border-bottom: 1px solid #EBEEF5;
		box-sizing: border-box;
	}
	.card-body{
		padding: 20px;
	}
</style>
