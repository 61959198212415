/* **********************************课包信息API*********************************** */

function stringToNumber (data) {
	var map = ['contract', 'remark', 'class_time', '']
	for( let i in data) {
		if (typeof data[i] == 'string' && map.includes(data[i])) {			
			data[i] = Number(data[i])			
		}
	}
}

/* 
	@describe 课包转增
	@date 2021-03-029
	@by FZY
 */
export async function donate (data) {
	const { data: res } = await this.$http.post('/student/cost/lesson/donate', data);
	if (res.code === 0) {
		this.$message.success('转增成功')
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/* 
	@describe 课包升级
	@date 2021-03-09
	@by FZY
 */
export async function upgradeCostLesson (data) {
	const { data: res } = await this.$http.post('/student/cost/lesson/upgrade', data);
	if (res.code === 0) {
		this.$message.success('课包升级成功')
		return res.data
	} else this.$message.error(res.message || res.msg)
}

/* 
	@describe 获取升级套餐剩余金额
	@date 2021-03-09
	@by FZY
 */
export async function settlement (course_id) {
	const { data: res } = await this.$http.get('/student/cost/lesson/upgrade/settlement?id=' + course_id);
	if (res.code === 0) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

export async function getCostLesson (params) {
	/* {
		sid: '学员id',
		page: '当前页',
		number: '每页默认数量 10' 非必选
	}*/
	var { data: res } = await this.$http.get('/student/cost/lesson/list', {
		params
	})
	if (res.code == 0) {
		return res.data
	}
}

export async function getCostLessonInfo (id) {
	// id为课包id
	var { data: res } = await this.$http.get('/student/cost/lesson/detail?id=' + id)
	if (res.code == 0) {
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function deleteCostLesson (params) {
	/* {
		id: '课包id',
		lesson: '购买课包记录总课时',
		sid: '学员id'
	} */
	stringToNumber(params)
	var { data: res } = await this.$http.delete('/student/cost/lesson', {
		data: params
	})
	if (res.code == 0) {
		this.$message.success('删除课包成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function modifyCostLesson (params) {
	/* {
		sid: '学员id',
		cost_lesson: '购买课时数量',
		give_lesson: '赠送课时数量',
		lesson: '课包课时总数',
		total_cost: '课包价格',
		reduction_amount: '减免金额',
		discount: '折扣，0表示无折扣',
		paid_amount: '实际支付金额',
		charge_value_id: '课包id（价格表Id）'，
		charge_id: '收费标准id',
		remark: '备注',
		contract: '合同，多个合同存到json',
		pay_method: '支付方式1-微信 2-支付宝 3-刷卡 4-现金 5-其他',
		payer: '支付者，学员监护者id'
	} */
	stringToNumber(params)
	var { data: res } = await this.$http.put('/student/cost/lesson', params)
	if (res.code == 0) {
		this.$message.success('修改课包信息成功')
		return true
	} else {
		this.$message.error(res.message)
	}
}


export async function addCostLesson (params) {
	/* {
		sid: '学员id',
		cost_lesson: '购买课时数量',
		give_lesson: '赠送课时数量',
		lesson: '课包课时总数',
		total_cost: '课包价格',
		reduction_amount: '减免金额',
		discount: '折扣，0表示无折扣',
		paid_amount: '实际支付金额',
		charge_value_id: '课包id（价格表Id）'，
		charge_id: '收费标准id',
		remark: '备注',
		contract: '合同，多个合同存到json',
		pay_method: '支付方式1-微信 2-支付宝 3-刷卡 4-现金 5-其他',
		payer: '支付者，学员监护者id'
	} */
	stringToNumber(params)
	var { data: res } = await this.$http.post('/student/cost/lesson', params)
	if (res.code == 0) {
		this.$message.success('添加课包信息成功')
		return true
	} else {
		this.$message.error(res.message)
	}
}

export async function paymentAmount (params) {
	/* {
		id: '课包id',		
		sid: '学员id',
		paid_amount: '实际支付金额',
		amount_payable: '应付金额',
		earnest_money: '定金',
		payment_amount: '补缴金额'
	} */
	stringToNumber(params)
	var { data: res } = await this.$http.put('/student/cost/lesson/payment', params)
	if (res.code == 0) {
		this.$message.success('补缴费用成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function computedRemainingAmount (course_id) {
	/* 
		@describe 更换课包计算剩余金额
		@date 2021-02-25
	*/
	var { data: res } = await this.$http.get('/student/cost/lesson/over?id=' + course_id)
	if (res.code == 0) {
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function replaceCostLesson (params) {
	/* 
		@describe 更换套餐
		@date 2021-02-25
	*/
   console.log(params, 888)
	var { data: res } = await this.$http.post('/student/cost/lesson/replace', params)
	if (res.code == 0) {
		this.$message.success('更换套餐成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}