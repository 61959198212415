<template>
	<!-- 消息模板 -->
	<div style="position: relative;">
		<!-- 关闭 -->
		<i class="el-icon-close cursor-p close" @click="$emit('close')" v-if="showCloseIcon"></i>
		<!-- 消息分类 -->
		<el-tabs type="border-card" v-model="selectTAB">
			<!--家长请假 -->
		  <el-tab-pane name="leave" lazy>
			<span slot="label" class="">
				<i class="iconfont icon-ziyuan206 flex flex-column align-center" style="font-size: 30px;position: relative;" @click="getMsg('leave')"><i style="font-size: 16px;" >家长请假</i>
					<!-- 消息数量 -->
					<div style="position: absolute;right: -2px;top: 23px;width: 20px;height: 20px;border-radius: 50%;background-color: #f17006;color: #ffffff;line-height: 20px;"
					class="fs-12 text-align-c textNoWarp" v-show="msgInfo.type_1 > 0">
						{{msgInfo.type_1}}
					</div>
				</i>				
			</span>
			<slot name='leave' :msgList='msgList.type1' :pagition='pagition'></slot>
		  </el-tab-pane>
		  <!--家长缴费 -->
		  <el-tab-pane name="cost" lazy>
			<span slot="label" class="">
					<i class="iconfont icon-feiyong flex flex-column align-center" style="font-size: 30px;" @click="getMsg('cost')"><i style="font-size: 16px;">家长缴费</i>
						<!-- 消息数量 -->
						<div style="position: absolute;right: 15px;top: 24px;width: 20px;height: 20px;border-radius: 50%;background-color: #f17006;color: #ffffff;line-height: 20px;"
						class="fs-12 text-align-c textNoWarp" v-show="msgInfo.type_2 > 0">
							{{msgInfo.type_2}}
						</div>
					</i>					
			</span>
			<slot name='cost' :msgList='msgList.type2' :pagition='pagition'></slot>
		  </el-tab-pane>
		  <!--有效期不足 -->
		  <el-tab-pane name="term" lazy>
			<span slot="label" class="">
					<i class="iconfont icon-licailinghuoqixian flex flex-column align-center" style="font-size: 30px;" @click="getMsg('term')"><i style="font-size: 16px;" >有效期不足</i>
						<!-- 消息数量 -->
						<div style="position: absolute;right: 15px;top: 24px;width: 20px;height: 20px;border-radius: 50%;background-color: #f17006;color: #ffffff;line-height: 20px;"
						class="fs-12 text-align-c textNoWarp" v-show="msgInfo.type_9 > 0">
							{{msgInfo.type_9}}
						</div>
					</i>					
			</span>
			<slot name='term' :msgList='msgList.type9' :pagition='pagition'></slot>
		  </el-tab-pane>		  
		  <!--课时不足 -->
		  <el-tab-pane name="classTime" lazy>
			<span slot="label" class="">
				<i class="iconfont icon-shijian flex flex-column align-center" style="font-size: 30px;" @click="getMsg('classTime')"><i style="font-size: 16px;" >课时不足</i>
					<!-- 消息数量 -->
					<div style="position: absolute;right: 15px;top: 24px;width: 20px;height: 20px;border-radius: 50%;background-color: #f17006;color: #ffffff;line-height: 20px;"
					class="fs-12 text-align-c textNoWarp" v-show="msgInfo.type_10 > 0">
						{{msgInfo.type_10}}
					</div>
				</i>					
			</span>
			<slot name='classTime' :msgList='msgList.type10' :pagition='pagition'></slot>
		  </el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	/* api */
	import{
		getMsg
	} from '../../assets/js/API/msg.js';
	/* 消息配置 */
	import {
		tab
	} from '../../assets/js/msgOption.js'
	export default {
		name: 'Msg',
		props: {
			msgInfo: {
				type: Object,
				default: function () {
					return {}
				}
			},
			selectTab: {
				type: String,
				default: 'leave'
			},
			showCloseIcon: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				msgList: {//消息列表
					type1: '', //家长请假
					type2: '', //家长缴费
					type5: '', //费用预警
					type9: '', //有效期不足
					type10: '' //课时不足
				},
				pagition: {
					total: 0,
					page: 1,
					number: 10
				},
				selectTAB: ''
			}
		},
		methods: {
			/* 获取消息 */
			getMsg (key, page) {
				if (key == this.selectTAB) return;
				this.selectTAB = key == undefined ? this.selectTAB : key;
				var type = tab.indexOf(this.selectTAB)
				getMsg.call(this, {
					page: page ? page : 1,
					number: 10,
					type
				}).then( res => {
					this.msgList['type' + type] = typeof res.data == 'string' ? [] : res.data;
					this.pagition.total = res.total;
					this.pagition.number = res.number;
					this.pagition.page = res.page;
				}).catch( err => {
					console.log(err)
				})
			}
		},
		created() {
			this.selectTAB = this.selectTab
			this.getMsg()
			this.$emit('getMsg', this.getMsg)
		}
	}
</script>

<style scoped lang="less">
	/deep/ .el-tabs__item{
		height: 80px;
	}
	/deep/ .is-active{
		color: #fed90e !important;
	}
	.close{
		position: absolute;
		top: 5px;
		right: 5px;
		z-index: 999;
		font-size: 20px;
	}
</style>
