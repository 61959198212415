import Axios from 'axios'
import router from '../router'
import { Message, MessageBox } from 'element-ui'
const BASE_URL = process.env.VUE_APP_BASE_URL + '/admin/v1';

/* baseurl */
Axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/admin/v1' : BASE_URL
/* axios.defaults.baseURL = BASE_URL */
// 开发环境
/* 设置拦截器 */
Axios.interceptors.request.use(config => {
  let url = config['url']
  let type
  if(config&&url){
      let urlType = url.split('/')
      type = urlType[1]
  }
  if(type=='edu'){
    config.baseURL = process.env.NODE_ENV == 'development' ? '/api/v1' : "http://analysis.hipposteam.com/api/v1"
    delete config.headers.Authorization
    delete config.headers.ticket
  }else{
    config.headers.Authorization = window.sessionStorage.getItem('token');
    config.headers.ticket = window.sessionStorage.getItem('checkSchoole');
  }
  return config;
})

Axios.interceptors.response.use(response => {
  if (response.status === 200) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
},
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          Message({
            type: "error",
            message: error.response.data.msg || error.response.data.message
          });
          break;
        case 401:
          MessageBox.alert('检测到你的账号已被登出或被挤下线，登录信息失效，重新登录！', '登录失效', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            showClose: false,
            type: 'warning'
          }).then(() => {
            router.replace({
              path: '/login'
            });
          })
          break;
        case 403:
          MessageBox.alert('检测到你的账号已被登出或被挤下线，登录信息失效，重新登录！', '登录失效', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            showClose: false,
            type: 'warning'
          }).then(() => {
            router.replace({
              path: '/login'
            });
          })
          break;
        default:
          Message({
            type: "error",
            message: error.response.data.msg || error.response.data.message
          });
          break;
      }
    }
    return Promise.reject(error.response.data)
  }
)
export default Axios