<template>
	<div class="h-per-100 box">
		<calendar-c></calendar-c>
	</div>
</template>
<script>
	import calendarC from './education-calendar-kaoqin.vue'
	export default {
		name: 'Calendar2',
		data() {
			return {

			}
		},
		methods: {



		},
		components: {
			calendarC
		}
	}
</script>
<style lang="less" scoped>
	.box {
		overflow-y: auto;
	}
</style>
