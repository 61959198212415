/**
@describe 校区经营指标
@date 2021-03-30
@by FZY
*/
<template>
	<div>
		<table border="1" ref='refTable' v-show="false">
			<!-- /标题 -->
			<tr style="text-align: center;">
				<th :colspan="3 + tableData_export.length">校区财务运营指标</th>
			</tr>
			<tr style="text-align: center;">
				<td colspan="3">校区</td>
				<td v-for="(item, index) in tableData_export" :key="'tr1' + index">{{item.name}}</td>
			</tr>
			<tr style="text-align: center;">
				<td colspan="3">时间</td>
				<td v-for="(item, index) in tableData_export" :key="'tr2' + index">{{item.date}}</td>
			</tr>
			<!-- /收入项 -->
			<tr style="text-align: center;">
				<td rowspan='6'>收入项</td>
				<td rowspan='4'>现金收入</td>
				<td>新签收入</td>
				<td v-for="(item, index) in tableData_export" :key="'tr3' + index">{{item.new_sign}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>续费收入</td>
				<td v-for="(item, index) in tableData_export" :key="'tr4' + index">{{item.review_sign}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>增值收入(小课包)</td>
				<td v-for="(item, index) in tableData_export" :key="'tr5' + index">{{item.add_income}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>待确认收入(定金)</td>
				<td v-for="(item, index) in tableData_export" :key="'tr6' + index">{{item.to_be_confirmed}}</td>
			</tr>
			<tr style="text-align: center;">
				<td colspan="2">退费</td>
				<td v-for="(item, index) in tableData_export" :key="'tr7' + index">{{item.return_money}}</td>
			</tr>
			<tr style="text-align: center;">
				<td colspan="2">现金净收入</td>
				<td v-for="(item, index) in tableData_export" :key="'tr8' + index">{{item.net_income}}</td>
			</tr>
			<!-- /成本项 -->
			<tr style="text-align: center;">
				<td rowspan="13">成本项</td>
				<td colspan="2">房租及物业水电</td>
				<td v-for="(item, index) in tableData_export" :key="'tr9' + index">{{item.rent_utilities}}</td>
			</tr>
			<tr style="text-align: center;">
				<td colspan="2">装修摊销及固定资产折旧</td>
				<td v-for="(item, index) in tableData_export" :key="'tr10' + index">{{item.decorate_amortization}}</td>
			</tr>
			<tr style="text-align: center;">
				<td rowspan="4">人力成本</td>
				<td>教学</td>
				<td v-for="(item, index) in tableData_export" :key="'tr11' + index">{{item.human_teaching}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>销售市场</td>
				<td v-for="(item, index) in tableData_export" :key="'tr12' + index">{{item.human_market}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>行政</td>
				<td v-for="(item, index) in tableData_export" :key="'tr13' + index">{{item.human_administrative}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>校长</td>
				<td v-for="(item, index) in tableData_export" :key="'tr14' + index">{{item.human_principal}}</td>
			</tr>
			<tr style="text-align: center;">
				<td rowspan="5">报销</td>
				<td>教学</td>
				<td v-for="(item, index) in tableData_export" :key="'tr15' + index">{{item.apply_teaching}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>销售市场</td>
				<td v-for="(item, index) in tableData_export" :key="'tr16' + index">{{item.apply_market}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>兼职</td>
				<td v-for="(item, index) in tableData_export" :key="'tr17' + index">{{item.apply_parttime_job}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>团建</td>
				<td v-for="(item, index) in tableData_export" :key="'tr18' + index">{{item.apply_league}}</td>
			</tr>
			<tr style="text-align: center;">
				<td>其他</td>
				<td v-for="(item, index) in tableData_export" :key="'tr19' + index">{{item.apply_other}}</td>
			</tr>
			<tr style="text-align: center;">
				<td colspan="2">其他</td>
				<td v-for="(item, index) in tableData_export" :key="'tr20' + index">{{item.other}}</td>
			</tr>
			<tr style="text-align: center;">
				<td colspan="2">成本统计</td>
				<td v-for="(item, index) in tableData_export" :key="'tr21' + index">{{item.amount}}</td>
			</tr>
			<tr style="text-align: center;">
				<td colspan="3">现金利润</td>
				<td v-for="(item, index) in tableData_export" :key="'tr22' + index">{{item.cash_profit}}</td>
			</tr>
		</table>
		<el-card>
			<div slot='header'>
				<el-row>
					<el-col :span='4'>
						<div class="flex align-center">
							<p>校区:</p>
							<el-select size='small' style="width: 100%" clearable v-model="screenTotal.school_id" @change="screenChange('')">
								<el-option v-for="(item, index) in schooleOptions" :key="'total' + index" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</el-col>
					<el-col :span='6' style="padding: 0 10px;">
						<div class="flex align-center">
							<p>时间:</p>
							<el-date-picker v-model="screenTotal.date" :clearable='false' type="month" placeholder="选择月" format="yyyy-MM"
							 value-format="yyyy-MM" size="small" style="width: 100%" @change="screenChange('')">
							</el-date-picker>
						</div>
					</el-col>
					<el-col :span='2'>
						<el-button type="primary" icon="el-icon-upload" size="small" @click='derive'>导出</el-button>
					</el-col>
				</el-row>
			</div>
			<div class="flex align-center space-around total">
				<div class="flex flex-column align-center">
					<div style="color: #666;">现金净收入(元)</div>
					<div class="c-main mg-t-10">{{tableDataTotal.net_income}}</div>
				</div>
				<div class="flex flex-column align-center">
					<div style="color: #666;">成本统计(元)</div>
					<div class="c-main mg-t-10">{{tableDataTotal.all_cost_total}}</div>
				</div>
				<div class="flex flex-column align-center">
					<div style="color: #666;">现金利润(元)</div>
					<div class="c-main mg-t-10">{{tableDataTotal.profit}}</div>
				</div>
			</div>
			<loading-c :show-loading='loadingTotal'></loading-c>
		</el-card>
		<el-card class="mg-t-10">
			<div slot='header'>
				<div class="flex justify-between income">
					<div style="width: 50%">收入项</div>
					<div class="flex align-center flex-end">
						<div class="flex align-center" style="max-width: 200px;">
							<p>校区:</p>
							<el-select size='small' style="width: 100%" clearable v-model="screenIncome.school_id" @change="screenChange('Income')">
								<el-option v-for="(item, index) in schooleOptions" :key="'income' + index" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="flex align-center" style="margin-left: 10px;max-width: 300px;">
							<p>时间:</p>
							<el-date-picker v-model="screenIncome.date" :clearable='false' type="month" placeholder="选择月" format="yyyy-MM"
							 value-format="yyyy-MM" size="small" style="width: 100%" @change="screenChange('Income')">
							</el-date-picker>
						</div>
					</div>
				</div>
			</div>
			<el-table :data="tableDataIncome" stripe size='small' class="mg-t-10">
				<el-table-column prop='name' label="校区" min-width="150"></el-table-column>
				<el-table-column label="时间" width="200">
					<template>
						{{screenIncome.date}}
					</template>
				</el-table-column>
				<el-table-column prop='new_sign' label="新签收入" width="120"></el-table-column>
				<el-table-column prop='review_sign' label="续费收入" width="120"></el-table-column>
				<el-table-column prop='add_income' label="增值收入/小课包" width="150"></el-table-column>
				<el-table-column prop='to_be_confirmed' label="待确认收入/定金" width="150"></el-table-column>
				<el-table-column prop='return_money' label="退费" width="150"></el-table-column>
				<el-table-column prop='net_income' label="现金净收入" width="150"></el-table-column>
			</el-table>
			<el-pagination background layout="total, prev, pager, next" small :total="paginationIncome.total" :current-page.sync="screenIncome.page"
			 :page-size="screenIncome.number" class="mg-t-10" @current-change="getIndicatorsIncome">
			</el-pagination>
			<loading-c :show-loading='loadingIncome'></loading-c>
		</el-card>
		<el-card class="mg-t-10">
			<div slot='header'>
				<div class="flex justify-between cost">
					<div style="width: 50%">成本项(手填)</div>
					<div class="flex align-center flex-end">
						<div class="flex align-center" style="max-width: 200px;">
							<p>校区:</p>
							<el-select size='small' style="width: 100%" clearable v-model="screenCost.school_id" @change="screenChange('Cost')">
								<el-option v-for="(item, index) in schooleOptions" :key="'cost' + index" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="flex align-center" style="padding: 0 10px;max-width: 300px;">
							<p>时间:</p>
							<el-date-picker v-model="screenCost.date" :clearable='false' type="month" placeholder="选择月" format="yyyy-MM"
							 value-format="yyyy-MM" size="small" style="width: 100%" @change="screenChange('Cost')">
							</el-date-picker>
						</div>
						<el-button type="primary" icon="el-icon-plus" size="small" @click='showCost = true'>添加</el-button>
					</div>
				</div>
			</div>
			<el-table :data="tableDataCost" stripe size='small' class="mg-t-10">
				<el-table-column prop='school_name' label="校区" width="150"></el-table-column>
				<el-table-column prop='add_time' label="时间" width="200"></el-table-column>
				<el-table-column prop='rent_utilities' label="房租及物业水电" width="110"></el-table-column>
				<el-table-column prop='decorate_amortization' label="装修摊销及固定资产折旧" width="160"></el-table-column>
				<!-- /人力成本 -->
				<el-table-column label="人力成本" align="center">
					<el-table-column prop='human_teaching' label="教学" width="100" align="center"></el-table-column>
					<el-table-column prop='human_market' label="销售市场" width="100" align="center"></el-table-column>
					<el-table-column prop='human_administrative' label="行政" width="100" align="center"></el-table-column>
					<el-table-column prop='human_principal' label="校长" width="100" align="center"></el-table-column>
				</el-table-column>
				<!-- /报销 -->
				<el-table-column label="报销" align="center">
					<el-table-column prop='apply_teaching' label="教学" width="100" align="center"></el-table-column>
					<el-table-column prop='apply_market' label="销售市场" width="100" align="center"></el-table-column>
					<el-table-column prop='apply_parttime_job' label="兼职" width="100" align="center"></el-table-column>
					<el-table-column prop='apply_league' label="团建" width="100" align="center"></el-table-column>
					<el-table-column prop='apply_other' label="其他" width="100" align="center"></el-table-column>
				</el-table-column>

				<el-table-column prop='other' label="其他" width="100"></el-table-column>
				<el-table-column prop='amount' label="成本统计" width="100"></el-table-column>
				<el-table-column prop='remark' label="备注" min-width="150"></el-table-column>
				<el-table-column label="操作" width="90" fixed="right">
					<template v-slot='scope'>
						<el-button size="mini" type="primary" @click='modify(scope.row)'>编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="total, prev, pager, next" small :total="paginationCost.total" :page-size="screenCost.number"
			 :current-page.sync="screenCost.page" class="mg-t-10" @current-change='getIndicatorsCost'>
			</el-pagination>
			<loading-c :show-loading='loadingCost'></loading-c>
		</el-card>
		<el-dialog :visible.sync="showCost" center :title="formDataCost.id ? '修改校区成本' : '添加校区成本'" @close="$refs.refFormCost.resetFields()">
			<el-form :model='formDataCost' label-position="right" label-width="170px" ref='refFormCost' :rules='rules'>
				<el-form-item label="ID" prop="id" v-show='false'>
					<el-input v-model="formDataCost.id" placeholder="id" size="small"></el-input>
				</el-form-item>
				<el-form-item label="校区" prop="school_id">
					<el-select v-model="formDataCost.school_id" size="small" style='width: 100%' :disabled="formDataCost.id ? true : false">
						<el-option v-for="(item, index) in schooleOptions" :key="'costForm' + index" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="时间" prop="add_time">
					<el-date-picker v-model="formDataCost.add_time" type="month" placeholder="选择月" format="yyyy-MM" value-format="yyyy-MM"
					 size="small" style="width: 100%" :disabled="formDataCost.id ? true : false">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="房租及物业水电" prop="rent_utilities">
					<el-input v-model="formDataCost.rent_utilities" placeholder="请输入房租及物业水电" size="small"></el-input>
				</el-form-item>
				<el-form-item label="装修摊销及固定资产折旧" prop="decorate_amortization">
					<el-input v-model="formDataCost.decorate_amortization" placeholder="请输入装修摊销及固定资产折旧" size="small"></el-input>
				</el-form-item>
				<el-form-item label="其他" prop="other">
					<el-input v-model="formDataCost.other" placeholder="其他" size="small"></el-input>
				</el-form-item>
				<!-- /人力成本 -->
				<el-form-item label="人力成本"></el-form-item>
				<el-form-item label="教学" prop="human_teaching">
					<el-input v-model="formDataCost.human_teaching" placeholder="教学" size="small" :disabled="formDataCost.id ? true : false"></el-input>
				</el-form-item>
				<el-form-item label="销售市场" prop="human_market">
					<el-input v-model="formDataCost.human_market" placeholder="销售市场" size="small" :disabled="formDataCost.id ? true : false"></el-input>
				</el-form-item>
				<el-form-item label="行政" prop="human_administrative">
					<el-input v-model="formDataCost.human_administrative" placeholder="行政" size="small" :disabled="formDataCost.id ? true : false"></el-input>
				</el-form-item>
				<el-form-item label="校长" prop="human_principal">
					<el-input v-model="formDataCost.human_principal" placeholder="校长" size="small" :disabled="formDataCost.id ? true : false"></el-input>
				</el-form-item>
				<!-- /报销 -->
				<el-form-item label="报销"></el-form-item>
				<el-form-item label="教学" prop="apply_teaching">
					<el-input v-model="formDataCost.apply_teaching" placeholder="教学" size="small"></el-input>
				</el-form-item>
				<el-form-item label="销售市场" prop="apply_market">
					<el-input v-model="formDataCost.apply_market" placeholder="销售市场" size="small"></el-input>
				</el-form-item>
				<el-form-item label="兼职" prop="apply_parttime_job">
					<el-input v-model="formDataCost.apply_parttime_job" placeholder="兼职" size="small"></el-input>
				</el-form-item>
				<el-form-item label="团建" prop="apply_league">
					<el-input v-model="formDataCost.apply_league" placeholder="团建" size="small"></el-input>
				</el-form-item>
				<el-form-item label="其他报销" prop="apply_other">
					<el-input v-model="formDataCost.apply_other" placeholder="其他报销" size="small"></el-input>
				</el-form-item>
				<!-- /备注 -->
				<el-form-item label="备注" prop="remark">
					<el-input v-model="formDataCost.remark" type='textarea' placeholder="备注" size="small"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showCost = false">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import vali from '../../assets/js/validate.js'
	import {
		extend,
		exportExcel,
		toNumber,
		insertMoneySeparator
	} from '../../assets/js/util.js'
	import {
		getIndicators,
		getIndicatorsIncome,
		getCost,
		modifyCost,
		addCost,
		exportExcelOfCost
	} from '../../assets/js/API/financial.js'
	import {
		getSchoole
	} from '../../assets/js/API/schoole.js'
	import {
		formatDate
	} from '../../assets/js/myMethods.js'
	import loadingC from '../common/loading.vue'
	var nowDate = {
		haveMonth: formatDate.detail(new Date(), 'yyyy-mm-dd'),
		noMonth: formatDate.detail(new Date(), 'yyyy-mm')
	}
	export default {
		name: 'FinancialIndicators',
		components: {
			loadingC
		},
		data() {
			return {
				tableData_export: [],
				isCanHttp: true, //能否请求 true能 false不能 （主要用于成本项的添加修改）
				schooleOptions: [],
				rules: {
					school_id: vali.validatorCommon.mandatory('请选择校区', 'change'),
					add_time: vali.validatorCommon.mandatory('请选择时间', 'change'),
					rent_utilities: vali.validatorCommon.float('格式不正确,请检查输入'),
					decorate_amortization: vali.validatorCommon.float('格式不正确,请检查输入'),
					other: vali.validatorCommon.float('格式不正确,请检查输入'),
					human_teaching: vali.validatorCommon.float('格式不正确,请检查输入'),
					human_market: vali.validatorCommon.float('格式不正确,请检查输入'),
					human_administrative: vali.validatorCommon.float('格式不正确,请检查输入'),
					human_principal: vali.validatorCommon.float('格式不正确,请检查输入'),
					apply_teaching: vali.validatorCommon.float('格式不正确,请检查输入'),
					apply_market: vali.validatorCommon.float('格式不正确,请检查输入'),
					apply_parttime_job: vali.validatorCommon.float('格式不正确,请检查输入'),
					apply_league: vali.validatorCommon.float('格式不正确,请检查输入'),
					apply_other: vali.validatorCommon.float('格式不正确,请检查输入'),
					remark: [],
				},
				loadingTotal: true,
				screenTotal: {
					school_id: '',
					date: nowDate.noMonth
				},
				tableDataTotal: {
					net_income: 0,
					all_cost_total: 0,
					profit: 0
				},
				loadingIncome: true,
				screenIncome: {
					page: 1,
					number: 5,
					date: nowDate.noMonth,
					school_id: ''
				},
				tableDataIncome: [],
				paginationIncome: {
					total: 0
				},
				loadingCost: true,
				screenCost: {
					date: nowDate.noMonth,
					page: 1,
					number: 10
				},
				tableDataCost: [],
				paginationCost: {
					total: 0
				},
				showCost: false,
				formDataCost: {
					id: '',
					school_id: '',
					add_time: '',
					rent_utilities: '',
					decorate_amortization: '',
					other: '',
					human_teaching: '',
					human_market: '',
					human_administrative: '',
					human_principal: '',
					apply_teaching: '',
					apply_market: '',
					apply_parttime_job: '',
					apply_league: '',
					apply_other: '',
					remark: '',
				}
			}
		},
		created() {
			this.getSchoole();
			this.getData();
		},
		methods: {
			derive() {
				if (this.loadingTotal) return this.$message.info('正在处理中...')
				this.loadingTotal = true;
				exportExcelOfCost.call(this, this.screenTotal)
					.then(res => {
						this.loadingTotal = false
						if (res) {
							this.tableData_export = res;
							this.tableData_export.forEach( item => {
								for( let i in item) {
									if (!isNaN(item[i] - 0)) {
										item[i] = insertMoneySeparator(toNumber(item[i]))
									}	
								}
							})
							this.$nextTick(() => exportExcel(this.$refs.refTable.outerHTML, '校区财务运营指标.xls'))
						}
					})
					.catch(err => this.loadingTotal = false)

			},
			modify(row) {
				extend(this.formDataCost, row);
				this.showCost = true
			},
			async confirm() {
				if (!this.isCanHttp) return this.$message.info('正在处理中...');
				await this.$refs.refFormCost.validate();
				if (this.formDataCost.id) this.modifyCost()
				else this.addCost()
			},
			screenChange(key) {
				this['screen' + key] && (this['screen' + key].page = 1);
				this['getIndicators' + key]()
			},
			addCost() {
				var params = { ...this.formDataCost
				};
				var exclude = ['remark', 'add_time'];
				for (let i in params) {
					if (!exclude.includes(i)) params[i] -= 0
				};
				var index = this.schooleOptions.findIndex(item => item.id == params.school_id);
				index != -1 && (params.school_name = this.schooleOptions[index].name);
				delete params.id;
				this.isCanHttp = false;
				addCost.call(this, params)
					.then(res => {
						this.isCanHttp = true;
						if (res) {
							this.getIndicatorsCost()
						}
					})
					.catch(err => this.isCanHttp = true)
			},
			modifyCost() {
				var params = { ...this.formDataCost
				};
				var exclude = ['remark', 'add_time'];
				for (let i in params) {
					if (!exclude.includes(i)) params[i] -= 0
				};
				var index = this.schooleOptions.findIndex(item => item.id == params.school_id);
				index != -1 && (params.school_name = this.schooleOptions[index].name);
				this.isCanHttp = false;
				modifyCost.call(this, params)
					.then(res => {
						this.isCanHttp = true;
						if (res) {
							this.getIndicatorsCost()
						}
					})
					.catch(err => this.isCanHttp = true)
			},
			getIndicators() {
				this.loadingTotal = true;
				var params = { ...this.screenTotal
				};
				params.date == null && (params.date = '');
				getIndicators.call(this, params)
					.then(res => {
						this.loadingTotal = false;
						if (res) {
							extend(this.tableDataTotal, res);
							for (let i in this.tableDataTotal) {
								this.tableDataTotal[i] = insertMoneySeparator(toNumber(this.tableDataTotal[i]))
							}
						}
					})
					.catch(err => this.loadingTotal = false)
			},
			getIndicatorsIncome() {
				this.loadingIncome = true;
				var params = { ...this.screenIncome
				};
				params.date == null && (params.date = '');
				getIndicatorsIncome.call(this, params)
					.then(res => {
						this.loadingIncome = false;
						if (res) {
							this.tableDataIncome = res.data;
							this.paginationIncome.total = res.total;
							this.tableDataIncome.forEach( item => {
								for( let i in item) {
									if (!isNaN(item[i] - 0)) {
										item[i] = insertMoneySeparator(toNumber(item[i]))
									}									
								}
							})
						}
					})
					.catch(err => this.loadingIncome = false)
			},
			getIndicatorsCost() {
				this.loadingCost = true;
				var params = { ...this.screenCost
				};
				getCost.call(this, params)
					.then(res => {
						this.loadingCost = false;
						if (res) {
							this.tableDataCost = res.data;
							this.paginationCost.total = res.total;
							this.tableDataCost.forEach( item => {
								if (!isNaN(item[i] - 0)) {
									item[i] = insertMoneySeparator(toNumber(item[i]))
								}	
							})
						}
					})
					.catch(err => this.loadingCost = false)
			},
			getSchoole() {
				getSchoole.call(this, {
						page: 1,
						number: 999999
					})
					.then(res => {
						if (res) this.schooleOptions = res;
					})
			},
			getData() {
				this.getIndicators();
				this.getIndicatorsIncome();
				this.getIndicatorsCost()
			}
		}
	}
</script>

<style scoped>
	.el-row p,
	.income p,
	.cost p {
		width: 50px;
	}

	.el-card {
		position: relative;
	}

	@media screen and (min-width: 1500px) {

		.total div,
		.income>div:first-of-type,
		.cost>div:first-of-type {
			font-size: 25px;
		}
	}

	@media screen and (max-width: 1499px) {

		.total div,
		.income>div:first-of-type,
		.cost>div:first-of-type {
			font-size: 20px;
		}
	}
</style>
