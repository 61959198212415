<template>
	<!-- 上课时间 -->
	<el-dialog
		:visible.sync="visible" width="80%"
	>
	<div class="">
		<el-card>
			<!-- header -->
			<div slot="header" class="flex align-center w-ab-90 justify-between">
				<span>时间段</span>
				<i class="el-icon-circle-plus c-blue fs-20 cursor-p" @click="addTime"></i>
			</div>
			<!-- body -->
			<div v-for="(item,indexP) in form" :key="indexP">
				<el-divider content-position="right">{{item.start_time}}&nbsp;-&nbsp;{{item.end_time}}</el-divider>
				<div class="flex-1 flex-start mg-b-10">
					<el-checkbox-button :indeterminate="item.indeterminate" :label="0" :key="0" @change="changeAll(indexP)" v-model="item.checkAll">每天</el-checkbox-button>
					<el-checkbox-group  v-model="item.week" class="mg-l-10">
					    <el-checkbox-button v-for="(item,index) in $param.week" :label="item.val" :key="item.val"  @change="changeSingle(indexP,index + 1)" >{{item.name}}</el-checkbox-button>
				    </el-checkbox-group>
				</div>
				<div class="flex justify-between">
					<div>
						<!-- <el-time-select
							placeholder="起始时间"
							v-model="item.start_time"
							:picker-options="pickOptions"
							@change="changeStart(indexP)"
							>
						</el-time-select> -->
						<el-time-picker
							v-model="item.start_time"
							value-format='HH:mm:ss'
							:picker-options="{
								selectableRange: '08:30:00 - 22:30:00'
							}"
							placeholder="起始时间">
						</el-time-picker>
						&nbsp;
						<el-time-picker
							v-model="item.end_time"
							value-format='HH:mm:ss'
							:picker-options="{
								selectableRange: item.start_time+'- 22:30:00'
							}"
							placeholder="结束时间">
						</el-time-picker>
						<!-- <el-time-select
							placeholder="结束时间"
							v-model="item.end_time"
							 :picker-options="{
							  start: '08:30',
							  step: '00:30',
							  end: '22:30',
							  minTime: item.start_time
							}">
						</el-time-select> -->
					</div>
				  <div>
					<el-button type="primary" @click="del(indexP)" v-if="indexP != 0">删除</el-button> 
				  </div>
				</div>
			</div>
			<!-- footer -->
			<div class="footer mg-t-10" style="text-align: center;">
				<el-button size="medium" style="width:140px;" type="primary" @click="sub">
					确认
				</el-button>
			</div>
			
		</el-card>
	</div>
	</el-dialog>
</template>

<script>
	export default{
		name: 'CourseTime',
		props: ['week', 'startTime', 'endTime'],
		data(){
			return{
				start_time:'',
				end_time:'',
				pickOptions:{
					start:'08:30',
					end:'22:30',
					step:'00:30',
				},
				checkbox:[],
				form:[
					{
						week:[],
						start_time:'',
						end_time:'',
						checkAll:false,
						indeterminate:true
					}
				],
				visible:false,
				item:''
			}
		},
		watch:{
			item(val){
				if(val != ''){
					this.form = val;
				}
			}
		},
		created () {
			if (this.week && this.startTime && this.endTime) {
				this.form[0].week = [this.week];
				this.form[0].start_time = this.startTime;
				this.form[0].end_time = this.endTime;
			}
		},
		methods:{
			/* 增加上课时间 */
			addTime(){
				this.form.push({
					week:[],
					start_time:'',
					end_time:'',
					checkAll:false
				})
			},
			/* 全选 */
			changeAll(index,bool){
				let boolean = this.form[index].checkAll;
				if(boolean){
					this.form[index].week = [1,2,3,4,5,6,7];
				}else{
					this.form[index].week = [];
				}
				this.form[index].indeterminate = false;
				!this.form[index].checkAll;
				return;
			},
			/* 单选 */
			changeSingle(index,indexS){
				if(this.form[index].week.length === 7){
					this.form[index].checkAll = true;
				}else{
					this.form[index].checkAll = false;
				}	
			},
			/* 删除 */
			del(index){
				this.form.splice(index,1);
			},
			/* 确认 */
			sub(){
				var bool = true;
				this.form.some((item,index)=>{
					if(item.start_time == '' ||item.start_time == null || item.end_time == '' ||  item.end_time == null || item.week.length == 0){
						this.$message.info(`请确认第 ${index + 1} 项，信息必须完整！`);
						bool = false;
						return bool;
					}
				})
				if(bool){
					this.$emit('sub',this.form);
					this.visible = false;
				}
			},
			/* 起的时间改变 */
			changeStart(index){
				this.form[index].end_time = '';
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
