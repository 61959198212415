/* 薪资计算 */
export function getMothPerformance(data) { //当月绩效
	//续费绩效 + 体验课绩效 + 月度备课 + 月度回访 + 月度转化 + 季度转介绍 + 全勤奖（是否全勤，是就用标准的全勤奖，不是就是0）+ 半年续费 + 其他奖惩
	if (data.moth_performance == null || data.moth_performance == '') {
		return closure(data.renewal_performance, data.performanceExperience, data.preparation_lession, data.return_visit, data.transformation,
			data.quarterly_referral, data.perfect_attendance, data.halfyear_renewa, data.other_awards)
	} else return data.moth_performance	
}
export function getMonthSalary(data) { //当月应发
	//当月绩效 + 实际底薪 + 课时费
	if (data.month_salary == null || data.month_salary) {
		var moth_performance = getMothPerformance(data),
			actual_base_salary = getActualBaseSalary(data);
		console.log(moth_performance, actual_base_salary, closure(moth_performance, actual_base_salary, data.classFee))
		return closure(moth_performance, actual_base_salary, data.classFee)
	} else return data.month_salary	
}

export function getActualBaseSalary (data) { //实际应发
   if (data.actual_base_salary == null || data.actual_base_salary == '') {
	   var date = data.date,
	   	DATE = new Date(date),
	   	day = new Date(DATE.getFullYear(), DATE.getMonth() + 1, 0).getDate();
	   var attendance_day = data.attendance_day == null ? day : (data.attendance_day == 0 ? 1 : data.attendance_day);
	   //（默认底薪 / 应该出勤天数）* 实际出勤天数
	   return ((data.base_salary / attendance_day) * data.real_attendance_day).toFixed(2)	
   } else return data.actual_base_salary			
}


function closure() {
	let result = 0,
		arg = Array.from(arguments),
		length = arg.length - 1;
	length >= 0 && computed(arg[length]);
	function computed(val) {
		val = val == null ? 0 : Number(val);
		result += val;
		length--;
		if (length < 0) return
		else return computed(arg[length])
	}
	return result.toFixed(2)
}
