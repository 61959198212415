<template>
	<div>
		<!-- 筛选 -->
		<div class="box-screen">
			<el-input size="small" v-model="key" class="w-per-20" @blur="click_queue" @>
				<i slot="suffix" class="el-input__icon el-icon-search" @click="click_queue"></i>
			</el-input>
		</div>
		<el-table :data="analysisList" ref="singleTable" @current-change="current_change" highlight-current-row class="fsMedia">
			<el-table-column label="姓名">
				<template slot-scope="scope">{{scope.row.studentName}}</template>
			</el-table-column>
			<el-table-column prop="className" label="班级"></el-table-column>
			<el-table-column label="课时" min-width="200">
				<template slot-scope="scope">{{scope.row.date}} &nbsp; {{scope.row.name}} &nbsp;{{scope.row.time_start}} &nbsp;{{scope.row.time_end}}</template>
			</el-table-column>

			<el-table-column prop="mainTeacher" label="主课老师"></el-table-column>
			<el-table-column prop="alternativeTeacher" label="配课老师"></el-table-column>
			<el-table-column label="考勤状态">
				<template slot-scope="scope">{{scope.row.a_status == 4 ? '未处理' : scope.row.a_status == 3 ? '旷课' : scope.row.a_status == 2 ? '请假' : scope.row.a_status == 1 ? '出勤' : '未知状态' }}</template>
			</el-table-column>
			<el-table-column label="作品上传状态">
				<template slot-scope="scope">{{scope.row.w_status == 1 ? '已上传' : '未上传'}}</template>
			</el-table-column>
			<el-table-column label="操作" width="100">
				<template slot-scope="scope">
					<el-dropdown @click="updateAttendance(scope.row,1)">
						<el-button type="primary" size="small">更改考勤</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="updateAttendance(scope.row,1)">出勤</el-dropdown-item>
							<el-dropdown-item @click.native="updateAttendance(scope.row,2)">请假</el-dropdown-item>
							<el-dropdown-item @click.native="updateAttendance(scope.row,3)">旷课</el-dropdown-item>
							<el-dropdown-item @click.native="updateAttendance(scope.row,4)">未处理</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>&nbsp;
					<!--  <el-button v-if="scope.row.a_status == 1 && scope.row.w_status == 2" size="small" type="primary" @click="uploadWorks(scope.row)">上传作品</el-button>
          <el-button v-if="scope.row.a_status == 1 && scope.row.w_status == 1" size="small" type="primary" @click="showWorks(scope.row)">查看作品</el-button>-->
					<!-- <el-button v-if="scope.row.a_status != 1" size="small" type="primary">临时调出</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="mg-t-10">
			<el-pagination background :current-page="page" layout="total, prev, pager, next" @current-change="hanldeCurrentChange"
			 :total="total"></el-pagination>
		</div>
		<!-- 分页/ -->
	</div>
</template>
<script>
	export default {
		name: 'TableAttendance',
		data() {
			return {
				analysisList: [{}, {}],
				total: 1,
				a_status: "",
				w_status: "",
				key: "",
				page: 1,
				value: "",
			};
		},
		methods: {
			current_change(row) {
				this.$emit("current_change", {
					id: row
				});
			},
			selection_change(currentRow) {
				this.$emit("selection_change", {
					id: currentRow
				});
			},
			click_queue() {
				this.getAnalysisList();
			},
			// 考勤筛选
			screen_attendance() {
				// 筛选条件
				var queue = {
					key: "",
				};
			},
			/* 获取考勤列表 */
			async getAnalysisList() {
				let param =
					"education/attendance?a_status=" +
					this.a_status +
					"&w_status=" +
					this.w_status +
					"&key=" +
					this.key +
					"&page=" +
					this.page;
				const {
					data: res
				} = await this.$http.get(param);
				if (res.code == 200) {
					this.analysisList = res.data.data;
					this.total = res.data.total;
				}
			},
			/* 分页 */
			hanldeCurrentChange(page) {
				this.page = page;
				this.getAnalysisList();
			},
			/* 更新考勤状态 */
			async updateAttendance(row, status) {
				const {
					data: res
				} = await this.$http.patch(
					"education/attendance/" + row.id, {
						status: status
					}
				);
				if (res.code == 200) {
					this.$message.success("更改成功");
					this.getAnalysisList();
				}
			},
		},
		created() {
			this.getAnalysisList();
		},
	};
</script>
<style lang="less" scoped>
	.box-screen {
		width: 25% !important;
	}
</style>
