/**
@describe 校区收入排名
@date 2021-03-30
@by FZY
*/
<template>
  <div>
    <table border="1" ref='refTableExport' v-show="false">
      <tr style="text-align: center;">
        <th>排名</th>
        <th>校区</th>
        <th>{{getLabel(0)}}</th>
        <th>{{getLabel(1)}}</th>
        <th>{{getLabel(2)}}</th>
        <th>{{getLabel(3)}}</th>
        <th>{{getLabel(4)}}</th>
        <th>{{getLabel(5)}}</th>
        <th>开业累计现金净收入</th>
      </tr>
      <tr v-for="(item, index) in tableData_export" :key="'table' + index" style="text-align: center;">
        <td>{{index + 1}}</td>
        <td>{{item.name}}</td>
        <td>{{item.cumulative}}</td>
        <td>{{item.new_sign}}</td>
        <td>{{item.student}}</td>
        <td>{{item.review_sign}}</td>
        <td>{{item.net_income}}</td>
        <td>{{item.consume_class_time}}</td>
        <td>{{item.all_net_income}}</td>
      </tr>
    </table>
    <el-card>
      <div class="flex align-center" slot='header'>
        <div style="width: 50%;" class="title">全国各校区收入排名</div>
        <div class="flex align-center flex-end">
          <el-radio-group v-model="screen.kind" size="small" style="margin-right: 10px;" @change="screenChange">
            <el-radio-button label="周"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
          </el-radio-group>
          <el-date-picker v-model="screen.week" :clearable="false" :picker-options="pickerOptions" type="week" placeholder="选择周" format="yyyy 第 WW 周" value-format="yyyy-MM-DD" size="small" style="max-width: 300px;" v-if="screen.kind == '周'" @change="screenChange">
          </el-date-picker>
          <el-date-picker v-model="screen.month" :clearable="false" type="month" placeholder="选择月" format="yyyy-MM" value-format="yyyy-MM" size="small" style="max-width: 300px;" v-else-if="screen.kind == '月'" @change="screenChange">
          </el-date-picker>
          <el-button type="primary" icon="el-icon-upload" size="small" style="margin-left: 10px;" @click='exportExcel'>导出</el-button>
        </div>
      </div>
      <el-table :data="tableData[screen.page]" stripe ref='refForm'>
        <el-table-column label="排名" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="校区" align="center" prop="name" width="150"></el-table-column>
        <el-table-column :label="getLabel(0)" prop="cumulative " min-width="110" align="center">
          <template v-slot="scope">
            {{scope.row.cumulative}}
          </template>
        </el-table-column>
        <el-table-column :label="getLabel(1)" prop="cumulative " min-width="110" align="center">
          <template v-slot="scope">
            {{scope.row.achievement}}
          </template>
        </el-table-column>
        <el-table-column :label="getLabel(2)" prop="new_sign" sortable min-width="140" align="center">
          <template v-slot="scope">
            {{scope.row.new_sign}}
          </template>
        </el-table-column>
        <el-table-column :label="getLabel(3)" prop="student" min-width="110" align="center">
          <template v-slot="scope">
            {{scope.row.student}}
          </template>
        </el-table-column>
        <el-table-column :label="getLabel(4)" prop="review_sign" sortable min-width="140" align="center">
          <template v-slot="scope">
            {{scope.row.review_sign}}
          </template>
        </el-table-column>
        <el-table-column :label="getLabel(5)" prop="net_income" min-width="120" align="center">
          <template v-slot="scope">
            {{scope.row.net_income}}
          </template>
        </el-table-column>
        <el-table-column :label="getLabel(3)" prop="consume_class_time" min-width="110" align="center">
          <template v-slot="scope">
            {{scope.row.consume_class_time}}
          </template>
        </el-table-column>
        <el-table-column label="开业累计现金净收入" prop="all_net_income" min-width="150" align="center">
          <template v-slot="scope">
            {{scope.row.all_net_income}}
          </template>
        </el-table-column>
      </el-table>
      <!-- 			<el-pagination background layout="total, prev, pager, next" small :total="pagination.total" :page-size="screen.number"
			 :current-page.sync="screen.page" class="mg-t-10">
			</el-pagination> -->
      <loading-c :show-loading='loading'></loading-c>
    </el-card>
  </div>
</template>

<script>
import {
  formatDate
} from '../../assets/js/myMethods.js'
import {
  exportExcel,
  toNumber,
  insertMoneySeparator
} from '../../assets/js/util.js'
import {
  getSchooleRanking
} from '../../assets/js/API/financial.js'
import loadingC from '../common/loading.vue'
export default {
  name: 'FinancialRanking',
  components: {
    loadingC
  },
  data() {
    return {
      loading: false,
      pagination: {
        total: 0
      },
      pickerOptions: {
        firstDayOfWeek: 1
      },
      screen: {
        page: 1,
        number: 10,
        week: new Date(),
        month: formatDate.detail(new Date(), 'yyyy-mm'),
        kind: '周' // 1- 是周 2- 是月
      },
      tableData: {
        1: []
      },
      tableData_export: []
    }
  },
  computed: {

  },
  created() {
    this.getSchooleRanking()
  },
  methods: {
    formatScreen() {
      var mapKind = {
        '周': 1,
        '月': 2
      }
      var params = {
        ...this.screen
      };
      if (params.kind == '周') {
        var date = formatDate.getWeekStartAndEnd(params.week);
        params.date = params.week ? [date.date_start, date.date_end] : []
      } else {
        params.date = params.month
      }
      params.kind = mapKind[params.kind];
      delete params.week;
      delete params.month;
      delete params.page;
      delete params.number;
      return params
    },
    exportExcel() {
      exportExcel(this.$refs.refTableExport.outerHTML, '校区排名.xls')
    },
    screenChange() {
      this.screen.page = 1;
      this.getSchooleRanking()
    },
    getSchooleRanking() {
      this.loading = true;
      getSchooleRanking.call(this, this.formatScreen())
        .then(res => {
          this.loading = false;
          if (res) {
            this.tableData_export = res;
            this.tableData = res;
            this.pagination.total = this.tableData_export.length
            var i = 1;
            var obj = {};
            this.tableData_export.forEach((item, index) => {
              item.index = index + 1;
              for (let i in item) {
                if (!isNaN(item[i] - 0)) {
                  item[i] = insertMoneySeparator(toNumber(item[i]))
                }
              }
              if (!(obj[i] instanceof Array)) obj[i] = [];
              obj[i].push(item);
              if ((index + 1) % this.screen.number == 0) i++
            });
            this.tableData = obj
          }
        })
        .catch(err => this.loading = false)
    },
    getLabel(index) {
      var mapLabel = {
        '周': [{
          label: '本周累计收款',
          value: ''
        },
        {
          label: '本周业绩',
          value: ''
        },
        {
          label: '本周新签(元)',
          value: ''
        },
        {
          label: '本周新招人数',
          value: ''
        },
        {
          label: '本周续费(元)',
          value: ''
        },
        {
          label: '本周现金净收入',
          value: ''
        },
        {
          label: '本周耗课金额',
          value: ''
        }
        ],
        '月': [{
          label: '本月累计收款',
          value: ''
        },
        {
          label: '本月业绩',
          value: ''
        },
        {
          label: '本月新签(元)',
          value: ''
        },
        {
          label: '本月新招人数',
          value: ''
        },
        {
          label: '本月续费(元)',
          value: ''
        },
        {
          label: '本月现金净收入',
          value: ''
        },
        {
          label: '本月耗课金额',
          value: ''
        }
        ]
      }
      return mapLabel[this.screen.kind][index].label
    }
  }
}
</script>

<style scoped>
.el-card {
  position: relative;
}

@media screen and (min-width: 1500px) {
  .title {
    font-size: 25px;
  }
}

@media screen and (max-width: 1499px) {
  .title {
    font-size: 20px;
  }
}
</style>
