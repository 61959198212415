<template>
	<!-- 学员管理 - 学员登记 - 学员报名 -->
	<div class="">
		<el-card>
			<!-- 导航标签 -->
			<breadcrumb-c :value_breadcrumb="value_breadcrumb"></breadcrumb-c>
			<!-- 导航标签/ -->
			<!-- 步骤条 -->
			<el-steps :active="active" finish-status="success" align-center>
				<el-step title="选择学员"></el-step>
				<el-step title="购买课包"></el-step>
			</el-steps>
			<!-- 步骤条/ -->
			<!-- 占位 -->
			<div style="height: 10px;"></div>
			<keep-alive>
				<component :is='getComponent' @current_change='current_change' :id="sid" :payer='payer' @costLesson='costLesson = $event' buttonText='购买' :isGetCostLesson.sync='costLessonSuccess'></component>				
			</keep-alive>
			<!-- 底部 -->
			<footer-button-c class="mg-t-10" @pre-step='preStep' @next-step='nextStep' :isShow_button1="isShow_button1"
			 :value_button2="value_button2" :isShow_button2="isShow_button2"></footer-button-c>
			<!-- 底部/ -->
		</el-card>
	</div>
</template>
<script>
	// 面包屑导航
	import breadcrumbC from '../../common/v-breadcrumb.vue'
	// 底部按钮
	import footerButtonC from './studentCourseRegister-button.vue'
	// 学员登记
	import registerC from './studentbasicInformation.vue'
	import studentC from '../../common/v-table-student.vue'
	import costLessonC from './costLessonBaseInfo.vue'
	import {
		getAge_range,
		isSpace,
		getMode_course
	} from '../../../assets/js/myMethods'
	export default {
		name: 'StudentCourseRegister',
		data() {
			return {
				isShow_button1: false,
				value_button2: '下一步',
				isShow_button2: true,
				value_breadcrumb: ['学员管理', '学员报名'],
				active: 0,
				sid: '',
				payer: '',
				costLessonSuccess: false
			}
		},
		computed: {
			getComponent () {
				if (this.active == 0) return 'student-c'
				else if (this.active == 1) return 'cost-lesson-c'				
			}
		},
		created() {
			
		},
		methods: {
			current_change (row) {
				this.sid = row.id.id + '';
				this.payer = row.id.guardian + ''
			},
			// 是否可以跳转到下一步骤
			isLeave() {
				if (this.active == 0 && this.sid == '') {
					this.$message.info('请选择一个学员');
					return true
				}
				return false
			},
			// 上一步
			preStep() {
				if (this.active == 0) return
				this.active--
			},
			// 下一步
			nextStep() {
				if (this.isLeave()) return
				console.log(this.active)
				if (this.active == 1) {
					this.costLesson()
				}
				else {
					this.active++;
				}
				
			}
		},
		components: {
			breadcrumbC,
			footerButtonC,
			studentC,
			costLessonC
		},
		watch: {
			active: function (val) {
				if (val == 0) {
					this.value_button2 = '下一步';
					this.isShow_button1 = false;
					this.isShow_button2 = true
				} else if (val == 1) {
					this.value_button2 = '购买课包';
					this.isShow_button1 = true;
					this.isShow_button2 = true
				}
			},
			costLessonSuccess: function (val) {
				if (val) this.$router.push('/student')
			}
		}
	}
</script>
<style scoped>
	.el-step__icon.is-text {
		width: 50px;
		height: 50px;
		border: 2px solid #7fcbcf;
	}

	.el-step .is-horizontal .el-step__line {
		top: 21px;
	}
</style>
