<template>
  <!-- 数据分析 -->
  <div>
    <div class="flex align-center justify-between">
      <div></div>
      <div>
        <el-select size='small' class="mg-r-10" style="" v-model="exportScreen.school_id">
          <el-option v-for="item in advanceOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-date-picker v-model="exportScreen.date" class='mg-r-10' style="" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-button size="small" type="primary" @click='exportExcel'>导出学员耗课明细</el-button>
      </div>
    </div>
    <el-card class="mg-t-10">
      <el-row :gutter="10">
        <el-col :span="6">
          <div style="font-size: 16px;font-weight: bold; display: inline;">预收数据</div>
          <!-- 校区 -->
          <el-select size='small' class="mg-l-10" style="width: 45%;" v-model="advanceScreen.school_id" @change='advanceSelectChange'>
            <el-option v-for="item in advanceOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <div class="card text-align-c flex align-center mg-t-10 space-around">
            <div class="flex align-center flex-column">
              <!-- 剩余未消耗课时 -->
              <div class="">
                <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                  {{advance.lave_lesson}}
                </div>
                <div class="" style="color: #9b9b9b;font-size: 14px;">剩余未消耗课时</div>
              </div>
              <!-- 总课时 -->
              <div class="mg-t-20">
                <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold">
                  {{advance.lesson}}
                </div>
                <div class="" style="color: #9b9b9b;font-size: 14px;">总课时</div>
              </div>
            </div>
            <div class="flex flex-column">
              <!-- 剩余未消耗金额 -->
              <div class="">
                <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold">
                  ￥{{advance.lave_amount | filterPrice | insertSeparator}}
                </div>
                <div class="" style="color: #9b9b9b;font-size: 14px;">剩余未消耗金额(元)</div>
              </div>
              <!-- 总金额 -->
              <div class="mg-t-20">
                <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold">
                  ￥{{advance.amount | filterPrice | insertSeparator}}
                </div>
                <div class="" style="color: #9b9b9b;font-size: 14px;">总金额</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <el-row>
            <el-col :span="6">
              <span style="font-size: 16px;font-weight: bold;display: inline;">校区经营报表</span>
            </el-col>
            <el-col :span="18">
              <data-analysis-search @throwDefault='getReportDefaultScreen = $event' @getScreen='getReportScreen' lastMonth='上月' thisMonth='当月' yesterday="昨天" today="今天" sevenDay="7天"></data-analysis-search>
            </el-col>
          </el-row>
          <div class="mg-t-10">
            <!-- 左侧 -->
            <el-row :gutter="5">
              <el-col :span="12">
                <div class="card text-align-c flex align-center space-around">
                  <div class="flex flex-column">
                    <!-- 业绩 -->
                    <div class="cursor-p" @click="showReportInfo('业绩')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold">
                        <span style="border-bottom: 2px dotted;">
                          ￥{{report.achievement | filterPrice | insertSeparator}}
                        </span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">业绩(元)</div>
                    </div>
                    <!-- 新签人次 -->
                    <div class="mg-t-20 cursor-p" @click="showReportInfo('新签人次')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold">
                        {{report.new_sign_count | insertSeparator}}
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">新签人次</div>
                    </div>
                  </div>
                  <div class="flex align-center flex-column space-around">
                    <!-- 流入金额(元) -->
                    <div class="cursor-p" @click="showReportInfo('流入金额')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold">
                        <span style="border-bottom: 2px dotted;">
                          ￥{{report.inflow_amount | filterPrice | insertSeparator}}
                        </span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">流入金额(元)</div>
                    </div>
                    <!-- 续费人次 -->
                    <div class="mg-t-20 cursor-p" @click="showReportInfo('续费学员列表')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.renew_total | insertSeparator}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">续费人次</div>
                    </div>
                  </div>
                  <div class="flex flex-column">
                    <!-- 流出金额 -->
                    <div class="cursor-p" @click="showReportInfo('流出金额')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">￥{{report.outflow_amount | filterPrice | insertSeparator}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">流出金额(元)</div>
                    </div>
                    <!-- 退费人次 -->
                    <div class="mg-t-20 cursor-p" @click="showReportInfo('退费人次')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.refund_total}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">退费人次</div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <!-- 右侧 -->
                <div class="card text-align-c flex align-center space-around" style="margin-left: 5px;">
                  <div class="flex flex-column align-center">
                    <!-- 出勤率 -->
                    <div class="" style="">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold">
                        {{report.attendance}}%
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">出勤率</div>
                    </div>
                    <!-- 消课金额 -->
                    <div class="mg-t-20 cursor-p" style="" @click="showReportInfo('产值记录')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">￥{{report.dismissal_amount | filterPrice | insertSeparator}}</span>
                      </div>
                      <div class="w-per-100" style="color: #9b9b9b;font-size: 14px;">消课金额</div>
                    </div>
                  </div>
                  <div class="flex flex-column align-center">
                    <!-- 出勤人次 -->
                    <div class="cursor-p" style="" @click="showReportInfo('考勤列表', 1)">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.attendance_total}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">出勤人次</div>
                    </div>
                    <!-- 消耗课时 -->
                    <div class="mg-t-20 cursor-p" @click="showReportInfo('产值记录')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.dismissal}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">消耗课时</div>
                    </div>
                  </div>
                  <div class="flex flex-column align-center">
                    <!-- 请假人次 -->
                    <div class="cursor-p" style="" @click="showReportInfo('考勤列表', 2)">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.leave_total}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">请假人次</div>
                    </div>
                    <!-- 未处理 -->
                    <div class="mg-t-20 cursor-p" style="" @click="showReportInfo('考勤列表', 4)">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.untreated_total}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">未处理</div>
                    </div>
                  </div>
                  <div class="flex flex-column align-center">
                    <!-- 旷课人次 -->
                    <div class="cursor-p" @click="showReportInfo('考勤列表', 3)">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.absenteeism_total}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">旷课人次</div>
                    </div>
                    <!-- 流失人次 -->
                    <div class="mg-t-20 cursor-p" style="" @click="showReportInfo('流失学员列表')">
                      <div class="" style="color: #fed90e;font-size: 20px;margin-bottom: 5px;font-weight:bold;">
                        <span style="border-bottom: 2px dotted;">{{report.churn_total}}</span>
                      </div>
                      <div class="" style="color: #9b9b9b;font-size: 14px;">流失人次</div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>

          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 经营数据 -->
    <el-card class="" style="margin-top: 10px;">
      <!-- 筛选 -->
      <div class="flex align-center">
        <div style="font-size: 16px;font-weight: bold;">经营数据</div>
        <!-- 日期 -->
        <data-analysis-search @throwDefault='getBusinessDefaultScreen = $event' @getScreen='getBusinessScreen' dateFormat="yyyy-MM"></data-analysis-search>
      </div>
      <div class="flex" style="flex-wrap: wrap;box-sizing: border-box;">
        <!-- 流水 -->
        <el-card class="mg-t-10" style="width: 49.5%;">
          <div class="flex">
            流水(元)
            <div class="" style="margin-left: 40px;">
              月平均
              <span>￥{{chartDataTurnover.average | filterPrice | insertSeparator}}</span>
            </div>
          </div>
          <ve-line :data="chartDataTurnover" class='mg-t-10'></ve-line>
        </el-card>
        <!-- 出勤率 -->
        <el-card class="mg-t-10" style="width: 49.5%;margin-left: 0.5%;">
          <div class="flex">
            出勤率(%)
            <div class="" style="margin-left: 40px;">
              月平均
              <span>{{(chartDataAttendance.average - 0).toFixed(0)}}%</span>
            </div>
          </div>
          <ve-line :data="chartDataAttendance" class='mg-t-10'></ve-line>
        </el-card>
        <!-- 消课(产值) -->
        <el-card class=" mg-t-10" style="width: 49.5%;">
          <div class="flex">
            产值统计(元)
            <div class="" style="margin-left: 40px;">
              月平均
              <span>￥{{chartDataOutputvalue.average | filterPrice | insertSeparator}}</span>
            </div>
          </div>
          <ve-line :data="chartDataOutputvalue" class='mg-t-10'></ve-line>
        </el-card>
      </div>
    </el-card>
    <!--  -->
    <el-dialog :visible.sync="dialogShow" center :title='dialogTitle' width="80%" v-if="dialogShow">
      <component :is="getComponent" :defaultScreen='defaultScreen' :achievement="achievement" :newPeople="newPeople" :type='attendanceStatus' :default-kind="dialogTitle === '退费人次' ? '退费金额' : dialogTitle" :use="dialogTitle === '退费人次' ? 'refund' : 'record'"></component>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "../../assets/js/myMethods.js";
import {
  extend,
  exportExcel,
  insertMoneySeparator,
} from "../../assets/js/util.js";
import dataAnalysisSearch from "./form/data-analysis-search.vue";
import recordListC from "./form/record-list.vue";
import outputvalueListC from "./form/outputvalue-list.vue";
import attendanceListC from "./form/attendance-list.vue";
import studentchurmListC from "./form/studentchurm-list.vue";
import studentrenewListC from "./form/studentrenew-list.vue";
export default {
  name: "DataAnalysis",
  data() {
    return {
      exportScreen: {
        school_id: "",
        date: [],
      },
      attendanceStatus: "",
      defaultScreen: {},
      getComponent: "",
      dialogTitle: "",
      dialogShow: false,
      achievement: false,
      newPeople: false,
      advanceOptions: [], //预收数据校区选择器配置
      advanceScreen: {
        //预收数据筛选
        school_id: "",
      },
      advance: {
        //预收数据绑定
        lave_lesson: 0,
        lesson: 0,
        lave_amount: 0,
        amount: 0,
      },
      report: {
        //校区经营数据绑定
        attendance_total: 0,
        leave_total: 0,
        absenteeism_total: 0,
        untreated_total: 0,
        attendance: 0,
        churn_total: 0,
        inflow_amount: 0,
        outflow_amount: 0,
        dismissal_amount: 0,
        dismissal: 0,
        renew_total: 0,
        refund_total: 0,
      },
      reportScreen: {
        //校区经营筛选
        school_id: "",
        date_start: "",
        date_end: "",
      },
      businessOptions: [], //经营数据校区选择器配置
      businessDateOptions: {
        //经营数据日期选择配置
        disabledDate(date) {
          var s = true;
          if (new Date() - new Date(date) > 0) s = false;
          return s;
        },
      },
      businessScreen: {
        //经营数据筛选
        school_id: "",
        date_start: "",
        date_end: "",
      },
      businessDate: [], //经营日期范围选择器绑定的
      chartDataOutputvalue: {}, //曲线图产值统计
      chartDataTurnover: {}, //曲线图流水统计
      chartDataAttendance: {}, //曲线图出勤率
    };
  },
  filters: {
    insertSeparator(val) {
      return insertMoneySeparator(val);
    },
  },
  methods: {
    exportExcel() {
      var date_start = this.exportScreen.date[0]
        ? this.exportScreen.date[0]
        : "";
      var date_end = this.exportScreen.date[1] ? this.exportScreen.date[1] : "";
      window.open(
        process.env.VUE_APP_BASE_URL +
        "/admin/v1/export/consume/detail?school_id=" +
        this.exportScreen.school_id +
        "&date_start=" +
        date_start +
        "&date_end=" +
        date_end,
        "_self"
      );
    },
    showReportInfo(title, type) {
      /* if (title == '流水记录') return */
      this.dialogShow = true;
      this.defaultScreen = this.getReportDefaultScreen();
      switch (title) {
        case "流入金额":
          this.getComponent = "record-list-c";
          this.achievement = false;
          this.newPeople = false;
          break;
        case "流出金额":
          this.getComponent = "record-list-c";
          this.achievement = false;
          this.newPeople = false;
          break;
        case "新签人次":
          this.getComponent = "record-list-c";
          this.newPeople = true;
          break;
        case "退费人次":
          this.getComponent = "record-list-c";
          this.achievement = false;
          this.newPeople = false;
          break;
        case "产值记录":
          this.getComponent = "outputvalue-list-c";
          break;
        case "考勤列表":
          this.getComponent = "attendanceListC";
          this.attendanceStatus = type;
          break;
        case "流失学员列表":
          this.getComponent = "studentchurm-list-c";
          break;
        case "续费学员列表":
          this.getComponent = "studentrenew-list-c";
          break;
        case "业绩":
          this.getComponent = "record-list-c";
          this.achievement = true;
          this.newPeople = false;
          break;
        default:
          this.getComponent = "";
          break;
      }
      this.dialogTitle = title;
    },
    getReportScreen(screen) {
      extend(this.reportScreen, screen);
      this.getReport();
    },
    getBusinessScreen(screen) {
      extend(this.businessScreen, screen);
      this.getBusiness();
    },
    /* 经营数据校区选择器改变 */
    businessSelectChange(id) {
      this.businessScreen.school_id = id;
      this.getBusiness();
    },
    /* 经营数据日期改变 */
    businessDateChange(date) {
      if (date == null) return;
      this.getBusiness();
    },
    /* 获取校区经营报表 */
    async getReport() {
      var { data: res } = await this.$http.get("/statistics/report", {
        params: this.reportScreen,
      });
      if (res.code == 0) {
        this.report = res.data;
      } else {
        this.$message.error("获取校区经营报表失败");
      }
    },
    /* 预收数据校区选择器改变 */
    advanceSelectChange(id) {
      this.advanceScreen.school_id = id;
      this.getAdvance();
    },
    /* 获取预收数据 */
    async getAdvance() {
      var { data: res } = await this.$http.get("/statistics/advance", {
        params: this.advanceScreen,
      });
      if (res.code == 0) {
        this.advance = res.data;
      } else {
        this.$message.error("获取预收数据失败");
      }
    },
    /* 获取消课金额 （产值统计） */
    async getOutputvalue() {
      var { data: res } = await this.$http.get("/statistics/outputvalue", {
        params: this.businessScreen,
      });
      if (res.code == 0) {
        this.chartDataOutputvalue = res.data;
      } else {
        this.$message.error("获取消课金额失败");
      }
    },
    /* 获取流水统计  */
    async getTurnover() {
      var { data: res } = await this.$http.get("/statistics/turnover", {
        params: this.businessScreen,
      });
      if (res.code == 0) {
        this.chartDataTurnover = res.data;
      } else {
        this.$message.error("获取流水统计失败");
      }
    },
    /* 获取出勤率 */
    async getAttendance() {
      var { data: res } = await this.$http.get("/statistics/attendance", {
        params: this.businessScreen,
      });
      if (res.code == 0) {
        this.chartDataAttendance = res.data;
      } else {
        this.$message.error("获取出勤率失败");
      }
    },
    /* 获取校区 */
    async getSchool() {
      var { data: res } = await this.$http.get("/statistics/school");
      if (res.code == 0) {
        var arr = [
          {
            id: "",
            name: "全部校区",
          },
        ];
        arr.push(...res.data);
        this.advanceOptions = [...arr];
        this.businessOptions = [...arr];
      } else {
        this.$message.error("获取校区失败");
      }
    },
    /* 获取经营数据 */
    getBusiness() {
      this.getOutputvalue();
      this.getTurnover();
      this.getAttendance();
    },
    /* 配置， 设置校区，计算一些日期 */
    options() {
      var school_id = JSON.parse(window.sessionStorage.getItem("schoole"))[0]
        .id;
      var reportScreen = this.getReportDefaultScreen();
      extend(this.reportScreen, reportScreen);
      this.reportScreen.date_start = reportScreen.date[0];
      this.reportScreen.date_end = reportScreen.date[1];

      this.advanceScreen.school_id = "";

      var businessScreen = this.getBusinessDefaultScreen();
      extend(this.businessScreen, businessScreen);
      this.businessScreen.date_start = businessScreen.date[0];
      this.businessScreen.date_end = businessScreen.date[1];
    },
  },
  watch: {
    reportDate: function (val) {
      var report = this.reportScreen;
      if (val == null) {
        report.date_start = "";
        report.date_end = "";
      } else {
        report.date_start = val[0];
        report.date_end = val[1];
      }
    },
    businessDate: function (val) {
      var business = this.businessScreen;
      if (val == null) {
        business.date_start = "";
        business.date_end = "";
      } else {
        business.date_start = val[0];
        business.date_end = val[1];
      }
    },
  },
  components: {
    dataAnalysisSearch,
    recordListC,
    outputvalueListC,
    attendanceListC,
    studentchurmListC,
    studentrenewListC,
  },
  created() {
    this.getSchool();
  },
  mounted() {
    this.options();
    this.getReport();
    this.getAdvance();
    this.getBusiness();
  },
};
</script>

<style scoped lang="less">
.card {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 2px;
  height: 220px;
}

.selectTag {
  border: 1px solid #fed90e !important;
  color: #fed90e !important;
}

.tag {
  border: 1px solid #c0c4cc;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  color: #424346;
  font-size: 14px;
}
</style>
