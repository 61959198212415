<template>
	<!-- 星级选择器 -->
	<el-select v-model="selectData" placeholder="请选择星级" style='width: 100%;' :disabled="disabled">
		<el-option v-for="item in option" :key="item.level" :label="item.level | getLabel" :value="item.level">
		</el-option>
	</el-select>
</template>

<script>
	import {
		getClassFee
	} from '../../assets/js/API/salary.js'
	export default {
		name: 'LevelSelect',
		props: {
		    defaultSelect: {
				default: ''
			},
			disabled: {
				type: Boolean,
				default: false
			},
			isGetClassFee: {
				type: Boolean,
				default: false
			},
			haveLevel: {
				type: Array,
				default: function () {
					return []
				}
			}
		},
		data () {
			return {
				option: [],
				selectData: null
			}
		},
		methods: {
			getClassFee () {
				getClassFee.call(this)
						   .then( res => {
							   this.option = res.data;
							   this.selectData = this.option.length == 0 ? '' : this.defaultSelect
							   console.log(this.selectData,this.option)
							   this.$emit('update:select', this.selectData,this.option);
						   })						   
						   .catch( err => {})
			},
			setOptions () {
				var level = this.$param.level.filter( item => {
					var bool = this.haveLevel.some( item2 => {
						return item2 == item.level
					})
					return !bool
				})
				this.selectData = level.length == 0 ? '' : level[0].level
				this.option = level;
			}
		},
		watch: {
			defaultSelect: function (val) {
				this.selectData = this.defaultSelect
			},
			selectData: function (val) {
				this.$emit('update:select', this.selectData,this.option);
			},
			haveLevel: function (val) {
				this.setOptions()
			}
		},
		filters: {
			getLabel (val) {
				const LEVEL = ['', '一星', '二星', '三星', '四星', '五星', '六星', '七星', '八星', '九星', '十星']
				return LEVEL[val]
			}
		},
		created() {			
			this.isGetClassFee ? this.getClassFee() : this.setOptions();
			// this.$emit('update:select', this.selectData,1);
		}
	}
</script>

<style>
</style>
