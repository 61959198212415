<template>
	<div :style="'min-width:' +widthX+ 'px;' ">
		<!-- 日期选择 -->
		<el-card>
			<!-- 头部 -->
			<div slot="header" class="clearfix" style="display: flex;max-height: 20px;justify-content: flex-start;align-items: center;">
				<div class="block">
					<span class="demonstration fs-15">选择时间：</span>
					<el-date-picker v-model="chooseTime" type="week" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" :picker-options="pickerOptions"
					 @change="timeChange" :clearable="false" size="medium">
						>
						<span>选择的时间：{{date_start}} {{date_end}}</span>
					</el-date-picker>
				</div>
				<div class="block" style="margin-left: 15px;">
					<span class="demonstration fs-15">搜索：</span>
					<el-input v-model="key" placeholder="请输入班级,老师(教师仅限所属班级)" style="width: 280px;" @blur="keyChange" size="medium"></el-input>
				</div>
			</div>
			<!-- 课表开始 -->
			<!-- 头部 -->
			<div class="flex" :style="'width:' + widthX + 'px;height: 60px; border-bottom: 1px solid #eee;align-items: center;border-top: 1px solid #eee;'"
			 ref="scrollXWidth">
				<div style="min-width: 150px;max-height: 100px;border-right: 1px solid #eee;color:grey;display: flex;flex-direction: column;">
					<div style="display: flex;align-items: center;width: 75px">
						<div style="width: 15px;height:15px; border-radius: 50%;background-color: #409EFF;"></div>
						<span style="margin-left: 10px;font-size: 10px;color:#409EFF ;"> 正式班</span>
					</div>
					<div style="display: flex;margin-top: 5px;align-items: center;width:75px">
						<div style="width: 15px;height:15px;border-radius: 50%;background-color: #8DC556;"></div>
						<span style="margin-left: 10px;font-size: 10px;color: #8DC556;"> 体验班</span>
					</div>
					<div style="display: flex;margin-top: 5px;align-items: center;width:75px">
						<div style="width: 15px;height:15px;border-radius: 50%;background-color: #ff7272;"></div>
						<span style="margin-left: 10px;font-size: 10px;color: #ff7272;"> 活动班</span>
					</div>

				</div>
				<!-- 上课地区 -->
				<div style="display:flex;flex-wrap: nowrap;overflow-x:scroll;width: 1650px;height: 60px;" ref="ScrollX">
					<div style="display: flex; flex-direction: column;height: 60px;color:grey;max-width: 150px;min-width:150px;border-right: 1px solid #eee;"
					 v-for="(item,index) in scrollXData">
						<span style="line-height: 30px;text-align: center;max-width: 150px;min-width:150px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding: 5px 10px;font-weight:bold;">{{item.name}}</span>
						<span style="line-height: 30px;text-align: center;max-width: 150px;min-width:150px;">可容纳{{item.capacity}}人</span>
					</div>
				</div>
			</div>
			<!-- body -->
			<div style="display: flex;max-height: 700px;overflow-y: auto;">
				<!-- Y轴 -->
				<div style="min-width: 150px;display: flex;align-items: center;flex-direction: column;overflow-y:hidden;" :style=" 'height:' + heightY + 'px'"
				 ref="moveY" id="moveY">
					<div :style="'mix-height:' + item.time.length * 50  +'px;display: flex;' " v-for="(item,index) in scrollYData">
						<div style="width: 75px;display: flex;justify-content: center;align-items: center;flex-direction: column;border-right: 1px solid #eee;"
						 class="dateTime">
							<div style="font-weight: bold;">{{item.week}}</div>
							<div>{{item.date}}</div>
						</div>
						<div style="width: 75px;" class="time">
							<div v-for="(time,index) in item.time" style="display: flex;justify-content: center;align-items: center;color:grey;">{{time}}</div>
						</div>
					</div>
				</div>
				<!-- 课表 -->
				<div style="width: 1650px;display: flex;align-items: center;flex-direction: column;overflow:scroll;position: relative;box-sizing: border-box;"
				 ref="moveClass" id="moveClass" :style=" 'height:' + heightY + 'px'">
					<canvas id="myCanvas" :height="heightY" :width="widthX" :style=" 'height:' + heightY +'px;z-index: 999;position: absolute;top: 0px;left: 0px;'"
					 @click="canvasClick"></canvas>
				</div>
			</div>
		</el-card>
		<!-- 选择班级弹出层 -->
		<el-dialog :visible.sync="dialogVisible" width="30%">
			<div class="dialog-container">
				<div>班级: {{choose.name}} ({{choose.age_start}} - {{choose.age_end}}岁)</div>
				<div>报班学员: {{choose.student}}人</div>
				<div>主课老师: {{choose.teacherName}}</div>
				<div>当前课时: {{choose.date}} {{choose.week}} {{choose.time_start}}-{{choose.time_end}}</div>
			</div>
			<div style="margin:20px auto; width: 80%;display: flex; justify-content:space-around;">
				<div style="background-color:#409EFF; padding: 10px 20px; border-radius: 2px;color: #FFFFFF;cursor: pointer;"
				 @click="classDetail">班级详情</div>
				<div style="background-color:#409EFF; padding: 10px 20px; border-radius: 2px;color: #FFFFFF;cursor: pointer;"
				 @click="attendanceAndWork">考勤/作品上传</div>
			</div>
		</el-dialog>
		<!-- 选择创建班级类型 -->
		<el-dialog :visible.sync="selectClassTypeShow" center title="选择班级类型" width="30%">
			<div class="flex align-center space-around">
				<img src="../../assets/image/openClassBtn1.png" class="classTypeImgSize cursor-p" @click="selectClassType(1)" />
				<img src="../../assets/image/openClassBtn2.png" class="classTypeImgSize cursor-p" @click="selectClassType(3)" />
			    <img src="../../assets/image/openClassBtn3.png" class="classTypeImgSize cursor-p" @click="selectClassType(3)" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import courseC from './form/education-calendar.vue'
	import classJs from '../../assets/js/class.js'
	export default {
		name: 'EducationFastTypesetting',
		props: {
			flagAddClass: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				selectClassTypeShow: false,
				/* 选择时间 */
				chooseTime: '',
				/* 时间选择配置 */
				pickerOptions: {
					firstDayOfWeek: 1
				},
				/* 搜索 */
				key: '',
				/*  */
				date_start: '',
				date_end: '',
				/* 是否代开dialog */
				dialogVisible: false,
				/* 允许横向拖拽 */
				allowXDrag: false,
				/* 正在移动的标志 */
				bodyIsMove: true,
				/* Y轴高度 */
				heightY: 0,
				/* X轴的宽度 */
				widthX: 0,
				/* 选中的班级 */
				choose: {},
				/* 存班级上课时间坐标 （二维数组） */
				coordinate: [
					[],
					[],
					[],
					[],
					[],
					[],
					[]
				],
				/* Y轴数据 */
				scrollYData: [],
				classTime: [],
				scrollXData: [],
				/* 颜色大全 */
				colors: [
					'#2196F3', '#00BCD4', '#e8900a', '#353535', '#d05939', '#5fb858', '#7f16e1', '#b941b5', '#3abc8f', '#5458f1',
					'#FF00FF', '#BC8F8F', '#808A87', '#A066D3', '#FF9912'
				],
				/* 记录时间 */
				day: [],
				/*  */
				emptyClass: {}
			}
		},
		methods: {
			selectClassType(type) {
				this.selectClassTypeShow = false;
				window.sessionStorage.setItem('initClass', JSON.stringify(this.emptyClass));
				this.$router.push('/education/courseManager/addClass?type=' + type);
			},
			/* 弹出事件 */
			/* 跳转班级详情 */
			classDetail() {
				this.dialogVisible = false;
				this.$router.push('/education/showClass/' + this.choose.cid)
			},
			/* 跳转考勤，上传作品 */
			attendanceAndWork() {
				this.dialogVisible = false;
				var tempDate = this.choose.year + '-' + this.choose.date.replace('月', '-');
				this.$router.push('/education/student-state?cid=' + this.choose.cid + '&date=' + tempDate + '&time_start=' + this.choose.time_start +
					'&time_end=' + this.choose.time_end + '&name=' + this.choose.name + '&course_content=' + this.choose.course_content)
			},
			/* 输入框改变 */
			keyChange(e) {
				this.getClassTime();
			},
			/* 时间选择改变 */
			timeChange(e) {
				let date = classJs.formatDate.getWeekStartAndEndByChange(e)
				this.date_start = date.date_start
				this.date_end = date.date_end;
				this.getClassTime()
			},
			/* 操作移动区域滑动 */
			operate(bool) {
				this.$nextTick(() => {
					let moveClass = this.$refs.moveClass
					/* 移动区域滑动 */
					moveClass.addEventListener('scroll', (e) => {
						moveY.scrollTop = e.target.scrollTop;
					})
				});
				/* 开始画画 */
				this.draw(bool);
			},
			/* 初始化空白部分的坐标 */
			iniEmpty() {
				//8:00 - 22:00  15个 每个占高 50 这里是一周内 每天的高度单位
				let tempDay = [
					[0, 750],
					[750, 1500],
					[1500, 2250],
					[2250, 3000],
					[3000, 3750],
					[3750, 4500],
					[4500, 5250]
				];
				this.day = tempDay;
			},
			/* draw 画布 */
			draw(bool) {
				this.coordinate = [
					[],
					[],
					[],
					[],
					[],
					[],
					[]
				];
				this.$nextTick(() => {
					var VerticalLineNumber = this.scrollXData.length,
					    HorizontalLineNumber = this.heightY / 50;					
					const canvas = document.getElementById('myCanvas');
					var context = canvas.getContext('2d')
					context.clearRect(0, 0, this.widthX, this.heightY);
					context.beginPath();
					/* 画横线 */
					context.translate(-0.5, -0.5);
					context.lineWidth = 1;
					context.strokeStyle = "#eee"
					for (var i = 0; i < HorizontalLineNumber; i++) {
						context.moveTo(0, (i + 1) * 50);
						context.lineTo(this.widthX - 150, (i + 1) * 50)
					}
					context.closePath();
					context.stroke();

					/* 画竖线 */
					context.beginPath();
					for (var i = 0; i < VerticalLineNumber; i++) {
						context.moveTo(151 * (i + 1), 0);
						context.lineTo(151 * (i + 1), this.heightY)
					}
					context.closePath();
					context.stroke();
					if (bool) {
						return;
					}
					/* 画课表 */
					this.classTime.forEach((item) => {
						switch (item.week) {
							case ('周一'):
								this.getClass(item, context, 0);
								break;
							case ('周二'):
								this.getClass(item, context, 1);
								break;
							case ('周三'):
								this.getClass(item, context, 2);
								break;
							case ('周四'):
								this.getClass(item, context, 3);
								break;
							case ('周五'):
								this.getClass(item, context, 4);
								break;
							case ('周六'):
								this.getClass(item, context, 5);
								break;
							case ('周天'):
								this.getClass(item, context, 6);
								break;
						}
					})
					//第一个定位
					let moveY = this.$refs.moveClass;
					let weeks = new Date().getDay();
					weeks == 0 ? weeks = 7 : '';
					let temp = 0;
					let tempWeek = (weeks - 1);
					while (tempWeek > 0) {
						tempWeek--;
						temp = temp + this.scrollYData[tempWeek].time.length * 50;
					}
					moveY.scrollTop = temp;
				})
			},
			/* 获取上课时间 */
			getClass(item, context, week) {
				item.class.forEach((classTime) => {
					const timeStart = new Date('2020/10/10 ' + classTime.time_start).getTime();
					const timeEnd = new Date('2020/10/10 ' + classTime.time_end).getTime();
					const timeXStart = new Date('2020/10/10 ' + this.scrollYData[week].time[0]).getTime();
					/* 计算时间差 */
					let top = ((timeStart - timeXStart) / 10 / 60 / 60 / 2)
					let buttom = (((timeEnd - timeXStart) / 10 / 60 / 60 / 2))
					/* 计算Y轴坐标 */
					const index = this.scrollXData.findIndex((classRoom, index) => {
						if (classRoom.id == classTime.classroomId) {
							return true;
						}
					})
					let temp = 0;
					let tempWeek = week;
					while (tempWeek > 0) {
						tempWeek--;
						temp = temp + this.scrollYData[tempWeek].time.length * 50;
					}
					temp = temp + top;
					/* 画矩形 也就是上课时间 */
					var mapBgColor = {
						1: '#409EFF',
						3: '#8DC556',
						4: '#ff7272'
					}
					context.beginPath();
					context.fillStyle = mapBgColor[classTime.type];
					context.strokeStyle = '#fed90e';
					context.fillRect(index * 150, temp, 149, buttom - top);
					context.strokeRect(index * 150, temp + 0.5, 149, 1)
					context.font = "12px 微软雅黑"
					/* 班级名字 */
					context.fillStyle = '#eee'
					buttom - top <= 25 ? context.fillText(this.elips(item.week + " " + classTime.name, 11), index * 150 + 10, temp +
						16) : context.fillText(this.elips(item.week + " " + classTime.name, 11), index * 150 + 10, temp + (buttom -
						top) / 2.8);
					context.font = "8px微软雅黑"
					/* 班级剩余人数 */
					//判断是否有班级容量
					let capacity = 0;
					if (classTime.classCapacity > 0) {
						capacity = classTime.classCapacity - classTime.student;
					} else {
						capacity = classTime.capacity - classTime.student;
					}
					buttom - top <= 25 ? '' : context.fillText(classTime.age_start + "-" + classTime.age_end + "岁 (空位:" + (capacity) +
						"人)", index * 150 + 10, temp + (buttom - top) / 2.5 + 11);
					/* 上课时间 */
					buttom - top <= 35 ? '' : context.fillText(classTime.time_start + "-" + classTime.time_end, index * 150 + 10,
						temp + (buttom - top) / 2.5 + 25);
					context.closePath();
					/* 记录坐标 */
					/* 记录 班级上课时间 减少遍历次数 */
					this.coordinate[week].push({
						yTop: temp, // y-top
						yBottom: buttom + temp - top, //y-botom
						xLeft: index * 150,
						xRight: index * 150 + 150,
						cid: classTime.cid,
						name: classTime.name,
						classrromId: classTime.classroomId,
						classroom: classTime.classroom,
						time_start: classTime.time_start,
						time_end: classTime.time_end,
						student: classTime.student,
						date: item.date,
						age_start: classTime.age_start,
						age_end: classTime.age_end,
						teacherName: classTime.teacherName,
						week: item.week,
						course_content: classTime.course_content
					});
				});

			},
			/* canvas点击事件 */
			canvasClick(e) {
				/* 点击的位置 */
				let X = e.offsetX;
				let Y = e.offsetY;
				/* 获取选中的节点 如果对象不等于空 */
				this.choose = this.getArea(X, Y);
				if ((JSON.stringify(this.choose) == '{}') ? true : false) {
					this.flagAddClass && (this.selectClassTypeShow = true)
					/* 这里是要开始新建班级  跳转*/
				} else {
					/* 延时100毫秒显示，不然会与遮罩层的关闭起冲突 */
					this.dialogVisible = true;
				}
			},
			/* 判断点击区域 */
			getArea(X, Y) {
				let tempArea = {};
				for (var i = 0; i < 7; i++) {
					let temp = this.scrollYData[i].time.length * 50;
					let tempWeek = i;
					while (tempWeek > 0) {
						tempWeek--;
						temp = temp + this.scrollYData[tempWeek].time.length * 50;
					}
					if (Y <= temp) {
						/* 比较周一区域的 班级  展现选中的区域*/
						this.coordinate[i].forEach((val, index) => {
							if ((X >= val.xLeft && X <= val.xRight) && (Y >=
									val.yTop && Y <= val.yBottom)) {
								this.coordinate[i][index].year = this.scrollYData[i].year
								tempArea = this.coordinate[i][index];
								return tempArea;
							}
						})
					}
				}
				if ((JSON.stringify(tempArea) != '{}') ? true : false) {
					return tempArea;
				} else {
					let chooseTempDay = 0;
					this.day.forEach((val, index) => {
						if (Y > val[0] && Y <= val[1]) {
							chooseTempDay = index;
							return false;
						}
					})
					/* 获取选中的空白部分是星期几 */
					var tempHoure = Y - this.day[chooseTempDay][0];
					var countsY = 0; //获取时间
					while (tempHoure > 50) {
						countsY++;
						tempHoure = tempHoure - 50;
					}
					var countsX = 0;
					while (X > 150) {
						countsX++;
						X = X - 150
					}
					/* 获取 时间 和上课场地 */
					this.emptyClass = {
						'week': chooseTempDay + 1,
						'date': (this.scrollYData[chooseTempDay].date).replace('月', '-'),
						'year': this.scrollYData[chooseTempDay].year,
						'time_start': this.scrollYData[chooseTempDay].time[countsY],
						'time_end': countsY + 1 == 15 ? '23:00' : this.scrollYData[chooseTempDay].time[countsY + 1],
						'classroom': {
							id: this.scrollXData[countsX].id,
							name: this.scrollXData[countsX].name
						},
						'capacity': this.scrollXData[countsX].capacity,
						'course_content': this.scrollXData[countsX].course_content
					}					
					return {};
				}
			},
			/* 超出字体省略 */
			elips(playerName, len) {
				var new_playerName = "";
				if (playerName.length > len) {
					new_playerName = playerName.substring(0, len);
					new_playerName += ".."
				} else {
					new_playerName = playerName;
				}
				return new_playerName;
			},
			/* 格式化数据 */
			formateData() {
				//获取Y轴的高度 
				var temp = 0;
				var bool = true;
				this.scrollYData.forEach((item, index) => {
					this.scrollYData[index].time = this.getDateArray(item.time.minTime.substring(0, 2) + ":00", item.time.maxTime);
					temp = temp + this.scrollYData[index].time.length;
					!this.scrollYData[index].bool ? bool = false : '';
				})
				this.heightY = temp * 50;
				this.widthX = (this.scrollXData.length + 1) * 150;
				bool ? this.$message.info('暂无上课班级..') : '';
				return bool;
			},
			/* 获取时间间隔 */
			getDateArray(start, end) {
				var start = new Date('2012/12/28 ' + start);
				var end = new Date('2012/12/28 ' + end);
				let startDate = start.getTime();
				let endDate = end.getTime();
				const space = 60 * 60 * 1000;
				let mod = endDate - startDate;
				let dateArray = [];
				while (mod > space) {
					let d = new Date();
					d.setTime(startDate + space);
					dateArray.push(d);
					mod = mod - space;
					startDate = startDate + space;
				}
				dateArray.unshift(new Date(start.getTime()));
				dateArray.push(new Date(end.getTime()));
				let time = dateArray.map(item => {
					item = item.getHours() + ":" + item.getMinutes().toString().padStart(2, "0");;
					return item;
				});
				return time;
			},
			/* 获取数据 */
			async getClassTime() {
				this.classTime = [];
				const {
					data: res
				} = await this.$http.get('system/index/class?date_start=' + this.date_start + '&date_end=' + this.date_end +
					"&key=" + this.key + "&type=1"
				);
				if (res.code == 200) {
					this.classTime = res.data.classTime;
					this.scrollXData = res.data.scrollXData
					this.scrollYData = res.data.scrollYData
					this.operate(this.formateData());
				}
			}
		},
		created() {
			/* 今天时间 */
			this.chooseTime = new Date();
			let date = classJs.formatDate.getWeekStartAndEndByChange(classJs.formatDate.getNowFormatDate());
			this.date_start = date.date_start
			this.date_end = date.date_end;
			setTimeout(() => {
				this.getClassTime();
			}, 50)
			this.iniEmpty();
		},
		components: {
			courseC
		}
	}
</script>

<style lang="less" scoped>
	.classTypeImgSize {
		vertical-align: middle;
		width: 30%;
	}
	.card-header {
		width: 10%;
		display: flex;
		justify-content: space-between;
	}

	/deep/ .el-card__body {
		flex: 1
	}

	div {
		font-size: 14px;
	}

	.containers {
		background: linear-gradient(180deg, red, blue);
		color: transparent;
		-webkit-background-clip: text;
		line-height: 100%;

	}

	/* chrome 和Safari去除滚动条 */
	*::-webkit-scrollbar {
		display: none;
	}

	* {
		/* IE去除滚动条IE10+ */
		-ms-overflow-style: none;
		/* 火狐 */
		scrollbar-width: none;
	}

	.time>div {
		line-height: 49px;
		min-height: 40px;
		max-height: 49px;
		border-bottom: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.dateTime {
		border-bottom: 1px solid #eee;
	}

	.dialog-container>div {
		padding: 8px;
	}
</style>
