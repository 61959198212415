// 2021-04-27由FZY创建 用于财务配置

/* 
	2021-04-27 创建 ，用于拿到财务审批的一些状态
*/
export function judgingState(res, flag = false) {
	/*
		imageIndex 1- 待提交 2- 待处理 3- 已驳回 4- 我已处理 5- 待采购 6- 采购中 7- 已完成 8- 已撤销 9- 已作废
		flag false- 报销、借款、付款、退费、发票 true- 采购
	*/
   const INITIAL_APPROVAL = 'trial_ids';
   const FINAL_APPROVAL = 'final_trial_ids';
   const CONFRIM_APPROVAL = 4; //申请通过的状态码
   const CONFRIM_EXAMINE_STATUS = 2; //审批人通过的状态吗
   !res.applicant && (res.applicant = res.application);
	var result = {
	   imageIndex: '', //用于图标展示
	   flag_applicant: false, //是不是申请人
	   flag_approve: false, //是不是审批人
	   applyStatus: res.applyStatus || res.status || res.approveStatus, //当前的审批状态
	   approveType: '',//是初始审批人(trial_ids)还是最终审批人(final_trial_ids)
	   examineStatus: '',//当前审批人的审批状态
	   isAllPassed: ''//所有初审批人是否全部审批
	}
	flag && (result.flag_purchase = false, result.purchaseStatus = '', result.flag_purchasePeople = false);
	var map_skip = { //直接返回的状态码 映射
		3: 3,
		5: 8
	};
	!flag && (map_skip[4] = 7);
	var skip = Object.keys(map_skip);
	var teacherId = sessionStorage.getItem('teacherID');
	var map_approveStatus = { //当前审批状态码映射
	   1: 2,
	   2: 4,
	   3: 3
	}
	var map_purchaseStatus = { //当前采购状态码 映射
	   1: 5,
	   2: 6,
	   3: 7
	}
	var	index_approve = res[INITIAL_APPROVAL].findIndex( item => item.id == teacherId);
	if (index_approve == -1) {
		index_approve = res[FINAL_APPROVAL].findIndex( item => {
			if (item.id == teacherId) {
				result.approveType = FINAL_APPROVAL
				return true
			}
		})
	} else result.approveType = INITIAL_APPROVAL
	
	if (flag && res.purchase.length > 0) {
		result.flag_purchasePeople = res.purchase[0].procurementId ? true : false;
		result.flag_purchase = res.purchase[0].procurementId == teacherId ? true : false;
		result.purchaseStatus = res.purchase[0].status
	}
	result.flag_applicant = res.applicant.id == teacherId ? true : false;
	result.flag_approve = index_approve == -1 ? false : true;
	if (skip.includes(result.applyStatus + '')) { //采购-如果审批状态变为3或者5，直接返回 其他是3、4、5
		result.imageIndex = map_skip[result.applyStatus];
		return result
	}
	if (result.flag_applicant && result.flag_approve && result.flag_purchase) { //既是申请也是审批也是采购 说明已经审批通过了
		result.imageIndex = map_purchaseStatus[result.purchaseStatus];
		result.examineStatus = result.applyStatus;
		result.isAllPassed = true
	} else if (result.flag_applicant && result.flag_approve) { //既是申请也是审批  有可能已经开始采购了、也可能还没有
		if (flag) {
			if (result.applyStatus == CONFRIM_APPROVAL) { //完成审批
				result.imageIndex = map_purchaseStatus[result.purchaseStatus];
				result.examineStatus = result.applyStatus;
				result.isAllPassed = true;
			} else {
				var approve = res[result.approveType][index_approve];
				if (approve.status == 1) result.imageIndex = result.applyStatus  //作为审批人有没有处理
				else result.imageIndex = map_approveStatus[approve.status]; //已处理	
				result.examineStatus = approve.status;
				result.isAllPassed = res[INITIAL_APPROVAL].every( item => item.status == 2)
			}
		} else {
			var approve = res[result.approveType][index_approve];
			if (approve.status == 1) result.imageIndex = result.applyStatus  //作为审批人有没有处理
			else result.imageIndex = map_approveStatus[approve.status]; //已处理	
			result.examineStatus = approve.status;
			result.isAllPassed = res[INITIAL_APPROVAL].every( item => item.status == 2)
		}
	} else if (result.flag_applicant && result.flag_purchase) { //既是申请也是采购	
		result.imageIndex = map_purchaseStatus[result.purchaseStatus]
	} else if (result.flag_approve && result.flag_purchase) { //既是审批也是采购
		result.imageIndex = map_purchaseStatus[result.purchaseStatus];
		result.examineStatus = CONFRIM_EXAMINE_STATUS;
		result.isAllPassed = true
	} else if (result.flag_applicant) { //申请 有可能在审批、驳回了、在分配采购人、在采购
		if (flag) {
			if (result.applyStatus == CONFRIM_APPROVAL) { //审批通过
				result.imageIndex = map_purchaseStatus[result.purchaseStatus];
			} else {
				result.imageIndex = result.applyStatus
			}
		} else {
			result.imageIndex = result.applyStatus
		}
	} else if (result.flag_purchase) { //采购
		result.imageIndex = map_purchaseStatus[result.purchaseStatus];
	} else if (result.flag_approve) { //审批 分有没有完成审批
		if (flag) {
			if (result.applyStatus == CONFRIM_APPROVAL) { //审批通过
				result.imageIndex = map_purchaseStatus[result.purchaseStatus];
				var approve = res[result.approveType][index_approve];
				result.examineStatus = approve.status;
				result.isAllPassed = true
			} else {
				var approve = res[result.approveType][index_approve];
				result.imageIndex = map_approveStatus[approve.status];
				result.examineStatus = approve.status;
				result.isAllPassed = res[INITIAL_APPROVAL].every( item => item.status == 2)
			}
		} else {
			var approve = res[result.approveType][index_approve];
			result.imageIndex = map_approveStatus[approve.status];
			result.examineStatus = approve.status;
			result.isAllPassed = res[INITIAL_APPROVAL].every( item => item.status == 2)
		}
	} else { //什么都不是
	    if (flag) {
			if (result.applyStatus == CONFRIM_APPROVAL) { //审批通过
				result.imageIndex = map_purchaseStatus[result.purchaseStatus];
			} else {
				result.imageIndex = result.applyStatus
			}
		} else {
			result.imageIndex = result.applyStatus
		}		
	}
	return result
}