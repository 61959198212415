<template>
  <!-- 课包信息 -->
  <div>
    <div class="text-align-r fs-12" style="color: #888;position: relative;height: 16px;margin-bottom: 20px">
      <div style="position: absolute;top: 0;left: 75px;" class="" v-if="getType == '结转'">
        <span style="cursor: help;" @mouseenter="mouseenter" @mouseleave="tipsShow = false">结转金额：{{surplus.lave_amount}}
          <span style="color: #bbb;">(鼠标移过来查看更多信息)</span></span>
        <span style="margin-left: 20px;">
          <span style="color: red;margin-right: 5px;">*</span>更换类型:
          <el-select size="mini" v-model="formData.replace_type" @change="replacementTypeChange">
            <el-option v-for="(item, index) in replacementType" :key="'replacement' + index" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </span>
        <div class="surplus" @mouseleave="tipsShow = false" @mouseenter="tipsShow = true" v-show='tipsShow' :style="{ left: cursor.cursor_x + 'px' , top: cursor.cursor_y + 'px'  }">
          <div class="flex align-center"><span style='color: red;margin-right: 10px;'>*</span>结转金额 = (应付金额 / 购买课时) * (购买课时 -
            消耗课时)</div>
          <div class="text-align-l" style="padding: 5px 15px;">
            <div class="flex">
              <div class='w-per-50'>购买课时： {{surplus.cost_lesson}}</div>
              <div class='w-per-50'>消耗课时： {{surplus.dis_lesson}}</div>
            </div>
            <div class="flex">
              <div class='w-per-50'>应付金额： {{surplus.amount_payable}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: absolute;top: 0;left: 75px;" class="" @mouseenter="mouseenter" @mouseleave="tipsShow = false" v-if="getType == '升级'">
        <span style="cursor: help;">应付金额：{{surplus.amount_payable}}</span><span style="cursor: help;margin-left: 15px">赠送课时：{{surplus.give_lesson}}</span><span style="cursor: help;margin-left: 15px">消耗课时：{{surplus.consume}}</span>
      </div>
      <div style="position: absolute;right: 5px;top: 0">学员ID： {{formData.sid}}</div>
      <div style="position: absolute;right: 5px;top: 16px">课包ID： {{formData.id}}</div>
    </div>
    <el-form label-position="right" label-width="100px" :model="formData" :rules="rules" ref='refForm' class="mg-t-10">
      <el-row>
        <el-col :span="8">
          <el-form-item label="课程" prop="course_classification_id">
            <el-select size="medium" style='width: 100%' v-model="formData.course_classification_id" @change="getCourseDetail" :disabled="getType == '修改' || getType == '升级'">
              <el-option v-for="item in courseOptions" :key='item.id' :value='item.id' :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="课包" prop="course_package_id">
            <el-select size="medium" style='width: 100%' v-model="formData.course_package_id" @change="getTotalCost" :disabled="getType == '修改'">
              <el-option v-for="item in courseValueOptions" :key='item.id' :value='item.id' :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="课包价格" prop="total_cost">
            <el-input size='medium' disabled v-model="formData.total_cost"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="购买课时" prop="cost_lesson">
            <el-input size='medium' v-model="formData.cost_lesson" :disabled="getType == '修改'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="赠送课时" prop="give_lesson">
            <el-input size='medium' v-model="formData.give_lesson" :disabled="getType === '修改' && (flagUse || !(getRole === '超级管理员' || getRole === '督导'))"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="课时总数" prop="lesson">
            <el-input size='medium' disabled v-model="getLesson"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="折扣" prop="discount">
            <el-input size="medium" v-model="formData.discount" :disabled="getType == '修改' || formData.replace_type == 2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="减免金额" prop="reduction_amount">
            <el-input size="medium" v-model="formData.reduction_amount" :disabled="getType == '修改' || formData.replace_type == 2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="应付金额" prop="amount_payable">
            <el-input size='medium' disabled v-model="getAmountPayable"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="缴费类型" prop="pay_type">
            <el-input v-if="formData.pay_type == 3" disabled value="结转套餐"></el-input>
            <el-input v-else-if="formData.pay_type == 4" disabled value="升级套餐"></el-input>
            <el-input v-else-if="formData.pay_type == 5" disabled value="转增套餐"></el-input>
            <el-select size="medium" style="width: 100%;" v-model="formData.pay_type" @change="formData.earnest_money = ''" :disabled="getType == '修改' || getType == '升级'" v-else>
              <el-option v-for="item in payTypeOptions" :key='item.id' :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="定金" prop="earnest_money">
            <el-input size="medium" v-model="formData.earnest_money" :disabled="(formData.pay_type == 1 || getType == '修改' || formData.pay_type == 3)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实际支付" prop="paid_amount">
            <el-input size='medium' disabled v-model="getPaidAmount"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form-item label="备注" prop="remark">
            <el-input size="medium" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" v-model="formData.remark"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付方式">
            <el-select size="medium" style="width: 100%;" v-model="formData.pay_method">
              <el-option v-for="item in payMethodOptions" :key='item.val' :value="item.val" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上课时间" prop="class_time" v-if="getType != '升级'">
            <el-date-picker v-model="formData.class_time" type="date" placeholder="选择上课时间" size="medium" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable>
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="合同" prop="contract">
            <upload-c @upFileList='upFileList = $event.func' @clearFileList='clearFileList = $event.func' @upLoadUrl='formData.contract.push($event.url)' @removeFile='formData.contract = [...$event]'></upload-c>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="成交课包类型" prop="is_first" label-width="125px">
            <div slot='label' class="flex align-center" style="position: relative;"><span style="color: red;margin-right: 5px;">*</span>成交课包类型
              <span class="help mg-l-10 fs-16" style="color: rgb(222 97 59);cursor: help;" @mouseenter="help = true" @mouseleave="help = false">!</span>
              <div v-show='help' style="border-radius: 4px;text-align: left;z-index: 999;position: absolute;top: -15px;left: 100px;background-color: rgb(228 228 228);width: 310px;padding: 0 20px;">
                提示：用于计算老师体验课缴费、续费提成</div>
            </div>
            <el-select size="medium" style="width: 100%;" v-model="formData.is_first" @change="is_firstChange" clearable>
              <el-option v-for="item in is_firstOptions" :key='item.id' :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="labelTeacher" prop="recommender">
            <el-select size="medium" style="width: 100%;" v-model="formData.recommender" clearable>
              <el-option v-for="item in recommenderOptions" :key='item.id' :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="getRole === '超级管理员' || getRole === '督导'|| getRole === '校长'">
        <el-col :span="12">
          <el-form-item label="课包购买日期" prop="date">
            <div class="block">
              <el-date-picker size="medium" style="width: 100%;" v-model="formData.date" :picker-options="pickerOptions" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="">
        <el-button type="primary" @click='addORmodify' v-if="buttonShow">{{getType}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import uploadC from "../../common/v-upload.vue";
import { extend } from "../../../assets/js/util.js";
import { getCharge } from "../../../assets/js/API/charge.js";
import { getCourse, getCourseDetail } from "../../../assets/js/API/course.js";
import {
  REPLACEMENT_TYPE,
  PAY_TYPE,
} from "../../../assets/js/CONFIG/cost-lesson.js";
import {
  getCostLesson,
  addCostLesson,
  modifyCostLesson,
  getCostLessonInfo,
  deleteCostLesson,
  replaceCostLesson,
  computedRemainingAmount,
  upgradeCostLesson,
  settlement,
} from "../../../assets/js/API/cost-lesson.js";

import { getRole } from "../../../assets/js/common.js";

import vali from "../../../assets/js/validate.js";
const validatorCommon = vali.validatorCommon;
const MAP_TYPE = ["add", "modify", "forward", "upgrade"]; //modify- 修改 add- 添加 forward- 结转 upgrade- 升级
const FORWARD = "forward";
const UPGRADE = "upgrade";
const MODIFY = "modify";
const ADD = "add";
/**
    is_refund 1- 未退费 2- 已退费 3- 已转换套餐 4- 已升级套餐 5- 已转增
    pay_type 1- 全款 2- 定金 3- 转换套餐 4- 升级套餐 5- 转增套餐
  */
export default {
  name: "StudentCostLessonInfo",
  props: {
    form: {
      default: function () {
        return {};
      },
    },
    studentId: {
      default: "",
    },
    payer: {
      default: "",
    },
    course_id: {
      default: "",
    },
    propType: {
      default: "0",
    },
    buttonShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCanSubmit: true,
      type: "",
      surplus: {
        amount_payable: 0,
        cost_lesson: 0,
        dis_lesson: 0,
        lave_amount: 0,
        give_lesson: 0,
        consume: 0,
        course_classification_id: "",
        course_package_id: [],
      },
      tipsShow: false,
      cursor: {
        cursor_x: 0,
        cursor_y: 0,
      },
      help: false,
      labelTeacher: "选择教师",
      rules: {
        cost_lesson: validatorCommon
          .mandatory("请输入购买课时")
          .concat(validatorCommon.number),
        give_lesson: validatorCommon
          .mandatory("请输入赠送课时")
          .concat(validatorCommon.number),
        course_package_id: [
          {
            required: true,
            message: "请选择课包",
            trigger: "change",
          },
        ],
        course_classification_id: [
          {
            required: true,
            message: "请选择课程",
            trigger: "change",
          },
        ],
        reduction_amount: validatorCommon
          .mandatory("请输入减免金额")
          .concat(validatorCommon.price),
        discount: [
          {
            validator: (rule, value, callback) => {
              var reg = /^[\d]+$/;
              if (!reg.test(value))
                return callback(
                  new Error("折扣只能填0-99之间的整数,0代表不打折")
                );
              if (value < 0 || value > 99) {
                return callback(
                  new Error("折扣只能填0-99之间的整数,0代表不打折")
                );
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        earnest_money: [
          {
            validator: (rule, value, callback) => {
              /* 全款支付 结转套餐*/
              if (this.formData.pay_type == 1 || this.formData.pay_type == 3)
                return callback();
              /* 定金支付 */
              var reg = /^[\d]+(?:\.\d{1,2})?$/;
              if (!reg.test(value) && value <= 0)
                return callback(new Error("请输入大于0的数,小数位精确到2位,"));
              if (value > this.getAmountPayable - 0)
                return callback(
                  new Error("定金不能大于应付金额" + this.getAmountPayable)
                );
              return callback();
            },
          },
        ],
        class_time: validatorCommon.mandatory("请选择上课时间", "change"),
        // contract: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (value.length == 0) return callback("请上传合同");
        //       callback();
        //     },
        //     trigger: "change",
        //   },
        // ],
        is_first: [
          {
            validator: (rule, value, callback) => {
              if (value) return callback();
              else return callback("请选择成交课包类型");
              if (value.length == 0) return callback("请上传合同");
              callback();
            },
            trigger: "change",
          },
        ],
      },
      payMethodOptions: this.$param.payType,
      payTypeOptions: PAY_TYPE,
      replacementType: REPLACEMENT_TYPE,
      is_firstOptions: this.$param.re_type,
      recommenderOptions: [],
      courseOptions: [],
      courseValueOptions: [],
      formData: {
        is_first: "",
        cost_lesson: "",
        give_lesson: 0,
        lesson: "",
        course_package_id: "",
        course_classification_id: "",
        total_cost: "",
        reduction_amount: 0,
        discount: 0,
        paid_amount: "",
        pay_method: 1,
        remark: "",
        contract: [],
        payer: "",
        recommender: "",
        pay_type: 1,
        earnest_money: "",
        class_time: "",
        replace_type: "1",
        lave_lesson: "", // 剩余课时
        date: "",
      },
      // 设置课包禁用时间
      pickerOptions: {
        disabledDate(date) {
          let zero = new Date();
          var nowdays = new Date();
          var year = nowdays.getFullYear();
          var month = nowdays.getMonth();
          if (month == 0) {
            month = 12;
            year = year - 1;
          }
          if (month < 10) {
            month = "0" + month;
          }
          var myDate = new Date(year, month, 0);
          var startDate = new Date(year + "-" + month + "-01 00:00:00"); //上个月第一天
          if (date.getTime() > zero || date.getTime() < startDate) {
            return true;
          }
          return false;
        },
      },
    };
  },
  computed: {
    flagUse() {
      return this.formData.lesson != this.formData.lave_lesson;
    },
    getRole() {
      return getRole();
    },
    getType() {
      var map = {
        add: "添加",
        modify: "修改",
        upgrade: "升级",
        forward: "结转",
      };
      return map[this.type];
    },
    getLesson() {
      var sum = 0;
      var data = this.formData;
      if (this.type == UPGRADE)
        sum = this.surplus.give_lesson - this.surplus.consume;
      return Number(data.cost_lesson) + Number(data.give_lesson) + sum;
    },
    getAmountPayable() {
      var data = this.formData,
        discount = data.discount == 0 ? 1 : data.discount / 100;
      return (data.total_cost * discount - data.reduction_amount).toFixed(2);
    },
    getPaidAmount() {
      var val = this.type == FORWARD ? this.surplus.lave_amount : 0;
      if (this.type == UPGRADE) {
        var sum = this.surplus.amount_payable - 0;
        if (this.formData.pay_type == 2) {
          /* 定金支付 */
          if (isNaN(this.formData.earnest_money - 0)) return 0;
          return this.formData.earnest_money - 0 - val - sum;
        } else return this.getAmountPayable - val - sum;
      } else {
        if (this.formData.pay_type == 2) {
          /* 定金支付 */
          if (isNaN(this.formData.earnest_money - 0)) return 0;
          return this.formData.earnest_money - 0 - val;
        } else {
          console.log(this.getAmountPayable)
          console.log(val)
          return this.getAmountPayable - val;
        }
      }
    },
  },
  methods: {
    replacementTypeChange() {
      this.formData.discount = 0;
      this.formData.reduction_amount = 0;
      this.getTotalCost();
    },
    upgradeCostLesson(params) {
      params.id = this.course_id;
      params.old = this.surplus;
      params.lesson =
        params.lesson - this.surplus.give_lesson + (this.surplus.consume - 0);
      upgradeCostLesson.call(this, params).then((res) => {
        if (res) this.$emit("update:isGetCostLesson", true);
      });
    },
    settlement() {
      settlement.call(this, this.course_id).then((res) => {
        if (res) {
          extend(this.surplus, res);
          this.formData.course_classification_id = this.surplus.course_classification_id;
          this.type == UPGRADE && this.getCourseDetail();
        }
      });
    },
    forwardCostLesson() {
      var params = { ...this.formData };
      params.id = this.course_id;
      params.lave_amount = this.surplus.lave_amount;
      params.lesson = this.getLesson;
      params.paid_amount = this.getPaidAmount;
      params.amount_payable = this.getAmountPayable;
      /* (params.is_first == '' || params.recommender == '') && (params.is_first = 0, params.recommender = 0); */
      replaceCostLesson.call(this, params).then((res) => {
        if (res) this.$emit("update:isGetCostLesson", true);
      });
    },
    computedRemainingAmount() {
      computedRemainingAmount.call(this, this.course_id).then((res) => {
        if (res) extend(this.surplus, res);
      });
    },
    mouseenter(event) {
      this.cursor.cursor_x = event.pageX;
      this.cursor.cursor_y = event.pageY;
      this.tipsShow = true;
    },
    is_firstChange() {
      const name = ["选择教师", "试课老师", "主课老师"];
      this.labelTeacher =
        this.formData.is_first == "" ? name[0] : name[this.formData.is_first];
      this.formData.recommender = "";
      if (this.recommenderOptions.length > 0) return;
      this.getTeacher();
    },
    async getTeacher() {
      let username = window.sessionStorage.getItem("username");
      const { data: res } = await this.$http.get("/system/index/teacher");
      if (res.code == 200) {
        this.recommenderOptions = res.data.data;
      } else {
        this.$message.error("获取教师信息失败...");
      }
    },
    async addORmodify() {
      if (!this.isCanSubmit) return this.$message.info("正在处理中...");
      console.log(this.formData)
      var bool = await this.$refs.refForm.validate().catch((err) => { });
      if (!bool) return;
      this.isCanSubmit = false;
      var params = { ...this.formData };
      params.lesson = this.getLesson;
      params.paid_amount = this.getPaidAmount;
      params.amount_payable = this.getAmountPayable;
      if (!params.date) {
        delete params.date
      }
      /* (params.is_first == '' || params.recommender == '') && (params.is_first = 0, params.recommender = 0); */
      delete params.lave_lesson;
      if (this.getType != "结转") delete params.type;
      this[this.type + "CostLesson"](params);
    },
    addCostLesson(params) {
      addCostLesson
        .call(this, params)
        .then((res) => {
          this.isCanSubmit = true;
          this.$emit("update:isGetCostLesson", true);
        })
        .catch((err) => (this.isCanSubmit = true));
    },
    modifyCostLesson(params) {
      modifyCostLesson
        .call(this, params)
        .then((res) => {
          this.isCanSubmit = true;
          this.$emit("update:isGetCostLesson", true);
        })
        .catch((err) => (this.isCanSubmit = true));
    },
    getTotalCost() {
      var option = this.courseValueOptions,
        formData = this.formData;
      var index = option.findIndex((item) => {
        return item.id == formData.course_package_id;
      });
      if (index === -1) return;
      // 如果是结转套餐的更换课包，课包价格 = 结转金额
      if (this.formData.replace_type == 2)
        formData.total_cost = this.surplus.lave_amount;
      else formData.total_cost = option[index].value;
      formData.cost_lesson = option[index].lesson;
    },
    getCourseDetail() {
      this.courseValueOptions = [];
      this.formData.course_package_id = "";
      this.formData.total_cost = "";
      getCourseDetail
        .call(this, this.formData.course_classification_id)
        .then((res) => {
          if (res) {
            var lists = res.coursePackagePrice;
            if (this.type == UPGRADE) {
              lists = lists.filter((item) => {
                return this.surplus.course_package_id.some((item1) => {
                  return item1 == item.id;
                });
              });
            }
            this.courseValueOptions = lists;
          }
        })
        .catch((err) => { });
    },
    getCourse() {
      getCourse
        .call(this)
        .then((res) => {
          if (res) {
            this.courseOptions = res;
            this.type == UPGRADE && this.settlement();
          }
        })
        .catch((err) => console.log(err));
    },
  },
  components: {
    uploadC,
  },
  watch: {
    propType: function (val) {
      this.type = MAP_TYPE[val == undefined ? 0 : val];
    },
    form: function (val) {
      extend(this.formData, val);
      this.getCourseDetail();
      extend(this.formData, val);
      this.formData.is_first == 0 ? (this.formData.is_first = "") : "",
        this.formData.recommender == 0 ? (this.formData.recommender = "") : "";
      this.formData.recommender > 0 &&
        this.formData.is_first > 0 &&
        this.recommenderOptions.length == 0 &&
        this.getTeacher();
      this.formData.id = val.id;
    },
    studentId: function (val) {
      this.formData.sid = val;
      this.$forceUpdate();
    },
    payer: function (val) {
      this.formData.payer = val;
    },
  },
  created() {
    this.type = MAP_TYPE[this.propType == undefined ? 0 : this.propType];
    this.getCourse();
    this.formData.payer = this.$route.query.payer || this.payer;
    this.formData.sid = this.$route.query.id || this.studentId;
    this.type == FORWARD &&
      ((this.formData.pay_type = 3), this.computedRemainingAmount());
  },
  mounted() {
    this.$emit("upFileList", this.upFileList);
    this.$emit("resetFiled", this.$refs.refForm.resetFields);
    this.$emit("clearFileList", this.clearFileList);
    this.$emit("costLesson", this.addORmodify);
  },
};
</script>

<style scoped>
.help:active {
}

.surplus {
  padding: 10px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 500;
}
</style>
