<template>
<!-- 教务管理 班级管理  调整课时 -->
    <div>
        <el-card>
            <!-- 面包屑 -->
			<el-breadcrumb separator="/">
			  <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item>教务管理</el-breadcrumb-item>
			  <el-breadcrumb-item>班级管理</a></el-breadcrumb-item>
			  <el-breadcrumb-item>调整课时</el-breadcrumb-item>
			</el-breadcrumb>
            <!-- 面包屑/ -->
            <!-- 表格 -->
			<class-hour-table-c :class-id="id" :is-hightlight="true"></class-hour-table-c>
            <!-- 表格/ -->
            <!-- 返回按钮 -->
            <div class="text-align-c mg-t-10">
                <el-button type="primary" @click="$router.go(-1)"
                class="w-per-10"
                >返回</el-button>
            </div>
            <!-- 返回按钮/ -->
        </el-card>
    </div>
</template>
<script>
import classHourTableC from '../../common/iteration-classhour-table.vue' 
import vali from '../../../assets/js/validate'
export default {
	name: 'AdjustmentClassHour',
	components: {
	    classHourTableC,        
	},
    props: ['id'],
    data () {
        return {        
            key: '',
            id_modify: '',
            form: {
                date: '',
                time_start: '',
                time_end: ''
            },
            rules: {
                date: vali.validatorCommon.required_blur,
                time_start: vali.validatorCommon.required_blur,
                time_end: vali.validatorCommon.required_blur
            }
        }
    },
    methods: {
        // 增加课时 // 调整课时
        async addClassHours (id, method) {
            // var params = {
            //     date: '',
            //     time_start: '',
            //     time_end: ''
            // }
            // param && (Object.assign(params, param))
				const {data:res} = await this.$http[method](`/education/classTime/${id}`, this.form);
				if(res.code === 200){
					return res.data
                }
                return false           
        },
        click_confirm (key) {
            if (this.key === 'add') {
                this.$refs.form.validate( async (err) => {
                    if (!err) return
                    this.dialogVisible = false
                    if (await this.addClassHours(this.id, 'post')) { this.$message.success('添加课时成功'); this.upData() }
                })
            }
            else if (this.key === 'adjustment') {
                this.$refs.form.validate( async (err) => {
                    if (!err) return
                    this.dialogVisible = false
                    if (await this.addClassHours(this.id_modify, 'patch')) { this.$message.success('修改课时成功'); this.upData() }
                })                
            }

        }
    },
    watch: {
        'form.time_start': function (val) {
            this.form.time_end = ''
        }
    }   
}
</script>
<style scoped>

</style>
