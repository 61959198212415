<template>
	<!-- 部门级别管理 -->
	<div>
		<breadcrumb-c :value_breadcrumb='breadcrumb'></breadcrumb-c>
		<el-card>
			<!-- 部门导航 -->
			<div class="flex align-center flex-wrap">
				<div v-for="(item, index) in departmentNavigation" :key='index' class="navigate cursor-p" @click="toSomeDepartment(item)">
					{{item.name}}
				</div>
			</div>			
			<el-row class="mg-t-10">
				<el-col :span='3'>
					<el-input placeholder="请输入部门名称" size="medium" clearable v-model="departmentScreen.name" @change="screenChange"></el-input>
				</el-col>
				<el-col :span='3' :offset='1'>
					<el-select style="width: 100%;" v-model="departmentScreen.level" placeholder="请选择等级" @change="screenChange" size="medium" clearable>
						<el-option v-for="(item, i) in levelOptions" :label="item.label" :value="item.value" :key='item.value'></el-option>
					</el-select>
				</el-col>
				<el-col :span='3' :offset='1' v-show="departmentScreen.id == 1">
					<el-button type="primary" size="medium" @click="showDepartmentInfo('add')">添加一级部门</el-button>
				</el-col>
				<el-col :span='departmentScreen.id == 1 ? 13 : 17' class="text-align-r">
					<span style="color: rgb(84 122 160);font-weight: 700;">所属部门：{{getDepartmentName}}</span>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" class="mg-t-10">
				<el-table-column prop="name" label="部门名称" sortable>
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" sortable >
				</el-table-column>
				<el-table-column label="操作" width="300" fixed="right">
					<template v-slot='scope'>
						<el-button type="primary" size="small" @click='showDepartmentInfo("add", scope.row)'>添加</el-button>
						<el-button type="primary" size="small" @click='showDepartmentInfo("modify", scope.row)'>编辑</el-button>
						<el-button type="primary" size="small" @click='getDepartment(scope.row)'>查看</el-button>
						<el-button type="primary" size="small" @click='delDepartment(scope.row)'>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page="departmentScreen.page - 0" :total="total" layout="total, prev, pager, next" background @current-change='pageChange'></el-pagination>

			<el-dialog :visible.sync="showDialogDepartment" center title="添加部门" @close="dialogClose">
				<el-form label-position="right" label-width="80px" :rules='rules' ref='refFormDepartment' :model="department">
					<el-form-item label="部门名称" prop="name">
						<el-input placeholder="请输入级别名称" v-model="department.name"></el-input>
					</el-form-item>
					<el-form-item label="部门类型" prop="type">
						<el-select style="width: 100%;" v-model="department.type" placeholder="请选择部门类型" :disabled='isCanSelectDepartmentType' >
							<el-option v-for="(item, i) in levelTypeOption" :label="item.label" :value="item.value" :key='item.value'></el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="showDialogDepartment = false">取 消</el-button>
				    <el-button type="primary" @click="addORmodifyDepartment">确 定</el-button>
				  </span>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	import breadcrumbC from '../common/v-breadcrumb.vue'
	import {
		getDepartment,
		getDepartmentInfo,
		addDepartment,
		delDepartment,
		modifyDepartment
	} from '../../assets/js/API/department.js'
	import {
		LEVELTYPE,
		LEVEL
	} from '../../assets/js/CONFIG/department.js'
	import vali from '../../assets/js/validate.js'

	export default {
		name: 'Department',
		data() {
			return {
				levelTypeOption: LEVELTYPE,
				levelOptions: LEVEL,
				rules: {
					name: vali.validatorCommon.mandatory('请输入部门名称'),
					type: vali.validatorCommon.mandatory('请选择部门类型')
				},
				showDialogDepartment: false,
				total: 0,
				departmentScreen: {
					level: '',
					name: '',
					id: 1,
					page: 1
				},
				department: {
					name: '',
					schoole_id: sessionStorage.getItem('checkSchoole'),
					parent_id: 1,
					type: '',
					id: ''
				},
				tableData: [],
				breadcrumb: ['机构管理', '部门管理'],
				departmentNavigation: [],
				departmentNavigation_storage: [
					{
						id: 1,
						name: '总部',
						index: 0
					}
				]
			}
		},
		methods: {
			dialogClose () {
				this.$refs.refFormDepartment.resetFields();
				this.department.name = '';
				this.department.type = ''
			},
			toSomeDepartment (item) {
				this.reset();
				this.departmentScreen.id = item.id;
				this.department.parent_id = item.id;
				this.departmentNavigation.splice(item.index, this.departmentNavigation.length - item.index);
				this.departmentNavigation_storage.splice(item.index, this.departmentNavigation_storage.length - item.index);
				console.log(this.departmentNavigation, this.departmentNavigation_storage, item)
				if (this.departmentNavigation_storage.length == 0) {
					this.departmentNavigation_storage.push({ id: 1, name: '总部', index: 0 })
				}
				this.getDepartment()
			},
			reset () {
				this.departmentScreen.level = '';
				this.departmentScreen.name = '';
				this.departmentScreen.page = 1;
			},
			pageChange (page) {
				this.departmentScreen.page = page;
				this.getDepartment()
			},
			screenChange () {
				this.departmentScreen.page = 1;
				this.getDepartment()
			},
			showDepartmentInfo (key, row) {
				if (row) {
					this.department.type = row.schoole_id == 0 ? 2 : 1;
					this.department.parent_id = row.id;					
					if (key == 'modify') {
						this.department.id = row.id;
						this.department.name = row.name
					}
				} else {}				
				this.showDialogDepartment = true;
				this.addORmodify = key;				
			},
			delDepartment (row) {
				this.$confirm('您确定要删除这个部门吗?', '提示')
					.then( res => {
						delDepartment.call(this, { id: row.id })
									 .then( res => {
										 this.getDepartment()
									 })
									 .catch( err => {})
					})
					.catch( err => console.log(err) )
			},
			async addORmodifyDepartment () {
				var bool = await this.$refs.refFormDepartment.validate().catch( err => {})
				if (!bool) return;
				if (this.addORmodify == 'add') {
					addDepartment.call(this, this.department)
								 .then( res => {
									 this.showDialogDepartment = false;
									 this.getDepartment()
								 })
								 .catch( err => console.log(err))
				} else if (this.addORmodify == 'modify') {
					modifyDepartment.call(this, this.department)
									.then( res => {
										this.showDialogDepartment = false;
										this.getDepartment()
									})
									.catch( err => { console.log(err)})
				}
			},
			getDepartment(row) {
				if (row != undefined) {
					this.departmentScreen.id = row.id;
					this.departmentNavigation_storage.push({ id: row.id, name: row.name, index: this.departmentNavigation_storage.length });
					this.departmentNavigation = this.departmentNavigation_storage.slice(0, this.departmentNavigation_storage.length - 1)
				}
				getDepartment.call(this, this.departmentScreen)
					.then(res => {
						this.tableData = res.data;
						this.total = res.total;
						this.departmentScreen.page = res.page;
						console.log(res)
					})
					.catch(err => console.log(err))
			}
		},
		created() {
			this.getDepartment();
		},
		computed: {
			isCanSelectDepartmentType () {
				if (this.addORmodify == 'modify' || this.department.parent_id != 1) return true
				return false
			},
			getDepartmentName () {
				return this.departmentNavigation_storage[this.departmentNavigation_storage.length - 1].name
			}
		},
		components: {
			breadcrumbC
		}
	}
</script>

<style scoped>
	.navigate{
		font-size: 12px;
		color: #fff;
		background-color: #3e9bf7;
		border-radius: 2px;
		padding: 3px 5px;
		margin-right: 10px;
	}
</style>
