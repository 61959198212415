import Vue from 'vue'
import {
	Button
} from 'element-ui'
import {
	Form,
	FormItem,
	Input,
	Message,
	Container,
	Aside,
	Header,
	Main,
	Row,
	Col,
	Divider,
	Dialog,
	Menu,
	Submenu,
	MenuItemGroup,
	MenuItem,
	Breadcrumb,
	BreadcrumbItem,
	Card,
	Tree,
	Icon,
	Table,
	TableColumn,
	Tooltip,
	MessageBox,
	Popover,
	Select,
	Option,
	DatePicker,
	Tabs,
	TabPane,
	Alert,
	Steps,
	Step,
	Upload,
	RadioGroup,
	Radio,
	ButtonGroup,
	Switch,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	Checkbox,
	Progress,
	Calendar,
	Collapse,
	CollapseItem,
	Pagination,
	Scrollbar,
	OptionGroup,
	RadioButton,
	Tag,
	CascaderPanel,
	Cascader,
	Drawer,
	TimelineItem,
	Timeline,
	Badge,
	TimeSelect,
	CheckboxButton,
	CheckboxGroup,
	Image,
	TimePicker,
	Popconfirm,
	Loading,
} from 'element-ui'
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(Dialog)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Tree)
Vue.use(Icon)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tooltip)
/* 下拉Tree */
Vue.use(Popover)
Vue.use(Select)
Vue.use(Option)
/* 下拉Tree */
Vue.use(DatePicker)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Alert)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Upload)
Vue.use(Tag)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(ButtonGroup)
Vue.use(Switch)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Checkbox)
Vue.use(Progress)
Vue.use(Calendar)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Pagination)
Vue.use(Scrollbar)
Vue.use(OptionGroup);
Vue.use(RadioButton);
Vue.use(CascaderPanel);
Vue.use(Cascader);
Vue.use(Drawer);
Vue.use(TimelineItem);
Vue.use(Timeline);
Vue.use(Badge);
Vue.use(TimeSelect);
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Image)
Vue.use(TimePicker)
Vue.use(Popconfirm)
Vue.use(Loading)
/* 消息组件 */
var msgBox = MessageBox
Vue.prototype.$confirm = msgBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$prompt = msgBox.prompt
