<template>
	<div>
		<el-breadcrumb separator="/" class="fs-12">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>学员管理</el-breadcrumb-item>
			<el-breadcrumb-item>学员档案</el-breadcrumb-item>
		</el-breadcrumb>
		<student-c class="mg-t-10"></student-c>
	</div>
</template>
<script>
	import studentC from '../common/iteration-student-table.vue'
	export default {
		name: 'StudentFile',
		data () {
			return {}
		},
		components: {
			studentC
		}
	}
</script>
<style scoped>
	
</style>