<template>
    <div>
        <line-charts ref="LineCharts" :lineId="lineId" :option="flowingOption" :lineStyle="{height:'300px'}"/>
    </div>
</template>
<script>
import LineCharts from "./LineCharts"
export default {
    props:{
        lineId:{
            type:String,
            default:'line'
        },
        monthArr:{
            type:Array,
            default:()=>{
                return []
            }
        },
        campusId:{
            type:[String,Number,Array],
            default:0
        },
        organization:{
            type:Array,
            default:()=>{
                return []
            }
        },
        dataItem:{
            type:Object
        },
        loading:{
            type:Boolean,
            default:false
        }
    },
    components:{
        LineCharts
    },
    data(){
        return{
            flowingOption:{},
            campusOptions:[],
        }
    },
    watch:{
        monthArr(news,old){
            this.init(news)
        },
        campusId(news,old){
            if(news!=old||news==0) this.initCampus()
        },
        organization(news,old){
            if(news&&news.length){
                this.initCampus()
            }
        },
        loading(news,old){
            if(!news) this.initCampus()
        }
    },
    created(){
        this.initCampus()
    },
    methods: {
        // 校区数组
        initCampus(){
            let options = []
            for(let item of this.organization){
                if(Array.isArray(this.campusId)){
                    for(let t of this.campusId){
                        if(t==item.id){
                            options.push(item.name)    
                        }
                    }
                }else if(this.campusId==item.id){
                    options.push(item.name)
                    break
                }
            }
            this.campusOptions = options
            this.init(this.monthArr)
        },
        // 初始化图表
        init(monthArr){
            let color = ['#9dd3e8', '#ee6666', '#fac858', '#95cd76', '#5470c6']
            let series = []

            let dataItem = this.dataItem
            let bool = this.campusOptions.length==1
            for(let i=0;i<this.campusOptions.length;i++){
                series.push(
                    {
                        name: this.campusOptions[i],
                        type: 'line',
                        // stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            color:color[i]
                        },
                        areaStyle: {
                            opacity:0,
                            color:color[i]
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: dataItem.dataShow? dataItem.datas[this.campusId[i]]:dataItem.data
                    }
                )
            }
            if(!series.length&&!this.campusOptions.length) return
            this.flowingOption = {
                    title: {
                        text: ''
                    },
                    color: color,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                        }
                    },
                    legend: {
                        show:!bool,
                        data: this.campusOptions||['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
                    },
                    toolbox: {
                        feature: {
                        saveAsImage: {}
                        }
                    },
                    grid: {
                        left: '4%',
                        right: '6%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                        type: 'category',
                        boundaryGap: false,
                        data: monthArr||['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value'
                        }
                    ],
                    series:series
                }
                this.$nextTick(()=>{
                    this.$refs.LineCharts.init(this.flowingOption)
                })
        },
    },
}
</script>