/* ------------------------------教师API---------------------------- */
export async function changeTeacherStatus (params) {
	const {data:res} = await this.$http.patch('/system/account/updateTeacherStatus', params);
	if(res.code == 200){
		this.$message.success('修改成功');
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

export async function getTeacher (username, params) {
	const { data: res } = await this.$http.get('getUserByUserName/' + username, {
		params
	});
	if(res.code == 200){
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

export async function getTeacher_class (username, page) {
	const { data: res } = await this.$http.get('/system/account/getUser');
	if(res.code == 200){
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

export async function getRole (username, page) {
	const { data: res } = await this.$http.get('/system/account/getRoles');
	if(res.code == 200){
		return res.data		
	} else this.$message.error(res.msg || res.message)
}


export async function modifyLevel (id, params) {
	const { data: res } = await this.$http.patch('/system/account/updateLevel/' + id, params);
	if(res.code == 200){
		this.$message.success('修改星级成功');		
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

export async function addTeacher (params) {
	const { data: res } = await this.$http.post('/system/account/addUser', params);
	if(res.code == 200){
		this.$message.success('添加成功');
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

export async function modifyRole (params) {
	const { data: res } = await this.$http.patch('/system/account/updateRoleByTeacher', params);
	if(res.code == 200){
		this.$message.success('修改权限成功');
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

/**
 * @description 修改密码
 * @param {Object} params
 */
export async function modifyPassword (params) {
	const { data: res } = await this.$http.post('/system/account/updatePassword', params);
	if(res.code == 200){
		this.$message.success('修改成功');
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

export async function modifyTeacher (params) {
	const { data: res } = await this.$http.post('/system/account/updateUserInfo', params);
	if(res.code == 200){
		this.$message.success('修改成功');
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

/**
 * @description  删除教师 已废弃
 * @param {Object} id
 */
export async function delTeacher (id) {
	const { data: res } = await this.$http.get('/system/account/delUser/' + id);
	if(res.code == 200){
		this.$message.success("删除成功");
		return res.data		
	} else this.$message.error(res.msg || res.message)
}

export async function getToken () {
	const { data: res } = await this.$http.get('/common/qiniuToken')
	if(res.code == 200){		
		return res.data		
	} else this.$message.error(res.msg || res.message)
}