<template>
  <!-- 学员信息表格 -->
  <div class="student-table">
    <!-- <div class="header mg-t-10" v-if="operation">
			<el-button type="primary" size="small" @click='$router.push("/student/form/studentRegister")'>添加学员</el-button>
		</div> -->
    <div class="mg-t-10 mg-b-10" v-if="operation && selectTab != 'leaveStudent'">
      <div class="flex align-center">
        <div style="width: 11%;;padding-right: 5px;">
          <el-input type="text" style="" v-model="screen.key" size="small" placeholder="学员名称" @blur="screenChange('key')">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="screenChange('key')" clearable></i>
          </el-input>
        </div>
        <div style="width: 11%;padding: 0 5px;">
          <el-select v-model="screen.sex" size="small" style="width: 100%;" placeholder="性别" clearable @change="screenChange('sex')">
            <el-option v-for="(item, index) in sexOptions" :label="item.name" :value="item.val" :key='item.val'></el-option>
          </el-select>
        </div>
        <div style="width: 11%;padding: 0 5px;">
          <el-select v-model="screen.class_status" size="small" style="width: 100%;" placeholder="班级状态" clearable @change="screenChange('class_status')">
            <el-option v-for="(item, index) in classStatusOptions" :label="item.name" :value="item.val" :key='item.val'></el-option>
          </el-select>
        </div>
        <div style="width: 11%;padding: 0 5px">
          <el-select v-model="screen.parent_status" size="small" style="width: 100%;" placeholder="家长端状态" clearable @change="screenChange('parent_status')">
            <el-option v-for="(item, index) in parentStatusOptions" :label="item.name" :value="item.val" :key='item.val'></el-option>
          </el-select>
        </div>
        <div style="width: 11%;padding: 0 5px;">
          <el-select v-model="screen.source" size="small" style="width: 100%;" placeholder="学员来源" clearable @change="screenChange('source')">
            <el-option v-for="(item, index) in studentSourceOptions" :label="item.name" :value="item.val" :key='item.val'></el-option>
          </el-select>
        </div>
        <div style="width: 20%;padding: 0 5px;">
          <div class="flex align-center">
            <el-input placeholder="最小年龄" v-model="screen.age_start" size="small" />
            <span class="mg-l-10"> - </span>
            <el-input placeholder="最大年龄" v-model="screen.age_end" size='small' class="mg-l-10" />
            <el-button type="primary" size="small" style="margin-left: 10px;" @click='checkAGE'>确定</el-button>
          </div>
        </div>
        <div style="width: 25%;padding-left: 5px;">
          <el-date-picker @change="screenChange('created_at')" style="width: 100%;" size="small" v-model="screen.created_at" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </div>
      <div class="flex align-center mg-t-10">
        <div style="width: 11%;;padding-right: 5px;">
          <el-select v-model="screen.state" size="small" style="width: 100%;" placeholder="学生状态" clearable @change="screenChange('state')">
            <el-option v-for="(item, index) in studentStateOptions" :label="item.name" :value="item.id" :key='item.id'></el-option>
          </el-select>
        </div>
        <div style="width: 11%;;padding-right: 5px;">
          <el-select v-model="screen.pay_type" size="small" style="width: 100%;" placeholder="缴费方式" clearable @change="screenChange('pay_type')">
            <el-option v-for="(item, index) in payTypeOptions" :label="item.name" :value="item.id" :key='item.id'></el-option>
          </el-select>
        </div>
        <div style="padding: 0 5px;">
          <span class="fs-main mg-r-10">剩余课时排序</span>
          <el-radio-group v-model="screen.lave_lesson_rank" size="mini" @change="screenChange('lave_lesson_rank')">
            <el-radio-button label="">不限</el-radio-button>
            <el-radio-button label="2">正序</el-radio-button>
            <el-radio-button label="1">倒叙</el-radio-button>
          </el-radio-group>
        </div>
        <div style="width: 10%;;padding: 0 5px;">
          <el-button type="primary" size="mini" icon="el-icon-download" @click='exportEvent'>导出</el-button>
        </div>
      </div>
    </div>
    <div class="tabs mg-t-10" v-if="operation">
      <div class="flex tabsHeader fs-main">
        <div class='tabsItem fs-main' style='border-top-left-radius: 5px;' :class="{selectTabs: selectTab == 'AllStudent'}" @click="typeChange('AllStudent')">全部学员</div>
        <div class='tabsItem' style='' :class="{selectTabs: selectTab == 'conventionalStudent'}" @click="typeChange('conventionalStudent')">在读学员</div>
        <!-- <div class='tabsItem' style='' :class="{selectTabs: selectTab == 'conventionStudent'}" @click="typeChange('conventionStudent')">预约学员</div> -->
        <div class='tabsItem' style='border-top-right-radius: 5px;' :class="{selectTabs: selectTab == 'leaveStudent'}" @click="typeChange('leaveStudent')">流失学员</div>
        <div class="flex-1" style="border-bottom: 1px solid #dcdfe6;"></div>
      </div>
    </div>
    <el-table :data="studentLists" ref="refTable" @current-change='current_change($event)' row-key="id" class="fsMedia" :highlight-current-row='isHightlight' :row-class-name="tableRowClassName">
      <el-table-column type="expand" v-if="selectTab != 'leaveStudent'">
        <template v-slot="scope">
          <div class="boxExpand">
            <div v-for="(item, index) in scope.row.cost_lesson_record" class="boxCostLesson" v-if="item.is_refund == 1">
              <div class='flex justify-between' style="align-items: flex-end;">
                <div style="font-size: 16px;font-weight: bold;">{{item.course_classification_name}}/{{item.course_package_name}}</div>
              </div>
              <div class="w-per-100" style="padding: 0 2px;margin-top: 5px;">
                <div class="flex align-center justify-between" style="margin-top: 2px;">
                  <div>
                    <span style="">其它赠送课时：</span>
                    <span>{{item.other_lesson}}</span>
                  </div>
                  <div>
                    <span style="">支付金额：</span>
                    <span>{{item.paid_amount}}</span>
                  </div>
                </div>
                <div class="flex align-center justify-between" style="margin-top: 2px;">
                  <div>
                    <span style="">赠送课时：</span>
                    <span>{{item.give_lesson}}</span>
                  </div>
                  <div>
                    <span style="">总课时：</span>
                    <span>{{item.lesson}}</span>
                  </div>
                </div>
                <div class="flex align-center justify-between" style="margin-top: 2px;">
                  <div>
                    <span style="">购买课时：</span>
                    <span>{{item.cost_lesson}}</span>
                  </div>
                  <div>
                    <span style="">剩余课时：</span>
                    <span>{{item.lave_lesson}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="学员ID" align="center" prop="id"></el-table-column>
      <el-table-column show-overflow-tooltip align="center" label="姓名">
        <template slot-scope="scope">
          <span class="cursor-p c-409eff" @click="$router.push('/student/studentFileSingle?id=' + scope.row.id + '&payer=' + scope.row.guardian + '&name=' + scope.row.name)">{{scope.row.name}} </span>
        </template>
      </el-table-column>
      <el-table-column prop="sex" label="性别" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{scope.row.sex == 1 ? '男' : '女'}}
        </template>
      </el-table-column>
      <el-table-column prop='class_status' label="分班状态" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{scope.row.class_status == 0 ? '未分班' : '已分班'}}
        </template>
      </el-table-column>
      <el-table-column label="剩余课时" show-overflow-tooltip align="center" prop="lave_lesson"></el-table-column>
      <el-table-column label="联系电话" show-overflow-tooltip align="center" prop="tel">
      </el-table-column>
      <el-table-column label="家长端状态" show-overflow-tooltip prop="is_activation" align="center">
        <template slot-scope="scope">
          {{scope.row.is_activation != null ? scope.row.is_activation == 1 ? '已激活' : '未激活' : '暂无监护人'}}
        </template>
      </el-table-column>
      <el-table-column label="学生状态" show-overflow-tooltip :width="operation ? '120px' : '100px'" prop="is_leave" align="center">
        <template slot-scope="scope">
          <el-select v-if="operation" size="small" v-model="scope.row.state" @change="updateStudentState(scope.row)">
            <el-option v-for="item in studentStateOptions" :key='item.id' :label="item.name" :value="item.id"></el-option>
          </el-select>
          <span v-else>{{studentStateOptions[scope.row.state - 0] ? studentStateOptions[scope.row.state - 0].name : '未知'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="登记时间" align='center' min-width="120" show-overflow-tooltip prop="created_at"></el-table-column>
      <el-table-column label="操作" align='center' :min-width="selectTab == 'leaveStudent' ? '150px' : '370px'" v-if="operation">
        <template v-slot="scope">
          <div v-if="selectTab == 'leaveStudent'">
            <el-button size="small" type="primary" @click="followUpStudent(scope.row)">重新跟进</el-button>
          </div>
          <div v-else>
            <el-button size="small" type="primary" @click="costLesson(scope.row)">购买课包</el-button>
            <el-button size="small" type="primary" @click="changeLeaveStatus(scope.row)">变更状态</el-button>
            <el-button size="small" type="primary" v-if='scope.row.cost_lesson_record && scope.row.cost_lesson_record.length > 0' @click="$router.push('/student/classHoursInfo?sid=' + scope.row.id)">赠送课时</el-button>
            <el-button size="small" type="danger" v-if='scope.row.cost_lesson_record && scope.row.cost_lesson_record.length > 0' @click="refund(scope.row)">退费</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="screen.page" layout="total, prev, pager, next" background class="mg-t-10" @current-change='pageChange'></el-pagination>
    <!-- 购买课包 -->
    <el-dialog title="购买课包" :visible.sync="costLessonDialogVisible" width="90%" @close='costLessonDialogClose'>
      <cost-lesson-c buttonText='保存' :diabled='diabled' :student-id='sid' :payer='guardian' :isGetCostLesson.sync='isGetStudent' @resetFiled='costLessonResetFields = $event' @clearFileList='clearFileList = $event'></cost-lesson-c>
    </el-dialog>
    <!-- 退费 -->
    <el-dialog :visible.sync="refundDialogVisible" width="90%" center v-if="refundDialogVisible">
      <div slot='title' style="">{{studentName}}<span class="mg-l-10">退费操作</span></div>
      <refund-c :studentId='sid' @refundSuccess='refundSuccess'></refund-c>
    </el-dialog>
    <!-- 变更状态 -->
    <el-dialog title="学员状态变更" :visible.sync="leaveDialogVisible" @close="LeaveDialogClose" width="30%">
      <label>请选择流失标签</label>
      <div class="tags-container">
        <el-tag v-for="(tag,index) in labelList" :key="tag.id" type="info" effect="plain" closable :class="isactive == index ? 'changeColor' : '' " @close="delLeabel(tag)" @click="chooseLeabel(index,tag)">
          {{tag.name}}
        </el-tag>
        <el-button size="small" @click='addLeaveDialogVisible = true'>+ 添加标签</el-button>
      </div>
      <label>请说明具体流失的原因</label>
      <div>
        <el-input type="textarea" v-model="leaveForm.des"></el-input>
      </div>
      <!-- 内容底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="leaveDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editStudentLeaveConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 变更状态/ -->
    <!-- 添加标签 -->
    <el-dialog title="添加标签" :visible.sync="addLeaveDialogVisible" @close="addLeaveDialogVisible = false" width="30%">
      <el-input type="text" style="margin-top: 10px;" v-model="label"></el-input>
      <!-- 内容底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addLeaveDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addLeaveLabel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加标签/ -->

    <loading-c :show-loading='loading'></loading-c>
  </div>
</template>

<script>
import costLessonC from "../student/form/costLessonBaseInfo.vue";
import refundC from "../student/form/studentRefund.vue";
import loadingC from "./loading.vue";
import { CLASSSTATUS, PARENTSTATUS } from "../../assets/js/CONFIG/class.js";
import {exportUtils} from "../../assets/js/util"
import {
  SEX,
  STUDENTSOURCE,
  STUDENT_STATE,
} from "../../assets/js/CONFIG/student.js";
import {
  getStudent,
  getLeavelStudent,
  followUpStudent,
  getLeaveLabel,
  addLeaveLabel,
  delLeaveLabel,
  editStudentLeaveConfirm,
  updateStudentState,
} from "../../assets/js/API/student.js";
import { PAY_TYPE } from "../../assets/js/CONFIG/cost-lesson.js";
import { extend } from "../../assets/js/util.js";
const TABMAP = [
  "AllStudent",
  "conventionalStudent",
  "conventionStudent",
  "leaveStudent",
];
export default {
  name: "StudentTable",
  components: {
    costLessonC,
    refundC,
    loadingC,
  },
  props: {
    isHightlight: {
      type: Boolean,
      default: false,
    },
    selectRowIndex: {
      default: -1,
    },
    operation: {
      type: Boolean,
      default: true,
    },
    tab: {
      default: "", //学员类型 //AllStudent-全部学员  conventionalStudent- 在读学员 conventionStudent-预约学员 leaveStudent- 流失学员
    },
  },
  data() {
    return {
      loading: false,

      isCanHttp: true,
      studentStateOptions: STUDENT_STATE,
      label: "",
      addLeaveDialogVisible: false,
      leaveForm: {
        id: "",
        label: "",
        des: "",
      },
      isactive: "",
      labelList: [],
      leaveDialogVisible: false,
      studentName: "",
      refundDialogVisible: false,
      isGetStudent: false,
      sid: "",
      guardian: "", //家长ID
      diabled: true,
      selectTab: "conventionalStudent",
      studentLists: [],
      sexOptions: SEX,
      classStatusOptions: CLASSSTATUS,
      parentStatusOptions: PARENTSTATUS,
      studentSourceOptions: STUDENTSOURCE,
      payTypeOptions: PAY_TYPE,
      screen: {
        key: "",
        type: 1,
        age_start: 1,
        age_end: 999,
        page: 1,
        state: "",
        pay_type: "",
        lave_lesson_rank: "",
        schoole_id:sessionStorage.getItem('checkSchoole')
      },
      page: 1,
      total: 0,
      rowIndex: -1,
      costLessonDialogVisible: false,
    };
  },
  methods: {
    resetHttpStatus() {
      setTimeout(() => (this.isCanHttp = true), 500);
    },
    updateStudentState(info) {
      if (!this.isCanHttp) {
        info.state = info.state == 1 ? 2 : 1;
        return this.$message.info("请稍等一会...");
      }
      this.isCanHttp = false;
      updateStudentState
        .call(this, { sid: info.id, state: info.state })
        .then((res) => {
          this.resetHttpStatus();
          if (!res) info.state = info.state == 1 ? 2 : 1;
        })
        .catch((err) => {
          this.resetHttpStatus();
          info.state = info.state == 1 ? 2 : 1;
        });
    },
    getLeaveLabel() {
      getLeaveLabel
        .call(this)
        .then((res) => {
          if (res) {
            this.labelList = res;
            this.leaveDialogVisible = true;
          }
        })
        .catch((err) => console.log(err));
    },
    changeLeaveStatus(row) {
      this.leaveForm.id = row.id;
      this.getLeaveLabel();
    },
    addLeaveLabel() {
      addLeaveLabel
        .call(this, { name: this.label })
        .then((res) => {
          if (res) {
            this.label = "";
            this.getLeaveLabel();
            this.addLeaveDialogVisible = false;
          }
        })
        .catch((err) => console.log(err));
    },
    editStudentLeaveConfirm() {
      if (this.leaveForm.label == "")
        return this.$message.error("请选择学员流失标签");
      editStudentLeaveConfirm
        .call(this, this.leaveForm.id, {
          leaveId: this.leaveForm.label,
          leaveReason: this.leaveForm.des,
        })
        .then((res) => {
          if (res) {
            this.getStudentData();
            this.leaveDialogVisible = false;
            this.isactive = -1;
            this.leaveForm.id = "";
            this.leaveForm.label = "";
            this.leaveForm.des = "";
          }
        })
        .catch((err) => console.log(err));
    },
    chooseLeabel(index, tag) {
      this.isactive = index;
      this.leaveForm.label = tag.id;
    },
    /* 刪除标签 */
    async delLeabel(tag) {
      const result = await this.$confirm("是否删除该标签", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result == "confirm") {
        const { data: res } = await this.$http.delete(
          "/student/info/label/" + tag.id
        );
        if (res.code == 200) {
          this.getLeaveLabel();
          this.$message.success("删除标签成功");
        }
      }
    },
    LeaveDialogClose() {
      this.leaveDialogVisible = false;
      this.isactive = -1;
      this.leaveForm.id = "";
      this.leaveForm.label = "";
      this.leaveForm.des = "";
    },
    refund(row) {
      this.refundDialogVisible = true;
      this.studentName = row.name;
      this.sid = row.id;
    },
    refundSuccess(bool) {
      this.refundDialogVisible = bool;
      this.getStudentData();
    },
    costLesson(row) {
      this.sid = row.id;
      this.guardian = row.guardian;
      this.costLessonDialogVisible = true;
      this.diabled = true;
    },
    costLessonDialogClose() {
      this.costLessonResetFields();
      this.clearFileList();
      this.diabled = false;
    },

    followUpStudent(row) {
      this.$confirm("是否恢复该学员为正常", "提示")
        .then(() => {
          followUpStudent
            .call(this, row.id)
            .then((res) => {
              if (res) this.getStudentData();
            })
            .catch((err) => conosle.log(err));
        })
        .catch();
    },
    getStudentData() {
      if (this.selectTab == "leaveStudent") this.getLeavelStudent();
      else this.getStudent();
    },
    getLeavelStudent() {
      this.loading = true;
      getLeavelStudent
        .call(this, 1)
        .then((res) => {
          this.loading = false;
          if (res) this.studentLists = res.data;
        })
        .catch((err) => (this.loading = false));
    },
    getStudent() {
      var params = { ...this.screen };
      params.created_at &&
        params.created_at.length > 0 &&
        ((params.created_at_start = params.created_at[0]),
          (params.created_at_end = params.created_at[1]));
      this.loading = true;
      getStudent
        .call(this, params)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.studentLists = res.data;
            this.total = res.total;
          }
        })
        .catch((err) => (this.loading = false));
    },
    screenChange(key) {
      this.screen.page = 1;
      var map = new Map();
      map.set(key, this.screen[key]);
      map.set("page", 1);
      var outArray = Array.from(map);
      var obj = {};
      for (var [key, value] of map.entries()) {
        obj[key] = value;
      }
      map = null;
      obj = null;
      this.getStudentData();
    },
    sortStudent() {
      //有问题
      return;
      this.screen.sort = 1;
      this.page = 1;
      this.getStudentData();
    },
    typeChange(key) {
      if (this.selectTab == key) return;
      this.screen.page = 1;
      this.selectTab = key;
      this.screen.type =
        key == "AllStudent" || key == "leaveStudent"
          ? ""
          : key == "conventionalStudent"
            ? 1
            : 2;
      this.getStudentData();
    },
    checkAGE() {
      var reg = /^[\d]+$/;
      if (
        reg.test(this.screen.age_start) &&
        reg.test(this.screen.age_end) &&
        this.screen.age_start - 0 < this.screen.age_end - 0
      ) {
        this.screen.page = 1;
        this.getStudentData();
      } else this.$message.error("请检查年龄范围输入");
    },
    pageChange(page) {
      this.screen.page = page;
      this.getStudentData();
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.rowIndex == rowIndex) return "hight-light-row";
      return "";
    },
    current_change($event) {
      this.rowIndex = -1;
      this.$emit("current_change", $event);
    },
    returnIndex() {
      this.rowIndex = this.classLists.findIndex((item) => {
        return item.id == this.selectRowIndex;
      });
    },
    exportEvent(){
      var params = { ...this.screen };
      params.created_at && params.created_at.length > 0 && ((params.created_at_start = params.created_at[0]), (params.created_at_end = params.created_at[1]));
      params['is_export'] = 1
      exportUtils(params,'/admin/v1/student/info')
    },
  },
  created() {
    var { tab } = this.$options.propsData;
    TABMAP.indexOf(tab) != -1 &&
      ((this.screen.type =
        tab == "AllStudent" || tab == "leaveStudent"
          ? ""
          : tab == "conventionalStudent"
            ? 1
            : 2),
        (this.selectTab = tab));
    this.getStudentData();
  },
  watch: {
    selectRowIndex: function (val) {
      this.$refs.refTable.setCurrentRow();
      this.returnIndex();
    },
    isGetStudent: function (val) {
      if (!val) return;
      this.costLessonDialogVisible = false;
      this.getStudentData();
      this.$nextTick(() => {
        this.isGetStudent = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tabs {
  /* box-shadow: 0 2px 4px rgba(0,0,0,.12),0 0 6px 0 rgba(0,0,0,.04); */
}
.tabsHeader {
  /* background-color: #f5f7fa; */
  height: 40px;
  line-height: 40px;
  /* border: 1px solid #dcdfe6; */
}
.tabsItem {
  cursor: pointer;
  padding: 0 20px;
  color: #909399;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid #dcdfe6;
}
.selectTabs {
  /* border: 1px solid transparent; 
		box-sizing: border-box; */
  transition: all 0.3s;
  color: #409eff !important;
  background-color: #fff;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
  border-bottom-color: rgba(0, 0, 0, 0);
}
.tag {
  margin: 0 10px 5px 0;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(209, 209, 209, 0.274);
  margin: 10px 0;
}
.classTypeImgSize {
  vertical-align: middle;
  width: 40%;
}
.boxExpand {
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 999;
}
.boxCostLesson {
  background-color: #fff;
  display: inline-block;
  margin-right: 10px;
  padding: 10px;
  border-radius: 2px;
  width: 500px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14);
}
.tags-container {
  margin-top: 5px;
  margin-bottom: 20px;

  > .el-tag {
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 10px;
  }

  > .el-tag:hover {
    background-color: #ffecec;
  }
}

.changeColor {
  background-color: #81d3f8 !important;
  color: #ffffff !important;
}
</style>
