<template>
  <!-- 课包信息表格 -->
  <div>
    <div class="header mg-t-10" v-if="operation">
      <el-button type="primary" @click="showCourseInfo('add')" size="small">新增课程包</el-button>
    </div>
    <el-table :data="courseList" ref="refTable" @current-change='current_change($event)' row-key="id" class="fsMedia" :highlight-current-row='isHightlight' :row-class-name="tableRowClassName">
      <el-table-column prop="name" align="center" show-overflow-tooltip label="课程包名称" min-width="80"></el-table-column>
      <el-table-column prop="type" align="center" show-overflow-tooltip label="课程类型">
        <template v-slot='scope'>
          {{courseType[scope.row.type - 1] ? courseType[scope.row.type - 1].name : '未知' }}
        </template>
      </el-table-column>
      <el-table-column prop="unit_price" label="课程单价(/元)" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="适龄范围" show-overflow-tooltip width="100px" align="center">
        <template v-slot="scope">
          {{scope.row.age_start}}-{{scope.row.age_end}}岁
        </template>
      </el-table-column>
      <el-table-column label="课包分类" show-overflow-tooltip min-width="150">
        <template slot-scope="scope">
          <el-tag size="small" v-for="(item, index) in scope.row.course_package_price" :key="index" class="mg-r-10">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="100px" v-if="operation">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="showCourseInfo('modify', scope.row)">查看</el-button>
          <!-- <el-button size="small" type="primary" @click='delCourse(scope.row)'>删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title='getDialogTitle' :visible.sync="courseDialogShow" class="dialogForm" @close="dialogClose" center>
      <div class="formContainer">
        <el-form class="formByFee" :model="formList" ref="refFormCourse" :rules='rules' label-position="right" label-width="80px">
          <el-card>
            <el-divider content-position="left">课程包</el-divider>
            <div class="card-body">
              <el-form-item label="课程类型" prop="type">
                <el-select v-model="formList.type" placeholder="请选择课程类型" style="width: 100%;" @change="courseTypeChange" :disabled="addORmodify == 'modify'">
                  <el-option v-for="(item, index) in courseType" :key="'type' + index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="课程名称" prop="name" v-show="formList.type">
                <el-select v-model="formList.name" placeholder="请选择课包名称" style="width: 100%;" v-if="formList.type == 1" @change="courseNameChange" :disabled="addORmodify == 'modify'">
                  <el-option v-for="(item, index) in courseNames" :key="'courseName' + index" :label="item.name" :value="item.name"></el-option>
                </el-select>
                <el-input type="text" placeholder="请输入课程名称" v-model="formList.name" v-else></el-input>
              </el-form-item>
              <el-form-item label="课程单价" prop="unit_price">
                <el-input type="text" placeholder="请输入课程单价" v-model="formList.unit_price" @blur="getPriceValue()">
                  <template slot="append">元/课时</template>
                </el-input>
              </el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="适龄范围" prop="age_start">
                    <el-input type="text" placeholder="请输入最小年龄" v-model="formList.age_start">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="——" prop="age_end">
                    <el-input type="text" placeholder="请输入最大年龄" v-model="formList.age_end">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-card>
          <el-divider content-position="left">
            <el-tooltip class="item" effect="light" content="添加价格表(至少一个)" placement="top-start">
              <el-button type="primary" icon="el-icon-plus" circle size="medium" @click='addForm'></el-button>
            </el-tooltip>
            课包分类
          </el-divider>
          <el-card v-for="(item,index) in formLists" :key="index">
            <div class="priceHeader" v-if="formLists.length != 1">
              <el-button size="small" type="primary" @click="delForm(index)">删除</el-button>
            </div>
            <el-form :model="item" ref='refFormLists' :rules='rules' label-position="right" label-width="80px" class="mg-t-10">
              <el-form-item label="课包名称" prop="name">
                <el-input type="text" placeholder="请输入课包名称" v-model="item.name"></el-input>
              </el-form-item>
              <el-form-item label="课时" prop="lesson">
                <el-input type="text" placeholder="请输入课时" v-model="item.lesson" @blur="getPriceValue(index)"></el-input>
              </el-form-item>
              <el-form-item label="有效时长" prop="effective_duration">
                <el-input type="text" placeholder="请输入有效时长" v-model="item.effective_duration">
                  <el-select v-model="item.unit" slot="append" style="width: 60px;">
                    <el-option :value=1 label="年"></el-option>
                    <el-option :value=2 label="月"></el-option>
                    <el-option :value=3 label="日"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="价格" prop="value">
                <el-input type="text" placeholder="请输入价格" v-model="item.value" class="input-with-select">
                  <template slot="append">
                    元
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="材料费">
                <template>
                  <el-select v-model="item.is_material_fee" style="width: 100%;">
                    <el-option :value='1' label="包含"></el-option>
                    <el-option :value='2' label="不包含"></el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-form>
          </el-card>
        </el-form>
      </div>
      <!-- 内容底部区域-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="courseDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="addORmodifyCourse">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vali from '../../assets/js/validate.js'
import {
  getCourse,
  addCourse,
  modifyCourse,
  delCourse,
  getCourseDetail
} from '../../assets/js/API/course.js'
import {
  extend
} from '../../assets/js/util.js'
import {
  COURSE_TYPE,
  COURSE_NAMES
} from '../../assets/js/CONFIG/course.js'
export default {
  name: 'CourseTable',
  props: {
    isHightlight: {
      type: Boolean,
      default: false
    },
    selectRowIndex: {
      default: -1
    },
    operation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectRow: {},
      coursePackagePrice: [], //获取课程详细信息时保存本来有的课包，用来修改
      addORmodify: '', //add是添加 modify是修改
      courseDialogShow: false,
      formList: {
        id: '',
        name: '',
        type: '',
        age_start: '',
        age_end: '',
        unit_price: ''
      },
      formLists: [{
        lesson: '',
        effective_duration: '',
        name: '',
        value: '',
        unit: 2, //有效时长 1-年 2-月 3-日
        is_material_fee: 1 //是否包含材料费 1- 包含 2- 不包含
      }],
      courseType: COURSE_TYPE,
      courseNames: COURSE_NAMES,
      rowIndex: -1,
      courseList: [],
      rules: {
        type: vali.validatorCommon.mandatory('请选择课程类型', 'change'),
        name: vali.validatorCommon.mandatory('场地名称不能为空'),
        unit_price: [
          ...vali.validatorCommon.mandatory('请输入课程单价'),
          ...vali.validatorCommon.price
        ],
        age_start: [
          ...vali.validatorCommon.mandatory('请输入最小年龄'),
          ...vali.validatorCommon.number,
          {
            validator: (rule, value, callback) => {
              if (this.formList.age_end == '') return callback()
              if (value - this.formList.age_end > 0) return callback(new Error('最小年龄不能大于最大年龄'))
              return callback()
            },
            trigger: 'blur'
          }
        ],
        age_end: [
          ...vali.validatorCommon.number,
          {
            validator: (rule, value, callback) => {
              if (value == '') return callback(new Error('请输入最大年龄'))
              if (this.formList.age_start == '') return callback()
              if (value - this.formList.age_start < 0) return callback(new Error('最大年龄不能小于最小年龄'))
              return callback()
            },
            trigger: 'blur'
          }
        ],
        lesson: [
          ...vali.validatorCommon.mandatory('请输入课时'),
          ...vali.validatorCommon.number,
        ],
        effective_duration: [
          ...vali.validatorCommon.mandatory('请输入有效时长'),
          ...vali.validatorCommon.number,
        ]
      }
    }
  },
  methods: {
    courseTypeChange() {
      this.formList.name = '';
      if (this.formList.type == 2) {
        this.formLists = [{
          lesson: '',
          effective_duration: '',
          name: '',
          value: '',
          unit: 2,
          is_material_fee: 1
        }]
      }
    },
    courseNameChange(name) {
      var count = 3;
      this.formLists = [];
      var mapName = {
        1: '一年包',
        2: '半年包',
        3: '季度包'
      }
      while (count > 0) {
        this.formLists.push({
          lesson: '',
          effective_duration: '',
          name: name + mapName[count],
          value: '',
          unit: 2,
          is_material_fee: 1
        })
        count--;
      }
    },
    getPriceValue(index) {
      var price = this.formList.unit_price - 0;
      if (isNaN(price)) return
      if (index != undefined) {
        var lesson = this.formLists[index].lesson - 0,
          value = this.formLists[index].value;
        if (isNaN(lesson) || value != '') return
        this.formLists[index].value = price * lesson
      } else {
        this.formLists.forEach(item => {
          var lesson = item.lesson - 0,
            value = item.value;
          if (!isNaN(lesson) || value != '') item.value = lesson * price
        })
      }
    },
    async delCourse(row) {
      var confirm = await this.$confirm('您确定要删除吗？', '提示').catch(err => console.log(err))
      if (confirm == 'confirm') {
        delCourse.call(this, row.id)
          .then(res => {
            if (res) {
              this.getCourse()
            }
          })
          .catch(err => console.log(err))
      }
    },
    getCourse() {
      getCourse.call(this, 1)
        .then(res => {
          if (res) {
            this.courseList = res
            this.returnIndex()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async addORmodifyCourse() {
      var bool = await this.$refs.refFormCourse.validate().catch(err => console.log(err))
      if (!bool) return
      bool = false
      var els = this.$refs.refFormLists;
      for (let i = 0; i < els.length; i++) {
        bool = await els[i].validate().catch(err => console.log(err));
        if (!bool) break
      }
      if (!bool) return;
      var params = {
        ...this.formList
      };
      if (this.addORmodify == 'add') {
        params.course_package_price = [...this.formLists];
        delete params.id;
        addCourse.call(this, params)
          .then(res => {
            if (res) {
              this.getCourse();
              this.courseDialogShow = false;
            }
          })
          .catch(err => { })
      } else if (this.addORmodify == 'modify') {
        params.add_course_package_price = [];
        params.delete_course_package_price = [];
        params.edit_course_package_price = [];
        this.formLists.forEach(item => {
          if ('id' in item) params.edit_course_package_price.push(item)
          else params.add_course_package_price.push(item)
        })
        this.coursePackagePrice.forEach(item => {
          let bool = this.formLists.some(item1 => {
            if ('id' in item1 && (item.id == item1.id)) return true
            else return false
          })
          if (!bool) params.delete_course_package_price.push(item.id)
        });
        modifyCourse.call(this, this.selectRow.id, params)
          .then(res => {
            if (res) {
              this.getCourse();
              this.courseDialogShow = false;
            }
          })
          .catch(err => { })
      }
    },
    dialogClose() {
      this.$refs.refFormCourse.resetFields();
      var els = this.$refs.refFormLists;
      this.formLists.splice(1, els.length - 1)
      els.forEach(item => item.resetFields())
    },
    addForm() {
      this.formLists.push({
        lesson: '',
        effective_duration: '',
        name: '',
        value: '',
        unit: 2,
        is_material_fee: 1
      })
    },
    delForm(index) {
      this.$confirm('您确定要移除吗？', '提示')
        .then(() => this.formLists.splice(index, 1))
        .catch(() => { })
    },
    showCourseInfo(key, row) {
      this.addORmodify = key;
      this.courseDialogShow = true
      if (row == undefined) { //添加

      } else { //修改
        this.selectRow = row;
        this.getCourseDetail()
      }
    },
    getCourseDetail(id) {
      getCourseDetail.call(this, this.selectRow.id)
        .then(res => {
          if (res) {
            extend(this.formList, res);
            this.formLists = res.coursePackagePrice;
            this.coursePackagePrice = [...res.coursePackagePrice]
          }
        })
        .catch(err => console.log(err))
    },
    tableRowClassName({
      row,
      rowIndex
    }) {
      if (this.rowIndex == rowIndex) return 'hight-light-row'
      return ''
    },
    current_change($event) {
      this.rowIndex = -1;
      this.$emit('current_change', $event)
    },
    pageChange(page) {
      this.page = page;
      this.getCourse();
    },
    /* 根据上课场地获取在已有上课场地的Index */
    returnIndex() {
      this.rowIndex = this.courseList.findIndex(item => {
        return item.id == this.selectRowIndex
      });
    }
  },
  created() {
    this.getCourse();
  },
  computed: {
    getDialogTitle() {
      if (this.addORmodify == 'add') return '添加课程分类'
      else if (this.addORmodify == 'modify') return '课程信息'
    }
  },
  watch: {
    'selectRowIndex': function (val) {
      this.$refs.refTable.setCurrentRow()
      this.returnIndex()
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #888888;
}

.formByFee > .el-card {
  margin-bottom: 10px;
}

.priceHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.formContainer {
  height: 600px;
  overflow-y: auto;
}

.el-divider {
  background-color: rgba(0, 0, 0, 0.14);
}

/deep/ .el-table .hight-light-row {
  background-color: #fed90e;
}
</style>
