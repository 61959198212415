import request from '../request/request'
// 更新数据
export const updateSalary = (data) => {
  return request({
    url: '/organization/salary/single/updateItem',
    method: 'POST',
    data
  })

}
// 查看明细
export const checkSalaryDetail = (params) => {
  return request({
    url: '/organization/salary/single/salaryDetailByDate',
    method: 'get',
    params
  })
}