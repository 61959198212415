/* ---------------上课场地API--------------------- */
export async function getClassRoom (page) {
	const { data: res } = await this.$http.get('organization/classrooms?page=' + page);
	if (res.code == 200) {
		return res.data
	} else this.$message.error(res.message || res.msg)
}

export async function addClassRoom (params) {
	const { data: res } = await this.$http.post('organization/classrooms', params);
	if (res.code == 200) {
		this.$message.success("添加上课场地成功");
		return res.data		
	} else this.$message.error(res.message || res.msg)
}

export async function modifyClassRoom (classrommID, params) {
	const { data: res } = await this.$http.patch('organization/classrooms/' + classrommID, params);
	if (res.code == 200) {
		this.$message.success("修改上课场地成功");
		return res.data		
	} else this.$message.error(res.message || res.msg)
}

export async function delClassRoom (classroomID) {
	const { data: res } = await this.$http.delete('organization/classrooms/' + classroomID);
	if (res.code == 200) {
		this.$message.success("删除上课场地成功");
		return res.data		
	} else this.$message.error(res.message || res.msg)
}

export async function sortClassRoom (params) {
	const { data: res } = await this.$http.patch('organization/classrooms',  params);
	if (res.code == 200) {
		this.$message.success("排序成功");
		return res.data		
	} else this.$message.error(res.message || res.msg)
}
