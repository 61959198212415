<template>
	<!-- 学生的导入导出 -->
	<div class="container">
		<div class="body">
			<!-- 下载 -->
			<el-card>
				<div class="des">
					<img src="../../assets/image/excel.png"/>
					<div>点击下载模板可按照模板样式填入学员信息</div>
					<el-button>下载模板</el-button>
				</div>
			</el-card>
			<!-- 导入 -->
			<el-card>
				<div class="des">
					<img src="../../assets/image/in.png"/>
					<div>点击导入学员即可将学员批量导入系统中</div>
					<el-button>导入学员</el-button>
				</div>
			</el-card>
			<!-- 导出 -->
			<el-card>
				<div class="des">
					<img src="../../assets/image/out.png"/>
					<div>默认仅校长能够导出学员校长可指定管理老师导出学员</div>
					<el-button>导出学员</el-button>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'StudentInAndOut'
	}
</script>

<style lang="less" scoped>
	.container{
		.des{
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			>img{
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}
			> div{
				color: #969896;
				padding: 10px;
			}
		}
	}
	.el-card__body{
		padding: 5px !important;
	}
	
</style>
