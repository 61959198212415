<template>
	<!-- 获取学生的详细信息模板 -->
	<div>
		<el-breadcrumb separator="/" v-if="showBreadcrumb">
			<el-breadcrumb-item :to="{ path: '/student/studentFile' }">学员档案</el-breadcrumb-item>
			<el-breadcrumb-item>学员详细信息</el-breadcrumb-item>
			<el-breadcrumb-item>{{name}}</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 卡片内容区 -->
		<el-card>
			<div slot='header' class="clearfix header">
				<el-tabs type="card" v-model="activeName">
					<el-tab-pane label="基础信息" name='studentbasicInformation' :key='1' lazy>
						<studentbasicInformation :student-id='studentId'></studentbasicInformation>
					</el-tab-pane>
					<el-tab-pane label="班级信息" name='studentClassInformation' :key='2' lazy>
						<student-class :student-id="studentId"></student-class>
					</el-tab-pane>
					<el-tab-pane label="课包购买记录" name='studentCostLessonInformation' :key='3' lazy>
						<student-cost-lesson :student-id="studentId" :student-name="studentName"></student-cost-lesson>
					</el-tab-pane>
					<el-tab-pane label="退费记录" name='studentRefundList' :key='5' lazy>
						<student-refund-list-c :student-id="studentId"></student-refund-list-c>
					</el-tab-pane>
					<el-tab-pane label="备注记录" name='studentRemarkList' :key='6' lazy>
						<student-remark-list-c :student-id="studentId"></student-remark-list-c>
					</el-tab-pane>
					<el-tab-pane label="学员作品记录" name='studentWorkList' :key='7' lazy>
						<student-work-list-c :student-id="studentId"></student-work-list-c>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-card>
		<!-- 卡片内容区/ -->
	</div>
</template>

<script>
	import studentbasicInformation from './form/studentbasicInformation.vue'
	import studentClass from './form/studentClassInformation'
	import studentCostLesson from './form/studentCostLessonInformation.vue'
	import studentRefundListC from './form/studentRefundList.vue'
	import studentRemarkListC from './form/studentRemarkList.vue'
	import studentWorkListC from './form/studentWorkLists.vue'
	export default {
		name: 'StudentFileSingle',
		components: {
			studentbasicInformation,
			studentClass,
			studentCostLesson,
			studentRefundListC,
			studentRemarkListC,
			studentWorkListC
		},
		props: {
			studentId: {
				default: ''
			},
			guardianId: {
				default: ''
			},
			studentName: {
				type: String,
				default: ''
			},
			showBreadcrumb: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				activeName: 'studentbasicInformation',
				name: ''
			}
		},
		created() {
			this.name = this.$route.query.name
		}
	}
</script>

<style lang="less" scoped>
	.el-tabs {
		width: 100%;
	}
</style>
