// 2021-04-15由FZY创建 用于附件上传
<template>
	<div class="file">
		<div class="boxFile" v-for="(item, index) in lists" :key='index'>
			<!-- 图片 -->
			<img :src="item.url || item.path" v-if="imgORvideoM(item)" style="width: 100%;height: 100%;"></img>
			<!-- 视频第一帧 -->
			<div v-else style="position: relative;width: 100%;height: 100%;">
				<img :src="item.url + '?vframe/jpg/offset/1' || item.path + '?vframe/jpg/offset/1'" style="width: 100%;height: 100%;"></img>
				<div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);opacity: 0.5;font-size: 30px;color: #fff;"
				 class="iconfont icon-bofang"></div>
			</div>
			<!-- 查看和删除 -->
			<div class="fileIcon">
				<i class="el-icon-search cursor-p" @click="handlePreview(item)"></i>
				<i class="el-icon-delete cursor-p" @click="handleRemove(item, index)"></i>
			</div>
			<div v-if="item.status && item.status == 'uploading'" style="position: absolute;z-index: 9999;top:50%;left: 50%;transform: translate(-50%, -50%);font-size: 16px;">{{item.percentage.toFixed(0)}}%</div>
		</div>
		<el-upload class="avatar-uploader" action="http://upload-z2.qiniup.com" :file-list="lists" :multiple='false'
		 list-type="picture-card" :on-preview="handlePreview" :before-upload="handleUploadBefore" :on-success="handleSuccess"
		 :on-progress="handleProgress" :data="formData" :accept="accept" :show-file-list='false'
		 :on-error="handleError" ref='refFile'>
			<i class="el-icon-plus"></i>
		</el-upload>
		<!-- 作品展示 -START -->
		<el-dialog :visible.sync="previewWorkDialogShow" :modal-append-to-body="false" append-to-body>
			<div class="text-align-c">
				<img width="100%" :src="previewWork.url" alt="" v-if="imgORvideoM(previewWork)">
				<video controls height="300" v-else>
					<source :src="previewWork.url" type="video/mp4">
					</source>
					<source :src="previewWork.url" type="video/ogg">
					</source>
					<source :src="previewWork.url" type="video/webm">
					</source>
					您的浏览器不支持看视频
				</video>
			</div>
		</el-dialog>
		<!-- 作品展示 -END -->
	</div>
</template>

<script>
	import {
		checkFileType
	} from '../../assets/js/util.js'
	import {
		getToken
	} from '../../assets/js/API/teacher.js'
	/* 压缩图片 */
	import compress from '../../assets/js/compress-file.js'
	export default {
		name: 'Upload',
		props: {
			accept: {
				type: String,
				default: 'image/png,image/jpeg,video/mp4'
			},
			fileLists: {
				type: Array,
				default: function () {
					return []
				}
			}
		},
		data () {
			return {
				previewWorkDialogShow: false,
				previewWork: {},
				uploading: [], // 正在上传的
				formData: {
					key: '',
					token: ''
				},
				lists: []
			}
		},
		watch: {
			fileLists: function (files) {
				this.lists = JSON.parse(JSON.stringify(files))
			}
		},
		created() {
			this.lists = JSON.parse(JSON.stringify(this.fileLists));
			this.getToken()
		},
		methods: {
			formatFile () {				
				return this.lists.map( item => {
					return {
						url: item.url,
						type: item.type
					}
				})
			},
			imgORvideoM(file) {
				var imgORvideo = checkFileType(file.type ? file.type : '');
				if (imgORvideo.img) return true
				else if (imgORvideo.video) return false
			},
			handlePreview(file) {
				this.previewWork = file;
				this.previewWorkDialogShow = true
			},
			handleSuccess(response, file, fileList) {
				var index = fileList.findIndex(item => {
					return item.uid == file.uid
				})
				var uploadSuccessIndex = this.uploading.indexOf(file.uid);
				this.lists[index].url = 'http://v.hipposteam.com/' + response.key;
				this.uploading.splice(uploadSuccessIndex, 1);
				this.$emit('success', { lists: this.formatFile() })
				this.$forceUpdate()
			},
			handleProgress(event, file, fileList) {
				var index = fileList.findIndex(item => {
					return item.uid == file.uid
				})
				this.lists[index] = file;
				this.$forceUpdate()
			},
			handleError(err, file, fileList) {
				this.$message.error('上传失败');
				var index = fileList.findIndex(item => {
					return file.uid == item.uid
				});
				this.lists.splice(index, 1);
				this.$forceUpdate()
			},
			handleRemove(file, index) {
				this.$confirm('您确定删除吗', '提示')
					.then(res => {
						this.lists.splice(index, 1)
						this.$emit('remove', { lists: this.formatFile() })
					})
					.catch(err => {})
			},
			async handleUploadBefore(file) {
				/* 只能是视频或者图片 */
				var accepts = this.accept.split(',');
				if (!accepts.includes(file.type)) {
					this.$message.error('文件格式不正确！！！')
					this.$refs.refFile.$el.abort()
				}
				var date = new Date();
				var currentTime = date.getFullYear() + '-' + (date.getMonth() - 0 + 1) + '-' + date.getDate();
				var nameArr = file.name.split('.');
				this.formData.key = currentTime + '/' + Math.floor((Math.random() * 1000000)) + '/' + Math.floor((Math.random() *
					1000000)) + '.' + nameArr[nameArr.length - 1];
				this.lists.push(file);
				this.uploading.push(file.uid);
				var imgType = ['image/jpeg', 'image/png'];
				if (imgType.indexOf(file.type) != -1) return await compress(file)
				return file
			},
			getToken() {
				getToken.call(this)
					.then(res => {
						if (res) this.formData.token = res.token
					})
					.catch(err => console.log(err))
			},
		}
	}
</script>

<style scoped>
	.file {
		display: flex;
	}

	.el-icon-plus {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}

	.boxFile {
		width: 80px;
		height: 80px;
		border-radius: 4px;
		overflow: hidden;
		margin-right: 10px;
		margin-bottom: 10px;
		position: relative;
		background-color: #f4f5f6;
		color: #606266;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
		overflow: hidden;
	}

	.fileIcon {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 20px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.1);
		width: 100%;
		height: 100%;
		opacity: 0;
		color: #fff;
	}

	.boxFile:hover .fileIcon{
		opacity: 1;
	}

	/deep/ .boxComment textarea:focus-within {
		border: 1px solid #fdd23e;
	}


	/deep/ .el-upload--picture-card {
		width: 80px;
		height: 80px;
	}

	/deep/ .el-upload {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}

	/deep/ .el-upload-list--picture-card .el-upload-list__item {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}

	/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}

	/deep/ .avatar {
		width: 80px;
		height: 80px;
	}

	@media screen and (max-width: 1499px) {
		.boxFile {
			width: 60px;
			height: 60px;
			border-radius: 4px;
			margin-right: 8px;
			margin-bottom: 8px;
		}

		.fileIcon {
			font-size: 14px;
		}

		.el-icon-plus {
			width: 60px;
			height: 60px;
			line-height: 60px;
		}

		/deep/ .el-upload--picture-card {
			width: 60px;
			height: 60px;
		}

		/deep/ .el-upload {
			width: 60px;
			height: 60px;
			line-height: 60px;
		}

		/deep/ .el-upload-list--picture-card .el-upload-list__item {
			width: 60px;
			height: 60px;
			line-height: 60px;
		}

		/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
			width: 60px;
			height: 60px;
			line-height: 60px;
		}

		/deep/ .avatar {
			width: 60px;
			height: 60px;
		}

		.boxConfirm {
			padding: 6px 50px;
		}

		.loading-two {
			font-size: 40px;
			margin-top: 260px
		}

		.loading-one {
			margin-top: 25px;
			font-size: 25px;
		}

		.boxScroll {
			height: 155px;
		}
	}

	@media screen and (min-width: 1500px) {}
</style>
