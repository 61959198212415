<template>
	<div>
		<el-upload class="avatar-uploader" action="http://upload-z2.qiniup.com" :file-list="fileList" :multiple='multiple'
		 list-type="picture-card" :on-preview="handlePreview" :before-upload="handleUploadBefore" :before-remove="beforeRemove"
		 :on-remove="handleRemove" :on-success="handleSuccess" :data="formData" accept="image/png, image/jpeg">
			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible" :modal-append-to-body='false'>
			<img width="100%" :src="imageUrl" alt="">
		</el-dialog>


	</div>
</template>
<script>
	import params from '../../assets/js/params.js'
	import {
		isImg
	} from '../../assets/js/myMethods'
	/* 压缩 */
	import compress from '../../assets/js/compress-file.js'
	export default {
		name: 'Upload2',
		props: {
			// 是否可以上传多个
			multiple: {
				type: Boolean,
				default: function() {
					return true
				}
			}
		},
		data() {
			return {
				action: params.url_img_file,
				fileList: [],
				imageUrl: '',
				formData: {
					key: '',
					token: ''
				},
				dialogVisible: false
			}
		},
		methods: {
			// 更新fileList
			upFileList(val) {
				this.fileList = val
			},
			// 清空filelist
			clearFileList() {
				this.fileList = []
			},
			// 获得token
			async getToken() {
				var {
					data: res
				} = await this.$http.get('/common/qiniuToken')
				if (res.code === 200) {
					this.formData.token = res.data.token
				}
			},
			// 上传之前
			async handleUploadBefore(file) {
				// 只允许传图片
				if (!isImg(file.type)) {
					this.$message.error('只允许上传图片')
					return false
				}
				// 更新key
				var date = new Date();
				var currentTime = date.getFullYear() + '-' + (date.getMonth() - 0 + 1) + '-' + date.getDate();
				this.formData.key = currentTime + '/' + file.uid + '/' + file.name
				return await compress(file)
				// this.formData.key = file
			},
			// 点击上传的文件
			handlePreview(file) {
				this.dialogVisible = true
				this.imageUrl = file.url
			},
			// 文件列表移除文件时的钩子    
			async handleRemove(file, fileList) {
				this.fileList = [...fileList]
				this.$emit('removeFile', fileList)
			},
			// 删除文件之前的钩子    
			beforeRemove(file, fileList) {
				// return false
				// this.$confirm('您确认要删除这个图片吗？', '提示', {
				//     confirmButtonText: '确定',
				//     cancelButtonText: '取消',
				//     type: 'warning'        
				// }).then( e => {
				//   return true
				// }).catch ( e => {
				//   return false
				// })
			},
			// 文件超出个数限制时的钩子
			handleExceed(file, fileList) {},
			// 上传成功的钩子函数
			handleSuccess(response, file, fileList) {
				if (response.key) {
					var json = {
						"name": response.key,
						"url": "http://v.hipposteam.com/" + response.key,
						uid: file.uid
					};
					this.fileList.push(json);
					console.log(fileList, json, this.fileList, 'load')
					this.$emit('upLoadUrl', {
						url: json
					})
				} else {
					this.fileList = [];
					this.$message.error("上传失败");
				}
			}

		},
		mounted() {
			this.getToken()
		},
		created() {
			this.$emit('clearFileList', {
				func: this.clearFileList
			})
			this.$emit('upFileList', {
				func: this.upFileList
			})
		}
	}
</script>
<style lang="less" scoped>
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.el-icon-plus {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}


	/deep/ .el-upload--picture-card {
		width: 80px;
		height: 80px;
	}

	/deep/ .el-upload {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}

	/deep/ .el-upload-list--picture-card .el-upload-list__item {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}

	/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
		width: 80px;
		height: 80px;
		line-height: 80px;
	}

	/deep/ .avatar {
		width: 80px;
		height: 80px;
	}

	.el-dialog__body>img {
		z-index: 999999 !important;
	}

	/deep/ .v-modal {
		display: none !important;
	}
</style>
