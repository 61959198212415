var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('msg-c',{attrs:{"selectTab":_vm.$route.query.tab},on:{"getMsg":function($event){_vm.getMsg = $event}},scopedSlots:_vm._u([{key:"leave",fn:function(ref){
var msgList = ref.msgList;
var pagition = ref.pagition;
return [_c('el-card',[_c('msg-list-c',{attrs:{"dataTable":msgList,"getMsg":_vm.getMsg,"pagition":pagition}})],1)]}},{key:"cost",fn:function(ref){
var msgList = ref.msgList;
var pagition = ref.pagition;
return [_c('el-card',[_c('msg-list-c',{attrs:{"dataTable":msgList,"getMsg":_vm.getMsg,"pagition":pagition}})],1)]}},{key:"term",fn:function(ref){
var msgList = ref.msgList;
var pagition = ref.pagition;
return [_c('el-card',[_c('msg-list-c',{attrs:{"dataTable":msgList,"getMsg":_vm.getMsg,"pagition":pagition}})],1)]}},{key:"classTime",fn:function(ref){
var msgList = ref.msgList;
var pagition = ref.pagition;
return [_c('el-card',[_c('msg-list-c',{attrs:{"dataTable":msgList,"getMsg":_vm.getMsg,"pagition":pagition}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }