class handleDate {
	/* 用来处理日期 */
	constructor(arg) {

	}
	/* 从一个日期数组里找到离想找到的日期的最近日期 */
	lately(date, ...params) {
		/* {
			date: 'Array -- 必填-- 要查的日期数组',
			findDate: 'String  --选填--依据这个日期找',
			keyObj: {
				date: 'String  --- date为对象时，date日期的key值',
				time: 'String  --- date为对象时，date时间的key值'
			}
		} */
		var findDate = Date.now(),
			keyObj = {
				date: 'date',
				time: 'time'
			};
		/* 依据日期findDate和keyObj不是必选的,这个是用来区分传的参数是哪个 */
		if (params.length == 1) {
			var type = typeof params[0];
			if (type == 'string' || type == 'number') {
				findDate = params[0] - 0
			} else if (params[0] instanceof Object) {
				keyObj = params[0]
			}
		} else if (params.length == 2) {
			findDate = params[0] - 0;
			keyObj = params[1]
		}
		var result = {
			/* 索引 */
			index: '',
			/* 返回的日期 */
			value: '',
			/* 错误信息 没错误为空 */
			err: '',
		};
		/* 检测date数组是不是日期格式的 */
		/* 检测keyObj是否合理，它的prototype>date应该是要查询数组对象的日期key值， time应该是要查询数组对象的时间key值 */
		for (let i = 0; i < date.length; i++) {
			var bool = false;
			if (typeof date[i] == 'string') {
				if (!this.checkDate(date[i])) bool = true
			} else if (date[i] instanceof Object) {
				if (!date[i][keyObj.date] || !date[i][keyObj.time]) {
					result.err = `date应该是要查询数组对象的日期key值， time应该是要查询数组对象的时间key值`;
					return result
				} else if (!this.checkDate(date[i][keyObj.date] + ' ' + date[i][keyObj.time])) bool = true
			}
			if (bool) {
				result.err = `${date[i]}不是日期格式，在索引为${i}的位置`;
				return result
			}
		}
		/* 检测findDate是不是日期格式或者是不是毫秒 */
		if (!this.checkDate(findDate)) {
			result.err = `${findDate}不是日期，也不是毫秒`;
			return result
		}
		/* 要查询的日期数组减去依据日期的绝对值数组 */
		var arr;
		try {
			arr = date.map(e => {
				/* 数组字符串形式  --格式为YYYY-MM-DD HH:MM:SS*/
				if (typeof e == 'string') {
					return Math.abs(new Date(e).getTime() - findDate)
				}
				/* 数组对象形式  --格式为{date: 'YYYY-MM-DD'， time: 'HH-MM-SS'},可以通过keyObj设置想要的键 */
				else if (e instanceof Object) {
					var d = new Date(e[keyObj.date] + ' ' + e[keyObj.time])
					return Math.abs(d.getTime() - findDate)
				}

			})
		} catch (e) {
			this.thorwErr(e);
			result.err = e;
			return result
		}
		var min = Math.min(...arr);
		result.index = arr.findIndex((p) => {
			return p == min;
		});
		result.value = date[result.index]
		return result
	}
	/* 按照格式返回日期 */
	detail(date, format) {
		/* date为要格式化的日期*/
		/* format为要返回的日期格式(现在只精确到日期)，有YYYY-MM-DD   YYYY年MM月DD日  YYYY/MM/DD */
		date = new Date(date);
		var year = date.getFullYear(), //年份
			mouth = date.getMonth() - 0 + 1, //月
			day = date.getDate(), //这个月几号
			nowDay = date.getDay(), //本周第几天
			hours = date.getHours(), //时
			minutes = date.getMinutes(), //分
			seconds = date.getSeconds(), //秒
			time = date.getTime(); //毫秒
		if (mouth < 10) mouth = '0' + mouth
		if (day < 10) day = '0' + day
		if (hours < 10) hours = '0' + hours
		if (minutes < 10) minutes = '0' + minutes
		if (seconds < 10) seconds = '0' + seconds
		var obj = {
			year,
			mouth,
			day,
			hours,
			minutes,
			seconds,
			time,
			nowDay
		}
		if (format) {
			format = format.toLocaleUpperCase();
			format = format.replace('YYYY', obj.year);
			format = format.replace('MM', obj.mouth);
			format = format.replace('DD', obj.day);
			return format
		} else return obj
	}
	/* 日期的计算 */
	computed(date, value, type, key = 'mouth', format = 'YYYY-MM-DD') {
		/* date是初始的日期， value是要加多少或者减多少，type是加还是减，有add和reduce, key是年（year）、月(mouth)、日(day)中的一种 format是最后返回的格式 --有YYYY-MM-DD   YYYY年MM月DD日  YYYY/MM/DD*/
		/* 第一步 -- 是要拿到初始日期 */
		var dateObj = this.detail(date),
			count = 0;
		/* 第二步-- 计算出应该加多少哦或者减多少 */
		switch (key) {
			case 'year':
				var year = dateObj.year;
				/* 闰年有366天 */
				if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {
					count = value * (366 * 24 * 60 * 60 * 1000)
				}
				/* 平年有365天 */
				else {
					count = value * (365 * 24 * 60 * 60 * 1000)
				}
				break;
			case 'mouth':
				/* 默认一个月有30天 ---待优化 闰年2月29天，平年28天 4、6、9、11是30天 1、3、5、7、8、10、12是31天*/
				count = value * 30 * 24 * 60 * 60 * 1000
				break;
			case 'day':
				count = value * 24 * 60 * 60 * 1000
				break;
			default:
				break;
		}
		/* 第三步-- 计算 */
		if (type == 'add') return this.detail(new Date(dateObj.time + count), format)
		else if (type == 'reduce') return this.detail(new Date(dateObj.time - count), format)
	}
	/* 某段时间的一周开始结束日期 */
	getWeekStartAndEnd(val) {
		/* val是日期 */
		let now = '';
		if (val) {
			now = new Date(val); // 日期
		} else {
			now = new Date(); // 日期
		}
		let nowDayOfWeek = now.getDay(); // 本周的第几天
		let nowDay = now.getDate(); // 当前日
		let nowMonth = now.getMonth(); // 当前月
		let nowYear = now.getFullYear(); // 当前年
		let weekStart = this.getWeekStart(nowYear, nowMonth, nowDay, nowDayOfWeek)
		let weekEnd = this.getWeekEnd(nowYear, nowMonth, nowDay, nowDayOfWeek)
		return {
			'date_start': weekStart,
			'date_end': weekEnd
		}
	}
	/* 获得一周的开始  ---周日 */
	getWeekStart(nowYear, nowMonth, nowDay, nowDayOfWeek) {
		/* nowYear(当前年), nowMonth(当前月), nowDay(当前日), nowDayOfWeek(本周的第几天) */
		let weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek)
		return this.detail(weekStartDate, 'YYYY-MM-DD')
	}
	/* 获得一周的结束  ---周六*/
	getWeekEnd(nowYear, nowMonth, nowDay, nowDayOfWeek) {
		/* nowYear(当前年), nowMonth(当前月), nowDay(当前日), nowDayOfWeek(本周的第几天) */
		let weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek))
		return this.detail(weekEndDate, 'YYYY-MM-DD')
	}
	/* 检测是不是日期 */
	checkDate(date) {
		/* 是数字或者字符串的 */
		/* 数字的只要不是负数就行 */
		if (isNaN(date) && !isNaN(Date.parse(date))) {
			return true
		} else if (date - 0 >= 0) {
			return true
		} else return false
	}
	thorwErr(e) {
		console.error(e)
	}
}
var formatDate = new handleDate();

// 删除两个数组里面重复的内容
function delRepeat (arr1, arr2) {
	// arr1是要删除的数组 arr2是筛选的数组
	var result = []
	result = arr1.filter( e => {
		var bool = arr2.some( ee => {
			return e.id == ee.id
		})
		return !bool
	})
	return result
}

// 是不是图片
function isImg (fileType) {
	const IMGTYPE = ['image/jpeg', 'image/png'];
	if (IMGTYPE.indexOf(fileType) == -1) return false
	return true
}

// 判断环境
function env() {
    if (process.env.NODE_ENV === "development") return "development";   //开发环境
    if (window.location.href.includes('192.168')) return 'test';        //测试环境，"192.168"根据实际情况而定
    return 'production'                                                 //线上环境
}

// 检测表格配置哪一些是有的
function table_test(obj, obj1) {
	var o = JSON.stringify(obj1);
		o = JSON.parse(o);
	// 拿到数据的键
	var s = Object.keys(obj);
	// 再用拿到的键和表格配置对比
	for (var i in o) {
		var ss = s.some((e) => {
			return e === o[i].prop
		})
		// 如果有就设置表格配置的type为true,如果没有就设置type为false
		if (!ss) {
			o[i].type = false
		}
	}
	return o
}

// 根据日期返回年月日时分秒
function getDetail_date (date) {
	// date格式年-月-日 小时：分钟：秒
	var reg = /^(([\d]+)-([\d]+)-([\d]+))*(?:\s)*(([\d]+):([\d]+):([\d]*))*$/,
		arr = date.match(reg);
	var obj = {
			year: arr[2],
			mouth: arr[3],
			day: arr[4],
			hours: arr[6],
			minute: arr[7],
			second: arr[8]
	}
		return obj		
	}
// 根据年月日返回日期
function returnDate (dateObj) {
	var	s = dateObj.mouth - 12,
		d = Math.floor(dateObj.mouth / 12);
	dateObj.year = s > 0 ? dateObj.year - 0 + Math.ceil(s / 12) : dateObj.year
	dateObj.mouth = s > 0 ? dateObj.mouth - d * 12 : dateObj.mouth;
	dateObj.mouth = dateObj.mouth == 0 ? 12 : dateObj.mouth
	dateObj.mouth = String(dateObj.mouth).length < 2 ? ('0' + dateObj.mouth) : dateObj.mouth
	dateObj.day = String(dateObj.day).length < 2 ? ('0' + dateObj.day) : dateObj.day;
	return dateObj.year + '-' + dateObj.mouth + '-' + dateObj.day
}

// 今天的日期
function getDate () {
	var d = new Date(),
		y = d.getFullYear(),
		m = d.getMonth() - 0 + 1,
		date = d.getDate(),
		h = d.getHours(),
		min = d.getMinutes(),
		s = d.getSeconds();
	if (String(m).length < 2) m = '0' + m
	if (String(date).length < 2) date = '0' + date
	if (String(h).length < 2) h = '0' + h
	if (String(min).length < 2) min = '0' + min
	if (String(s).length < 2) s = '0' + s	
	return y + '-' + m + '-' + date + ' ' + h + ':' + min + ':' + s
}

// 判断性别显示不同的头像
function showImg_sex (student) {
	if (student instanceof Array){
			student.forEach (e => {
			if (e.sex == 1) return 'http://v.hipposteam.com/man.svg'
			else if (e.sex == 2) return 'http://v.hipposteam.com/woman.svg'
		})
	}
	else {
		if (student == 1 || '男') return 'http://v.hipposteam.com/man.svg'
		else if (student == 2 || '女') return 'http://v.hipposteam.com/woman.svg'
	}
}

// 是否为空字符串
function isSpace (val) {
	if (String(val).trim().length === 0) return true
	return false
}


function checkDate (d1, d2) {
	var date1 = new Date(d1).getTime(),
		date2 = new Date(d2).getTime();
	if (date2 < date1) return true
	return false
}
function checkAge (d1, d2) {
	d1 = String(d1).length == 0 ? 1 : d1,
	d2 = String(d2).length == 0 ? 999 : d2
	if (d2 - 0 <= d1 - 0) return true
	return false
}


// 处理年龄范围
function getAge_range (obj) {
	return obj.age_start + '-' + obj.age_end + '岁'
}
// 处理上课频率
function getFrequency (obj) {
	return obj.frequency + '次/周'
}
// 处理上课模式
function getMode_course (obj) {
	if (obj.mode === 1) return '班级教学'
	else if (obj.mode === 2) return '一对一'
}
// 处理班级状态
function getStatus_class (obj) {
	if (obj.status === 1) return '启用'
	else if (obj.status === 2) return '关闭'
}
// 处理上课时间
function getTime_course (obj) {
	var obj1 = JSON.parse(obj);
	return obj1
	// return obj1.start_time +  '-' + obj1.end_time
	// 时间 + 星期几
	// if (obj.status === 1) return '启用'
	// else if (obj.status === 2) return '关闭'
}
// 处理缴费状态
function getStatus_fee (obj) {
	if (obj.pay_status == 1) return '全额缴费'
	else if (obj.pay_status == 2) return '缴费不足'
}
// 处理费用状态
function getUtility_fee (obj) {
	if (obj.utility == 1) return '正常'
	else if (obj.utility == 2) return '即将到期'
	else if (obj.utility == 3) return '已经结束'
	else if (obj.utility == 4) return '已经欠费'		
}
// 处理费用有效状态
function getEffective_fee (obj) {
	if (obj.is_effective == 1) return '有效'
	else if (obj.is_effective == 2) return '失效'
}
function getType_log (obj) {
	if (obj.type == 1) return '续费'
	else if (obj.type == 2) return '修改'
	else if (obj.type == 3) return '补缴'
	else if (obj.type == 4) return '新增缴费'	
	else if (obj.type == 5) return '退费'
}

// 年龄范围--最小年龄
function age_start (val) {
	var reg = /^[\d]{1}$/
	if (!reg.test(val)){ 
		this.$message.error('请输入1-999之间的数字');
		//  return this.value_age_end = 100
	}
	if (val <= 0) {
		this.$message.error('最小年龄不能小于1');					
		// return this.value_age_start = 1
	}
}
// 年龄范围-最大年龄
function age_end (val) {
	var reg = /^[\d]{1,}$/
	if (!reg.test(val)){ 
		this.$message.error('请输入1-999之间的数字');
		 return this.value_age_end = 100
	}
	if (val < that.value_age_start) {
		this.$message.error('不能小于最小年龄' + this.value_age_start)
		return this.value_age_end = this.value_age_start
	}
	if (val > 999) {
		that.$message.error('最大年龄不能大于999' + this.value_age_start)					
		return this.value_age_end = 999
	}
}

/* 过滤价格 */
class myFilter {
	constructor(arg) {
	    
	}
	/* 过滤价格 */
	filterPrice (val) {
		return Number(val).toFixed(2)
	}
}
var filter = new myFilter();

/* 页面筛选条件的保存和读取 */
export class screen {
	constructor(arg) {
	    
	}
	setItem (key, value, k = 'screen', type = 'sessionStorage') { //type是保存到哪一个存储对象  接受两个 sessionStorage 和 localStorage
		if (!this.checkType(type)) return this.throwError('type类型错误')
		var storageValue =  window[type].getItem(k) ? JSON.parse(window[type].getItem(k)) : {};
		(!storageValue.hasOwnProperty(key)) && (storageValue[key] = {});
		for (let i in value) {
			storageValue[key][i] = value[i]
		}
		window[type].setItem(k, JSON.stringify(storageValue));
		return JSON.parse(window[type].getItem(k))
	}
	getItem (need, k = 'screen', type = 'sessionStorage') { //need是从本地存储对象内拿到这个key值的value
		if (!this.checkType(type)) return this.throwError('type类型错误')
		var storageValue = window[type].getItem(k) ? JSON.parse(window[type].getItem(k)) : {};
		(!storageValue.hasOwnProperty(need)) && (this.throwError(`本地存储没找到${need}`));
		return storageValue[need]
	}
	removeItem (need, k = 'screen', type = 'sessionStorage') {
		if (!this.checkType(type)) return this.throwError('type类型错误')
		var storageValue =  window[type].getItem(k) ? JSON.parse(window[type].getItem(k)) : {};
		for (let i in storageValue) {
			need != i && delete storageValue[i]
		}
		window[type].setItem(k, JSON.stringify(storageValue));
		return storageValue
	}
	checkType (type) {
		if (type == 'sessionStorage' || type == 'localStorage') return true
		return false
	}
	throwError (err) {
		/* console.error(err); */
		return false
	}
}

export const WEEKMAP = ['', '一', '二', '三', '四', '五', '六', '日']




export {
	env,
	table_test,
	getAge_range,
	getFrequency,
	isSpace,
	getStatus_class,
	getMode_course,
	getTime_course,
	age_start,
	age_end,
	showImg_sex,
	getEffective_fee,
	getUtility_fee,
	getStatus_fee,
	getType_log,
	getDate,
	isImg,
	getDetail_date,
	returnDate,
	checkDate,
	checkAge,
	delRepeat,
	formatDate,
	filter
}
