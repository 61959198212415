/* 消息配置 */
export const msgContent = ['', '学员{{student}}因为{{content}}对{{date}}的{{class}}请假了,请知悉!',
	'学员{{student}}的家长缴费了,请知悉!', '', '',
	'学员{{student}}有一笔学费的课时即将用完,请及时提醒家长缴费!', '', '', '',
	'学员{{student}}的有效期不足，请及时提醒家长续费!',
	'学员{{student}}的课时即将用完，请及时提醒家长续费!'
];
export const tab = ['',
	'leave',
	'cost', '', '',
	'warning', '', '', '',
	'term',
	'classTime'
]
export const type = ['',
	'家长请假',
	'家长缴费',
	'', '',
	'费用预警', '', '', '',
	'有效期不足',
	'课时不足'
	]
	
	
export function getContent (val) {
	var student_name = val.from.student_name,
		content = val.content.content,
		date = val.type == 1 ? (val.content.attendance ? val.content.attendance.date : val.content.class_time.date): '',
		class_name = val.type == 1 ? val.content.class.name : '';
	return msgContent[val.type].replace('{{student}}', student_name).replace('{{content}}', content).replace('{{date}}', date).replace('{{class}}', class_name)				
	
}
