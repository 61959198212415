<template>
	<!-- 教师所有薪资 -->
	<div>
		<breadcrumb-c :value_breadcrumb='value_breadcrumb'></breadcrumb-c>
		<el-card>
			<el-button type="primary" size="small" @click='showAddSalary' v-if="isCanAdd">添加薪资</el-button>
			<el-table :data="salary" class="mg-t-10 fsMedia" stripe size='small'>
				<el-table-column width='70' prop="date" label="月份"></el-table-column>
				<el-table-column min-width='100' prop="month_salary" label="当月应发(/元)" align="center">
					<template v-slot="scope">
						{{scope.row | getMonthSalary}}
					</template>
				</el-table-column>
				<el-table-column min-width="100" prop="moth_performance" label="当月绩效(/元)" align="center">
					<template v-slot="scope">
						{{scope.row | getMothPerformance}}
					</template>
				</el-table-column>
				<el-table-column width="90" prop="classFee" label="课时费(/元)" align="center">
					<template v-slot='scope'>
						{{scope.row.classFee == null ? 0 : scope.row.classFee}}
					</template>
				</el-table-column>
				<el-table-column min-width="100" prop="" label="实际底薪(/元)" align="center">
					<template v-slot="scope">
						{{scope.row | getActualBaseSalary}}
					</template>
				</el-table-column>
				<el-table-column width="150" prop="real_attendance_day" label="本月实际出勤天数(/天)" align="center"></el-table-column>
				<el-table-column label="操作" fixed='right' width="110">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="$router.push('/organization/salaryInfo?teacherId=' + scope.row.sid + '&salaryId=' + scope.row.id)">详细信息</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page="page" :total="total" layout="total, prev, pager, next" background class="mg-t-10" @current-change='pageChange'></el-pagination>
		</el-card>
		<el-dialog :visible.sync="salaryDialogShow" title="添加薪资" center width="90%" :close-on-click-modal="false" @close="salaryDialogClose" v-if='salaryDialogShow'>
			<salary-info-c :isHaveBreadCrumb='false'  @resetFields='resetFields = $event' @isShow='addSalarySuccess' :show.sync='salaryDialogShow'></salary-info-c>
		</el-dialog>
	</div>
	
</template>

<script>
	import breadcrumbC from '../../common/v-breadcrumb.vue'
	import salaryInfoC from './salaryInfo.vue'
	import {
		getTeacherSalary
	} from '../../../assets/js/API/salary.js'
	import {
		getMothPerformance,
		getMonthSalary,
		getActualBaseSalary
	} from '../../../assets/js/CONFIG/salary.js'
	export default {
		name: 'SalaryAll',
		data () {
			return {
				isCanAdd: true,
				value_breadcrumb: ['机构管理', '薪资管理', '所有薪资'],
				salary: [],
				salaryDialogShow: false,
				page: 1,
				total: 0
			}
		},
		methods: {
			addSalarySuccess (bool) {
				this.salaryDialogShow = bool;
				this.page = 1;
				this.getTeacherSalary()
			},
			salaryDialogClose () {
				this.resetFields()
			},
			showAddSalary () {
				this.salaryDialogShow = true
			},
			pageChange (page) {
				this.page = page;
				this.getTeacherSalary()
			},
			getTeacherSalary () {
				getTeacherSalary.call(this, this.$route.query.teacherId, this.page)
								.then( res => {
									this.salary = res.data
									this.page = res.current_page;
									this.total = res.total
								})
								.catch( err => {})
			}
		},
		components: {
			breadcrumbC,
			salaryInfoC
		},
		filters: {
			getMothPerformance (val) {
				return getMothPerformance(val)
			},
			getMonthSalary (val) {
				return getMonthSalary(val)
			},
			getActualBaseSalary (val) {
				return getActualBaseSalary(val)
			}
		},
		created() {
			this.getTeacherSalary();
			var role = window.sessionStorage.getItem('role');
			// if (role > 2) this.isCanAdd = false
			this.isCanAdd = false
		}
	}
</script>

<style>
</style>
