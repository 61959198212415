<template>
	<!-- 课程 -->
	<div>
		<!-- 日志 -->
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>系统管理</el-breadcrumb-item>
		  <el-breadcrumb-item>日志管理</el-breadcrumb-item>
		  <el-breadcrumb-item>课程日志</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 卡片 -->
		<el-card>
			<!-- 搜索区域 -->
			<div slot="header">
				<el-select
				 v-model="course"
				 placeholder="请筛选需要查找的课程"
				 @change="getChooseCourse"
				 clearable
				>
					<el-option
					v-for="(item,index) in courses"
					:key="index"
					:label="item.name"
					:value="item.id"
					>
					</el-option>
				</el-select>
				<el-select
					v-model="type"
					 placeholder="请筛选操作的类型"
					 @change="kindChoose"
					 clearable
					 style="margin-left: 15px;"
				>
					<el-option
					v-for="(item,index) in kind"
					:key="index"
					:label="item.name"
					:value="item.id"
					>
					</el-option>
				</el-select>
				<el-date-picker
				style="margin-left: 15px;"
				v-model="date"
				type="daterange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				value-format="yyyy-MM-dd"
				@change="dateChange"
				>
					
				</el-date-picker>
				
			</div>
			<!-- 表格区域 -->
			<div>
				<el-table
					:data="courseLog"
				>
					<el-table-column
						label="事件"
						prop="event"
					>
					</el-table-column>
					<el-table-column
						align="center"
						label="记录时间"
						prop="created_at"
					>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination
				    layout="prev, pager, next"
					@current-change="paginateChange"
					:page-size=20
				    :total="total">
				  </el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default{
		name: 'LogCourse',
		data(){
			return{
				/* 课程 */
				courses:[],
				course:'',
				date:[],
				/* 日志表格 */
				courseLog:[],
				/* 分页用到 */
				currentPage:1,
				total:0,
				/* 操作类型 */
				kind:[
					{id:1,name:"增加操作"},
					{id:2,name:"修改操作"},
					{id:3,name:"删除操作"}
				],
				type:''
			}
		},
		methods:{
			/* 获取课程日志 */
			async getCourses(){
				const {data:res} = await this.$http.get('/education/courses/system/log?page='+this.currentPage+'&course=' + this.course + '&date='+ this.date+'&type='+this.type);
				if(res.code == 200){
					this.courseLog = res.data.data;
					this.total = res.data.total;
					this.currentPage = res.data.currentPage;
				}
			},
			/* 获取课程 */
			async getCourseByNotPaginate(){
				const {data:res} = await this.$http.get('/education/courses/system/get');
				if(res.code == 200){
					this.courses = res.data;
				}
			},
			/* 监听课程筛选的改变 */
			getChooseCourse(item){
				this.currentPage = 1;
				this.getCourses();
			},
			/* 监听时间的改变 */
			dateChange(item){
				this.currentPage = 1;
				this.getCourses();
			},
			/* 页码改变 */
			paginateChange(item){
				this.currentPage = item;
				this.getCourses();
			},
			/* 操作类型 */
			kindChoose(){
				this.currentPage = 1;
				this.getCourses()();
			}
		},
		created() {
			/* 获取课程日志 */
			this.getCourses();
			/* 获取课程 */
			this.getCourseByNotPaginate();
		}
	}
</script>

<style>
</style>
