/* 部门API */
//级别类型 1-校区 2-总部    等级 1，10，20，30...依次类推
export async function addLevel (params) {
	/* {
		name: '级别名称',
		type: '级别类型',
		level: '等级'
	} */
	var { data: res } = await this.$http.post('/organization/level', params);
	if (res.code == 0) {
		this.$message.success('添加级别成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function modifyLevel (params) {
	/* {
		id: '级别id',
		name: '级别名称',
		type: '级别类型',
		level: '等级'
	} */
	var { data: res } = await this.$http.put('/organization/level', params);
	if (res.code == 0) {
		this.$message.success('修改级别信息成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function delLevel (data) {
	/* {
		id: '级别id'
	} */
	var { data: res } = await this.$http.delete('/organization/level', {
		data
	});
	if (res.code == 0) {
		this.$message.success('删除级别信息成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function getLevel (params) {
	/* {
		name: '级别名称',
		type: '级别类型',
		level: '等级'
	} */
	var { data: res } = await this.$http.get('/organization/level/list', {
		params
	});
	if (res.code == 0) {
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function getLevelInfo (id) {
	//id级别id
	var { data: res } = await this.$http.get('/organization/level/detail?id=' + id);
	if (res.code == 0) {
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function getDepartment (params) {
	/* {
		name: '部门名称',//非必选
		level: '部门等级',//非必选
		id: '部门id'  //非必选
	} */
	var { data: res } = await this.$http.get('/organization/department/list', {
		params
	});
	if (res.code == 0) {
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function addDepartment (params) {
	/* {
		name: '部门名称',
		parent_id: '从属部门id',
		type: '部门类型',
		schoole_id: '对应校区'
	} */
	var { data: res } = await this.$http.post('/organization/department', params);
	if (res.code == 0) {
		this.$message.success('添加部门成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function getDepartmentInfo (id) {
	//id部门id
	var { data: res } = await this.$http.get('/organization/department/detail?id=' + id);
	if (res.code == 0) {
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function modifyDepartment (params) {
	/* {
		name: '部门名称',
		id: '部门id'
	} */
	var { data: res } = await this.$http.put('/organization/department', params);
	if (res.code == 0) {
		this.$message.success('修改部门信息成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}

export async function delDepartment (data) {
	/* {
		id: '部门id'
	} */
	var { data: res } = await this.$http.delete('/organization/department', {
		data
	});
	if (res.code == 0) {
		this.$message.success('删除部门成功')
		return res.data
	} else {
		this.$message.error(res.message)
	}
}