// 学员备注

/* 
	@describe 获取学员备注记录
	@date 2021-03-04
	@by FZY
	@params sid- 学员id  page- 当前页
*/
export async function getRemarkLists (params) {
	var { data: res } = await this.$http.get('/student/notes/get', {
		params
	});
	if (res.code == 200) {
		return res.data
	}
}

/* 
	@describe 添加学员备注记录
	@date 2021-03-04
	@by FZY
*/
export async function addRemark (params) {
	var { data: res } = await this.$http.post('/student/notes/add', params);
	if (res.code == 200) {
		this.$message.success('添加学员备注成功')
		return res.data
	}
}

/* 
	@describe 修改学员备注记录信息
	@date 2021-03-04
	@by FZY
*/
export async function modifyRemark (params) {
	var { data: res } = await this.$http.patch('/student/notes/update', params);
	if (res.code == 200) {
		this.$message.success('修改学员备注成功')
		return res.data
	}
}

/* 
	@describe 删除学员备注记录
	@date 2021-03-04
	@by FZY
*/
export async function delRemark (sid) {
	var { data: res } = await this.$http.delete('/student/notes/delete?noteId=' + sid);
	if (res.code == 200) {
		this.$message.success('备注信息已删除')
		return res.data
	}
}