<template>
	<!-- 部门级别管理 -->
	<div>
		<breadcrumb-c :value_breadcrumb='breadcrumb'></breadcrumb-c>
		<el-card>
			<el-row>
				<el-col :span='3'>
					<el-input placeholder="请输入级别名称" size="medium" clearable v-model="levelScreen.name" @change="levleScreenChange"></el-input>
				</el-col>
				<el-col :span='3' :offset='1'>
					<el-select style="width: 100%;" v-model="levelScreen.type" placeholder="请选择级别类型" @change="levleScreenChange" clearable size="medium">
						<el-option v-for="(item, i) in levelTypeOption" :label="item.label" :value="item.value" :key='item.value'></el-option>
					</el-select>
				</el-col>
				<el-col :span='3' :offset='1'>
					<el-select style="width: 100%;" v-model="levelScreen.level" placeholder="请选择等级" @change="levleScreenChange" size="medium" clearable>
						<el-option v-for="(item, i) in levelOptions" :label="item.label" :value="item.value" :key='item.value'></el-option>
					</el-select>
				</el-col>
				<el-col :span='3' :offset='1'>
					<el-button type="primary" size="medium" @click="showLevelInfo('add')">添加部门级别</el-button>
				</el-col>
			</el-row>
			
			<el-table :data="levelTableData" style="width: 100%;margin-bottom: 20px;" row-key="id" class="mg-t-10">
				<el-table-column prop="type" label="级别类型" sortable>
					<template v-slot='scope'>
						{{levelTypeOption[scope.row.type - 1].label}}
					</template>
				</el-table-column>
				<el-table-column prop="name" label="级别名称">
				</el-table-column>
				<el-table-column prop="level" label="等级" sortable>
				</el-table-column>
				<el-table-column  label="操作" width="150" fixed="right">
					<template v-slot='scope'>
						<el-button type="primary" size="small" @click="showLevelInfo('modify', scope.row)">编辑</el-button>
						<el-button type="primary" size="small" @click='delLevel(scope.row)'>删除</el-button>
					</template>
				</el-table-column>				
			</el-table>
			<el-pagination :current-page="levelScreen.page" layout="total, prev, pager, next" :total='levelTotal' background @current-change='levelPageChange'></el-pagination>					
			<el-dialog :visible.sync="showDialogLevel" center title="添加部门级别">
				<el-form label-position="right" label-width="80px" :rules='rules' ref='refFormLevel' :model="level">
					<el-form-item label="级别名称" prop="name">
						<el-input placeholder="请输入级别名称" v-model="level.name"></el-input>
					</el-form-item>
					<el-form-item label="级别类型" prop="type">
						<el-select style="width: 100%;" v-model="level.type" placeholder="请选择级别类型" @change="levelTypeChange" :disabled="leveladdORmodify == 'modify'">
							<el-option v-for="(item, i) in levelTypeOption" :label="item.label" :value="item.value" :key='item.value'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="等级">
						<span style="color: #0086B3;">{{level.level}}</span> 级
						<!-- <el-select style="width: 100%;">
							<el-option v-for="(item, i) in levelTypeOption" :label="item.label" :value="item.value" :key='item.value'></el-option>
						</el-select> -->
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="showDialogLevel = false">取 消</el-button>
				    <el-button type="primary" @click="addORmodifyLevel">确 定</el-button>
				  </span>
			</el-dialog>
		</el-card>		
	</div>
</template>

<script>
	import breadcrumbC from '../common/v-breadcrumb.vue'
	import {
		getLevel,
		getLevelInfo,
		addLevel,
		delLevel,
		modifyLevel
	} from '../../assets/js/API/department.js'
	import {
		level,
		LEVELTYPE,
		LEVEL
	} from '../../assets/js/CONFIG/department.js'
	import {
		extend
	} from '../../assets/js/util.js'
	import vali from '../../assets/js/validate.js'
	const LEVELDATA = new level();
	export default {
		name: 'DepartmentLevel',
		data() {
			return {
				showDialogLevel: false,
				leveladdORmodify: 'add',
				level: {
					id: '',
					name: '',
					type: '',
					level: ''
				},
				levelTypeOption: LEVELTYPE,
				levelOptions: LEVEL,
				levelTableData: [],
				levelTotal: 0,
				levelScreen: {
					name: '',
					type: '',
					level: '',
					page: 1
				},
				rules: {
					name: vali.validatorCommon.mandatory('请输入级别名称'),
					type: vali.validatorCommon.mandatory('请选择级别类型', 'change')
				},
				breadcrumb: ['机构管理', '部门级别管理']
			}
		},
		watch: {
			'showDialogLevel': function (val) {
				if (!val) { this.$refs.refFormLevel.resetFields(); this.level.level = ''; this.level.name = ''; this.level.type = '' }
			}
		},
		methods: {
			showLevelInfo (key, row) {
				this.leveladdORmodify = key;
				this.showDialogLevel = true;
				if (row) extend(this.level, row)
			},
			levelPageChange (page) {
				this.levelScreen.page = page;
				this.getLevel()
			},
			levleScreenChange () {
				this.levelScreen.page = 1;
				this.getLevel()
			},
			levelTypeChange () {
				if (this.leveladdORmodify == 'add') {
					var key = this.level.type == 1 ? 'schoole' : (this.level.type == 2 ? 'headquarters' : '');
					this.level.level = LEVELDATA.getLately(key)
				}				
			},
			async addORmodifyLevel () {
				var bool = await this.$refs.refFormLevel.validate().catch( err => console.error(err));
				if (!bool) return
				if (this.leveladdORmodify == 'add') {
					addLevel.call(this, this.level)
							.then( res => {
								this.showDialogLevel = false;
								this.getLevel();
								this.getLevel(999999)
							})
							.catch( err => { console.log(err) })
				} else if (this.leveladdORmodify == 'modify'){
					modifyLevel.call(this, this.level)
							   .then( res => {
								   this.showDialogLevel = false;
								   this.getLevel();
								   this.getLevel(999999)
							   })
							   .catch( err => { console.log(err) })
				}				
			},
			delLevel (row) {
				var key = row.type == 1 ? 'schoole' : (row.type == 2 ? 'headquarters' : ''),
					data = LEVELDATA[key];
				var max = Math.max(...data);
				if (max != row.level) return this.$message.error('不允许跳级删除')
				this.$confirm('您确定要删除这个级别吗?', '提示')
					.then( res => {
						delLevel.call(this, { id: row.id })
								.then( res => {
									this.getLevel();
									this.getLevel(999999)
								})
								.catch( err => {})
					})
					.catch( err => {})
				
			},
			getLevel (number) {
				var params = {...this.levelScreen};
				number && (params.number = number)
				getLevel.call(this, params)
						.then(res => {
							number == undefined ? (this.levelTableData = res.data, this.levelPage = res.page, this.levelTotal = res.total) : (LEVELDATA.setData(res.data, 'type'), LEVELDATA.setDefault())
						})
						.catch( err => { console.log(err) })
			}
		},
		created() {
			this.getLevel();
			this.getLevel(999999)
		},
		components: {
			breadcrumbC
		}
	}
</script>

<style>
</style>
